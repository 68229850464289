import React from 'react';

export type currentProductStateType = {
  isLoading: boolean;
  isError: boolean;
  id?: string;
  product: any;
  redirectToReferrer: boolean;
  from: string;
  activeKey: string;
  providerList?: any;
};

export enum ActionType {
  DATA_LOADER,
  SET_ID,
  SET_PRODUCT,
  SET_REDIRECT,
  SET_FROM,
  SET_ACTIVE_KEY,
  SET_PROVIDER_LIST,
  SET_ERROR,
}
export type Action = {
  type: ActionType;
  payload?: any;
};

export function currentProductReducer(state: currentProductStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      return {
        ...state,
        isLoading: typeof action.payload === undefined ? !state.isLoading : action.payload,
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case ActionType.SET_PRODUCT:
      return {
        ...state,
        product: { ...state.product, ...action.payload },
      };
    case ActionType.SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case ActionType.SET_REDIRECT:
      return {
        ...state,
        redirectToReferrer: action.payload,
      };
    case ActionType.SET_FROM:
      return {
        ...state,
        from: action.payload,
      };
    case ActionType.SET_ACTIVE_KEY:
      return {
        ...state,
        activeKey: action.payload,
      };
    case ActionType.SET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action.payload,
      };

    default:
      return state;
  }
}

export const currentProductInitialState = {
  isLoading: true,
  isError: false,
  id: '',
  product: {},
  redirectToReferrer: false,
  activeKey: '',
  from: '',
  providerList: [],
};

export const ProductDetailsContext = React.createContext<{
  state: currentProductStateType;
  dispatch: React.Dispatch<Action>;
}>({ state: currentProductInitialState, dispatch: () => {} });
