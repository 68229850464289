import React, { useContext, useEffect, useReducer, useState } from 'react';
import cn from 'classnames';
import Card from 'components/card';
import styles from 'pages/productDocuments/assets/productDocuments.module.scss';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import { ActionType, productDocumentsReducer } from './store/productDocuments.reducer';
import { ProductDetailsContext } from 'pages/productDetails/store/productDetails.reducer';
import { useAppState } from 'store';
import useForm from '_shared/hooks/useForm';
import { productDocumentFields } from './fields/productDocuments.fields';
import validation from '_shared/fieldValidation';
import Field from 'components/field';
import { sectionCategoriesType } from 'pages/productInformation/types';
import { TOAST_MESSAGE } from 'store/toast/types';
import { putProduct } from '_shared/api/products';
import BlockNavigationModal from 'components/BlockNavigationModal';
import classNames from 'classnames';

const ProductDocuments: React.FC = () => {
  const { state: contextState } = useContext(ProductDetailsContext);

  const initialState = {
    isSaving: false,
    isError: false,
    ableToSave: false,
  };

  const [state, dispatch] = useReducer(productDocumentsReducer, initialState);
  const [fields, setFields] = useState<any>([]);
  const { t } = useTranslation();
  const store = useAppState();
  const productCategory = contextState.product.type;
  const productId = contextState.product.productId;
  const canEditProducts = Boolean(
    (store.state.system.isSuperAdmin && store.state.system.isSuperAdmin()) ||
      (store.state.system.isMarketPlaceManager && store.state.system.isMarketPlaceManager())
  );

  const handleSaveChanges = async (changedValues: { [key: string]: any }) => {
    let productValues = Object.fromEntries(
      Object.entries(contextState.product).filter(([_, value]) => value != null)
    );

    const combinedValues = {
      ...productValues,
      ...changedValues,
    };

    if (!productId) return;
    dispatch({
      type: ActionType.SET_IS_SAVING,
      payload: true,
    });
    try {
      await putProduct(combinedValues);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:productdetails:documents:success') },
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: ActionType.SET_IS_SAVING,
        payload: false,
      });
      dispatch({
        type: ActionType.SET_ABLE_TO_SAVE,
        payload: false,
      });
    }
  };

  const { handleChange, handleSubmit, errors, metadata, values } = useForm(
    {},
    fields,
    handleSaveChanges,
    validation
  );

  const filteredItems = metadata?.filter((product: sectionCategoriesType) => {
    return product.productType?.includes(productCategory);
  });

  useEffect(() => {
    if (!Object.keys(values).length) {
      dispatch({
        type: ActionType.SET_ABLE_TO_SAVE,
        payload: false,
      });
      return;
    }
    dispatch({
      type: ActionType.SET_ABLE_TO_SAVE,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!contextState?.product || !Object.keys(contextState?.product).length) {
      return;
    }
    setFields(productDocumentFields(contextState.product, canEditProducts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.product]);

  const SaveChanges = () => {
    return (
      <div className={cn(styles['application-actions'])}>
        {(state.ableToSave && (
          <button className={cn(styles['save-button'])} onClick={handleSubmit}>
            {(state.isSaving && <Spinner size="small" />) ||
              t('home:productdetails:documents:save')}
          </button>
        )) ||
          (!state.ableToSave && (
            <button
              className={cn(styles['save-button'], !state.ableToSave && styles.inactive)}
              onClick={handleSubmit}
              disabled
            >
              {(state.isSaving && <Spinner size="small" />) ||
                t('home:productdetails:documents:save')}
            </button>
          ))}
      </div>
    );
  };

  return (
    <div>
      <h3 className={classNames('sw-h3', styles.title)}>
        Documents
        {(canEditProducts && <SaveChanges />) || null}
      </h3>
      <div className={cn(styles['fields-container'])} data-testid="product-documents">
        {(filteredItems?.length &&
          filteredItems?.map((metadataField) => {
            return (
              <Field
                {...{
                  key: metadataField.id,
                  metadata: metadataField,
                  errors: errors,
                  handleChange: handleChange,
                }}
              />
            );
          })) ||
          null}
      </div>
      <BlockNavigationModal shouldBlock={state.ableToSave} />
    </div>
  );
};

export default ProductDocuments;
