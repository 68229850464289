import { sentenceCase, titleCase } from './casing';

export function displayStringValue(
  value: string | number | undefined | null,
  {
    applySentenceCase = false,
    applyTitleCase = false,
    replaceWithSpace,
    displayFallback = true,
    fallback = 'N/A',
  }: {
    applySentenceCase?: boolean;
    applyTitleCase?: boolean;
    replaceWithSpace?: string;
    displayFallback?: boolean;
    fallback?: string;
  } = {}
) {
  if (typeof value === 'number') {
    // eslint-disable-next-line no-param-reassign
    value = String(value);
  }
  if (replaceWithSpace && value) {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(new RegExp(`${replaceWithSpace}`, 'g'), ' ');
  }
  const fallbackValue = displayFallback ? fallback : '';
  if (applySentenceCase) {
    return typeof value === 'string' ? sentenceCase(value) : fallbackValue;
  }

  if (applyTitleCase) {
    return typeof value === 'string' ? titleCase(value) : fallbackValue;
  }

  return typeof value === 'string' ? value : fallbackValue;
}

export const formatAddress = (address: {
  address?: string;
  city?: string;
  postcode?: string;
  district?: string;
  county?: string;
  stateCode?: string;
  country?: string;
}) => {
  const { address: location, city, postcode, district, county, stateCode, country } = address || {};
  const orderArray = [location, city, postcode, district, county, stateCode, country];

  return (orderArray.length && orderArray.filter((val: any) => Boolean(val)).join(', ')) || 'N/A';
};

export function tryParseJSON(jsonString: string) {
  try {
    var parsed = JSON.parse(jsonString);
    if (parsed && typeof parsed === 'object') {
      return parsed;
    }
  } catch (e) {
    return false;
  }

  return false;
}

export function capitalizeFirstLetter(string: string) {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Gets the first letter of the first two words in a text.
export function getTextInitials(text: string) {
  if (!text.length) return '';

  // Splitting each word of the text.
  const splittedNames = text.split(' ');

  // Getting the first letter of the first two words only and transforming it into uppercase letter before adding it to the initials list.
  const initialsList = splittedNames.slice(0, 2).map((name) => {
    return name.charAt(0).toUpperCase();
  });

  // Getting each letter of the initials list and concatenate into a single string.
  return initialsList.join('');
}

export function spaceBetweenTitleCaseWords(string: string) {
  if (typeof string !== 'string') return '';
  return string.replace(/([A-Z])/g, ' $1').trim();
}
