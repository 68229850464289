import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { convertToLocalDateFormat } from '_shared/utils/date';
import { AdvancedDatePickerType, FilterType } from './type';
import styles from './assets/advancedDatePicker.module.scss';
import { datePickerRange, monthsDic } from '_shared/utils/constants';
import { range } from '../datepicker/utils';
import ReactDatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import Button from 'components/button';
import { convertToUTCDate } from './utils/utils';

const calendarStartDate = new Date('1900-01-01');
const calendarEndDate = new Date('2100-12-31');
const years = range(datePickerRange.startYear.value, datePickerRange.endYear.value);
const months = monthsDic.map(function (month, index) {
  return monthsDic[index].value;
});

const AdvancedDatePicker = React.forwardRef(
  (
    {
      placeholder,
      className,
      showCalendarIcon,
      todayButtonText,
      disabled,
      shouldCloseOnSelect,
      filters,
      handeUpdateLastProgressedFilters,
    }: AdvancedDatePickerType,
    ref: any
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [filterType, setFilterType] = useState<FilterType>(FilterType.Before);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const inputDisplayValue = useMemo(() => {
      const {
        LastProgressedFilterType: type,
        LastProgressedFrom: from,
        LastProgressedTo: to,
      } = filters;

      switch (type) {
        case FilterType.Before:
          return from ? `Before: ${convertToLocalDateFormat(from)}` : '';
        case FilterType.After:
          return from ? `After: ${convertToLocalDateFormat(from)}` : '';
        case FilterType.Between:
          return from && to
            ? `Between: ${convertToLocalDateFormat(from)} - ${convertToLocalDateFormat(to)}`
            : '';
        default:
          return '';
      }
    }, [filters.LastProgressedFilterType, filters.LastProgressedFrom, filters.LastProgressedTo]);

    const startDateDisplayValue = useMemo(
      () => (startDate ? convertToLocalDateFormat(startDate) : ''),
      [startDate]
    );

    const endDateDisplayValue = useMemo(
      () => (endDate ? convertToLocalDateFormat(endDate) : ''),
      [endDate]
    );

    const handleSingleDateChange = (date: Date | null) => {
      if (!date) return;

      setStartDate(filterType === FilterType.Before ? null : date);
      setEndDate(filterType === FilterType.Before ? date : null);

      handeUpdateLastProgressedFilters(filterType, convertToUTCDate(date));
    };

    const handleMultipleDatesChange = (date: Date | null) => {
      if (!date) return;

      if (!startDate) {
        setStartDate(date);
      } else if (!endDate) {
        if (date >= startDate) {
          setEndDate(date);
          handeUpdateLastProgressedFilters(
            filterType,
            convertToUTCDate(startDate),
            convertToUTCDate(date)
          );
        } else {
          setStartDate(date);
        }
      } else {
        setStartDate(date);
        setEndDate(null);
      }
    };

    const handleMonthChange = (
      value: string,
      date: Date,
      changeMonth: Function,
      givenStartDate: Date,
      givenEndDate: Date
    ) => {
      let currentStartDateAsString = givenStartDate ? givenStartDate.toString() : '';
      let currentEndDateAsString = givenEndDate ? givenEndDate.toString() : '';

      const reformattedStartDateWithNewMonth = currentStartDateAsString.replace(
        currentStartDateAsString.slice(4, 7),
        value.slice(0, 3)
      );

      const reformattedEndDateWithNewMonth = currentEndDateAsString.replace(
        currentEndDateAsString.slice(4, 7),
        value.slice(0, 3)
      );

      if (filterType === FilterType.Before && endDate) {
        handleSingleDateChange(convertToUTCDate(new Date(reformattedEndDateWithNewMonth)));
      }
      if (filterType === FilterType.After && startDate) {
        handleSingleDateChange(convertToUTCDate(new Date(reformattedStartDateWithNewMonth)));
      } else if (filterType === FilterType.Between && startDate && endDate) {
        const isMonthTheSame = givenStartDate?.getMonth() === givenEndDate?.getMonth();

        if (isMonthTheSame) {
          handeUpdateLastProgressedFilters(
            filterType,
            convertToUTCDate(new Date(reformattedStartDateWithNewMonth)),
            convertToUTCDate(new Date(reformattedEndDateWithNewMonth))
          );
        } else {
          handeUpdateLastProgressedFilters(filterType, null, null);
        }
      }

      changeMonth(months.indexOf(value));
    };

    const handleYearChange = (
      value: string,
      date: Date,
      changeYear: Function,
      givenStartDate: Date,
      givenEndDate: Date
    ) => {
      let currentStartDateAsString = givenStartDate ? givenStartDate.toString() : '';
      let currentEndDateAsString = givenEndDate ? givenEndDate.toString() : '';

      const reformattedStartDateWithNewYear = currentStartDateAsString.replace(
        currentStartDateAsString.slice(11, 15),
        value.slice(0, 4)
      );

      const reformattedEndDateWithNewYear = currentEndDateAsString.replace(
        currentEndDateAsString.slice(11, 15),
        value.slice(0, 4)
      );

      if (filterType === FilterType.Before && endDate) {
        handleSingleDateChange(convertToUTCDate(new Date(reformattedEndDateWithNewYear)));
      }
      if (filterType === FilterType.After && startDate) {
        handleSingleDateChange(convertToUTCDate(new Date(reformattedStartDateWithNewYear)));
      } else if (filterType === FilterType.Between && startDate && endDate) {
        const isYearTheSame = givenStartDate?.getFullYear() === givenEndDate?.getFullYear();

        if (isYearTheSame) {
          handeUpdateLastProgressedFilters(
            filterType,
            convertToUTCDate(new Date(reformattedStartDateWithNewYear)),
            convertToUTCDate(new Date(reformattedEndDateWithNewYear))
          );
        } else {
          handeUpdateLastProgressedFilters(filterType, null, null);
        }
      }

      changeYear(parseInt(value));
    };

    const setActiveTab = (newFilterType: FilterType) => {
      setFilterType(newFilterType);

      if (newFilterType === FilterType.Before) {
        setStartDate(null);
        setEndDate(
          newFilterType === filters.LastProgressedFilterType ? filters.LastProgressedFrom : null
        );
      } else if (newFilterType === FilterType.After) {
        setStartDate(
          newFilterType === filters.LastProgressedFilterType ? filters.LastProgressedFrom : null
        );
        setEndDate(null);
      } else {
        setStartDate(
          newFilterType === filters.LastProgressedFilterType ? filters.LastProgressedFrom : null
        );
        setEndDate(
          newFilterType === filters.LastProgressedFilterType ? filters.LastProgressedTo : null
        );
      }
    };

    useEffect(() => {
      const { LastProgressedFilterType, LastProgressedFrom, LastProgressedTo } = filters;

      if (LastProgressedFilterType) {
        setFilterType(LastProgressedFilterType);
      }

      if (LastProgressedFilterType === FilterType.Before) {
        setStartDate(null);
        setEndDate(LastProgressedFrom ? convertToUTCDate(LastProgressedFrom) : null);
      } else if (LastProgressedFilterType === FilterType.After) {
        setStartDate(LastProgressedFrom ? convertToUTCDate(LastProgressedFrom) : null);
        setEndDate(null);
      } else if (LastProgressedFilterType === FilterType.Between) {
        setStartDate(LastProgressedFrom ? convertToUTCDate(LastProgressedFrom) : null);
        setEndDate(LastProgressedTo ? convertToUTCDate(LastProgressedTo) : null);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    }, [filters.LastProgressedFilterType, filters.LastProgressedFrom, filters.LastProgressedTo]);

    return (
      <ReactDatePicker
        dateFormat="dd-MM-yyyy"
        onChange={
          filterType === FilterType.Between ? handleMultipleDatesChange : handleSingleDateChange
        }
        className={className}
        customInput={
          <CustomDateInput
            inputPlaceholder={placeholder}
            inputValue={inputDisplayValue}
            activeTab={filterType}
            startDate={startDate}
            endDate={endDate}
            inputRef={inputRef}
            className={className}
            disabled={disabled}
            showCalendarIcon={showCalendarIcon}
          />
        }
        todayButton={todayButtonText ?? 'Today'}
        disabledKeyboardNavigation
        data-testid="advanced-date-picker"
        shouldCloseOnSelect={shouldCloseOnSelect}
        renderDayContents={renderDayContents}
        startDate={filterType === FilterType.Before ? calendarStartDate : startDate}
        endDate={filterType === FilterType.After ? calendarEndDate : endDate}
        selectsStart={filterType === FilterType.Between}
        selectsEnd={filterType === FilterType.Between}
        renderCustomHeader={({ date, ...headerProps }) => (
          <DatePickerCustomHeader
            date={date}
            {...headerProps}
            months={months}
            handleMonthChange={handleMonthChange}
            years={years}
            handleYearChange={handleYearChange}
            startDateDisplayValue={startDateDisplayValue}
            endDateDisplayValue={endDateDisplayValue}
            activeTab={filterType}
            setActiveTab={setActiveTab}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      />
    );
  }
);

const DatePickerCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  months,
  handleMonthChange,
  years,
  handleYearChange,
  startDateDisplayValue,
  endDateDisplayValue,
  activeTab,
  setActiveTab,
  startDate,
  endDate,
}: any) => {
  const advancedDatePickerCalendarTabs = [
    { label: 'Before', value: FilterType.Before },
    { label: 'Between', value: FilterType.Between },
    { label: 'After', value: FilterType.After },
  ];
  return (
    <>
      {/* Tabs */}
      <div
        className={classNames(styles['tab-container'])}
        data-testid="advanced-date-picker-container"
      >
        {advancedDatePickerCalendarTabs.map((tab: { [key: string]: string }) => {
          return (
            <Button
              key={tab.value}
              id={tab.value}
              data-testid={tab.value}
              ariaLabel={tab.label}
              className={classNames(
                styles['tab-button'],
                activeTab === tab.value && styles['active-filter-button']
              )}
              clickHandler={() => {
                setActiveTab(tab.value);
              }}
            >
              {tab.label}
            </Button>
          );
        })}
      </div>

      {/* Month & Year Dropdowns */}
      <div className={classNames(styles['month-and-year-dropdown'])}>
        <button
          className={classNames(styles['headings'], styles['change-month'])}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          {'<'}
        </button>
        <select
          data-testid="month-select"
          className={styles.headings}
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) => {
            handleMonthChange(value, date, changeMonth, startDate, endDate);
          }}
        >
          {months.map((month: any) => (
            <option key={month} value={month} data-testid={month}>
              {month}
            </option>
          ))}
        </select>
        <select
          data-testid="year-select"
          className={classNames(styles['headings'], styles['year-select'])}
          value={getYear(date)}
          onChange={({ target: { value } }) => {
            handleYearChange(value, date, changeYear, startDate, endDate);
          }}
        >
          {years.map((option: any) => (
            <option key={option} value={option} data-testid={option}>
              {option}
            </option>
          ))}
        </select>
        <button
          className={classNames(styles['headings'], styles['change-month'])}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          {'>'}
        </button>
      </div>

      {/* Users Selected Tab */}
      <div className={classNames(styles['date-tab-filters'])}>
        {(!activeTab || activeTab === 'Before') && (
          <div data-testid="advanced-date-picker-Before-tab">
            <label>Before - </label>
            <input
              data-testid="before-input-value"
              type="text"
              placeholder="dd/mmm/yyyy"
              value={endDateDisplayValue}
              readOnly
              className={classNames(endDate ? styles['user-input'] : styles['focused-user-input'])}
            />
          </div>
        )}
        {activeTab === 'Between' && (
          <div data-testid="advanced-date-picker-Between-tab">
            <input
              data-testid="between-input-start-date"
              type="text"
              placeholder="dd/mmm/yyyy"
              value={String(startDateDisplayValue)}
              readOnly
              className={classNames(
                startDate ? styles['user-input'] : styles['focused-user-input']
              )}
            />
            <label> - </label>
            <input
              data-testid="between-input-end-date"
              type="text"
              placeholder="dd/mmm/yyyy"
              value={String(endDateDisplayValue)}
              readOnly
              className={classNames(
                startDate && !endDate ? styles['focused-user-input'] : styles['user-input']
              )}
            />
          </div>
        )}
        {activeTab === 'After' && (
          <div data-testid="advanced-date-picker-After-tab">
            <input
              data-testid="after-input-value"
              type="text"
              placeholder="dd/mmm/yyyy"
              value={startDateDisplayValue}
              readOnly
              className={classNames(
                startDate ? styles['user-input'] : styles['focused-user-input']
              )}
            />
            <label> - Now</label>
          </div>
        )}
      </div>
    </>
  );
};

//React Date Picker - Custom Input Component
const CustomDateInput = React.forwardRef(
  (
    {
      inputPlaceholder,
      inputValue,
      activeTab,
      startDate,
      endDate,
      onClick,
      inputRef,
      className,
      disabled,
      showCalendarIcon,
    }: any,
    _ref: React.Ref<any>
  ) => {
    return (
      <div className={styles.datepicker}>
        <input
          key={'customDatePickerInputKey'}
          onClick={onClick}
          ref={inputRef}
          placeholder={inputPlaceholder}
          className={classNames(styles.input, styles.trailingicon, className)}
          value={inputValue}
          disabled={disabled}
          readOnly
          data-testid="advanced-date-picker-input"
        />
        {showCalendarIcon && (
          <i
            aria-labelledby="trailing-icon"
            className={classNames(styles['calendar-icon'], 'material-icons')}
            onClick={onClick}
          >
            calendar_month
          </i>
        )}
      </div>
    );
  }
);
const renderDayContents = (day: number, date: Date) => {
  return <div data-testid={`date-${convertToLocalDateFormat(date)}`}>{day}</div>;
};

export default AdvancedDatePicker;
