import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../../assets/duplicateDocumentModal.module.scss';
import Button from 'components/button';
import Modal from 'components/modal';
import { DuplicateDocumentModalPropType } from './types';
import Table from 'components/table';
import {
  duplicateDocumentsFields,
  duplicateFileNameField,
} from 'pages/documentLibrary/fields/duplicateDocuments.fields';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { TablePropType } from 'components/table/type';

const DuplicateDocumentModal = ({
  openModal,
  setOpenModal,
  duplicateDocuments,
  fileName,
}: DuplicateDocumentModalPropType) => {
  const fileNames = [{ id: fileName + '-id', name: fileName }];
  const { t } = useTranslation();
  const [documentFields, setDocumentFields] = useState<TablePropType>(duplicateDocumentsFields([]));
  const [duplicateFileName, setDuplicateFileName] = useState<TablePropType>(
    duplicateFileNameField([])
  );

  useEffect(() => {
    setDocumentFields(duplicateDocumentsFields(duplicateDocuments));
  }, [duplicateDocuments]);

  useEffect(() => {
    setDuplicateFileName(duplicateFileNameField(fileNames));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return (
    <Modal show={openModal} handleClose={() => setOpenModal(false)}>
      <div className={cn(styles['duplicate-document-modal'])}>
        <header className={cn(styles['duplicate-document-header'])}>
          <h3>{t('home:companydetails:documentlibrary:duplicatedocumentmodal:title')}</h3>
        </header>
        <div className={cn(styles['duplicate-table-container'])}>
          <span className={cn(styles['duplicate-warning'])}>
            {t('home:companydetails:documentlibrary:duplicatedocumentmodal:warning1')}{' '}
            {duplicateDocuments.length}{' '}
            {t('home:companydetails:documentlibrary:duplicatedocumentmodal:warning2')}
          </span>
          <Table {...duplicateFileName} />
        </div>
        <div className={cn(styles['existing-document-table'])}>
          <h4>{t('home:companydetails:documentlibrary:duplicatedocumentmodal:existingtitle')}</h4>
          <Table {...documentFields} />
        </div>
        <div className={cn(styles['action-container'])}>
          <Button
            id="duplicate-document-action"
            className={cn(styles['action-button'])}
            ariaLabel="duplicate-document-action"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            tabIndex={0}
            clickHandler={() => {
              setOpenModal(false);
            }}
          >
            {t('home:companydetails:documentlibrary:duplicatedocumentmodal:buttontext')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateDocumentModal;
