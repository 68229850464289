import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './text.module.scss';
import { FieldPropType } from '../type';
import { currencyIcon } from '_shared/utils/currency';
import Spinner from 'components/spinner';

const TextInput = React.forwardRef(
  (
    { metadata, errors, handleChange, onEnter }: FieldPropType,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { t } = useTranslation();

    const error = metadata.name && errors[metadata.name];

    return (
      <div className={cn(styles.control, metadata.classNames?.control)}>
        {(metadata.leadingIcon && (
          <i
            aria-labelledby="leading-icon"
            className={cn('material-icons', styles['leading-icon'])}
          >
            {metadata.leadingIcon}
          </i>
        )) ||
          null}

        <div className={cn('material-icons', styles['trailing-icon'])}>
          {metadata.isLoading ? (
            <Spinner size="small" />
          ) : (
            metadata.trailingIcon && (
              <i
                aria-labelledby="trailing-icon"
                className={cn('material-icons', styles['trailing-icon'])}
              >
                {metadata.trailingIcon}
              </i>
            )
          )}
        </div>

        <input
          data-format={metadata.format}
          data-leading-icon={currencyIcon()}
          data-testid={`test-${metadata.id}`}
          id={metadata.id}
          type={metadata.type}
          name={metadata.name}
          tabIndex={metadata.tabIndex || 0}
          aria-label={metadata.label}
          aria-invalid={!!error}
          aria-describedby={`error-${metadata.name}`}
          ref={ref}
          placeholder={t(metadata.placeholder || '')}
          className={cn(styles.input, !!error && styles.error, metadata.classNames?.value)}
          value={[null, undefined].includes(metadata.value) ? '' : metadata.value}
          {...(typeof metadata?.min !== undefined && { min: metadata.min })}
          {...(typeof metadata?.max !== undefined && { max: metadata.max })}
          onChange={handleChange}
          onWheel={
            metadata.type === 'number'
              ? (e) => e.target instanceof HTMLElement && e.target.blur()
              : undefined
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !metadata?.disabled) {
              onEnter?.();
            }
          }}
          disabled={metadata?.disabled}
          autoComplete={metadata?.autoComplete}
        />
      </div>
    );
  }
);

export default TextInput;
