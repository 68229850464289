import React from 'react';
import { useParams } from 'react-router-dom';
import styles from 'pages/productApplicationForm/assets/productApplicationForm.module.scss';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import ErrorComponent from 'components/error';
import ProductApplicationFormHook from './hooks/productApplicationForm.hook';
import BlockNavigationModal from 'components/BlockNavigationModal';
import { IContext } from 'store/types';
import { useAppState } from 'store';
import SplitPane from 'components/v2/SplitPane';
import FormSection from 'components/FormSection';
import FormQuestionGroup from 'components/FormQuestionGroup';
import FormQuestion from 'components/FormQuestionGroup/FormQuestion';
import TextInput from 'components/v2/TextInput';
import { FormSectionType } from './store/productApplicationForm.reducer';
import StickyFooter from 'components/v2/StickyFooter';

type AvailableSectionsProps = {
  searchTerm: string;
  filteredAvailableSections: FormSectionType[];
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAdd: (idsToAdd: string[]) => void;
};

type PreviewFormProps = {
  formSections: FormSectionType[];
  isDefaultField: (fieldId: string) => boolean;
  handleDelete: (idsToDelete: string[]) => void;
};

const AvailableSections: React.FC<AvailableSectionsProps> = ({
  searchTerm,
  filteredAvailableSections,
  handleSearchChange,
  handleAdd,
}) => {
  return (
    <div className={styles['question-sections']}>
      <span className={styles['question-title']}>Add questions to build up the application</span>
      <TextInput
        value={searchTerm}
        placeholder={'Search for questions'}
        className={styles['search-task-input']}
        leadingIcon={'search'}
        onChange={handleSearchChange}
      />
      <span>Forms</span>
      {filteredAvailableSections.map((section) => {
        return (
          <FormQuestionGroup title={section.name} onAdd={handleAdd}>
            {section.fields.map((field) => {
              return <FormQuestion id={field.id}>{field.displayName}</FormQuestion>;
            })}
          </FormQuestionGroup>
        );
      })}
    </div>
  );
};

const PreviewForm: React.FC<PreviewFormProps> = ({
  formSections,
  isDefaultField,
  handleDelete,
}) => {
  return (
    <div className={styles['form-sections']}>
      <div className={styles['title-container']}>
        <span className={styles['form-title']}>Application form config</span>
        <div className={styles['required-label']}>
          <span>
            The current application system has 6 minimum questions that the user must fill in, these
            are marked below as "Not removable".
          </span>
          <span> Please note, the new application system will not have this issue.</span>
        </div>
      </div>
      {formSections.map((section) => (
        <FormSection
          icon={'domain_add'}
          title={section.name}
          questions={section.fields.map((field) => {
            return {
              id: field.id,
              text: field.displayName,
              displayOrder: field.displayOrder,
              isRequired: isDefaultField(field.id),
            };
          })}
          onDelete={handleDelete}
        ></FormSection>
      ))}
    </div>
  );
};

const ProductApplicationForm: React.FC = () => {
  const { id: productId } = useParams() as { id?: string };
  const { t } = useTranslation();
  const { state: globalState }: IContext = useAppState();
  const newProductId = globalState.deals.funderId ? globalState.deals.funderId : productId;
  const {
    state,
    filteredAvailableSections,
    handleSaveChanges,
    handleSearchChange,
    handleAdd,
    handleDelete,
    handleDiscardChanges,
    isDefaultField,
  } = ProductApplicationFormHook(newProductId ?? '');

  return (
    <>
      {((state.isLoading || state.isError) && (
        <div className={styles['loading-container']}>
          {(state.isError && (
            <ErrorComponent message={t('home:productdetails:applicationform:error')} />
          )) || <Spinner size="large" />}
        </div>
      )) || (
        <SplitPane
          left={
            <PreviewForm
              formSections={state.previewSections}
              isDefaultField={isDefaultField}
              handleDelete={handleDelete}
            />
          }
          right={
            <AvailableSections
              searchTerm={state.searchTerm}
              handleSearchChange={handleSearchChange}
              filteredAvailableSections={filteredAvailableSections}
              handleAdd={handleAdd}
            />
          }
          leftPaneSize={70}
        ></SplitPane>
      )}
      <StickyFooter show={state.ableToSave} contentClassName={styles['action-buttons']}>
        <button className={styles['discard-button']} onClick={handleDiscardChanges}>
          Discard changes
        </button>
        <button className={styles['save-button']} onClick={handleSaveChanges}>
          {state.isSaving ? <Spinner size="small" /> : 'Save changes'}
        </button>
      </StickyFooter>
      <BlockNavigationModal shouldBlock={state.ableToSave} />
    </>
  );
};

export default ProductApplicationForm;
