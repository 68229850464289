import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import styles from '../assets/register.module.scss';

const defaultStyles = {
  control: styles['field-control'],
  field: styles['field'],
  error: styles['field-error'],
};

export const registerFields = (email: string) => {
  return [
    {
      id: 'register-email',
      name: 'email',
      label: 'register:email:label',
      value: email,
      fieldType: FieldTypes.TEXT,
      disabled: true,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'register-firstname',
      name: 'firstName',
      placeholder: '',
      label: 'register:firstname:label',
      validationtype: ValidationTypes.TEXT,
      required: true,
      tabIndex: 1,
      value: '',
      fieldType: FieldTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'register-lastname',
      name: 'lastName',
      label: 'register:lastname:label',
      placeholder: '',
      required: true,
      tabIndex: 2,
      value: '',
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'register-password',
      name: 'password',
      label: 'login:password:placeholder',
      validationtype: ValidationTypes.PASSWORD,
      value: '',
      required: true,
      tabIndex: 3,
      minimumLength: 8,
      type: 'password',
      fieldType: FieldTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'register-confirm-password',
      name: 'confirm-password',
      label: 'register:confirmpassword:label',
      validationtype: ValidationTypes.CONFIRM_PASSWORD,
      value: '',
      required: true,
      tabIndex: 4,
      minimumLength: 8,
      type: 'password',
      fieldType: FieldTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'register-contactnumber',
      name: 'contactNumber',
      label: 'register:contact:label',
      validationtype: ValidationTypes.TEXT,
      value: '',
      required: true,
      tabIndex: 5,
      minimumLength: 8,
      type: 'tel',
      fieldType: FieldTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
  ];
};
