import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import { userDetailFields } from './userDetails.field';
import { RoleListType } from './type';
import { customRoleFields } from 'pages/userManagement/components/roleManagement/roleManagement.field';

export type usersStateType = {
  loading: boolean;
  error: boolean;
  userDetailsInfo: any;
  currentUserRoles: Array<{ [key: string]: string }>;
  availableUserRoles: RoleListType[];
  userDetailFields: Array<FieldMetaDataPropType>;
  customUserRoles: Array<{ [key: string]: string }>;
  deleteUserConfirmationModal: boolean;
};

export const initialState = {
  loading: true,
  error: false,
  userDetailsInfo: [],
  currentUserRoles: [],
  availableUserRoles: [],
  userDetailFields: userDetailFields(),
  customUserRoles: customRoleFields(),
  deleteUserConfirmationModal: false,
};

export enum ActionType {
  SET_LOADING,
  SET_ERRORS,
  SET_USER_DETAIL_INFO,
  SET_CURRENT_USER_ROLES,
  SET_AVAILABLE_USER_ROLES,
  SET_USER_DETAIL_FIELDS,
  SET_CUSTOM_USER_ROLES,
  SHOW_DELETE_CONFIRMATION_MODAL,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function userReducer(state: usersStateType, action: Action) {
  switch (action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case ActionType.SET_ERRORS:
      return {
        ...state,
        error: action.payload.error,
      };
    case ActionType.SET_USER_DETAIL_INFO:
      return {
        ...state,
        userDetailsInfo: action.payload,
      };
    case ActionType.SET_CURRENT_USER_ROLES:
      return {
        ...state,
        currentUserRoles: action.payload,
      };
    case ActionType.SET_AVAILABLE_USER_ROLES:
      return {
        ...state,
        availableUserRoles: action.payload,
      };
    case ActionType.SET_USER_DETAIL_FIELDS:
      return {
        ...state,
        userDetailFields: action.payload.userDetailFields,
      };
    case ActionType.SET_CUSTOM_USER_ROLES:
      return {
        ...state,
        customUserRoles: action.payload.customUserRoles,
      };
    case ActionType.SHOW_DELETE_CONFIRMATION_MODAL:
      return {
        ...state,
        deleteUserConfirmationModal: action.payload.deleteUserConfirmationModal,
      };
    default:
      return state;
  }
}
