import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CompanyDetailsContext } from 'pages/companyDetails/store/companyDetails.reducer';
import Card from 'components/card';
import cn from 'classnames';
import styles from 'pages/integrations/assets/integrations.module.scss';
import Button from 'components/button';
import {
  getAccountingStatus,
  postAccountingIntegration,
  getBankAccountStatus,
} from '_shared/api/financialInfo';
import history from '_shared/history';
import {
  IntegrationStatusEnum,
  IntegrationStatusType,
  statusMap,
  BankIntegrationStatusType,
  bankStatusMap,
} from './types';
import Spinner from 'components/spinner';
import features from 'config/features';

const Integrations: React.FC = () => {
  const { state } = useContext(CompanyDetailsContext);
  const { id: companyId } = useParams();
  const { t } = useTranslation();
  const [accountingIntegrationStatus, setAccountingIntegrationStatus] = useState<{
    [key: string]: string;
  }>({});
  const [bankIntegrationStatus, setBankIntegrationStatus] = useState<{
    [key: string]: string;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const companyEmail =
    state.company?.data?.email ??
    state?.accountInfoData?.contacts?.[0]?.email ??
    'fallback@mail.com';

  const integrationStatus = () => {
    const status = statusMap[accountingIntegrationStatus.status as IntegrationStatusType];

    if (status) {
      return (
        <span className={cn(styles['connection-status'], { [styles[status.className]]: true })}>
          <span
            className={cn('material-icons', styles['connection-icon'])}
            data-testid={`accoutning-status-icon-${status.icon}`}
          >
            {status.icon}
          </span>
          <span data-testid={`accounting-status-text-${status.text}`}>{status.text}</span>
        </span>
      );
    }
  };

  const bankIntegrationStatusBox = () => {
    const status = bankStatusMap[bankIntegrationStatus.status as BankIntegrationStatusType];

    if (status) {
      return (
        <span className={cn(styles['connection-status'], styles[status.className])}>
          {status.spinner ? (
            <Spinner size={'small'} color={'orange'} />
          ) : (
            <span
              className={cn('material-icons', styles['connection-icon'])}
              data-testid={`bank-status-icon-${status.icon}`}
            >
              {status.icon}
            </span>
          )}
          <span data-testid={`bank-status-text-${status.text}`}>{status.text}</span>
        </span>
      );
    }
  };

  const requestAccountingIntegration = async () => {
    if (!companyId || accountingIntegrationStatus.status === IntegrationStatusEnum.CONNECTED)
      return;

    setLoading(true);

    try {
      if (
        accountingIntegrationStatus.status === IntegrationStatusEnum.NOTCONNECTED &&
        !accountingIntegrationStatus.connectionUrl
      ) {
        await postAccountingIntegration(companyId, {
          companyName: state.company.data.companyName,
          companyEmail,
          redirectUrl: window.location.href,
        });

        // Added this delay to give time for integration request so that we get updated status response from forwardAi api
        await new Promise<void>((resolve) => setTimeout(resolve, 3000));

        const { data } = await getAccountingStatus(companyId);

        if (data.status === IntegrationStatusEnum.PENDING && data.connectionUrl) {
          history.push(data.connectionUrl);
        }
      } else if (
        accountingIntegrationStatus.status === IntegrationStatusEnum.PENDING &&
        accountingIntegrationStatus.connectionUrl
      ) {
        history.push(accountingIntegrationStatus.connectionUrl);
      }

      setLoading(false);
    } catch (error) {
      console.log(error, 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!companyId) return;
    const getStatus = async () => {
      const { data } = await getAccountingStatus(companyId);
      setAccountingIntegrationStatus(data);
    };

    const getBankStatus = async () => {
      let { data } = await getBankAccountStatus(companyId);
      setBankIntegrationStatus(data.length === 0 ? { status: 'NotIntegrated' } : data[0]);
    };

    getStatus().catch((err) => {
      console.log(err);
    });

    getBankStatus().catch((err) => {
      console.log(err);
    });
  }, [companyId]);

  return (
    <>
      <Card
        title={t('home:companydetails:integrations:title')}
        className={{ card: cn(styles['integration-container']) }}
      >
        <div>
          <div className={cn(styles['icon-and-label'])}>
            <span className={cn('material-icons', styles['icon'])}>auto_graph</span>
            <span className={cn(styles['label'])}>
              {t('home:companydetails:integrations:accountingsoftware:title')}
            </span>
          </div>
          {accountingIntegrationStatus.status &&
            accountingIntegrationStatus.status !== IntegrationStatusEnum.CONNECTED && (
              <Button
                id={'link-accounting-btn'}
                ariaLabel={'Link accounting software'}
                className={cn(styles['link-integration-btn'])}
                clickHandler={requestAccountingIntegration}
                loading={loading}
                disabled={loading}
              >
                {accountingIntegrationStatus.status === IntegrationStatusEnum.PENDING
                  ? t('home:companydetails:integrations:accountingsoftware:resumeintegration')
                  : t('home:companydetails:integrations:accountingsoftware:linksoftware')}
              </Button>
            )}
        </div>
        <div>{integrationStatus()}</div>
      </Card>

      {features.bankIntegrationEnabled && (
        <Card className={{ card: cn(styles['integration-container']) }}>
          <div className={cn(styles['icon-and-label'])}>
            <span className={cn('material-icons', styles['icon'])}>account_balance</span>
            <span className={cn(styles['label'])}>
              {t('home:companydetails:integrations:bankingsoftware:title')}
            </span>
          </div>
          <div>{bankIntegrationStatusBox()}</div>
        </Card>
      )}
    </>
  );
};

export default Integrations;
