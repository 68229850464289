import { CashFlowProjectionItems, CashFlowShortTermItem } from '../types';

export type financialInformationStateType = {
  from: any;
  financialInfo: {
    financialInfoCards: Array<any>;
    loading: boolean;
    error: boolean;
  };
  profitLoss: {
    profitLossField: any;
    loading: boolean;
    isEmpty: boolean;
    error: boolean;
  };
  balanceSheet: {
    balanceSheetField: any;
    loading: boolean;
    isEmpty: boolean;
    error: boolean;
  };
  creditReports: {
    creditPassportBadgeUrl?: string;
    loading: boolean;
    descriptions: {
      label: string;
      gradeAsPerCreditPassport: string;
      yourGradeExplainer: string;
      whatThisMeans: string;
      behaviouralAssessment: string;
      financialAssessment: string;
    };
  };
  cashflowForecast: {
    data: CashFlowProjectionItems;
    fields: any;
    loading: boolean;
    error: boolean;
    filter: {
      actualMonths: number;
      forecastMonths: number;
    };
  };
  cashflowShortterm: {
    data: CashFlowShortTermItem;
    loading: boolean;
    error: boolean;
  };
};

export enum FinancialInfoActionType {
  SET_FINANCIAL_INFO_CARDS,
  SET_PROFIT_LOSS_FIELD,
  SET_BALANCE_SHEET_FIELD,
  SET_ONBOARDING_QUESTIONS_FIELD,
  SET_CREDIT_REPORTS,
  SET_ACTUAL_CASHFLOW_FORECAST,
  SET_CASHFLOW_FORECAST_FILTER,
  SET_CASHFLOW_SHORTTERM,
}

export type Action = {
  type: FinancialInfoActionType;
  payload: any;
};

export function financialInfoReducer(state: financialInformationStateType, action: Action) {
  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case FinancialInfoActionType.SET_CREDIT_REPORTS:
      return {
        ...state,
        creditReports: { ...state.creditReports, ...action.payload },
      };

    case FinancialInfoActionType.SET_FINANCIAL_INFO_CARDS:
      return {
        ...state,
        financialInfo: { ...state.financialInfo, ...action.payload },
      };

    case FinancialInfoActionType.SET_PROFIT_LOSS_FIELD:
      return {
        ...state,
        profitLoss: { ...state.profitLoss, ...action.payload },
      };

    case FinancialInfoActionType.SET_BALANCE_SHEET_FIELD:
      return {
        ...state,
        balanceSheet: { ...state.balanceSheet, ...action.payload },
      };

    case FinancialInfoActionType.SET_ACTUAL_CASHFLOW_FORECAST:
      return {
        ...state,
        cashflowForecast: { ...state.cashflowForecast, ...action.payload },
      };

    case FinancialInfoActionType.SET_CASHFLOW_FORECAST_FILTER:
      return {
        ...state,
        cashflowForecast: {
          ...state.cashflowForecast,
          filter: { ...state.cashflowForecast.filter, ...action.payload },
        },
      };

    case FinancialInfoActionType.SET_CASHFLOW_SHORTTERM:
      return {
        ...state,
        cashflowShortterm: { ...state.cashflowForecast, ...action.payload },
      };

    default:
      return state;
  }
}
