import { FieldTypes } from '_shared/fieldValidation/types';
import styles from '../assets/fundingMatchesDetails.module.scss';
import { OfferTypes } from 'pages/deals/types';

export const fundingOfferFields = (initialValue: string, offerType: OfferTypes) => {
  return {
    id: 'fundingRequiredTimescale',
    name: 'fundingRequiredTimescale',
    placeholder: 'home:companydetails:fundingmatches:content:sortoffers',
    value: (offerType === OfferTypes.GRANTS && 'speedName') || initialValue,
    fieldType: FieldTypes.SELECT,
    required: true,
    options: [
      ...((offerType !== OfferTypes.GRANTS && [
        {
          value: 'aprMin',
          label: 'Rates From',
        },
      ]) ||
        []),
      {
        value: 'providerName',
        label: 'provider Name',
      },
      {
        value: 'speedName',
        label: 'Decision Time',
      },
    ],
    classNames: {
      control: styles.sort,
    },
  };
};
