import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';

import { FormFieldEssentials } from 'pages/companyEssentials/types';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { convertToCurrency } from '_shared/utils/currency';
import SummarisedJourney from '../summarisedJourney';

type PropertyEssentialsProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

const PropertyEssentials: React.FC<PropertyEssentialsProps> = ({
  fetchedData,
  dateCreated,
  editDetails,
  journeyDisplayName,
}) => {
  const fieldsConfig = [
    {
      fieldName: 'fundingAmount',
      render: (field: FormFieldEssentials) => {
        const propertyFundingPurposeField = getFieldByName('propertyFundingPurpose');
        const propertyFundingPurpose = propertyFundingPurposeField?.value
          ? propertyFundingPurposeField.value.toString().toLowerCase()
          : '';

        const propertyTypeField = getFieldByName('propertyType');
        const propertyType = propertyTypeField?.value
          ? `, ${propertyTypeField.value.toString().toLowerCase()} property`
          : '';

        const fundingAmount =
          field.value && field.value !== undefined ? convertToCurrency(field.value.toString()) : '';
        const fundingDescription =
          field.value && field.value !== undefined
            ? `${fundingAmount} funding requested for ${propertyFundingPurpose}${propertyType}`
            : '';

        return (
          field.value &&
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {fundingDescription}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'businessUKLocated',
      render: (field: FormFieldEssentials) => {
        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value === 'Yes' ? 'check' : 'close'}
              </span>
              {field.value === 'Yes' ? `Located in the UK` : 'Not located in the UK'}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'propertyCountyCourtJudgments',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value === 'Yes' ? 'close' : 'check'}
            </span>
            {field.value === 'Yes'
              ? `Has judgments, missed payments, covenant breaches, or expired loans.`
              : 'Does not have judgments, missed payments, covenant breaches, or expired loans.'}
          </p>
        ),
    },
  ];

  const getFieldByName = (name: string) => fetchedData.find((field) => field.name === name);

  return <SummarisedJourney data={fetchedData} fields={fieldsConfig} />;
};

export default PropertyEssentials;
