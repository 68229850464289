import { TablePropType } from 'components/table/type';
import { ICompanyFilter } from 'pages/companies/components/filters/types';

export enum SORTTYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SORTBY {
  SWOOP_SCORE = 'SWOOP_SCORE',
  BROKER_OWNER = 'BROKER_OWNER',
  DATE_CREATED = 'signUpDate',
  FUNDING_MANAGER_LAST_ASSIGNED = 'fundingManagerLastAssignmentDate',
  ACCOUNT_OWNER_LAST_ASSIGNED = 'accountOwnerLastAssignmentDate',
}

export type companiesStateType = {
  redirectToReferrer: boolean;
  loading: boolean;
  isSearching: boolean;
  from: any;
  field: TablePropType;
  pageNumber: number;
  items: any;
  availableFilters: Array<ICompanyFilter>;
  companyName: string;
  searchBy: string;
  selectedFilter: {
    name: string;
    value: number;
  };
  filter: {
    orderBy: string;
    direction: string;
    dateCreated: string;
    pageSize: number;
  };
};
export enum ActionType {
  DATA_LOADER,
  SET_FILTERS,
  SET_ITEMS,
  SET_SEARCH_CRITERIA,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function companiesReducer(state: companiesStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          filteredDataLoading: false,
        };
      }
      return {
        ...state,
        loading: action.payload,
        filteredDataLoading: action.payload,
      };

    case ActionType.SET_FILTERS:
      if (action.payload.orderBy) {
        return {
          ...state,
          filter: action.payload,
        };
      }

      return {
        ...state,
        selectedFilter: action.payload.selectedFilter || state.selectedFilter,
        availableFilters: action.payload.availableFilters || state.availableFilters,
      };

    case ActionType.SET_ITEMS:
      if (action.payload.items.length > 0) {
        return {
          ...state,
          items: [...state.items, ...action.payload.items],
          field: action.payload.field,
          fetchCompanies: false,
        };
      }
      return {
        ...state,
        totalCompaniesFetched: true,
        field: action.payload.field,
      };

    case ActionType.SET_SEARCH_CRITERIA:
      return {
        ...state,
        companyName: action.payload.search,
        searchBy: action.payload.searchBy,
      };
    default:
      return state;
  }
}
