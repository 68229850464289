import React from 'react';
import styles from 'pages/fundingMatches/assets/nonSwoopEquityMessage.module.scss';
import { useTranslation } from 'react-i18next';

const NonSwoopEquityMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['non-swoop-equity-message-container']}>
      <h3 className={styles['instruction-header']}>
        {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionheader1')}
      </h3>
      <p className={styles['instruction-subtitle']}>
        {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionsubtitle')}
      </p>
      <ol className={styles['instruction-list']}>
        <li>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem1')}
        </li>
        <li>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem2')}
        </li>
      </ol>
      <h3 className={styles['instruction-header']}>
        {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionheader2')}
      </h3>
      <ol className={styles['instruction-list']}>
        <li>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem3')}
        </li>
        <li>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem4')}
        </li>
        <li>
          {t('home:companydetails:fundingmatches:nonswoopequitymessage:instructionlistitem5')}
        </li>
      </ol>
    </div>
  );
};

export default NonSwoopEquityMessage;
