import { APIDocumentType } from 'pages/documentLibrary/types';

export type documentLibraryStateType = {
  data: Array<APIDocumentType>;
  loading: boolean;
  error: boolean;
  reload: boolean;
};

export enum documentLibraryActionType {
  SET_DOCUMENTS,
}

export type Action = {
  type: documentLibraryActionType;
  payload: any;
};

export function documentLibraryReducer(state: documentLibraryStateType, action: Action) {
  if (action.type === documentLibraryActionType.SET_DOCUMENTS) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return state;
  }
}
