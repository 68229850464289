import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';
import decisionTimeIconPound from 'assets/images/icon/decisionTimeIconPound.svg';
import decisionTimeIconDollar from 'assets/images/icon/decisionTimeIconDollar.svg';
import fundingRequiredIconPound from 'assets/images/icon/fundingRequiredIconPound.svg';
import fundingRequiredIconDollar from 'assets/images/icon/fundingRequiredIconDollar.svg';

const country: TCountry = env('REACT_APP_COUNTRY');

const icons = {
  decisionTimeIcon: {
    unitedkingdom: decisionTimeIconPound,
    australia: decisionTimeIconDollar,
    canada: decisionTimeIconDollar,
  }[country],
  fundingRequiredIcon: {
    unitedkingdom: fundingRequiredIconPound,
    australia: fundingRequiredIconDollar,
    canada: fundingRequiredIconDollar,
  }[country],
};

export default icons;
