import { useState } from 'react';
import cn from 'classnames';
import styles from './editActivity.module.scss';
import Modal from 'components/modal';
import { ACTIVITY_TYPE, EditActivityPropTypes } from '../types';
import Activity from '../activity';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import TextEditor from 'components/field/textEditor';

const EditActivity = ({
  activity,
  closeEditModal,
  handleConfirmEdit,
  loading,
}: EditActivityPropTypes) => {
  const { t } = useTranslation();

  const [editActivity, setEditActivity] = useState(activity);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditActivity({ ...editActivity, content: event.target.value });
  };

  return (
    <Modal show={Boolean(activity)} handleClose={closeEditModal}>
      <div data-testid="edit-activity" className={cn(styles.container)}>
        <h4 className={cn('sw-h4')}>
          {t('home:companydetails:activities:edit:confirmedit', {
            activityType: spaceBetweenTitleCaseWords(activity.activityType).toLowerCase(),
          })}
        </h4>
        {editActivity.activityType === ACTIVITY_TYPE.NOTE && (
          <TextEditor
            metadata={{
              id: 'edit-wysiwyg',
              name: 'edit-wysiwyg',
              value: editActivity.content,
              disabled: false,
            }}
            handleChange={handleChange}
            errors={{}}
          />
        )}
        {editActivity.activityType === ACTIVITY_TYPE.TASK && (
          <>
            <Activity key={editActivity.id} activity={editActivity} handleEditOnChange={() => {}} />
            <TextEditor
              metadata={{
                id: 'edit-wysiwyg',
                name: 'edit-wysiwyg',
                value: editActivity.content,
                disabled: false,
              }}
              handleChange={handleChange}
              errors={{}}
            />
          </>
        )}
        {editActivity.activityType !== ACTIVITY_TYPE.NOTE &&
          editActivity.activityType !== ACTIVITY_TYPE.TASK && (
            <Activity
              key={editActivity.id}
              activity={editActivity}
              handleEditOnChange={handleChange}
            />
          )}
        <div className={cn(styles.actions)}>
          <button onClick={closeEditModal} className={cn(styles['cancel'])}>
            {t('home:companydetails:activities:edit:cancel')}
          </button>
          <button onClick={() => handleConfirmEdit(editActivity)} className={cn(styles['confirm'])}>
            {(loading && <Spinner size="small" />) ||
              t('home:companydetails:activities:edit:confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditActivity;
