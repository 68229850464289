import React from 'react';
import cn from 'classnames';
import styles from './button.module.scss';
import { ButtonPropType, ButtonStyleTypeEnum } from './type';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';

type IconProps = {
  icon: string | React.ReactNode;
};

const Icon: React.FC<IconProps> = ({ icon }) => {
  if (typeof icon === 'string') {
    return <span className={cn('material-icons', styles.icon)}>{icon}</span>;
  } else {
    return <>{icon}</>;
  }
};

const Button: React.FC<ButtonPropType> = (props: ButtonPropType) => {
  const { t } = useTranslation();

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (props.loading) return;
    props?.clickHandler?.(event);
  };

  return (
    <button
      id={props.id}
      data-testid={props.dataTestId ? `test-${props.dataTestId}` : `test-${props.id}`}
      className={cn(styles.primary, props.className, {
        [styles.primary]: props.buttonStyleType === ButtonStyleTypeEnum.PRIMARY,
        [styles.secondary]: props.buttonStyleType === ButtonStyleTypeEnum.SECONDARY,
        [styles.disabled]: props.buttonStyleType === ButtonStyleTypeEnum.DISABLED,
      })}
      aria-label={props.ariaLabel ? t(props.ariaLabel) : ''}
      onClick={handleOnClick}
      tabIndex={props.tabIndex || 0}
      disabled={props.disabled}
      type={props.type}
      style={props.style}
    >
      {props.icon && <Icon icon={props.icon} />}
      {(props.loading && <Spinner size="small" />) || props.children}
    </button>
  );
};

export default Button;
