import { User } from '../types';

export type UserToggleState = {
  isDropdownOpen: boolean;
  dropdownUsers: User[];
  searchUser: string;
};

export enum ActionType {
  SET_IS_DROPDOWN_OPEN,
  SET_SEARCH_USER,
  SET_DROPDOWN_USERS,
}

export type Action = {
  type: ActionType;
  payload: any;
};

export function userToggleReducer(state: UserToggleState, action: Action) {
  switch (action.type) {
    case ActionType.SET_IS_DROPDOWN_OPEN:
      return {
        ...state,
        isDropdownOpen: action.payload.isDropdownOpen,
      };

    case ActionType.SET_SEARCH_USER:
      return {
        ...state,
        searchUser: action.payload,
      };
    case ActionType.SET_DROPDOWN_USERS:
      return {
        ...state,
        dropdownUsers: action.payload,
      };

    default:
      return state;
  }
}
