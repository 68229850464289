export type TaskType = {
  activityType?: string;
  assignedToFullName?: string;
  assignedToId?: string;
  assignedToPicture?: string;
  companyId?: string;
  companyName?: string;
  content?: string;
  createdBy?: string;
  dateCreated?: string;
  dateModified?: string;
  direction?: string;
  dueDate?: string;
  duration?: number;
  fullName?: string;
  id?: string;
  modifiedBy?: string;
  picture?: string;
  status?: string;
  subject?: string;
  taskStatus?: string;
};

export type CompanyAutoCompleteType = {
  accountOwnerImageUrl: string;
  accountOwnerName: string;
  accountOwnerSecurityId: string;
  currentStage: string;
  fundingRequired: number;
  id: string;
  name: string;
  numberOfCalls: number;
  numberOfEmails: number;
  referredBy: string;
  score: string;
  signUpDate: string;
  turnoverLastYear: number;
};

export type UserAutoCompleteType = {
  email: string;
  fullName: string;
  picture: string;
  securityId: string;
};

export type AssignmentFilterType = {
  assignedToMe: boolean | null;
  assignedToOthers: boolean | null;
};

export type OrderByDirectionType = {
  sortBy: string | null;
  direction: string;
};

export enum AssignedFilterEnum {
  ALL = 'all',
  ASSIGNEDTOME = 'assignedToMe',
  ASSIGNEDTOOTHERS = 'assignedToOthers',
}

export enum TaskStatusEnum {
  TODO = 'Todo',
  INPROGRESS = 'InProgress',
  DONE = 'Done',
}
