import { GptAPi } from '_shared/api';
import React, { useEffect } from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

function GptChatWidget() {
  const [messages, setMessages] = React.useState<{ role: string; content: string }[]>([]);

  enum Models {
    ChatGPT = 'gpt-4',
  }

  useEffect(() => {
    if (!GptAPi || !GptAPi.post || !messages.length) return;
    GptAPi.post('/api/v1/completion', {
      model: Models.ChatGPT,
      messages: messages.slice(-6),
    }).then(({ data }) => {
      setMessages([...messages, { role: 'assistant', content: data?.completion }]);
      addResponseMessage(data?.completion);
    });
  }, [messages.filter(({ role }) => role === 'user').length]);

  const handleNewUserMessage = async (newMessage: string) => {
    setMessages([...messages, { role: 'user', content: newMessage }]);
  };

  return (
    <Widget
      title="SwoopGPT"
      subtitle="Please do not share company data."
      handleNewUserMessage={handleNewUserMessage}
      fullScreenMode={false}
      emojis={false}
      resizable={true}
    />
  );
}

export default GptChatWidget;
