import { useEffect, useState } from 'react';
import cn from 'classnames';
import Button from 'components/button';
import styles from 'pages/companyOverview/assets/accountDetails.module.scss';
import { getCompanyContacts } from '_shared/api/businesses';
import Spinner from 'components/spinner';
import Table from 'components/table';
import ContactModal from '../contactModal';
import { contactFields } from 'pages/companyOverview/fields/contactInfo.fields';
import { ContactModalEnum, TContactProps } from '../contactModal/type';

const Contacts = ({ companyId }: { companyId: string }) => {
  const [contactModalContext, setContactModalContext] = useState<ContactModalEnum | null>(null);
  const [contactInfoFields, setContactInfoFields] = useState<any>([]);
  const [selectedContact, setSelectedContact] = useState<TContactProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchContactsInfo = async (id: string) => {
    setIsLoading(true);
    try {
      const { data } = await getCompanyContacts(id);
      setContactInfoFields(
        contactFields(data?.companyContacts, setContactModalContext, setSelectedContact)
      );

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!companyId) return;
    fetchContactsInfo(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      {(isLoading && (
        <div className={cn(styles['loading-container'])}>
          <Spinner size="large" />
        </div>
      )) || (
        <section className={cn(styles.section)}>
          {(isLoading && (
            <div className={cn(styles['loading-container'])}>
              <Spinner size="small" />
            </div>
          )) || <Table {...contactInfoFields} />}{' '}
          <div className={cn(styles['save-container'])}>
            <Button
              className={cn(styles['add-contact-button'])}
              id="add-contact"
              ariaLabel="add-contact"
              clickHandler={() => {
                setContactModalContext(ContactModalEnum.ADD);
              }}
            >
              <span className={cn('material-icons')}>add_circle</span>
              <span>Add Contact</span>
            </Button>
          </div>
        </section>
      )}
      {Boolean(contactModalContext) && (
        <ContactModal
          {...{
            contactModalContext,
            setContactModalContext,
            selectedContact,
            setSelectedContact,
            companyId,
            fetchContactsInfo,
          }}
        />
      )}
    </>
  );
};

export default Contacts;
