import { UserRoles } from 'config/roles';
import { CurrentUserType } from 'store/system/types';

export type hasPermissionType = (
  requiredRoles: UserRoles | UserRoles[],
  currentUserRoles?: UserRoles[]
) => boolean;

const getRoles = (roles?: string[]) => {
  return roles || [];
};

export const isSuperAdmin = (currentUser?: CurrentUserType) => {
  return getRoles(currentUser?.roles).includes(UserRoles.SUPER_ADMIN);
};

export const isMarketPlaceManager = (currentUser?: CurrentUserType) => {
  return getRoles(currentUser?.roles).includes(UserRoles.MARKETPLACE_MANAGER);
};

export const isMarketPlaceManagerOrSuperAdmin = function (currentUser?: CurrentUserType) {
  return getRoles(currentUser?.roles).some((role: string) =>
    [`${UserRoles.SUPER_ADMIN}`, `${UserRoles.MARKETPLACE_MANAGER}`].includes(role)
  );
};

export const isAdminOrSuperAdmin = (currentUser?: CurrentUserType) => {
  return getRoles(currentUser?.roles).some((role: string) =>
    [`${UserRoles.EXTERNAL_ADMIN}`, `${UserRoles.ADMIN}`, `${UserRoles.SUPER_ADMIN}`].includes(role)
  );
};

export const isBroker = (currentUser?: CurrentUserType) => {
  return Boolean(getRoles(currentUser?.roles).includes(UserRoles.BROKER));
};

export const isBrokerRoleType = (currentUser?: CurrentUserType) => {
  return getRoles(currentUser?.roles).some((role: string) =>
    [`${UserRoles.BROKER_MANAGER}`, `${UserRoles.BROKER}`].includes(role)
  );
};

export const isAccountantRoleType = (currentUser?: CurrentUserType) => {
  return getRoles(currentUser?.roles).some((role: string) =>
    [`${UserRoles.ADVISOR_MANAGER}`, `${UserRoles.ADVISOR}`].includes(role)
  );
};

export const hasOneOfRoles = (currentUser: CurrentUserType, roles: string[]) => {
  return getRoles(currentUser?.roles).some((role: string) => roles.includes(role.toString()));
};

export const isUserManager = (currentUser?: CurrentUserType) => {
  return Boolean(getRoles(currentUser?.roles).includes(UserRoles.IAM_ADMIN));
};
