export type productInsightsStateType = {
  isSaving: boolean;
  isError: boolean;
  ableToSave: boolean;
};

export enum Action {
  SET_IS_SAVING,
  SET_IS_ERROR,
  SET_ABLE_TO_SAVE,
}

export function productInsightsReducer(
  state: productInsightsStateType,
  action: {
    type: Action;
    payload?: any;
  }
) {
  switch (action.type) {
    case Action.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload,
      };
    case Action.SET_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case Action.SET_ABLE_TO_SAVE:
      return {
        ...state,
        ableToSave: action.payload,
      };

    default:
      return state;
  }
}
