import { ApplicationCardType } from '../types';

export type applicationFormStateType = {
  id?: string;
  isLoading: boolean;
  isSaving: boolean;
  isError: boolean;
  ableToSave: boolean;
  applicationForms: ApplicationCardType[];
  fieldDefinitions: string[];
  productDefinitions: string[];
  searchTerm: string;
  availableSections: FormSectionType[];
  previewSections: FormSectionType[];
  isInitialLoad: boolean;
};

export type FormField = {
  id: string;
  displayName: string;
  name: string;
  originalOrder: number;
  displayOrder: number;
};

export type FormSectionType = {
  displayOrder: number;
  name: string;
  id: string;
  fields: FormField[];
  selectAll: boolean;
};

export enum ActionType {
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_IS_ERROR,
  SET_ABLE_TO_SAVE,
  SET_FIELD_DEFINITIONS,
  SET_PRODUCT_DEFINITIONS,
  SET_APPLICATION_FIELDS,
  SET_SEARCH_TERM,
  SET_AVAILABLE_SECTIONS,
  SET_PREVIEW_SECTIONS,
  SET_INITIAL_LOAD,
}

export function applicationFormReducer(
  state: applicationFormStateType,
  action: {
    type: ActionType;
    payload?: any;
  }
) {
  switch (action.type) {
    case ActionType.SET_IS_LOADING:
      return {
        ...state,
        isLoading: typeof action.payload === undefined ? !state.isLoading : action.payload,
      };
    case ActionType.SET_IS_SAVING:
      return {
        ...state,
        isSaving:
          typeof action.payload.isSaving === undefined ? !state.isSaving : action.payload.isSaving,
      };
    case ActionType.SET_IS_ERROR:
      return {
        ...state,
        isError:
          typeof action.payload.isError === undefined ? !state.isError : action.payload.isError,
      };
    case ActionType.SET_APPLICATION_FIELDS:
      return {
        ...state,
        applicationForms: action.payload.applicationForms,
      };
    case ActionType.SET_ABLE_TO_SAVE:
      return {
        ...state,
        ableToSave: action.payload.ableToSave,
      };
    case ActionType.SET_FIELD_DEFINITIONS:
      return {
        ...state,
        fieldDefinitions: action.payload.fieldDefinitions,
      };
    case ActionType.SET_PRODUCT_DEFINITIONS:
      return {
        ...state,
        productDefinitions: action.payload.productDefinitions,
      };
    case ActionType.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      };
    case ActionType.SET_AVAILABLE_SECTIONS:
      return {
        ...state,
        availableSections: action.payload.availableSections,
      };
    case ActionType.SET_PREVIEW_SECTIONS:
      return {
        ...state,
        previewSections: action.payload.previewSections,
      };
    case ActionType.SET_INITIAL_LOAD:
      return {
        ...state,
        isInitialLoad: action.payload.isInitialLoad,
      };
    default:
      return state;
  }
}
