import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import i18next from 'i18next';
import React, { useEffect, useReducer } from 'react';
import { useAppState } from 'store';
import { TOAST_MESSAGE } from 'store/toast/types';
import { deleteActivity, getActivities, putActivity } from '_shared/api/activity';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import { activitiesFields } from './activities.fields';
import { ActionType, activitiesReducer } from './reducer';
import {
  ACTIVITY_ORDER,
  ActivityType,
  ACTIVITY_CATEGORY,
  APIActivityType,
  ACTIVITY_TYPE,
} from './types';

interface UpdateActivityParams {
  type: ACTIVITY_TYPE;
  content: string;
  task?: { [key: string]: any };
}

const ActivitiesHook = (companyId: string) => {
  const [state, dispatch] = useReducer(activitiesReducer, {
    isActivitiesDropdownOpen: false,
    activitiesCount: 0,
    activities: [],
    data: [],
    loading: {
      activities: false,
      delete: false,
      update: false,
    },
    error: {
      activities: false,
    },
    activityToDelete: null,
    activityToEdit: null,
    activityCategory: null,
    blockNavigation: false,
    order: ACTIVITY_ORDER.newestFirst,
    params: {
      filter: '',
      direction: '',
      pageSize: 100,
    },
    isChooseActivityModalOpen: false,
  });
  const store = useAppState();
  const userId = store.state.system.currentUser?.authId || '';

  const toggleOpenActivityDropdown = () => {
    dispatch({
      type: ActionType.SET_IS_ACTIVITIES_DROPDOWN_OPEN,
      payload: '',
    });
  };

  const handleDeleteActivity = (activityId: string) => {
    dispatch({
      type: ActionType.SET_ACTIVITY_TO_DELETE,
      payload: {
        activityToDelete: state.activities.find(({ id }: { id: string }) => id === activityId),
      },
    });
  };

  const closeDeleteModal = () => {
    dispatch({
      type: ActionType.SET_ACTIVITY_TO_DELETE,
      payload: { activityToDelete: null },
    });
  };

  const handleConfirmDelete = async () => {
    if (!companyId || !state.activityToDelete?.id) return;
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { delete: true },
    });

    try {
      const { status } = await deleteActivity(companyId, state.activityToDelete.id);
      if (status === 202) {
        store.dispatch({
          type: TOAST_MESSAGE,
          payload: {
            toastMessage: i18next.t(`home:companydetails:activities:delete:success`, {
              activityType: spaceBetweenTitleCaseWords(state.activityToDelete.type),
            }),
          },
        });
        dispatch({
          type: ActionType.SET_ACTIVITY_TO_DELETE,
          payload: { activityToDelete: null },
        });
        fetchActivities(companyId);
        sendAmplitudeData(AmplitudeTrackingEnum.deletenote);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: { delete: false },
      });
    }
  };

  const handleEditActivity = (activityId: string) => {
    dispatch({
      type: ActionType.SET_ACTIVITY_TO_EDIT,
      payload: {
        activityToEdit: state.data.find(({ id }: { id: string }) => id === activityId),
      },
    });
  };

  const handleConfirmEdit = async (updatedActivity: ActivityType) => {
    if (!companyId || !updatedActivity.id) return;
    dispatch({
      type: ActionType.SET_LOADING,
      payload: { update: true },
    });
    try {
      let activityParams: UpdateActivityParams = {
        content: updatedActivity.content,
        type: updatedActivity.activityType,
      };

      if (updatedActivity.activityType === ACTIVITY_TYPE.TASK) {
        activityParams.task = updatedActivity;
      }

      const { status } = await putActivity(companyId, updatedActivity.id, activityParams);

      if (status === 204) {
        store.dispatch({
          type: TOAST_MESSAGE,
          payload: {
            toastMessage: i18next.t(`home:companydetails:activities:edit:success`, {
              activityType: updatedActivity.activityType,
            }),
          },
        });
        fetchActivities(companyId);
        closeEditModal();
        sendAmplitudeData(AmplitudeTrackingEnum.editnotes);
      }
    } catch (e) {
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: i18next.t(`home:companydetails:activities:edit:error`, {
            activityType: updatedActivity.activityType,
          }),
        },
      });
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: { update: false },
      });
    }
  };

  const closeEditModal = () => {
    dispatch({
      type: ActionType.SET_ACTIVITY_TO_EDIT,
      payload: { activityToDelete: null },
    });
  };

  const handleCreateNewActivity = (activityCategory: ACTIVITY_CATEGORY) => {
    if (state.activityCategory) {
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: i18next.t(`home:companydetails:activities:new:noteexists`) },
      });
    } else {
      dispatch({
        type: ActionType.SET_NEW_ACTIVITY_CATEGORY,
        payload: { activityCategory },
      });
      sendAmplitudeData(AmplitudeTrackingEnum.newnote);
    }
  };

  const deleteNewActivity = () => {
    dispatch({
      type: ActionType.SET_NEW_ACTIVITY_CATEGORY,
      payload: { activityCategory: null },
    });
  };

  const fetchActivities = async (id: string) => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: { activities: true },
      });
      const {
        data: { results, paging },
      } = await getActivities({
        ...((state.params.filter && { ActivityTypes: [state.params.filter] }) || {}),
        companyId: id,
        pageSize: state.params.pageSize,
      });

      dispatch({
        type: ActionType.SET_ACTIVITIES,
        payload: {
          activities: activitiesFields(orderActivities(results, state.order), userId),
          activitiesCount: paging.totalCount,
          data: results,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.SET_ERROR,
        payload: { activities: true },
      });
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: { activities: false },
      });
    }
  };

  const handleChangeOrder = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: ActionType.SET_ACTIVITY_ORDER,
      payload: { order: event.target.value },
    });
  };

  const orderActivities = (data: Array<APIActivityType>, order: ACTIVITY_ORDER) => {
    switch (order) {
      case ACTIVITY_ORDER.newestFirst:
        return data.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));
      case ACTIVITY_ORDER.oldestFirst:
        return data.sort((a, b) => (a.dateCreated > b.dateCreated ? 1 : -1));
      case ACTIVITY_ORDER.userAtoZ:
        return data.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
      case ACTIVITY_ORDER.userZtoA:
        return data.sort((a, b) => (a.fullName < b.fullName ? 1 : -1));
      default:
        return data.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));
    }
  };

  const handleFilterActivities = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: ActionType.SET_ACTIVITIES_FILTER,
      payload: { filter: event.target.value },
    });
  };

  const openChooseActivityModal = (isOpen?: boolean) => {
    dispatch({
      type: ActionType.SET_OPEN_CHOOSE_ACTIVITY_MODAL,
      payload: { isChooseActivityModalOpen: isOpen },
    });
  };

  useEffect(() => {
    if (!companyId) return;

    fetchActivities(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, state.params.filter]);

  useEffect(() => {
    if (!companyId) return;
    dispatch({
      type: ActionType.SET_ACTIVITIES,
      payload: { activities: activitiesFields(orderActivities(state.data, state.order), userId) },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.order]);

  useEffect(() => {
    if (state.isActivitiesDropdownOpen) {
      sendAmplitudeData(AmplitudeTrackingEnum.viewcompanynotes);
    }
  }, [state.isActivitiesDropdownOpen]);

  return {
    state,
    fetchActivities,
    toggleOpenActivityDropdown,
    handleCreateNewActivity,
    handleDeleteActivity,
    deleteNewActivity,
    handleConfirmDelete,
    closeDeleteModal,
    handleEditActivity,
    handleConfirmEdit,
    closeEditModal,
    handleChangeOrder,
    openChooseActivityModal,
    handleFilterActivities,
  };
};

export default ActivitiesHook;
