import React, { useEffect, useReducer, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import styles from 'pages/tasksManagement/assets/tasks.module.scss';
import Table from 'components/table';
import { field } from './fields/tasks.field';
import { UPDATE_TASK, TaskModalEnum } from 'store/tasks/types';
import { getActivities, putActivity } from '_shared/api/activity';
import useAutoFocus from '_shared/hooks/useAutoFocus';
import Switch from 'components/switch';
import TextInput from 'components/field/text';
import { FieldTypes, FormFieldPropType } from '_shared/fieldValidation/types';
import useDebounce from '_shared/hooks/useDebounce';
import { AssignedFilterEnum, AssignmentFilterType, OrderByDirectionType } from './types';
import Button from 'components/button';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import usePagination from '_shared/hooks/usePagination';
import Pagination from 'components/pagination';
import { IContext } from 'store/types';
import { tasksReducer, ActionType } from 'pages/tasksManagement/store/tasks.reducer';
import { useLocation } from 'react-router-dom';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import { ACTIVITY_TYPE } from 'components/activities/types';

const Tasks: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const didMount = useRef(false);
  const { state: appState, dispatch: globalDispatch }: IContext = useAppState();
  const [doneToggle, setDoneToggle] = useState<boolean>(true);
  const [orderDirection, setOrderDirection] = useState<OrderByDirectionType>({
    sortBy: 'dueDate',
    direction: 'asc',
  });
  const [searchedTask, setSearchedTask] = useState<string>('');

  const debouncedSearchTerm = useDebounce(searchedTask, 500);
  const [filterBy, setFilterBy] = useState<AssignedFilterEnum>(AssignedFilterEnum.ALL);
  const [assignmentFilter, setAssignmentFilter] = useState<AssignmentFilterType>({
    assignedToMe: null,
    assignedToOthers: null,
  });

  const assignedFilters = [
    { label: t('home:tasks:assignedfilters:all'), value: AssignedFilterEnum.ALL },
    { label: t('home:tasks:assignedfilters:assignedtome'), value: AssignedFilterEnum.ASSIGNEDTOME },
    {
      label: t('home:tasks:assignedfilters:assignedtoothers'),
      value: AssignedFilterEnum.ASSIGNEDTOOTHERS,
    },
  ];

  const [state, dispatch] = useReducer(tasksReducer, {
    redirectToReferrer: false,
    loading: false,
    isSearching: false,
    from: location.state || { from: { pathname: '/' } },
    field: field(
      [],
      { sortBy: '', direction: '' },
      () => {},
      () => {}
    ),
    pageNumber: appState.tasks.pageNumber || 1,
    items: [],
    availableFilters: [],
    selectedFilter: appState.tasks.selectedFilters || assignedFilters[0],
    companyName: appState.tasks.companyName || '',
    searchBy: appState.tasks.searchBy || 'CompanyName',
    filter: {
      orderBy: appState.tasks.filter.orderBy || 'dueDate',
      direction: appState.tasks.filter.direction || 'desc',
      dateCreated: '',
      pageSize: Number(appState?.company?.filter?.pageSize) || 20,
    },
  });

  const searchInputRef = useAutoFocus();
  const paginationHook = usePagination(state.filter.pageSize, state.pageNumber);

  const setGlobalPaginationState = (pageNumber: number) => {
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        pageNumber: pageNumber,
      },
    });
  };

  const handleOnHeaderSort = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    event.preventDefault();
    const sortBy = event.currentTarget.getAttribute('data-name');
    const direction = state.filter.direction === 'desc' ? 'asc' : 'desc';

    dispatch({
      type: ActionType.SET_FILTERS,
      payload: {
        ...state.filter,
        orderBy: `${sortBy} ${direction}`,
        direction,
      },
    });
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        filter: {
          ...appState.tasks.filter,
          orderBy: `${sortBy} ${direction}`,
          direction,
        },
      },
    });

    paginationHook.setCurrentPage(1);

    setOrderDirection({ sortBy, direction });
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: ActionType.SET_FILTERS,
      payload: {
        ...state.filter,
        pageSize: event.target.value,
      },
    });
  };

  const updateTaskStatus = async (companyId: string, activityId: string, values: any) => {
    dispatch({ type: ActionType.DATA_LOADER, payload: true });
    try {
      await putActivity(companyId, activityId, values);

      globalDispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:updatedtask'),
        },
      });
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:updatetaskfailed'),
        },
      });
    } finally {
      fetchTasks();
      dispatch({ type: ActionType.DATA_LOADER, payload: false });
    }
  };

  const handleStatusChange = async (value: string, selectedTask: any) => {
    const taskValues = {
      content: selectedTask?.content,
      type: ACTIVITY_TYPE.TASK,
      dateCreated: new Date().toISOString(),
      task: {
        ...selectedTask,
        taskStatus: value,
      },
    };

    await updateTaskStatus(selectedTask.companyId, selectedTask.id, taskValues);
  };

  const goToTask = (id: string) => {
    if (!id) return;
    const selectedTask = state?.items?.find((task: FormFieldPropType) => {
      return task.id === id;
    });

    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        modalContext: TaskModalEnum.EDIT,
        selectedTask: selectedTask,
      },
    });
  };

  const getFilterQueries = () => {
    const selectedFilters: { [key: string]: any } = Object.entries(state.filter).reduce(
      (acc, [key, val]) => {
        if (val) {
          return { ...acc, [key]: val };
        }
        return acc;
      },
      {}
    );

    let userFilterQuery = {} as any;

    if (doneToggle) {
      userFilterQuery.HideDoneTasks = doneToggle;
    }

    if (searchedTask && searchedTask.length) {
      userFilterQuery.Search = searchedTask;
    }

    return {
      ...selectedFilters,
      ...(state.selectedFilter.param !== AssignedFilterEnum.ALL
        ? { [AssignedFilterEnum.ALL]: false }
        : {}),
      [state.selectedFilter.param]: state.selectedFilter.value,
      ...((state.companyName && { search: state.companyName.trim() }) || {}),
      ...userFilterQuery,
      ActivityTypes: 'Task',
      AssignedToMe: assignmentFilter.assignedToMe,
      AssignedToOthers: assignmentFilter.assignedToOthers,
      pageSize: state.filter.pageSize,
      pageNumber: paginationHook.currentPage,
      OrderDirection: 'Ascending',
    };
  };

  const fetchTasks = async () => {
    dispatch({ type: ActionType.DATA_LOADER, payload: true });

    try {
      const filterQuery = getFilterQueries();
      const {
        data: { results, paging },
      } = await getActivities(filterQuery);

      paginationHook.setTotalCount(paging.totalCount);

      dispatch({
        type: ActionType.SET_ITEMS,
        payload: {
          items: results,
          field: field(
            [...results],
            {
              sortBy: state.filter.orderBy,
              direction: state.filter.direction,
            },
            handleOnHeaderSort,
            handleStatusChange
          ),
        },
      });

      dispatch({ type: ActionType.DATA_LOADER, payload: false });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: ActionType.DATA_LOADER, payload: false });
    }
  };

  useEffect(() => {
    const { sortBy, direction } = orderDirection;
    if (!sortBy || !state.items.length) {
      return;
    }
    const sortedData =
      (direction === 'asc' &&
        state.items.sort((a: any, b: any) => {
          return a[sortBy] > b[sortBy] ? -1 : 1;
        })) ||
      state.items.sort((a: any, b: any) => {
        return a[sortBy] < b[sortBy] ? -1 : 1;
      });

    dispatch({
      type: ActionType.SET_ITEMS,
      payload: {
        items: sortedData,
        field: field(
          [...sortedData],
          {
            sortBy: state.filter.orderBy,
            direction: state.filter.direction,
          },
          handleOnHeaderSort,
          handleStatusChange
        ),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDirection.direction, orderDirection.sortBy]);

  useEffect(() => {
    // Do not apply filter on initial render
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const amplitudeFilterLabels: Record<AssignedFilterEnum, string> = {
      [AssignedFilterEnum.ALL]: 'All',
      [AssignedFilterEnum.ASSIGNEDTOME]: 'Assigned to Me',
      [AssignedFilterEnum.ASSIGNEDTOOTHERS]: 'Assigned to Others',
    };

    sendAmplitudeData(AmplitudeTrackingEnum.taskmanagementfilter, {
      filterType: amplitudeFilterLabels[filterBy],
    });

    setAssignmentFilter({
      assignedToMe: filterBy === AssignedFilterEnum.ASSIGNEDTOME,
      assignedToOthers: filterBy === AssignedFilterEnum.ASSIGNEDTOOTHERS,
    });
  }, [filterBy]);

  useEffect(() => {
    // Do not fetch tasks when add or edit modal is open
    if (appState.tasks?.modalContext !== null) {
      return;
    }

    fetchTasks();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    doneToggle,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    appState.tasks?.modalContext === null,
    state.searchBy,
    assignmentFilter,
    state.filter,
    paginationHook.currentPage,
  ]);

  useEffect(() => {
    sendAmplitudeData(AmplitudeTrackingEnum.taskssidebar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setSearchedTask(event.target.value);
  };

  return (
    <div className={cn('wrapper', styles.tasks)} data-testid="tasks-page">
      <div className={cn(styles['header-filters-container'])}>
        <h2 className={cn('sw-h2')}>
          {t('home:tasks:title')}({state.field?.items?.length})
        </h2>
        <div className={cn(styles['task-filters-container'])}>
          <div className={cn(styles['filter-buttons-container'])}>
            {assignedFilters.map((option) => {
              return (
                <Button
                  key={option.value}
                  className={cn(
                    styles['filter-button'],
                    filterBy === option.value && styles['active-filter-button']
                  )}
                  ariaLabel={option.label}
                  id={option.value}
                  clickHandler={() => setFilterBy(option.value)}
                >
                  {option.label}
                </Button>
              );
            })}
          </div>
          <TextInput
            {...{
              metadata: {
                id: 'search-tasks',
                placeholder: t('home:tasks:searchtask'),
                fieldType: FieldTypes.TEXT,
                value: searchedTask,
                trailingIcon: 'search',
                classNames: {
                  control: styles['search-task-input'],
                },
              },
              errors: [],
              handleChange: handleSearchTask,
            }}
            ref={searchInputRef}
          />
          <div className={cn(styles.toggle)}>
            <Switch
              {...{
                id: 'toggle-only-active',
                onChange: () => {
                  setDoneToggle(!doneToggle);
                },
                defaultChecked: doneToggle,
                label: t('home:tasks:toggledone'),
                name: 'toggle-only-active',
              }}
            />
          </div>
        </div>
      </div>

      <Table
        table-container={styles['table-container']}
        {...state.field}
        loading={state.loading}
        rowCallBack={goToTask}
      />
      {!state.loading && (
        <Pagination
          {...{
            ...paginationHook,
            setGlobalPaginationState,
            handlePageSize,
            pageSize: state.filter.pageSize,
          }}
        />
      )}
    </div>
  );
};

export default Tasks;
