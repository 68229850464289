import { createContext, useContext, useReducer } from 'react';

import { initialState as initialSystemState } from './system/reducers';
import { initialState as initialToastState } from './toast/reducers';
import { AppStateType, IContext } from './types';
import { initialCompanyState } from './company/reducers';
import { initialProductState } from './products/reducers';
import { appReducers } from './reducer';
import { SystemState } from './system/types';
import { LSKeys } from '_shared/utils/constants';
import { initialTaskState } from './tasks/reducers';
import { initialDealsState } from './deals/reducers';

const loadState = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState === null) return undefined;

    return JSON.parse(serializedState);
  } catch (e) {
    console.error(e);
    return initialAppState;
  }
};

const clearState = (key: string) => {
  try {
    localStorage.removeItem(key);

    return null;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const saveState = (state: SystemState) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem('sw-bp-state', serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getPersistedState = (): SystemState => loadState(LSKeys.swoopAnalytics);

const initialAppState: AppStateType = {
  system: {
    ...initialSystemState,
    ...getPersistedState(),
  },
  toast: initialToastState,
  company: initialCompanyState,
  deals: initialDealsState,
  tasks: initialTaskState,
  product: initialProductState,
};

const Context = createContext<IContext>({ state: initialAppState, dispatch: () => {} });

const AppStateProvider = ({ reducer, initialState = initialAppState, children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={{ state, dispatch } as IContext}>{children}</Context.Provider>;
};

const useAppState = () => {
  return useContext(Context);
};

export { AppStateProvider, appReducers, useAppState, loadState, clearState, saveState };
