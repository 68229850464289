import React from 'react';
import classNames from 'classnames';
import styles from './historyButton.module.scss';
import { HistoricalData } from 'pages/companyOverview/types';

const HistoryButton: React.FC<HistoricalData> = (props: HistoricalData) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.callback();
  };
  return (
    <button type="button" className={classNames(styles.historybutton)} onClick={handleOnClick}>
      <span
        className={classNames(styles['history-icon'], styles['tooltip'])}
        data-label={props.tooltip}
      >
        <i className={classNames('material-icons')}>history</i>
      </span>
    </button>
  );
};

export default HistoryButton;
