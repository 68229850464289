export enum ActiveTabs {
  LOANS = '77e891be-2e22-4208-b029-3267179487a7',
  DYNAMIC = 'Dynamic',
}

export type EquityRequirementsType = {
  companyId: string;
  fundingRequired: number;
  businessSummary: string;
  turnoverLastYear: number;
  sectors: Array<string>;
  region: string;
  businessModels: Array<string>;
  approvedScheme: string;
  website: string;
  fundingRaisedPreviouslyAmount: number;
  applicationAdviceRequested: boolean;
  isDataComplete: boolean;
  fundingRaisedPreviously: boolean;
  pageMetadata: EquityPageMetadata;
  hasPitchDeck?: boolean;
  monthlyRecurringRevenue: number;
};

type EquityPageMetadata = {
  approvedSchemes: Array<RequirmentsMetadataField>;
  sectors: Array<RequirmentsMetadataField>;
  regions: Array<RequirmentsMetadataField>;
  businessModels: Array<RequirmentsMetadataField>;
};

export type DebtRequirementsType = {
  companyId: string;
  turnoverLastYear: number;
  fundingRequired: number;
  cardPaymentAverageMonthlyRevenue: number;
  profitableLastYear: boolean;
  invoiceAverageMonthlyRevenue: number;
  fundingRequiredTimescale: string;
  isHomeowner: boolean;
  currentlyBank: string;
  fundingPurposes: Array<string>;
  fundingPurposeSummary: string;
  sectors: Array<string>;
  firstCommercialSale: number;
  monthlyRecurringRevenue: number;
  isDataComplete: boolean;
  cardPaymentsAccepted: boolean;
  invoicesSent: boolean;
  isTrading: boolean;
  pageMetadata: DebtPageMetadata;
};

type DebtPageMetadata = {
  fundingPurposes: Array<RequirmentsMetadataField>;
  sectors: Array<RequirmentsMetadataField>;
  fundingRequiredTimescales: Array<RequirmentsMetadataField>;
};

export type GrantRequirementsType = {
  companyId: string;
  isDataComplete: boolean;
  registeredAddress: AddressType;
  tradingAddressIsDifferent: boolean;
  tradingAddress: AddressType;
  fundingRequired: number;
  turnoverLastYear: number;
  sectors: Array<string>;
  fundingPurposes: Array<string>;
  fundingPurposeSummary: string;
  isTrading: boolean;
  firstCommercialSale: number;
  pageMetadata: GrantPageMetatdata;
};

export type GoalsValuesType = {
  formFieldId: string;
  value: unknown;
};

type GrantPageMetatdata = {
  sectors: Array<RequirmentsMetadataField>;
  fundingPurposes: Array<RequirmentsMetadataField>;
  countries: Array<RequirmentsMetadataField>;
};

type AddressType = {
  line?: string;
  city?: string;
  country?: string;
  postcode?: string;
  state?: string;
};

export type RequirmentsMetadataField = {
  value: string;
  label: string;
};
