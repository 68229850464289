import React, { useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';
import {
  applicationFormReducer,
  ApplicationFormContext,
  applicationFormInitialState,
} from './store/companyApplicationForm.reducer';

const ApplicationForm: React.FC = () => {
  const [state, dispatch] = useReducer(applicationFormReducer, applicationFormInitialState);

  return (
    <div className={cn('wrapper')}>
      <ApplicationFormContext.Provider value={{ state, dispatch }}>
        <Outlet />
      </ApplicationFormContext.Provider>
    </div>
  );
};

export default ApplicationForm;
