import React, { ReactElement } from 'react';
import styles from './TabList.module.scss';
import { TabProps } from '../Tab/Tab';

type TabListProps = {
  value: string;
  onChange: (value: string) => void;
  children: ReactElement<TabProps>[];
};

const TabList: React.FC<TabListProps> = ({ value, onChange, children }) => {
  return (
    <div role="tablist" className={styles['tablist-container']} data-testid="tabs">
      {children.map((child, index) => {
        if (React.isValidElement(child)) {
          const key = child.key ?? `tab-${index}`;
          return React.cloneElement(child, {
            key: key,
            isActive: child.props.value === value,
            onClick: () => onChange(child.props.value),
          });
        }
        return child;
      })}
    </div>
  );
};

export default TabList;
