import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CompanyActionType,
  CompanyDetailsContext,
} from 'pages/companyDetails/store/companyDetails.reducer';
import AccountDetails from './components/accountDetails';
import OverviewHook from './hooks/companyOverviewHook';
import FundingRequirements from 'pages/companyOverview/components/fundingRequirements';
import CompanyInfoCard from './components/companyInfoCard';
import FinancialSnapshotCard from './components/financialSnapshotCard';
import PeopleInfoCard from './components/peopleInfoCard';
import Accordion from 'components/accordion';
import Contacts from './components/contacts';
import CreditSafeReport from './components/creditSafeReport/creditSafeReport';
import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';
import Spinner from 'components/spinner';
import { RoutePath } from '_shared/routes';
import features from 'config/features';

const Overview: React.FC = () => {
  const { state, dispatch } = useContext(CompanyDetailsContext);
  const { id: companyId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const reactAppCountry: TCountry = env('REACT_APP_COUNTRY');

  const { localState, handleToggleOnlyCurrentDirectors, setShowCompanyOwnerModal } = OverviewHook({
    companyId,
    state,
  });

  useEffect(() => {
    if (state.completedFormsLength === undefined || !companyId) return;

    const navigationEntries = performance.getEntriesByType('navigation');
    const navigationEntry = (navigationEntries?.[0] as PerformanceNavigationTiming) ?? null;

    const shouldNavigateToEssentials =
      state.completedFormsLength > 0 && // If there are completed forms
      !state.hasNavigatedToEssentials && // And we haven't navigated to essentials yet
      navigationEntry !== null && // And we have a navigation entry...
      navigationEntry.type !== 'reload' && // ...and it's not a reload i.e. overview page hasn't been refreshed - if it has, we want to stay here
      features.essentialsEnabled; // And essentials feature flag is enabled - not currently in Canada or Australia as at 2024-11-11;

    if (shouldNavigateToEssentials) {
      navigate(RoutePath.companyessentials.replace(':id', companyId));
    } else {
      setIsLoading(false);
    }
  }, [state.completedFormsLength, state.hasNavigatedToEssentials, navigate, companyId]);

  return isLoading ? (
    <Spinner size="large" />
  ) : (
    <>
      <Accordion
        items={[
          {
            title: t('home:companydetails:fundingmatches:fundingrequirements:title'),
            content: <FundingRequirements companyId={companyId || ''} />,
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:info:title'),
            content: (
              <CompanyInfoCard
                {...{
                  companyId: companyId || '',
                  isLoading: state.isLoading,
                  error: state.company?.error,
                  fields: localState?.companyInfo?.fields,
                }}
              />
            ),
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:accountinfo:contact:title'),
            content: (
              <Contacts
                {...{
                  companyId: companyId || '',
                }}
              />
            ),
            collapsedByDefault: false,
          },
          {
            title: t('home:companydetails:overview:accountinfo:title'),
            content: (
              <AccountDetails
                {...{
                  companyId: companyId || '',
                }}
              />
            ),
            collapsedByDefault: false,
          },
          reactAppCountry === 'unitedkingdom' && {
            title: t('home:companydetails:overview:creditsafe:title'),
            content: <CreditSafeReport companyId={companyId || ''} />,
          },
          {
            title: t('home:companydetails:overview:financialsnapshot:title'),
            content: <FinancialSnapshotCard companyId={companyId || ''} />,
          },
          {
            title: t('home:companydetails:overview:people:title'),
            content: (
              <PeopleInfoCard
                {...{
                  peopleInfo: localState?.peopleInfo,
                  handleToggleOnlyCurrentDirectors,
                  setShowCompanyOwnerModal,
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default Overview;
