import React from 'react';
import styles from './SidebarSlideOut.module.scss';
import cn from 'classnames';

type SidebarSlideOutProps = {
  children: React.ReactNode;
  className?: string;
};

const SidebarSlideOut = React.forwardRef<HTMLDivElement, SidebarSlideOutProps>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={cn(styles['slide-out'], className)}>
        {children}
      </div>
    );
  }
);

export default SidebarSlideOut;
