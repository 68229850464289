import { FieldPosition, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { convertServerDataTypeToFieldType } from '_shared/utils';
import {
  activitiesToFund,
  businessModelsDic,
  monthsDic,
  residentialStatus,
  sectorsDic,
  titleOptionsDic,
} from '_shared/utils/constants';
import { ApplicationFieldValueDetailsType, ApplicationFormFieldsMetadataType } from '../types';
import styles from 'pages/companyApplicationForm/assets/editApplicationForm.module.scss';
import { convertToISODate } from '_shared/utils/date';
import { currencyIcon } from '_shared/utils/currency';

const readOnlyFields = ['Company.IncorporationCountry', 'Company.AccountOwnerEmail'];

const fieldsWithValidation = [
  'Company.RegisteredName',
  'Company.RegistrationNumber',
  'Company.RegisteredAddressLine1',
  'Company.RegisteredAddressCity',
  'Company.RegisteredAddressCountry',
  'Company.RegisteredAddressPostcode',
  'Company.TradingAddressLine1',
  'Company.TradingAddressCity',
  'Company.TradingAddressCountry',
  'Company.TradingAddressPostCode',
];

const getFieldSectionCount = (fields: Array<ApplicationFieldValueDetailsType>): number => {
  const filledFields =
    fields?.length &&
    fields.reduce((total: number, current: ApplicationFieldValueDetailsType) => {
      return total + ((current.value && 1) || 0);
    }, 0);

  return filledFields;
};

const parseValue = (value: string, dataType: string, isReadonly: boolean = false) => {
  if (isReadonly && dataType === 'StringArray') {
    const stringArrayValue = JSON.parse(value);
    return stringArrayValue.join(', ');
  } else if (dataType === 'Date') {
    return (value && value.substring(0, 10)) || new Date().toISOString().substring(0, 10);
  }

  return value;
};

const getFieldOptions = (dataType: any, value: any, idName: any, name: string, isReadonly: any) => {
  const standardFields = {
    fieldType: convertServerDataTypeToFieldType(dataType),
    type:
      ((dataType === 'Decimal' || dataType === 'Integer') && 'number') ||
      (dataType === 'Date' && 'date') ||
      'TEXT',
    leadingIcon: dataType === 'Decimal' && currencyIcon(),
    value: (value && parseValue(value, dataType, isReadonly)) || '',
  };

  if (isReadonly && readOnlyFields.includes(name)) {
    return {
      ...standardFields,
      disabled: true,
    };
  }

  if (name === 'Contact.Title') {
    return {
      options: [...titleOptionsDic],
      ...standardFields,
      fieldType: FieldTypes.SELECT,
      value: value && parseValue(value, 'Select', false),
      placeholder: 'Select a title',
      required: true,
    };
  } else if (name === 'Company.SectorsClassification') {
    const newValue = (value && JSON.parse(value)) || [];
    return {
      options: [...sectorsDic],
      ...standardFields,
      value: (newValue?.length && parseValue(newValue, dataType, false)) || [],
    };
  } else if (name === 'Company.BusinessModel') {
    const newValue = (value && JSON.parse(value)) || [];
    return {
      options: [...businessModelsDic],
      ...standardFields,
      value: (newValue?.length && parseValue(newValue, dataType, false)) || [],
    };
  } else if (name === 'Opportunity.FundingPurposes') {
    const newValue = (value && JSON.parse(value)) || [];
    return {
      options: [...activitiesToFund],
      ...standardFields,
      value: (newValue?.length && parseValue(newValue, dataType, false)) || [],
    };
  } else if (name === 'ContactAddress.ResidentialStatus') {
    return {
      options: [...residentialStatus],
      ...standardFields,
      fieldType: FieldTypes.SELECT,
      value: value,
    };
  } else if (name === 'Company.FirstCommercialSaleYear') {
    return {
      ...standardFields,
      max: new Date().getFullYear(),
      validationtype: ValidationTypes.NUMERIC,
    };
  } else if (name === 'Company.FirstCommercialSaleMonth') {
    return {
      options: [
        ...monthsDic.map((month, index: number) => {
          return {
            ...month,
            value: index + 1,
          };
        }),
      ],
      ...standardFields,
      fieldType: FieldTypes.SELECT,
      value: value,
    };
  } else if (dataType === 'Date') {
    return {
      ...standardFields,
      max: convertToISODate(new Date()),
    };
  }

  if (dataType === 'Bool') {
    return {
      ...standardFields,
      selectionKey: value,
      options: [
        { name: 'true', value: 'true', label: 'Yes', parentId: idName },
        { name: 'false', value: 'false', label: 'No', parentId: idName },
      ],
      type: 'radio',
    };
  }

  return {
    ...standardFields,
  };
};

const customDisplayName = (name: string, displayName: string) => {
  if (name.includes('RegisteredAddress')) {
    return `${displayName} (Registered)`;
  } else if (name.includes('TradingAddress')) {
    return `${displayName} (Trading)`;
  } else {
    return displayName;
  }
};

const setValidationFields = (name: string, dataType: string) => {
  if (fieldsWithValidation.includes(name)) {
    return {
      validationtype: ValidationTypes.TEXT,
      required: true,
    };
  } else if (dataType === 'Date') {
    return {
      validationtype: ValidationTypes.DATE,
    };
  }
};

export const editApplicationFormfields = (
  data: Array<any>
): Array<ApplicationFormFieldsMetadataType> => {
  return data.map((section: any) => {
    const id = `${section.sectionId}_${section.rootId}_${section.childId}`;
    return {
      id,
      name: section.displayName,
      rootId: section?.rootId,
      childId: section?.childId,
      count: getFieldSectionCount(section.fieldValueDetails),
      displayOrder: section.displayOrder,
      postion: FieldPosition.GROUPLEFT,
      totalCount: section.totalNumberOfSectionItems,
      subHeading: section.subHeading,
      label: section.displayName,
      children: section.fieldValueDetails
        .filter((item: any) => !item.displayName.includes('ID') && item.displayName !== 'Applicant')
        .map((fieldValues: ApplicationFieldValueDetailsType) => {
          let { name, displayName, value, isReadonly, dataType } = fieldValues;
          const idName = `${id}*${name}`;
          return {
            id: idName,
            name: idName,
            label: customDisplayName(name, displayName),
            isReadonly,
            ...getFieldOptions(dataType, value, idName, name, isReadonly),
            ...setValidationFields(name, dataType),
            classNames: {
              field: dataType === 'TextBox' && styles['field-full'],
            },
          };
        }),
    };
  });
};
