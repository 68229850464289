import cn from 'classnames';
import styles from './deleteActivity.module.scss';
import Modal from 'components/modal';
import { DeleteActivityPropTypes } from '../types';
import Activity from '../activity';
import { useTranslation } from 'react-i18next';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';

const DeleteActivity = ({
  activity,
  closeDeleteModal,
  handleConfirmDelete,
  loading,
}: DeleteActivityPropTypes) => {
  const { t } = useTranslation();
  return (
    <Modal show={Boolean(activity)} handleClose={closeDeleteModal}>
      <div data-testid="delete-activity" className={cn(styles['activity-deletion-confirm-modal'])}>
        <h3>{t('home:customlinkmodal:title')}</h3>
        <p className={cn(styles['warning'])}>
          {t('home:companydetails:activities:delete:confirmdelete', {
            activityType: spaceBetweenTitleCaseWords(activity.activityType).toLocaleLowerCase(),
          })}
        </p>
        <Activity
          key={activity.id}
          {...{
            activity,
          }}
        />
        <div className={cn(styles['actions'])}>
          <Button
            id="delete-modal-cancel"
            ariaLabel="delete-modal-cancel"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={closeDeleteModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            id="delete-button"
            ariaLabel="delete-button"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            className={cn(styles['delete'])}
            tabIndex={0}
            clickHandler={handleConfirmDelete}
            loading={loading}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteActivity;
