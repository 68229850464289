import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { CustomDropdownStyle, CustomSelectType } from './type';
import styles from 'components/field/customSelect/customSelect.module.scss';
import useBlur from '_shared/hooks/useBlur';
import { createPortal } from 'react-dom';
import { mergeRefs } from '_shared/utils/ref';

const CustomSelect = React.forwardRef<HTMLDivElement, CustomSelectType>(
  (
    { metadata, errors, handleChange, waitForConfirmation, value, elementForPositionCheck },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState('');
    const [label, setLabel] = useState('');
    const [isListOpened, setIsListOpened] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    const [dropdownStyle, setDropdownStyle] = useState<CustomDropdownStyle>({
      left: 'auto',
      top: 'auto',
      minWidth: 'auto',
      maxHeight: 'auto',
    });
    useBlur([selectRef, dropdownRef], () => setIsListOpened(false));

    const handleNewValue = (option: any) => {
      if (!waitForConfirmation) {
        setCurrentValue(option.value);
        setLabel(option.label);
      }
    };

    const closeListOnResize = useCallback(() => {
      setIsListOpened(false);
    }, [setIsListOpened]);

    const closeListOnScroll = useCallback(
      (event: Event) => {
        if (!dropdownRef.current?.contains(event.target as Node)) {
          setIsListOpened(false);
        }
      },
      [dropdownRef, setIsListOpened]
    );

    useEffect(() => {
      if (isListOpened) {
        // Close dropdown when resizing in order to force the recalculation of dropdown position and size
        window.addEventListener('resize', closeListOnResize);
        // Close dropdown when scrolling in order to force the recalculation of dropdown position and size
        window.addEventListener('scroll', closeListOnScroll, true);
      } else {
        window.removeEventListener('resize', closeListOnResize);
        window.removeEventListener('scroll', closeListOnScroll, true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isListOpened]);

    useEffect(() => {
      if (value && metadata.options) {
        const currentSelected = metadata.options.filter(
          (option: { label: string; value: string }) => option.value === value
        );

        setCurrentValue(value);

        setLabel(!value?.length ? '' : currentSelected[0].label);
      } else {
        setLabel(metadata?.placeholder || '');
        setCurrentValue((metadata.options && metadata?.options[0]?.value) || '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
      if (isListOpened) {
        const selectEleRect = selectRef.current?.getBoundingClientRect();
        const minWidth = selectEleRect?.width ?? 'auto';
        const left = selectEleRect?.x ?? 'auto';
        const top = selectEleRect ? selectEleRect.y + selectEleRect.height : 'auto';
        const maxHeight = Math.min(
          (selectEleRect ? window.innerHeight - selectEleRect.y - selectEleRect.height : 0) - 10, // Add padding between bottom edge of the screen and the dropdown
          300
        );

        setDropdownStyle({
          minWidth,
          left,
          top,
          maxHeight,
        });
      }
    }, [selectRef, ref, isListOpened]);

    const dropdownContent = isListOpened ? (
      <ul
        ref={dropdownRef}
        id={`${metadata.id}-list`}
        className={classNames(styles['custom-select-list'])}
        style={dropdownStyle}
        data-testid={`${metadata.id}-test-id-list`}
      >
        {(metadata.options || []).map((option: any, index: number) => (
          <li
            key={`${option.value}-main-list`}
            className={classNames(
              metadata.disableFirstItem && index === 0 ? styles.disabled : styles.enabled
            )}
          >
            <label
              id={metadata.id}
              data-testid={`element-label-${option.value}-${metadata.id}`}
              htmlFor={`${option.value}-main-list`}
              className={classNames(
                styles[
                  currentValue === option.value || (index === 0 && !currentValue)
                    ? 'selected'
                    : 'custom-select-option'
                ]
              )}
              aria-selected={currentValue === option.value}
              onClick={(event: any) => {
                if (metadata.disableFirstItem && index === 0) return;

                handleNewValue(option);

                event.target.value = option.value;
                handleChange(event);
              }}
            >
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    ) : null;

    return (
      <div
        ref={mergeRefs(ref, selectRef)}
        id={metadata.id}
        className={classNames(
          styles[metadata.classNames?.control || 'custom-select'],
          metadata.classNames?.borderRadius,
          metadata.disabled && styles['select-disabled']
        )}
        aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
        aria-describedby={`error-${metadata.name}`}
        data-testid={`${metadata.id}-test-id-main`}
        tabIndex={metadata.tabIndex}
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
          event.stopPropagation();
          if (metadata.disabled) return;
          setIsListOpened((oldState) => !oldState);
        }}
      >
        <div
          id={`${metadata.id}-current`}
          className={classNames(
            styles[isListOpened ? 'custom-select-current-active' : 'custom-select-current']
          )}
        >
          {(metadata.options || []).map((option: any, index: number) => (
            <div
              className={classNames(styles['custom-select-value'])}
              key={`${metadata.id}-${option.value}-list`}
            >
              <input
                className={classNames(styles['custom-select-input'])}
                type="checkbox"
                id={`${option[option.value]}-${metadata.id}`}
                value={option[option.value]}
                name="select-item"
                checked={index === 0}
                readOnly
              />
              <span
                className={classNames(
                  styles['custom-select-input-text'],
                  metadata.disabled && styles['select-disabled']
                )}
              >
                {label || (metadata.options && metadata.options[0].label)}
              </span>
            </div>
          ))}

          <span className={classNames(styles['custom-select-icon'])} />
        </div>
        {createPortal(dropdownContent, document.body)}
      </div>
    );
  }
);

export default CustomSelect;
