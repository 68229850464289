import { BrokerAPI } from '_shared/api';
import { BrokerAPIURL } from '_shared/url';

export const getFundingMatches = (id: string) => BrokerAPI.get(BrokerAPIURL.getFundingMatches(id));

export const getFundingRequirements = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFundingRequirements(id));

export const getFundingMatchesEquity = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.fundingMatchesEquity(id));

export const getFundingMatchesDebt = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.fundingMatchesDebt(id));

export const getFundingMatchesGrant = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.fundingMatchesGrant(id));

export const getFundingForms = () => BrokerAPI.get(BrokerAPIURL.getFundingForms);
export const getCompletedFundingForms = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCompletedFundingForms(companyId));
export const getFundingForm = (formId: string, companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.fundingForm(formId, companyId));
export const getFundingFormV2 = (formId: string, companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.fundingFormV2(formId, companyId));

export const putFundingMatchesLoan = (companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.fundingMatchesDebt(companyId), data);
export const putFundingMatchesEquity = (companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.fundingMatchesEquity(companyId), data);
export const putFundingMatchesGrant = (companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.fundingMatchesGrant(companyId), data);
export const putFundingForm = (formId: string, companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.fundingForm(formId, companyId), data);
export const putFundingFormV2 = (formId: string, companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.fundingFormV2(formId, companyId), data);
export const putDynamicFundingFormFields = (companyId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.dynamicFundingFormFields(companyId), data);
export const deleteDynamicFundingForm = (formId: string, companyId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteDynamicFundingForm(formId, companyId));

export const getFundingFormOptions = () => BrokerAPI.get(BrokerAPIURL.getFundingFormOptions());

export const getFundingMatchesByType = (companyId: string, type: string) =>
  BrokerAPI.get(BrokerAPIURL.getFundingMatchesByType(companyId, type));

export const getMatchesByType = (companyId: string, type: string) =>
  BrokerAPI.get(BrokerAPIURL.getMatchesByType(companyId, type));

export const getInsightsByType = (companyId: string, type: string) =>
  BrokerAPI.get(BrokerAPIURL.getInsightsByType(companyId, type));

export const updateInsight = (url: string) => BrokerAPI.put(BrokerAPIURL.updateInsight(url));

export const patchFundingRequirements = (
  id: string,
  data: Array<{ op: string; value: string; path: string; from?: string }>
) => BrokerAPI.patch(BrokerAPIURL.getFundingRequirements(id), data);
