import React, { useReducer, useEffect, useState, useCallback } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppState } from 'store';
import { IContext } from 'store/types';
import { UPDATE_COMPANY } from 'store/company/types';
import { deleteCompany, getCompanies, QueryKeys } from '_shared/api/businesses';
import usePagination from '_shared/hooks/usePagination';
import styles from 'pages/companies/assets/companies.module.scss';
import { field } from 'pages/companies/fields/companies.field';
import { companiesReducer, ActionType } from 'pages/companies/store/companies.reducer';
import {
  availableFilters,
  FiterParams,
  SearchByOptions,
} from 'pages/companies/components/filters/types';
import CompaniesFilters from 'pages/companies/components/filters';
import Table from 'components/table';
import Pagination from 'components/pagination';
import { RoutePath } from '_shared/routes';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { isAdvisorApplication, isBrokerApplication, appSpecific } from '_shared/utils/application';
import { TOAST_MESSAGE } from 'store/toast/types';
import { CompanyPropsType } from 'pages/companyOverview/types';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import Modal from 'components/modal';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { deleteCompanyFields } from 'components/deleteModal/deleteModal.field';
import features from 'config/features';
import { getCompletedFundingForms, getFundingForms } from '_shared/api/fundingMatches';
import { allowedEssentialsFormNames } from 'pages/companyEssentials/formComponentConfig';
import { FundingFormType } from 'pages/companyEssentials/types';

const Companies: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const [state, dispatch] = useReducer(companiesReducer, {
    redirectToReferrer: false,
    loading: false,
    isSearching: false,
    from: location.state || { from: { pathname: '/' } },
    field: field([], () => {}),
    pageNumber: globalState.company.pageNumber || 1,
    items: [],
    availableFilters: [],
    selectedFilter: globalState.company.selectedFilters || availableFilters[0],
    companyName: globalState.company.companyIndexState || '',
    searchBy: globalState.company.searchBy || 'CompanyName',
    filter: {
      orderBy:
        globalState.company.filter.orderBy ||
        appSpecific('fundingManagerLastAssignmentDate desc', 'accountOwnerLastAssignmentDate desc'),
      direction: globalState.company.filter.direction || 'desc',
      dateCreated: '',
      pageSize: Number(globalState?.company?.filter?.pageSize) || 20,
    },
  });
  const { t } = useTranslation();
  const paginationHook = usePagination(state.filter.pageSize, state.pageNumber);
  const [itemToDelete, setItemToDelete] = useState<CompanyPropsType | null>(null);
  const [deleteModalTable, setDeleteModalTable] = useState<any>(deleteCompanyFields([]));
  const [dispatchingData, setDispatchingData] = useState(true);

  const setGlobalPaginationState = (pageNumber: number) => {
    globalDispatch({
      type: UPDATE_COMPANY,
      payload: {
        ...globalState.company,
        pageNumber: pageNumber,
      },
    });
  };

  const [fundingForms, setFundingForms] = useState<FundingFormType[]>([]);

  const getFilterQueries = () => {
    const selectedFilters: { [key: string]: any } = Object.entries(state.filter).reduce(
      (acc, [key, val]) => {
        if (val) {
          return { ...acc, [key]: val };
        }
        return acc;
      },
      {}
    );

    let userFilterQuery = {};
    if (isBrokerApplication && globalState.company.selectedUserIds?.length) {
      userFilterQuery = {
        brokerIds: globalState.company.selectedUserIds,
      };
    } else if (isAdvisorApplication && globalState.company.selectedUserIds?.length) {
      userFilterQuery = {
        advisorIds: globalState.company.selectedUserIds,
      };
    }

    return {
      ...selectedFilters,
      ...(state.selectedFilter.param !== FiterParams.ALL ? { [FiterParams.ALL]: false } : {}),
      [state.selectedFilter.param]: state.selectedFilter.value,
      ...((state.companyName && { search: state.companyName.trim() }) || {}),
      ...userFilterQuery,
      pageNumber: paginationHook.currentPage,
      searchBy: state.searchBy,
    };
  };

  const getAccountOwnerDetails = (results: any[] = []) => {
    return (id: string) => {
      const company = results.find((results: any) => results.id === id) || null;

      return (
        <>
          {(company?.accountOwnerName && (
            <div className={cn(styles['account-manager-container'])}>
              {company.accountOwnerImageUrl && (
                <img src={company.accountOwnerImageUrl} alt="profile" />
              )}
              <span>{company?.accountOwnerName}</span>
            </div>
          )) ||
            'Unassigned'}
        </>
      );
    };
  };

  const getFundingManagerDetails = (results: any[] = []) => {
    return (id: string) => {
      const company = results.find((results: any) => results.id === id) || null;
      return (
        <>
          {(company?.fundingManagerName && (
            <div className={cn(styles['account-manager-container'])}>
              {company.fundingManagerImageUrl && (
                <img src={company.fundingManagerImageUrl} alt="profile" />
              )}
              <span>{company?.fundingManagerName}</span>
            </div>
          )) ||
            'Unassigned'}
        </>
      );
    };
  };

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetCompanies, getFilterQueries()],
    queryFn: ({ queryKey }) => getCompanies(queryKey[1]),
  });

  useEffect(() => {
    const fetchFundingForms = async () => {
      try {
        const fundingFormResult = await getFundingForms();
        setFundingForms(fundingFormResult.data);
      } catch (error) {
        console.error('Error fetching funding forms:', error);
      }
    };

    fetchFundingForms();
  }, []);

  useEffect(() => {
    if (!data || fundingForms.length === 0) return;

    setDispatchingData(true);

    const { paging, results } = data.data;
    const filterQuery = getFilterQueries();

    paginationHook.setTotalCount(paging.totalCount);

    dispatch({
      type: ActionType.SET_ITEMS,
      payload: {
        items: results,
        field: field(
          [...results],
          handleOnHeaderSort,
          {
            order: filterQuery.orderBy,
            direction: filterQuery.direction,
          },
          getFundingManagerDetails(results),
          getAccountOwnerDetails(results),
          setItemToDelete
        ),
      },
    });
    setDispatchingData(false);
  }, [data, fundingForms]);

  const onSearch = useCallback(
    (search: string, searchBy: SearchByOptions) => {
      paginationHook.setCurrentPage(1);
      dispatch({
        type: ActionType.SET_SEARCH_CRITERIA,
        payload: {
          ...state,
          search,
          searchBy,
        },
      });
      globalDispatch({
        type: UPDATE_COMPANY,
        payload: {
          ...globalState.company,
          companyName: search,
          searchBy,
        },
      });
    },
    [paginationHook, dispatch, globalDispatch, state, globalState]
  );

  useEffect(() => {
    if (!itemToDelete) return;

    const modalTable = deleteCompanyFields([itemToDelete]) || [];
    setDeleteModalTable(modalTable);
  }, [itemToDelete]);

  const goToCompany = useCallback(
    async (id: string) => {
      if (!id) return;

      const companyData = state.items.find((item: any) => {
        return item.id === id;
      });
      globalDispatch({
        type: UPDATE_COMPANY,
        payload: {
          ...globalState.company,
          companyData: companyData,
        },
      });

      const completedFormsLength = await fetchCompletedFormsLength(id);

      const routePath =
        features.essentialsEnabled && completedFormsLength > 0
          ? RoutePath.companyessentials.replace(':id', id)
          : RoutePath.companyoverview.replace(':id', id);

      navigate(routePath);

      sendAmplitudeData(AmplitudeTrackingEnum.viewcompanylink);
    },
    [navigate, globalDispatch, globalState.company, state.items]
  );

  const fetchCompletedFormsLength = async (id: string) => {
    try {
      const { data: completedFormsList } = await getCompletedFundingForms(id);

      if (!Array.isArray(completedFormsList)) {
        return 0;
      }

      if (completedFormsList.length === 0) return 0;

      const filteredForms = completedFormsList.filter((completedForm: any) => {
        const matchedForm = fundingForms.find(
          (fundingForm) => fundingForm.formId === completedForm.formId
        );
        const formName = matchedForm ? matchedForm.name : null;
        return formName && allowedEssentialsFormNames.includes(formName);
      });

      return filteredForms.length;
    } catch (error) {
      console.error('Error fetching completed forms:', error);
      return 0;
    }
  };

  const deleteSelectedCompany = useCallback(async () => {
    if (!itemToDelete?.id) return;

    try {
      await deleteCompany(itemToDelete.id);

      globalDispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:deletemodal:toast:clients:success') },
      });

      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });
    } catch (e) {
      console.error(e);
      dispatch({ type: ActionType.DATA_LOADER, payload: false });
    } finally {
      setItemToDelete(null);
    }
  }, [globalDispatch, t, itemToDelete?.id]);

  const resetItemToDelete = useCallback(() => {
    setItemToDelete(null);
  }, [setItemToDelete]);

  const handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: ActionType.SET_FILTERS,
      payload: {
        ...state.filter,
        pageSize: event.target.value,
      },
    });
  };

  const handleOnHeaderSort = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    event.preventDefault();
    const orderBy = event.currentTarget.getAttribute('data-name');
    const direction = state.filter.direction === 'desc' ? 'asc' : 'desc';
    dispatch({
      type: ActionType.SET_FILTERS,
      payload: {
        ...state.filter,
        orderBy: `${orderBy} ${direction}`,
        direction,
      },
    });
    globalDispatch({
      type: UPDATE_COMPANY,
      payload: {
        ...globalState.company,
        filter: {
          ...globalState.company.filter,
          orderBy: `${orderBy} ${direction}`,
          direction,
        },
      },
    });

    paginationHook.setCurrentPage(1);
  };

  if (state.redirectToReferrer) {
    return <Navigate to={state.from} />;
  }

  const isFetching = isLoading || dispatchingData;

  return (
    <div className={cn('wrapper', styles.companies)}>
      <CompaniesFilters
        {...{
          isSearching: isFetching,
          onSearch,
        }}
      />

      <Table
        table-container={styles['table-container']}
        {...state.field}
        loading={isFetching}
        rowCallBack={goToCompany}
        emptyMessage={
          <>
            <div>
              {!state.companyName
                ? t('home:companies:search:empty')
                : t('home:companies:search:emptysearch') +
                  spaceBetweenTitleCaseWords(state.searchBy) +
                  ' = ' +
                  state.companyName}
            </div>
            <br />
            <div
              className={cn('uploadWarning', styles['uploadWarning'])}
              hidden={Boolean(!state.companyName)}
            >
              <i className={cn('material-icons', styles['info-icon'])}>info</i>
              {t('home:companies:search:warning')}
            </div>
            <br />
            <div>{t('home:companies:search:add')}</div>
          </>
        }
      />

      <Pagination
        {...{
          ...paginationHook,
          setGlobalPaginationState,
          handlePageSize,
          pageSize: state.filter.pageSize,
        }}
      />
      <Modal show={Boolean(itemToDelete)} handleClose={resetItemToDelete}>
        <div className={cn(styles['company-deletion-confirm-modal'])}>
          <h3>{t('home:companydeletionmodal:title')}</h3>

          <p className={cn(styles['warning'])}>{t('home:companydeletionmodal:description')}</p>

          <Table {...deleteModalTable} />

          <div className={cn(styles['actions'])}>
            <Button
              id="delete-modal-cancel"
              ariaLabel="delete-modal-cancel"
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              tabIndex={0}
              clickHandler={resetItemToDelete}
            >
              {t('common:cancel')}
            </Button>
            <Button
              id="delete-modal-action"
              ariaLabel="delete-modal-action"
              buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
              className={cn(styles['delete'])}
              tabIndex={0}
              clickHandler={deleteSelectedCompany}
            >
              {t('common:delete')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Companies;
