import styles from './assets/users.module.scss';
import { FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';

const defaultCellStyles = {
  cell: styles['tr-cell'],
  value: styles['td-value'],
};

export type CurrentUserType = {
  userId: string;
  authId: string;
  groupIds: string[];
  picture: string;
  firstName: string;
  lastName: string;
  email: string;
  dateCreated: string;
  roles: string[];
};

export const userDetailFields = (item?: CurrentUserType): Array<FieldMetaDataPropType> => {
  return [
    {
      classNames: defaultCellStyles,
      value: item?.firstName || '',
      id: `${item?.userId}-firstName`,
      label: 'First Name',
      fieldType: FieldTypes.READONLY,
    },
    {
      classNames: defaultCellStyles,
      value: item?.lastName || '',
      id: `${item?.userId}-lastName`,
      label: 'Last Name',
      fieldType: FieldTypes.READONLY,
    },
    {
      classNames: defaultCellStyles,
      value: item?.email || '',
      id: `${item?.userId}-email`,
      label: 'Email',
      fieldType: FieldTypes.READONLY,
    },
    {
      id: `${item?.userId}-roles`,
      label: 'Assigned Roles',
      fieldType: FieldTypes.READONLY,
      classNames: defaultCellStyles,
      value: item?.roles.join(', ') ?? '',
    },
  ];
};
