export interface SwitchProps {
  id: string;
  label?: string;
  onChange?: ({ id, value }: { id: string; value: boolean }) => void;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  size?: SizeEnum;
  name: string;
  tooltip?: string;
}

export enum SizeEnum {
  SMALL = 'small',
  LARGE = 'large',
}
