import React from 'react';
import styles from './FormQuestion.module.scss';

export type FormQuestionProps = {
  id: string;
  children?: React.ReactNode;
  onAdd?: (id: string) => void;
};

const FormQuestion: React.FC<FormQuestionProps> = ({ children, id, onAdd }) => {
  return (
    <div className={styles['form-question']}>
      <span className={styles.title}>{children}</span>
      <button className={styles.button} onClick={() => onAdd?.(id)}>
        <i className="material-icons">add_circle</i>
      </button>
    </div>
  );
};

export default FormQuestion;
