import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '_shared/hooks/useMediaQuery';
import styles from './backbutton.module.scss';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.persist();
    navigate(-1);
  };
  return (
    <button type="button" className={classNames(styles.button)} onClick={handleOnClick}>
      <span className={classNames('material-icons', styles.icon)}>arrow_back</span>
      {!isMobile && <span className={classNames(styles.label)}>{t('common:goback')}</span>}
    </button>
  );
};

export default BackButton;
