import React, { useState, useRef, useEffect } from 'react';
import { TextformatType } from '_shared/fieldValidation/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useBlur from '_shared/hooks/useBlur';
import styles from './multiselect.module.scss';

function MultiSelect(
  {
    metadata,
    handleChange,
    errors,
  }: {
    metadata: any;
    handleChange: Function;
    errors: { [key: string]: any };
  },
  ref: React.Ref<any>
) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [multiSelectSearchValue, setnewMultiSelectSearchValue] = useState<string>('');

  const handleAddItem = (event: any) => {
    handleChange(event);
    setIsOpen(false);
    setnewMultiSelectSearchValue('');
  };

  const handleRemoveItem = (event: any) => {
    handleChange(event);
  };

  const disableRemoveItem = (value: string) => {
    if (metadata.disableRemove === value) return true;
    else return metadata.disabled;
  };

  const availableOptions = metadata.options?.filter(({ label }: { label: string }) => {
    return !metadata.value?.includes(label);
  });

  const toggleDropdown = () => {
    if (metadata.disabled) return;

    setIsOpen(!isOpen);
    setnewMultiSelectSearchValue('');
  };

  const handleMultiSelectSearch = (event: any) => {
    setnewMultiSelectSearchValue(event.target.value);
  };

  const isObject = (property: any) => typeof property === 'object' && property !== null;

  const stringifyDataOption = (option: any) => {
    if (isObject(option)) {
      return JSON.stringify(option);
    } else {
      return option;
    }
  };

  const getValue = (option: any) => {
    if (isObject(option)) {
      return option.value;
    }
    return (
      metadata.options.find((o: { value: any; label: string }) => o.value == option)?.value ||
      option
    );
  };

  const getLabel = (option: any) => {
    if (isObject(option)) {
      return option.label;
    }
    return (
      metadata.options.find((o: { value: any; label: string }) => o.value == option)?.label ||
      option
    );
  };

  const filteredResultsBasedOnSearch = !multiSelectSearchValue
    ? availableOptions
    : availableOptions.filter((option: any) =>
        option.label.toLowerCase().includes(multiSelectSearchValue.toLowerCase())
      );

  useEffect(() => {
    if (availableOptions?.length === 0) setIsOpen(false);
  }, [availableOptions?.length]);

  useEffect(() => {
    if (isOpen && searchRef) {
      searchRef.current?.focus();
    }
  }, [isOpen]);

  useBlur(dropdownRef, () => setIsOpen(false));

  return (
    <div className={styles['container']}>
      <div
        id={metadata.id}
        data-testid={metadata.id ? `test-element-wrapper-${metadata.id}` : 'multiselect-selected'}
        onClick={toggleDropdown}
        className={classNames(styles.selected, isOpen && styles.active, metadata.classNames?.value)}
        tabIndex={metadata.tabIndex}
        aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
        aria-describedby={`error-${metadata.name}`}
      >
        {(Array.isArray(metadata?.value) &&
          metadata.value?.map((option: any) => {
            let label = getLabel(option);
            let value = getValue(option);
            return (
              <span
                data-testid={`test-element-${metadata.id}-${label}`}
                key={`selected-${label}`}
                className={classNames(styles['selected-item'])}
              >
                {label}
                <button
                  key={`remove-${label}`}
                  data-testid={`test-element-${metadata.id}-${label}-remove`}
                  className={classNames('material-icons', styles['remove-item'])}
                  data-customfield={TextformatType.MULTISELECT}
                  data-action="remove"
                  data-option={stringifyDataOption(option)}
                  name={metadata.name}
                  disabled={disableRemoveItem(label)}
                  onClick={(e) => {
                    handleRemoveItem(e);
                    e.stopPropagation();
                  }}
                  value={value}
                >
                  clear
                </button>
              </span>
            );
          })) || <span>{t(metadata.placeholder)}</span>}
        <span className={classNames(styles[isOpen ? 'arrow-icon-up' : 'arrow-icon-down'])} />
      </div>
      <div
        id={`${metadata.id}-list`}
        ref={dropdownRef}
        data-testid="multiselect-options"
        className={classNames(styles.dropdown, isOpen && styles.active)}
      >
        <input
          autoFocus={isOpen}
          type="text"
          ref={searchRef}
          placeholder="Search..."
          value={multiSelectSearchValue}
          onChange={handleMultiSelectSearch}
          className={classNames(styles['multiselect-search'])}
        />
        {filteredResultsBasedOnSearch?.map((option: { value: string; label: string }) => {
          return (
            <button
              key={option.label + option.value}
              data-testid={`test-element-${metadata.id}-${option.label}-add`}
              data-customfield={TextformatType.MULTISELECT}
              data-action="add"
              data-option={stringifyDataOption(option)}
              onClick={handleAddItem}
              name={metadata.name}
              value={option.value}
              className={classNames(styles['dropdown-item'])}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default React.forwardRef(MultiSelect);
