import React, { useContext, useEffect, useReducer, useState } from 'react';
import cn from 'classnames';
import styles from './assets/productInsights.module.scss';
import { Action, productInsightsReducer } from './store/reducer';
import { newProductFields } from './fields/productInsights.fields';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import Field from 'components/field';
import { ProductDetailsContext } from 'pages/productDetails/store/productDetails.reducer';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import { TOAST_MESSAGE } from 'store/toast/types';
import { useAppState } from 'store';
import BlockNavigationModal from 'components/BlockNavigationModal';
import { UserRoles } from 'config/roles';
import { getProductInsights, putProductInsights } from '_shared/api/products';
import { ProductInsightsField } from './types';

const ProductInsights: React.FC = () => {
  const { state: contextState } = useContext(ProductDetailsContext);

  const initialState = {
    isSaving: false,
    isError: false,
    ableToSave: false,
    productCategory: contextState.product.type || '',
    subCategory: contextState.product.subcategory || '',
  };

  const [state, dispatch] = useReducer(productInsightsReducer, initialState);
  const [fields, setFields] = useState<any>([]);
  const [lastModified, setLastModified] = useState<string>('');
  const productId = contextState.product.productId;
  const { t } = useTranslation();
  const store = useAppState();

  const canEditProducts = store.state.system?.hasOneOfRoles?.([
    UserRoles.SUPER_ADMIN,
    UserRoles.MARKETPLACE_MANAGER,
  ]);

  const fetchProductInsights = async (productId: string) => {
    const { data } = await getProductInsights(productId);

    const lastModified: any = Object.values(data).reduce(
      (acc: any, curr: any) => {
        if (curr.modifiedDate && new Date(curr.modifiedDate) > new Date(acc.modifiedDate)) {
          acc.modifiedDate = curr.modifiedDate;
          acc.modifiedByName = curr.modifiedByName;
        }
        return acc;
      },
      { modifiedDate: data?.likes?.createdDate, modifiedByName: data?.likes?.createdByName } as {
        modifiedDate: string;
        modifiedByName: string;
      }
    );

    setLastModified(
      lastModified.modifiedDate
        ? `Last update: ${new Date(lastModified.modifiedDate).toLocaleDateString()} by ${
            lastModified.modifiedByName
          }`
        : ''
    );

    setFields(
      newProductFields(
        {
          tradingName: contextState?.product?.tradingName || contextState?.product?.providerName,
          likes: data?.likes?.value || '',
          dislikes: data?.dislikes?.value || '',
          applicationProcess: data?.applicationProcess?.value || '',
        },
        Boolean(canEditProducts)
      )
    );
  };

  useEffect(() => {
    if (!contextState?.product || !Object.keys(contextState?.product).length) {
      return;
    }
    fetchProductInsights(contextState.product.productId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.product]);

  const getFieldValues = (fieldName: string) => {
    return fields.find(({ name }: { name: string }) => name === fieldName)?.value;
  };

  const handleSaveChanges = async (changedValues: { [key: string]: any }) => {
    changedValues = {
      likesValue: getFieldValues(ProductInsightsField.likes),
      dislikesValue: getFieldValues(ProductInsightsField.dislikes),
      applicationProcessValue: getFieldValues(ProductInsightsField.applicationProcess),
      ...changedValues,
    };

    if (!productId || !state.ableToSave) return;
    dispatch({
      type: Action.SET_IS_SAVING,
      payload: true,
    });
    try {
      await putProductInsights(productId, changedValues);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:productdetails:insights:success') },
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: Action.SET_IS_SAVING,
        payload: false,
      });
      dispatch({
        type: Action.SET_ABLE_TO_SAVE,
        payload: false,
      });
    }
  };

  const { handleChange, handleSubmit, errors, metadata, values } = useForm(
    {},
    fields,
    handleSaveChanges,
    validation
  );

  useEffect(() => {
    if (!Object.keys(values).length) {
      dispatch({
        type: Action.SET_ABLE_TO_SAVE,
        payload: false,
      });
      return;
    }
    dispatch({
      type: Action.SET_ABLE_TO_SAVE,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div>
      <div>
        {(canEditProducts && (
          <div className={cn(styles['application-actions'])}>
            <p>{lastModified}</p>
            <button
              className={cn(styles['save-button'], { [styles.inactive]: !state.ableToSave })}
              onClick={handleSubmit}
              disabled={!state.ableToSave}
            >
              {(state.isSaving && <Spinner size="small" />) ||
                t('home:productdetails:insights:save')}
            </button>
          </div>
        )) ||
          null}
        <div className={cn(styles['product-insights'])} data-testid="product-insights">
          {(metadata?.length &&
            metadata?.map((metadataField) => {
              return (
                <Field
                  {...{
                    metadata: metadataField,
                    errors: errors,
                    handleChange: handleChange,
                    key: metadataField.id,
                    className: {
                      field: styles['field'],
                      control: styles['input-field'],
                    },
                  }}
                />
              );
            })) ||
            null}
        </div>
      </div>
      <BlockNavigationModal shouldBlock={state.ableToSave} />
    </div>
  );
};

export default ProductInsights;
