import { FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';
import { docTypeCategoryOptions, docTypes } from '_shared/utils/constants';
import { NewDocumentFieldEnum } from '../components/newDocument/types';
import styles from '../assets/newDocument.module.scss';

export const NewDocumentFields = ({
  category,
  title,
  fileName,
}: {
  category: string;
  title: string;
  fileName: string;
}): Array<FieldMetaDataPropType> => {
  const titleOptions = (category && [...docTypes[category]]) || [
    ...docTypes[Object.keys(docTypes)[0]],
  ];

  if (!NewDocumentFieldEnum.CATEGORY) {
    return [];
  } else {
    return [
      {
        id: 'category',
        label: NewDocumentFieldEnum.CATEGORY,
        classNames: {
          label: styles['hidden-label'],
        },
        placeholder: NewDocumentFieldEnum.CATEGORY,
        required: false,
        value: category,
        options: docTypeCategoryOptions,
        fieldType: FieldTypes.SELECT,
        name: NewDocumentFieldEnum.CATEGORY,
      },
      {
        id: 'title',
        label: NewDocumentFieldEnum.TITLE,
        classNames: {
          label: styles['hidden-label'],
        },
        placeholder: NewDocumentFieldEnum.TITLE,
        required: false,
        options: titleOptions,
        value: title,
        fieldType:
          category === docTypeCategoryOptions[5].label ? FieldTypes.TEXT : FieldTypes.SELECT,
        name: NewDocumentFieldEnum.TITLE,
      },
      {
        id: 'fileName',
        label: NewDocumentFieldEnum.FILENAME,
        classNames: {
          label: styles['hidden-label'],
        },
        placeholder: fileName,
        required: false,
        value: fileName,
        fieldType: FieldTypes.TEXT,
      },
    ];
  }
};
