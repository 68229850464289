export type APIUsersByRoleType = {
  userId: string;
  picture?: string;
  fullName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  salesforceId?: string;
};

export type UserDetailsFieldType = {
  classname: {
    cell: string;
    value: string;
  };
  value: string | [];
  id: string;
  label: string;
};

export type APIRoleListType = {
  results: RoleListType[];
  paging: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  };
};

export type RoleListType = {
  id: string;
  name?: string;
  description?: string;
};

export enum UserRoles {
  BROKER = 'Broker',
  USER = 'User',
  ADVISOR = 'Advisor',
}

export enum UserActivationStatus {
  ACTIVE = 1,
  DEACTIVATED = 2,
}
