import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';

import IntegrationIcon from 'assets/images/icon/integration.svg';

type IntegrationsDetailsProps = {
  integrations: string;
};

const IntegrationsDetails: React.FC<IntegrationsDetailsProps> = ({ integrations }) => {
  return (
    <div
      className={cn(styles['card'], styles['integrations-details'], {
        [styles['no-integration']]: integrations === '',
      })}
    >
      <div
        className={cn(
          styles['container-integration-icon'],
          integrations ? styles['integration'] : styles['no-integration']
        )}
      >
        <img
          src={IntegrationIcon}
          alt="integration"
          className={cn(
            styles['custom-icon'],
            integrations ? styles['integration'] : styles['no-integration']
          )}
        />
      </div>
      <div className={styles['container-details']}>
        <p className={styles['label']}>Client has {integrations ? '' : 'no'} integrated accounts</p>
        {integrations && <p className={styles['sub-label']}>{integrations}</p>}
      </div>
    </div>
  );
};

export default IntegrationsDetails;
