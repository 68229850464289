import cn from 'classnames';
import Spinner from 'components/spinner';
import { useTranslation } from 'react-i18next';
import styles from 'pages/companyApplicationForm/assets/editApplicationForm.module.scss';
import { TApplicationSaving } from 'pages/companyApplicationForm/store/editApplicatioForm.reducer';

const ApplicationHeading = ({
  ableToSave,
  hasSubmittedApplication,
  saving,
  handleSubmit,
  handleExport,
}: {
  ableToSave: boolean;
  hasSubmittedApplication: boolean;
  saving: TApplicationSaving;
  handleSubmit: (event: any) => void;
  handleExport: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.heading)}>
      <div data-testid="application-actions" className={cn(styles['heading-actions'])}>
        <button
          id="save-as-draft"
          onClick={handleSubmit}
          disabled={!ableToSave}
          className={cn(styles['heading-button'], !ableToSave && styles['button-inactive'])}
        >
          <span>{t('home:companydetails:applicationform:edit:savedraft')}</span>
          {(saving === TApplicationSaving.SAVEDRAFT && <Spinner size="small" />) || (
            <i className={cn('material-icons')}>save</i>
          )}
        </button>
        <button
          id="export-as-pdf"
          onClick={handleExport}
          disabled={ableToSave}
          data-label={
            !hasSubmittedApplication && 'Please submit application before downloading as PDF'
          }
          className={cn(
            styles['heading-button'],
            !hasSubmittedApplication && styles['button-inactive'],
            !hasSubmittedApplication && styles['document-download-inactive-tooltip']
          )}
        >
          <span>{t('home:companydetails:applicationform:edit:export')}</span>
          {(saving === TApplicationSaving.DOWNLOADPDF && <Spinner size="small" />) || (
            <i className={cn('material-icons')}>picture_as_pdf</i>
          )}
        </button>
      </div>
    </div>
  );
};

export default ApplicationHeading;
