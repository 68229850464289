import { BrokerAPI } from '_shared/api';
import { BrokerAPIURL } from '_shared/url';

const getFinancialInfoProfit = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoProfit(id));

const getFinancialInfoGrossProfit = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoGrossProfit(id));

const getFinancialInfoIncome = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoIncome(id));

const getFinancialInfoInterestCoverage = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoInterestCoverage(id));

const getFinancialInfoEquityRatio = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoEquityRatio(id));

const getFinancialInfoCurrentRatio = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoCurrentRatio(id));

export const getFinancialInformationTab = async (id: string) => {
  return Promise.all([
    getFinancialInfoIncome(id),
    getFinancialInfoGrossProfit(id),
    getFinancialInfoProfit(id),
    getFinancialInfoInterestCoverage(id),
    getFinancialInfoEquityRatio(id),
    getFinancialInfoCurrentRatio(id),
  ]);
};

export const getFinancialInfoProfitLossStatement = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoProfitLossStatement(id));

export const getFinancialInfoBalanceSheet = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoBalanceSheet(id));

export const getFinancialInfoOnboardingQuestions = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getFinancialInfoOnboardingQuestions(id));

export const getTrueLayerStatus = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getTrueLayerStatus(companyId));

export const postAccountingIntegration = (companyId: string, data: { [key: string]: string }) =>
  BrokerAPI.post(BrokerAPIURL.postAccountingIntegration(companyId), data);

export const getAccountingStatus = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getAccountingStatus(companyId));

export const getBankAccountStatus = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getBankAccountStatus(companyId));

export const getActualCashflowForecast = (companyId: string, params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getActualCashflowForecast(companyId), { params });

export const getCashflowShortterm = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCashflowShortterm(companyId));
