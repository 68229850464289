import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import React from 'react';

import { convertToLocalDateFormat } from '_shared/utils/date';
import { TextformatType } from '_shared/fieldValidation/types';
import { DatePickerType } from './type';
import styles from './datepicker.module.scss';
import { range } from './utils';
import { getMonth, getYear } from 'date-fns';
import { monthsDic, datePickerRange } from '_shared/utils/constants';

function DatePicker(props: DatePickerType) {
  const [inputProp, setInputProp] = React.useState({
    value: props.value,
    name: props.name,
    placeholder: props.placeholder,
    id: props.id,
  });
  const inputRef = React.useRef<HTMLInputElement>(null);

  const CustomInput = React.forwardRef(({ value, onClick }: any, _ref: React.Ref<any>) => {
    const displayValue = (inputProp.value && convertToLocalDateFormat(inputProp.value)) || '';

    return (
      <div
        className={classNames(styles.datepicker, {
          [styles.error]: props.hasError,
        })}
      >
        <input
          key={inputProp.value}
          id={inputProp.id}
          type="text"
          readOnly
          ref={inputRef}
          onClick={onClick}
          {...((props.max && { max: props.max }) || {})}
          {...((props.min && { min: props.min }) || {})}
          name={inputProp.name}
          placeholder={inputProp.placeholder}
          className={classNames(styles.input, styles.trailingicon, props.className)}
          data-customfield={TextformatType.DATEPICKER}
          data-date={inputProp.value}
          value={displayValue}
          disabled={props.disabled}
          data-testid="date-picker"
        />
        {props.showCalendarIcon && (
          <i
            aria-labelledby="trailing-icon"
            className={classNames(styles['trailing-icon'], 'material-icons')}
          >
            today
          </i>
        )}
      </div>
    );
  });

  const handleOnDateChange = async (selection: any) => {
    setInputProp({ ...inputProp, value: selection });

    setTimeout(() => {
      props.handleChange({ target: inputRef.current });
    }, 50);
  };

  const years = range(datePickerRange.startYear.value, datePickerRange.endYear.value);
  const months = monthsDic.map(function (month, index) {
    return monthsDic[index].value;
  });

  return (
    <ReactDatePicker
      dateFormat="dd-MM-yyyy"
      selected={(inputProp.value && new Date(inputProp.value)) || new Date()}
      onChange={handleOnDateChange}
      value={inputProp?.value}
      className={props?.className}
      customInput={<CustomInput />}
      todayButton={props.todayButtonText || 'Today'}
      {...((props.max && { maxDate: new Date(props.max) }) || {})}
      {...((props.min && { minDate: new Date(props.min) }) || {})}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className={styles.header} data-testid="date-picker-container">
          <button
            className={classNames(styles['headings'], styles['change-month'])}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            data-testid="prev-month"
          >
            {'<'}
          </button>

          <select
            className={styles.headings}
            data-testid="month-select"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((month) => (
              <option key={month} value={month} data-testid={month}>
                {month}
              </option>
            ))}
          </select>

          <select
            className={classNames(styles['headings'], styles['year-select'])}
            data-testid="year-select"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(parseInt(value))}
          >
            {years.map((option) => (
              <option key={option} value={option} data-testid={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            className={classNames(styles['headings'], styles['change-month'])}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            data-testid="next-month"
          >
            {'>'}
          </button>
        </div>
      )}
    />
  );
}

export default DatePicker;
