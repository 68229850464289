export enum HistoryModalEnum {
  Broker = 'Broker',
  Advisor = 'AccountOwner',
}

export type HistoryModalProps = {
  historyState: any;
  onModalClose: Function;
  companyId: string;
  opportunityId?: string;
  initialTableFieldState: any;
  tableFields: any;
  historyApiEndpoint: Function;
  modalTitle: string;
};

export type AssignmentHistoryEntry = {
  fullName: string;
  date: Date;
  assignedBy: string;
};
