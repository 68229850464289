import React, { useRef, useState } from 'react';
import cn from 'classnames';
import useBlur from '_shared/hooks/useBlur';
import styles from './CreatorFilter.module.scss';
import { CreatorFilterProp } from './types';
import Checkbox from 'components/field/checkbox';
import { CreatorType } from 'pages/deals/types';

const CreatorFilter = ({
  label,
  creatorTypes,
  selectedCreatorTypes,
  onChange,
  disabled,
}: CreatorFilterProp) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const onCheckboxChange = (selectedValue: CreatorType) => {
    onChange(
      selectedCreatorTypes.includes(selectedValue)
        ? selectedCreatorTypes.filter((value) => value !== selectedValue)
        : [...selectedCreatorTypes, selectedValue]
    );
  };

  useBlur(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div className={cn(styles['creator-filter-selector'])}>
      <div
        data-testid="creator-filter-dropdown-container"
        className={cn(styles['creator-filter-dropdown-container'])}
      >
        <button
          data-testid="creator-filter-dropdown-button"
          className={cn(styles['creator-filter-dropdown-button'])}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>
            {label || 'Selected'} ({selectedCreatorTypes.length})
          </span>
          <i className={'material-icons'}>expand_more</i>
        </button>
        {(isDropdownOpen && (
          <div
            ref={dropdownRef}
            className={cn(styles['creator-filter-dropdown'])}
            data-testid="creator-filter-filter-dropdown"
          >
            <div className={cn(styles['dropdown-checkbox-container'])}>
              {creatorTypes.map((creatorType) => {
                return (
                  <label key={creatorType.value} className={cn(styles['dropdown-checkbox-label'])}>
                    <Checkbox
                      {...{
                        id: creatorType.value.toString(),
                        name: creatorType.value.toString(),
                        handleChange: () => onCheckboxChange(creatorType.value),
                        selected: selectedCreatorTypes.includes(creatorType.value),
                        disabled,
                      }}
                    />
                    <span>{creatorType.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default CreatorFilter;
