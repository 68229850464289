import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Register from '.';

export const registerRoutes: RouteObject[] = [
  {
    path: RoutePath.register,
    element: withTransaction('Register', 'component')(<Register />),
  },
];
