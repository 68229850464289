import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './fieldHolder.module.scss';
import { FieldHolderProps } from './types';
import HistoryButton from 'components/historybutton';

const FieldHolder = ({ metadata, children, errors }: FieldHolderProps) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid={`field-container-${metadata.id}`}
      className={cn(styles.field, metadata.classNames?.field)}
    >
      <div className={cn(styles['error-and-label-container'])}>
        {metadata.label && (
          <label htmlFor={metadata.id} className={cn(styles.label, metadata.classNames?.label)}>
            {t(metadata.label || '')}
            {metadata.trailingIcon && metadata.historicalStatusData && (
              <HistoryButton
                callback={metadata.historicalStatusData.callback}
                tooltip={metadata.historicalStatusData.tooltip}
              />
            )}
          </label>
        )}
        {metadata.name && errors[metadata.name] && (
          <span
            role="alert"
            id={`error-${metadata.name}`}
            className={cn(styles['label-error'], metadata.classNames?.error)}
          >
            {t(errors[metadata.name], { length: metadata.minimumLength })}
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

export default FieldHolder;
