import cn from 'classnames';
import styles from './ProcessSteps.module.scss';
import { ProcessStepsProps } from 'pages/companySavingsProducts/types';

export default function ProcessSteps({ steps }: Readonly<ProcessStepsProps>) {
  return (
    <div>
      {steps.map((step, index) => (
        <div className={styles['process-steps-row']} key={step.title}>
          <div className={cn('number-wrapper', styles['number-wrapper'])}>
            <div className={cn('number-container', styles['number-container'])}>{index + 1}</div>
          </div>
          <div className={cn('content', styles.content)}>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
