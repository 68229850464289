import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './percentageInput.module.scss';
import { FieldPropType } from '../type';

const PercentageInput = React.forwardRef(
  (
    { metadata, errors, handleChange, onEnter }: FieldPropType,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [currentValue, setCurrentValue] = useState<string>('');
    const [validationError, setValidationError] = useState<string | null>(null);

    const error = metadata.name && errors[metadata.name];

    function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value;
      if (!isNaN(Number(value))) {
        const numericValue = Number(value);
        if (numericValue < 0 || numericValue > 100) {
          setValidationError('Value must be between 0 and 100');
        } else {
          setValidationError(null);
          setCurrentValue(value);
          handleChange(e);
        }
      }
    }

    useEffect(() => {
      setCurrentValue(metadata.value ? String(metadata.value) : '');
    }, [metadata.value]);

    return (
      <div className={classNames(styles['percentage-input-wrapper'])}>
        <input
          id={metadata.id}
          ref={ref}
          data-testid={`test-${metadata.id}`}
          type="text"
          name={metadata.name}
          tabIndex={metadata.tabIndex || 0}
          aria-label={metadata.label}
          aria-invalid={!!error || !!validationError}
          aria-describedby={`error-${metadata.name}`}
          value={currentValue}
          placeholder={metadata.placeholder || ''}
          className={classNames(
            styles.input,
            (!!error || !!validationError) && styles.error,
            metadata.classNames?.value
          )}
          {...(typeof metadata?.min !== 'undefined' && { min: metadata.min })}
          {...(typeof metadata?.max !== 'undefined' && { max: metadata.max })}
          onChange={changeHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !metadata?.disabled) {
              onEnter?.();
            }
          }}
        />
        <span>%</span>
      </div>
    );
  }
);

export default PercentageInput;
