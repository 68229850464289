import { ActivityPropTypes, ACTIVITY_TYPE } from '../types';
import { useTranslation } from 'react-i18next';
import { activityTypeIcons } from '../utils';
import cn from 'classnames';
import Textarea from 'components/field/textarea';
import styles from './activity.module.scss';
import useToggle from '_shared/hooks/useToggle';
import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertHtmlToEditorState } from 'components/field/textEditor/utils';

const Activity = ({
  activity,
  handleEditActivity,
  handleEditOnChange,
  handleDeleteActivity,
}: ActivityPropTypes) => {
  const { t } = useTranslation();
  const [isTextTruncated, setIstTextTruncated] = useToggle(true);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const truncateLength = 140;

  const editActivity = () => {
    if (activity.id && handleEditActivity) handleEditActivity(activity.id);
  };

  const deleteActivity = () => {
    if (activity.id && handleDeleteActivity) handleDeleteActivity(activity.id);
  };

  useEffect(() => {
    setEditorState(convertHtmlToEditorState(activity.content));
  }, [activity.content]);

  const taskItem = () => {
    if (activity.activityType === ACTIVITY_TYPE.TASK) {
      return (
        <span>
          <ul className={cn(styles['task-info-list'])}>
            <li>
              <b>Subject:</b> {activity.subject}
            </li>
            <li>
              <b>Detail:</b>
              <Editor
                editorState={editorState}
                readOnly={true}
                toolbarHidden={true}
                editorClassName={styles.taskEditor}
              />
            </li>
            <li>
              <b>Assignee:</b> {activity.assignedToFullName}
            </li>
            <li>
              <b>Due Date:</b> {activity.dueDate}
            </li>
            <li>
              <b>Status:</b> {activity.taskStatus}
            </li>
          </ul>
        </span>
      );
    } else if (
      activity.activityType === ACTIVITY_TYPE.EMAIL &&
      activity.fromAddress &&
      activity.toAddress
    ) {
      return (
        <div className={cn(styles['email-body'])}>
          <ul className={cn(styles['task-info-list'])}>
            <li>
              <b>From:</b> {activity?.fromAddress}
            </li>
            <li>
              <b>To:</b> {activity?.toAddress}
            </li>
            <li>
              <b>Subject:</b> {activity?.subject}
            </li>
            <li>
              <b>Detail:</b>{' '}
              {isTextTruncated
                ? activity?.content
                    .substring(0, truncateLength)
                    .split('\n')
                    .map((paragraph, index) => {
                      return <p key={index}> {paragraph}</p>;
                    })
                : activity?.content.split('\n').map((paragraph, index) => {
                    return <p key={index}> {paragraph}</p>;
                  })}
              {activity.content.length > truncateLength && (
                <button
                  className={cn(styles['elipsis'])}
                  data-label={
                    isTextTruncated
                      ? t('home:companydetails:activities:emails:showmore')
                      : t('home:companydetails:activities:emails:hide')
                  }
                  onClick={setIstTextTruncated}
                >
                  <i className={cn('material-icons')}>more_horiz</i>
                </button>
              )}
            </li>
          </ul>
        </div>
      );
    } else if (activity.activityType === ACTIVITY_TYPE.NOTE) {
      return (
        <div>
          <Editor editorState={editorState} readOnly={true} toolbarHidden={true} />
        </div>
      );
    } else {
      return activity?.content.split('\n').map((paragraph, index) => {
        return <p key={index}> {paragraph}</p>;
      });
    }
  };

  return (
    <div data-testid="activity" className={cn(styles['activity-container'])}>
      <div className={cn(styles['activity-heading'])}>
        <div className={cn(styles['activity-user'])}>
          <i
            className={cn('material-icons', {
              [styles['call-success']]: activity.activityType === ACTIVITY_TYPE.CALL_SUCCESS,
              [styles['call-failed']]: activity.activityType === ACTIVITY_TYPE.CALL_FAILURE,
            })}
          >
            {activityTypeIcons[activity.activityType]}
          </i>
          <img src={activity.picture} alt="user" />
          <span>{activity.user}</span>
        </div>
        <span>Created: {activity.dateCreated}</span>
      </div>
      {handleEditOnChange && (
        <>
          {activity.activityType !== ACTIVITY_TYPE.NOTE &&
            activity.activityType !== ACTIVITY_TYPE.TASK && (
              <Textarea
                metadata={{
                  id: 'edit-activity',
                  name: 'edit-activity',
                  placeholder: t('home:companydetails:activities:new:placeholder'),
                  value: activity.content,
                  classNames: {
                    value: styles['edit-activity-textarea'],
                  },
                }}
                errors={[]}
                handleChange={handleEditOnChange}
              />
            )}
        </>
      )}
      {taskItem()}
      {(activity.isMyNote && handleEditActivity && handleDeleteActivity && (
        <div className={cn(styles['activity-actions-container'])}>
          <div className={cn(styles['activity-actions'])}>
            <button onClick={editActivity} data-testid="edit-activity-btn">
              {t('home:companydetails:activities:note:edit')}
            </button>
            <span>.</span>
            <button onClick={deleteActivity}>{t('common:delete')}</button>
          </div>
          {(activity.dateModified !== activity.dateCreated && (
            <div className={cn(styles['edit-date'])}>modified: {activity.dateModified}</div>
          )) ||
            null}
        </div>
      )) ||
        null}
    </div>
  );
};

export default Activity;
