import React from 'react';
import styles from './TableCell.module.scss';
import cn from 'classnames';

type TableCellProps = {
  className?: string;
  colSpan?: number;
  children?: React.ReactNode;
};

const TableCell: React.FC<TableCellProps> = ({ className, colSpan, children }) => {
  return (
    <td className={cn(styles.td, className)} colSpan={colSpan}>
      {children}
    </td>
  );
};

export default TableCell;
