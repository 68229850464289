import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './upload.module.scss';
import { UploadType, UploadValue } from './type';
import { sendAmplitudeData } from 'config/amplitude';

function Upload(props: UploadType, _ref: any) {
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [isDragging, setIsDragging] = useState(false);
  const [value, setValue] = useState<UploadValue>([]);
  const { format, toolTipContent, acceptedFileTypes, multiple } = props;
  const uploadText = format ? format : t('common:uploadfile');

  const handleUploadFile = useCallback(
    (ev) => {
      const input = ev.target;

      const files: FileList = ev?.target?.files || ev.dataTransfer.files;

      if (files && files.length > 0) {
        if (props.multiple) {
          props.handleChange(Array.from(files));
        } else {
          props.handleChange([files[0]]);
        }
        setValue(
          Array.from(files).map((file) => ({
            name: file.name,
            size: Math.round(file.size / 1024).toString(),
            format: file.type.split('/').length > 1 ? file.type.split('/')[1] : '',
          }))
        );
        if (props.tracking) {
          sendAmplitudeData(props.tracking);
        }
      } else {
        setError(t('common:failupload'));
      }
      input.value = '';
    },
    [t, props]
  );

  const handleDragEnd = useCallback((ev) => {
    ev.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDragStart = useCallback((ev) => {
    ev.preventDefault();
    setIsDragging(true);
  }, []);

  return (
    <div className={cn(styles.upload, props.className?.control)}>
      <label
        data-testid="upload-file-test-id"
        htmlFor={props.name || 'upload-file'}
        className={cn(styles['upload-input'], {
          [styles['upload-input--error']]: error,
          [styles['upload-input--dragging']]: isDragging,
        })}
        onDrop={(ev) => {
          handleDragEnd(ev);
          handleUploadFile(ev);
        }}
        onDragOver={handleDragStart}
        onDragLeave={handleDragEnd}
        onDragEnd={handleDragEnd}
      >
        {isDragging ? (
          <span>{t('common:dropfiles')}</span>
        ) : (
          <>
            <i className={cn('material-icons')}>cloud_upload</i>
            <span>{t('common:draganddrop')}</span>
            <span className={cn(styles.uploadSmall)}>
              {uploadText}
              <span
                className={cn(styles['uploadmessage'], styles['format-tooltip'])}
                data-label={toolTipContent}
                data-testId="upload-tooltip"
              >
                <i className={cn('material-icons', styles['info-icon'])}>info</i>
              </span>
            </span>
          </>
        )}
        <input
          id={props.name || 'upload-file'}
          type="file"
          onChange={handleUploadFile}
          value={undefined}
          accept={acceptedFileTypes}
          multiple={multiple}
        />
      </label>
      {!props.hideFileValues && (
        <div className={styles['upload-information']}>
          {value.map((file) => (
            <div key={file.name} className={styles['file-information']}>
              {file.name} - {file.size}KB {file.format && ` -  ${file.format}`}
            </div>
          ))}
          <label
            htmlFor={props.name || 'upload-file'}
            className={cn('sw-button primary', styles.button)}
          >
            {t(props.label || 'upload')}
          </label>
        </div>
      )}
    </div>
  );
}

export default Upload;
