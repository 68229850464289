import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Companies from '.';

export const companiesRoutes: RouteObject[] = [
  {
    path: RoutePath.companies,
    element: withTransaction('Companies', 'component')(<Companies />),
  },
];
