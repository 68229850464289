import { useState } from 'react';

const usePagination = (itemsPerPage: number = 10, initialCurrentPage: number = 1) => {
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [totalCount, setTotalCount] = useState(0);

  const maxPage = Math.ceil(totalCount / itemsPerPage);

  const pageList = Array.from(
    { length: Math.min(7, maxPage) },
    (v, k) => k + Math.max(Math.min(currentPage - 3, maxPage - 6), 1)
  );

  const nextPage = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  };

  const previousPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const jump = (page: number) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return {
    currentPage,
    maxPage,
    totalCount,
    pageList,
    nextPage,
    previousPage,
    jump,
    setTotalCount,
    setCurrentPage,
  };
};

export default usePagination;
