import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './textEditor.module.scss';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { FieldPropType } from '../type';
import { convertHtmlToEditorState, editorOptions } from './utils';

const TextEditor = ({ metadata, handleChange }: FieldPropType) => {
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(metadata.value));

  const customHandleChange = (updatedState: EditorState) => {
    // Convert Draft.js content to HTML
    const value = draftToHtml(convertToRaw(updatedState.getCurrentContent()));
    setEditorState(updatedState);

    handleChange({
      target: {
        id: metadata.id,
        name: metadata.name,
        value,
      },
    });
  };

  return (
    <Editor
      toolbar={editorOptions}
      editorState={editorState}
      toolbarClassName={styles.toolbar}
      wrapperClassName={styles.wrapper}
      editorClassName={styles.editor}
      onEditorStateChange={customHandleChange}
      readOnly={metadata.disabled}
    />
  );
};

export default TextEditor;
