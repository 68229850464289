export type IntegrationStatusType = 'NotIntegrated' | 'Pending' | 'Connected' | 'Error';
export type BankIntegrationStatusType =
  | 'NotIntegrated'
  | 'FetchingData'
  | 'FetchingDataCompleted'
  | 'Processing'
  | 'ProcessingCompleted'
  | 'FetchingDataError'
  | 'AuthorisationExpired';

export enum IntegrationStatusEnum {
  NOTCONNECTED = 'NotIntegrated',
  PENDING = 'Pending',
  CONNECTED = 'Connected',
  ERROR = 'Error',
}

export const statusMap: {
  [key in IntegrationStatusType]: { text: string; className: string; icon: string };
} = {
  NotIntegrated: {
    text: 'Not connected',
    className: 'not-connected',
    icon: 'link_off',
  },
  Pending: {
    text: 'In progress',
    className: 'pending',
    icon: 'av_timer',
  },
  Connected: {
    text: 'Connected',
    className: 'connected',
    icon: 'link',
  },
  Error: {
    text: "We couldn't get the data",
    className: 'error',
    icon: 'link_off',
  },
};

export const bankStatusMap: {
  [key in BankIntegrationStatusType]: {
    text: string;
    className: string;
    icon?: string;
    spinner?: boolean;
  };
} = {
  NotIntegrated: {
    text: 'Not connected',
    className: 'not-connected',
    icon: 'link_off',
  },
  FetchingData: {
    text: 'Fetching data',
    className: 'pending',
    spinner: true,
  },
  FetchingDataCompleted: {
    text: 'Fetching data completed',
    className: 'pending',
    spinner: true,
  },
  Processing: {
    text: 'Processing data',
    className: 'pending',
    spinner: true,
  },
  ProcessingCompleted: {
    text: 'Connected',
    className: 'connected',
    icon: 'link',
  },
  FetchingDataError: {
    text: 'Error while fetching data',
    className: 'error',
    icon: 'link_off',
  },
  AuthorisationExpired: {
    text: 'Authorisation expired',
    className: 'error',
    icon: 'link_off',
  },
};
