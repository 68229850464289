import React, { MouseEventHandler, useEffect, useState } from 'react';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import { creatorTypeList } from '../../constant';
import { convertToLocalDateFormat } from '_shared/utils/date';
import { Controller, useForm } from 'react-hook-form';
import CurrencyInput from 'components/v2/CurrencyInput/CurrencyInput';
import { useTranslation } from 'react-i18next';
import { calculateNetFee } from '../../utils';
import Select from 'components/v2/Select/Select';
import { dealsStatusList } from '_shared/utils/constants';
import styles from './FunderTable.module.scss';
import Menu from 'components/menu';
import MenuItem from 'components/menu/MenuItem';
import useToastMessage from '_shared/hooks/useToastMessage';
import Tooltip from 'components/v2/Tooltip';
import { formatCurrency } from '_shared/utils/currency';

type FunderTableProps = {
  applications: Application[];
  advisorView?: boolean;
  disabled?: boolean;
  onUpdate?: (applicationId: string, opportunityId: string, payload: UpdatePayload) => void;
  onDelete?: (applicationId: string) => void;
};

type FunderRowProps = Omit<FunderTableProps, 'applications' | 'onDelete'> & {
  application: Application;
  onExpandMenu: MouseEventHandler<HTMLButtonElement>;
};
type Application = {
  id: string;
  opportunityId: string;
  creatorType: string;
  providerName: string;
  productName: string;
  dateModified: string;
  status: string;
  netFee?: number;
  fundingAmount?: number;
  funderCommission?: number;
  clientFee?: number;
  introducerFee?: number;
};

export type UpdatePayload = {
  fundingAmount: number;
  funderCommission: number;
  clientFee: number;
  introducerFee: number;
  status: string;
};

const FunderRow = ({
  application,
  advisorView,
  disabled,
  onExpandMenu,
  onUpdate,
}: FunderRowProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      status: dealsStatusList[application.status].value,
      fundingAmount: application.fundingAmount?.toString() ?? '0',
      funderCommission: application.funderCommission?.toString() ?? '0',
      clientFee: application.clientFee?.toString() ?? '0',
      introducerFee: application.introducerFee?.toString() ?? '0',
    },
    mode: 'onSubmit',
  });

  const funderCommission = watch('funderCommission');
  const clientFee = watch('clientFee');
  const introducerFee = watch('introducerFee');

  const netFee = calculateNetFee(
    parseFloat(funderCommission),
    parseFloat(clientFee),
    parseFloat(introducerFee)
  );

  const getCreatorLabel = (creatorType: string) => {
    return creatorTypeList.find((creator) => creator.value === creatorType)?.label ?? 'N/A';
  };

  const onSubmit = (formData: Record<string, string>) => {
    onUpdate?.(application.id, application.opportunityId, {
      fundingAmount: parseFloat(formData.fundingAmount),
      funderCommission: parseFloat(formData.funderCommission),
      clientFee: parseFloat(formData.clientFee),
      introducerFee: parseFloat(formData.introducerFee),
      status: formData.status,
    });
  };

  // Reset form when application changes
  useEffect(() => {
    reset({
      status: dealsStatusList[application.status].value,
      fundingAmount: application.fundingAmount?.toString() ?? '0',
      funderCommission: application.funderCommission?.toString() ?? '0',
      clientFee: application.clientFee?.toString() ?? '0',
      introducerFee: application.introducerFee?.toString() ?? '0',
    });
  }, [application]);

  return (
    <TableRow>
      <TableCell className={styles['creator-type']}>
        {getCreatorLabel(application.creatorType)}
      </TableCell>
      <TableCell className={styles['provider-name']}>
        <Tooltip text={application.providerName}>{application.providerName}</Tooltip>
      </TableCell>
      <TableCell className={styles['product-name']}>
        <Tooltip text={application.productName}>{application.productName}</Tooltip>
      </TableCell>
      <TableCell className={styles['date-modified']}>
        {convertToLocalDateFormat(application.dateModified) ?? 'N/A'}
      </TableCell>
      <TableCell className={styles['status']}>
        {advisorView ? (
          dealsStatusList[application.status].label
        ) : (
          <Controller
            name="status"
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <Select {...field} options={Object.values(dealsStatusList)} disabled={disabled} />
            )}
          />
        )}
      </TableCell>
      <TableCell className={styles['funding-amount']}>
        {advisorView ? (
          <span>{formatCurrency(application.fundingAmount ?? '0', t('currency:symbol'))}</span>
        ) : (
          <Controller
            name="fundingAmount"
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                prefix={t('currency:symbol')}
                disabled={disabled}
                precision={2}
              />
            )}
          />
        )}
      </TableCell>
      {!advisorView && (
        <TableCell className={styles['funder-commission']}>
          <Controller
            name="funderCommission"
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                prefix={t('currency:symbol')}
                disabled={disabled}
                precision={2}
              />
            )}
          />
        </TableCell>
      )}
      {!advisorView && (
        <TableCell className={styles['client-fee']}>
          <Controller
            name="clientFee"
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                prefix={t('currency:symbol')}
                disabled={disabled}
                precision={2}
              />
            )}
          />
        </TableCell>
      )}
      <TableCell className={styles['introducer-fee']}>
        {advisorView ? (
          <span>{formatCurrency(application.introducerFee ?? '0', t('currency:symbol'))}</span>
        ) : (
          <Controller
            name="introducerFee"
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                prefix={t('currency:symbol')}
                disabled={disabled}
                precision={2}
              />
            )}
          />
        )}
      </TableCell>
      {!advisorView && (
        <TableCell className={styles['net-fee']}>
          {formatCurrency(netFee, t('currency:symbol'))}
        </TableCell>
      )}
      {!advisorView && (
        <TableCell className={styles['actions']}>
          <button
            disabled={!isDirty || disabled}
            className={styles['action-btn']}
            onClick={handleSubmit(onSubmit)}
          >
            <i className="material-icons">save</i>
          </button>
          <button className={styles['action-btn']} onClick={onExpandMenu}>
            <i className="material-icons">more_horiz</i>
          </button>
        </TableCell>
      )}
    </TableRow>
  );
};

const EmptyRow = ({ advisorView }: { advisorView: boolean }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell colSpan={11} className={styles['empty-row']}>
        {advisorView
          ? t('home:deals:dealdetailsmodal:fundertable:advisoremptymessage')
          : t('home:deals:dealdetailsmodal:fundertable:brokeremptymessage')}
      </TableCell>
    </TableRow>
  );
};

const FunderTable: React.FC<FunderTableProps> = ({
  applications,
  advisorView = false,
  disabled = false,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { successToastMessage } = useToastMessage();

  const [menuMeta, setMenuMeta] = useState<{
    anchorEl: HTMLElement | null;
    applicationId: string;
  } | null>({
    applicationId: '',
    anchorEl: null,
  });

  const copyApplicationId = () => {
    navigator.clipboard.writeText(menuMeta!.applicationId);
    successToastMessage(t('home:deals:dealdetailsmodal:toast:copysucess'));
    setMenuMeta(null);
  };

  return (
    <>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>Creator</TableCell>
            <TableCell>Funder</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Last Progressed</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <Tooltip text="This is the amount for which your client is applying">Amount</Tooltip>
            </TableCell>
            {!advisorView && (
              <TableCell>
                <Tooltip text="This is where you can enter the anticipated Funder Commission">
                  Funder Commission
                </Tooltip>
              </TableCell>
            )}
            {!advisorView && (
              <TableCell>
                <Tooltip text="If applicable, this is where you can enter the Fee you're charging your client">
                  Client Fee
                </Tooltip>
              </TableCell>
            )}
            <TableCell>
              <Tooltip text="If applicable, this is where you can enter the amount you owe the Introducer of this client">
                Introducer Fee
              </Tooltip>
            </TableCell>
            {!advisorView && (
              <TableCell>
                <Tooltip text="This is automatically calculated by adding the Funder Commission to the Client Fee, then subtracting the Introducer Fee">
                  Net Fee
                </Tooltip>
              </TableCell>
            )}
            {!advisorView && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.length === 0 && <EmptyRow advisorView={advisorView} />}

          {applications.map((application) => (
            <FunderRow
              key={application.id}
              application={application}
              advisorView={advisorView}
              disabled={disabled}
              onUpdate={onUpdate}
              onExpandMenu={(e) => {
                setMenuMeta({
                  applicationId: application.id,
                  anchorEl: e.target as HTMLElement,
                });
                e.stopPropagation();
              }}
            />
          ))}
        </TableBody>
      </Table>

      <Menu
        open={!!menuMeta?.anchorEl}
        anchorEl={menuMeta?.anchorEl ?? null}
        onClose={() => {
          setMenuMeta(null);
        }}
      >
        <MenuItem icon="content_copy" onClick={copyApplicationId}>
          Copy Funder ID
        </MenuItem>
        <MenuItem icon="delete" onClick={() => onDelete?.(menuMeta!.applicationId)}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default FunderTable;
