import React, { useReducer, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './assets/users.module.scss';
import { fields } from './users.field';
import { APIUsersByRoleType } from './type';
import { userReducer, ActionType, initialState } from './reducer';
import { getUsersByRole } from '_shared/api/roles';
import { useAppState } from 'store';
import { currentApplicationRoles, Role, UserRoles } from 'config/roles';
import useDebounce from '_shared/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import Table from 'components/table';
import Card from 'components/card';
import { RoutePath } from '_shared/routes';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { FieldTypes } from '_shared/fieldValidation/types';
import Select from 'components/field/select';
import TextInput from 'components/field/text';
import {
  isAdvisorApplication,
  isBrokerApplication,
  SWOOP_GROUP_ID,
} from '_shared/utils/application';
import { UserModalEnum } from 'layouts/type';
import AddUserModal from 'layouts/components/AddUserModal/AddUserModal';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import useAutoFocus from '_shared/hooks/useAutoFocus';

const Users: React.FC = () => {
  const store = useAppState();
  const navigate = useNavigate();
  const { roles } = store.state.system;
  const [state, dispatch] = useReducer(userReducer, initialState);
  const { t } = useTranslation();
  const availableRoles = Object.values(currentApplicationRoles);
  const [selectedFilter, setSelectedFilter] = useState<string>(availableRoles[0]);
  const debouncedSearchTerm = useDebounce(state.searchUser, 500);
  const [userModalContext, setUserModalContext] = useState<UserModalEnum | null>(null);
  const searchInputRef = useAutoFocus();

  const getUserRoleOptions = () => {
    return availableRoles
      .map((availableRole: string) => {
        return {
          label: spaceBetweenTitleCaseWords(availableRole),
          value: availableRole,
        };
      })
      .filter(({ value }: { value: string }) => {
        if (value === 'SuperAdmin' && store.state.system.groupId !== SWOOP_GROUP_ID) return false;
        return true;
      });
  };

  const setModalContextAndSendAmplitude = (
    userContext: UserModalEnum,
    eventType: AmplitudeTrackingEnum,
    eventProperty?: { [key: string]: string }
  ) => {
    setUserModalContext(userContext);
    sendAmplitudeData(eventType, eventProperty);
  };

  const filterUserBySearch = () => {
    const searchUser = state.data.filter((item: APIUsersByRoleType) => {
      return item.fullName?.toLowerCase().includes(state.searchUser.toLowerCase());
    });
    dispatch({
      type: ActionType.SET_SEARCH_FIELDS,
      payload: { fields: fields(searchUser, state.order, handleOnHeaderSort) },
    });
  };

  useEffect(() => {
    filterUserBySearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleOnSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const query = event.target.value;
    dispatch({
      type: ActionType.SET_SEARCH_BROKER,
      payload: query,
    });
  };

  const selectFilter = (event: any) => {
    setSelectedFilter(event.target.value);
  };

  const handleOnHeaderSort = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    event.preventDefault();
    const sortBy = event.currentTarget.getAttribute('data-name');
    const direction = state.order.direction === 'desc' ? 'asc' : 'desc';

    dispatch({
      type: ActionType.SET_ORDER,
      payload: {
        sortBy,
        direction,
      },
    });
  };

  const fetchUsers = async () => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: true,
        },
      });

      let response;
      if (roles) {
        const role = roles.find((role: Role) => selectedFilter === role.name);

        if (role) {
          if (role.name === 'Admin') {
            const externalAdminRole = roles.find(
              (role: Role) => UserRoles.EXTERNAL_ADMIN === role.name
            );

            const [{ data: adminUsers }, { data: externalAdminUsers }] = await Promise.all([
              getUsersByRole(role.id),
              getUsersByRole(externalAdminRole?.id || ''),
            ]);
            response = [...(adminUsers?.results || []), ...(externalAdminUsers?.results || [])];
          } else {
            const usersFetchedByRole = await getUsersByRole(role.id);
            response = usersFetchedByRole?.data?.results;
          }
        }
      }

      dispatch({
        type: ActionType.SET_BROKERS,
        payload: {
          data: response || [],
          fields: fields(response || [], state.order, handleOnHeaderSort),
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const goToDetails = (id: string) => {
    if (!id) return;
    navigate(RoutePath.userdetails.replace(':id', id));
    sendAmplitudeData(AmplitudeTrackingEnum.viewbrokerinfo);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles?.length, selectedFilter]);

  useEffect(() => {
    const { sortBy, direction } = state.order;
    if (!sortBy || !state.data.length) {
      return;
    }
    const sortedData =
      (direction === 'asc' &&
        state.data.sort((a: any, b: any) => {
          return a[sortBy] > b[sortBy] ? -1 : 1;
        })) ||
      state.data.sort((a: any, b: any) => {
        return a[sortBy] < b[sortBy] ? -1 : 1;
      });

    dispatch({
      type: ActionType.SET_BROKERS,
      payload: {
        fields: fields(sortedData, state.order, handleOnHeaderSort),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.order.direction, state.order.sortBy]);

  return (
    <div className={cn('wrapper', styles['users-container'])}>
      <div className={cn(styles.users)} data-testid="user-list">
        <div className={cn(styles['filters-wrapper'])}>
          <div className={cn(styles.filters)}>
            <div className={cn(styles['select-role-container'])}>
              <Select
                {...{
                  metadata: {
                    id: 'role-type-select',
                    placeholder: 'Select Role',
                    required: true,
                    options: getUserRoleOptions(),
                    value: selectedFilter,
                  },
                  errors: [],
                  handleChange: selectFilter,
                }}
              />
            </div>
            <div className={cn(styles.input)}>
              <TextInput
                {...{
                  metadata: {
                    id: 'search-users',
                    placeholder: t('home:colleagues:search:placeholder'),
                    fieldType: FieldTypes.TEXT,
                    value: state.searchUser,
                    trailingIcon: 'search',
                  },
                  errors: [],
                  handleChange: handleOnSearchUser,
                }}
                ref={searchInputRef}
              />

              {store.state.system.isUserManager?.() && (
                <Link to={RoutePath.usermanagement}>
                  <div data-testid="manage-users-btn" className={cn(styles['add-button'])}>
                    <i className={cn('material-icons', styles['add-icon'])}>manage_accounts</i>
                    <span>{t('sidebar:manageuser:title')}</span>
                  </div>
                </Link>
              )}

              {isBrokerApplication &&
                store?.state?.system?.isAdminOrSuperAdmin?.() &&
                ((
                  <button
                    data-testid="add-button"
                    className={cn(styles['add-button'])}
                    onClick={() =>
                      setModalContextAndSendAmplitude(
                        UserModalEnum.BROKER,
                        AmplitudeTrackingEnum.addbrokerstart
                      )
                    }
                  >
                    <i className={cn('material-icons', styles['add-icon'])}>add_circle</i>
                    <span>{t('sidebar:inviteuser:title')}</span>
                  </button>
                ) ||
                  null)}
              {isAdvisorApplication &&
                store?.state?.system?.isAdminOrSuperAdmin?.() &&
                ((
                  <button
                    className={cn(styles['add-button'])}
                    onClick={() => setUserModalContext(UserModalEnum.ADVISOR)}
                  >
                    <i className={cn('material-icons', styles['add-icon'])}>add_circle</i>
                    <span>{t('sidebar:inviteuser:title')}</span>
                  </button>
                ) ||
                  null)}
            </div>
          </div>
        </div>
        <Table
          {...state.fields}
          loading={state.loading}
          rowCallBack={goToDetails}
          emptyMessage={t('home:colleagues:search:empty')}
        />
      </div>
      {Boolean(userModalContext) && <AddUserModal {...{ userModalContext, setUserModalContext }} />}
    </div>
  );
};

export default Users;
