import { useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import {
  FundingMatchesContext,
  fundingMatchesReducer,
  initialState,
} from './store/fundingMatches.reducer';

const FundingMatches = () => {
  const [state, dispatch] = useReducer(fundingMatchesReducer, initialState);

  return (
    <FundingMatchesContext.Provider value={{ state, dispatch }}>
      <Outlet data-testid="outlet" />
    </FundingMatchesContext.Provider>
  );
};

export default FundingMatches;
