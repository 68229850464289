import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import ProductInsights from '.';

export const productInsightsRoutes: RouteObject[] = [
  {
    path: RoutePath.productinsights,
    element: withTransaction('ProductInsights', 'component')(<ProductInsights />),
  },
];
