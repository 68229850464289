import React from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomMenuItem } from '../type';
import { RoutePath } from '_shared/routes';
import styles from './Bookmarks.module.scss';
import SidebarSlideOut from './SidebarSlideOut';

const Bookmark: React.FC<{
  bookmark: CustomMenuItem;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}> = ({ bookmark, onEdit, onDelete }) => {
  const onEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onEdit(bookmark.id);
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onDelete(bookmark.id);
  };

  return (
    <a href={bookmark.address} className={styles.bookmark} target="_blank" rel="noreferrer">
      <i className={cn('material-icons', styles.icon)}>{bookmark.icon}</i>
      <div className={styles.title}>{bookmark.label}</div>
      <div className={styles['bookmark-actions']}>
        <button className={styles['bookmark-action']} onClick={onEditClick}>
          <i className={cn('material-icons-outlined')}>edit</i>
        </button>
        <button className={styles['bookmark-action']} onClick={onDeleteClick}>
          <i className={cn('material-icons-outlined')}>bookmark_remove</i>
        </button>
      </div>
    </a>
  );
};

const Bookmarks = React.forwardRef<
  HTMLDivElement,
  { bookmarks: CustomMenuItem[]; onClose: () => void; onDelete: (id: string) => void }
>(({ bookmarks, onClose, onDelete }, ref) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onAdd = () => {
    onClose();
    navigate(RoutePath.addBookmark, { state: { from: pathname } });
  };

  const onEdit = (id: string) => {
    onClose();
    navigate(RoutePath.editBookmark.replace(':id', id), { state: { from: pathname } });
  };

  return (
    <SidebarSlideOut className={styles.bookmarks} ref={ref}>
      <div className={styles.header}>
        <button className={styles['add-bookmark-btn']} onClick={onAdd}>
          <i className={cn('material-icons-outlined', styles.icon)}>bookmark_add</i>
          Add bookmark
        </button>
      </div>
      {bookmarks.map((bookmark) => (
        <Bookmark bookmark={bookmark} key={bookmark.id} onEdit={onEdit} onDelete={onDelete} />
      ))}
    </SidebarSlideOut>
  );
});

export default Bookmarks;
