import { useReducer, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import ErrorComponent from 'components/error';

import { chargesReducer, ActionType } from './reducer';

import { getCompanyChargeDetails } from '_shared/api/businesses';

import { fields } from '../../fields/companyCharges.fields';
import styles from 'pages/companyOverview/assets/companyCharges.module.scss';

import Table from 'components/table';
import Spinner from 'components/spinner';

const CompanyCharges = ({ companyId }: { companyId: string | undefined }) => {
  const initialState = {
    charges: fields([]),
    loading: true,
    error: false,
  };

  const { t } = useTranslation();

  const [state, dispatch] = useReducer(chargesReducer, initialState);

  const fetchCompanyCharges = async (id: string) => {
    try {
      const { data } = await getCompanyChargeDetails(id);
      dispatch({
        type: ActionType.SETCHARGES,
        payload: {
          loading: false,
          charges: fields(data.companyCharges),
        },
      });
    } catch (e) {
      dispatch({
        type: ActionType.SETCHARGES,
        payload: {
          loading: false,
          error: true,
        },
      });
    }
  };

  useEffect(() => {
    if (!companyId) return;

    fetchCompanyCharges(companyId);
  }, [companyId]);

  return (
    <div className={classnames(styles['charges-page-container'])}>
      {(state.loading && (
        <div className={classnames(styles['loading'])}>
          <Spinner size="large" />
        </div>
      )) ||
        (state.error && (
          <div className={classnames(styles['error'])}>
            <ErrorComponent message={t('common:servererror')} />
          </div>
        )) || (
          <>
            <h2>{t('home:companydetails:overview:info:charges')}</h2>
            <Table {...state.charges} loading={state.loading} />
          </>
        )}
    </div>
  );
};

export default CompanyCharges;
