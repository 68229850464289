export enum LoaderType {
  PAGE_LOADER,
  COMPONENT_LOADER,
}

export interface ILoader {
  text?: string;
  active: boolean;
  loaderStyle?: LoaderType;
}
