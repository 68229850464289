import { OptionsType } from '_shared/fieldValidation/types';
import { CreatorType } from '../../pages/deals/types';

export interface DealsState {
  dealId: string;
  dealIndexState: string;
  companyName: string;
  selectedFilters: any;
  pageNumber: number;
  refreshIndexPage: number;
  selectedUserIds?: string[];
  selectedStatus?: string[];
  selectedCreatorTypes: CreatorType[];
  hideCompletedDeals?: boolean;
  userList: any;
  filter: {
    orderBy: string;
    direction: string;
  };
  dealsData: any[];
  currentDealData: any;
  currentDealFunders: any;
  uniqueDealFundersIds: string[];
  companyId: string;
  funderId: string;
  detailsModalOpen: boolean;
  lastProgressedFilterType: string;
  lastProgressedFrom: Date | null;
  lastProgressedTo: Date | null;
  selectedDealSubTypes: any;
  loanDealSubTypes: OptionsType[] | undefined;
  equityDealSubTypes: OptionsType[] | undefined;
  allDealSubTypes: OptionsType[] | undefined;
}

export const UPDATE_DEALS = 'UPDATE_DEALS';

interface UpdateDealsAction {
  type: typeof UPDATE_DEALS;
  payload?: DealsState | any;
}

export type DealsActionTypes = UpdateDealsAction;
