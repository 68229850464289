import { useState } from 'react';
import cn from 'classnames';
import styles from '../../assets/companyOverview.module.scss';
import { useTranslation } from 'react-i18next';
import { ActionTypes, FieldMetaDataPropType } from '_shared/fieldValidation/types';
import ReadOnly from 'components/field/readOnly';
import Modal from 'components/modal';
import CompanyCharges from '../companyCharges';
import { TCompanyInfoProps } from 'pages/companyOverview/types';

const CompanyInfoCard = ({ isLoading, error, fields, companyId }: TCompanyInfoProps) => {
  const { t } = useTranslation();

  const [showChargesModal, setShowChargesModal] = useState(false);

  return (
    <>
      <div data-testid="overview-company-info" className={cn(styles.info)}>
        {fields?.length &&
          fields
            .filter((field) => field.value)
            .map((field: FieldMetaDataPropType) => {
              return (
                <ReadOnly
                  {...{
                    key: field.id,
                    metadata: field,
                    actionCallbacks: {
                      [ActionTypes.ALERT]: setShowChargesModal,
                    },
                  }}
                />
              );
            })}
      </div>
      {showChargesModal && (
        <Modal show={showChargesModal} handleClose={setShowChargesModal}>
          <CompanyCharges companyId={companyId} />
        </Modal>
      )}
    </>
  );
};

export default CompanyInfoCard;
