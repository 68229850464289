import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../field.module.scss';
import readOnlyStyes from './readOnly.module.scss';
import {
  FieldPosition,
  ReadOnlyTypes,
  FieldMetaDataPropType,
  ActionTypes,
} from '_shared/fieldValidation/types';
import EditSvg from 'assets/images/icon/edit.svg';
import { sendAmplitudeData } from 'config/amplitude';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { HistoricalData } from 'pages/companyOverview/types';
import HistoryButton from 'components/historybutton';
import { numberWithCommas } from '_shared/utils/currency';

function ReadOnly(
  {
    metadata,
    actionCallbacks,
    children,
    historicalData = undefined,
  }: {
    metadata: FieldMetaDataPropType;
    actionCallbacks?: any;
    children?: any;
    historicalData?: HistoricalData;
  },
  _ref: any
) {
  const { t } = useTranslation();

  if (typeof metadata.value === 'string') {
    metadata.value = DOMPurify.sanitize(metadata.value);
  }

  const actionHandler = () => {
    if (metadata.tracking) {
      sendAmplitudeData(metadata.tracking);
    }

    if (metadata.action) {
      actionCallbacks[metadata.action](metadata.id);
    }
  };

  const addComma = (index: number, length: number) => (index + 1 !== length ? ', ' : '');

  return (
    <div
      className={classnames('field', styles.field, metadata?.classNames?.field, {
        [styles['group-left']]: metadata.position === FieldPosition.GROUPLEFT,
        [styles['group-right']]: metadata.position === FieldPosition.GROUPRIGHT,
      })}
      data-testid={`field-container-${metadata.id}`}
    >
      <label
        htmlFor={metadata.id}
        className={classnames(
          styles.label,
          metadata?.classNames?.label,
          metadata?.toolTipContent && styles['label-tooltip'],
          metadata?.classNames?.labelToolTip
        )}
      >
        {t(metadata.label || '')}
        {metadata?.toolTip && metadata?.toolTipContent && (
          <span
            className={classnames(styles['info-icon'], metadata?.classNames?.icon)}
            data-label={metadata.toolTipContent}
          >
            <i className={classnames('material-icons')}>info</i>
          </span>
        )}
        {historicalData && (
          <HistoryButton callback={historicalData.callback} tooltip={historicalData.tooltip} />
        )}
      </label>
      {(metadata.type === ReadOnlyTypes.LIST && (
        <ul className={classnames(styles['control-holder'], metadata?.classNames?.control)}>
          {Array.isArray(metadata.value) &&
            metadata.value.map((x: any) => {
              return <li key={x}>{x}</li>;
            })}
        </ul>
      )) || (
        <div className={classnames(styles['control-holder'], metadata?.classNames?.control)}>
          {(metadata.leadingIcon && metadata.value !== ' - ' && (
            <i
              aria-labelledby="leading-icon"
              className={classnames('material-icons', readOnlyStyes['leading-icon'])}
            >
              {metadata.leadingIcon}
            </i>
          )) ||
            null}
          {(metadata.type === ReadOnlyTypes.ANCHOR && (
            <a
              className={classnames(styles['control-holder'], metadata?.classNames?.control)}
              target="_blank"
              rel="noopener noreferrer"
              href={metadata?.link}
              onClick={actionHandler}
            >
              {metadata.value}
            </a>
          )) ||
            (metadata.type === ReadOnlyTypes.LINK && (
              <Link
                to={{
                  pathname: metadata?.link,
                }}
              >
                {metadata?.value}
              </Link>
            )) ||
            (metadata.type === ReadOnlyTypes.ICON && (
              <span
                className={classnames(
                  'material-icons',
                  styles['icon'],
                  metadata?.classNames?.value
                )}
              >
                {metadata.value}
              </span>
            )) ||
            (metadata.type === ReadOnlyTypes.NUMBER && (
              <label>{numberWithCommas(metadata.value)}</label>
            )) ||
            (Array.isArray(metadata.value) &&
              metadata.value.map((value, index) => {
                return (
                  <span key={value} className={classnames(styles.pill)}>
                    {value + addComma(index, metadata.value.length)}
                  </span>
                );
              })) || <div dangerouslySetInnerHTML={{ __html: metadata.value }}></div>}
        </div>
      )}
      {metadata.action && (
        <button
          type="button"
          aria-labelledby="action-button"
          className={classnames(metadata.classNames?.action || styles['edit-button'])}
          onClick={actionHandler}
        >
          {metadata.action === ActionTypes.EDIT && <img src={EditSvg} alt="edit" />}
          {metadata.action === ActionTypes.ALERT && (
            <i className={classnames('material-icons')}>error_outline</i>
          )}
        </button>
      )}
      {children}
    </div>
  );
}

export default ReadOnly;
