export type newDealStateType = {
  dealType: string;
  dealSubType: string;
  dealStage: string | number;
  dealAmount: number;
  ownerList: any[];
  selectedOwner: any;
  opportunityId: string;
  isFetchingUsers: boolean;
  isCreatingDeal: false;
};

export enum ActionType {
  SET_DEAL_TYPE,
  SET_DEAL_SUBTYPE,
  SET_DEAL_STAGE,
  SET_DEAL_AMOUNT,
  SET_OWNER_LIST,
  SET_SELECTED_OWNER,
  SET_OPPORTUNITY_ID,
  SET_IS_FETCHING_USERS,
  SET_IS_CREATING_DEAL,
}

export type Action = {
  type: ActionType;
  payload: any;
};

export function newDealReducer(state: newDealStateType, action: Action) {
  switch (action.type) {
    case ActionType.SET_DEAL_TYPE:
      return {
        ...state,
        dealType: action.payload,
      };

    case ActionType.SET_DEAL_SUBTYPE:
      return {
        ...state,
        dealSubType: action.payload,
      };
    case ActionType.SET_DEAL_STAGE:
      return {
        ...state,
        dealStage: action.payload,
      };
    case ActionType.SET_DEAL_AMOUNT:
      return {
        ...state,
        dealAmount: action.payload,
      };
    case ActionType.SET_OWNER_LIST:
      return {
        ...state,
        ownerList: action.payload,
      };
    case ActionType.SET_SELECTED_OWNER:
      return {
        ...state,
        selectedOwner: action.payload,
      };
    case ActionType.SET_OPPORTUNITY_ID:
      return {
        ...state,
        opportunityId: action.payload,
      };
    case ActionType.SET_IS_FETCHING_USERS:
      return {
        ...state,
        isFetchingUsers: action.payload,
      };
    case ActionType.SET_IS_CREATING_DEAL:
      return {
        ...state,
        isCreatingDeal: action.payload,
      };

    default:
      return state;
  }
}
