import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import DocumentTable from './components/documentTable';
import UploadDocument from 'pages/documentLibrary/components/uploadDocument';
import { documentLibraryActionType, documentLibraryReducer } from './store/documentLibrary.reducer';
import { getCompanyDocuments } from '_shared/api/documents';
import { CompanyDetailsContext } from '../companyDetails/store/companyDetails.reducer';

const DocumentLibrary: React.FC = () => {
  const { refreshCompanyData } = useContext(CompanyDetailsContext);
  const { id: companyId } = useParams();

  const [state, dispatch] = useReducer(documentLibraryReducer, {
    data: [],
    loading: true,
    error: false,
  });

  const fetchDocumentList = async (id: string) => {
    // To reload document counter in company details tab bar after adding or deleting documents
    void refreshCompanyData();

    try {
      dispatch({
        type: documentLibraryActionType.SET_DOCUMENTS,
        payload: { loading: true },
      });
      const { data } = await getCompanyDocuments(id);
      dispatch({
        type: documentLibraryActionType.SET_DOCUMENTS,
        payload: { data },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: documentLibraryActionType.SET_DOCUMENTS,
        payload: { error: true },
      });
    } finally {
      dispatch({
        type: documentLibraryActionType.SET_DOCUMENTS,
        payload: { loading: false },
      });
    }
  };

  useEffect(() => {
    if (!companyId) return;
    fetchDocumentList(companyId);
  }, [companyId]);

  return (
    <>
      <UploadDocument
        {...{ companyId: companyId || '', fetchDocumentList, uploadedDocuments: state.data }}
      />
      <DocumentTable
        {...{
          companyId: companyId || '',
          documentList: state.data,
          loading: state.loading,
          error: state.error,
          fetchDocumentList,
        }}
      />
    </>
  );
};

export default DocumentLibrary;
