import { TablePropType } from 'components/table/type';
import styles from 'pages/tasksManagement/assets/tasks.module.scss';
import { OrderByDirectionType, TaskStatusEnum } from '../types';
import { RoutePath } from '_shared/routes';
import { TLinkTarget } from 'components/table/cell/type';
import { convertToLocalDateFormat } from '_shared/utils/date';
import {
  convertHtmlToEditorState,
  getPlainTextFromEditorState,
} from 'components/field/textEditor/utils';

export const field = (
  items: Array<{ [key: string]: any }>,
  orderDirection: OrderByDirectionType,
  handleOnHeaderSort: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void,
  handleStatusChange: Function
): TablePropType => {
  const { sortBy: sortOrder, direction } = orderDirection || {};
  const updatedItems: any = items.map((item: { [key: string]: any }) => {
    const rowStyleChange = () => {
      return Math.floor(new Date(item?.dueDate).getTime() / 1000) - 86400 <
        Math.floor(new Date().getTime() / 1000)
        ? 'tr-dynamic-cell'
        : 'tr-cell';
    };

    const statusList: { label: string; value: string }[] = [
      { label: 'To do', value: TaskStatusEnum.TODO },
      { label: 'In progress', value: TaskStatusEnum.INPROGRESS },
      { label: 'Done', value: TaskStatusEnum.DONE },
    ];

    const taskDetails = getPlainTextFromEditorState(convertHtmlToEditorState(item.content));

    return [
      {
        classname: {
          cell: styles[rowStyleChange()],
          value: styles['td-value'],
        },
        default: item.subject,
        id: item.id,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          toolTip: styles['tool-tip'],
        },
        tooltip: taskDetails,
        icon: item.content && 'info',
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.companyName,
        link: RoutePath.companyoverview.replace(':id', item.companyId),
        linkTarget: TLinkTarget.BLANK,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.fullName,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: convertToLocalDateFormat(item.dateCreated),
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.assignedToFullName,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: convertToLocalDateFormat(item.dueDate),
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-custom-select'],
        },
        default:
          statusList.find((status) => status.value === item.taskStatus)?.label || item.taskStatus,
        // v2 optimisation
        // statusSelector: true,
        // status: item.taskStatus,
        // selectedStatus: item.taskStatus,
        // key: item.id + item.taskStatus,
        // id: item.id,
        // statusList,
        // actionCb: (event: any) => handleStatusChange(event.target.value, item)
      },
    ];
  });
  return {
    header: [
      {
        value: 'Subject',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Detail',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Client Name',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Created By',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Created Date',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Assignee',
        classname: {
          header: styles['th-header'],
        },
        disabled: false,
      },
      {
        value: 'Due Date',
        sortBy: 'dueDate',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('dueDate')) && direction) || '',
        isSortable: true,
        callback: handleOnHeaderSort,
      },
      {
        value: 'Status',
        sortBy: 'taskStatus',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('taskStatus')) && direction) || '',
        isSortable: true,
        callback: handleOnHeaderSort,
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    emptyMessage: 'No tasks found',
    classname: { table: styles.table },
    items: updatedItems,
  };
};
