import { UPDATE_COMPANY, CompanyState, CompanyActionTypes } from './types';

export const initialCompanyState: CompanyState = {
  companyId: '',
  companyIndexState: '',
  selectedFilters: null,
  selectedUserIds: [],
  companyName: '',
  searchBy: 'CompanyName',
  pageNumber: 1,
  refreshIndexPage: 0,
  filter: {
    orderBy: '',
    direction: 'desc',
  },
  companyData: {},
};

export function companyReducer(state: CompanyState, action: CompanyActionTypes): CompanyState {
  switch (action.type) {
    case UPDATE_COMPANY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
