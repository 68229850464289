import cn from 'classnames';
import styles from 'pages/companyOverview/assets/accountWarningBanner.module.scss';
import { useTranslation } from 'react-i18next';
import { isBrokerApplication } from '_shared/utils/application';

const AccountWarningBanner = ({ accountInfoData }: { accountInfoData: any }) => {
  const { t } = useTranslation();

  const accountInfoWarnings = [
    {
      shouldShow: accountInfoData.isNotTrustPilot,
      label: 'home:companydetails:overview:accountinfo:warningbanner:trustpilot',
    },
    {
      shouldShow: accountInfoData.isEnhancedDueDiligence,
      label: 'home:companydetails:overview:accountinfo:warningbanner:duediligence',
    },
    {
      shouldShow: accountInfoData.isBlacklisted,
      label: 'home:companydetails:overview:accountinfo:warningbanner:blacklisted',
    },
    {
      shouldShow: accountInfoData.hasRaisedComplaint,
      label: 'home:companydetails:overview:accountinfo:warningbanner:raisedcomplaint',
    },
    {
      shouldShow: accountInfoData.hasGivenFundingApplicationConsent === false,
      label: 'home:companydetails:overview:accountinfo:warningbanner:applicationconsent',
    },
  ];

  const displayWarningBanner = () => {
    if (
      accountInfoData.isNotTrustPilot ||
      accountInfoData.isEnhancedDueDiligence ||
      accountInfoData.isBlacklisted ||
      accountInfoData.hasRaisedComplaint ||
      accountInfoData.hasGivenFundingApplicationConsent === false
    ) {
      return true;
    }
  };

  return (
    <>
      {isBrokerApplication && displayWarningBanner() && (
        <div className={cn(styles['warning-container'])} data-testid="warning-banner">
          <header>
            <h3 className={cn(styles['warning-header'])}>
              {t('home:companydetails:overview:accountinfo:warningbanner:header')}
            </h3>
          </header>
          <div className={cn(styles['warnings'])}>
            {accountInfoWarnings.map((warning) => {
              return (
                warning.shouldShow && (
                  <span key={warning.label} className={cn(styles['warning-flag'])}>
                    <span className={cn('material-icons', styles['warning-icon'])}>warning</span>
                    {t(warning.label)}
                  </span>
                )
              );
            }) || null}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountWarningBanner;
