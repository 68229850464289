import {
  allEquitySubCategories,
  allLoanSubCategories,
  allProductCategories,
  allSubCategories,
  setFieldToReadOnly,
} from 'pages/productInformation/fields/productInformation.fields';
import { ProductInformationFieldType } from 'pages/productInformation/types';
import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import styles from 'pages/productDocuments/assets/productDocuments.module.scss';
import i18n from 'config/i18n';

export const productDocumentFields = (
  product: ProductInformationFieldType,
  canEditProducts: boolean
) => {
  return [
    {
      id: 'forAMLWhoseInformationDoYouRequire',
      label: 'For AML/KYC, whose information do you require?',
      value: product.forAMLWhoseInformationDoYouRequire,
      name: 'forAMLWhoseInformationDoYouRequire',
      fieldType: FieldTypes.SELECT,
      productType: allProductCategories,
      subType: allSubCategories + '',
      placeholder: '',
      required: true,
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
      selectionKey: product.forAMLWhoseInformationDoYouRequire + '',
      options: [
        { label: 'Primary Director', value: 'Primary Director' },
        { label: 'Any Director', value: 'Any Director' },
        { label: 'Any Shareholder', value: 'Any Shareholder' },
        { label: 'All Directors', value: 'All Directors' },
        { label: 'All Shareholders', value: 'All Shareholders' },
        { label: 'All Shareholders with 25%+', value: 'All Shareholders with 25%+' },
        {
          label: 'All Directors and Shareholders with >25% shareholding',
          value: 'All Directors and Shareholders with >25% shareholding',
        },
        { label: 'Case By Case basis', value: 'Case By Case basis' },
      ],
    },
    {
      id: 'howManyMonthsMustDraftAccountsBeAvailable',
      label: 'After how many months from financial year end must Draft Accounts be available?',
      value: product.howManyMonthsMustDraftAccountsBeAvailable,
      type: 'number',
      min: 0,
      max: 11,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      name: 'howManyMonthsMustDraftAccountsBeAvailable',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      classNames: {
        error: styles['validation-error'],
        errorIcon: styles['error-icon'],
        field: styles['field'],
        control: styles['input-field'],
      },
    },
    {
      id: 'howManyMonthsMustFiledAccountsBeAvailable',
      label: 'After how many months from financial year end must Filed Accounts be available?',
      value: product.howManyMonthsMustFiledAccountsBeAvailable,
      type: 'number',
      min: 0,
      max: 11,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      name: 'howManyMonthsMustFiledAccountsBeAvailable',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      classNames: {
        error: styles['validation-error'],
        errorIcon: styles['error-icon'],
        field: styles['field'],
        control: styles['input-field'],
      },
    },
    {
      id: 'filedAuditAccounts',
      label: 'Filed Accounts',
      value: product.filedAuditAccounts,
      name: 'filedAuditAccounts',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.filedAuditAccounts + '',
      options: [
        {
          parentId: 'filedAuditAccounts',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'filedAuditAccounts',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasBankStatements',
      label: 'Company Bank Statements',
      value: product.hasBankStatements,
      name: 'hasBankStatements',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasBankStatements + '',
      options: [
        {
          parentId: 'hasBankStatements',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasBankStatements',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'bankStatements',
      label: 'How many months?',
      value: product.bankStatements,
      name: 'bankStatements',
      fieldType: FieldTypes.SELECT,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
      selectionKey: product.bankStatements + '',
      placeholder: '',
      required: true,
      options: [
        { label: '3 Months', value: 3 },
        { label: '6 Months', value: 6 },
        { label: '12 Months', value: 12 },
      ],
      dependsOn: {
        name: 'hasBankStatements',
        value: 'true',
      },
    },
    {
      id: 'hasManagementAccounts',
      label: 'Management Accounts',
      value: product.hasManagementAccounts,
      name: 'hasManagementAccounts',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasManagementAccounts + '',
      options: [
        {
          parentId: 'hasManagementAccounts',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasManagementAccounts',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'cashFlowForecast',
      label: 'Cashflow Forecast',
      value: product.cashFlowForecast,
      name: 'cashFlowForecast',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.cashFlowForecast + '',
      options: [
        {
          parentId: 'cashFlowForecast',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'cashFlowForecast',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasTaxClearance',
      label: 'Proof of Tax Clearance',
      value: product.hasTaxClearance,
      name: 'hasTaxClearance',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasTaxClearance + '',
      options: [
        {
          parentId: 'hasTaxClearance',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasTaxClearance',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasVATReturns',
      label: i18n.t('general:vatreturns'),
      value: product.hasVATReturns,
      name: 'hasVATReturns',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasVATReturns + '',
      options: [
        {
          parentId: 'hasVATReturns',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasVATReturns',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'isSummaryAgedDebtors',
      label: 'Summary Aged Debtors report',
      value: product.isSummaryAgedDebtors,
      name: 'isSummaryAgedDebtors',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.isSummaryAgedDebtors + '',
      options: [
        {
          parentId: 'isSummaryAgedDebtors',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSummaryAgedDebtors',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'isDetailedAgedDebtors',
      label: 'Detailed Aged Debtors report',
      value: product.isDetailedAgedDebtors,
      name: 'isDetailedAgedDebtors',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.isDetailedAgedDebtors + '',
      options: [
        {
          parentId: 'isDetailedAgedDebtors',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isDetailedAgedDebtors',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'isSummaryAgedCreditors',
      label: 'Summary Aged Creditors report',
      value: product.isSummaryAgedCreditors,
      name: 'isSummaryAgedCreditors',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.isSummaryAgedCreditors + '',
      options: [
        {
          parentId: 'isSummaryAgedCreditors',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSummaryAgedCreditors',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'isDetailedAgedCreditors',
      label: 'Detailed Aged Creditors report',
      value: product.isDetailedAgedCreditors,
      name: 'isDetailedAgedCreditors',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.isDetailedAgedCreditors + '',
      options: [
        {
          parentId: 'isDetailedAgedCreditors',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isDetailedAgedCreditors',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasFixedAsset',
      label: i18n.t('home:products:fields:fixedAssetSchedule:label') + '?',
      value: product.hasFixedAsset,
      name: 'hasFixedAsset',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasFixedAsset + '',
      options: [
        {
          parentId: 'hasFixedAsset',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasFixedAsset',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'isOutstandingDebt',
      label: 'A schedule of Outstanding Debt?',
      value: product.isOutstandingDebt,
      name: 'isOutstandingDebt',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.isOutstandingDebt + '',
      options: [
        {
          parentId: 'isOutstandingDebt',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isOutstandingDebt',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasShareAndDirectorsRegister',
      label: 'A certified copy of the Share register and Directors register?',
      value: product.hasShareAndDirectorsRegister,
      name: 'hasShareAndDirectorsRegister',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasShareAndDirectorsRegister + '',
      options: [
        {
          parentId: 'hasShareAndDirectorsRegister',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasShareAndDirectorsRegister',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasCertificateOfIncorporation',
      label: i18n.t('home:products:fields:certifiedCopyOfIncorporation:label') + '?',
      value: product.hasCertificateOfIncorporation,
      name: 'hasCertificateOfIncorporation',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasCertificateOfIncorporation + '',
      options: [
        {
          parentId: 'hasCertificateOfIncorporation',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasCertificateOfIncorporation',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasSalie',
      label: 'SALIE (Statement of Assets and Liabilities and Income and Expenditure)',
      value: product.hasSalie,
      name: 'hasSalie',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasSalie + '',
      options: [
        {
          parentId: 'hasSalie',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasSalie',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasApplicationForm',
      label: 'A Completed Application form?',
      value: product.hasApplicationForm,
      name: 'hasApplicationForm',
      fieldType: FieldTypes.RADIO,
      productType: allProductCategories,
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasApplicationForm + '',
      options: [
        {
          parentId: 'hasApplicationForm',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasApplicationForm',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasPitchDeck',
      label: 'A Pitch Deck?',
      value: product.hasPitchDeck,
      name: 'hasPitchDeck',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasPitchDeck + '',
      options: [
        {
          parentId: 'hasPitchDeck',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasPitchDeck',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasBusinessPlan',
      label: 'A Business Plan?',
      value: product.hasBusinessPlan,
      name: 'hasBusinessPlan',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasBusinessPlan + '',
      options: [
        {
          parentId: 'hasBusinessPlan',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasBusinessPlan',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasFinancialModel',
      label: 'A Financial Model?',
      value: product.hasFinancialModel,
      name: 'hasFinancialModel',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasFinancialModel + '',
      options: [
        {
          parentId: 'hasFinancialModel',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasFinancialModel',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'hasFinancialForecast',
      label: 'A Financial Forecast?',
      value: product.hasFinancialForecast,
      name: 'hasFinancialForecast',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      selectionKey: product.hasFinancialForecast + '',
      options: [
        {
          parentId: 'hasFinancialForecast',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasFinancialForecast',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'additionalDocuments',
      label: 'Please list any additional documents required',
      value: product.additionalDocuments,
      name: 'additionalDocuments',
      fieldType: FieldTypes.TEXT,
      productType: ['Equity', 'Grant', 'Loan'],
      subType: allSubCategories + '',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      classNames: {
        field: styles['field'],
        control: styles['input-field'],
      },
    },
  ];
};
