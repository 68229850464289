import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import { Action } from 'pages/documentLibrary/store/documentLibrary.reducer';

export type APIDocumentType = {
  documentId: string;
  name: string;
  type: string;
  isBrokerDocument?: boolean;
  uploadedDate: string;
  title: string;
  documentSize: number;
  isNew?: boolean;
  file?: any;
  isEdit?: boolean;
};

export type DocumentField = FieldMetaDataPropType & { isNew?: boolean; file: any };

export type NewDocumentType = {
  id: string;
  selectedCategory: string;
  selectedTitle: string;
  fileName: string;
  file: any;
};

export type DocumentMenuType = {
  id: string;
  label: string;
  value: string;
  count: number;
  totalCount: number;
};

export type DocumentComponentPropType = {
  initialState: any;
  isEdit: boolean;
  companyId: string;
  deleteDocument: Function;
  dispatch: React.Dispatch<Action>;
};

export type DocumentGroupType = { [key: string]: Array<{ label: string; value: string }> };

export enum docTypeEnum {
  FINANCILAS = 'Financials',
  OWNERSHIP = 'Ownership',
  APPLICATIONS = 'Applications',
  FUNDING = 'FundingDocuments',
  IDENTIFICATION = 'Identification',
  OTHER = 'Other',
}

export type DocumentActionType = {
  delete?: Function;
  edit?: Function;
  download?: Function;
  saveEdit?: Function;
  preview?: Function;
  select?: Function;
  selectAll?: Function;
  changeField?: Function;
  sort?: any;
};

export enum PdfTypesEnum {
  TRUELAYER = 'trueLayer',
}

export type SelectAllDocumentItemsType = {
  id: string;
  icon: string;
  i18n: string;
  clickHandler: Function;
  loading?: boolean;
};
