import cn from 'classnames';
import styles from 'pages/companyOverview/assets/fundingRequirements.module.scss';
import { useTranslation } from 'react-i18next';
import Tabs from 'components/tabs';
import { ActiveTabs } from './types';
import Spinner from 'components/spinner';
import ErrorComponent from 'components/error';
import FundingRequirementsHook from 'pages/companyOverview/hooks/fundingRequirements.hook';
import Field from 'components/field';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import BlockNavigationModal from 'components/BlockNavigationModal';
import { TTab } from 'pages/companyOverview/store/fundingRequirements.reducer';
import Modal from 'components/modal';
import { useState } from 'react';
import { fundingPurposes } from '_shared/utils/constants';
import Tooltip from 'components/tooltip';
import { ScoreGoalsType } from 'pages/companyOverview/types';

const FundingRequirements = ({
  companyId,
  defaultTab,
}: {
  companyId: string;
  defaultTab?: ActiveTabs | string;
}) => {
  const { t } = useTranslation();
  const [formMigrationSelected, setFormMigrationSelected] = useState<string>();

  const handleFormMigrationSelectedChange = (event: any) => {
    const { value } = event.target;
    setFormMigrationSelected(value as string);
  };

  const {
    tabs,
    completedFundingForms,
    formMigrationModalOpen,
    openFormMigrationModal,
    closeFormMigrationModal,
    loading,
    error,
    activeTab,
    selectTab,
    isSaving,
    ableToSave,
    handleChange,
    handleSubmit,
    handleLoansFormMigration,
    metadata,
    formErrors,
    scoreGoalsHistory,
    isSwoopGroupBroker,
  } = FundingRequirementsHook(companyId || '', defaultTab);

  const getTabItems = () => {
    return tabs.map(
      (
        tab: TTab
      ): {
        keys: string[];
        title: any;
      } => {
        return {
          keys: [tab.formId],
          title: (
            <>
              <span>{t(`${tab.label}`)}</span>
            </>
          ),
        };
      }
    );
  };

  return (
    <>
      <div className={cn(styles['requirements-container'])} data-testid="funding-requirements">
        {(!defaultTab && (
          <Tabs
            {...{
              items: getTabItems(),
              activeKey: activeTab,
              activeKeys: completedFundingForms,
              onChangeTab: (tab: string) => selectTab(tab),
              classNames: {
                tabs: cn(styles.tabs),
                tabItem: cn(styles.tabItem),
                tabTitle: cn(styles.tabTitle),
                activeTab: cn(styles.activeTab),
                availableTab: cn(styles.availableTab),
              },
            }}
          />
        )) || <h1>{tabs.find((tab: any) => tab.formId === defaultTab)?.label}</h1>}
        {isSwoopGroupBroker && scoreGoalsHistory.length > 0 && (
          <div className={cn(styles['score-goals-container'])}>
            <label className={cn(styles['score-goals-label'])}>
              {t(scoreGoalsHistory[0]?.score)}
            </label>
            <Tooltip
              className={cn(styles['tooltip'])}
              content={
                <table>
                  <tbody>
                    {scoreGoalsHistory.map((score: ScoreGoalsType) => {
                      return (
                        <tr className={cn(styles['tooltip-table'])} key={score?.score}>
                          <td>{score?.score}</td>
                          <td>{score?.periodStart}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            >
              <span className={cn('material-icons', styles['tooltip-icon'])}>info</span>
            </Tooltip>
          </div>
        )}
        <section className={cn(styles['content-container'])}>
          {(loading && (
            <div className={cn(styles['error-container'])}>
              <Spinner size="large" />
            </div>
          )) ||
            (error && (
              <div className={cn(styles['error-container'])}>
                <ErrorComponent message={`Could not find ${activeTab} data`} />
              </div>
            )) || (
              <div className={cn(styles['content'])}>
                {metadata.map((field: any) => {
                  return (
                    <Field
                      key={field.id}
                      metadata={field}
                      errors={formErrors}
                      handleChange={handleChange}
                    />
                  );
                })}
              </div>
            )}
        </section>
        <div className={cn(styles['save-container'])}>
          {activeTab !== ActiveTabs.LOANS ? (
            <Button
              id="save-funding-requirements"
              ariaLabel="save-funding-requirements"
              className={cn(styles['save-button'])}
              clickHandler={handleSubmit}
              loading={isSaving}
              disabled={!ableToSave}
              buttonStyleType={
                ableToSave ? ButtonStyleTypeEnum.PRIMARY : ButtonStyleTypeEnum.DISABLED
              }
            >
              {t('home:productdetails:documents:save')}
            </Button>
          ) : (
            <Button
              id="migrate-funding-requirements"
              ariaLabel="migrate-funding-requirements"
              className={cn(styles['save-button'])}
              clickHandler={openFormMigrationModal}
              loading={isSaving}
              disabled={completedFundingForms.length > 1}
              buttonStyleType={
                completedFundingForms.length === 1
                  ? ButtonStyleTypeEnum.PRIMARY
                  : ButtonStyleTypeEnum.DISABLED
              }
            >
              {t('home:forms:migrate')}
            </Button>
          )}
        </div>
      </div>
      <Modal show={formMigrationModalOpen} handleClose={() => closeFormMigrationModal()}>
        <div className={cn(styles['form-migration-modal'])}>
          <h1 className={'sw-h1'}>Choose a goal</h1>
          <Field
            key={'form-migration-type'}
            metadata={{
              id: 'sectors',
              label: `Please select the goal you’d like to migrate to`,
              placeholder: 'Select goal',
              name: 'sectors',
              value: '',
              validationtype: ValidationTypes.SELECT,
              fieldType: FieldTypes.SELECT,
              options: tabs
                .filter(({ formId }: TTab) =>
                  fundingPurposes.find(
                    (fundingPurpose) =>
                      fundingPurpose?.formId?.toLocaleLowerCase() === formId?.toLocaleLowerCase()
                  )
                )
                .map(({ label, formId }: TTab) => ({
                  label,
                  value: formId,
                })),
            }}
            errors={formErrors}
            handleChange={handleFormMigrationSelectedChange}
          />

          <Button
            id={'migrate-form-submit-button'}
            ariaLabel={'Migrate form submit button'}
            className={styles['activity']}
            clickHandler={() =>
              formMigrationSelected && handleLoansFormMigration(formMigrationSelected)
            }
          >
            <i className={'material-icons'}>{}</i>
            <span>{t('home:forms:confirm')}</span>
          </Button>
        </div>
      </Modal>
      <BlockNavigationModal shouldBlock={ableToSave} />
    </>
  );
};

export default FundingRequirements;
