export interface ICompanyFilter {
  label: string;
  param: string;
  count?: number;
  value: any;
}

export enum SearchByOptions {
  CompanyName = 'CompanyName',
  ContactName = 'ContactName',
  ContactEmail = 'ContactEmail',
  ContactNumber = 'ContactNumber',
}

export interface ICompaniesFiltersProps {
  isSearching: boolean;
  onSearch: (keyword: string, searchBy: SearchByOptions) => void;
}

export enum EngagementStateEnum {
  ENGAGEMENT = 'Engagement',
  QUALIFIED = 'Qualified',
  MATCHING = 'Matching',
  SUCCESSFUL = 'Successful',
  FUTURE = 'Future',
  DEAD = 'Dead',
}

export enum LeadScoreEnum {
  COPPER = 'Copper',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
}

export enum FiterParams {
  ALL = 'All',
  UNASSIGNED = 'Unassign',
  NEW = 'OnlyNew',
  ENGAGEMENTSTATE = 'EngagementState',
  LEADSCORE = 'LeadScore',
}

export const availableFilters: Array<ICompanyFilter> = [
  {
    label: 'All',
    param: FiterParams.ALL,
    value: true,
  },
  {
    label: 'Unassign',
    param: FiterParams.UNASSIGNED,
    value: true,
  },
  {
    label: 'New',
    param: FiterParams.NEW,
    value: true,
  },
];
