import { FieldMetaDataPropType, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';

export const emailClientFields = (): Array<FieldMetaDataPropType> => {
  return [
    {
      id: 'firstName',
      name: 'firstName',
      placeholder: 'First name',
      label: 'First Name',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
    },
    {
      id: 'lastName',
      name: 'lastName',
      placeholder: 'Last name',
      label: 'Last name',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
    },
    {
      id: 'email',
      name: 'email',
      label: 'Email address',
      placeholder: 'email@example.com',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.EMAIL,
      type: 'email',
    },
    {
      id: 'groupId',
      name: 'groupId',
      label: 'Group',
      value: '',
      required: false,
      options: [],
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
    },
    {
      id: 'fundingReason',
      name: 'fundingReason',
      label: "Client's reason for funding",
      required: false,
      placeholder: 'Unselected',
      options: [],
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
    },
  ];
};
