import { displayStringValue } from '_shared/utils/string';
import { TablePropType } from 'components/table/type';
import styles from 'pages/companies/assets/companies.module.scss';
import features from 'config/features';
import i18next from 'i18next';
import { appSpecific, isAdvisorApplication } from '_shared/utils/application';
import { convertToCurrency } from '_shared/utils/currency';
import { RoutePath } from '_shared/routes';
import { TLinkTarget } from 'components/table/cell/type';
import { convertToLocalDateFormat } from '_shared/utils/date';

export const field = (
  items: Array<{ [key: string]: any }>,
  headerCb: (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void,
  order: { order: string; direction: string } = { order: '', direction: '' },
  fundingManagerLabel?: any,
  accountOwnerLabel?: any,
  setItemToDelete?: any
): TablePropType => {
  const { order: sortOrder, direction } = order || {};

  const getLastAssigmentDate = (item: any) => {
    const lastAssigmentDate = appSpecific(
      item.fundingManagerLastAssignmentDate,
      item.accountOwnerLastAssignmentDate
    );

    if (lastAssigmentDate) {
      return convertToLocalDateFormat(lastAssigmentDate);
    } else {
      return '';
    }
  };
  const updatedItems: any = items.map((item: { [key: string]: any }) => {
    return [
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: displayStringValue(item?.name),
        tooltip: displayStringValue(item?.name),
        id: item.id,
        link: RoutePath.companyoverview.replace(':id', item.id),
        linkTarget: TLinkTarget.BLANK,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: getLastAssigmentDate(item),
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.signUpDate ? convertToLocalDateFormat(item.signUpDate) : 'N/A',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: !isNaN(item.turnoverLastYear) ? convertToCurrency(item.turnoverLastYear) : 'N/A',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: !isNaN(item.fundingRequired) ? convertToCurrency(item.fundingRequired) : 'N/A',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.incorporationDate ? convertToLocalDateFormat(item.incorporationDate) : 'N/A',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.numberOfDeals || '0',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.lastContactDate ? convertToLocalDateFormat(item.lastContactDate) : 'N/A',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.numberOfCalls || '0',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.numberOfEmails || '0',
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item.referredBy,
        tooltip: item.referredBy,
        disabled: isAdvisorApplication,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        children: fundingManagerLabel && fundingManagerLabel(item.id),
        disabled: !features.assignUsersEnabled,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        children: accountOwnerLabel && accountOwnerLabel(item.id),
        disabled: !features.assignUsersEnabled,
      },
      {
        classname: {
          cell: styles['tr-cell-label'],
        },
        id: `${item.id}-delete`,
        icon: 'delete',
        default: '',
        disabled: isAdvisorApplication,
        actionCb: (e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
          e.stopPropagation();
          setItemToDelete(item);
        },
      },
    ].filter((item: any) => item.disabled !== true);
  });
  return {
    header: [
      {
        value: 'Company name',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Last assignment',
        sortBy: appSpecific('fundingManagerLastAssignmentDate', 'accountOwnerLastAssignmentDate'),
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction:
          (Boolean(
            sortOrder &&
              sortOrder.includes(
                appSpecific('fundingManagerLastAssignmentDate', 'accountOwnerLastAssignmentDate')
              )
          ) &&
            direction) ||
          '',
        isSortable: true,
        callback: headerCb,
        disabled: false,
      },
      {
        value: 'Signup',
        sortBy: 'signUpDate',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('signUpDate')) && direction) || '',
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Turnover',
        sortBy: 'turnoverLastYear',
        direction:
          (Boolean(sortOrder && sortOrder.includes('turnoverLastYear')) && direction) || '',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        disabled: !features.assignUsersEnabled,
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Requirement',
        sortBy: 'totalFundingRequired',
        direction:
          (Boolean(sortOrder && sortOrder.includes('totalFundingRequired')) && direction) || '',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        disabled: !features.assignUsersEnabled,
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Incorporation',
        sortBy: 'incorporationDate',
        direction:
          (Boolean(sortOrder && sortOrder.includes('incorporationDate')) && direction) || '',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        disabled: !features.assignUsersEnabled,
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Deals',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Contacted',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Calls',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Emails',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Referral',
        classname: {
          header: styles['th-header'],
        },
        disabled: isAdvisorApplication,
      },
      {
        value: 'Funding Manager',
        classname: {
          header: styles['th-header'],
        },
        tooltip: i18next.t('home:applicationsClient:tooltips:broker:fundingmanager'),
        disabled: !features.assignUsersEnabled,
      },
      {
        value: 'Account Owner',
        classname: {
          header: styles['th-header'],
        },
        tooltip: i18next.t('home:applicationsClient:tooltips:broker:accountowner'),
        disabled: !features.assignUsersEnabled,
      },
      {
        value: '',
        classname: {
          header: styles['th-header'],
        },
        disabled: isAdvisorApplication,
      },
    ].filter((item) => item.disabled !== true),
    row: {
      classname: styles['tr-row'],
    },
    emptyMessage: 'No companies found',
    classname: { table: styles.table },
    items: updatedItems,
  };
};
