import { TablePropType } from 'components/table/type';
import classNames from 'classnames';
import { displayStringValue } from '_shared/utils/string';
import styles from '../assets/companyCharges.module.scss';

export const fields = (items: Array<{ [key: string]: any }>): TablePropType => {
  const updatedItems = items.map((item: { [key: string]: any }, index: number) => {
    return [
      {
        id: `${index}-margin-left`,
        default: '',
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
      },
      {
        id: item.id,
        default: displayStringValue(item?.classification, { applySentenceCase: true }),
        tooltip: true,
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
      },
      {
        classname: {
          cell: classNames(styles['tr-cell']),
        },
        default: item?.delivered,
      },
      {
        classname: {
          cell: styles['tr-cell'],
        },
        default: item?.status,
      },

      {
        classname: {
          cell: styles['tr-cell'],
          value: styles.link,
          iconLink: styles['link-icon'],
        },
        default: item?.personsEntitled,
      },
      {
        id: `${index}-margin-right`,
        default: '',
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
      },
    ];
  });

  const headings = ['Classification', 'Delivered', 'Status', 'Persons entitled']?.map(
    (header: any) => {
      return {
        id: header,
        value: header,
        classname: {
          header: styles['th-header'],
          value: styles['th-label'],
          label: styles['th-label'],
        },
      };
    }
  );

  return {
    header: [
      {
        id: 'header-margin-left',
        value: ' ',
        classname: {
          header: styles['th-header'],
          value: styles['th-label'],
          label: styles['th-label'],
        },
      },
      ...headings,
      {
        id: 'header-margin-right',
        value: ' ',
        classname: {
          header: styles['th-header'],
          value: styles['th-label'],
          label: styles['th-label'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: updatedItems,
  };
};
