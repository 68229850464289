import { convertToLocalDateFormat } from '_shared/utils/date';
import { convertToCurrency } from '_shared/utils/currency';
import { roundTwoDecimal } from '_shared/utils/rounding';
import { FinancialSnapshotFields } from '../types';
import PositivePerformance from 'assets/images/icon/positive-return.svg';
import NegativePerformance from 'assets/images/icon/negative-return.svg';

export function financialSnapshotFields(initialValue: {
  [key: string]: any;
}): Array<FinancialSnapshotFields> {
  const { totalLiabilities, totalAssets, debtRatio, netAssets, turnover } = initialValue;

  const cashBalances =
    initialValue?.balances?.length &&
    initialValue?.balances.map((balance: { [key: string]: any }) => {
      return {
        label: balance.accountName,
        value: convertToCurrency(balance.currentBalance),
      };
    });

  return [
    {
      id: 'financialsnapshot-yearend',
      label:
        (!initialValue.isCalendarFinancialYearEndUsed &&
          'home:companydetails:overview:financialsnapshot:yearend') ||
        'home:companydetails:overview:financialsnapshot:calendaryearend',
      value: (initialValue?.yearEnd && convertToLocalDateFormat(initialValue?.yearEnd)) || '',
    },
    {
      id: 'financialsnopshot-turnover',
      label: 'home:companydetails:overview:financialsnapshot:turnover',
      value:
        ((totalLiabilities?.current ||
          totalLiabilities?.difference ||
          totalLiabilities?.percentage) &&
          convertToCurrency(turnover.current)) ||
        '',
      details: {
        current: turnover?.current,
        isPositive: turnover?.difference > 0,
        performance: turnover?.difference
          ? `${convertToCurrency(turnover?.difference)} (${roundTwoDecimal(turnover?.percentage)}%)`
          : '0%',
        icon: turnover?.difference > 0 ? PositivePerformance : NegativePerformance,
      },
    },
    {
      id: 'financialsnopshot-employees',
      label: 'home:companydetails:overview:financialsnapshot:employees',
      value: initialValue?.employessCount,
    },
    {
      id: 'financialsnapshot-totalliabilities',
      label: 'home:companydetails:overview:financialsnapshot:liabilities',
      value:
        ((totalLiabilities?.current ||
          totalLiabilities?.difference ||
          totalLiabilities?.percentage) &&
          convertToCurrency(totalLiabilities?.current)) ||
        '',
      details: {
        current: totalLiabilities?.current,
        isPositive: totalLiabilities?.difference > 0,
        performance: totalLiabilities?.difference
          ? `${convertToCurrency(totalLiabilities?.difference)} (${roundTwoDecimal(
              totalLiabilities?.percentage
            )}%)`
          : '0%',
        icon: totalLiabilities?.difference > 0 ? PositivePerformance : NegativePerformance,
      },
    },
    {
      id: 'financialsnapshot-totalassets',
      label: 'home:companydetails:overview:financialsnapshot:assets',
      value:
        ((totalAssets?.current || totalAssets?.difference || totalAssets?.percentage) &&
          convertToCurrency(totalAssets?.current)) ||
        '',
      details: {
        current: totalAssets?.current,
        isPositive: totalAssets?.difference > 0,
        performance: totalAssets?.difference
          ? `${convertToCurrency(totalAssets?.difference)} (${roundTwoDecimal(
              totalAssets?.percentage
            )}%)`
          : '0%',
        icon: totalAssets?.difference > 0 ? PositivePerformance : NegativePerformance,
      },
    },
    {
      id: 'financialsnapshot-netassets',
      label: 'home:companydetails:overview:financialsnapshot:netassets',
      value:
        ((netAssets?.current || netAssets?.difference || netAssets?.percentage) &&
          convertToCurrency(roundTwoDecimal(netAssets?.current))) ||
        '',
      details: {
        current: netAssets?.current,
        isPositive: netAssets?.difference > 0,
        performance: netAssets?.difference
          ? `${convertToCurrency(netAssets?.difference)} (${roundTwoDecimal(
              netAssets?.percentage
            )}%)`
          : '0%',
        icon: netAssets?.difference > 0 ? PositivePerformance : NegativePerformance,
      },
    },
    {
      id: 'financialsnapshot-debtratio',
      label: 'home:companydetails:overview:financialsnapshot:debtratio',
      value:
        ((debtRatio?.current || debtRatio?.difference || debtRatio?.percentage) &&
          roundTwoDecimal(debtRatio?.current)) ||
        '',
      details: {
        current: debtRatio?.current,
        isPositive: debtRatio?.difference > 0,
        performance: debtRatio?.difference
          ? `${convertToCurrency(debtRatio?.difference)} (${roundTwoDecimal(
              debtRatio?.percentage
            )}%)`
          : '0%',
        icon: debtRatio?.difference > 0 ? PositivePerformance : NegativePerformance,
      },
    },
    {
      id: 'financialsnapshot-cash',
      label: 'home:companydetails:overview:financialsnapshot:cash',
      value: cashBalances || '',
    },
  ];
}
