import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';

import ContactIcon from 'assets/images/icon/contact.svg';
import { ContactType } from 'pages/companyEssentials/types';

type ContactDetailsProps = {
  contact: ContactType;
  buttonHandler: () => void;
};

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact, buttonHandler }) => {
  return (
    <div className={styles['contact-details']}>
      <div className={styles['container-details']}>
        <img
          src={ContactIcon}
          alt="contact"
          className={cn(styles['custom-icon'], styles['contact'])}
        />
        <div>
          <p className={styles['contact-name']}>{contact.name || ''}</p>
          <p className={styles['contact-phone-email']}>
            <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber || ''}</a> ·{' '}
            <a href={`mailto:${contact.email}`}>{contact.email || ''}</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
