import { APIApplicationFormType } from '../types';

export enum TApplicationSaving {
  DOWNLOADPDF,
  SAVEDRAFT,
}

export type applicationsStateType = {
  applicationForm: {
    data: APIApplicationFormType;
    fields: Array<any>;
    flattedDataForReference: any;
    loading: boolean;
    error: boolean;
    defaultValues: { [key: string]: any };
  };
  loading: {
    loading: boolean;
    saving: TApplicationSaving | null;
    sendToUser: boolean;
    export: boolean;
  };
  error: {
    loading: boolean;
  };
  serverCompletionPercentage: number;
  selectedMenuItem: string;
  ableToSave: boolean;
};
export enum ActionType {
  DATA_LOADER,
  DATA_ERROR,
  SET_SELECTED_MENU,
  SET_ABLE_TO_SAVE,
  SET_APPLICATION_FORM,
  SET_HAS_SUBMITTED_APPLICATION,
  SET_COMPLETION_PERCENT,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function editApplicationFormReducer(state: applicationsStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    case ActionType.DATA_ERROR:
      return {
        ...state,
        error: { ...state.error, ...action.payload },
      };
    case ActionType.SET_APPLICATION_FORM:
      return {
        ...state,
        applicationForm: { ...state.applicationForm, ...action.payload },
      };
    case ActionType.SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenuItem: action.payload.selectedMenuItem,
      };
    case ActionType.SET_ABLE_TO_SAVE:
      return {
        ...state,
        ableToSave: action.payload.ableToSave,
      };

    case ActionType.SET_COMPLETION_PERCENT:
      return {
        ...state,
        serverCompletionPercentage: action.payload,
      };
    default:
      return state;
  }
}
