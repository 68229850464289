import { useState } from 'react';
import cn from 'classnames';
import styles from './Menu.module.scss';
import { SavingsProductsMenuProps } from '../../types';
import downArrow from '../../assets/down-arrow.svg';
import bankingIcon from '../../assets/banking-and-investments-icon.svg';
import creditHealthIcon from '../../assets/monitor-heart.svg';
import toolsIcon from '../../assets/tools-icon.svg';
import dayToDaySavings from '../../assets/day-to-day-savings-icon.svg';
import businessInsuranceIcon from '../../assets/business-insurance-sidebar-icon.svg';

const SavingsProductsMenu = ({
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
}: Readonly<SavingsProductsMenuProps>) => {
  // is menu category expanded
  const [menuState, setMenuState] = useState<{ [key: string]: boolean }>({
    banking: true,
    creditHealth: true,
    tools: true,
    dayToDaySavings: true,
    insurance: true,
  });

  const createLink = (id: string, label: string) => {
    return (
      <button
        className={cn(styles['menu-item'], {
          [styles.active]: selectedMenuItem === id,
        })}
        onClick={() => setSelectedMenuItem(id)}
        key={id}
      >
        {label}
      </button>
    );
  };

  const createLinkContainer = (id: string, items: { id: string; label: string }[]) => {
    return (
      <div className={cn(styles['menu-link-container'], { [styles.collapsed]: !menuState[id] })}>
        {items.map((item) => createLink(item.id, item.label))}
      </div>
    );
  };

  const createCategoryHeader = (id: string, title: string, icon: string) => {
    return (
      <button
        onClick={() => handleMenuCategoryToggle(id)}
        onKeyDown={() => handleMenuCategoryToggle(id)}
        className={cn('menu-header', styles['menu-header'])}
      >
        <img
          className={cn('category-icon', styles['category-icon'])}
          src={icon}
          alt={`banking and investments icon`}
        />
        <h3 className={cn('category-title', styles['category-title'])}>{title}</h3>
        <img
          className={cn('expand-icon', styles['expand-icon'], {
            [styles['y-flip']]: menuState[id],
          })}
          src={downArrow}
          alt={`expand and collapse arrow`}
        />
      </button>
    );
  };

  const handleMenuCategoryToggle = (id: string) => {
    setMenuState((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className={cn('wrapper', styles.wrapper)} data-testid="savings-products-menu">
      {createCategoryHeader('banking', 'Banking & investments', bankingIcon)}
      {createLinkContainer('banking', menuItems.banking)}

      {createCategoryHeader('creditHealth', 'Credit Health', creditHealthIcon)}
      {createLinkContainer('creditHealth', menuItems.creditHealth)}

      {createCategoryHeader('tools', 'Tools', toolsIcon)}
      {createLinkContainer('tools', menuItems.tools)}

      {createCategoryHeader('dayToDaySavings', 'Day to day savings', dayToDaySavings)}
      {createLinkContainer('dayToDaySavings', menuItems.dayToDaySavings)}

      {createCategoryHeader('insurance', 'Insurance', businessInsuranceIcon)}
      {createLinkContainer('insurance', menuItems.insurance)}
    </div>
  );
};

export default SavingsProductsMenu;
