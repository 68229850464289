// Describing the shape of the system's slice of state
export interface ToastState {
  toastMessage: string;
}

// Describing the different ACTION NAMES available
export const TOAST_MESSAGE = 'TOAST_MESSAGE';
export const TOAST_ERROR_MESSAGE = 'TOAST_ERROR_MESSAGE';

interface ToastMessageAction {
  type: typeof TOAST_MESSAGE | typeof TOAST_ERROR_MESSAGE;
  payload: ToastState;
}

export type ToastActionTypes = ToastMessageAction;
