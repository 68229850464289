import React from 'react';
import classNames from 'classnames';
import styles from './spinner.module.scss';
import { SpinnerType } from './type';

const Spinner = ({ size, style, color }: SpinnerType) => {
  return (
    <svg
      className={classNames(styles.spinner, {
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
        [styles.alternateStyle]: size === 'alternate',
        [styles.orange]: color === 'orange',
      })}
      style={{ ...style }}
      viewBox="0 0 50 50"
    >
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  );
};

export default Spinner;
