import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import DocumentLibrary from '.';

export const documentLibraryRoutes: RouteObject[] = [
  {
    path: RoutePath.companydocumentlibrary,
    element: withTransaction('DocumentLibrary', 'component')(<DocumentLibrary />),
  },
];
