import { createPortal } from 'react-dom';
import React, { Ref, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './menu.module.scss';
import useBlur from '_shared/hooks/useBlur';
import { mergeRefs } from '_shared/utils/ref';

type VerticalAnchorOrigin = 'top' | 'center' | 'bottom';
type HorizontalAnchorOrigin = 'left' | 'center' | 'right';

export type MenuProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
  anchorOrigin?: {
    vertical?: VerticalAnchorOrigin;
    horizontal?: HorizontalAnchorOrigin;
  };
} & React.HTMLProps<HTMLDivElement>;

const Menu = React.forwardRef(
  (
    { open, anchorEl, onClose, children, className = '', anchorOrigin, ...props }: MenuProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const vAnchorOrigin = anchorOrigin?.vertical ?? 'bottom';
    const hAnchorOrigin = anchorOrigin?.horizontal ?? 'right';

    useBlur([menuRef], () => onClose?.());

    const repositionMenu = useCallback(() => {
      if (anchorEl && menuRef.current) {
        const {
          top: anchorTop,
          bottom: anchorBottom,
          left: anchorLeft,
          right: anchorRight,
        } = anchorEl.getBoundingClientRect();
        const { width: menuWidth } = menuRef.current.getBoundingClientRect();
        const { clientWidth } = document.documentElement;
        let left: number;
        let top: number;

        switch (vAnchorOrigin) {
          case 'top':
            top = anchorTop;
            break;
          case 'center':
            top = (anchorTop + anchorBottom) / 2;
            break;
          case 'bottom':
            top = anchorBottom;
            break;
        }

        switch (hAnchorOrigin) {
          case 'left':
            left = anchorLeft;
            break;
          case 'center':
            left = Math.min(
              anchorLeft + anchorEl.offsetWidth / 2 - menuWidth / 2,
              clientWidth - menuWidth
            );
            break;
          case 'right':
            left = Math.min(anchorRight, clientWidth - menuWidth);
        }

        setMenuPosition({ top, left });
      }
    }, [anchorEl, menuRef]);

    useLayoutEffect(repositionMenu, [open]);

    useEffect(() => {
      if (open) {
        window.addEventListener('resize', repositionMenu);
        window.addEventListener('scroll', repositionMenu, true);

        if (onClose) {
          window.addEventListener('click', onClose, { once: true });
        }
      }

      return () => {
        window.removeEventListener('resize', repositionMenu);
        window.removeEventListener('scroll', repositionMenu, true);
      };
    }, [open, repositionMenu]);

    return (
      <>
        {open &&
          createPortal(
            <div
              ref={mergeRefs(ref, menuRef)}
              className={cn([styles['menu'], className])}
              style={menuPosition}
              role="menu"
              {...props}
            >
              {children}
            </div>,
            document.body
          )}
      </>
    );
  }
);

export default Menu;
