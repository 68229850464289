import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Deals from '.';
import { DealDetailsPage } from './components/DealDetailsPage/DealDetailsPage';

export const dealsRoutes: RouteObject[] = [
  {
    path: RoutePath.deals,
    element: withTransaction('Deals', 'component')(<Deals />),
  },
  {
    path: RoutePath.companydeal,
    element: withTransaction('DealDetails', 'component')(<DealDetailsPage />),
  },
  {
    path: RoutePath.dealdetails,
    element: withTransaction('DealDetails', 'component')(<DealDetailsPage />),
  },
];
