import { useState } from 'react';
import styles from './Accordion.module.scss';
import cn from 'classnames';

export type AccordionProps = {
  title: string;
  className?: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  alwaysRender?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  defaultExpanded = false,
  alwaysRender = false,
  title,
  children,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div className={cn(styles['accordion'], isExpanded && styles['expanded'], className)}>
      <button
        className={styles['header']}
        aria-expanded={isExpanded}
        tabIndex={0}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={cn('material-icons', styles['arrow-icon'])}>arrow_forward_ios</span>
        {title}
      </button>
      {(isExpanded || alwaysRender) && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Accordion;
