import { AmplitudeTrackingEnum } from 'config/amplitude';

export type MenuType = {
  image?: string;
  icon?: string;
  label: string;
  path?: string;
  active?: boolean;
  url?: string;
  adminRoute?: boolean;
  hasPermission?: boolean;
  enabled?: boolean;
  testId?: string;
  amplitudeKey?: AmplitudeTrackingEnum;
};

export type CustomMenuItem = {
  icon: string;
  label: string;
  address: string;
  id: string;
};

export enum UserModalEnum {
  GROUP = 'group',
  BROKER = 'broker',
  ADVISOR = 'advisor',
  MIGRATEEXISTINGUSER = 'migrate-existing-user',
  INVITEEXISTINGUSER = 'invite-existing-user',
  ADDBUSINESS = 'add-business',
  INVITEBUSINESS = 'invite-business',
}
