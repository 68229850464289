import React, { useMemo } from 'react';
import { useAppState } from 'store';
import SidebarSlideOut from './SidebarSlideOut';
import styles from './AddNewSlideOut.module.scss';

type AddNewSlideOutProps = {
  onAddTask: () => void;
  onAddClient: () => void;
};

const AddNewSlideOut = React.forwardRef<HTMLDivElement, AddNewSlideOutProps>(
  ({ onAddTask, onAddClient }, ref) => {
    const { state: appState } = useAppState();

    const isCurrentUserInSelectedGroup = useMemo(() => {
      const selectedGroupIds = appState.system.selectedGroups?.map((group) => group.groupId);
      return (
        selectedGroupIds?.includes(appState.system.groupId || 'all') ||
        selectedGroupIds?.includes('all')
      );
    }, [appState.system]);

    return (
      <SidebarSlideOut ref={ref}>
        {isCurrentUserInSelectedGroup && (
          <button className={styles.item} onClick={onAddClient}>
            <i className="material-icons">add_business</i> Add client
          </button>
        )}
        <button className={styles.item} onClick={onAddTask}>
          <i className="material-icons-outlined">add_to_photos</i> Add task
        </button>
      </SidebarSlideOut>
    );
  }
);

export default AddNewSlideOut;
