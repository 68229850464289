import { ValidationTypes, FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';

export const fields: Array<FieldMetaDataPropType> = [
  {
    id: 'login-email',
    name: 'email',
    label: 'login:username:placeholder',
    validationtype: ValidationTypes.EMAIL,
    value: '',
    required: true,
    placeholder: 'login:username:placeholder',
    type: 'text',
    fieldType: FieldTypes.TEXT,
  },
  {
    id: 'login-password',
    name: 'password',
    label: 'login:password:placeholder',
    validationtype: ValidationTypes.TEXT,
    value: '',
    required: true,
    minimumLength: 8,
    placeholder: 'login:password:placeholder',
    type: 'password',
    fieldType: FieldTypes.TEXT,
  },
];
