import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './pagination.module.scss';
import { IPaginationProps } from './types';
import Select from 'components/field/select';
import { pageSizes } from '_shared/utils/constants';

const Pagination = ({
  currentPage,
  maxPage,
  previousPage,
  pageList,
  jump,
  nextPage,
  setGlobalPaginationState,
  handlePageSize,
  pageSize,
}: IPaginationProps) => {
  useEffect(() => {
    if (!setGlobalPaginationState) {
      return;
    }
    setGlobalPaginationState(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  return (
    <div data-testid="pagination" className={classNames(styles.pagination)}>
      <div className={classNames(styles['page-size'])}>
        <Select
          {...{
            metadata: {
              id: 'page-size-select',
              options: pageSizes,
              value: pageSize,
              classNames: {
                value: styles['page-size-selector'],
              },
            },
            errors: [],
            handleChange: handlePageSize,
          }}
        />
      </div>
      <div className={classNames(styles['start-end-container'])}>
        <button
          onClick={() => jump(0)}
          disabled={currentPage <= 1}
          className={classNames(
            currentPage <= 1 ? [styles.back, styles['back--disabled']] : styles.back
          )}
        >
          <i className={classNames('material-icons', styles['page-icon'])}>first_page</i>
        </button>
        <button
          onClick={() => previousPage()}
          disabled={currentPage <= 1}
          className={classNames(
            currentPage <= 1 ? [styles.back, styles['back--disabled']] : styles.back
          )}
        >
          <i className={classNames('material-icons', styles['page-icon'])}>chevron_left</i>
        </button>
      </div>

      <div className={classNames(styles['jump-to-page'])}>
        {pageList.map((pageIndex: number) => {
          return (
            <button
              key={pageIndex}
              value={pageIndex}
              onClick={() => jump(pageIndex)}
              className={
                currentPage === pageIndex
                  ? classNames([styles.page, styles['page--selected']])
                  : classNames(styles.page)
              }
            >
              {pageIndex}
            </button>
          );
        })}
      </div>
      <div className={classNames(styles['start-end-container'])}>
        <button
          onClick={() => nextPage()}
          disabled={currentPage === maxPage}
          className={classNames(
            currentPage === maxPage ? [styles.next, styles['next--disabled']] : styles.next
          )}
        >
          <i className={classNames('material-icons', styles['page-icon'])}>chevron_right</i>
        </button>
        <button
          onClick={() => jump(maxPage)}
          disabled={currentPage === maxPage}
          aria-label="test"
          className={classNames(
            currentPage === maxPage ? [styles.next, styles['next--disabled']] : styles.next
          )}
        >
          <i className={classNames('material-icons', styles['page-icon'])}>last_page</i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
