import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useMediaQuery from '_shared/hooks/useMediaQuery';
import { RoutePath } from '_shared/routes';
import styles from './assets/login.module.scss';
import Card from 'components/card';
import LoginSidebar from './components/loginSidebar';
import Footer from 'components/footer';

const ForgotPasswordCheckEmail: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setstate] = useState({
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
  });
  useEffect(() => {
    if (!location.state || location.state.path !== RoutePath.forgotpassword) {
      setstate({
        ...state,
        redirectToReferrer: true,
      });
    }
    return () => {
      navigate(location.pathname, {
        replace: true,
        state: {},
      });
    };
  }, [state, state.from]);
  if (state.redirectToReferrer) {
    return <Navigate to="/" />;
  }
  const email = location?.state?.email;
  return (
    <div className={styles.container}>
      <LoginSidebar />
      <div className={styles.content}>
        <Card>
          <div className={classNames(styles['login-form-container'])}>
            <h1 className="sw-h1">{t('login:forgotpassword:checkemail:title')}</h1>
            <h4 className="sw-h4">
              {t('login:forgotpassword:checkemail:instructions', { email })}
            </h4>
            <hr className="sw-divider" />
            <div
              className={classNames(
                styles['action-container'],
                styles['check-email-actions'],
                'm-t-1'
              )}
            >
              <span className="m-r-1">
                <Link to={RoutePath.login}>{t('login:forgotpassword:checkemail:signIn')}</Link>
              </span>
            </div>
          </div>
        </Card>
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPasswordCheckEmail;
