import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { addRequest, pendingRequests } from '@swoop-ltd/api-request-manager';
import { toast, ToastOptions } from 'react-toastify';
import axiosRetry from 'axios-retry';
import i18next from 'i18next';
import qs from 'qs';
import history from '../history';
import { loadState, clearState } from 'store';
import { setXGroupHeaders } from '_shared/utils/grouping';
import { BrokerAPIURL } from '../url';
import { ReactText } from 'react';
import { formattedCountry, LSKeys } from '../utils/constants';
import { applicationType } from '_shared/utils/application';
import { RoutePath } from '_shared/routes';

const nonToastStatus = [401, 422, 404];
let toastId: ReactText = 0;

function isNetworkError(err: any) {
  return !!err.isAxiosError && !err.response;
}

const bpUrl = (window as any)?._env_?.REACT_APP_BASE_URI || process.env.REACT_APP_BASE_URI;
const marketplaceUrl =
  (window as any)?._env_?.REACT_APP_MP_BASE_URI || process.env.REACT_APP_MP_BASE_URI;

const SwoopAxiosInstance = Axios.create({
  baseURL: bpUrl,
});

export const BrokerAPI = SwoopAxiosInstance;

export const MarketplaceAPI = Axios.create({
  baseURL: marketplaceUrl,
});

// This is added because the above marketplace API instance shows a toast message on all kinds of errors
// which is not needed for some use cases
export const PureMarketplaceAPI = Axios.create({
  baseURL: marketplaceUrl,
});

export const GptAPi = Axios.create({
  baseURL: bpUrl.replace('api.brokers', 'openai-service'),
});

axiosRetry(SwoopAxiosInstance, {
  retries: 1,
  retryCondition: (e) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(e) || e?.response?.status === 503;
  },
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
});

const setXCountryHeader = (config: AxiosRequestConfig) => {
  config.headers['X-Country'] = formattedCountry;
};

const setXGApplicationTypeHeader = (config: AxiosRequestConfig) => {
  config.headers['X-Application-Type'] = applicationType;
};

const requestInterceptor = (config: AxiosRequestConfig) => {
  if (config.method === 'patch') config.headers['Content-Type'] = 'application/json-patch+json';

  setXGroupHeaders(config);
  setXCountryHeader(config);
  setXGApplicationTypeHeader(config);

  if (config.data?.requestId) {
    const source = Axios.CancelToken.source();

    config.cancelToken = source.token;

    addRequest(config.data.requestId, source.cancel);
  }

  return config;
};

const responseInterceptor = (response: AxiosResponse) => {
  const { config } = response;

  if (config && config.data && config.data.requestId) {
    delete pendingRequests[config.data.requestId];
    delete config.data.requestId;
  }

  return response;
};

const responseErrorInterceptor = async (error: AxiosError) => {
  let message = null;
  const system = {
    ...loadState(LSKeys.swoopAnalytics),
  };

  if (
    error?.response?.config?.url?.includes('/api/v1/fundingMatches/') &&
    error?.response?.config?.url?.includes('/fundingRequirements') &&
    error?.response?.status === 400
  ) {
    return toastMessage(
      i18next.t('home:companydetails:fundingmatches:fundingrequirements:errortoast')
    );
  }

  if (error.response && error.response.status === 403) {
    toastMessage(`${error.response.status} Access forbidden`);
  } else if (error.response && [401, 405].includes(error.response.status)) {
    clearState(LSKeys.swoopAnalytics);

    if (system && system.accessToken && error.response.config.url !== BrokerAPIURL.logout)
      toastMessage(i18next.t(`error:${error.response.status}`), 'error');

    history.push(RoutePath.login);
  } else {
    let serverMessage = null;

    try {
      serverMessage = error?.response?.data?.Message || '';
    } catch (e) {
      const [test] = error?.response?.data?.Details || [];

      serverMessage = test?.Message;
    }

    message = serverMessage ? serverMessage : error?.response?.status;

    if (error.response && !nonToastStatus.includes(error.response.status))
      toastMessage(i18next.t(`error:${message}`), 'error');
  }

  if (isNetworkError(error)) return Promise.reject(error);

  return Promise.reject(error);
};

const toastMessage = (message: string, type: ToastOptions['type'] = 'success') => {
  toast.dismiss(toastId);

  toastId = toast(message, {
    hideProgressBar: true,
    className: 'sw-toast',
    closeButton: false,
    position: 'bottom-center',
    type,
  });
};

const queryArraySerializer = (config: AxiosRequestConfig) => {
  config.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  return config;
};

SwoopAxiosInstance.interceptors.request.use(queryArraySerializer);
SwoopAxiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
SwoopAxiosInstance.interceptors.request.use(requestInterceptor);

MarketplaceAPI.interceptors.request.use(queryArraySerializer);
MarketplaceAPI.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
MarketplaceAPI.interceptors.request.use(requestInterceptor);
export default Axios;
