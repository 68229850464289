import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import { ActiveTabs } from '../../companyOverview/components/fundingRequirements/types';
import { ScoreGoalsType } from '../types';

export type TTab = { label: string; formId: string; type: string; name: string };

export type FundingRequirementsType = {
  [ActiveTabs.LOANS]: {
    fields: FieldMetaDataPropType[];
    data: { [key: string]: any };
    error: boolean;
    loading: boolean;
    actionType: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS;
    formId: ActiveTabs.LOANS;
  };
  [ActiveTabs.DYNAMIC]: {
    fields: FieldMetaDataPropType[];
    data: { [key: string]: any };
    error: boolean;
    loading: boolean;
    actionType: FundingRequirementsActionType.SET_DYNAMIC_FORM;
    formId: string | null;
  };
  formOptions?: { [key: string]: [{ value: string; label: string | number }] };
  activeTab: ActiveTabs | null;
  activeTabDetails: { [key: string]: any };
  tabs: TTab[];
  completedFundingForms: string[];
  isSaving: boolean;
  ableToSave: boolean;
  formMigrationModalOpen: boolean;
  scoreGoals: ScoreGoalsType[];
};

export enum FundingRequirementsActionType {
  SET_DYNAMIC_FORM,
  SET_DEBT_REQUIREMENTS,
  SET_TABS,
  SET_COMPLETED_FORMS,
  SET_ACTIVE_TAB,
  SET_SELECTED_TAB,
  SET_FORM_OPTIONS,
  SET_IS_SAVING,
  SET_ABLE_TO_SAVE,
  SET_SELECTED_FUNDING_TYPES,
  SET_FORM_MIGRATION_MODAL_OPEN,
  SET_SCORE_GOALS,
}

export type Action = {
  type: FundingRequirementsActionType;
  payload: any;
};

export function fundingMatchesReducer(state: FundingRequirementsType, action: Action) {
  switch (action.type) {
    case FundingRequirementsActionType.SET_FORM_MIGRATION_MODAL_OPEN:
      return {
        ...state,
        formMigrationModalOpen: action.payload,
      };
    case FundingRequirementsActionType.SET_DYNAMIC_FORM:
      return {
        ...state,
        [ActiveTabs.DYNAMIC]: { ...state[ActiveTabs.DYNAMIC], ...action.payload },
      };
    case FundingRequirementsActionType.SET_DEBT_REQUIREMENTS:
      return {
        ...state,
        [ActiveTabs.LOANS]: { ...state[ActiveTabs.LOANS], ...action.payload },
      };
    case FundingRequirementsActionType.SET_TABS:
      return {
        ...state,
        tabs: action.payload,
      };
    case FundingRequirementsActionType.SET_COMPLETED_FORMS:
      return {
        ...state,
        completedFundingForms: action.payload,
      };
    case FundingRequirementsActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case FundingRequirementsActionType.SET_FORM_OPTIONS:
      return {
        ...state,
        formOptions: action.payload,
      };
    case FundingRequirementsActionType.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload.isSaving,
      };
    case FundingRequirementsActionType.SET_ABLE_TO_SAVE:
      return {
        ...state,
        ableToSave: action.payload.ableToSave,
      };
    case FundingRequirementsActionType.SET_SCORE_GOALS:
      return {
        ...state,
        scoreGoals: action.payload,
      };

    default:
      return state;
  }
}
