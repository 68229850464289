import React from 'react';
import classnames from 'classnames';
import { ICardProps } from './types';
import styles from './card.module.scss';
import Spinner from '../spinner';
import ErrorComponent from 'components/error';
import i18next from 'i18next';

const Card: React.FC<ICardProps> = (props: ICardProps) => {
  const Loading = ({ message }: { message?: string }) => {
    return (
      <div
        data-testid="card-loading"
        className={classnames(props?.className?.loading || styles['loading-container'])}
      >
        <span className={styles.loading}>
          <Spinner size="large" />
        </span>
        {Boolean(props?.loadingMessage) ? (
          <div className={classnames(styles['loading-message-container'])}>
            <div className={classnames(styles['loading'])}>Loading...</div>
            <div className={classnames(styles['loading-message'])}>{message}</div>
          </div>
        ) : (
          <div className={classnames(styles['loading-message-container'])}>
            <div className={classnames(styles['loading'])}>Loading...</div>
          </div>
        )}
      </div>
    );
  };

  const Error = ({ message }: { message?: string }) => {
    return (
      <div className={props?.className?.error || styles['error-container']}>
        <ErrorComponent message={message || i18next.t('common:servererror')} />
      </div>
    );
  };

  return (
    <div
      data-testid={props.id}
      className={classnames(styles['card-wrapper'], { [styles['card-margin']]: props.hasMargin })}
    >
      {(props.headerSlot || props.title) && (
        <div className={classnames(styles['header'])}>
          <h1 className={classnames('sw-h1')}>{props.title}</h1>
          {props.subtitle && <h4 className={classnames('sw-h4')}>{props.subtitle}</h4>}
        </div>
      )}
      {(props.loading && <Loading message={props.loadingMessage} />) ||
        (Boolean(props.emptyMessage) && <Error message={props.emptyMessage} />) ||
        (props.error && <Error />) || (
          <div
            className={classnames(
              styles.card,
              { [styles['card-no-padding']]: props.noPadding },
              props?.className?.card
            )}
          >
            {props.children}
          </div>
        )}
    </div>
  );
};

export default Card;
