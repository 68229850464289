import Hotjar from '@hotjar/browser';

export const initHotjar = () => {
  const siteId =
    (window as any)?._env_?.REACT_APP_HOTJAR_SITE_ID || process.env.REACT_APP_HOTJAR_SITE_ID;
  const hotjarVersion = 6;

  if (siteId) {
    Hotjar.init(siteId, hotjarVersion);
  }
};
