import { BrokerAPI } from '_shared/api';
import { BrokerAPIURL } from '_shared/url';

export const postInviteGroupAdmin = (params: { groupName: string; email: string }) =>
  BrokerAPI.post(BrokerAPIURL.postInviteGroupAdmin, params);

export const postInviteGroupUser = (params: { role: string; email: string }) =>
  BrokerAPI.post(BrokerAPIURL.postInviteGroupUser, params);

export const postInviteGroupCompany = (data: any) =>
  BrokerAPI.post(BrokerAPIURL.postInviteGroupCompany, data);

export const getGroup = (groupId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getGroup(groupId));
};

export const putGroup = (groupId: string, data: { [key: string]: any }) => {
  return BrokerAPI.put(BrokerAPIURL.putGroup(groupId), data);
};

export const putGroupLogo = (groupId: string, data: string) => {
  return BrokerAPI.post(BrokerAPIURL.putGroupLogo(groupId), data);
};
