import useToastMessage from '../../../_shared/hooks/useToastMessage';
import {
  getSavingsProductApplicationStatus,
  postSavingsProductApplication,
} from '_shared/api/savingsProducts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useSavingsProducts = (productName: string) => {
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { errorToastMessage } = useToastMessage();
  const params = useParams();
  const companyId = params.id!;

  useEffect(() => {
    const getStatus = async () => {
      try {
        setIsLoading(true);
        const { data } = await getSavingsProductApplicationStatus(companyId, productName);
        if (!data.isSucceed) throw new Error();
        setIsAvailable(!data.doesProductPageSubmissionExist);
      } catch (e) {
        return errorToastMessage('Failed to fetch product application status.');
      } finally {
        setIsLoading(false);
      }
    };
    getStatus();
  }, [productName, companyId]);

  const postProductApplication = async (
    productName: string,
    formData: { [key: string]: string } | object = {}
  ) => {
    try {
      const { data } = await postSavingsProductApplication(companyId, productName, formData);
      if (!data.isSucceed) throw new Error();
      setIsAvailable(false);
    } catch (e) {
      return errorToastMessage('Failed to fetch product application status.');
    }
  };

  const scrollToForm = (form: HTMLDivElement | null, offset = 200) => {
    if (form) {
      const rect = form.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      window.scrollTo({
        top: rect.top + scrollTop - offset,
        behavior: 'smooth',
      });
    }
  };

  return {
    isLoading,
    isAvailable,
    postProductApplication,
    scrollToForm,
  };
};

export default useSavingsProducts;
