import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import ErrorComponent from 'components/error';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import { getDownloadCompanyDocument, getAccountingDocuments } from '_shared/api/documents';
import { APIDocumentType } from 'pages/documentLibrary/types';
import styles from 'pages/documentLibrary/assets/previewDocument.module.scss';
import { useTranslation } from 'react-i18next';

const pdfTypesPreview = ['application/pdf', 'application/octet-stream'];
const acceptedDocTypeForPreview = [...pdfTypesPreview, 'image/jpeg', 'image/png'];

const PreviewDocument = ({
  companyId,
  document,
  isOpen,
  close,
}: {
  companyId: string;
  document: APIDocumentType;
  isOpen: boolean;
  close: Function;
}) => {
  const { documentId, name } = document || {};
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [previewDisabled, setPreviewDisabled] = useState<boolean>(false);
  const [isImgDoc, setIsImgDoc] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const imageRef = useRef<HTMLImageElement>(null);

  const pdfToolbarConfig = '#toolbar=1&view=Fit';

  const minZoom = 0.2; // 20%
  const maxZoom = 2.0; // 200%
  const zoomIncrement = 0.05; // 5%

  const fetchDocument = async (id: string, docId: string) => {
    try {
      const { data } = document.isBrokerDocument
        ? await getAccountingDocuments(id, docId)
        : await getDownloadCompanyDocument(id, docId);

      let objectUrl = '';

      if (!acceptedDocTypeForPreview.includes(data.type)) {
        // data type that cant be previewed
        setPreviewDisabled(true);
      } else if (pdfTypesPreview.includes(data.type)) {
        // doc type thats displayed by object tag
        objectUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      } else {
        // doc type thats displayed by image tag
        objectUrl = window.URL.createObjectURL(new Blob([data], { type: data.type }));
        setIsImgDoc(true);
      }

      setUrl(objectUrl);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleZoomIn = () => {
    if (zoomLevel + zoomIncrement <= maxZoom) {
      setZoomLevel(zoomLevel + zoomIncrement);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel - zoomIncrement >= minZoom) {
      setZoomLevel(zoomLevel - zoomIncrement);
    }
  };

  const percentageZoom = useMemo(() => {
    return Math.round(((zoomLevel - minZoom) / (maxZoom - minZoom)) * 100);
  }, [zoomLevel]);

  /* Dynamically calculates and sets the zoom level of an image
    based on the width of its parent container and the image's natural width.
  */
  useEffect(() => {
    const parentElement = imageRef?.current?.parentElement;
    const containerWidth = parentElement?.offsetWidth ?? null;
    const naturalWidth = imageRef?.current?.naturalWidth ?? null;

    if (containerWidth && naturalWidth) {
      const clampedZoom = Math.min(Math.max(containerWidth / naturalWidth, minZoom), maxZoom);
      setZoomLevel(clampedZoom);
    } else {
      setZoomLevel(1.0);
    }
  }, [url]);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.transform = `scale(${zoomLevel})`;
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (!companyId || !documentId) return;
    fetchDocument(companyId, documentId);
  }, [companyId, documentId]);

  return (
    <Modal show={isOpen} handleClose={close} previewPage={true}>
      <div className={cn(styles.container)}>
        <h3 className={cn(styles.previewName)}>{name}</h3>
        {(loading && <Spinner size="large" />) ||
          (error && <ErrorComponent message="error fetching document" />) ||
          (isImgDoc && (
            <div className={cn(styles['preview-image-container'])}>
              <div className={cn(styles['preview-container'])}>
                <img
                  ref={imageRef}
                  src={url}
                  alt="preview"
                  style={{ transform: `scale(${zoomLevel})` }}
                />
              </div>
              <div className={cn(styles['zoom-btn-container'])}>
                <button
                  className={cn('material-icons', styles['zoom-icon'])}
                  onClick={handleZoomOut}
                  onKeyDown={handleZoomOut}
                >
                  zoom_out
                </button>
                <span>{percentageZoom} %</span>
                <button
                  className={cn('material-icons', styles['zoom-icon'])}
                  onClick={handleZoomIn}
                  onKeyDown={handleZoomIn}
                >
                  zoom_in
                </button>
              </div>
            </div>
          )) ||
          (previewDisabled && (
            <div className={cn(styles['preview-error'])}>
              <ErrorComponent message="" />
              <span>{t('home:companydetails:documentlibrary:previewerror:title')}</span>
              <span>{t('home:companydetails:documentlibrary:previewerror:subtitle')}</span>
            </div>
          )) || (
            <iframe
              title="Preview Document"
              src={url + pdfToolbarConfig}
              width="100%"
              height="100%"
            ></iframe>
          )}
      </div>
    </Modal>
  );
};

export default PreviewDocument;
