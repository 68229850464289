import { useEffect, useReducer } from 'react';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import { TOAST_MESSAGE } from 'store/toast/types';
import { updateTrueLayerDocuments } from '_shared/api/documents';
import { getTrueLayerStatus } from '_shared/api/financialInfo';
import { PdfTypesEnum } from '../types';
import { uploadDocumentActionType, uploadDocumentReducer } from '../store/uploadDocument.reducer';

const UploadDocumentHook = ({ companyId, fetchDocumentList }: any) => {
  const initialState = {
    newDocuments: [],
    data: [],
    loading: true,
    error: false,
    updatingTransactions: {
      trueLayer: false,
    },
    financialStatus: {
      trueLayer: false,
    },
  };

  const store = useAppState();
  const [state, dispatch] = useReducer(uploadDocumentReducer, initialState);

  const { t } = useTranslation();

  const fetchFinancialStatus = async (id: string) => {
    if (!id) return;
    try {
      const [{ data: truelayerResult }] = await Promise.all([getTrueLayerStatus(id)]);
      dispatch({
        type: uploadDocumentActionType.SET_FINANCIAL_STATUS,
        payload: { trueLayer: truelayerResult },
      });
    } catch (e) {
      console.error(e);
    }
  };

  // upload documents
  const uploadFiles = (files: any) => {
    files
      .filter(({ type, size }: { type: string; size: number }) => {
        if (
          ![
            'application/pdf',
            'image/png',
            'image/jpeg',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-powerpoint',
          ].includes(type) ||
          size > 5e7
        ) {
          store.dispatch({
            type: TOAST_MESSAGE,
            payload: { toastMessage: t('home:companydetails:documentlibrary:subheading') },
          });
          return false;
        }
        return true;
      })
      .forEach((file: File, index: number) => {
        dispatch({
          type: uploadDocumentActionType.SET_NEW_DOCUMENTS,
          payload: {
            id: Date.now() + index,
            selectedCategory: '',
            selectedTitle: '',
            fileName: file?.name || '',
            file: file,
          },
        });
      });
  };

  const handleCancelDocument = (documentId: string) => {
    dispatch({
      type: uploadDocumentActionType.REMOVE_NEW_DOCUMENTS,
      payload: documentId,
    });
  };

  // saves uplaoded files
  const handleNewFileSubmit = (id: string) => {
    dispatch({
      type: uploadDocumentActionType.REMOVE_NEW_DOCUMENTS,
      payload: id,
    });
    store.dispatch({
      type: TOAST_MESSAGE,
      payload: { toastMessage: t('home:companydetails:documentlibrary:upload:toast') },
    });
    fetchDocumentList(companyId);
  };

  const displayToast = (message: string) => {
    store.dispatch({
      type: TOAST_MESSAGE,
      payload: { toastMessage: message },
    });
  };

  // generates truelayer pdf's
  const updateTransactions = async (provider: string) => {
    if (!companyId) return;

    try {
      dispatch({
        type: uploadDocumentActionType.SET_UPDATING_TRANSACTIONS,
        payload: {
          [provider]: true,
        },
      });
      if (provider === PdfTypesEnum.TRUELAYER) {
        const updateDocumentRequests = [3, 6, 9, 12].map((numberOfMonths: number) =>
          updateTrueLayerDocuments(companyId, { numberOfMonths })
        );

        await Promise.all(updateDocumentRequests);

        fetchDocumentList(companyId);
        sendAmplitudeData(AmplitudeTrackingEnum.updatetruelayerdocs);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: uploadDocumentActionType.SET_UPDATING_TRANSACTIONS,
        payload: {
          [provider]: false,
        },
      });
    }
  };

  useEffect(() => {
    if (!companyId) return;
    fetchFinancialStatus(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return { state, uploadFiles, updateTransactions, handleNewFileSubmit, handleCancelDocument };
};

export default UploadDocumentHook;
