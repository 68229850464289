import cn from 'classnames';

import { SavingsProductsHeaderProps } from 'pages/companySavingsProducts/types';
import styles from './Header.module.scss';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Spinner from 'components/spinner';

export default function SavingsProductsHeader({
  icon,
  title,
  ctaText,
  ctaCallback,
  isCtaDisabled,
  isLoading,
}: Readonly<SavingsProductsHeaderProps>) {
  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <div className={cn('text-container', styles['text-container'])}>
        <img src={icon} alt={`${title} icon`} />
        <h1>{title}</h1>
      </div>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <Button
          id={`${title} header cta button`}
          ariaLabel={`${title} button`}
          buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
          style={{
            height: '52px',
            justifyContent: 'center',
            minWidth: 'fit-content',
            fontSize: '16px',
            backgroundColor: isCtaDisabled ? '#2E9C8E' : '#003e52',
          }}
          disabled={isCtaDisabled}
          clickHandler={ctaCallback}
        >
          <p>{!isCtaDisabled ? ctaText : 'Waiting for reply'}</p>
        </Button>
      )}
    </div>
  );
}
