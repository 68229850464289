/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/indent */
/**
 * Upper case the first character of an input string.
 */
export function upperCaseFirst(input: string) {
  return input.charAt(0).toUpperCase() + input.substr(1);
}
/**
 * Locale character mapping rules.
 */
interface Locale {
  regexp: RegExp;
  map: Record<string, string>;
}

/**
 * Source: ftp://ftp.unicode.org/Public/UCD/latest/ucd/SpecialCasing.txt
 */
const SUPPORTED_LOCALE: Record<string, Locale> = {
  tr: {
    regexp: /\u0130|\u0049|\u0049\u0307/g,
    map: {
      İ: '\u0069',
      I: '\u0131',
      İ: '\u0069',
    },
  },
  az: {
    regexp: /\u0130/g,
    map: {
      İ: '\u0069',
      I: '\u0131',
      İ: '\u0069',
    },
  },
  lt: {
    regexp: /\u0049|\u004A|\u012E|\u00CC|\u00CD|\u0128/g,
    map: {
      I: '\u0069\u0307',
      J: '\u006A\u0307',
      Į: '\u012F\u0307',
      Ì: '\u0069\u0307\u0300',
      Í: '\u0069\u0307\u0301',
      Ĩ: '\u0069\u0307\u0303',
    },
  },
};

/**
 * Localized lower case.
 */
export function localeLowerCase(str: string, locale: string) {
  const lang = SUPPORTED_LOCALE[locale.toLowerCase()];
  if (lang) return lowerCase(str.replace(lang.regexp, (m) => lang.map[m]));
  return lowerCase(str);
}

/**
 * Lower case as a function.
 */
export function lowerCase(str: string) {
  return str.toLowerCase();
}
export interface Options {
  splitRegexp?: RegExp | RegExp[];
  stripRegexp?: RegExp | RegExp[];
  delimiter?: string;
  transform?: (part: string, index: number, parts: string[]) => string;
}

// Support camel case ("camelCase" -> "camel Case" and "CAMELCase" -> "CAMEL Case").
const DEFAULT_SPLIT_REGEXP = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g];

// Remove all non-word characters.
const DEFAULT_STRIP_REGEXP = /[^A-Z0-9]+/gi;

/**
 * Normalize the string into something other libraries can manipulate easier.
 */
export function noCase(input: string, options: Options = {}) {
  const {
    splitRegexp = DEFAULT_SPLIT_REGEXP,
    stripRegexp = DEFAULT_STRIP_REGEXP,
    transform = lowerCase,
    delimiter = ' ',
  } = options;

  const result = replace(replace(input, splitRegexp, '$1\0$2'), stripRegexp, '\0');
  let start = 0;
  let end = result.length;

  // Trim the delimiter from around the output string.
  while (result.charAt(start) === '\0') start++;
  while (result.charAt(end - 1) === '\0') end--;

  // Transform each token independently.
  return result.slice(start, end).split('\0').map(transform).join(delimiter);
}

/**
 * Replace `re` in the input string with the replacement value.
 */
function replace(input: string, re: RegExp | RegExp[], value: string) {
  if (re instanceof RegExp) return input.replace(re, value);
  return re.reduce((input, re) => input.toString().replace(re, value), input);
}
export function sentenceCaseTransform(input: string, index: number) {
  const result = input.toLowerCase();
  if (index === 0) return upperCaseFirst(result);
  return result;
}

export function sentenceCase(input: string, options: Options = {}) {
  return noCase(input, {
    delimiter: ' ',
    transform: sentenceCaseTransform,
    ...options,
  });
}
export function dotCase(input: string, options: Options = {}) {
  return noCase(input, {
    delimiter: '.',
    ...options,
  });
}
export function snakeCase(input: string, options: Options = {}) {
  return dotCase(input, {
    delimiter: '_',
    ...options,
  });
}

export function titleCase(input: string) {
  return input
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
}

/**
 * Formats string to Kebab Case.
 */
export function formatToKebabCase(stringToFormat: string) {
  // Removing special characters.
  let formattedString = stringToFormat.replace(/[^\w\s]/gi, '');

  // Formatting to lower case and adding dash as a separator.
  formattedString = formattedString.toLocaleLowerCase().split(' ').join('-');

  return formattedString;
}

/**
 * Formats string to Pascal Case.
 */
export function formatToPascalCase(stringToFormat: string) {
  // Changing all first letters to upper case.
  let formattedString = stringToFormat.replace(/\w+/g, (word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });

  // Removing white spaces.
  formattedString = formattedString.split(' ').join('');

  return formattedString;
}
