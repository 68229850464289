import * as Sentry from '@sentry/react';

export const initSentry = () => {
  const dsn = (window as any)?._env_?.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;
  const environment =
    (window as any)?._env_?.REACT_APP_SENTRY_ENVIRONMENT ||
    process.env.REACT_APP_SENTRY_ENVIRONMENT;

  if (dsn && environment) {
    Sentry.init({
      dsn,
      environment,

      // Replay configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: true,
        }),
      ],
    });
  }
};
