import { useRef } from 'react';
import cn from 'classnames';
import styles from './activityDropdown.module.scss';
import Activity from '../activity';
import Select from 'components/field/select';
import useBlur from '_shared/hooks/useBlur';
import { useTranslation } from 'react-i18next';
import { ActivityDropdownPropTypes, ActivityType, ACTIVITY_TYPE } from '../types';
import { filterActivitiesFields, orderMetadata } from '../activities.fields';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import Spinner from 'components/spinner';

const ActivityDropdown = ({
  activities,
  order,
  loading,
  filter,
  actions,
}: ActivityDropdownPropTypes) => {
  const activitiesDropdown = useRef<any>(null);
  useBlur(activitiesDropdown, actions.toggleOpenActivityDropdown);
  const { t } = useTranslation();
  const orderField = orderMetadata(order);
  const fielterField = filterActivitiesFields(filter);
  const futureOverdueActivities = activities.filter((activity: ActivityType) => {
    return (
      activity.activityType === ACTIVITY_TYPE.TASK &&
      (activity.taskStatus === 'InProgress' || activity.taskStatus === 'Todo')
    );
  });
  const completedActivities = activities.filter((activity: ActivityType) => {
    return activity.taskStatus === 'Done' || activity.activityType !== ACTIVITY_TYPE.TASK;
  });

  const activityList = (filteredActivity: ActivityType[], filteredActivityStatus: string) => {
    return filteredActivity.length ? (
      filteredActivity.map((filteredActivity) => {
        return (
          <Activity
            key={filteredActivity.id}
            {...{
              activity: filteredActivity,
              handleDeleteActivity: actions.handleDeleteActivity,
              handleEditActivity: actions.handleEditActivity,
            }}
          />
        );
      })
    ) : (
      <div className={cn(styles['empty-activities'])}>
        {(loading && <Spinner size="large" />) || (
          <>
            <span>
              {t(
                `home:companydetails:activities:note:${
                  filteredActivityStatus === 'history' ? 'emptyhistory' : 'emptyfutureandoverdue'
                }`,
                {
                  activityType: spaceBetweenTitleCaseWords(filter).toLowerCase(),
                }
              )}
            </span>
            <button
              onClick={() => {
                actions?.openChooseActivityModal?.(true);
                actions?.toggleOpenActivityDropdown?.();
              }}
            >
              {t('home:companydetails:activities:note:newnote')}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      data-testid="activities-dropdown"
      ref={activitiesDropdown}
      className={cn(styles['activities-dropdown'])}
    >
      <div className={cn(styles['activities-heading-container'])}>
        <Select
          {...{
            metadata: fielterField,
            errors: [],
            handleChange: actions.handleFilterActivities,
          }}
        />
        <Select
          {...{
            metadata: orderField,
            errors: [],
            handleChange: actions.handleChangeOrder,
          }}
        />
      </div>
      <header>
        <h3 className={cn('sw-h3')}>
          {t('home:companydetails:activities:titles:futureandoverdue')}
        </h3>
      </header>
      {activityList(futureOverdueActivities, 'futureandoverdue')}
      <header>
        <h3 className={cn('sw-h3')}>{t('home:companydetails:activities:titles:history')}</h3>
      </header>
      {activityList(completedActivities, 'history')}
    </div>
  );
};

export default ActivityDropdown;
