import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from 'react-router-dom';
import useMediaQuery from '_shared/hooks/useMediaQuery';
import classNames from 'classnames';
import BackButton from 'components/backbutton';
import styles from 'pages/profile/assets/yourprofile.module.scss';
import { useAppState } from 'store';

function YourProfile() {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [state, setstate] = useState({
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
  });
  const store = useAppState();
  const authToken = store.state.system.accessToken;
  useEffect(() => {
    if (!authToken) {
      return setstate({ ...state, redirectToReferrer: true });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);
  if (state.redirectToReferrer) {
    // @ts-ignore
    return <Navigate to={state.from} />;
  }
  return (
    <div className={classNames(styles.yourprofile, 'wrapper')}>
      {!isMobile && (
        <div className={classNames(styles.sidebar)}>
          <div className={classNames(styles.content)}>
            <BackButton />
            <h2 className={classNames('sw-h2', styles.title)}>{t('home:yourprofile:title')}</h2>
            <div className={classNames(styles['sub-nav'])}>
              {t('home:yourprofile:password:title')}
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <header className={classNames(styles.header)}>
          <div className={classNames(styles.content)}>
            <BackButton />
            <h2 className={classNames('sw-h2', styles.title)}>{t('home:yourprofile:title')}</h2>
          </div>
          <div className={classNames(styles['sub-nav-holder'])}>
            <select aria-labelledby="profile-menu" className={classNames(styles['sub-nav'])}>
              <option value="">{t('home:yourprofile:password:title')}</option>
            </select>
          </div>
        </header>
      )}
      <main
        className={classNames(styles.container, {
          [styles.mobile]: isMobile,
        })}
      >
        {!isMobile && (
          <div className={classNames(styles.header)}>
            <h1 className={classNames('sw-h1', styles.title)}>
              {t('home:yourprofile:password:title')}
            </h1>
          </div>
        )}
        <hr className={classNames('sw-divider', styles.divider)} />
      </main>
    </div>
  );
}

export default YourProfile;
