import React from 'react';
import cn from 'classnames';
import styles from './radio.module.scss';
import { RadioType } from './type';

function Radio(props: RadioType, _ref: React.Ref<any>) {
  const id = `${props.id}-${props.name}`;
  const error = props.name && props.errors[props.metadata.name];

  return (
    <div className={cn(styles.radio)}>
      <input
        id={id}
        type="radio"
        value={props?.value}
        name={props.name}
        className={cn(styles.input)}
        checked={props.selected}
        data-parentid={props.parentId}
        onChange={props.handleChange}
        ref={_ref}
        tabIndex={0}
        disabled={props.disabled || false}
      />
      <label
        htmlFor={id}
        className={cn(
          styles.label,
          props?.classNames?.radio,
          { [styles['error']]: error },
          {
            [styles['is-checked']]: props.selected,
          }
        )}
      >
        {props.label}
      </label>
    </div>
  );
}

export default React.forwardRef(Radio);
