import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import SavingsProducts from '.';

export const companySavingsProductsRoutes: RouteObject[] = [
  {
    path: RoutePath.companysavingsproducts,
    element: withTransaction('SavingsProducts', 'component')(<SavingsProducts />),
  },
];
