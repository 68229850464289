import React from 'react';
import { FieldTypes, OptionsType } from '_shared/fieldValidation/types';
import { selectionFieldTypes } from '_shared/utils/constants';
import { useTranslation } from 'react-i18next';

import { FieldPropType } from './type';
import Autocomplete from './autocomplete';
import CustomSelect from 'components/field/customSelect';
import MultiSelect from './multiselect';
import classNames from 'classnames';
import DatePicker from './datepicker';
import TextInput from './text';
import Checkbox from './checkbox';
import ReadOnly from './readOnly';
import Textarea from './textarea';
import Select from './select';
import Upload from './upload';
import styles from './field.module.scss';
import Radio from './radio';
import CurrencyInput from './currencyInput';
import FieldHolder from './fieldHolder';
import TextEditor from './textEditor';
import PercentageInput from './percentageInput';

function Field(props: FieldPropType) {
  const { metadata, errors, handleChange } = props;
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectRef = React.useRef<HTMLSelectElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const multiselectRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const topErrorFieldName = Object.keys(errors)
      .reduce((acc: string, x: string) => {
        if (errors[x]) {
          return `${acc}|${x}`;
        }
        return acc;
      }, '')
      .split('|')[1];
    if (metadata.name === topErrorFieldName) {
      if (inputRef.current) {
        if (selectionFieldTypes.includes(inputRef.current.type)) {
          inputRef.current?.focus();
          return;
        }
        if (inputRef.current.name === topErrorFieldName) {
          inputRef.current?.focus();
          return;
        }
      }
      if (textareaRef.current && textareaRef.current.name === topErrorFieldName) {
        textareaRef.current?.focus();
        return;
      }
      if (selectRef.current && selectRef.current.name === topErrorFieldName) {
        selectRef.current?.focus();
      }
      if (multiselectRef.current && multiselectRef.current.id === topErrorFieldName) {
        multiselectRef.current?.scrollIntoView({ block: 'center' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  if (metadata.fieldType === FieldTypes.READONLY) {
    return <ReadOnly metadata={metadata} actionCallbacks={props.actionCallbacks} />;
  }

  if (metadata.fieldType === FieldTypes.TEXT) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <TextInput
          {...{ metadata, className: props.className, errors, handleChange }}
          ref={inputRef}
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.TEXTAREA) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <Textarea {...{ metadata, handleChange, errors }} ref={textareaRef} />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.SELECT) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <Select
          {...{ metadata, className: props.className, handleChange, errors }}
          ref={selectRef}
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.CUSTOM_SELECT) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <CustomSelect {...{ metadata, handleChange, errors }} ref={multiselectRef} />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.EDITOR) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <TextEditor {...{ metadata, handleChange, errors }} />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.MULTISELECT) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <MultiSelect {...{ metadata, handleChange, errors }} ref={multiselectRef} />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.RADIO) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <div className={classNames(styles['radio-wrapper'], metadata.classNames?.control)}>
          {metadata.options &&
            metadata.options.map((opt: OptionsType) => {
              return (
                <Radio
                  key={`${metadata.id}-${opt.label}`}
                  ref={inputRef}
                  id={metadata.id!}
                  label={opt.label}
                  name={opt.name!}
                  value={opt.value}
                  aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
                  aria-describedby={`error-${metadata.name}`}
                  handleChange={handleChange}
                  selected={metadata.selectionKey === opt.value}
                  parentId={opt.parentId}
                  disabled={metadata.disabled}
                  errors={errors}
                  metadata={metadata}
                />
              );
            })}
        </div>
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.CHECKBOX) {
    const isSelected = (optionName: string, selectionKey: string | string[]): boolean => {
      if (metadata.dataType === 'array' && Array.isArray(selectionKey)) {
        return selectionKey.some((option: any) => (option.value || option) === optionName);
      } else if (typeof selectionKey === 'string') {
        return selectionKey.split(',').includes(optionName);
      }
      return false;
    };

    const selectionKey = metadata.selectionKey || '[]'; // Default to empty array if selectionKey is empty string

    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <div className={metadata.classNames?.control || props.className?.control}>
          {metadata.options &&
            metadata.options.map((opt: { [key: string]: any }) => {
              return (
                <Checkbox
                  key={`${metadata.id}-${opt.name}`}
                  id={`${metadata.id}-${opt.name}`}
                  label={opt.label}
                  name={opt.name || ''}
                  aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
                  aria-describedby={`error-${metadata.name}`}
                  handleChange={handleChange}
                  selected={isSelected(opt.name, selectionKey)}
                  parentId={opt.parentId}
                  classNames={metadata?.classNames?.checkbox}
                />
              );
            })}
        </div>
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.AUTOCOMPLETE) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <Autocomplete
          name={metadata.name}
          enableAutofocus={metadata.enableAutofocus}
          placeholder={t(metadata.placeholder!)}
          multiselectTag={props.multiselectTag}
          handleChange={handleChange}
          callBack={props.callBack || metadata.callBack!}
          defaultItems={props.defaultItems}
          className={metadata.classNames?.control || props.className?.control}
          multiselectTagList={props.multiselectTag && metadata.value}
          errors={errors}
          value={metadata.value}
          disabled={metadata.disabled}
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.DATEPICKER) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <DatePicker
          key={`${metadata.id}-${metadata.value}`}
          id={metadata.id}
          handleChange={props.handleChange}
          value={metadata.value}
          className={metadata.classNames?.control || props.className?.control}
          name={metadata.name}
          placeholder={t(metadata.placeholder!)}
          aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
          aria-describedby={`error-${metadata.name}`}
          disabled={metadata.disabled}
          hasError={!!errors[metadata.name!]}
          showCalendarIcon
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.CURRENCY) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <CurrencyInput
          {...{
            metadata,
            errors,
            handleChange,
            disabled: metadata?.disabled,
            value: metadata.value,
          }}
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.PERCENTAGE) {
    return (
      <FieldHolder metadata={metadata} errors={errors}>
        <PercentageInput
          {...{
            metadata,
            errors,
            handleChange,
          }}
        />
      </FieldHolder>
    );
  }

  if (metadata.fieldType === FieldTypes.UPLOAD) {
    return (
      <div className={classNames('field', styles.field, props.className?.field)}>
        <Upload handleChange={props.handleChange} name={metadata.name} {...metadata} />
      </div>
    );
  }

  if (metadata.fieldType === FieldTypes.SECTION_TITLE) {
    return <div className={classNames(styles['section-title'])}>{t(metadata.label!)}</div>;
  }

  return <div />;
}

export default Field;
