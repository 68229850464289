import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './select.module.scss';
import { FieldPropType } from '../type';

type valueOptions = {
  value?: string | number;
  defaultValue?: string | number;
};

const Select = React.forwardRef(
  ({ metadata, errors, handleChange }: FieldPropType, ref: React.Ref<HTMLSelectElement>) => {
    const { t } = useTranslation();
    const valueProps: valueOptions = {};
    if (metadata.value) {
      valueProps.value = metadata.value;
    } else {
      valueProps.defaultValue = '';
    }
    return (
      <select
        data-testid={metadata.id || 'select'}
        key={`${metadata.id}-${metadata.value}`}
        id={metadata.id}
        name={metadata.name}
        ref={ref}
        tabIndex={metadata.tabIndex || 0}
        aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
        aria-describedby={`error-${metadata.name}`}
        placeholder={t(metadata.placeholder || '')}
        className={classNames(styles.select, metadata.classNames?.value)}
        onChange={handleChange}
        required={metadata.required}
        disabled={metadata.disabled}
        {...valueProps}
      >
        {(metadata.placeholder && (
          <option value="" disabled={metadata.required}>
            {t(metadata.placeholder || '')}
          </option>
        )) ||
          null}
        {metadata.options &&
          metadata.options.map((opt: { [key: string]: any }) => (
            <option
              data-testid="select-option"
              key={opt.id || `${metadata.id}-${opt.label}`}
              value={opt.value}
            >
              {opt.label}
            </option>
          ))}
      </select>
    );
  }
);

export default Select;
