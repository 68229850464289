import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellPropType, ActionMenuListType, TLinkTarget } from './type';
import { sendAmplitudeData } from 'config/amplitude';
import { formatToKebabCase } from '_shared/utils/casing';
import CustomSelect from 'components/field/customSelect';
import StatusIndicator from 'components/statusIndicator';
import CurrencyInput from 'components/field/currencyInput';
import DatePicker from 'components/field/datepicker';
import Spinner from 'components/spinner';
import styles from './cell.module.scss';
import Field from 'components/field';

const Cell: React.FC<CellPropType> = (props: CellPropType) => {
  const [state, setstate] = React.useState({
    isActionListMenuOpen: false,
  });

  React.useEffect(() => {
    const handleClose = () => setstate({ ...state, isActionListMenuOpen: false });

    if (state.isActionListMenuOpen) {
      document.addEventListener('click', handleClose, false);

      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) handleClose();
      });
    }

    return () => {
      document.removeEventListener('click', handleClose, false);

      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) handleClose();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isActionListMenuOpen]);

  const hanldeOnActionMenuClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> & React.KeyboardEvent<HTMLSpanElement>
  ) => {
    event.persist();

    setstate({ ...state, isActionListMenuOpen: true });
  };

  const hanldeOnActionMenuOptionClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent> & React.KeyboardEvent<HTMLSpanElement>
  ) => {
    event.persist();
    props.actionMenuListClickCb(event.currentTarget.getAttribute('data-name'));
  };

  return (
    <td
      className={classNames(styles.cell, props.classname?.cell, {
        [styles.tooltip]: props?.tooltip,
      })}
      data-label={typeof props.tooltip == 'string' ? props.tooltip : props.default}
      colSpan={props.colspan || 1}
    >
      <div className={classNames(styles.input, props.classname?.value)}>
        {Boolean(props.statusSelector) && (
          <CustomSelect
            {...{
              elementForPositionCheck: 'table',
              metadata: {
                id: `status-selector-${props.id}`,
                name: `status-selector-${props.id}`,
                options: props.statusList,
                disableFirstItem: false,
                classNames: {
                  field: 'table-select',
                  borderRadius: formatToKebabCase(props?.selectedStatus || ''),
                },
              },
              errors: [],
            }}
            value={props.status}
            handleChange={props.actionCb}
          />
        )}

        {Boolean(props.readOnlyStatus && props.status) && (
          <StatusIndicator status={props.status!} />
        )}

        {Boolean(props.currencyInput) && (
          <CurrencyInput
            metadata={{
              id: props.id,
              name: props.id,
              disabled: false,
            }}
            value={props.value}
            errors={[]}
            handleChange={props.actionCb}
          />
        )}

        {Boolean(props.datePicker) && (
          <DatePicker
            id={props.id}
            name={props.id}
            value={props.value}
            handleChange={props.actionCb}
            placeholder={props.value}
            todayButtonText="Today"
          />
        )}

        {props.actionMenuList && props.actionMenuList.length > 0 && (
          <span
            role="button"
            tabIndex={0}
            className={classNames(styles.actionmenu, props.classname?.actionMenu)}
            onKeyUp={hanldeOnActionMenuClick}
            onClick={hanldeOnActionMenuClick}
          >
            <i className={classNames('material-icons')}>more_vert</i>
          </span>
        )}
      </div>
      <div className={classNames(styles.value, props.classname?.value)}>
        {Boolean(props.icon) && (
          <i
            data-id={props.id}
            data-testid={props.id}
            data-name={props.name}
            className={classNames('material-icons', styles.icon, props.classname?.icon)}
            {...(props.actionCb && { onClick: props.actionCb })}
          >
            {props.icon}
          </i>
        )}

        {Boolean(props.tag) && (
          <span className={classNames(styles.tag, props.classname?.tag)}>{props.tag}</span>
        )}

        {Boolean(props.image) && (
          <img className={classNames(styles.image)} alt={props.alt} src={props.image} />
        )}

        {Boolean(props.buttonWithLoadingIndicator) && (
          <span
            data-id={props.id}
            onClick={props.disabled ? undefined : props.actionCb}
            className={classNames(props.classname?.link)}
          >
            {props.isLoading ? <Spinner size="small" /> : props.buttonText}
          </span>
        )}
        {(Boolean(props.link) && (
          <Link
            className={classNames(styles.link, props.classname?.link)}
            to={{
              pathname: props.link || '/',
            }}
            state={props.linkState}
            onClick={(event) => {
              if (props.tracking) {
                sendAmplitudeData(props.tracking);
              }
              event.stopPropagation();
            }}
            target={props.linkTarget ?? TLinkTarget.SELF}
          >
            {props.iconLink ? (
              <i className={classNames('material-icons', props.classname?.iconLink)}>
                {props.iconLink}
              </i>
            ) : (
              props.default
            )}
          </Link>
        )) ||
          (props.anchor && Boolean(props.default) && (
            <a
              href={props.anchor}
              target={TLinkTarget.BLANK}
              rel="noreferrer"
              className={props.classname?.anchor}
            >
              {props.default}
            </a>
          )) ||
          (props.fieldMetadata && (
            <Field metadata={props.fieldMetadata} errors={[]} handleChange={props.actionCb} />
          )) ||
          (Boolean(props.children) && props.children) ||
          (Boolean(props.default) && (
            <span
              data-id={props.id}
              data-testid={props.id}
              onClick={props.disabled ? undefined : props.actionCb}
              className={classNames(props.classname?.link)}
            >
              {props.default}
            </span>
          ))}
      </div>

      {state.isActionListMenuOpen && (
        <div className={classNames(styles['actionmenu-list'])}>
          {props.actionMenuList && props.actionMenuList.length > 0 && (
            <>
              {props.actionMenuList.map((list: ActionMenuListType) => (
                <span
                  key={list.key}
                  data-name={list.key}
                  role="button"
                  tabIndex={0}
                  onKeyUp={hanldeOnActionMenuOptionClick}
                  onClick={hanldeOnActionMenuOptionClick}
                >
                  {list.label}
                </span>
              ))}
            </>
          )}
        </div>
      )}
    </td>
  );
};

export default Cell;
