import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import FinancialInformation from '.';

export const financialInformationRoutes: RouteObject[] = [
  {
    path: RoutePath.companyfinance,
    element: withTransaction('FinancialInformation', 'component')(<FinancialInformation />),
  },
];
