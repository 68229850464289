export interface ITaskFilter {
  label: string;
  param: string;
  count?: number;
  value: any;
}

export interface TaskState {
  modalContext: TaskModalEnum | null;
  taskId?: string;
  selectedTask?: { [key: string]: any } | null;
  refreshTasks: Function;
  activityData?: InfoStateType[];
  searchBy: string;
  selectedFilters: ITaskFilter | null;
  companyName: string;
  pageNumber: number;
  filter: {
    orderBy: string;
    direction: string;
    pageSize?: string | number;
  };
}

export type InfoStateType = {
  assignedToFullName?: string;
  assignedToId?: string;
  assignedToPicture?: string;
  companyId?: string;
  companyName?: string;
  content?: string;
  createdBy?: string;
  dateCreated?: string;
  dateModified?: string;
  direction?: string;
  dueDate?: string;
  duration?: number;
  fullName?: string;
  id?: string;
  modifiedBy?: string;
  picture?: string;
  status?: string;
  subject?: string;
  taskStatus?: string;
};

export enum TaskModalEnum {
  ADD = 'new-task',
  EDIT = 'edit-task',
}

export const UPDATE_TASK = 'UPDATE_TASK';

interface UpdateTaskAction {
  type: typeof UPDATE_TASK;
  payload?: TaskState;
}

export type TaskActionTypes = UpdateTaskAction;
