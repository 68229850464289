import React from 'react';
import cn from 'classnames';
import ReactDatePicker from 'react-datepicker';
import styles from './Datepicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { getMonth, getYear } from 'date-fns';
import { range } from '../../field/datepicker/utils';
import { datePickerRange, monthsDic } from '_shared/utils/constants';
import { convertToLocalDateFormat } from '_shared/utils/date';

interface DatePickerProps {
  id?: string;
  value: string;
  className?: string;
  onChange?: (date: string | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

type CustomHeaderProps = {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: CustomHeaderProps) => {
  const years = range(datePickerRange.startYear.value, datePickerRange.endYear.value);
  const months = monthsDic.map((_, index) => monthsDic[index].value);

  return (
    <div className={styles.header} data-testid="date-picker-container">
      <button
        className={cn(styles['headings'], styles['change-month'])}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
      >
        {'<'}
      </button>

      <select
        className={styles.headings}
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((month) => (
          <option key={month} value={month} data-testid={month}>
            {month}
          </option>
        ))}
      </select>

      <select
        className={cn(styles['headings'], styles['year-select'])}
        data-testid="year-select"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
      >
        {years.map((option) => (
          <option key={option} value={option} data-testid={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        className={cn(styles['headings'], styles['change-month'])}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
      >
        {'>'}
      </button>
    </div>
  );
};

const Datepicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  ({ id, value, className, onChange = () => {}, onBlur }, ref) => {
    const normalisedValue = value ? new Date(value) : null;
    const _onChange = (date: Date | null) => {
      if (date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        onChange(`${year}-${month}-${day}`);
      } else {
        onChange(null);
      }
    };

    const CustomInput = React.forwardRef<HTMLInputElement>(({ onClick }: any, ref2) => {
      const displayValue = value ? (convertToLocalDateFormat(value) as string) : '';

      return (
        <div className={styles['custom-input']}>
          <input
            id={id}
            ref={ref2}
            type="text"
            readOnly
            className={cn(styles.input, className)}
            value={displayValue}
            onClick={onClick}
          />
          <i
            aria-labelledby="trailing-icon"
            className={cn(styles['trailing-icon'], 'material-icons')}
          >
            today
          </i>
        </div>
      );
    });

    return (
      <ReactDatePicker
        dateFormat="dd MMM yyyy"
        selected={normalisedValue}
        todayButton={'Today'}
        customInput={<CustomInput ref={ref} />}
        renderCustomHeader={CustomHeader}
        onChange={_onChange}
        onBlur={onBlur}
      />
    );
  }
);

export default Datepicker;
