import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { FormFieldPropType } from '_shared/fieldValidation/types';
import validation from '_shared/fieldValidation';
import useForm from '_shared/hooks/useForm';
import styles from './AddTaskModal.module.scss';
import Button from 'components/button';
import Field from 'components/field';
import Modal from 'components/modal';
import { useAppState } from 'store';
import { addTaskModalFields } from 'layouts/components/AddTaskModal.fields';
import { TaskModalEnum, UPDATE_TASK } from 'store/tasks/types';
import { postActivity, putActivity } from '_shared/api/activity';
import { ACTIVITY_TYPE } from 'components/activities/types';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';

const TaskModal = () => {
  const { state: appState, dispatch: globalDispatch } = useAppState();
  const [loading, setLoading] = useState(false);
  const { roles } = appState.system;
  const { t } = useTranslation();
  const [taskFields, setTaskFields] = useState<FormFieldPropType[]>([]);
  const refreshTasks = appState.tasks.refreshTasks;

  const clearTaskModalContext = () => {
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        modalContext: null,
        selectedTask: null,
      },
    });
  };

  const addTask = async (companyId: string, values?: any) => {
    setLoading(true);
    try {
      await postActivity(companyId, values);

      globalDispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:addedtask'),
        },
      });
      sendAmplitudeData(AmplitudeTrackingEnum.savetask);
    } catch (error) {
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:addtaskfailed'),
        },
      });
    } finally {
      refreshTasks(companyId);
      clearTaskModalContext();
      setLoading(false);
    }
  };

  const editTask = async (companyId: string, activityId: string, values: any) => {
    setLoading(true);
    try {
      await putActivity(companyId, activityId, values);

      globalDispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:updatedtask'),
        },
      });
    } catch (error) {
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t('home:tasks:toast:updatetaskfailed'),
        },
      });
    } finally {
      refreshTasks(companyId);
      clearTaskModalContext();
      setLoading(false);
    }
  };

  const submitTask = (changedValues?: any) => {
    setLoading(true);
    const taskValues = {
      content: changedValues.content || appState.tasks.selectedTask?.content,
      type: ACTIVITY_TYPE.TASK,
      dateCreated: new Date().toISOString(),
      task: {
        ...appState.tasks.selectedTask,
        ...changedValues,
        assignedToId:
          changedValues.assignedToId?.id ||
          appState.tasks.selectedTask?.assignedToId ||
          appState.system.currentUser?.authId,
        companyName:
          changedValues.companyName?.value ||
          appState.tasks.selectedTask?.companyName ||
          appState.company.companyName,
        taskStatus: changedValues?.status || appState.tasks.selectedTask?.taskStatus,
      },
    };

    if (appState.tasks?.modalContext === TaskModalEnum.ADD)
      return addTask(changedValues.companyName?.id || appState.company.companyId, taskValues);
    if (appState.tasks?.modalContext === TaskModalEnum.EDIT)
      return editTask(
        changedValues.companyName?.id || appState.tasks.selectedTask?.companyId,
        appState.tasks.selectedTask?.id,
        taskValues
      );
  };

  const { handleChange, handleSubmit, errors, metadata } = useForm(
    {},
    taskFields,
    submitTask,
    validation
  );

  useEffect(() => {
    const modalFields =
      (appState.tasks?.modalContext === TaskModalEnum.ADD &&
        addTaskModalFields(roles, {
          assignedToFullName: `${appState.system.currentUser?.firstName} ${appState.system.currentUser?.lastName}`,
          companyName: appState.company.companyName,
        })) ||
      (appState.tasks?.modalContext === TaskModalEnum.EDIT &&
        addTaskModalFields(roles, appState?.tasks?.selectedTask)) ||
      [];

    setTaskFields(modalFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.tasks?.modalContext]);

  return (
    <Modal
      show={Boolean(appState.tasks?.modalContext)}
      handleClose={() => clearTaskModalContext()}
      testId="task-modal"
    >
      <div className={cn(styles['task-modal-wrapper'])}>
        <header className={cn(styles['header-and-icon-container'])}>
          <h3 className={cn(styles['task-modal-header'])} data-testid="task-modal-heading">
            {appState.tasks?.modalContext === TaskModalEnum.ADD
              ? t('home:tasks:taskmodal:newtitle')
              : t('home:tasks:taskmodal:edittitle')}
          </h3>
          <div className={cn(styles['task-modal-header-icon'])}>
            <span className={cn('material-icons')}>task_alt</span>
          </div>
        </header>
        <div className={cn(styles['task-modal-fields'])}>
          {metadata.map((item) => {
            return (
              <Field metadata={item} errors={errors} handleChange={handleChange} key={item.id} />
            );
          })}
        </div>
        <div className={cn(styles['task-modal-actions-container'])}>
          <Button
            id="task-modal-cancel"
            className={cn(styles['action-button'])}
            ariaLabel="task-modal-cancel"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={clearTaskModalContext}
          >
            {t('home:tasks:taskmodal:cancelbutton')}
          </Button>
          <Button
            id="task-modal-action-button"
            className={cn(styles['action-button'])}
            ariaLabel="task-modal-action-button"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            tabIndex={0}
            loading={loading}
            clickHandler={handleSubmit}
          >
            {appState.tasks?.modalContext === TaskModalEnum.ADD
              ? t('home:tasks:taskmodal:newsubmitbutton')
              : t('home:tasks:taskmodal:editsubmitbutton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TaskModal;
