import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import ApplicationForm from '.';
import ViewClientDeals from './components/viewClientDeals';

export const companyDealsRoutes: RouteObject[] = [
  {
    path: RoutePath.companydeals,
    element: withTransaction('ApplicationForm', 'component')(<ApplicationForm />),
    children: [
      {
        path: RoutePath.companydeals,
        element: withTransaction('ViewClientDeals', 'component')(<ViewClientDeals />),
      },
    ],
  },
];
