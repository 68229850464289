import cn from 'classnames';
import styles from './newActivity.module.scss';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import { ACTIVITY_CATEGORY, NewActivityContentPropTypes } from '../types';
import Field from 'components/field';
import { newCallMetadata } from '../activities.fields';
import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import validation from '_shared/fieldValidation';
import { useState } from 'react';
import Textarea from 'components/field/textarea';

const NewActivityContent = ({
  saveActivity,
  isCollapsed,
  activity,
  handleChange,
  isSaving,
  activityCategory,
  handleCallStatus,
  callStatus,
}: NewActivityContentPropTypes) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    callErrors: {},
    activityErrors: {},
  });

  const onClick = () => {
    if (activityCategory === ACTIVITY_CATEGORY.CALL && callStatus === undefined) {
      setState({ ...state, callErrors: validation([newCallMetadata]) });
    } else if (activityCategory !== ACTIVITY_CATEGORY.CALL && !activity) {
      setState({ ...state, activityErrors: validation([textAreaMetadata]) });
    } else {
      saveActivity();
    }
  };

  const textAreaMetadata = {
    id: 'new-activity',
    name: 'newActivity',
    validationtype: ValidationTypes.TEXT,
    placeholder: t('home:companydetails:activities:new:placeholder'),
    value: activity,
    type: 'text',
    fieldType:
      activityCategory === ACTIVITY_CATEGORY.NOTE ? FieldTypes.EDITOR : FieldTypes.TEXTAREA,
    required: activityCategory !== ACTIVITY_CATEGORY.CALL,
    classNames: {
      value: styles['edit-activity-textarea'],
    },
  };

  return (
    <div className={cn(styles['content-container'], isCollapsed && styles['collapsed'])}>
      {activityCategory === ACTIVITY_CATEGORY.CALL ? (
        <div>
          <Field
            {...{
              metadata: { ...newCallMetadata, selectionKey: callStatus },
              errors: state.callErrors,
              handleChange: handleCallStatus,
            }}
          />
        </div>
      ) : null}
      {(activityCategory === ACTIVITY_CATEGORY.CALL && (
        <span className={cn(styles['description-header'])}>Detail (Optional)</span>
      )) ||
        null}
      {(activityCategory === ACTIVITY_CATEGORY.CALL && (
        <Textarea
          {...{
            metadata: textAreaMetadata,
            errors: [],
            handleChange,
          }}
        />
      )) || (
        <Field
          {...{
            metadata: textAreaMetadata,
            errors: state.activityErrors,
            handleChange,
          }}
        />
      )}
      <div className={cn(styles['save'])}>
        <button onClick={onClick}>
          {(isSaving && <Spinner size="small" />) || t('home:companydetails:activities:new:save')}
        </button>
      </div>
    </div>
  );
};

export default NewActivityContent;
