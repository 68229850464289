import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';

const languages: any = {
  unitedkingdom: 'en-GB',
  australia: 'en-AU',
  canada: 'en-CA',
  southafrica: 'en-SA',
};

if (process.env.NODE_ENV !== 'test') {
  i18n.use(initReactI18next);
}

i18n.init({
  // we init with resources
  resources: {
    en: {
      app: {
        name: 'for {{appType}}',
        swoop: 'Swoop Analytics : : Funder Marketplace',
        description: 'A portal for Swoop brokers',
      },
      currency: {
        symbol: 'currency_pound',
      },
      general: {
        vat: 'VAT',
        vatreturns: 'VAT returns',
      },
      common: {
        noData: 'N/A',
        pleasewait: 'Please wait...',
        permissionerror: 'Not authorised.',
        yes: 'Yes',
        no: 'No',
        onemoment: 'One moment',
        socialmedia: 'Social Media',
        google: 'Google',
        press: 'Press',
        friendsfamily: 'Friend/Family',
        usertype: 'My Accountant/Business Advisor',
        media: 'Media',
        event: 'Event',
        vc: 'VC',
        bank: 'Bank',
        partner: 'Partner',
        referred: 'Referred',
        other: 'Other',
        close: 'close',
        done: 'done',
        continue: 'continue',
        createaccount: 'Create account',
        next: 'next',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        disable: 'Disable',
        loading: 'Loading....',
        back: 'back',
        goback: 'Back',
        signin: 'Sign in',
        signout: 'Sign out',
        notfound: {
          title: "We couldn't find this page.",
          instructions: 'Please go back using the below link',
          goback: 'Go back',
        },
        notsupported: {
          title: 'Screen size is not supported',
          instructions: 'Please use desktop to access the broker portal',
        },
        forgotpassword: 'Forgot Password?',
        thankyou: 'Thank you 😊',
        failupload: 'Fail uploading file, try again!',
        dropfiles: 'Drop file here',
        draganddrop: 'Drag and drop or browse to upload',
        uploadsmallpdf: "To upload, please make sure it's a PDF smaller than 5MB",
        uploadfile: 'Please ensure your file(s) are supported',
        unpubPub: 'Unpublish/publish:',
        unpub: 'Unpublished',
        pub: 'Published',
        changessaved: 'Changes saved',
        servererror: 'We encountered a problem. Please try again later',
        update: 'update',
      },
      validation: {
        pristine: '',
        required: 'Required',
        email: {
          invalid: 'The email field must be a valid email',
          confirmemail: 'Emails do not match',
        },
        phone: {
          invalid: 'Please enter valid contact number',
        },
        password: {
          minInvalid: 'Password minimum length is {{length}}',
          notstrong: 'Password should contain mix of capital and numeric characters',
          confirmpassword: 'Passwords do not match.',
        },
        numeric: {
          invalid: 'Please enter a valid numeric value',
          min: 'Please enter a number greater than or equal to ',
          max: 'Please enter a number less than or equal to ',
        },
        percentage: {
          invalid: 'Please enter a valid percent/value',
        },
        checkbox: {
          atleastone: 'Please select at least one option',
        },
        date: {
          min: 'Please enter a date after',
          max: 'Please enter a date before',
        },
        customerrormessages: {
          rndspendlastyear:
            "Please enter your client's estimated R&D spend from the last financial year.",
          hasct600beenfiled: "Please let us know if your client's CT600 has been filed.",
          hasclaimedrndbefore: 'Please let us know if your client has filed an R&D claim before.',
          taxcreditspaidduringperiod:
            "Please enter how much your client has paid to HMRC in the period you're looking to get tax credits.",
          reasonforeligibility:
            'Please enter a brief summary of why your client is eligible for tax credits.',
          insurancepolicyrequested:
            'Please enter a brief description of the insurance policy requested.',
        },
      },
      error: {
        401: 'As your session expired, we logged you out to keep your account secure',
        400: 'There was an error processing your request',
        403: 'For security reasons, you have been logged out',
        404: 'There was an error processing your request',
        422: 'There was an error processing your request',
        429: 'There was an error processing your request',
        500: 'We encountered a problem. Please try again later',
        501: 'We encountered a problem. Please try again later',
        networkError: 'We are unable to connect you to our network',
        incorrectunamepassword: 'Wrong email or password.',
      },
      signup: {
        firstname: {
          label: 'First name',
          placeholder: 'John',
        },
        lastname: {
          label: 'Last name',
          placeholder: 'Doe',
        },
        email: {
          label: 'Email',
          placeholder: 'John@swoopfunding.com',
        },
        phonenumber: {
          label: 'Phone number',
          placeholder: '+44 7891234569',
        },
        password: {
          label: 'Password',
          placeholder: '***********',
        },
        interest: {
          label: 'Are you interested in:',
          placeholder: 'Select an option',
        },
        reference: {
          label: 'Where did you hear about Swoop?',
          placeholder: 'Select an option',
        },
        marketing: {
          label: 'Send me updates & product information',
        },
        bottom: {
          agreeDesc: 'By clicking the button, you agree to our',
        },
        confirmation: {
          title:
            "You've signed up successfully, you should have received a verification email from us.",
          verifyemail: "Once you've verified your email address you will be able to proceed.",
          subtitle: "If you've already done this",
          clickhere: 'click here',
        },
        verification: {
          title: 'Your email was verified. You can continue using the application.',
          subtitle:
            'Your account is now verified and you can start your funding and savings process',
        },
      },
      login: {
        title: 'Hi there, welcome back...',
        username: {
          label: 'Username',
          placeholder: 'User Name',
        },
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        forgotpassword: {
          title: 'Forgot your password?',
          submit: 'Submit',
          instructions: "We'll send you an email with instructions on how to reset your password.",
          email: {
            label: 'Email address',
            placeholder: 'john@swoopfunding.com',
          },
          emailme: 'Email me instructions',
          justremembered: 'Just remembered? Sign In instead',
          checkemail: {
            title: 'Forgot your password? No problem.',
            instructions:
              "Thank you! If you have an account attached to the email {{email}},  we sent you instructions to change your password. If you haven't receive it, you can always contact us.",
            signIn: 'Sign In',
            createaccount: 'Create an Account',
          },
        },
      },

      register: {
        isvProgram: 'Welcome to Swoop! Please wait, we are validating some data with Microsoft.',
        submit: 'Register',
        alreadyhaveaccount: 'Already have an account?',
        invitationsent: 'Invitation Sent',
        clientadded: 'Client added',
        confirmpassword: {
          label: 'Confirm Password',
          placeholder: '***********',
        },
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        firstname: {
          label: 'First Name',
          placeholder: 'First Name',
        },
        lastname: {
          label: 'Last Name',
          placeholder: 'Last Name',
        },
        contact: {
          label: 'Contact Number',
          placeholder: 'Contact Number',
        },
        userexists: 'This user already exists',
      },

      migrateuser: {
        success: 'Successfully upgraded account.',
        failure: 'Failed to upgrade account.',
        submit: 'I want to upgrade',
      },

      onboarding: {
        company: {
          question: "What's the name of your businsess?",
          title: 'We will connect you straight to Companies House to find your business info',
          business: {
            label: 'Search business name:',
            placeholder: 'Search....',
          },
          description: {
            part1:
              "We're going to gather some information now to ensure all the finance solutions we share are tailored specifically to your business.",
            part2:
              "You'll only be asked for this information once and it should take just a couple of minutes.",
            part3:
              "Don't worry, all the details you provide are confidential and protected by the most advanced security technology available.",
          },
        },
        officer: {
          question: 'Are you one of these officers?',
          title: 'Are you one of these officers?',
          officers: {
            label: 'Are you one of these officers?',
            placeholder: 'Search....',
          },
          description: {
            part1:
              "We're going to gather some information now to ensure all the finance solutions we share are tailored specifically to your business.",
            part2:
              "You'll only be asked for this information once and it should take just a couple of minutes.",
            part3:
              "Don't worry, all the details you provide are confidential and protected by the most advanced security technology available.",
          },
        },
        integration: {
          question: 'Integrate your bank account',
          title: 'Integrate Swoop Limited’s bank account and accountancy software',
          accountancy: {
            label: 'Linked accountancy software',
            question: 'What is the accountancy software data used for?',
            description:
              'Linking your accounting software provides: most accurate matches, speediest application process, access to all financial products, easy administration',
            button: 'Link your accountancy software',
          },
          bankaccount: {
            label: 'Linked bank account',
            question: 'What is the bank account data used for?',
            description:
              'Integrating your bank account enables: instant analysis of everyday spend, compare your bank costs vs all banks, save on average £3000 p.a. and simplify your admin',
            button: 'Link your bank account data',
            unlink: 'Unlink account',
          },
          description: {
            part1:
              'Supercharge your access to funding by integrating your accountancy software and bank account.',
            part2:
              'Link your accountancy software to get the quickest access to funding with rapid applications. Integrated ',
          },
        },
        fundingneed: {
          question: 'Your funding requirements',
          title: 'Your funding requirements',
          subtitle: 'Funding needs',
          funding: {
            label: 'How much funding do you require?',
            placeholder: '',
          },
          bank: {
            label: 'Who is your primary bank?',
            placeholder: '',
          },
          performance: {
            label:
              'What changes has the business made in the short to medium term to help drive business performance either back to pre-virus levels or beyond?',
            placeholder: '',
          },
          description: {
            part1:
              'The more we understand about the funding you require, the slicker the process will be.',
            part2:
              'We work with world class partners to ensure we’re finding you only the best funding and savings options.',
          },
        },
        companyinfo: {
          question: 'Your company details',
          details: {
            title: 'General',
            owner: {
              label: 'Account owner',
              placeholder: 'JohnDoe@swoopfunding.com',
            },
            stage: { label: 'Company stage', placeholder: 'Select a stage' },
            score: { label: 'Company score', placeholder: '' },
            name: { label: 'Company name', placeholder: '' },
            companynumber: { label: 'Company number', placeholder: '' },
            address: { label: 'Registered address', placeholder: '' },
            tradingaddress: { label: 'Trading address', placeholder: '' },
            tradingStartDate: {
              label: 'Trading Start Date',
              placeholder: '',
            },
            businesstype: { label: 'Legal entity type', placeholder: '' },
            model: {
              label: 'Business model',
              placeholder: '',
              b2b: 'B2B',
              b2c: 'B2C',
              b2b2c: 'B2B2C',
            },
            industry: {
              label: 'Industry list',
            },
            sic: {
              label: 'SIC codes list',
              placeholder: '',
            },
            incorp: {
              label: 'Incorporation date',
              placeholder: '',
            },
            tradestartdate: {
              label: 'Trading start date',
              placeholder: '',
            },
            primarybank: {
              label: 'Primary bank',
            },
            fundingrequirement: {
              label: 'Funding requirement',
            },
            changes: {
              label:
                'What changes has the business made in the short to medium term to help drive business performance either back to pre-virus levels or beyond?',
            },
            guarantee: {
              label: 'Is your business happy to provide a personal guarantee?',
            },
            numofemployees: {
              label: 'Number of employees',
            },
          },
          director: {
            title: 'Director information',
            name: { label: 'Name', placeholder: '' },
            appointment: { label: 'Number of appointments', placeholder: '' },
            flags: { label: 'Possible flags', placeholder: '' },
          },
          person: {
            title: 'Persons with significant control (>25%)',
            name: { label: 'Name', placeholder: '' },
            nationality: { label: 'Nationality', placeholder: '' },
            share: { label: 'Share holding percentage', placeholder: '' },
            voting: { label: 'Voting percentage', placeholder: '' },
          },
          primary: {
            title: 'Primary officer',
          },
          secondary: {
            title: 'Secondary officer',
          },
          tertiary: {
            title: 'Tertiary officer',
          },
        },
        financialinfo: {
          question: 'Your financial information',
          title: 'Your financial information',
          subtitle: 'Funding needs',
          turnover: {
            label: 'What was the turnover in the last financial year?',
            placeholder: '',
          },
          currentprofit: {
            label: 'What was the operating profit in the current financial year?',
            placeholder: '',
          },
          pastprofit: {
            label: 'What was your operating profit in the last financial year?',
            placeholder: '',
          },
          trade: {
            label: 'What is your current Trade Debt balance?',
            placeholder: '',
          },
          assets: {
            label: 'What is the value of your unencumbered fixed assets?',
            placeholder: '',
          },
          wage: {
            label: 'What is your annual wage bill?',
            placeholder: '',
          },
          ebitda: {
            label:
              'What were your company earnings before interest, tax, depreciation and amortisation (EBITDA) for the last financial year?',
            placeholder: '',
          },
          guarantee: {
            label: 'Are you happy to provide Personal Guarantee?',
            placeholder: '',
          },
        },
        userinfo: {
          fname: 'First name',
          lname: 'Last name',
          title: 'Title',
          dob: 'Date of birth',
          gender: 'Gender',
          nationality: 'Nationality',
          dnationality: 'Dual nationality holder',
          country: 'Country of residence',
          role: 'Role',
          contactnumber: 'Contact number',
          email: 'Email',
        },
      },
      blocknavigation: {
        heading: 'You have unsaved changes, are you sure you want to navigate without saving?',
        stay: 'stay',
        leave: 'leave',
      },
      sidebar: {
        primary: {
          add: 'Add',
          addbroker: 'Add Broker',
          addadvisor: 'Add Advisor',
          invitebroker: 'Email broker invitation',
          inviteadvisor: 'Email advisor invitation',
          addbusiness: 'Add client',
          invitebusiness: 'Email client invitation',
          quickinvite: 'Quickly Add a Client',
          overview: 'Overview',
          businesses: 'Businesses',
          matches: 'Matches',
          clients: 'Clients',
          opportunities: 'Opportunities',
          colleagues: 'Colleagues',
          dashboard: 'Dashboard',
          products: 'Products',
          companies: 'Companies',
          essentials: 'Essentials',
          funders: 'Funders',
          applications: 'Applications',
          deals: 'Deals',
          tasks: 'Tasks',
          newtask: 'New task',
          groupprofile: 'Company Profile',
          retool: 'Retool',
          help: 'Help & FAQs',
          education: 'Education',
        },
        tertiary: {
          yourprofile: 'Your Profile',
        },
        addbusiness: {
          title: 'Add Client',
        },
        invitebusiness: {
          title: 'Invite Client',
        },
        invitebroker: {
          title: 'Invite Broker',
        },
        inviteadvisor: {
          title: 'Invite Advisor',
        },
        invitegroup: {
          title: 'Invite Group',
        },
        migrateexistinguser: {
          title: 'Migrate Existing User',
        },
        inviteexistinguser: {
          title: 'Warning!',
        },
        inviteuser: {
          title: 'Invite Colleague',
        },
        manageuser: {
          title: ' Manage Colleagues',
        },
        addclientmodal: {
          header: 'How would you like to add this client?',
          options: {
            manual: {
              title: 'Manual',
              bestfor: 'Best for:',
              description:
                'Clients where you have all the information to instantly start a funding search',
              button: 'Add manually',
            },
            invitation: {
              title: 'Invitation',
              bestfor: 'Best for:',
              description:
                'Clients where you need to collect more information before you can start a funding search',
              button: 'Invite your client',
            },
          },
          fields: {
            companynumber: {
              placeholder: 'Company number',
              label: "What is your client's company number?",
            },
          },
        },
      },
      home: {
        dashboard: {
          title: 'Dashboard',
          widgets: {
            companieseachbroker: {
              title: 'Number of companies assigned to each broker',
              ylabel: 'Record count',
              legend: {
                gold: 'Gold',
                silver: 'Silver',
                bronze: 'Bronze',
                copper: 'Copper',
              },
            },
            companiesregistered: {
              title: 'Number of companies registered',
              ylabel: 'Record count',
              actions: {
                pastweek: 'Past week',
                pastyear: 'Past year',
                yearly: 'Yearly',
              },
              labels: {
                pastweek: {
                  monday: 'Monday',
                  tuesday: 'Tuesday',
                  wednesday: 'Wednesday',
                  thursday: 'Thursday',
                  friday: 'Friday',
                  saturday: 'Saturday',
                  sunday: 'Sunday',
                },
                pastyear: {
                  january: 'January',
                  february: 'February',
                  march: 'March',
                  april: 'April',
                  may: 'May',
                  june: 'June',
                  july: 'July',
                  august: 'August',
                  september: 'September',
                  october: 'October',
                  november: 'November',
                  december: 'December',
                },
              },
            },
            companiesmovedstage: {
              title: 'Number of companies moved stage',
              ylabel: 'Record count',
              actions: {
                pastweek: 'Past week',
                pastmonth: 'Past month',
              },
              labels: {
                qualified: 'Qualified',
                future: 'Future',
                information: 'Information',
                matched: 'Matched',
                additionalinformationrequired: 'Additional Information Required',
                underwriting: 'Underwriting',
                closedlost: 'Closed Lost',
              },
            },
            userswithoutcompanyinfo: {
              title: 'Number of users registered without adding company information',
              viewusers: 'View users',
            },
          },
        },
        products: {
          title: 'Products',
          heading: 'Products',
          search: {
            placeholder: 'Search products',
            empty: 'No products found',
          },
          filter: {
            enabled: 'Show Only Enabled?',
            pagesize: 'Page Size',
            type: 'Type',
            subtype: 'Subtype',
          },
          fields: {
            certifiedCopyOfIncorporation: {
              label: 'A certified copy of the Certificate of Incorporation',
            },
            fixedAssetSchedule: {
              label: 'A Fixed Asset Schedule',
            },
          },
        },
        forms: {
          migrate: 'Migrate',
          confirm: 'Confirm migration',
        },
        productdetails: {
          information: {
            title: 'Information',
            save: 'Save Changes',
            success: 'Product fields saved',
            error: 'Could not fetch product fields, please try again later.',
            general: {
              title: 'General Information',
            },
            eligibility: {
              title: 'Eligibility Criteria',
            },
          },
          documents: {
            title: 'Documents',
            save: 'Save Changes',
            success: 'Product fields saved',
            error: 'Could not fetch product fields, please try again later.',
          },
          applicationform: {
            title: 'Application form',
            islive: 'Is Product Live?',
            save: 'Save Changes',
            success: 'Product fields saved',
            error: 'Could not fetch company fields, please try again later.',
            saveerror: 'Could not save product fields, please try again later.',
          },
          insights: {
            title: 'Insights',
            save: 'Save Changes',
            success: 'Product fields saved',
            error: 'Could not fetch product fields, please try again later.',
          },
        },
        productinfo: {
          confirmremove: 'Are you sure you want to remove the document? ',
          undone: 'This cannot be undone',
          details: {
            title: 'General',
            productname: { label: 'Product name', placeholder: '' },
            fundername: { label: 'Funder name', placeholder: '' },
            category: { label: 'Category', placeholder: 'Select an option' },
            subcategory: {
              label: 'Subcategory',
              placeholder: 'Select an option',
            },
            desc: { label: 'Product information', placeholder: '' },
            additionalEligibility: {
              label: 'Additional Eligibility Criteria',
              placeholder: '',
            },
            standalone: { label: 'Standalone', placeholder: '' },

            minfacility: {
              label: 'Min facility',
              placeholder: 'Default value',
            },
            maxfacility: {
              label: 'Max facility',
              placeholder: 'Default value',
            },
            minterm: {
              label: 'Min term (year)',
              placeholder: 'Default value',
            },
            maxterm: {
              label: 'Max term (year)',
              placeholder: 'Default value',
            },
            minpa: {
              label: 'Interest rate % (min pa)',
              placeholder: 'Default value',
            },
            maxpa: {
              label: 'Interest rate % (max pa)',
              placeholder: 'Default value',
            },
            arrfees: {
              label: 'Arrangement fees',
              placeholder: 'Default value',
            },
            exitfees: { label: 'Exit fees', placeholder: 'Default value' },
            interestfree: {
              label: 'Interest free periods (months)',
              placeholder: 'Default value',
            },
            capitalrepay: {
              label: 'Capital repayment (months)',
              placeholder: 'Default value',
            },
            ebidtaMaxMult: {
              label: 'EBITDA (max x)',
              placeholder: 'Default value',
            },
            wageBill: {
              label: 'Annual wage bill (max x)',
              placeholder: 'Default value',
            },
            turnoverMax: {
              label: 'Turnover (max x)',
              placeholder: 'Default value',
            },
          },
          financials: {
            title: 'Financials',
            oneyear: '1 year filed accounts',
            twoyear: '2 year filed accounts',
            draftaccount: 'Draft account for previous year',
            management: 'Management accounts (up to date)',
            sixmonths: '6 months company bank statements',
            twelevemonthscompany: '12 months company bank statements',
            twelevemonthsmerchant: '12 months merchant terminal  statements',
            twelevemonthsonlinemerchant: '12 months online merchant terminal  statements',
            prooftax: 'Proof of tax clearance/tax access number',
            vat: 'VAT returns',
            debtorssummary: 'Summary aged debtors report',
            debtorsdetailed: 'Detailed aged debtors report',
            creditorssummary: 'Summary aged creditors',
            creditorsdetailed: 'Detailed aged creditors',
            fixedasset: 'A fixed asset schedule',
            debt: 'A schedule pf outstanding Debt',
            proofod: 'Proof of overdraft balance',
          },
          ownership: {
            title: 'Ownership',
            directors: 'A certified copy of the share register and directors registers',
            incorp: 'A certified copy of the certificate of incorporation',
          },
          funding: {
            title: 'Funding documents',
            terms: 'Terms of business operations',
            salie: 'SALIE form',
            business: 'Business plan',
            pitchdeck: 'Pitch deck',
            financialmodel: 'Financial model',
            financialforecast: 'Financial forecast',
            guarantee: 'Personal guarantee',
          },
          identification: {
            title: 'Identification',
            proofidentity: 'Proof of identity',
            proofaddress: 'Prood of address (dated within last 3 months)',
          },
          other: {
            title: 'Other',
            user: 'User customisation',
          },
          application: {
            title: 'Application form',
            form: 'Applications form',
            upload: {
              title: 'Application form uploader',
              subtitle: 'To upload, make sure it’s a PDF smaller than 5mb',
              required: 'Please upload required documents.',
            },
          },
          eligibility: {
            title: 'Eligibility criteria',
            isspecifictobank: {
              label: 'Is your product specific to particular bank account?',
              placeholder: '',
            },
            clients: {
              label: 'Existing clients only?',
            },
            bank: {
              label: 'If yes, which bank?',
              placeholder: 'Select an option',
            },
            isspecificlocation: {
              label: 'Is your product specific to particular location?',
              placeholder: '',
            },
            location: {
              label:
                'If yes, choose specific regions (cities, counties, locales, boroughs or local authorities)?',
              placeholder: 'Select an option',
            },
            isspecificindustry: {
              label: 'Is there any industry that needs to be excluded?',
              placeholder: '',
            },
            sector: {
              label: 'If yes, please specify all sectors that are covered?',
              placeholder: 'Select an option',
            },
            minturnover: { label: 'Minimum turnover', placeholder: '' },
            tradingage: {
              label: 'Minimum trading age (months)',
              placeholder: 'Default value',
            },
          },
        },
        formBuilder: {
          search: 'Search fields...',
          buttons: {
            addall: 'Add all',
            addsection: 'Add section',
            clearall: 'Clear all',
          },
          previewtitle: 'Form preview',
          emptystate: {
            withaccess: 'Drag fields here to add them to the form',
            noaccess: 'This form has not been calibrated yet',
          },
          requiredfield: 'Required',
        },
        advisors: {
          title: 'Advisors',
          heading: 'Advisors',
          details: {
            title: 'Details',
            empty: 'Advisor details not found',
          },
          search: {
            placeholder: 'Search Advisors',
            empty: 'No advisors found',
          },
        },
        colleagues: {
          title: '{{title}}',
          heading: '{{heading}}',
          details: {
            title: 'Details',
            empty: 'Colleague details not found',
            toast: {
              selectrole: 'Please select colleague role',
              assignedsucces: 'Colleague roles succesfully assigned',
              assignfailed: 'Colleague Roles Not Assigned',
              cannoteditsuperadmin: 'Cannot edit Super Admin colleagues',
              resetpasswordsuccess: 'Password reset email sent successfully',
              flushcachesuccess: 'Colleague cache flushed successfully',
              reactivatedusersuccess: 'Colleague has been restored',
              deactivatedusersuccess: 'Colleague has been disabled',
            },
            savebutton: 'Save Changes',
          },
          search: {
            placeholder: 'Search colleagues',
            empty: 'No colleagues found',
          },
          deletionmodal: {
            title: 'Confirm disable',
            warningtext:
              'Are you sure you want to disable {{name}}? Doing so will disable their access to the application.',
          },
        },
        usermanagement: {
          title: 'Manage External Colleagues',
          description:
            'Start by entering a full email address below to search for an external colleague.',
          search: {
            placeholder: 'i.e. name@email.com',
          },
          table: {
            empty: 'No user found by that email',
          },
        },
        tasks: {
          title: 'Tasks',
          searchtask: 'Search Tasks',
          toggledone: 'Hide done tasks',
          emptymessage: 'No tasks found',
          assignedfilters: {
            all: 'All',
            assignedtome: 'Assigned to me',
            assignedtoothers: 'Assigned to others',
          },
          taskmodal: {
            edittitle: 'Task',
            newtitle: 'New Task',
            editsubmitbutton: 'Save Changes',
            newsubmitbutton: 'Add Task',
            cancelbutton: 'Cancel',
          },
          toast: {
            addedtask: 'Task added successfully',
            updatedtask: 'Task updated successfully',
            addtaskfailed: 'Adding task failed',
            updatetaskfailed: 'Updating task failed',
          },
        },
        companies: {
          title: 'Companies',
          search: {
            placeholder: 'Search clients',
            empty: 'No companies found',
            warning:
              'You may need to adjust your search settings in the top right corner to find your result',
            emptysearch: 'No companies found for ',
            add: 'You can add clients at the top of the left menu',
          },
          filter: {
            swoopscore: 'Swoop Score',
            accountowner: 'Account owner',
            datecreated: 'Date created',
          },
        },
        groupprofile: {
          fields: {
            groupname: 'Group name',
            logo: 'Logo (Optional)',
            emailfooter: 'Email Footer (Optional)',
          },
          tooltip: {
            emailfooter:
              "If you'd like to add a footer to all emails the system sends to your clients.",
          },
          welcomemodal: {
            title: 'Welcome to Swoop!',
            details:
              "To make Swoop feel like home, you can edit your Company's profile to set things like your logo and add footers to your email communications.",
            button: 'Make me at home',
          },
          savedmodal: {
            title: 'Changes saved!',
            details:
              "If you want to update your Company's profile again, you can do this from the left hand menu.",
            button: 'Got it',
          },
          toast: {
            adminonly: "Only admin users can edit the Company's profile",
            specificfile: "To upload, make sure it's a JPG, PNG or a SVG image smaller than 20MB",
            entername: 'Please enter Group name',
            savesuccess: 'Company profile successfully updated',
            savefailed: 'Failed to update Company profile',
          },
          loadingmessage: 'Please wait while we fetch your group profile...',
          savebutton: 'Save Changes',
          uploaddescription:
            'To preserve your logo, please use the best quality version you have (smaller than 20MB)',
        },
        applicationsClient: {
          title: 'Applications',
          search: {
            placeholder: 'Search applications',
            empty: 'No applications found',
            add: 'To start a new Application, first open the intended client and then choose a product on the Funding Matches tab',
          },
          filter: {
            swoopscore: 'Swoop Score',
            accountowner: 'Account owner',
            datecreated: 'Date created',
          },
          tooltips: {
            broker: {
              amount: 'This is the amount for which your client is applying',
              commission: 'This is where you can enter the anticipated Funder Commission',
              clientfee:
                "If applicable, this is where you can enter the Fee you're charging your client",
              introducerfee:
                'If applicable, this is where you can enter the amount you owe the Introducer of this client',
              netfee:
                'This is automatically calculated by adding the Funder Commission to the Client Fee, then subtracting the Introducer Fee',
              fundingmanager:
                'This is the name of the funding manager who is helping the client get funding',
              accountowner:
                'If applicable, this is the name of the account owner at the firm that referred this client',
            },
            advisor: {
              introducerfee:
                "Based on the funding amount, this is how much commission you're set to earn",
            },
            success: {
              submit: 'Application Updated',
            },
            error: {
              invalidnetfeevalue: 'Net Fee must be greater than or equal to 0',
              invalidfieldvalue: 'Fields must have a value equal to or greater than 0',
            },
          },
        },
        deals: {
          title: 'Deals',
          emptymessage1: 'No Deals found',
          emptymessage2:
            'You can start a new deal from within the client Deals or Funding Matches tab',
          filter: {
            usertoggle: 'Owner',
            statustoggle: 'Status',
            creatorfilter: 'Creator',
            dealSubTypeFilter: 'Subtype',
            clearfilters: 'Clear',
            hidecompletedlabel: 'Hide Completed',
            hidecompletedtooltip:
              'This toggle hides completed deals where the status is Closed Won or Closed Lost.',
            hidepipelinetooltip: 'Toggle Pipeline View',
            searchdealsplaceholder: 'Search deals',
            startnewdealbutton: 'Start New Deal',
          },
          createdealmodal: {
            title: 'New Deal',
            fields: {
              typelabel: 'Type',
              subtypelabel: 'Subtype',
              subtypeplaceholder: 'Select subtype',
              stagelabel: 'Stage',
              stageplaceholder: 'Select stage',
              amountlabel: 'Amount',
              amountplaceholder: 'Enter amount',
              ownerlabel: 'Owner',
            },
            buttons: {
              start: 'Start',
              cancel: 'Cancel',
            },
            toast: {
              success: 'New Deal created',
              failed: 'Failed to create Deal',
            },
          },
          dealdetailsmodal: {
            cardtitles: {
              info: 'Info',
              funders: 'Funders',
              addnewfunders: 'Add New Funders',
              requireddocuments: 'Required Documentation',
            },
            button: {
              savebutton: 'Save info',
            },
            fundertable: {
              brokeremptymessage:
                'No funders found. Please use the section below to add new funders.',
              advisoremptymessage: 'No funders found.',
            },
            addfunders: {
              matchesbutton: 'Matches',
              allfundersbutton: 'All Funders',
            },
            requireddocumentslabel:
              'Which funders would you like to see the required documentation for?',
            selectfunderplaceholder: 'Select funder',
            toast: {
              updatesuccess: 'Deal succesfully updated',
              updatefailed: 'Deal could not be updated',
              failedfetch: 'Deal info could not be loaded',
              removedfundersuccess: 'Funder successfully removed from Deal',
              removedfunderfailed: 'Funder could not be removed from Deal',
              updatefundersuccess: 'Funder successfully updated',
              updatefunderfailed: 'Funder update failed',
              applicationformsave: 'Application form saved',
              applicationformsubmitted: 'Application form submitted',
              applicationformfailedsubmitted: 'Application form failed to submit',
              invalidnetfee: 'Net fee should not be less than 0',
              deletesucess: 'Deal successfully deleted',
              deletefailed: 'Deal could not be deleted',
              copysucess: 'Funder ID copied to clipboard',
            },
          },
          dealsnotificationmodal: {
            brokertitle: 'Upgraded Deal Management',
            advisortitle: 'Deal Tracking Upgrades',
            brokerdescription: `
                To simplify the Deal Management process, we have now created a parent "Deal" entity.
                Instead of creating a separate application for each funder you're considering, you can now simply attach the funders you're considering to the parent "Deal" and manage your overall progress there.`,
            notessection: {
              header: 'Please note:',
              note: `
                  To add funders to a deal, simply open an existing/new deal, and add the funder(s) you're planning to speak to.
                  To remove a funder from a deal, simply click the delete icon on the right hand side within the deal.
                  At any point, you can add or remove funders from a deal.
                  At any point, you can change the deal type.
                `,
            },
            advisordescription: `
                To simplify the Deal Tracking process, we have now created a parent “Deal” entity.
                Instead of each funder being shown separately, they’ll now be grouped together inside a parent “Deal”.
                This also applies to historic applications, which will remain accessible from within the relevant deal.
                As before, you’ll still be able to easily track the high-level progress of deals using the pipeline view.
              `,
            checkboxlabel: "Don't show me this again",
            closemodal: 'Got it',
          },
          deletesucess: 'Deal successfully deleted',
          deletefailed: 'Deal could not be deleted',
        },

        deletemodal: {
          title: 'Confirm delete',
          description: 'Please confirm you want to delete the following funder',
          toast: {
            applications: {
              success: 'Funder succesfully deleted',
              failed: 'Funder could not be deleted',
            },
            clients: {
              success: 'Client succesfully deleted',
              failed: 'Client could not be deleted',
            },
          },
          fields: {
            company: {
              companyname: 'Company Name',
              signupdate: 'Signup Date',
              fundingmanager: 'Funding Manager',
            },
            application: {
              providername: 'Funder',
              productname: 'Product Name',
              datecreated: 'Created Date',
            },
          },
        },
        customlinkmodal: {
          title: 'Confirm delete',
          description: 'Please confirm you want to delete the following custom link',
        },
        companydeletionmodal: {
          title: 'Confirm delete',
          description: 'Please confirm you want to delete the following client',
        },
        incompleteapplicationsmodal: {
          title: 'Incomplete Applications',
          currentlysubmitted: "You've currently only submitted:",
          beforesubmit:
            'Please be aware that we may need the rest of this information before we can submit your application to the relevant lender.',
          cancel: 'Cancel',
          submit: 'Submit Incomplete Application',
          missingdocs: '{{submittedDocs}} out of {{requiredDocs}} documents',
          incompleteapp: '{{percentage}}% of the application form',
        },
        highcommissionmodal: {
          header: 'Higher Commission Rates',
          warningmessage: 'Please confirm the following higher commission rates',
          buttons: {
            cancel: 'Cancel',
            confirm: 'Confirm',
          },
        },
        statushistorymodal: {
          title: 'Status History',
          tooltip: 'View Status History',
        },
        dealdeletionconfirmmodal: {
          title: 'Confirm delete',
          description:
            'Please confirm you want to delete the following deal. It has {{numOfFunders}} funders attached.',
          owner: 'Owner',
          company: 'Company',
          subtype: 'Subtype',
          status: 'Status',
        },
        companydetails: {
          savingsproducts: {
            title: 'Savings Products',
          },
          essentials: {
            title: 'Essentials',
          },
          deals: {
            title: 'Deals',
          },
          integrations: {
            title: 'Integrations',
            accountingsoftware: {
              title: 'Accounting software',
              linksoftware: 'Link accounting software',
              resumeintegration: 'Resume integration',
            },
            bankingsoftware: {
              title: 'Bank account',
              linksoftware: 'Link bank account data',
              resumeintegration: 'Resume integration',
            },
          },
          activities: {
            note: {
              edit: 'Edit',
              emptyhistory: 'No completed activities found',
              emptyfutureandoverdue: 'No future or overdue activities found',
              newnote: 'Add new Activity',
              order: 'Order by',
              filter: 'All Activities',
            },
            delete: {
              title: 'Confirm delete',
              confirmdelete:
                'Please confirm you want to delete the following {{activityType}} activity',
              success: '{{activityType}} activity deleted',
            },
            edit: {
              confirmedit: 'Edit the following {{activityType}} activity',
              confirm: 'confirm',
              cancel: 'cancel',
              error: 'Could not update {{activityType}}',
              success: '{{activityType}} updated',
            },
            new: {
              title: 'New Activity',
              placeholder: 'write a description...',
              noteexists: 'Please save or delete current activity',
              save: 'Save your activity',
              success: '{{activityType}} Saved',
              confirmdelete: 'Are you sure you want to delete this activity?',
              confirmleave: 'Unsaved activity will be lost, Are you sure you want to leave?',
            },
            titles: {
              futureandoverdue: 'Future & Overdue',
              history: 'History',
            },
            emails: {
              showmore: 'Show trimmed content',
              hide: 'Hide trimmed content',
            },
          },
          overview: {
            title: 'Overview',
            info: {
              title: 'Company Info',
              name: 'Company Name',
              businessmodel: 'Business Model',
              companynumber: 'Company Number',
              siccode: 'Sic Code',
              industry: 'Industry',
              status: 'Status',
              accounts: 'Accounts',
              industrydescription: 'Industry Description',
              charges: 'Charges',
              incorporationdate: 'Incorporation Date',
              creditscore: 'Credit Score',
              tradingage: 'Trading Age (months)',
              ccj: 'CCJ Info',
              companysize: 'Company Size',
              accountant: 'Accountant',
              solicitor: 'Solicitor',
              auditor: 'Auditor',
              businesstype: 'Legal Entity Type',
              currentbank: 'Current Bank',
              registeredaddress: 'Registered Address',
              website: 'Website',
              tradingaddress: 'Trading Address',
            },
            creditreports: {
              title: 'Credit report',
              notlinked: '{{companyName}} has no linked credit reports.',
            },
            financialsnapshot: {
              title: 'Financial snapshot',
              yearend: 'Year End',
              calendaryearend: 'Year End (Calendar Year End)',
              turnover: 'turnover',
              employees: 'Employees',
              liabilities: 'Total Liabilities',
              assets: 'Total Assets',
              debtratio: 'Debt Ratio (%)',
              cash: 'Cash in bank',
              netassets: 'Net Assets',
              vsprevious: 'vs previous year',
              viewfinancialinfo: 'view financial info',
            },
            people: {
              title: 'People info',
              directors: 'Directors',
              stakeholders: 'Stakeholders',
              shareholder: 'Shareholder',
              emptydirectors: 'No directors found',
              emptystakeholders: 'No stakeholders found',
              currentdirectors: 'Show only current directors',
              name: 'Full Name',
              flag: 'Flag',
              flagdescription:
                'This means this director is a director at a company that is in "Liquidation" at Companies House. To see more info, please click on the Companies House link to the right.',
              dob: 'DOB',
              resignationdate: 'Resignation Date',
              emptystate: 'No contacts found',
              source: 'Source',
              appointments: '#appointments',
              nationality: 'Nationality',
              shareholderpercentage: 'Shareholding%',
              votingpercentage: 'Voting%',
              companyowner: {
                fullname: 'Full Name',
                dateofbirth: 'Date of Birth',
                employmentstatus: 'Employment Status',
                buildingnumber: 'Building Number',
                street: 'Street',
                state: 'State',
                postcode: 'Postcode',
                residentialstatus: 'Residential Status',
                income: 'Income',
                personalcreditlimit: 'Personal Credit Limit',
                gender: 'Gender',
                city: 'City',
                country: 'Country',
                title: 'Title',
              },
            },
            accountinfo: {
              title: 'Account info',
              accountowner: 'Account Owner',
              fundingmanager: 'Funding Manager',
              liveopportunities: 'Live opportunities',
              leadscore: 'Lead score',
              fundedopportunities: 'Funded opportunities',
              source: 'Source',
              totalfundedamount: 'Total funded amount',
              sourcedetail: 'Source detail',
              totalswoopfee: 'Total Swoop fee generated',
              referredby: 'Referred by',
              energysavings: 'Energy savings',
              accountstatus: 'Account status',
              fxsavings: 'FX savings',
              rdsavings: 'Has R&D Tax Credit?',
              contact: {
                title: 'Contacts',
                new: 'Add new contact',
                name: 'First name',
                lastname: 'Last name',
                newheading: 'New Contact',
                email: 'Email address',
                phonenumber: 'Phone number',
                role: 'Role in company',
                emptystate: 'No contacts found',
                originalcontact:
                  'Unfortunately you cannot edit or delete this contact as it is one of the original contacts for this client',
                contactmodal: {
                  addcontact: {
                    title: 'Add Contact',
                    description: 'Please enter details of new contact',
                    buttonText: 'Add Contact',
                    toastsuccess: 'Contact added succesfully',
                    toastfailed: 'Failed to add contact',
                  },
                  editcontact: {
                    title: 'Edit Contact',
                    description: 'Please edit details of existing contact',
                    buttonText: 'Update Contact',
                    toastsuccess: 'Contact updated succesfully',
                    toastfailed: 'Failed to update contact',
                  },
                  deletecontact: {
                    title: 'Confirm delete',
                    description: 'Please confirm you want to delete the following contact',
                    toastsuccess: 'Contact deleted succesfully',
                    toastfailed: 'Failed to delete contact',
                  },
                },
              },
              provider: {
                software: 'Accountancy software',
                firstconnection: 'First connection',
                lastupdated: 'Last updated',
                bankname: 'Bank name',
              },
              checkbox: {
                updated: 'Succesfully Updated',
                testaccountlabel: 'Is this a test account?',
              },
              history: {
                historymodal: {
                  title: 'Assignment History',
                  table: {
                    assigneeBroker: 'Funding Manager',
                    accountOwner: 'Account Owner',
                    dateAssigned: 'Date Assigned',
                    assignedBy: 'Assigned by',
                    empty: 'No history data found',
                    unset: 'Initial',
                    none: 'Unassigned',
                  },
                },
                tooltips: {
                  broker: 'View Funding Manager assignment history',
                  advisor: 'View Account Owner assignment history',
                },
              },
              warningbanner: {
                header: 'Warning - see more',
                blacklisted:
                  'This client has been blacklisted, do not speak to them before having a conversation with your line manager',
                applicationconsent:
                  'This client has not yet given funding application consent, please confirm this before proceeding',
                raisedcomplaint:
                  'This client has previously raised a complaint, please proceed with caution',
                duediligence:
                  'This client has been flagged for enhanced due diligence, please proceed with caution',
                trustpilot:
                  'This client does not want to provide a review, please do not ask for one',
              },
            },
            creditsafe: {
              title: 'Client credit & reports',
            },
            fundingtypes: {
              title: 'Interested In:',
            },
            fundingrequirements: {
              success: 'Funding requirements saved',
              failure: 'Funding requirements failed to save',
              error: 'Failed to load funding requirements.',
            },
          },
          fundingmatches: {
            title: 'Funding Matches',
            content: {
              subtitle: 'From our funder network',
              loans: 'Loan',
              equity: 'Equity',
              grants: 'Grant',
              sortoffers: 'Sort Offers',
              loading: 'Loading...',
              findingmatches: "We're currently finding matches for your client",
              selectAClient: 'Please, select a client to continue',
              noClient: 'Please, add a new client to continue',
            },
            fundingrequirements: {
              title: 'Funding requirements',
              fundingrequired: 'Amount of funding required',
              currentlytrading: 'Currently trading',
              tradingsince: 'Trading since',
              firstcommercialsale: 'When did the client start trading',
              turnoverlastyear: 'Turnover last year',
              cardpaymentaveragemonthlyrevenue:
                'Card/Online payment revenue in the last financial year',
              profitablelastyear: 'Profitable last fiscal year',
              invoiceaveragemonthlyrevenue: 'Invoice revenue in the last financial year',
              fundingrequiredtimescale: 'Funding required timescale',
              ishomeowner: 'Is the client a homeowner',
              fundingpurposes: 'Funding purpose',
              currentlybank: 'Current bank',
              fundingpurposesummary: 'Description of funding needed',
              sectors: 'Company Sectors',
              businesssummary: "Client's one line business summary",
              region: 'Region the client is based in',
              businessmodels: "Client's business type",
              approvedscheme: 'Is the client SEIS/EIS approved',
              website: "Client's website URL",
              fundingraisedpreviouslyamount: 'How much funding has the client raised in the past',
              applicationadvicerequested:
                'Client would like to speak to an advisor about how to apply',
              pitchdeck: 'Does the client have a pitchdeck',
              registeredaddress: 'Registrered address',
              tradingaddress: 'Trading address',
              acceptscardpayment: 'Accepts card payments',
              invoicessent: 'Invoices customers',
              loadingrequireddocuments: "We're currently gathering the required documents...",
              loadingapplication: "We're currently building the application form...",
              applicationformerror: 'Could not load application form.',
              errortoast:
                'This client has not yet filled in their Funding Requirements, you can do this on their behalf in the Funding Requirements section',
            },
            fields: {
              loans: {
                fundingpurposesummary: 'Description of funding needed',
                fundingpurposes: 'Funding purpose',
                fundingrequired: 'Amount of funding required',
                businesssummary: "Client's one line business summary",
                turnoverlastyear: 'Turnover last year',
                fundingrequiredtimescale: 'How quickly the client needs the funding',
                ishomeowner: 'Is the client a homeowner',
                sectors: 'Company Sectors',
                currentlytrading: 'Currently trading',
                tradingsince: {
                  title: 'Trading since',
                  month: 'Month',
                  year: 'Year',
                },
                profitablelastyear: 'Profitable last fiscal year',
                acceptscardpayment: 'Accepts card payments',
                invoicessent: 'Invoices customers',
                currentlybank: 'Current bank',
                recurringrevenue: 'Monthly Recurring Revenue',
              },
              propertyFinance: {
                propertyFinanceType: 'Funding purpose',
                borrowAmount: 'Amount of funding required',
                address: 'Property address',
                propertyLocation: 'Property location',
                whenFinanceNeeded: 'Finance term',
                financeDuration: 'How quickly the client needs the funding',
                purchasingOrRefinancing: 'Purchasing or Refinancing',
                purchasePrice: 'Purchase price of property (if purchasing)',
                refinanceReason: 'Why are you looking to refinance (if refinancing)',
                propertyValuedByProfessional:
                  'Has the property been independently valued by a professional',
                propertyValuation: 'Property value',
                propertyUsage: 'Property type',
                propertyOccupation: 'Who occupies, or will occupy the property',
                propertyType: 'Type of property (if occupied by 3rd party tenant)',
                rentalIncome: 'Rental income (if occupied by 3rd party tenant)',
                leaseYearsLeft: 'Years left on lease (if occupied by 3rd party tenant)',
                developing: 'Developing',
                refinanceAnotherBuytolet:
                  'Looking to refinance another buy to let property at same time (if residential property)',
                developingExperience: 'Experience of property development',
                schemeType: 'Type of scheme',
                planningLevel: 'Level of planning consent',
                estimatedCost: 'Estimated total project cost',
                developmentWorth: 'Estimated gross development value',
                developingCompletionPlan: 'Plan on completion',
                companyFinances: 'Company finances',
                turnoverLastYear: 'Turnover last year',
                businesIndustry: 'Company Sectors',
                tradingAs: 'Trading as',
                mainBank: 'Current bank',
                tradingStartDate: 'Trading since (Year)',
                hasBeenProfitable: 'Profitable last fiscal year',
                acceptCardPayment: 'Accepts card payments',
                raiseInvoice: 'Raises invoices',
              },
              equity: {
                regions: 'Company region',
                approvedschemes: 'SEIS/EIS approved',
                fundingraisedpreviously: 'Previously raised funding',
                monthlyrecurringrevenue: 'Monthly recurring revenue',
                businesssummary: 'Business summary',
                businessmodels: 'Business model',
                website: 'Company website',
                turnoverlastyear: 'Turnover last year',
                applicationadvicerequested: 'Advisor requested',
              },
              grants: {
                istrading: 'Currently trading',
                registeredaddress: {
                  title: 'Register address',
                  line: 'line',
                  city: 'city',
                  country: 'country',
                  postcode: 'postcode',
                  state: 'state',
                },
                tradingaddressdifferent: 'Trading address is different',
                tradingaddress: {
                  title: 'Trading address',
                  line: 'line',
                  city: 'city',
                  country: 'country',
                  postcode: 'postcode',
                  state: 'state',
                },
              },
            },
            liveoffers: {
              title: 'Live Offer Matches',
              type: 'Type',
              subtype: 'Subtype',
              name: 'Name',
              provider: 'Provider',
              facilitysize: 'Facility size',
              eligibility: 'Eligibility',
            },
            fundingmatches: {
              title: 'Funding Matches',
              search: 'Search matches',
              emptystate: 'No funding matches found',
            },
            nonmatchedproducts: {
              shownonmatches: 'Start Any Product',
              modal: {
                title: 'Search for Any Product',
                description: 'Please search below for any live product in the marketplace',
                search: {
                  placeholder: 'Search',
                },
                toast: {
                  addfunderesuccess: 'Funder succesfully added to Deal.',
                  addfunderfailed: 'Failed to add Funder to Deal',
                },
              },
            },
            fundingInsights: {
              decisionTime: {
                title: 'Decision time',
                alt: 'Decision time icon',
              },
              fundingRequired: {
                title: 'Funding required',
                alt: 'Funding required icon',
              },
              toast: 'Changes have been updated',
            },
            nonswoopequitymessage: {
              instructionheader1: 'Is your client interested in raising capital via equity?',
              instructionheader2: 'What happens then?',
              instructionsubtitle: "If you haven't already, please do the following:",
              instructionlistitem1: `Fill out the form in the "Find an investor" tab, under the "Funding requirements" area (you'll find this in the Overview tab).`,
              instructionlistitem2: `Upload your client's pitch deck in the "Documents" area.`,
              instructionlistitem3:
                "Swoop's equity team will review the pitch deck and the information provided.",
              instructionlistitem4:
                'If Swoop think the investment would be a good fit for our investors, our equity team will get in touch with you (or your client) to arrange an introductory call.',
              instructionlistitem5:
                'Otherwise, the team will follow up with any alternative solutions that can be offered.',
            },
          },
          info: {
            title: 'Information',
            username: 'Username',
          },
          documentlibrary: {
            title: 'Documents',
            download: {
              inactive: 'Please select the file(s) you would like to download',
              active: 'Download selected file(s)',
            },
            edit: {
              inactive: 'Please select the file(s) you would like to edit',
              active: 'Edit selected file(s)',
            },
            delete: {
              inactive: 'Please select the file(s) you would like to delete',
              active: 'Delete selected file(s)',
              confirmdelete: 'Confirm delete',
              confirmdeletemessage:
                'Please confirm you want to delete the following {number} file(s)',
              toast: 'File deleted successfully',
            },
            upload: {
              title: 'Upload New Documents',
              updatetruelayer: 'Bank Account Docs',
              toast: 'File uploaded successfully',
              profileuploadfiletooltip:
                'File must be a JPG, PNG or SVG file, and smaller than 20MB',
              documentuploadfiletooltip:
                'Files must be smaller than 50MB and a PDF, JPG ,PNG ,DOC ,DOCX ,XLS , XLSX, PPT, PPTX file',
              truelayertooltip:
                'This feature is unavailable as your client has not integrated their bank account',
              newdocumentupload: {
                categoryheader: 'Category (Optional)',
                titleheader: 'Title (Optional)',
                filenameheader: 'Filename',
              },
            },
            manage: 'Manage existing documents',
            preview: 'Click file name to preview',
            previewerror: {
              title: 'Unable to preview this document type',
              subtitle: 'Please download the document to view.',
            },
            headers: {
              actions: 'Actions',
              category: 'Category',
              title: 'Title',
              filename: 'File Name',
              uploadeddate: 'Uploaded Date',
            },
            subheading:
              'To upload, please make sure it’s smaller than 50MB and a PDF,JPG,PNG,DOC,DOCX,XLS,XLSX,PPT,PPTX file',
            placeholder: 'Select Document Type',
            search: 'Search documents',
            empty: 'No documents found',
            duplicatedocumentmodal: {
              title: 'Duplicate Document',
              warning1:
                "You can't upload the following document(s) as you already have the following",
              warning2: 'file(s) with the same name(s).',
              existingtitle: 'Existing document(s)',
              buttontext: 'ok',
            },
          },
          applicationform: {
            back: 'Back to Applications list',
            title: 'Applications',
            linked: {
              newapplication: 'New application',
              heading: 'Applications',
              subheading: 'Please choose which application you would like to view',
              empty: 'No Application form sections found',
              activateapplicationsections: 'Activate Application Sections',
              notyetcalibrated: 'This application form has not yet been calibrated yet',
            },
            new: {
              heading: 'Activate a New Application',
              subheading: 'Please choose which application you would like to view',
              onlymatches: 'Show only Matches',
            },
            confirm: {
              heading: 'Confirm New Application',
              subheading:
                'Please confirm you would like to create an application for the following product',
              accept: 'Confirm',
              cancel: 'Cancel',
              success: 'Application form created',
            },
            edit: {
              title: 'Application Form',
              savedraft: 'Save Progress',
              sendtouser: 'Send to user',
              export: 'Save & Export PDF',
              openapplication: 'Open Applications',
            },
          },
          people: {
            title: 'People',
          },
          finance: {
            title: 'Financial Info',
            errormessage:
              "Sorry, either your client hasn't linked their accounting software yet (you can check in the Integrations tab), or we couldn't access the data. Try refreshing the page, or come back a little later.",
            overview: {
              emptystate: 'No data found',
              income: {
                label: 'Income',
                description:
                  'Also known as revenue or turnover. This gives an indication to the funder of the scale of the oporation and is used in other financial calculations.',
              },
              grossprofitmargin: {
                label: 'Gross Profit Margin',
                description:
                  'The ratio of gross profit total income. This shows how much profit the company makes before operating and other costs. Often referred to as sales margin.',
              },
              profit: {
                label: 'Profit',
                description:
                  'Profit is measured here as Earnings before Interest, Tax, Depreciation and Amortization (EBITDA). This is a measure of profit often as an indication of the businesses ability to generate cash from operations.',
              },
              interestcoverageratio: {
                label: 'Interest Coverage Ratio',
                message: {
                  lessthanone: 'This could negatively impact ability to borrow further funds',
                  oneandthree: 'This could impact on cost of borrowing, expect higher rates',
                  threeandsix: 'There may be capacity to service additional debt',
                  abovesix: 'There should be capacity to service additional debt',
                  nointerest: 'No Interest payment data found',
                  noearnings: 'No Earnings data found',
                },
                description: `A measure of the number of times the company's Operating Profit covers its interest costs over a given period of time. Used by funders to indicate how comfortably a business can honour its debt obligations. The higher the number, the better.`,
              },
              currentratio: {
                label: 'Current Ratio',
                message: {
                  lessthanzero: 'This could negatively impact ability to borrow further funds',
                  zerotoone: 'This could impact on cost of borrowing, expect higher rates',
                  onetotwo: 'There may be capacity to service additional debt',
                  abovetwo: 'There should be capacity to service additional debt',
                },
                description: `The current ratio is a liquidity ratio that measures whether a company has enough resources to meet its short-term obligations or those due withing one year.`,
              },
              returnonequity: {
                label: 'Return on Equity',
                description: `Return on Equity is a measure of the profitability of a business in relation to its equity. It may also be calculated as the return on the comapny's assets minus its liabilities.`,
                message: {
                  negative: 'This could negatively impact ability to borrow further funds',
                  neutral: 'This could impact on cost of borrowing, expect higher rates',
                  positive: 'There may be capacity to borrow further funds',
                },
              },
            },
            financial: {
              title: 'Financial',
            },
            profitloss: {
              title: 'Profit and loss statement',
              betawarning:
                'BETA: Data standardisation is still in development, meaning we cannot guarantee 100% data accuracy.',
            },
            balancesheet: {
              title: 'Balance sheet',
            },
            cashflowforecasting: {
              title: 'Cash flow & forecasting',
              subtitle: 'Welcome to your personalised cash flow forecast',
              shorttermcashflow: {
                title: 'Short term cash flow',
                cashavailable: 'Cash available',
                invoices: 'Invoices',
                bills: 'Bills',
              },
              actualprojectedcashflow: {
                title: 'Actual & projected cash flow',
                cashin: 'Cash in',
                cashout: 'Cash out',
                historyfilter: 'History:',
                forecastfilter: 'Forecast:',
              },
              cashflowactualprojection: {
                title: 'Cash flow actual & projection',
              },
            },
            insights: {
              title: 'Insights',
            },
            onboarding: {
              title: 'Onboarding questions',
              hascardterminals: 'Has credit/debit card terminals?',
              terminalrevenue: 'Average monthly revenue from terminals',
              haspurchaseorders: 'Has purchase orders from customers?',
              averagepurchaseorders: 'Average monthly purchase orders',
              approvedrefund:
                'Has the business been approved for an R&D Tax credit refund, but yet to receive payment?',
            },
          },
          user: {
            title: 'User information',
          },
          matches: {
            title: 'Matches',
            nomatches: 'No matches',
            showcalculations: 'Show calculations',
          },
          interest: {
            title: 'Applications',
          },
          integration: {
            title: 'Integration',
            viewaccount: 'View account',
            viewtransactions: 'View transactions',
          },
          dataroom: {
            title: 'Dataroom',
          },
        },
        funders: {
          title: 'Funders',
          search: {
            placeholder: 'Search funders',
          },
          createfunder: 'Create funder',
        },
        funderdetails: {
          info: {
            title: 'Information',
            upload: {
              title: 'Upload Funder Logo',
              uploadlogo: 'upload logo',
            },
            general: {
              title: 'General',
              funderlogo: {
                label: 'Funder Logo',
              },
              fundername: {
                label: 'Funder Name',
              },
              website: {
                label: 'Website',
                placeholder: 'http://',
              },
              description: {
                label: 'Description',
              },
            },
          },
          contacts: {
            title: 'Contacts',
            primarycontact: {
              title: 'Primary contact',
            },
            additionalcontact: {
              title: 'Additional contact',
              add: 'add another contact',
            },
            name: {
              label: 'Name',
            },
            email: {
              label: 'Email',
            },
            phone: {
              label: 'Phone',
            },
            officephone: {
              label: 'Office phone',
            },
            role: {
              label: 'Role',
            },
          },
        },
        userdetails: {
          activated: 'Activated',
          deactivated: 'Deactivated',
          activate: 'Activate',
          deactivate: 'Deactivate',
          info: {
            title: 'Information',
            firstname: {
              label: 'First name',
            },
            lastname: {
              label: 'Last name',
            },
            title_field: {
              label: 'Title',
              placeholder: '',
            },
            dateofbirth: {
              label: 'Date of birth',
            },
            gender: {
              label: 'Gender',
              placeholder: '',
            },
            nationality: {
              label: 'Nationality',
              placeholder: '',
            },
            dualnationalholder: {
              label: 'Dual national holder',
            },
            country: {
              label: 'Country of residence',
              placeholder: '',
            },
            role: {
              label: 'Role',
            },
            contactnumber: {
              label: 'Contact number',
            },
            email: {
              label: 'Email',
            },
          },
        },
        applications: {
          title: 'Applications',
          filter: {
            lastmodifiedfilterheader: 'Last Progressed',
            lastmodifiedtooltip:
              'Simply select the dates you are interested in, and we will show you the relevant details.',
            lastmodifiedplaceholder: 'Select a date',
            clear: 'Clear Filter',
            producttype: 'Product type',
            productsubcategory: 'Product sub category',
            stage: 'Stage',
            dealowner: 'Deal owner',
            datecreated: 'Date created',
            hidecompletedtooltip:
              'This toggle hides completed applications where the status is Closed Won or Closed Lost.',
            hidepipelinetooltip: 'Toggle Pipeline View',
          },
          search: {
            placeholder: 'Search applications',
          },
        },
        applicationdetails: {
          info: {
            title: 'Information',
          },
          companyname: 'Company name',
          productname: 'Product name',
          fundername: 'Funder name',
          producttype: 'Product type',
          productsubcategory: 'Product sub category',
          datecreated: 'Date created',
          dealowner: {
            label: 'Deal owner',
          },
          stage: {
            label: 'Stage',
            placeholder: 'Select a stage',
          },
          amount: {
            label: 'Amount',
          },
          swoopfee: {
            label: 'Swoop fee',
          },
          closeddate: {
            label: 'Closed date',
          },
        },
        integration: {
          title: 'Integrations',
          areyousure: 'Are you sure you want to unlink your account?',
          dontunlink: "don't unlink",
          unlink: 'I want to unlink my account',
        },
        yourprofile: {
          title: 'Your Profile',
          password: {
            title: 'Password',
            desc: 'If you want to change your password, please click the button below and we’ll send you an email with instructions',
            changepassword: 'Change password',
            button: 'change password',
            wehavesentyou:
              'We have sent you an email containing instructions to change your password',
          },
        },
      },
      missingKeyHandler(lng: any, ns: any, key: any, value: any) {
        return key;
      },
    },
    'en-GB': {
      currency: {
        symbol: '£',
        icon: 'currency_pound',
      },
      main: {
        //Will throw duplication warning due to similarity to en-AU & en-CA
        bottom: {
          privacyPolicy: {
            label: 'Privacy Policy',
            link: 'https://swoopfunding.com/privacy-policy',
          },
          tc: {
            label: 'Terms & Conditions',
            link: 'https://swoopfunding.com/terms-conditions',
          },
          website: {
            label: 'Swoop Website',
            link: 'https://www.swoopfunding.com',
          },
        },
      },
    },
    'en-AU': {
      currency: {
        symbol: '$',
        icon: 'attach_money',
      },
      general: {
        vat: 'GST',
        vatreturns: 'ATO summary',
      },
      home: {
        products: {
          fields: {
            certifiedCopyOfIncorporation: {
              label: 'ASIC Registration Certificate',
            },
            fixedAssetSchedule: {
              label: 'Monthly Commitment Schedule',
            },
          },
        },
      },
      onboarding: {
        companyinfo: {
          companynumber: { label: 'ABN number', placeholder: '' },
        },
      },
      sidebar: {
        addclientmodal: {
          fields: {
            companynumber: {
              placeholder: 'ABN number',
              label: "What is your client's abn number?",
            },
          },
        },
      },
      main: {
        //Will throw duplication warning due to similarity to en-GB & en-CA
        bottom: {
          privacyPolicy: {
            label: 'Privacy Policy',
            link: 'https://swoopfunding.com/au/privacy-policy',
          },
          tc: {
            label: 'Terms & Conditions',
            link: 'https://swoopfunding.com/au/terms-conditions',
          },
          website: {
            label: 'Swoop Website',
            link: 'https://www.swoopfunding.com/au',
          },
        },
      },
    },
    'en-CA': {
      currency: {
        symbol: '$',
        icon: 'attach_money',
      },
      general: {},
      onboarding: {
        companyinfo: {
          companynumber: { label: 'Company number', placeholder: '' },
        },
      },
      sidebar: {
        addclientmodal: {
          fields: {
            companynumber: {
              placeholder: 'Company number',
              label: "What is your client's company number?",
            },
          },
        },
      },
      main: {
        //Will throw duplication warning due to similarity to en-GB & en-AU
        bottom: {
          privacyPolicy: {
            label: 'Privacy Policy',
            link: 'https://swoopfunding.com/ca/privacy-policy',
          },
          tc: {
            label: 'Terms & Conditions',
            link: 'https://swoopfunding.com/ca/terms-conditions',
          },
          website: {
            label: 'Swoop Website',
            link: 'https://www.swoopfunding.com/ca',
          },
        },
      },
    },
    'en-SA': {
      currency: {
        symbol: 'R',
        icon: 'attach_money',
      },
    },
  },
  lng: languages[env('REACT_APP_COUNTRY') as TCountry],
  // fallbackLng: 'en',
  // have a common namespace used around the full app
  ns: [
    'app',
    'login',
    'validation',
    'error',
    'signup',
    'common',
    'currency',
    'general',
    'eligibility',
    'onboarding',
    'sidebar',
    'home',
  ],
  defaultNS: 'app',
  // keySeparator: false, // we use content as keys

  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
