import { FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';
import { ProductInsightsField, ProductInsightsFieldType } from '../types';
import styles from 'pages/productInformation/assets/productInformation.module.scss';

export const setFieldType = (cannotEdit: boolean) => {
  return {
    fieldType: cannotEdit ? FieldTypes.EDITOR : FieldTypes.READONLY,
    disabled: !cannotEdit,
  };
};

export const newProductFields = (
  product: ProductInsightsFieldType,
  canEditProducts: boolean
): Array<FieldMetaDataPropType> => {
  return [
    {
      id: ProductInsightsField.likes,
      value: product.likes,
      label: `What ${product.tradingName} likes`,
      placeholder: '- Exammple A \n - Example B \n - Example C',
      name: ProductInsightsField.likes,
      ...setFieldType(canEditProducts),
      classNames: {
        field: styles.editor,
      },
    },
    {
      id: ProductInsightsField.dislikes,
      value: product.dislikes,
      label: `What ${product.tradingName} DOES NOT like`,
      placeholder: '- Exammple A \n - Example B \n - Example C',
      name: ProductInsightsField.dislikes,
      ...setFieldType(canEditProducts),
      classNames: {
        field: styles.editor,
      },
    },
    {
      id: ProductInsightsField.applicationProcess,
      value: product.applicationProcess,
      label: 'Application Process',
      placeholder: '1. Step 1 \n 2. Step 2 \n 3. Step 3',
      name: ProductInsightsField.applicationProcess,
      ...setFieldType(canEditProducts),
      classNames: {
        field: styles.editor,
      },
    },
  ];
};
