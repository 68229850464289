import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import styles from 'pages/deals/assets/incompleteApplicationWarningModal.module.scss';

type IncompleteApplicationWarningModalProps = {
  isModalOpen: boolean;
  onConfirm: () => void;
  onModalClose: () => void;
  numOfSubmittedDocs: number;
  numOfRequiredDocs: number;
  applicationPercentage: number;
};

const IncompleteApplicationWarningModal = ({
  isModalOpen,
  onConfirm,
  onModalClose,
  numOfSubmittedDocs,
  numOfRequiredDocs,
  applicationPercentage,
}: IncompleteApplicationWarningModalProps) => {
  const { t } = useTranslation();

  const missingDocuments = numOfSubmittedDocs < numOfRequiredDocs;
  const isApplicationIncomplete = applicationPercentage < 100;

  const summaryContent = (
    <ul>
      {missingDocuments && (
        <li>
          {t('home:incompleteapplicationsmodal:missingdocs', {
            submittedDocs: numOfSubmittedDocs,
            requiredDocs: numOfRequiredDocs,
          })}
        </li>
      )}
      {isApplicationIncomplete && (
        <li>
          {t('home:incompleteapplicationsmodal:incompleteapp', {
            percentage: applicationPercentage,
          })}
        </li>
      )}
    </ul>
  );

  return (
    <Modal
      show={isModalOpen}
      handleClose={onModalClose}
      testId="incomplete-application-warning-modal"
    >
      <div className={cn(styles['incomplete-application-warning-modal'])}>
        <h3>{t('home:incompleteapplicationsmodal:title')}</h3>

        <div className={cn(styles['summary'])}>
          <p>{t('home:incompleteapplicationsmodal:currentlysubmitted')}</p>
          {summaryContent}
        </div>

        <p>{t('home:incompleteapplicationsmodal:beforesubmit')}</p>
        <div className={cn(styles['actions'])}>
          <Button
            id="ia-warning-cancel-btn"
            ariaLabel="cancel-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={onModalClose}
          >
            {t('home:incompleteapplicationsmodal:cancel')}
          </Button>

          <Button
            id="ia-warning-confirm-btn"
            ariaLabel="cancel-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            tabIndex={0}
            clickHandler={onConfirm}
          >
            {t('home:incompleteapplicationsmodal:submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default IncompleteApplicationWarningModal;
