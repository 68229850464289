import cn from 'classnames';
import Button from 'components/button';
import { RoutePath } from '_shared/routes';
import { Link } from 'react-router-dom';
import styles from 'pages/fundingMatches/assets/fundingMatchCard.module.scss';
import LogoNotFound from 'assets/images/logo-not-found.svg';
import { OfferTypes } from 'pages/deals/types';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { isBrokerApplication } from '_shared/utils/application';
import { useEffect, useState } from 'react';
import CreateDealModal from 'pages/deals/components/createDealModal';
import { useAppState } from 'store';
import { postDealApplication } from '_shared/api/businesses';
import { useTranslation } from 'react-i18next';
import useToastMessage from '_shared/hooks/useToastMessage';

const FundingMatchCard = ({
  field,
  companyId,
  offerType,
  isComponentView,
  dealId,
  updateFunders,
  requestedAmount,
}: {
  field: any;
  companyId: string;
  offerType: OfferTypes;
  isComponentView?: boolean;
  dealId?: string;
  updateFunders?: Function;
  requestedAmount?: number;
}) => {
  const { state: globalState, dispatch: globalDispatch } = useAppState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const { t } = useTranslation();
  const [isCreateDealOpen, setIsCreateDealOpen] = useState<boolean>(false);
  const includesFunderId = globalState?.deals?.uniqueDealFundersIds?.includes(field.opportunityId);
  const dealButtonLabel = isComponentView ? 'Add to deal' : 'Start New Deal';

  const createApplication = async (
    productId: string,
    productName?: string,
    applicationStatus?: string,
    productCategory?: string
  ) => {
    if (!companyId || !productId) return;
    try {
      await postDealApplication(companyId, productId, {
        productName: productName || '',
        applicationStatus: applicationStatus || 'Created',
        productCategory: productCategory || 'BusinessLoan',
        opportunityId: dealId,
      });

      successToastMessage(
        t('home:companydetails:fundingmatches:nonmatchedproducts:modal:toast:addfunderesuccess')
      );
    } catch (e) {
      console.error(e);
      errorToastMessage(
        t('home:companydetails:fundingmatches:nonmatchedproducts:modal:toast:addfunderfailed')
      );
    }
  };

  const startApplication = () => {
    if (isComponentView) {
      createApplication(field.opportunityId);
      updateFunders?.();
      setIsLoading(true);
    } else {
      setIsCreateDealOpen(true);
    }
    sendAmplitudeData(AmplitudeTrackingEnum.startapplication, { productType: offerType });
  };

  useEffect(() => {
    if (includesFunderId) {
      setIsLoading(false);
    }
  }, [includesFunderId]);

  return (
    <div
      key={field.opportunityId}
      className={cn(styles['matches-card'])}
      data-testid="matches-tile"
    >
      <span
        className={cn(styles['trading-name-tooltip'])}
        data-label={field.tradingName ? field.tradingName : field.providerName}
      >
        <div className={cn(styles['match-item'])}>
          <img
            src={field.logoUri || LogoNotFound}
            alt={field?.providerName || 'company logo'}
            className={cn(styles.img)}
          />
        </div>
      </span>
      <div className={cn(styles['match-item'])}>
        <div className={cn(styles['match-provider-details'])}>
          <span>{field.productName}</span>
          <span>{field.providerName}</span>
        </div>
      </div>
      <div className={cn(styles['match-item'])}>
        <div className={cn(styles['match-funding'])}>
          <span>Offers between</span>
          <span>{field.funding}</span>
        </div>
      </div>

      {(offerType !== OfferTypes.LOANS && (
        <div className={cn(styles['match-item'])}>
          <div className={cn(styles['match-details'])}>
            <span>{field.speedName || '-'}</span>
            <span>Decision Time</span>
          </div>
        </div>
      )) ||
        null}

      {(offerType === OfferTypes.LOANS && (
        <div className={cn(styles['matches-details'])}>
          <span className={cn(styles['icon-wrapper'])}>
            <i className={cn('material-icons', styles['icon'])}>contacts</i>Client
          </span>
          <div className={cn(styles['detail-wrapper'])}>
            <div className={cn(styles['item'])}>
              <div className={cn(styles['bold'])}>{field.speedName || '-'}</div>
              <div className={cn(styles['title'])}>Decision Time</div>
            </div>
            <div className={cn(styles['item'])}>
              <div className={cn(styles['bold'])}>
                {(field.aprMin && `${field.aprMin} %`) || '-'}
              </div>
              <div className={cn(styles['title'])}>Rates from</div>
            </div>
          </div>
        </div>
      )) ||
        null}
      <div className={cn(styles['match-item'], styles['action-links'])}>
        {isBrokerApplication && (
          <Button
            id="start-application"
            ariaLabel="start-application"
            clickHandler={() => startApplication()}
            className={cn(
              includesFunderId
                ? styles['existing-application-button']
                : styles['start-application-button']
            )}
            disabled={includesFunderId}
            loading={isLoading}
          >
            {includesFunderId ? 'In Deal' : dealButtonLabel}
          </Button>
        )}
        <Link
          to={RoutePath.productinformation.replace(':id', field.opportunityId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          See Info
        </Link>
      </div>
      <CreateDealModal
        companyId={companyId}
        isCreateDealOpen={isCreateDealOpen}
        setIsCreateDealOpen={setIsCreateDealOpen}
        productId={field.opportunityId}
        initialProductType={offerType}
        initialProductSubtype={field.subcategory}
        initialAmount={requestedAmount}
      />
    </div>
  );
};

export default FundingMatchCard;
