import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './assets/register.module.scss';
import validation from '_shared/fieldValidation';
import BrokerPortalLogo from 'assets/images/broker-portal-logo.svg';
import AdvisorPortalLogo from 'assets/images/advisor-portal-logo-white.svg';
import RegisterImage from 'assets/images/register-image.png';
import useForm from '_shared/hooks/useForm';
import Field from 'components/field';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { registerFields } from './fields/register.fields';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { postRegister } from '_shared/api/users';
import { RegisterUser } from './types';
import LoginHook from 'pages/login/hooks/login.hook';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search.replace('+', '%2B'));
  const token = urlParams.get('token') || '';
  const email = urlParams.get('email') || '';

  const { t } = useTranslation();
  const { submitLogin } = LoginHook();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<FieldMetaDataPropType[]>([]);

  const navigateToLogin = () => {
    navigate('/login');
  };

  const submitRegister = async (fields: RegisterUser) => {
    try {
      setLoading(true);
      const { status } = await postRegister({ ...fields, activationToken: token });
      if (status === 200) {
        submitLogin({ ...fields, email });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { handleChange, handleSubmit, errors, metadata } = useForm(
    {},
    fields,
    submitRegister,
    validation
  );

  useEffect(() => {
    if (!email || !token) navigateToLogin();
    setFields(registerFields(email));
  }, [email && token]);

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles['image-container'])}>
        <div className={cn(styles['image-content-container'])}>
          {(isBrokerApplication && (
            <img
              src={BrokerPortalLogo}
              alt="register-broker-portal"
              className={cn(styles['broker-portal-logo'])}
            />
          )) ||
            (isAdvisorApplication && (
              <img
                src={AdvisorPortalLogo}
                alt="register-advisor-portal"
                className={cn(styles['advisor-portal-logo'])}
              />
            ))}
          <img src={RegisterImage} alt="register" className={cn(styles['register-image'])} />
        </div>
      </div>
      <div className={cn(styles['content-container'])}>
        <div className={cn(styles['content-signin-container'])}>
          <span>{t('register:alreadyhaveaccount')}</span>
          <Button
            id="register-signin"
            ariaLabel="register-signin"
            type="submit"
            clickHandler={navigateToLogin}
            tabIndex={6}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            {t('common:signin')}
          </Button>
        </div>
        <div className={cn(styles['field-container'])}>
          <h2>{t('register:submit')}</h2>
          {metadata.map((metadataField) => {
            return (
              <Field
                key={metadataField.id}
                metadata={metadataField}
                errors={errors}
                handleChange={handleChange}
              />
            );
          })}
          <Button
            id="register-submit"
            ariaLabel="register-submit"
            type="submit"
            tabIndex={5}
            clickHandler={handleSubmit}
            loading={loading}
          >
            {t('register:submit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Register;
