// eslint-disable-next-line import/named
import { ACTIVITY_TYPE } from 'components/activities/types';
import { BrokerAPIURL } from '_shared/url';
import { resetXGroupHeaders } from '_shared/utils/grouping';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export const getActivities = (params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getActivities, { params });

export const postActivity = (
  companyId: string,
  data: { type: ACTIVITY_TYPE; content: string; dateCreated: string; task?: { [key: string]: any } }
) => {
  return BrokerAPI.post(BrokerAPIURL.postActivity(companyId), data, resetXGroupHeaders());
};

export const putActivity = (
  companyId: string,
  activityId: string,
  data: { type: string; content: string; task?: { [key: string]: any } }
) => BrokerAPI.put(BrokerAPIURL.putActivity(companyId, activityId), data);

export const deleteActivity = (companyId: string, activityId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteActivity(companyId, activityId));
