import { MarketplaceAPI, PureMarketplaceAPI } from '../index';
import { MarketplaceAPIURL } from '_shared/url/index';

export enum QueryKeys {
  GetSubTypeMetaData = 'getSubTypeMetaData',
}

export const getDynamicFormsMetadata = (entities: string[], country?: string) =>
  MarketplaceAPI.post(MarketplaceAPIURL.getMetaData, {
    entities,
    ...(country && { countryName: country }),
  });
export const getSubTypeMetaData = (entityName: string) =>
  MarketplaceAPI.post(MarketplaceAPIURL.getMetaData, {
    entities: [entityName],
  });
export const getWhiteLabelByGroupId = (groupId: string) =>
  PureMarketplaceAPI.get(`api/v2/whitelabels/${groupId}`);
