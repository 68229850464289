export interface ProductState {
  productId: string;
  productIndexState: string;
  selectedFilters: null;
  pageNumber: number;
  product: { [key: string]: any } | null;
  filter: {
    onlyActive: boolean;
    pageSize: number;
    type: string;
    subcategory: string;
  };
}

// Describing the different ACTION NAMES available
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

interface UpdateProductsAction {
  type: typeof UPDATE_PRODUCTS;
  payload?: ProductState;
}

export type ProductsActionTypes = UpdateProductsAction;
