import styles from '../../assets/creditSafeReport.module.scss';
import { getStatusClass } from './util';
import { KeyInfomationType, KeyInfoType } from './creditSafeReport';

type CreditSafeKeyInfoProps = {
  keyInfomationSection: KeyInfomationType;
  keyInfoCardData: KeyInfoType;
  displayCreditAndContactLimits: string[];
};

const CreditSafeKeyInfoSection = (props: CreditSafeKeyInfoProps) => {
  const { keyInfomationSection, keyInfoCardData, displayCreditAndContactLimits } = props;
  return (
    <>
      <div className={styles['key-info-header']}>
        <div className={styles['company-name']}>
          <span>{keyInfomationSection.companyName ? keyInfomationSection.companyName : '-'}</span>
        </div>
        <div className={styles['company-info']}>
          <p>
            <strong>
              {keyInfomationSection.companyRegistration
                ? keyInfomationSection.companyRegistration
                : ''}
            </strong>
          </p>
          <p>
            <strong>Company Number: </strong>
            {keyInfomationSection.companyNumber ? keyInfomationSection.companyNumber : 'N/A'}
          </p>
        </div>
      </div>
      <br />
      <div className={styles['key-info-card-container']}>
        {keyInfoCardData.map(({ title, value }, index) => (
          <div key={index} className={`${styles['key-info-card']} ${getStatusClass(title, value)}`}>
            <span>{title}</span>
            <div className={styles['scrollable-wrapper']}>
              <span className={styles['scrollable-content']}>
                {displayCreditAndContactLimits[index]}
              </span>
            </div>
          </div>
        ))}
      </div>
      <br />
    </>
  );
};

export default CreditSafeKeyInfoSection;
