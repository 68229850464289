import { Filters } from '../../../pages/deals/types';

export enum FilterType {
  Before = 'Before',
  Between = 'Between',
  After = 'After',
}

export type AdvancedDatePickerType = {
  placeholder: string;
  value?: any;
  className?: string;
  showCalendarIcon?: boolean;
  todayButtonText?: string;
  disabled?: boolean;
  shouldCloseOnSelect?: boolean;
  filters: Filters;
  handeUpdateLastProgressedFilters: (
    filterType: FilterType,
    dateOrFrom: Date | null,
    to?: Date | null
  ) => void;
};
