import { MutableRefObject, useEffect } from 'react';

type TargetRef = MutableRefObject<any>;

function useBlur(ref: TargetRef | TargetRef[], callback: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const selectedRefs = Array.isArray(ref) ? ref : [ref];

      if (selectedRefs.every((r) => !r.current?.contains(event.target))) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useBlur;
