import React from 'react';
import { ToggleButtonProp } from '../ToggleButton/ToggleButton';
import styles from './ToggleButtonGroup.module.scss';
import cn from 'classnames';

type ToggleButtonGroupProps = {
  value: any;
  onChange: (value: any) => void;
  children: React.ReactNode;
  className?: string;
};

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  value,
  onChange,
  className = '',
  children,
}) => {
  const buttons = React.Children.map(children, (child) => {
    if (React.isValidElement<ToggleButtonProp>(child)) {
      return React.cloneElement(
        child,
        {
          ...child.props,
          active: child.props.value === value,
          onClick: () => onChange(child.props.value),
        },
        child.props.children
      );
    }
  });

  return <div className={cn(styles['toggle-button-group'], className)}>{buttons}</div>;
};

export default ToggleButtonGroup;
