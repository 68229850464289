// eslint-disable-next-line import/named
import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export const getApplicationsList = (companyId: string, params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getCompanyApplications(companyId), {
    params,
  });

export const getLinkedApplicationsList = (companyId: string, isClosed: boolean) =>
  BrokerAPI.get(BrokerAPIURL.getLinkedCompanyApplications(companyId, isClosed));

export const postCreateApplication = (
  companyId: string,
  productId: string,
  data: {
    productName: string;
    applicationStatus: string | number;
    productCategory: string;
  }
) => BrokerAPI.post(BrokerAPIURL.postCreateApplication(companyId, productId), data);

export const postSubmitApplication = (companyId: string, productId: string, data: any) =>
  BrokerAPI.post(BrokerAPIURL.postSubmitApplication(companyId, productId), data);

export const getApplication = (companyId: string, applicationId: string) =>
  BrokerAPI.get(BrokerAPIURL.getApplication(companyId, applicationId));

export const putUpdateApplication = (
  companyId: string,
  applicationId: string,
  data: { [key: string]: any }
) => BrokerAPI.put(BrokerAPIURL.putUpdateApplication(companyId, applicationId), data);

export const putApplicationContacts = (
  companyId: string,
  contactId: string,
  data: { [key: string]: any }
) => BrokerAPI.put(BrokerAPIURL.putApplicationContacts(companyId, contactId), data);

export const putApplicationContactAddress = (
  companyId: string,
  contactId: string,
  contactAddressId: string,
  data: { [key: string]: any }
) =>
  BrokerAPI.put(
    BrokerAPIURL.putApplicationContactAddress(companyId, contactId, contactAddressId),
    data
  );

export const postInvitationToFillApplicationForm = (productId: string) =>
  BrokerAPI.post(BrokerAPIURL.postInvitationToFillApplicationForm(productId));

export const deleteApplication = (companyId: string, applicationId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteApplication(companyId, applicationId));

export const getApplicationsStatistics = (params?: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getApplicationsStatistics, { params });
