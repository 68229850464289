import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';

import { FormFieldEssentials } from 'pages/companyEssentials/types';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { convertToCurrency } from '_shared/utils/currency';
import SummarisedJourney from '../summarisedJourney';

type VehicleEssentialsProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

const VehicleEssentials: React.FC<VehicleEssentialsProps> = ({
  fetchedData,
  dateCreated,
  editDetails,
  journeyDisplayName,
  journeyName,
}) => {
  const fieldsConfig = [
    {
      fieldName: 'vehicleBeingPurchase',
      render: (field: FormFieldEssentials) => {
        const conditionField = getFieldByName('vehicleCondition');
        const condition = conditionField?.value
          ? conditionField.value.toString().toLowerCase()
          : '';

        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value
                ? `Looking to make an offer on a ${condition} ${field.value
                    .toString()
                    .toLowerCase()}`
                : ''}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'vehiclePurchasePriceRange',
      render: (field: FormFieldEssentials) => {
        return (
          field.value &&
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value
                ? `Would like to spend ${getLabelFromOptions(field)?.toString().toLowerCase()}`
                : ''}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'assetPurchasePrice',
      render: (field: FormFieldEssentials) => {
        const vehicleTypeField = getFieldByName('vehicleBeingPurchase');
        const vehicleType = vehicleTypeField?.value ? vehicleTypeField.value.toString() : '';

        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value
                ? `${vehicleType} purchase price is ${convertToCurrency(field.value.toString())}`
                : 'Does not pay VAT'}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'buyingOrLeasing',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value !== 'Not sure' ? 'check' : 'close'}
            </span>
            {field.value !== 'Not sure'
              ? `They need ${field.value?.toString().toLowerCase()}`
              : 'Not sure if they need hire purchase or finance lease'}
          </p>
        ),
    },
    {
      fieldName: 'vehicleVATQualified',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value === 'Yes' ? 'check' : 'close'}
            </span>
            {field.value === 'Yes' && 'Vehicle is VAT qualifying'}
            {field.value === 'No' && 'Vehicle is not VAT qualifying'}
            {field.value === 'Not sure' && 'Not sure if vehicle is VAT qualifying'}
          </p>
        ),
    },
    {
      fieldName: 'cashDeposit',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value && field.value !== '0'
              ? `Has ${convertToCurrency(field.value?.toString())} to contribute to the purchase`
              : 'Has no funds to contribute to the purchase'}
          </p>
        ),
    },
    {
      fieldName: 'vehicleWhenFundingNeeded',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value
              ? `Would like the vehicle ${getLabelFromOptions(field)?.toString().toLowerCase()}`
              : ''}
          </p>
        ),
    },
  ];

  const getFieldByName = (name: string) => fetchedData.find((field) => field.name === name);

  const getLabelFromOptions = (field: FormFieldEssentials | undefined) => {
    if (!field) return;
    const option = field.options?.find((opt) => opt.value === field.value?.toString());
    return option ? option.label : `Undefined ${field.name}`;
  };

  return <SummarisedJourney data={fetchedData} fields={fieldsConfig} />;
};

export default VehicleEssentials;
