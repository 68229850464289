import { AmplitudeTrackingEnum } from 'config/amplitude';
import { FormFieldPropType } from '_shared/fieldValidation/types';
import { XApplicationTypes } from '_shared/utils/application';

export type ActionMenuListType = {
  label: string;
  key: string;
};

export enum TLinkTarget {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}

//Ordered Alphabetically
export type CellPropType = {
  actionCb?: any;
  actionMenuListClickCb?: any;
  actionMenuList?: ActionMenuListType[];
  alt?: string;
  anchor?: string;
  buttonText?: boolean;
  buttonWithLoadingIndicator?: boolean;
  children?: React.ReactNode;
  classname?: {
    cell?: string;
    value?: string;
    icon?: string;
    link?: string;
    iconLink?: string;
    tag?: string;
    actionMenu?: string;
    anchor?: string;
  };
  displayOnApp?: XApplicationTypes;
  colspan?: number;
  currencyInput?: boolean;
  default?: any;
  disabled?: boolean;
  datePicker?: boolean;
  fieldMetadata?: FormFieldPropType;
  icon?: string;
  iconLink?: string;
  id?: string;
  isLoading?: boolean;
  image?: string;
  link?: string;
  linkState?: { [key: string]: any };
  linkTarget?: TLinkTarget;
  name?: string;
  readOnlyStatus?: boolean;
  selectedStatus?: string;
  status?: string;
  statusList?: string[];
  statusSelector?: boolean;
  tag?: string;
  tooltip?: boolean | string;
  tracking?: AmplitudeTrackingEnum;
  value?: any;
};
