import Modal from 'components/modal';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import history from '_shared/history';
import cn from 'classnames';
import styles from './blockNavigationModal.module.scss';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';

const BlockNavigationModal = ({ shouldBlock }: { shouldBlock: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
  const [leaveAddress, setLeaveAddress] = useState<string | undefined>();

  const closeModal = () => {
    setShowModal(false);
  };

  const continueToRoute = () => {
    setConfirmLeave(true);
  };

  useLayoutEffect(() => {
    const unblock = history.block((location) => {
      if (!showModal) {
        setShowModal(true);
        setLeaveAddress(location.location.pathname);
      }
      return false;
    });

    if (!shouldBlock) {
      unblock();
    }
    if (confirmLeave && leaveAddress) {
      unblock();
      navigate(leaveAddress);
    }

    return () => {
      unblock();
    };
  }, [confirmLeave, shouldBlock]);

  return (
    <Modal {...{ show: showModal, handleClose: closeModal }}>
      <div className={cn(styles['block-modal-container'])}>
        <p className={'sw-h2'}>{t('blocknavigation:heading')}</p>
        <div className={cn(styles['action-container'])}>
          <Button
            id="stay-on-page"
            ariaLabel={'stay-on-page'}
            clickHandler={closeModal}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            {t('blocknavigation:stay')}
          </Button>
          <Button id="leave-page" ariaLabel={'leave-page'} clickHandler={continueToRoute}>
            {t('blocknavigation:leave')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BlockNavigationModal;
