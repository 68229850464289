import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';

type ContactAddressProps = {
  address: string;
};

const ContactAddress: React.FC<ContactAddressProps> = ({ address }) => {
  return (
    <div className={styles['contact-address']}>
      <p>Trading address</p>
      <p>{address}</p>
    </div>
  );
};

export default ContactAddress;
