import { districtsLookup } from '_shared/utils/constants';
import { regionType } from './types';

export const parseDistrictsFromApi = (data: string[]) => {
  return data?.reduce((total: string[], current: any) => {
    return [...total, ...(current?.districts || [])];
  }, []);
};

export const parseStateFromApi = (data: Object[]) => {
  return data?.map((value: regionType) => value.statecode);
};

export const parseRegionForApi = (region: string[], districts: string[]) => {
  return region?.map((state: string) => {
    const foundState = districtsLookup.find((district: any) => {
      return district.code === state;
    });
    const foundDistrict =
      districts?.filter((district: string) => {
        return foundState?.values.includes(district);
      }) || [];
    return { statecode: state, districts: foundDistrict };
  });
};

export const parseProfibilityBasisForApi = (data: boolean) => {
  return data ? 'Net Profit' : 'EBITDA';
};

export const parseInvestmentSchemesForApi = (data: string) => {
  if (data) {
    return [parseInt(data)];
  } else {
    return [];
  }
};

export const parseHasPersonalGuaranteeForApi = (data: boolean) => {
  return data ? 1 : 0;
};

export const parseHasPersonalGuaranteeFromApi = (data: any) => {
  if (data === 1) {
    return 'true';
  } else {
    return 'false';
  }
};

export const parseProfibilityBasisFromApi = (data: any) => {
  if (data === 'Net Profit') {
    return 'true';
  } else {
    return 'false';
  }
};

export const parseIndustryExcludedFromApi = (data: any) => {
  if (Array.isArray(data) && data.length > 0) {
    return 'true';
  } else {
    return 'false';
  }
};
