import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/companyOverview.module.scss';
import { selectedCompanyOwnerFields } from '../../fields';
import Card from 'components/card';
import Checkbox from 'components/field/checkbox';
import Table from 'components/table';
import Modal from 'components/modal';
import { TPeopleInfoCardProps } from 'pages/companyOverview/types';
import ErrorComponent from 'components/error';

const PeopleInfoCard = ({
  peopleInfo,
  handleToggleOnlyCurrentDirectors,
  setShowCompanyOwnerModal,
}: TPeopleInfoCardProps) => {
  const { t } = useTranslation();
  const hasDirectors = Boolean(peopleInfo.data.directors?.length);
  const hasStakeHolders = Boolean(peopleInfo.data.stakeholders?.length);

  return (
    <>
      {hasDirectors && (
        <>
          <div className={cn(styles['people-heading'])}>
            <h3 className={cn('sw-h4')}>{t('home:companydetails:overview:people:directors')}</h3>
            <Checkbox
              {...{
                id: 'current-directors',
                name: 'only-current-directors',
                label: t('home:companydetails:overview:people:currentdirectors'),
                selected: peopleInfo.showOnlycurrentDirectors,
                handleChange: handleToggleOnlyCurrentDirectors,
              }}
            />
          </div>
          <Table
            {...peopleInfo.directors}
            loading={peopleInfo.loading}
            emptyMessage={t('home:companydetails:overview:people:emptydirectors')}
          />
        </>
      )}
      {hasStakeHolders && (
        <>
          <div className={cn(styles['people-heading'])}>
            <h3 className={cn('sw-h4')}>{t('home:companydetails:overview:people:stakeholders')}</h3>
          </div>
          <Table
            {...peopleInfo.stakeholders}
            loading={peopleInfo.loading}
            emptyMessage={t('home:companydetails:overview:people:emptystakeholders')}
          />
        </>
      )}
      {!hasDirectors && !hasStakeHolders && (
        <ErrorComponent message={'No people info data found.'} />
      )}
      {peopleInfo.selectedCompanyOwner && (
        <Modal
          show={Boolean(peopleInfo.selectedCompanyOwner)}
          handleClose={() => setShowCompanyOwnerModal()}
        >
          <div className={cn(styles['owner-container'])}>
            <h3>{t('home:companydetails:overview:people:shareholder')}</h3>
            <div className={cn(styles['owner-fields'])}>
              {selectedCompanyOwnerFields(peopleInfo.selectedCompanyOwner).map(
                ({
                  id,
                  label,
                  value,
                }: {
                  id: string;
                  label: string;
                  value: string | number | Date;
                }) => {
                  return (
                    <div key={id} className={cn(styles['owner-field'])}>
                      <span>{t(label)}</span>
                      <span>{value}</span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PeopleInfoCard;
