import ActivityDropdown from './activityDropdown';
import React, { useEffect } from 'react';
import cn from 'classnames';
import styles from './activities.module.scss';
import { useTranslation } from 'react-i18next';
import DeleteActivity from './deleteActivity';
import EditActivity from './editActivity';
import NewActivity from './newActivity';
import Spinner from 'components/spinner';
import ActivitiesHook from './activitiesHook';
import ChooseActivityModal from './chooseActivityModal';
import { useAppState } from 'store';
import { UPDATE_TASK } from 'store/tasks/types';

const Activities = ({ companyId }: { companyId: string }) => {
  const {
    state,
    fetchActivities,
    toggleOpenActivityDropdown,
    handleCreateNewActivity,
    handleDeleteActivity,
    deleteNewActivity,
    handleConfirmDelete,
    closeDeleteModal,
    handleEditActivity,
    handleConfirmEdit,
    closeEditModal,
    handleChangeOrder,
    openChooseActivityModal,
    handleFilterActivities,
  } = ActivitiesHook(companyId);

  const { t } = useTranslation();
  const { state: appState, dispatch: globalDispatch } = useAppState();

  useEffect(() => {
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        refreshTasks: fetchActivities,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const updateTasksInformation = () => {
    globalDispatch({
      type: UPDATE_TASK,
      payload: {
        ...appState.tasks,
        activityData: state.data,
      },
    });
  };

  return (
    <>
      <div className={cn(styles['activities-container'])}>
        <button
          onClick={() => {
            toggleOpenActivityDropdown();
            updateTasksInformation();
          }}
          data-label="Activity"
          data-testid="activities-button"
          className={cn(
            styles['activities-button'],
            state.isActivitiesDropdownOpen && styles['activities-button--active'],
            styles['activities-tooltip']
          )}
        >
          <span className={cn('material-icons', styles['button-icon'])}>sticky_note_2</span>
          <div className={cn(styles['activities-count'])}>
            {(state.loading.activities && <Spinner size="small" />) || state.activitiesCount}
          </div>
        </button>
        <button
          onClick={() => openChooseActivityModal(true)}
          className={cn(styles['create-activity-button'])}
        >
          <span className={cn('material-icons')}>add_circle</span>
          <span>{t('home:companydetails:activities:new:title')}</span>
        </button>

        {(state.isActivitiesDropdownOpen && (
          <ActivityDropdown
            {...{
              activities: state.activities,
              order: state.order,
              loading: state.loading.activities,
              filter: state.params.filter,
              actions: {
                handleChangeOrder,
                handleDeleteActivity,
                handleEditActivity,
                openChooseActivityModal,
                toggleOpenActivityDropdown,
                handleFilterActivities,
              },
            }}
          />
        )) ||
          null}
      </div>
      <ChooseActivityModal
        {...{
          show: state.isChooseActivityModalOpen,
          closeModal: openChooseActivityModal,
          chooseActivity: handleCreateNewActivity,
        }}
      />
      <div className={cn(styles['new-activity-container'])}>
        {(state.activityCategory && (
          <NewActivity
            {...{
              deleteNewActivity,
              companyId,
              refresh: fetchActivities,
              activityCategory: state.activityCategory,
            }}
          />
        )) ||
          null}
      </div>
      {(state.activityToDelete && (
        <DeleteActivity
          {...{
            activity: state.activityToDelete,
            handleConfirmDelete,
            closeDeleteModal,
            loading: state.loading.delete,
          }}
        />
      )) ||
        null}
      {(state.activityToEdit && (
        <EditActivity
          {...{
            activity: state.activityToEdit,
            handleConfirmEdit,
            closeEditModal,
            loading: state.loading.update,
          }}
        />
      )) ||
        null}
    </>
  );
};

export default React.memo(Activities);
