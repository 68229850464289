import { ValidationTypes, FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';

export const fields: Array<FieldMetaDataPropType> = [
  {
    id: 'forgotpassword-email',
    name: 'email',
    validationtype: ValidationTypes.EMAIL,
    value: '',
    required: true,
    label: 'login:forgotpassword:email:label',
    placeholder: 'login:forgotpassword:email:placeholder',
    type: 'text',
    fieldType: FieldTypes.TEXT,
  },
];
