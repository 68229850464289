import { companiesRoutes } from 'pages/companies/companies.routes';
import { dealsRoutes } from 'pages/deals/deals.routes';
import { companyDetailsRoutes } from 'pages/companyDetails/companyDetails.routes';
import { productDetailsRoutes } from 'pages/productDetails/productDetails.routes';
import { productRoutes } from 'pages/products/products.routes';
import { profileRoutes } from 'pages/profile/profile.routes';
import { userDetailsRoutes } from 'pages/userDetails/userDetails.routes';
import { usersRoutes } from 'pages/users/users.routes';
import { Navigate, RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import MainLayout from 'layouts/main';
import { withTransaction } from '@elastic/apm-rum-react';
import { tasksRoutes } from 'pages/tasksManagement/tasks.routes';
import { groupManagementRoutes } from 'pages/groupManagement/groupManagement.routes';
import { userManagementRoutes } from 'pages/userManagement/userManagement.routes';
import { bookmarkRoutes } from 'pages/bookmarks/Bookmark.routes';

const routes: RouteObject[] = [
  {
    path: RoutePath.home,
    element: withTransaction('MainLayout', 'component')(<MainLayout />),
    children: [
      ...companiesRoutes,
      ...dealsRoutes,
      ...companyDetailsRoutes,
      ...productRoutes,
      ...productDetailsRoutes,
      ...usersRoutes,
      ...userDetailsRoutes,
      ...userManagementRoutes,
      ...profileRoutes,
      ...tasksRoutes,
      ...groupManagementRoutes,
      ...bookmarkRoutes,
      // redirect to companies on /
      { path: RoutePath.home, element: <Navigate to={RoutePath.companies} /> },
    ],
  },
];

export default routes;
