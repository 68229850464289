import i18next from 'i18next';
import { convertToLocalDateFormat } from '_shared/utils/date';
import { APIDocumentType } from '../types';
import styles from 'pages/documentLibrary/assets/deleteDocument.module.scss';

const deleteDocumentFields = (documents: Array<APIDocumentType>) => {
  const defaultHeaderStyles = {
    header: styles['th-header'],
  };

  const defaultCellStyles = {};
  return {
    header: [
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:category'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:title'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:filename'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:uploadeddate'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {},
    classname: {
      table: styles.table,
    },
    items: documents.map((document) => {
      return [
        {
          id: document.documentId,
          default: document.type,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-delete-title`,
          default: document.title,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-delete-name`,
          default: document.name,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-delete-uploaded-date`,
          default: convertToLocalDateFormat(document.uploadedDate),
          classname: {
            ...defaultCellStyles,
          },
        },
      ];
    }),
  };
};

export default deleteDocumentFields;
