import { useMemo } from 'react';
import cn from 'classnames';
import styles from '../../assets/productFilters.module.scss';
import Select from 'components/field/select';
import TextInput from 'components/field/text';
import { useTranslation } from 'react-i18next';
import Switch from 'components/switch';
import { useAppState } from 'store';
import { SWOOP_GROUP_ID } from '_shared/utils/application';
import { productFilterTypes } from '_shared/utils/constants';
import useAutoFocus from '_shared/hooks/useAutoFocus';
import { ProductFiltersType } from './types';
import { OfferTypeSingular } from 'pages/deals/types';

const ProductsFilters = (props: ProductFiltersType) => {
  const { state: globalState } = useAppState();
  const { t } = useTranslation();
  const searchInputRef = useAutoFocus();
  const isSwoopGroupUser = globalState.system.groupId === SWOOP_GROUP_ID;

  const typeOptions = useMemo(() => {
    const options = [
      { label: 'Loan', value: OfferTypeSingular.Loan },
      { label: 'Equity', value: OfferTypeSingular.Equity },
      { label: 'Grant', value: OfferTypeSingular.Grant },
    ];

    if (isSwoopGroupUser) {
      return options;
    }

    return options.filter(({ value }) => value !== OfferTypeSingular.Equity);
  }, [isSwoopGroupUser]);

  const subtypeOptions = (props.filter.type && [...productFilterTypes[props.filter.type]]) || [
    ...productFilterTypes[Object.keys(productFilterTypes)[0]],
  ];

  return (
    <div className={cn(styles.filters)}>
      <div className={cn(styles.heading)}>
        <h3>{t('home:products:heading')}</h3>
        <span>
          <span>({props.filter.totalCount})</span>
        </span>
      </div>
      <div className={cn(styles['filter-list'])}>
        <div className={cn(styles['category-filters'])}>
          <div className={cn(styles['type-filter'])}>
            <span>{t('home:products:filter:type')}</span>
            <Select
              {...{
                metadata: {
                  id: 'product-type-filter',
                  placeholder: 'All',
                  options: typeOptions,
                  value: props.filter.type,
                  required: false,
                },
                errors: [],
                handleChange: props.handleTypeFilter,
              }}
            />
          </div>
          <div className={cn(styles['subtype-filter'])}>
            <span>{t('home:products:filter:subtype')}</span>
            <Select
              {...{
                metadata: {
                  id: 'product-subtype-filter',
                  placeholder: 'Filter by',
                  options: subtypeOptions,
                  value: props.filter.subcategory,
                  required: false,
                  disabled: props.filter.type === 'Grant' || !props.filter.type,
                },
                errors: [],
                handleChange: props.handleSubtypeFilter,
              }}
            />
          </div>
          <div className={cn(styles['clear-filters-button'])}>
            <button onClick={props.clearTypeAndSubtypeProductFilters}>Clear</button>
          </div>
        </div>
        <div className={cn(styles.search)}>
          <TextInput
            {...{
              metadata: {
                id: 'product-search',
                placeholder: t('home:products:search:placeholder'),
                value: props.filter.searchProduct,
                trailingIcon: 'search',
                classNames: {
                  field: styles.search,
                },
              },
              errors: [],
              handleChange: props.handleOnSearchProduct,
            }}
            ref={searchInputRef}
          />
        </div>
        {(globalState.system.groupId === SWOOP_GROUP_ID && (
          <div className={cn(styles.toggle)}>
            <Switch
              {...{
                id: 'toggle-only-active',
                onChange: props.toggleEnabled,
                defaultChecked: props.filter.onlyActive,
                label: t('home:products:filter:enabled'),
                name: 'toggle-only-active',
              }}
            />
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default ProductsFilters;
