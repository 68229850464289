import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearState, loadState, useAppState } from 'store';
import { UPDATE_SESSION } from 'store/system/types';
import { login } from '_shared/api/login';
import { getRoles } from '_shared/api/roles';
import { getCurrentUser } from '_shared/api/users';
import { RoutePath } from '_shared/routes';
import { LSKeys } from '_shared/utils/constants';
import { DisableModal } from '../../groupManagement/types';

const LoginHook = () => {
  const { dispatch } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const system = loadState(LSKeys.swoopAnalytics);

  const submitLogin = async (values: any) => {
    const { data } = await login({
      username: values.email,
      password: values.password,
    });
    dispatch({
      type: UPDATE_SESSION,
      payload: {
        accessToken: data.accessToken,
        groupId: data.groupId,
        exposedGroups: data?.exposedGroups || [],
      },
    });
  };

  async function dispatchUserContext() {
    try {
      const { data: currentUser } = await getCurrentUser();
      const { data: roles } = await getRoles();
      dispatch({
        type: UPDATE_SESSION,
        payload: {
          roles: roles || [],
          currentUser,
        },
      });
      if (location.state?.from) {
        navigate(location.state.from);
        return;
      }
      if (currentUser?.disabledModalsList?.includes(DisableModal.WELCOME_MODAL)) {
        navigate(RoutePath.companies);
      } else {
        navigate(RoutePath.groupManagement);
      }
    } catch (e) {
      console.error(e);
      clearState(LSKeys.swoopAnalytics);
    }
  }

  useEffect(() => {
    if (system && system.accessToken && system.groupId) {
      dispatchUserContext();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system && system.accessToken && system.groupId]);

  return {
    submitLogin,
  };
};

export default LoginHook;
