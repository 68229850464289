import NewActivityContent from './NewActivityContent';
import NewActivityConfirmDelete from './newActivityConfirmDelete';
import NewActivityHeader from './newActivityHeader';
import { useReducer } from 'react';
import cn from 'classnames';
import styles from './newActivity.module.scss';
import { useTranslation } from 'react-i18next';
import { postActivity } from '_shared/api/activity';
import { TOAST_MESSAGE } from 'store/toast/types';
import { useAppState } from 'store';
import { newActivityReducer, ActionType } from './reducer';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { ACTIVITY_CATEGORY, NewActivityPropTypes } from '../types';
import { getActivityTypeFromCategory } from '../utils';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import BlockNavigationModal from 'components/BlockNavigationModal';

const NewActivity = ({
  deleteNewActivity,
  companyId,
  refresh,
  activityCategory,
}: NewActivityPropTypes) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(newActivityReducer, {
    activity: '',
    isCollapsed: false,
    isSaving: false,
    wantsToDeleteWithoutSaving: false,
    callStatus: undefined,
  });
  const store = useAppState();

  const toggleIsCollapsed = () => {
    if (!state.isCollapsed) {
      sendAmplitudeData(AmplitudeTrackingEnum.collapsenotepad);
    }
    dispatch({
      type: ActionType.SET_IS_COLLAPSED,
      payload: { isCollapsed: !state.isCollapsed },
    });
  };

  const handleDeleteActivity = () => {
    if (state.activity) {
      dispatch({
        type: ActionType.SET_WANTS_TO_DELETE,
        payload: { wantsToDeleteWithoutSaving: true },
      });
    } else {
      deleteNewActivity();
      sendAmplitudeData(AmplitudeTrackingEnum.closenotepad);
    }
  };

  const cancelDeleteActivity = () => {
    dispatch({
      type: ActionType.SET_WANTS_TO_DELETE,
      payload: { wantsToDeleteWithoutSaving: false },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      type: ActionType.SET_ACTIVITY,
      payload: event.target.value,
    });
  };

  const handleCallStatus = (event: any) => {
    dispatch({
      type: ActionType.SET_CALL_STATUS,
      payload: { callStatus: event.target.value },
    });
  };

  const saveActivity = async () => {
    if (!companyId) return;
    try {
      dispatch({
        type: ActionType.SET_IS_SAVING,
        payload: { isSaving: true },
      });

      const activityType = getActivityTypeFromCategory(activityCategory, {
        callStatus: state.callStatus,
      });

      if (!activityType) return;

      await postActivity(companyId, {
        content: state.activity,
        type: activityType,
        dateCreated: new Date().toISOString(),
      });
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t('home:companydetails:activities:new:success', {
            activityType: spaceBetweenTitleCaseWords(activityType),
          }),
        },
      });
      dispatch({
        type: ActionType.SET_IS_SAVING,
        payload: { isSaving: false },
      });
      refresh(companyId);
      deleteNewActivity();
      sendAmplitudeData(AmplitudeTrackingEnum.savenote);
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SET_IS_SAVING,
        payload: { isSaving: false },
      });
    }
  };

  const confirmDeleteActivity = () => {
    deleteNewActivity();
    sendAmplitudeData(AmplitudeTrackingEnum.closenotepad);
  };

  return (
    <div
      data-testid="new-activity"
      className={cn(styles['new-activity'], state.isCollapsed && styles['collapsed'], {
        [styles['note-activity-wisiwyg']]: activityCategory === ACTIVITY_CATEGORY.NOTE,
      })}
    >
      <NewActivityHeader
        activityCategory={activityCategory}
        toggleIsCollapsed={toggleIsCollapsed}
        handleDeleteActivity={handleDeleteActivity}
      />
      {(state.wantsToDeleteWithoutSaving && (
        <NewActivityConfirmDelete
          cancelDeleteActivity={cancelDeleteActivity}
          confirmDeleteActivity={confirmDeleteActivity}
          isCollapsed={state.isCollapsed}
        />
      )) || (
        <NewActivityContent
          saveActivity={saveActivity}
          activity={state.activity}
          isCollapsed={state.isCollapsed}
          handleChange={handleChange}
          isSaving={state.isSaving}
          activityCategory={activityCategory}
          handleCallStatus={handleCallStatus}
          callStatus={state.callStatus}
        />
      )}
      <BlockNavigationModal shouldBlock={Boolean(state.activity)} />
    </div>
  );
};

export default NewActivity;
