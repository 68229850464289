import { UPDATE_PRODUCTS, ProductState, ProductsActionTypes } from './types';

export const initialProductState: ProductState = {
  productId: '',
  productIndexState: '',
  selectedFilters: null,
  product: null,
  pageNumber: 1,
  filter: {
    onlyActive: true,
    pageSize: 20,
    type: '',
    subcategory: '',
  },
};

export function productsReducer(state: ProductState, action: ProductsActionTypes): ProductState {
  switch (action.type) {
    case UPDATE_PRODUCTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
