import React from 'react';
import cn from 'classnames';
import styles from './menu.module.scss';

type MenuItemProps = React.HTMLProps<HTMLDivElement> & {
  icon?: string;
  iconStyle?: 'outlined' | 'filled';
};

const MenuItem = ({ icon, children, className, iconStyle = 'filled', ...props }: MenuItemProps) => {
  const iconClass = iconStyle === 'outlined' ? 'material-icons-outlined' : 'material-icons';

  return (
    <div className={cn(className, styles['menu-item'])} role="menuitem" {...props}>
      {icon && <i className={cn(iconClass, styles['icon'])}>{icon}</i>}
      {children}
    </div>
  );
};

export default MenuItem;
