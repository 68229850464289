import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import MigrateUser from '.';

export const migrateUserRoutes: RouteObject[] = [
  {
    path: RoutePath.migrateuser,
    element: withTransaction('MigrateUser', 'component')(<MigrateUser />),
  },
];
