import cn from 'classnames';
import styles from './tabs.module.scss';
import { TabsProps } from './types';
import { sendAmplitudeData } from 'config/amplitude';
import { useEffect, useRef, useState } from 'react';

const Tabs: React.FC<TabsProps> = ({ items, activeKey, activeKeys, onChangeTab, classNames }) => {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(true);

  const handleChange = (item: any) => {
    if (item.tracking) {
      sendAmplitudeData(item.tracking);
    }
    if (onChangeTab) {
      onChangeTab(item.keys[0]);
    }
  };

  const hasIntersection = (itemKeys: string[]) => {
    if (!activeKeys) return false;
    return itemKeys.filter((value) => activeKeys.includes(value)).length;
  };

  const handleClickScroll = (direction: string) => {
    requestAnimationFrame(() => {
      if (tabContainerRef?.current) {
        const scrollWidth = 200;
        if (direction === 'left') {
          tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollLeft - scrollWidth;
        } else {
          tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollLeft + scrollWidth;
        }
      }
    });
  };

  useEffect(() => {
    if (tabContainerRef.current) {
      setScrollable(tabContainerRef.current.offsetWidth < tabContainerRef.current.scrollWidth);
    }
  }, [items]);

  return (
    <div data-testid="tabs-container" className={styles['tabs-wrapper']}>
      <button
        data-testid="scroll-left"
        className={cn('material-icons', styles['scroll-btn'], scrollable && styles['show'])}
        onClick={() => handleClickScroll('left')}
      >
        chevron_left
      </button>
      <div
        ref={tabContainerRef}
        data-testid="tabs"
        className={cn(classNames?.tabs, styles['tabs'])}
      >
        {items.map((item) => {
          return (
            <button
              key={item.keys?.join('-')}
              data-testId={item.keys?.join('-')}
              onClick={() => handleChange(item)}
              onKeyDown={() => handleChange(item)}
              className={cn(classNames?.tabItem, styles['tab-pane'], {
                [cn(classNames?.availableTab, styles['availabe'])]:
                  activeKeys && hasIntersection(item.keys),
                [cn(classNames?.activeTab, styles['active'])]: item.keys?.includes(activeKey),
                [styles['inactive']]: activeKeys && !hasIntersection(item.keys),
              })}
            >
              <div className={cn(classNames?.tabTitle, styles['tab-title'])}>{item.title}</div>
            </button>
          );
        })}
      </div>
      <button
        data-testid="scroll-right"
        className={cn('material-icons', styles['scroll-btn'], scrollable && styles['show'])}
        onClick={() => handleClickScroll('right')}
      >
        chevron_right
      </button>
    </div>
  );
};

export default Tabs;
