import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Users from '.';

export const usersRoutes: RouteObject[] = [
  {
    path: RoutePath.userslist,
    element: withTransaction('Users', 'component')(<Users />),
  },
];
