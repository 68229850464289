import { TablePropType } from 'components/table/type';
import { AssignmentHistoryEntry, HistoryModalEnum } from '../type';
import i18next from 'i18next';
import { convertToLocalDateTimeFormat } from '_shared/utils/date';
import styles from 'pages/companyOverview/assets/contactModal.module.scss';

export const HistoryEntriesTable = (
  data: Array<AssignmentHistoryEntry>,
  historyType: HistoryModalEnum | null
): TablePropType => {
  const nameHeader = i18next.t(
    historyType === HistoryModalEnum.Broker
      ? 'home:companydetails:overview:accountinfo:history:historymodal:table:assigneeBroker'
      : 'home:companydetails:overview:accountinfo:history:historymodal:table:accountOwner'
  );
  return {
    header: [
      {
        value: nameHeader,
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        value: i18next.t(
          'home:companydetails:overview:accountinfo:history:historymodal:table:dateAssigned'
        ),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'assignedBy',
        value: i18next.t(
          'home:companydetails:overview:accountinfo:history:historymodal:table:assignedBy'
        ),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t(
      'home:companydetails:overview:accountinfo:history:historymodal:table:empty'
    ),
    items: data.map((item: AssignmentHistoryEntry) => {
      return [
        {
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default:
            item?.fullName ||
            i18next.t('home:companydetails:overview:accountinfo:history:historymodal:table:none'),
        },
        {
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: convertToLocalDateTimeFormat(item?.date) || 'N/A',
        },
        {
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default:
            item?.assignedBy ||
            i18next.t('home:companydetails:overview:accountinfo:history:historymodal:table:unset'),
        },
      ];
    }),
  };
};
