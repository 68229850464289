import React, { useContext, useEffect, useReducer, useState } from 'react';
import cn from 'classnames';
import Card from 'components/card';
import styles from 'pages/productInformation/assets/productInformation.module.scss';
import { Action, productInformationReducer } from './store/reducer';
import {
  equitySubCategoryFields,
  loanSubCategoryFields,
  newProductFields,
  productCategoryFields,
} from './fields/productInformation.fields';
import LoanSvg from 'assets/images/icon/loan.svg';
import EquitySvg from 'assets/images/icon/equity.svg';
import GrantSvg from 'assets/images/icon/grants.svg';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import Field from 'components/field';
import { ProductDetailsContext } from 'pages/productDetails/store/productDetails.reducer';
import { sectionCategoriesType } from './types';
import { putProduct } from '_shared/api/products';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import { TOAST_MESSAGE } from 'store/toast/types';
import { useAppState } from 'store';
import {
  parseDistrictsFromApi,
  parseHasPersonalGuaranteeForApi,
  parseInvestmentSchemesForApi,
  parseProfibilityBasisForApi,
  parseRegionForApi,
} from './utils';
import BlockNavigationModal from 'components/BlockNavigationModal';
import classNames from 'classnames';

const ProductInformation: React.FC = () => {
  const { state: contextState } = useContext(ProductDetailsContext);

  const initialState = {
    isSaving: false,
    isError: false,
    ableToSave: false,
    productCategory: contextState.product.type || '',
    subCategory: contextState.product.subcategory || '',
  };

  const [state, dispatch] = useReducer(productInformationReducer, initialState);
  const [fields, setFields] = useState<any>([]);
  const providerList = contextState?.providerList;
  const productId = contextState.product.productId;
  const productType = contextState.product.type;
  const productSubType = contextState.product.subcategory;
  const { t } = useTranslation();
  const store = useAppState();

  const canEditProducts = Boolean(
    (store.state.system.isSuperAdmin && store.state.system.isSuperAdmin()) ||
      (store.state.system.isMarketPlaceManager && store.state.system.isMarketPlaceManager())
  );

  const addCategoriesOnLoad = () => {
    dispatch({ type: Action.SET_PRODUCT_CATEGORY, payload: productType });
    dispatch({
      type: Action.SET_SUB_CATEGORY,
      payload: productType === 'Grant' ? '' : productSubType,
    });
  };

  const selectProductCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: Action.SET_PRODUCT_CATEGORY,
      payload: e.target.value,
    });
    if (e.target.value === 'Loan') {
      dispatch({ type: Action.SET_SUB_CATEGORY, payload: 'InvoiceFinance' });
    } else if (e.target.value === 'Equity') {
      dispatch({ type: Action.SET_SUB_CATEGORY, payload: 'SEIS' });
    } else {
      dispatch({ type: Action.SET_SUB_CATEGORY, payload: '' });
    }
    dispatch({
      type: Action.SET_ABLE_TO_SAVE,
      payload: true,
    });
  };

  const selectSubCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: Action.SET_SUB_CATEGORY, payload: e.target.value || '' });
    dispatch({
      type: Action.SET_ABLE_TO_SAVE,
      payload: true,
    });
  };

  useEffect(() => {
    if (!contextState?.product || !Object.keys(contextState?.product).length) {
      return;
    }
    setFields(newProductFields(contextState.product, providerList, canEditProducts));
    addCategoriesOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextState.product]);

  const handleSaveChanges = async (changedValues: { [key: string]: any }) => {
    let productValues = Object.fromEntries(
      Object.entries(contextState.product).filter(([_, value]) => value != null)
    );

    const profitabilityBasis = parseProfibilityBasisForApi(changedValues.profitabilityBasis);
    const investmentSchemes = parseInvestmentSchemesForApi(changedValues?.investmentSchemes);
    const hasPersonalGuarantee = parseHasPersonalGuaranteeForApi(
      changedValues.hasPersonalGuarantee
    );
    const defaultDistricts = parseDistrictsFromApi(contextState.product.specificRegionDefinition);
    const combinedDistricts = Array.from(
      new Set([...defaultDistricts, ...(changedValues?.specificDistrictDefinition || [])])
    );
    const regionValues =
      parseRegionForApi(changedValues.specificRegionDefinition, combinedDistricts) ||
      contextState.product.specificRegionDefinition;

    delete changedValues.specificDistrictDefinition;

    const combinedValues = {
      ...productValues,
      ...changedValues,
      type: state.productCategory,
      subcategory: state.subCategory,
      productId,
      specificRegionDefinition: regionValues,
      profitabilityBasis,
      investmentSchemes,
      hasPersonalGuarantee,
    };

    if (!productId) return;
    dispatch({
      type: Action.SET_IS_SAVING,
      payload: true,
    });
    try {
      await putProduct(combinedValues);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:productdetails:information:success') },
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: Action.SET_IS_SAVING,
        payload: false,
      });
      dispatch({
        type: Action.SET_ABLE_TO_SAVE,
        payload: false,
      });
    }
  };

  const { handleChange, handleSubmit, errors, metadata, values } = useForm(
    {},
    fields,
    handleSaveChanges,
    validation
  );

  useEffect(() => {
    if (!Object.keys(values).length) {
      dispatch({
        type: Action.SET_ABLE_TO_SAVE,
        payload: false,
      });
      return;
    }
    dispatch({
      type: Action.SET_ABLE_TO_SAVE,
      payload: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const generalItems = metadata?.filter((product: sectionCategoriesType) => {
    if (state.productCategory === 'Grant') {
      return (
        product.productSection === 'general' && product.productType?.includes(state.productCategory)
      );
    }
    return (
      product.productSection === 'general' &&
      product.productType?.includes(state.productCategory) &&
      product.subType?.includes(state.subCategory || '')
    );
  });
  const elegibleItems = metadata?.filter((product: sectionCategoriesType) => {
    if (state.productCategory === 'Grant') {
      return (
        product.productSection === 'eligibility' &&
        product.productType?.includes(state.productCategory)
      );
    }
    return (
      product.productSection === 'eligibility' &&
      product.productType?.includes(state.productCategory) &&
      product.subType?.includes(state.subCategory || '')
    );
  });

  const SaveChanges = () => {
    return (
      <div className={cn(styles['application-actions'])}>
        {(state.ableToSave && (
          <button className={cn(styles['save-button'])} onClick={handleSubmit}>
            {(state.isSaving && <Spinner size="small" />) ||
              t('home:productdetails:information:save')}
          </button>
        )) ||
          (!state.ableToSave && (
            <button
              className={cn(styles['save-button'], !state.ableToSave && styles.inactive)}
              onClick={handleSubmit}
              disabled
            >
              {(state.isSaving && <Spinner size="small" />) ||
                t('home:productdetails:information:save')}
            </button>
          ))}
      </div>
    );
  };

  const getProductCategoryData = () => {
    switch (state.productCategory) {
      case 'Loan':
        return { image: LoanSvg, alt: 'loan', subCatMetadata: loanSubCategoryFields };
      case 'Equity':
        return { image: EquitySvg, alt: 'Equity', subCatMetadata: equitySubCategoryFields };
      case 'Grant':
        return { image: GrantSvg, alt: 'Equity' };
      default:
        return null;
    }
  };

  const categoryData = getProductCategoryData();

  return (
    <div>
      <div>
        <h3 className={classNames('sw-h3', styles.title)}>
          General Information
          {(canEditProducts && <SaveChanges />) || null}
        </h3>
        <div className={cn(styles['type-container'])}>
          <div className={cn(styles['category-container'])}>
            <Field
              {...{
                metadata: productCategoryFields(state.productCategory, canEditProducts),
                handleChange: selectProductCategory,
                errors,
              }}
            />
            {(categoryData && <img src={categoryData.image} alt={categoryData.alt} />) || null}
          </div>
          {(categoryData?.subCatMetadata && (
            <Field
              {...{
                metadata: categoryData?.subCatMetadata(state.subCategory, canEditProducts),
                handleChange: selectSubCategory,
                errors,
              }}
            />
          )) ||
            null}
        </div>
        <div className={cn(styles['product-information'])} data-testid="product-information">
          {(generalItems?.length &&
            generalItems?.map((metadataField) => {
              return (
                <Field
                  {...{
                    metadata: metadataField,
                    errors: errors,
                    handleChange: handleChange,
                    key: metadataField.id,
                    className: {
                      field: styles['field'],
                      control: styles['input-field'],
                    },
                  }}
                />
              );
            })) ||
            null}
        </div>
        <h3 className={classNames('sw-h3', styles.title)}>Eligibility Criteria</h3>
        <div className={cn(styles['product-information'])} data-testid="eligibility-criteria">
          {(elegibleItems?.length &&
            elegibleItems?.map((metadataField) => {
              return (
                <Field
                  {...{
                    metadata: metadataField,
                    errors: errors,
                    handleChange: handleChange,
                    key: metadataField.id,
                    className: {
                      field: styles['field'],
                      control: styles['input-field'],
                    },
                  }}
                />
              );
            })) ||
            null}
        </div>
      </div>
      <BlockNavigationModal shouldBlock={state.ableToSave} />
    </div>
  );
};

export default ProductInformation;
