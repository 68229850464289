import { OptionType } from 'components/field/dropdown/types';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOAST_MESSAGE } from 'store/toast/types';
import {
  getApplication,
  putApplicationContactAddress,
  putApplicationContacts,
  putUpdateApplication,
} from '_shared/api/applications';
import { getDownloadApplication } from '_shared/api/documents';
import useForm from '_shared/hooks/useForm';
import { RoutePath } from '_shared/routes';
import { downloadDocumentLink } from '_shared/utils';
import {
  ApplicationFormActionType,
  ApplicationFormContext,
} from '../store/companyApplicationForm.reducer';
import { API_TO_SAVE, LinkedApplicationType } from '../types';
import { editApplicationFormfields } from '../fields/editApplicationForm.fields';
import {
  ActionType,
  editApplicationFormReducer,
  TApplicationSaving,
} from '../store/editApplicatioForm.reducer';
import validation from '_shared/fieldValidation';
import { useAppState } from 'store';
import {
  combineApiDataFields,
  copyAddress,
  flattenData,
  newAddress,
  newApplicant,
  sortContactAddressByDateMoveIn,
} from '../components/editApplicationForm/utils';
import { postContact, postContactAddress } from '_shared/api/businesses';
import { useTranslation } from 'react-i18next';

const EditApplicationFormHook = ({
  companyId,
  applicationId,
  application,
  dontFetchApplication,
}: {
  companyId: string;
  applicationId: string;
  application?: any;
  dontFetchApplication?: boolean;
}) => {
  const { state: applicationFormState, dispatch: applicationFormDispatch } =
    useContext(ApplicationFormContext);

  const [state, dispatch] = useReducer(editApplicationFormReducer, {
    applicationForm: {
      loading: true,
      error: false,
      fields: [],
      data: {},
      flattedDataForReference: [],
      defaultValues: {},
    },
    loading: {
      loading: true,
      saving: null,
      sendToUser: false,
      export: false,
    },
    error: {
      loading: false,
    },
    serverCompletionPercentage: 0,
    selectedMenuItem: applicationFormState.linkedApplications.selectedMenu,
    ableToSave: false,
  });

  const store = useAppState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /*
  ========================================================================================================================
  Click Handlers
  ========================================================================================================================
  */

  /**
   * Changes current active menu
   */
  const handleMenuSelect = (selectedMenuItem: string) => {
    dispatch({
      type: ActionType.SET_SELECTED_MENU,
      payload: { selectedMenuItem, metadata: metadata },
    });

    applicationFormDispatch({
      type: ApplicationFormActionType.SET_EDIT_APPLICATIN_SELECTED_MENU,
      payload: selectedMenuItem,
    });
    sendAmplitudeData(AmplitudeTrackingEnum.applicationformsubsection, {
      FormSubsection: selectedMenuItem,
    });
  };

  /**
   * Essentially a back button.
   */
  const navigateBackToApplications = () => {
    if (!companyId) return;
    navigate(RoutePath.companyapplicationform.replace(':id', companyId));
    sendAmplitudeData(AmplitudeTrackingEnum.applicationformbacktoapplist);
  };

  /**
   * Sets "able to save" state variable when field changes.
   */
  const ableToSave = (ableToSave = true) => {
    dispatch({ type: ActionType.SET_ABLE_TO_SAVE, payload: { ableToSave } });
  };

  const customHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ableToSave();
    handleChange(event);
  };

  /**
   * Changes current application.
   */
  const handleChangeApplication = (options: OptionType) => {
    const { id: selectedApplicationId } = options;
    if (!companyId || !selectedApplicationId || selectedApplicationId === applicationId) return;

    navigate(
      RoutePath.companyeditapplicationform
        .replace(':id', companyId)
        .replace(':applicationId', selectedApplicationId)
    );
    sendAmplitudeData(AmplitudeTrackingEnum.applicationformswitch);
  };

  const copyRegisteredAddress = () => {
    const { fields, combinedData, updatedAddressValues } = copyAddress(
      state.applicationForm.data,
      currentFormValues
    );

    dispatch({
      type: ActionType.SET_APPLICATION_FORM,
      payload: {
        data: combinedData,
        fields: fields,
        defaultValues: updatedAddressValues,
      },
    });

    ableToSave();
  };

  const addNewApplicant = async () => {
    if (!companyId) return;
    const { combinedData, fieldsMetadata } = await newApplicant(
      companyId,
      state.applicationForm.data,
      currentFormValues
    );

    if (!combinedData && !fieldsMetadata) return;

    dispatch({
      type: ActionType.SET_APPLICATION_FORM,
      payload: {
        data: combinedData,
        fields: fieldsMetadata,
        loading: false,
      },
    });
  };

  const removeApplicant = async (rootId: string) => {
    if (!companyId || !rootId) return;

    const data = state.applicationForm.data;
    data.applicationExtendedSectionDetails = data.applicationExtendedSectionDetails.filter(
      (section: any) => {
        return section.rootId !== rootId;
      }
    );

    const combinedData = combineApiDataFields(data);

    const fieldsMetadata =
      (combinedData.combinedSectionDetails.length &&
        editApplicationFormfields(combinedData.combinedSectionDetails)) ||
      [];

    if (!combinedData && !fieldsMetadata) return;

    dispatch({
      type: ActionType.SET_APPLICATION_FORM,
      payload: {
        data: combinedData,
        fields: fieldsMetadata,
        loading: false,
      },
    });
  };

  const addNewAddress = async (rootId: string) => {
    if (!companyId || !rootId) return;

    const { combinedData, fieldsMetadata } = await newAddress(
      companyId,
      rootId,
      state.applicationForm.data,
      currentFormValues
    );

    if (!combinedData && !fieldsMetadata) return;

    dispatch({
      type: ActionType.SET_APPLICATION_FORM,
      payload: {
        data: combinedData,
        fields: fieldsMetadata,
        loading: false,
      },
    });
  };

  const removeAddress = async (rootId: string, childId: string) => {
    if (!companyId || !rootId || !childId) return;

    const data = state.applicationForm.data;
    data.applicationExtendedSectionDetails = data.applicationExtendedSectionDetails.map(
      (section: any) => {
        return (
          (section.rootId !== rootId && section) || {
            ...section,
            childrenDetails: section.childrenDetails.filter(
              (childDetails: any) => childDetails.childId !== childId
            ),
          }
        );
      }
    );

    const combinedData = combineApiDataFields(data);

    const fieldsMetadata =
      (combinedData.combinedSectionDetails.length &&
        editApplicationFormfields(combinedData.combinedSectionDetails)) ||
      [];

    if (!combinedData && !fieldsMetadata) return;

    dispatch({
      type: ActionType.SET_APPLICATION_FORM,
      payload: {
        data: combinedData,
        fields: fieldsMetadata,
        loading: false,
      },
    });
  };

  /*
  ========================================================================================================================
  API Calls
  ========================================================================================================================
  */

  /**
   * Fetches current application
   */
  const fetchApplication = async (id: string, appId: string, refresh: boolean = false) => {
    try {
      let data = null;
      if (dontFetchApplication && !refresh) {
        data = application;
      } else {
        const applicationRes = await getApplication(id, appId);
        data = applicationRes.data;
      }

      // when saving form in funding matchees product page,
      // we only need completion percentage but dont store the rest of the data.
      if (refresh) {
        storeCompletionPercentage(data?.completion || 0);
        return;
      }

      data.applicationExtendedSectionDetails = sortContactAddressByDateMoveIn(
        data.applicationExtendedSectionDetails
      );

      const combinedData = combineApiDataFields(data);

      const fieldsMetadata =
        (combinedData.combinedSectionDetails.length &&
          editApplicationFormfields(combinedData.combinedSectionDetails)) ||
        [];

      dispatch({
        type: ActionType.SET_APPLICATION_FORM,
        payload: {
          data: combinedData,
          fields: fieldsMetadata,
          flattedDataForReference: flattenData(combinedData?.combinedSectionDetails),
          loading: false,
        },
      });

      const { label: currentMenuSelected } =
        (applicationFormState.linkedApplications.selectedMenu &&
          fieldsMetadata.find((field: any) => {
            return field.label === applicationFormState.linkedApplications.selectedMenu;
          })) ||
        {};

      dispatch({
        type: ActionType.SET_SELECTED_MENU,
        payload: {
          selectedMenuItem:
            (fieldsMetadata.length && (currentMenuSelected || fieldsMetadata[0]?.label)) || '',
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SET_APPLICATION_FORM,
        payload: { error: true, loading: false },
      });
    }
  };

  const saveAddressesAndContacts = async (contactAndAddressFields: any[]) => {
    try {
      if (!contactAndAddressFields.length) return;

      const newContactAndAddressIds: { [key: string]: string } = {};

      let updatedContactFields = [];
      for await (const fields of contactAndAddressFields) {
        const { rootId } = fields.ids;
        let contactId = rootId;

        if (rootId.includes('new-contact')) {
          if (newContactAndAddressIds[rootId]) {
            contactId = newContactAndAddressIds[rootId];
          } else {
            const { data: newContactId } = await postContact(companyId);
            newContactAndAddressIds[rootId] = newContactId;
            contactId = newContactId;
          }
        }

        updatedContactFields.push({
          ...fields,
          ids: {
            ...fields.ids,
            rootId: contactId,
          },
        });
      }

      let updatedContactAndAddressFields = [];
      for await (const fields of updatedContactFields) {
        const { childId, rootId } = fields.ids;

        let addressId = childId;

        if (childId && childId?.includes('new-address')) {
          if (newContactAndAddressIds[childId]) {
            addressId = newContactAndAddressIds[childId];
          } else {
            const { data: newAddressId } = await postContactAddress(companyId, rootId);
            newContactAndAddressIds[childId] = newAddressId;
            addressId = newAddressId;
          }
        }

        updatedContactAndAddressFields.push({
          ...fields,
          ids: {
            ...fields.ids,
            childId: addressId,
          },
        });
      }

      const groupdedContacts = updatedContactAndAddressFields
        .filter((field) => field.api === API_TO_SAVE.CS_CONTACT)
        .reduce((total: any, current: any) => {
          const { rootId } = current.ids;
          return {
            ...total,
            [rootId]: [...(total[rootId] || []), current],
          };
        }, {});

      const groupdedAddresses = updatedContactAndAddressFields
        .filter((field) => field.api === API_TO_SAVE.CS_ADDRESS)
        .reduce((total: any, current: any) => {
          const { childId } = current.ids;
          return {
            ...total,
            [childId]: [...(total[childId] || []), current],
          };
        }, {});

      const contactFieldPromises = Object.entries(groupdedContacts).map(
        async ([contactId, values]: [string, any]) => {
          const contactFieldsToSave = values.map((value: { [key: string]: any }) => {
            return {
              name: value.Name,
              value: value.Value,
            };
          });
          return putApplicationContacts(companyId, contactId, {
            fieldValues: contactFieldsToSave,
          });
        }
      );

      const addressFieldPromises = Object.entries(groupdedAddresses).map(
        async ([addressId, values]: [string, any]) => {
          const [firstChild] = values;
          const contactId = firstChild?.ids?.rootId;
          const addressFieldsToSave = values.map((value: any) => {
            return {
              name: value.Name,
              value: value.Value,
            };
          });

          if (contactId) {
            return putApplicationContactAddress(companyId, contactId, addressId, {
              fieldValues: addressFieldsToSave,
            });
          }
        }
      );

      await Promise.all([...contactFieldPromises, ...addressFieldPromises]);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Downloads selected file
   */
  const handleExport = async () => {
    const productId = state.applicationForm?.data?.productId;
    if (!companyId || !applicationId || !productId) return;
    dispatch({ type: ActionType.DATA_LOADER, payload: { saving: TApplicationSaving.DOWNLOADPDF } });
    const { data } = await getDownloadApplication(companyId, applicationId, productId);
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    downloadDocumentLink(url, `application-${applicationId}`);
    sendAmplitudeData(AmplitudeTrackingEnum.applicationformexport, {});
    dispatch({ type: ActionType.DATA_LOADER, payload: { saving: null } });
  };

  /**
   * Summary. Submits form changes, Passed into useForm hook.
   */
  const handleSaveAsDraft = async (values: { [key: string]: string }) => {
    if (!companyId) return;

    dispatch({ type: ActionType.DATA_LOADER, payload: { saving: TApplicationSaving.SAVEDRAFT } });
    const opportunityId = state.applicationForm?.data?.opportunityId;
    if (!opportunityId || !companyId) return;

    try {
      const fieldsToSave = convertFormValuesToApiSchema({
        ...values,
        ...state.applicationForm.defaultValues,
      });

      const bpFields = fieldsToSave
        .filter((fields: any) => fields.api === API_TO_SAVE.BP)
        .map((field) => {
          return (
            (Array.isArray(field.Value) && {
              ...field,
              Value:
                (field.Name === 'Opportunity.FundingPurposes' && field.Value.join(',')) ||
                JSON.stringify(field.Value),
            }) ||
            field
          );
        });

      const contactAndAddressFields =
        fieldsToSave.filter(
          (fields: any) =>
            fields.api === API_TO_SAVE.CS_CONTACT || fields.api === API_TO_SAVE.CS_ADDRESS
        ) || [];

      await saveAddressesAndContacts(contactAndAddressFields);

      if (bpFields.length) {
        await putUpdateApplication(companyId, opportunityId, {
          fieldsToUpdate: bpFields.map(({ Name, Value, readonly }) => {
            return {
              Name,
              Value: (Value === true && 'true') || (Value === false && 'false') || Value,
              readonly,
            };
          }),
        });
      }
      ableToSave(false);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:deals:dealdetailsmodal:toast:applicationformsave') },
      });
      await fetchApplication(companyId, applicationId, true);
      sendAmplitudeData(AmplitudeTrackingEnum.applicationformsave, {
        FormStatus: state.applicationForm.data.completion === 100 ? 'Complete' : 'Draft',
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: ActionType.DATA_LOADER, payload: { saving: null } });
    }
  };

  /*
  ========================================================================================================================
  Helpers
  ========================================================================================================================
  */

  const convertFormValuesToApiSchema = (values: { [key: string]: string }) => {
    return Object.entries(values).reduce((total: Array<any>, [key, value]) => {
      if (!key.includes('Contact.') && !key.includes('ContactAddress.')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, name] = key.split('*');
        const field = state.applicationForm.flattedDataForReference.find(
          (field: any) => field.name === name
        );

        return [
          ...total,
          {
            Name: name,
            Value: value,
            readonly: field?.isReadonly ?? true,
            api: API_TO_SAVE.BP,
          },
        ];
      }

      const [compoundKey, name] = key.split('*');
      const [sectionId, rootId, childId] = compoundKey.split('_');

      const { dataType, isReadonly } = state.applicationForm.data.combinedSectionDetails
        .find(
          (ref: any) =>
            ref.sectionId === sectionId &&
            (!ref.rootId || ref.rootId === rootId) &&
            (!ref.childId || ref.childId === childId)
        )
        ?.fieldValueDetails.find((fieldValueDetail: any) => fieldValueDetail.name === name);

      return [
        ...total,
        {
          Name: name,
          Value: dataType === 'Bool' ? value.toString() : value,
          readonly: isReadonly || false,
          api:
            (childId !== 'undefined' && API_TO_SAVE.CS_ADDRESS) ||
            (rootId !== 'undefined' && API_TO_SAVE.CS_CONTACT) ||
            API_TO_SAVE.BP,
          ids: {
            childId: childId !== 'undefined' && childId,
            rootId: rootId !== 'undefined' && rootId,
            sectionId,
          },
        },
      ];
    }, []);
  };

  /**
   * Options for application dropdown
   */
  const getApplicationDropdownOptions = (): Array<OptionType> => {
    return (
      applicationFormState.linkedApplications.data.map(
        (linkedApplication: LinkedApplicationType) => {
          const value = [
            ...((linkedApplication.providerName && [linkedApplication.providerName]) || []),
            ...((linkedApplication.productName && [linkedApplication.productName]) || []),
            ...((linkedApplication.subCategory && [linkedApplication.subCategory]) || []),
          ].join(' - ');
          return {
            id: linkedApplication.applicationId,
            label: value,
            value: value,
          };
        }
      ) || []
    );
  };

  const storeCompletionPercentage = (completionPercentage: number) => {
    dispatch({
      type: ActionType.SET_COMPLETION_PERCENT,
      payload: completionPercentage,
    });
  };

  /*
  ========================================================================================================================
  Hooks
  ========================================================================================================================
  */

  const {
    handleChange,
    handleSubmit,
    errors,
    metadata,
    values: currentFormValues,
  } = useForm({}, state.applicationForm.fields, handleSaveAsDraft, validation);

  useEffect(() => {
    if (!Object.keys(errors).length || !Object.values(errors).some((value) => value)) return;

    const [fieldName] = Array.from(Object.entries(errors).filter(([_, value]: any) => value))[0];

    const errorSection =
      state.applicationForm?.fields.find((section: any) => fieldName.includes(section.id)) || {};

    if (errorSection?.name) {
      dispatch({
        type: ActionType.SET_SELECTED_MENU,
        payload: { selectedMenuItem: errorSection.label },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (
      !companyId ||
      !applicationId ||
      (dontFetchApplication && Object.keys(application).length <= 2)
    )
      return;

    fetchApplication(companyId, applicationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, applicationId, application]);

  useEffect(() => {
    storeCompletionPercentage(application?.completion);
  }, [application?.completion]);

  return {
    state,
    getApplicationDropdownOptions,
    handleChangeApplication,
    navigateBackToApplications,
    customHandleChange,
    handleExport,
    handleMenuSelect,
    handleSubmit,
    addNewAddress,
    addNewApplicant,
    copyRegisteredAddress,
    removeApplicant,
    removeAddress,
    errors,
    metadata,
    ableToSave,
  };
};

export default EditApplicationFormHook;
