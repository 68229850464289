import React, { useEffect, useReducer, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  deleteDeal,
  getDeals,
  QueryKeys,
  QueryKeys as BusinessesQueryKeys,
} from '_shared/api/businesses';
import { ActionType, dealReducer } from 'pages/deals/store/deals.reducer';
import { dealsStatusList } from '_shared/utils/constants';
import { useAppState } from 'store';
import { IContext } from 'store/types';
import DealsFilters from 'pages/deals/components/filters';
import usePagination from '_shared/hooks/usePagination';
import Pagination from 'components/pagination';
import styles from 'pages/deals/assets/deals.module.scss';
import Table from 'components/table';
import PipelineView from './components/pipelineView';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { dealsField } from './fields/deals.field';
import { UPDATE_DEALS } from 'store/deals/types';
import DealsNotification from './components/dealsNotification';
import { isBrokerApplication } from '_shared/utils/application';
import Menu from 'components/menu';
import MenuItem from 'components/menu/MenuItem';
import DealDeletionConfirmModal from './components/DealDeletionConfirmModal';
import useToastMessage from '_shared/hooks/useToastMessage';
import { DealDetails } from './components/DealDeletionConfirmModal/types';
import { getSearchDateString } from 'components/field/advancedDatePicker/utils/utils';
import { CreatorType, FilterSearchParam } from './types';
import { FilterType } from '../../components/field/advancedDatePicker/type';
import useDealSearchParams from './hooks/useDealSearchParams';
import { patchUserModal } from '_shared/api/users';
import { DISABLE_MODAL } from 'store/system/types';
import { RoutePath } from '_shared/routes';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSubTypeMetaData, QueryKeys as MarketplaceQueryKeys } from '_shared/api/marketplaceApi';
import { dealTypesWithoutSubtypesValue } from './constant';
import useDealTypes from '_shared/hooks/useDealTypes';

const notificationModalName = 'UpgradedDealManagementModal';

const Deals = ({
  companyId,
  isClientSpecificDeals = false,
}: {
  companyId?: string;
  isClientSpecificDeals?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const [isCreateDealOpen, setIsCreateDealOpen] = useState<boolean>(false);
  const [menuActionMeta, setMenuActionMeta] = useState<{ anchor: HTMLElement; item: any } | null>(
    null
  );
  const [dealToDelete, setDealToDelete] = useState<DealDetails | undefined>(undefined);
  const [isDeletingDeal, setIsDeletingDeal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const { types: dealTypes } = useDealTypes();

  const onMenuExpand = (anchor: HTMLElement, item: { [key: string]: any }) => {
    setMenuActionMeta({ anchor, item });
  };

  const [state, dispatch] = useReducer(dealReducer, {
    redirectToReferrer: false,
    loading: false,
    isSearching: false,
    from: location.state || { from: { pathname: '/' } },
    field: dealsField({
      items: [],
      headerCb: () => {},
      order: { order: '', direction: '' },
      isClientSpecificDeals,
      onMenuExpand,
      currencySymbol: t('currency:symbol'),
    }),
    items: [],
    hidePipeline: false,
  });

  const defaultOwnerIds =
    isBrokerApplication && !isClientSpecificDeals ? [globalState.system.currentUser!.authId] : [];
  const { isInitialised, filters, queries, setSearchParams, clearSearchParams } =
    useDealSearchParams({
      [FilterSearchParam.OwnerIds]: defaultOwnerIds.join(','),
    });

  const paginationHook = usePagination(filters.pageSize, filters.pageNumber);

  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

  const [searchTermTimeout, setSearchTermTimeout] = useState<any | null>(null);

  const { data: dealsData } = useQuery({
    queryKey: [BusinessesQueryKeys.GetDeals, companyId, queries],
    queryFn: () => getDeals({ ...queries, companyId }),
  });

  const { data: dealSubTypesData } = useQuery({
    queryKey: [MarketplaceQueryKeys.GetSubTypeMetaData, 'dealSubTypes'],
    queryFn: () => getSubTypeMetaData('dealSubTypes'),
    staleTime: Infinity,
  });

  const onDeleteClick = () => {
    const {
      companyId,
      id,
      ownerName,
      companyName,
      type,
      subType,
      status,
      dealsStatusList,
      numOfApplications,
    } = menuActionMeta!.item;

    const subTypeName =
      dealSubTypesData?.data.dealSubTypes.find(
        (st: any) => st.value === subType || st.label === subType
      )?.label ?? '';

    const subtypeLabel = dealTypesWithoutSubtypesValue.includes(type)
      ? dealTypes.find(({ value }) => value === type)?.label
      : subTypeName || 'N/A';

    setDealToDelete({
      companyId,
      id,
      ownerName,
      companyName,
      subtype: subtypeLabel,
      status: dealsStatusList[status].label,
      numOfApplications,
    });
  };

  const onConfirmDelete = async () => {
    const { companyId, id } = dealToDelete!;
    setIsDeletingDeal(true);

    try {
      await deleteDeal(companyId, id);
      setDealToDelete(undefined);
      successToastMessage(t('home:deals:deletesucess'));
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });
    } catch (e) {
      console.error(e);
      errorToastMessage(t('home:deals:deletefailed'));
    } finally {
      setIsDeletingDeal(false);
    }
  };

  const setGlobalPaginationState = (pageNumber: number) => {
    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        pageNumber: pageNumber,
      },
    });
  };

  const handleOnHeaderSort = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    event.preventDefault();

    const orderBy = event.currentTarget.getAttribute('data-name')!;
    const direction = filters.direction === 'desc' ? 'asc' : 'desc';

    setSearchParams({
      [FilterSearchParam.PageNumber]: '1',
      [FilterSearchParam.OrderBy]: orderBy,
      [FilterSearchParam.Direction]: direction,
    });

    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        filter: {
          orderBy: `${orderBy}`,
          direction,
        },
      },
    });

    paginationHook.setCurrentPage(1);
  };

  useEffect(() => {
    if (!dealsData) return;

    const { results, paging } = dealsData.data;

    const itemsList = results.map((item: any) => ({
      ...item,
      subType: item.subTypeDescription,
      dealsStatusList,
    }));

    paginationHook.setTotalCount(paging.totalCount);

    dispatch({
      type: ActionType.SET_ITEMS,
      payload: {
        items: itemsList,
        field: dealsField({
          items: [...itemsList],
          headerCb: handleOnHeaderSort,
          order: {
            order: filters.orderBy,
            direction: filters.direction,
          },
          isClientSpecificDeals,
          onMenuExpand,
          currencySymbol: t('currency:symbol'),
        }),
      },
    });

    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        dealsData: itemsList,
      },
    });
  }, [dealsData]);

  const handlePageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParams({
      [FilterSearchParam.PageSize]: event.target.value,
    });
  };

  const handleOnSearchDeals = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (searchTermTimeout) {
      clearTimeout(searchTermTimeout);
    }

    const timeout = setTimeout(() => {
      setSearchParams({
        [FilterSearchParam.PageNumber]: '1',
        [FilterSearchParam.CompanyName]: event.target.value,
      });

      globalDispatch({
        type: UPDATE_DEALS,
        payload: {
          ...globalState.deals,
          dealIndexState: event.target!.value,
        },
      });

      paginationHook.setTotalCount(0);
      paginationHook.setCurrentPage(1);

      sendAmplitudeData(AmplitudeTrackingEnum.applicationsearch);
    }, 500);

    setSearchTermTimeout(timeout);
  };

  const displayPipelineView = () => {
    dispatch({
      type: ActionType.TOGGLE_PIPELINE,
    });
    sendAmplitudeData(AmplitudeTrackingEnum.applicationspipelineviewtoggle, {
      status: state.hidePipeline ? 'Off' : 'On',
    });
  };

  const updateOwnerIdsChange = (ownerIds: string[]) => {
    setSearchParams({
      [FilterSearchParam.OwnerIds]: ownerIds.join(','),
    });
  };

  const updateHideCompletedToggle = (updatedHideCompleted: boolean) => {
    setSearchParams({
      [FilterSearchParam.HideCompleted]: updatedHideCompleted.toString(),
    });
  };

  const updateStatusChanges = (statuses: string[]) => {
    setSearchParams({
      [FilterSearchParam.Statuses]: statuses.join(','),
    });
  };

  const updateCreatorTypeChanges = (creatorTypes: CreatorType[]) => {
    setSearchParams({
      [FilterSearchParam.CreatorTypes]: creatorTypes.join(','),
    });
  };

  const updateDealSubTypeChanges = (dealSubTypes: string[]) => {
    setSearchParams({
      [FilterSearchParam.subtypes]: dealSubTypes.join(','),
    });
  };

  const handleNotificationClose = async (dontShowAgain: boolean) => {
    setIsNotificationOpen(false);

    if (dontShowAgain) {
      // Update store and local storage to disable modal
      globalDispatch({
        type: DISABLE_MODAL,
        payload: notificationModalName,
      });

      try {
        // Notify backend to disable modal so that the modal is marked as disabled in /current endpoint response
        await patchUserModal(notificationModalName);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const isNotificationModalDisabled =
      globalState.system.currentUser?.disabledModalsList?.includes(notificationModalName);

    if (!isNotificationModalDisabled) {
      setIsNotificationOpen(true);
    }
  }, []);

  useEffect(() => {
    const { currentPage } = paginationHook;

    // Below prevents the initial render from setting the page number to `null` which is unnecessary
    if (!isInitialised) return;

    setSearchParams({
      [FilterSearchParam.PageNumber]: currentPage === 1 ? null : currentPage.toString(),
    });
  }, [paginationHook.currentPage]);

  // Added to temporarily fix BP-2339
  useEffect(() => {
    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        lastProgressedFilterType: '',
        lastProgressedFrom: null,
        lastProgressedTo: null,
      },
    });
  }, []);

  if (state.redirectToReferrer) return <Navigate to={state.from} />;

  const setLastProgressedDateFilters = (
    LastProgressedFilterType: FilterType,
    dateOrFrom: Date | null,
    to: Date | null = null
  ) => {
    const searchParams: Partial<Record<FilterSearchParam, string | null>> = {
      [FilterSearchParam.LastProgressedFilterType]: LastProgressedFilterType,
    };

    if ([FilterType.Before, FilterType.After].includes(LastProgressedFilterType)) {
      searchParams[FilterSearchParam.LastProgressedFrom] = getSearchDateString(dateOrFrom!);
      searchParams[FilterSearchParam.LastProgressedTo] = null;
    } else {
      searchParams[FilterSearchParam.LastProgressedFrom] = getSearchDateString(dateOrFrom!);
      searchParams[FilterSearchParam.LastProgressedTo] = getSearchDateString(to!);
    }

    setSearchParams(searchParams);

    sendAmplitudeData(AmplitudeTrackingEnum.lastprogressedfilter, {
      searchType: LastProgressedFilterType,
    });
  };

  return (
    <div className={cn('wrapper', styles.applications)} data-testid="deals-page">
      <DealsFilters
        {...{
          filters,
          hidePipeline: state.hidePipeline,
          companyId: companyId,
          isSearching: state.loading,
          isClientSpecificDeals: isClientSpecificDeals,
          isCreateDealOpen: isCreateDealOpen,
          setIsCreateDealOpen: setIsCreateDealOpen,
          onClearFilters: clearSearchParams,
          onOwnerIdsChange: updateOwnerIdsChange,
          onStatusesChange: updateStatusChanges,
          onHideCompletedChange: updateHideCompletedToggle,
          onCreatorTypeChange: updateCreatorTypeChanges,
          onDealSubTypeChange: updateDealSubTypeChanges,
          displayPipelineView: displayPipelineView,
          handleOnSearchDeals: handleOnSearchDeals,
          handleTotalCount: (countValue: number) => paginationHook.setTotalCount(countValue),
          handeUpdateLastProgressedFilters: setLastProgressedDateFilters,
          subtypeOptions: dealSubTypesData?.data.dealSubTypes,
        }}
      />
      {!state.hidePipeline && isInitialised && (
        <PipelineView
          filters={filters}
          companyId={companyId}
          isClientSpecificDeals={isClientSpecificDeals}
          disabled={state.loading}
          onStatusesChange={updateStatusChanges}
        />
      )}
      <Table
        table-container={cn(styles['table-container'])}
        {...state.field}
        fullTableLoader={state.loading}
        loading={state.loading}
        emptyMessage={
          <>
            <div>{t('home:deals:emptymessage1')}</div>
            {isBrokerApplication && (
              <>
                <br />
                <div>{t('home:deals:emptymessage2')}</div>
              </>
            )}
          </>
        }
        hasReducedTableSize={false}
        rowCallBack={(id: string, companyId: string) => {
          const route = isClientSpecificDeals ? RoutePath.companydeal : RoutePath.dealdetails;

          navigate(route.replace(':companyId', companyId).replace(':dealId', id), {
            state: {
              from: location.pathname,
              search: location.search,
            },
          });

          sendAmplitudeData(AmplitudeTrackingEnum.opendealpage, {
            launchPage: window.location.href.includes('/company') ? 'Within Clients' : 'Deals List',
          });
        }}
      />
      {!state.loading && (
        <Pagination
          {...{
            ...paginationHook,
            setGlobalPaginationState,
            handlePageSize,
            pageSize: filters.pageSize,
          }}
        />
      )}
      <DealsNotification isOpen={isNotificationOpen} handleClose={handleNotificationClose} />

      {isBrokerApplication && (
        <Menu
          open={Boolean(menuActionMeta)}
          anchorEl={menuActionMeta?.anchor!}
          onClose={() => setMenuActionMeta(null)}
          data-testid="deals-menu"
        >
          <MenuItem icon="delete" onClick={onDeleteClick}>
            Delete
          </MenuItem>
        </Menu>
      )}

      <DealDeletionConfirmModal
        isModalOpen={Boolean(dealToDelete)}
        dealDetails={dealToDelete}
        isLoading={isDeletingDeal}
        onConfirm={() => void onConfirmDelete()}
        onModalClose={() => setDealToDelete(undefined)}
      />
    </div>
  );
};

export default Deals;
