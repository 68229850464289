import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import { getApplicationFormDefinition, putApplicationFormDefinition } from '_shared/api/products';
import { FieldDefinitionsType } from '../types';
import {
  ActionType,
  FormField,
  FormSectionType,
  applicationFormReducer,
} from '../store/productApplicationForm.reducer';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import { getFieldDefinitions } from '_shared/api/businesses';
import {
  applicationFormsMetadata,
  defaultFieldDefinitions,
} from '../fields/productApplicationForm.fields';
import useDebounce from '_shared/hooks/useDebounce';

const ProductApplicationFormHook = (productId: string) => {
  const [state, dispatch] = useReducer(applicationFormReducer, {
    isLoading: true,
    isSaving: false,
    isError: false,
    ableToSave: false,
    fieldDefinitions: {},
    applicationForms: [],
    productDefinitions: [],
    searchTerm: '',
    availableSections: [],
    previewSections: [],
    isInitialLoad: true,
  });

  const { t } = useTranslation();
  const store = useAppState();
  const initialFieldIds = useRef<string[]>([]);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);

  const requiredFieldIds = [
    '7e932944-6893-6bba-4d16-21595fc611a2',
    'b18914c6-8721-7aea-9a8b-7e1ef25d5fea',
    '7bb0dcd3-306b-4aa0-534a-86275ad153f0',
    'f69c683d-6ef6-1ddf-9891-708c73043bae',
    '6472b041-6735-3acf-4f6a-8b8b727f0dc4',
    '272a06f3-9696-250f-779b-c8ac457a6025',
  ];

  const isDefaultField = (fieldId: string) => requiredFieldIds.includes(fieldId);

  const enhanceDataWithOrder = useCallback(
    (sections: FormSectionType[]) =>
      sections.map((section, sectionIndex) => ({
        ...section,
        fields: section.fields.map((field, fieldIndex) => ({
          ...field,
          originalOrder: sectionIndex * 1000 + fieldIndex,
        })),
      })),
    []
  );

  const sortSections = useCallback(
    (sections: FormSectionType[]) => [...sections].sort((a, b) => a.displayOrder - b.displayOrder),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.SET_SEARCH_TERM,
      payload: { searchTerm: e.target.value },
    });
  };

  const handleDelete = (idsToDelete: string[]) => {
    const nonDefaultIdsToDelete = idsToDelete.filter((id) => !isDefaultField(id));
    handleUpdate(
      state.productDefinitions.filter((id: string) => !nonDefaultIdsToDelete.includes(id))
    );
  };

  const handleAdd = (idsToAdd: string[]) => {
    const updatedIds = new Set(state.productDefinitions);

    idsToAdd.forEach((id) => updatedIds.add(id));
    handleUpdate(Array.from(updatedIds));
  };

  const handleDiscardChanges = () => {
    handleUpdate(initialFieldIds.current);
    dispatch({
      type: ActionType.SET_ABLE_TO_SAVE,
      payload: { ableToSave: false },
    });
  };

  const filteredAvailableSections: FormSectionType[] = state.availableSections
    .map((section: FormSectionType) => ({
      ...section,
      fields: section.fields.filter((field) =>
        field.displayName.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      ),
    }))
    .filter((section: FormSectionType) => section.fields.length > 0);

  const handleUpdate = useCallback(
    (updatedFieldIds) => {
      dispatch({
        type: ActionType.SET_PRODUCT_DEFINITIONS,
        payload: { productDefinitions: updatedFieldIds },
      });

      dispatch({
        type: ActionType.SET_ABLE_TO_SAVE,
        payload: { ableToSave: true },
      });
    },
    [dispatch]
  );

  const handleSaveChanges = async () => {
    if (!productId) return;

    dispatch({
      type: ActionType.SET_IS_SAVING,
      payload: {
        isSaving: true,
      },
    });

    try {
      await putApplicationFormDefinition(productId, {
        fieldsIds: state.productDefinitions.filter(
          (def: string) => def !== 'e3a958ed-356a-46f7-85ff-d61cb01ae1a3'
        ),
      });

      dispatch({
        type: ActionType.SET_ABLE_TO_SAVE,
        payload: {
          ableToSave: false,
        },
      });
      initialFieldIds.current = state.productDefinitions;
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:productdetails:applicationform:success') },
      });
    } catch (e) {
      console.error(e);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:productdetails:applicationform:saveerror') },
      });
    } finally {
      dispatch({
        type: ActionType.SET_IS_SAVING,
        payload: {
          isSaving: false,
        },
      });
    }
  };

  const fetchFieldDefinitions = async () => {
    dispatch({
      type: ActionType.SET_IS_LOADING,
      payload: true,
    });
    try {
      const { data } = await getFieldDefinitions();
      const filteredFieldDefinitions = {
        ...data,
        fieldDefinitions: data.fieldDefinitions.filter(
          (fieldDef: FieldDefinitionsType) =>
            !fieldDef.displayName.includes(' ID') && fieldDef.displayName !== 'Applicant'
        ),
      };

      dispatch({
        type: ActionType.SET_FIELD_DEFINITIONS,
        payload: {
          fieldDefinitions: filteredFieldDefinitions,
        },
      });

      dispatch({
        type: ActionType.SET_APPLICATION_FIELDS,
        payload: {
          applicationForms: applicationFormsMetadata(filteredFieldDefinitions),
        },
      });
      return filteredFieldDefinitions.fieldDefinitions;
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SET_IS_ERROR,
        payload: { isError: true },
      });
    } finally {
      dispatch({
        type: ActionType.SET_IS_LOADING,
        payload: false,
      });
    }
  };

  const fecthProductApplicationFormDefinition = async (
    id: string,
    fieldDefintions: FieldDefinitionsType[]
  ) => {
    try {
      const { data } = await getApplicationFormDefinition(id);

      const defaultFieldsDefs = fieldDefintions
        .filter((fieldDefs: FieldDefinitionsType) =>
          defaultFieldDefinitions.includes(fieldDefs.name)
        )
        .map((defaultDefs: FieldDefinitionsType) => defaultDefs.fieldDefinitionId);

      const combinedDefsIds = defaultFieldsDefs.concat(data?.fieldDefinitionIds || []);
      const uniqueProductDefinitions = combinedDefsIds.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      dispatch({
        type: ActionType.SET_PRODUCT_DEFINITIONS,
        payload: {
          productDefinitions: uniqueProductDefinitions,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const matchingSections: FormSectionType[] = [];
    const remainingSections: FormSectionType[] = [];

    state.applicationForms.forEach((item: FormSectionType) => {
      const matchingFields: FormField[] = [];
      const remainingFields: FormField[] = [];

      item.fields.forEach((field) => {
        if (state.productDefinitions.includes(field.id)) {
          matchingFields.push(field);
        } else {
          remainingFields.push(field);
        }
      });

      if (matchingFields.length > 0) {
        matchingSections.push({ ...item, fields: matchingFields });
      }
      if (remainingFields.length > 0) {
        remainingSections.push({ ...item, fields: remainingFields });
      }
    });

    dispatch({
      type: ActionType.SET_PREVIEW_SECTIONS,
      payload: {
        previewSections: sortSections(matchingSections),
      },
    });

    dispatch({
      type: ActionType.SET_AVAILABLE_SECTIONS,
      payload: {
        availableSections: sortSections(remainingSections),
      },
    });
  }, [state.applicationForms, state.productDefinitions, sortSections]);

  useEffect(() => {
    const defaultSections: FormSectionType[] = [];
    const nonDefaultSections: FormSectionType[] = [];

    state.applicationForms.forEach((section: FormSectionType) => {
      const defaultFields: FormField[] = [];
      const nonDefaultFields: FormField[] = [];

      section.fields.forEach((field) => {
        if (isDefaultField(field.id)) {
          defaultFields.push(field);
        } else {
          nonDefaultFields.push(field);
        }
      });

      if (defaultFields.length > 0) {
        defaultSections.push({ ...section, fields: defaultFields });
      }
      if (nonDefaultFields.length > 0) {
        nonDefaultSections.push({ ...section, fields: nonDefaultFields });
      }
    });

    dispatch({
      type: ActionType.SET_PREVIEW_SECTIONS,
      payload: {
        previewSections: sortSections(enhanceDataWithOrder(defaultSections)),
      },
    });
    dispatch({
      type: ActionType.SET_AVAILABLE_SECTIONS,
      payload: {
        availableSections: enhanceDataWithOrder(nonDefaultSections),
      },
    });
  }, [state.applicationForms, enhanceDataWithOrder, sortSections]);

  useEffect(() => {
    if (state.isInitialLoad && state.productDefinitions.length > 0) {
      initialFieldIds.current = state.productDefinitions;
      dispatch({
        type: ActionType.SET_INITIAL_LOAD,
        payload: { isInitialLoad: false },
      });
    }
  }, [state.productDefinitions, state.isInitialLoad]);

  useEffect(() => {
    if (!productId) return;

    const getAllData = async () => {
      const fieldDefinitions: FieldDefinitionsType[] = await fetchFieldDefinitions();
      fecthProductApplicationFormDefinition(productId, fieldDefinitions);
    };

    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return {
    state,
    filteredAvailableSections,
    handleSaveChanges,
    handleUpdate,
    handleSearchChange,
    handleAdd,
    handleDelete,
    handleDiscardChanges,
    isDefaultField,
  };
};

export default ProductApplicationFormHook;
