import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Integrations from '.';

export const integrationsRoutes: RouteObject[] = [
  {
    path: RoutePath.companyintegrations,
    element: withTransaction('Integrations', 'component')(<Integrations />),
  },
];
