// eslint-disable-next-line import/named
import { RegisterUser } from 'pages/register/types';
import { RegisterIsv } from 'pages/registerIsv/types';
import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';
import { UserActivationStatus } from 'pages/userDetails/type';

export type UserRolestype = {
  roles: string[];
};

const getUsers = async (data: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getUsers, { params: data });
const getUser = async (userId: string) => BrokerAPI.get(BrokerAPIURL.getUser(userId));
const getExistingUser = async (email: string) => BrokerAPI.get(BrokerAPIURL.getExistingUser(email));
const getCurrentUser = () => BrokerAPI.get(BrokerAPIURL.getCurrentUser);
const putUserMigrate = (activationToken: string) =>
  BrokerAPI.put(BrokerAPIURL.putUserMigrate, { activationToken });
const postRegister = (data: RegisterUser) => BrokerAPI.post(BrokerAPIURL.postRegister, data);
const postTokenIsv = (data: RegisterIsv) => BrokerAPI.post(BrokerAPIURL.postTokenIsv, data);
const patchUserModal = (modalName: string) =>
  BrokerAPI.patch(BrokerAPIURL.patchUserModal(modalName));

// IAM Urls
const getUsersByEmailAddress = async (emailAddress: string) =>
  BrokerAPI.get(BrokerAPIURL.getUsersByEmailAddress(emailAddress));
const postForgetPassword = async (securityId: string) =>
  BrokerAPI.post(BrokerAPIURL.postForgotPassword(securityId));
const postFlushCache = async (securityId: string) =>
  BrokerAPI.post(BrokerAPIURL.postFlushCache(securityId));
const patchActivationStatus = async (securityId: string, userStatus: UserActivationStatus) =>
  BrokerAPI.patch(BrokerAPIURL.patchActivationStatus(securityId, userStatus));
const patchUserRoles = async (securityId: string, data: UserRolestype) =>
  BrokerAPI.patch(BrokerAPIURL.patchUserRoles(securityId), data);

export {
  getUsers,
  getUser,
  getCurrentUser,
  postRegister,
  getExistingUser,
  putUserMigrate,
  postTokenIsv,
  patchUserModal,
  getUsersByEmailAddress,
  postForgetPassword,
  postFlushCache,
  patchActivationStatus,
  patchUserRoles,
};
