import { APIActivityType, ActivityType, ACTIVITY_CATEGORY } from './types';

export type activitiesStateType = {
  isActivitiesDropdownOpen: boolean;
  activitiesCount: number;
  activities: Array<ActivityType>;
  data: Array<APIActivityType>;
  loading: {
    activities: boolean;
    delete: boolean;
    update: boolean;
  };
  error: {
    activities: boolean;
  };
  activityToDelete: ActivityType | null;
  activityToEdit: ActivityType;
  activityCategory: ACTIVITY_CATEGORY | null;
  blockNavigation: boolean;
  order: any;
  params: {
    filter: string;
    direction: string;
    pageSize: number;
  };
  isChooseActivityModalOpen: boolean;
};
export enum ActionType {
  SET_IS_ACTIVITIES_DROPDOWN_OPEN,
  SET_ACTIVITIES,
  SET_LOADING,
  SET_ERROR,
  SET_ACTIVITY_TO_DELETE,
  SET_ACTIVITY_TO_EDIT,
  SET_NEW_ACTIVITY_CATEGORY,
  SET_ACTIVITY_ORDER,
  SET_OPEN_CHOOSE_ACTIVITY_MODAL,
  SET_ACTIVITIES_FILTER,
}

export function activitiesReducer(
  state: activitiesStateType,
  action: {
    type: ActionType;
    payload: any;
  }
) {
  switch (action.type) {
    case ActionType.SET_IS_ACTIVITIES_DROPDOWN_OPEN:
      return {
        ...state,
        isActivitiesDropdownOpen: !state.isActivitiesDropdownOpen,
      };

    case ActionType.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload.activities,
        ...(action.payload.activitiesCount !== undefined
          ? { activitiesCount: action.payload.activitiesCount }
          : {}),
        ...(action.payload.data ? { data: action.payload.data } : {}),
      };

    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };

    case ActionType.SET_ERROR:
      return {
        ...state,
        error: { ...state.error, ...action.payload.error },
      };

    case ActionType.SET_ACTIVITY_TO_DELETE:
      return {
        ...state,
        activityToDelete: action.payload.activityToDelete,
        isActivitiesDropdownOpen: false,
      };

    case ActionType.SET_ACTIVITY_TO_EDIT:
      return {
        ...state,
        activityToEdit: action.payload.activityToEdit,
        isActivitiesDropdownOpen: false,
      };
    case ActionType.SET_NEW_ACTIVITY_CATEGORY:
      return {
        ...state,
        activityCategory: action.payload.activityCategory,
      };
    case ActionType.SET_ACTIVITY_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case ActionType.SET_OPEN_CHOOSE_ACTIVITY_MODAL:
      return {
        ...state,
        isChooseActivityModalOpen: action.payload.isChooseActivityModalOpen,
      };

    case ActionType.SET_ACTIVITIES_FILTER:
      return {
        ...state,
        params: { ...state.params, filter: action.payload.filter },
      };

    default:
      return state;
  }
}
