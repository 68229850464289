import React from 'react';
import styles from './Radio.module.scss';
import cn from 'classnames';

export type RadioProps = {
  name?: string;
  value: string;
  checked?: boolean;
  children: React.ReactNode;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Radio: React.FC<RadioProps> = ({
  children,
  name,
  checked = false,
  className,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <label className={cn(styles.radio, { [styles.checked]: checked }, className)}>
      <span>{children}</span>
      <input
        type="radio"
        name={name}
        value={value}
        className={styles.input}
        defaultChecked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
    </label>
  );
};

export default Radio;
