export enum GraphTypesEnum {
  BAR = 'BAR',
  LINE = 'LINE',
  PERCENTAGE = 'PERCENTAGE',
}

export type CashFlowShortTermItem = {
  bills: number;
  cashToday: number;
  invoices: number;
  lastAvailableDate: string;
};

export type CashFlowProjectionItem = {
  cashIn: { [key: string]: number };
  cashOut: { [key: string]: number };
  closeBalance: number;
  month: number;
  openingBalance: number;
  year: number;
};

export type CashFlowProjectionItems = CashFlowProjectionItem[];
