import { useContext, useState } from 'react';
import Card from 'components/card';
import cn from 'classnames';
import { ActionType, FundingMatchesContext } from '../../store/fundingMatches.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Button from 'components/button';
import styles from 'pages/fundingMatches/assets/fundingMatchesDetails.module.scss';
import Tabs from 'components/tabs';
import FundingMatchCard from '../fundingMatchCard';
import { readableGroupName } from 'pages/fundingMatches/utils';
import Select from 'components/field/select';
import { fundingOfferFields } from 'pages/fundingMatches/fields/fundingOfferDetails.fields';
import { fundingRequiredTimescaleMetadata } from '_shared/utils/constants';
import { OfferTypes } from 'pages/deals/types';

const FundingMatchesOfferDetails: React.FC = () => {
  const {
    id: companyId,
    offerType,
    subType,
  } = useParams() as { id: string; offerType: OfferTypes; subType: string };

  const { state, dispatch } = useContext(FundingMatchesContext);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<string>(subType);

  const backToFundingMatches = () => {
    navigate(
      RoutePath.companyfundingmatches.replace(':id', companyId).replace(':offerType', offerType)
    );
  };

  const getTabItems = () => {
    return Object.entries(state.matches.fields as { [key: string]: any[] }).map(
      ([tab, values]: [string, any[]]): { keys: string[]; title: any } => {
        const count = values.length;
        return {
          keys: [tab],
          title: (
            <div className={cn(styles['tab-count-container'])}>
              <span>{readableGroupName(tab)}</span>
              <div className={cn(styles['tab-count'], !count && styles['tab-count-empty'])}>
                {count}
              </div>
            </div>
          ),
        };
      }
    );
  };

  const onChangeTab = (tab: string) => {
    navigate(
      RoutePath.companyfundingmatchesdetails
        .replace(':id', companyId)
        .replace(':offerType', offerType)
        .replace(':subType', tab)
    );
    setCurrentTab(tab);
  };

  const sortByFieldName = state.sortOffersBy[offerType];
  const sortFunction = (a: any, b: any) => {
    if (sortByFieldName === 'speedName') {
      const { value: aValue } =
        fundingRequiredTimescaleMetadata.find(
          ({ label }: { label: string }) => label === a[sortByFieldName]
        ) || {};
      const { value: bValue } =
        fundingRequiredTimescaleMetadata.find(
          ({ label }: { label: string }) => label === b[sortByFieldName]
        ) || {};
      return (aValue || 5) < (bValue || 5) ? -1 : 1;
    }

    return a[sortByFieldName] < b[sortByFieldName] || !b[sortByFieldName] ? -1 : 1;
  };

  const sortOfferFieldsMetadata = fundingOfferFields(sortByFieldName, offerType);
  const currentMatches = state.matches.fields[currentTab] || [];
  const sortedCurrentMatches = currentMatches.sort(sortFunction);

  const handleSort = (event: any) => {
    dispatch({
      type: ActionType.SET_SORT_OFFERS_BY,
      payload: { sortBy: event.target.value, offerType },
    });
  };

  return (
    <Card {...{ noPadding: true, loading: state.matches.loading, error: state.matches.error }}>
      <div className={cn(styles['heading-container'])}>
        <Button
          id="return-to-funding-matches"
          ariaLabel="return-to-funding-matches"
          className={cn(styles.return)}
          clickHandler={backToFundingMatches}
        >
          <span className="material-icons">arrow_back_ios</span>
          <h3>{offerType}</h3>
        </Button>
        <Tabs
          {...{
            items: getTabItems(),
            activeKey: currentTab || '',
            onChangeTab,
          }}
        />
      </div>
      <div className={cn(styles['content-container'])}>
        <div className={cn(styles['content-header'])}>
          <Select
            {...{
              metadata: sortOfferFieldsMetadata,
              handleChange: handleSort,
              errors: [],
            }}
          />
        </div>
        {sortedCurrentMatches.map((field: any) => {
          return (
            <FundingMatchCard
              key={field.id}
              field={field}
              companyId={companyId}
              offerType={offerType}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default FundingMatchesOfferDetails;
