import cn from 'classnames';
import styles from '../../assets/editApplicationForm.module.scss';
import { useTranslation } from 'react-i18next';
import ApplicationMenu from '../applicationMenu';
import ApplicationFields from '../applicationFields';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { getRelevantMenuSections } from '../editApplicationForm/utils';
import { ApplicationFormFieldsMetadataType } from 'pages/companyApplicationForm/types';
import BlockNavigationModal from 'components/BlockNavigationModal';
import { useAppState } from 'store';

const EditApplicationFormSections = ({
  state,
  metadata,
  customHandleChange,
  handleMenuSelect,
  addNewAddress,
  addNewApplicant,
  copyRegisteredAddress,
  removeApplicant,
  removeAddress,
  errors,
  isClientSoleTrader,
}: any) => {
  const { t } = useTranslation();
  const { state: globalState } = useAppState();

  const ableToCalibrate = Boolean(
    globalState.system.isSuperAdmin?.() || globalState.system.isMarketPlaceManager?.()
  );
  const sectionsMetadata: ApplicationFormFieldsMetadataType[] =
    (metadata && metadata.filter((section: any) => section.name === state.selectedMenuItem)) || [];

  const sections = getRelevantMenuSections(sectionsMetadata);

  return (
    <>
      {(!sectionsMetadata.length && (
        <div className={cn(styles['no-data-container'])}>
          {ableToCalibrate ? (
            <>
              <span>{t('home:companydetails:applicationform:linked:empty')}</span>
              <Link
                to={RoutePath.productapplicationform.replace(
                  ':id',
                  state.applicationForm.data.productId
                )}
              >
                <Button
                  id="activate-application-sections"
                  ariaLabel="activate-application-sections"
                >
                  {t('home:companydetails:applicationform:linked:activateapplicationsections')}
                </Button>
              </Link>
            </>
          ) : (
            <span>{t('home:companydetails:applicationform:linked:notyetcalibrated')}</span>
          )}
        </div>
      )) || (
        <>
          <div className={cn(styles.content)}>
            {(metadata && (
              <ApplicationMenu
                {...{
                  metadata,
                  selectedMenuItem: state.selectedMenuItem,
                  handleMenuSelect,
                }}
              />
            )) ||
              null}

            <div
              data-testid="application-fields"
              className={cn(styles['edit-application-content'])}
            >
              <ApplicationFields
                {...{
                  sections,
                  sectionHeading: state.selectedMenuItem,
                  handleChange: customHandleChange,
                  errors,
                  isClientSoleTrader,
                  actions: {
                    addNewAddress,
                    addNewApplicant,
                    copyRegisteredAddress,
                    removeApplicant,
                    removeAddress,
                  },
                }}
              />
            </div>
          </div>
        </>
      )}
      <BlockNavigationModal shouldBlock={state.ableToSave} />
    </>
  );
};

export default EditApplicationFormSections;
