import React, { useRef, useState } from 'react';
import cn from 'classnames';
import useBlur from '_shared/hooks/useBlur';
import styles from './assets/statusToggle.module.scss';
import { Status, StatusToggleProps } from './types';
import Checkbox from 'components/field/checkbox';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import { TOption } from '_shared/utils/form';

const StatusToggle = ({
  label,
  statuses,
  selectedStatus,
  setSelectedStatus,
  disabled,
}: StatusToggleProps) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useBlur(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  const toggleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget?.id;
    const updatedSelectedStatus = (selectedStatus.includes(id) &&
      selectedStatus.filter((statusId: string) => statusId !== id)) || [...selectedStatus, id];

    setSelectedStatus(updatedSelectedStatus);
  };

  return (
    <div className={cn(styles['status-selector'])}>
      <div
        data-testid="status-dropdown-container"
        className={cn(styles['status-dropdown-container'])}
      >
        <button
          data-testid="status-dropdown-button"
          className={cn(styles['status-dropdown-button'])}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>
            {label || 'Selected'} ({selectedStatus?.length})
          </span>
          <i className={'material-icons'}>expand_more</i>
        </button>
        {(isDropdownOpen && (
          <div
            ref={dropdownRef}
            className={cn(styles['status-dropdown'])}
            data-testid="status-filter-dropdown"
          >
            <div className={cn(styles['dropdown-checkbox-container'])}>
              {statuses?.map((status: Status) => {
                return (
                  <label key={status.id} className={cn(styles['dropdown-checkbox-label'])}>
                    <Checkbox
                      {...{
                        id: status.id,
                        name: status.name,
                        handleChange: toggleSelected,
                        selected: selectedStatus.includes(status.id),
                        disabled,
                      }}
                    />
                    <>{<span>{spaceBetweenTitleCaseWords(status.name)}</span>}</>
                  </label>
                );
              })}
            </div>
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default StatusToggle;
