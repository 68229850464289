import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './ForeignExchange.module.scss';
import icon from '../../assets/foreign-exchange-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import { InfoboxDesciptions } from '../../types';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';

const ForeignExchange: React.FC = () => {
  const { isLoading, postProductApplication } = useSavingsProducts('foreign-exchange');

  const infoboxes: InfoboxDesciptions = {
    amount: 'Any',
    purpose:
      "Reduce exchange rate risk in your client's business by holding cash in multiple currencies, with the option to lock-in fixed rates.",
    speed: 'Typically 24-48 hours',
    suitablefor: 'Businesses and individuals',
    cost: 'No upfront fees',
  };

  const handleSubmit = async () => {
    window.open('https://swoop.alt21.com/register', '_blank', 'noopener');
    await postProductApplication('foreign-exchange');
  };

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Foreign Exchange (FX)"
        icon={icon}
        ctaText="Book a meeting"
        ctaCallback={handleSubmit}
        isCtaDisabled={false}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)}>
        <div className={cn(styles.container)}>
          <div className={cn('text-container', styles['text-content'])}>
            <h2>
              SMEs in the UK are overcharged by about £4bn a year on business money transfers. On
              average our customers save up to £8,000 a year by using our FX savings analysis tools.
              Your client can too.
            </h2>
            <p>
              Sign up for a fast, easy, international business account that saves your client money
              every time they use it.
            </p>
            <ul>
              <li>Manage your cash in multiple currencies</li>
              <li>Reduce the finance burden of managing payments from different accounts</li>
              <li>
                Lock in fixed rates for the future, so there are no nasty surprises in your future
                supply chain
              </li>
              <li>
                Multi-currency accounts, wallets, and payments that support cross-currency business
                activities
              </li>
              <li>FX hedging solutions in 30+ currencies</li>
              <li>Automated quote generation</li>
              <li>Manage beneficiaries, transactions, notifications</li>
              <li>Access to margin credit facilities</li>
              <li>User access and permission control</li>
              <li>Self-service customer portal accessible 24/7</li>
              <li>Real-time reporting</li>
              <li>Transparent T&Cs</li>
              <li>Dedicated Account Manager</li>
            </ul>
            <br />
            <h2>International payments</h2>
            <p>
              International payments are also known as cross-border payments, international money
              transfers or global payments. And when foreign nationals send funds home to family
              members, they are known as remittances. Whether your client had a business collecting
              payments from overseas, a business paying overseas suppliers, or is an individual
              sending money to their homeland, they have many options in addition to traditional
              bank transfers.
            </p>
          </div>
          <SavingsProductsQuickInfoBox descriptions={infoboxes} />
        </div>
      </div>
    </div>
  );
};

export default ForeignExchange;
