import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';

import AccountOwnerIcon from 'assets/images/icon/account-owner.svg';

type AccountOwnerProps = {
  fundingManagerName: string;
};

const AccountOwner: React.FC<AccountOwnerProps> = ({ fundingManagerName }) => {
  return (
    <div className={cn([styles['card'], styles['account-owner-details']])}>
      <img
        src={AccountOwnerIcon}
        alt="account owner"
        className={cn(styles['custom-icon'], styles['account-owner'])}
      />
      <div className={styles['container-details']}>
        <p className={styles['label']}>Account owner</p>
        <p className={styles['sub-label']}>{fundingManagerName}</p>
      </div>
    </div>
  );
};

export default AccountOwner;
