import React, { LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CheckboxType } from './type';
import styles from './checkbox.module.scss';

function Checkbox(props: CheckboxType, _ref: LegacyRef<HTMLInputElement>) {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.readOnly) return false;
    props.handleChange(event);
  };

  const renderCheckbox = (option: any) => {
    const { label, selected } = option;
    return (
      <label
        htmlFor={`${props.id}`}
        className={classNames(props.classNames?.checkbox || styles.checkbox, {
          [props.classNames?.isChecked || styles['is-checked']]: selected,
        })}
        key={`${props.id}`}
      >
        <input
          id={`${props.id}`}
          type="checkbox"
          name={props.name}
          className={classNames(props.classNames?.inputCheckbox || styles['input-checkbox'])}
          ref={_ref}
          tabIndex={props.tabIndex}
          checked={selected || ''}
          onChange={handleChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
          data-testid={`test-${props.id}`}
          data-parentid={props.parentId}
        />
        <span className={classNames(props.classNames?.holder || styles.holder)}>
          {selected ? (
            <i className={classNames(props.classNames?.icon || styles.icon, 'material-icons')}>
              check_box
            </i>
          ) : (
            <i className={classNames(props.classNames?.icon || styles.icon, 'material-icons')}>
              check_box_outline_blank
            </i>
          )}
          {label && (
            <span className={classNames(styles.text)}>
              {(typeof label === 'string' && t(label)) || label}
            </span>
          )}
        </span>
      </label>
    );
  };

  if (Array.isArray(props.options) && props.options.length > 1) {
    return <div>{props.options.map(renderCheckbox)}</div>;
  }

  return renderCheckbox(props);
}

export default React.forwardRef(Checkbox);
