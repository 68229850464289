import React from 'react';
import classNames from 'classnames';

import { StatusIndicatorType } from './type';
import { formatToKebabCase } from '_shared/utils/casing';
import styles from 'components/statusIndicator/statusIndicator.module.scss';

const CurrencyInput = React.forwardRef(({ status }: StatusIndicatorType, _ref: React.Ref<any>) => {
  return (
    <div
      className={classNames(styles['status-indicator-wrapper'], styles[formatToKebabCase(status)])}
    >
      <span className={classNames(styles['status-indicator'])}>{status}</span>
    </div>
  );
});

export default CurrencyInput;
