import styles from './assets/highCommissionModal.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Modal from 'components/modal';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import { calculateNetFee } from '../../pages/deals/utils';
import { useCallback, useMemo } from 'react';
import { formatCurrency } from '_shared/utils/currency';

type HighCommissionModalProps = {
  isModalOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
  dealType?: string;
  funder?: string;
  productName?: string;
  amount: number;
  funderCommission: number;
  clientFee: number;
  introducerFee: number;
};

const HighCommissionModal = ({
  isModalOpen,
  isLoading,
  onClose,
  onConfirm,
  companyName,
  dealType = '',
  funder = '',
  productName = '',
  amount,
  funderCommission,
  clientFee,
  introducerFee,
}: HighCommissionModalProps) => {
  const { t } = useTranslation();

  const currencySymbol = t('currency:symbol');

  const netFee = useMemo(
    () => calculateNetFee(funderCommission, clientFee, introducerFee),
    [funderCommission, clientFee, introducerFee]
  );

  const checkHighCommission = useCallback(
    (commission: number) => {
      return commission > amount * 0.1;
    },
    [amount]
  );

  return (
    <Modal show={isModalOpen} handleClose={onClose} testId={'high-commission-modal'}>
      <div className={cn(styles['high-commission-modal'])}>
        <header className={cn(styles['high-commission-modal-header'])}>
          <h3>{t('home:highcommissionmodal:header')}</h3>
        </header>
        <div className={cn(styles['high-commission-table-container'])}>
          <span className={cn(styles['high-commission-warning'])}>
            {t('home:highcommissionmodal:warningmessage')}
          </span>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                {dealType ? <TableCell>Deal</TableCell> : null}
                {funder ? <TableCell>Funder</TableCell> : null}
                {productName ? <TableCell>Product Name</TableCell> : null}
                <TableCell>Amount</TableCell>
                <TableCell>Funder Commission</TableCell>
                <TableCell>Client Fee</TableCell>
                <TableCell>Introducer Fee</TableCell>
                <TableCell>Net Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{companyName}</TableCell>
                {dealType ? <TableCell>{dealType}</TableCell> : null}
                {funder ? <TableCell>{funder}</TableCell> : null}
                {productName ? <TableCell>{productName}</TableCell> : null}
                <TableCell>{formatCurrency(amount, currencySymbol, 2)}</TableCell>
                <TableCell
                  className={cn([{ [styles.red]: checkHighCommission(funderCommission) }])}
                >
                  {formatCurrency(funderCommission, currencySymbol, 2)}
                </TableCell>
                <TableCell className={cn([{ [styles.red]: checkHighCommission(clientFee) }])}>
                  {formatCurrency(clientFee, currencySymbol, 2)}
                </TableCell>
                <TableCell className={cn([{ [styles.red]: checkHighCommission(introducerFee) }])}>
                  {formatCurrency(introducerFee, currencySymbol, 2)}
                </TableCell>
                <TableCell className={cn([{ [styles.red]: checkHighCommission(netFee) }])}>
                  {formatCurrency(netFee, currencySymbol, 2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={cn(styles['action-container'])}>
          <Button
            id="cancel-modal-action"
            className={cn(styles['action-button'])}
            ariaLabel="cancel-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={onClose}
          >
            {t('home:highcommissionmodal:buttons:cancel')}
          </Button>
          <Button
            id="confirm-modal-action"
            className={cn(styles['action-button'])}
            ariaLabel="confirm-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            tabIndex={0}
            clickHandler={onConfirm}
            loading={isLoading}
          >
            {t('home:highcommissionmodal:buttons:confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default HighCommissionModal;
