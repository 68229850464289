import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './Pension.module.scss';
import icon from '../../assets/pension-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';
import { InfoboxDesciptions } from '../../types';

const Pension: React.FC = () => {
  const { isLoading, isAvailable, postProductApplication } = useSavingsProducts('pension');

  const infoboxes: InfoboxDesciptions = {
    amount: null,
    purpose: null,
    speed: null,
    suitablefor: 'Individuals\nEmployers\nAccountants\nAdvisers',
    cost: null,
  };

  const handleSubmit = async () => {
    await postProductApplication('pension');
  };

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Pension"
        icon={icon}
        ctaText="Book a meeting"
        ctaCallback={handleSubmit}
        isCtaDisabled={!isAvailable}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h2>
            Our technology unlocks the power of workplace pensions for businesses of all sizes.
          </h2>
          <p>
            We offer pension schemes, salary exchange, and payroll systems to enhance financial
            well-being and prosperity.
          </p>
          <h2>Get expert assistance for your client's pension scheme selection and setup.</h2>
          <p>
            We can provide easy access to more scheme choices for your client's employees and
            facilitate decision-making during an initial setup or re-enrolment.
          </p>
          <p>
            You'll gain full transparency over any fees, investment strategies, and more. Plus, we
            have a unique ability to rank pension schemes based on ethical and green credentials.
          </p>
          <h2>Salary exchange</h2>
          <p>
            Unlock significant cost savings for both your client's business and each of their staff
            members with the Salary Exchange Scheme.
          </p>
          <br />
          <p>
            95% of small businesses are not benefiting from the Salary Exchange Scheme. We handle
            the setup and management for your client, so you don't miss out on this valuable
            benefit.
          </p>
          <h2>Why is setting up salary exchange so crucial?</h2>
          <p>
            It allows you and your client's employees to save on National Insurance contributions,
            putting more money in their pockets each month and increasing cash savings for your
            business. In short, everyone wins.
          </p>
          <h2>Pricing to suit business of all sizes</h2>
          <p>
            When registering, you can opt for us to also handle their payroll, salary exchange, and
            compliance matters. Please note that selecting these services may result in pricing
            variations based on the size and complexity of your client's business. Rest assured, we
            will provide a pricing solution tailored to their specific needs.
          </p>
        </div>
        <SavingsProductsQuickInfoBox descriptions={infoboxes} />
      </div>
    </div>
  );
};

export default Pension;
