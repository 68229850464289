import { AxiosRequestConfig } from 'axios';
import { nonGroupEndpoints } from 'config/grouping';
import { getPersistedState } from 'store';
import { SystemState } from 'store/system/types';

export enum XGroupHeaders {
  XGroupIds = 'X-Group-Ids',
  XGroupDefaultId = 'X-Group-Default-Id',
}

const getXGroupIds = (currentSystemState?: SystemState) => {
  // Return the default group id for users without exposed groups
  if (!currentSystemState?.exposedGroups?.length) {
    return currentSystemState?.groupId;
    // Return the all group ids for users who have all selected
  } else if (currentSystemState?.selectedGroups?.find((group) => group.groupId === 'all')) {
    return [
      ...currentSystemState.exposedGroups.map((group) => group.groupId),
      currentSystemState.groupId,
    ].join(',');
  } else {
    // return selected group ids
    return currentSystemState?.selectedGroups?.map((group) => group.groupId).join(',');
  }
};

export const getXGroupId = (currentSystemState?: SystemState) => currentSystemState?.groupId || '';

export function setXGroupHeaders(config: AxiosRequestConfig) {
  if (!config.url || nonGroupEndpoints.includes(config.url)) return;

  const currentSystemState = getPersistedState();

  // store this for later use in config overrides
  const defaultId = getXGroupId(currentSystemState);
  if (defaultId) {
    config.headers[XGroupHeaders.XGroupDefaultId] = defaultId;
  }

  if (currentSystemState?.accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${currentSystemState.accessToken}`;
  }

  config.headers[XGroupHeaders.XGroupIds] = getXGroupIds(currentSystemState);

  if (!config.headers[XGroupHeaders.XGroupIds]?.length) {
    delete config.headers[XGroupHeaders.XGroupIds];
  }
}

// Set the x group headers back to the deafult group for this request
export function resetXGroupHeaders(requestConfig = {}) {
  return {
    headers: {
      [XGroupHeaders.XGroupIds]: null,
      ['Content-Type']: 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
    transformRequest: (data: any, headers: any) => {
      delete headers[XGroupHeaders.XGroupIds];
      headers[XGroupHeaders.XGroupIds] = headers[XGroupHeaders.XGroupDefaultId];
      return JSON.stringify(data);
    },
    ...requestConfig,
  };
}

export function deleteXGroupHeaders(requestConfig = {}) {
  return {
    headers: {
      [XGroupHeaders.XGroupDefaultId]: null,
      [XGroupHeaders.XGroupIds]: null,
    },
    ...requestConfig,
  };
}
