import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  if ((window as any)?._env_?.REACT_APP_AMPLITUDE || process.env.REACT_APP_AMPLITUDE) {
    amplitude
      .getInstance()
      .init((window as any)?._env_?.REACT_APP_AMPLITUDE || process.env.REACT_APP_AMPLITUDE);
  }
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: { email: string }) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (
  eventType: AmplitudeTrackingEnum,
  eventProperties: { [key: string]: string | string[] } = {},
  shouldSendEvent?: boolean
) => {
  const bpState = localStorage.getItem('sw-bp-state');
  const email = bpState && JSON.parse(bpState)?.currentUser?.email;
  if (email || shouldSendEvent !== false) {
    setAmplitudeUserProperties({ email });
    amplitude.getInstance().logEvent(eventType, { ...eventProperties });
  }
};

export enum AmplitudeTrackingEnum {
  // navigation tabs
  overviewtab = '<BrokerPortal><Broker><Businesses><BusinessInfoTab><Button>',
  fundingmatchestab = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><Button>',
  financetab = '<BrokerPortal><Broker><Businesses><FinancialInfoTab><Button>',
  documentlibrarytab = '<BrokerPortal><Broker><Businesses><DocumentLibraryTab><Button>',
  dealstab = 'Deal Tab',

  // overview
  viewcompanylink = 'View Client',
  companynumberlink = '<BrokerPortal><Broker><Businesses><BusinessInfoTab><CompanyNumber><Button>',

  // funding matches
  editfundingrequirements = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><StartEdit><Button>',
  updatefundingrequirements = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><ConfirmEdit><Button>',
  fundingmatchesequitytab = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><EquitySection><Button>',
  fundingmatchesgranttab = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><GrantsSection><Button>',
  fundingmatchestogglesubsection = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><ToggleSubsection><Button>',

  // task management
  taskmanagementfilter = 'Tasks > Filter',
  newtask = 'Start New Task',
  savetask = 'Complete New Task',

  // documents
  downloaddocument = '<BrokerPortal><Broker><Businesses><DocumentLibraryTab><Download><Button>',
  savedocument = '<BrokerPortal><Broker><Businesses><DocumentLibraryTab><SaveChanges><Button>',
  editdocument = '<BrokerPortal><Broker><Businesses><DocumentLibraryTab><EditName><Button>',
  uploaddocument = '<BrokerPortal><Broker><Businesses><DocumentLibraryTab><Upload><Button>',
  generatebankstatements = '<GenerateBankStatements>',
  updatetruelayerdocs = 'Generate TrueLayer Docs',

  // application form
  applicationformsave = '<BrokerPortal><Broker><Businesses><ApplicationFormTab><Save><Button>',
  applicationformsendtouser = '<BrokerPortal><Broker><Businesses><ApplicationFormTab><SendToUser><Button>',
  applicationformexport = '<BrokerPortal><Broker><Businesses><ApplicationFormTab><ExportToPDF><Button>',
  applicationformsubsection = '<BrokerPortal><Broker><Businesses><ApplicationFormTab><FormSubsection><Button>',
  applicationformconfirmnewapp = 'New App Form',
  applicationformview = 'App Form View',
  applicationformbacktoapplist = 'App Form Back',
  applicationformswitch = 'App Form Switch',
  submitapplicationtolender = 'Submit Application To Lender',

  // custom sidebar
  customSidebarAddItem = 'Add custom sidebar item',

  // notes
  viewcompanynotes = 'View Company Notes',
  editnotes = 'Edit Note',
  newnote = 'New Note',
  deletenote = 'Delete Note',
  collapsenotepad = 'Collapse Note',
  closenotepad = 'Close Notepad',
  savenote = 'Save Note',

  // journeys
  addclientstartmanual = 'Add Client Start',
  addclientendmanual = 'Add Client End',
  addclientstartinvite = 'Add Client Start',
  addclientendinvite = 'Add Client End',
  addbrokerstart = 'Broker List',
  addbrokerend = 'View Broker Info',

  // application process
  applicationprocessloans = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><DebtSection><Button>',
  applicationprocessequity = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><EquitySection><Button>',
  applicationprocessgrants = '<BrokerPortal><Broker><Businesses><FundingMatchesTab><GrantsSection><Button>',
  applicationprocessfiltersall = 'ALL Filters Expand/Collapse',
  applicationprocessfilterssingle = 'Single Filter Expand/Collapse',
  submittolender = 'Submit to Lender',
  startapplication = 'Start Application',

  // product pages
  productlist = 'Product List',
  viewproductinfo = 'View Product Info',
  producttab = 'Product Tab',
  producttypefilter = 'Products - Type Filter',
  productsubtypefilter = 'Products - Subtype Filter',

  // broker pages
  brokerlist = 'Broker List',
  viewbrokerinfo = 'View Broker Info',

  // application page overview
  applicationspage = 'Applications',
  applicationsearch = 'Applications Search',
  applicationspipelineviewtoggle = 'Applications - Toggle Pipeline View',
  applicationscompletedtoggle = 'Applications - Toggle Completed',
  applicationsclearfilter = 'Applications = Clear Filter',
  applicationsstatusfilter = 'Applications - Status Filter',
  applicationsownerfilter = 'Applications - Owner Filter',
  applicationscreatorfilter = 'Applications - Creator Filter',
  applicationsdealsubtypefilter = 'Applications - Deal SubType Filter',
  applicationsstatusbartoggle = 'Applications - Status Bar Toggle',
  applicationsdatefiltertabs = 'Applications - Date Filter Tabs',

  // any product/non-matches modal
  startanyproductclientapplications = 'Client Applications - Start any Product',
  searchanyproduct = 'Search any Product',
  productcreateapplication = 'Create Application',
  startanyproductexitmodal = 'Client Applications - End any Product',
  startanyproductfilter = 'Filter Any Product',

  // sidebar navigation
  togglesidebar = 'Toggle Side Menu',
  clientssidebar = 'Sidebar - Clients',
  dealssidebar = 'Sidebar - Deals',
  taskssidebar = 'Sidebar - Tasks',
  companyprofilesidebar = 'Sidebar - Company Profile',
  userssidebar = 'Sidebar - Users List',
  helpfaqsidebar = 'Sidebar - Help & FAQ',

  //deals
  confirmnewdeal = 'Confirm New Deal',
  accordiansectiontoggle = 'Accordian Section Toggle',
  deleteapplication = 'Delete Application',
  opendealpage = 'Open Deal',
  lastprogressedfilter = 'Deals - Last Progressed filter',

  //login
  login = 'Login - Portals',

  //clients
  clientsfundingmanagerfilter = 'Clients - funding manager filter',
  clientssearchfilter = 'Clients - search filter',
}
