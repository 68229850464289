import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/modal';
import styles from './InviteClientModal.module.scss';

type InviteClientModalProps = {
  show: boolean;
  onClose: () => void;
  onAddBusiness: () => void;
  onInviteBusiness: () => void;
};

const InviteClientModal: React.FC<InviteClientModalProps> = ({
  show,
  onClose,
  onAddBusiness,
  onInviteBusiness,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} handleClose={onClose}>
      <div className={cn(styles['add-client-modal'])}>
        <header className={cn(styles['add-client-header'])}>
          <h2>{t('sidebar:addclientmodal:header')}</h2>
          <span className={cn('material-icons', styles['header-icon'])}>person_add</span>
        </header>
        <div className={cn(styles['add-client-options-container'])}>
          <div className={cn(styles['add-client-option'])}>
            <div className={cn(styles['option-header'])}>
              <span className={cn('material-icons', styles['option-icon'])}>
                format_list_numbered
              </span>
              <h3>{t('sidebar:addclientmodal:options:manual:title')}</h3>
            </div>
            <p>{t('sidebar:addclientmodal:options:manual:bestfor')}</p>
            <ul>
              <li>{t('sidebar:addclientmodal:options:manual:description')}</li>
            </ul>
            <button
              id="add-client-manual"
              className={cn(styles['add-client-button'])}
              onClick={onAddBusiness}
            >
              {t('sidebar:addclientmodal:options:manual:button')}
              <span className={cn('material-icons', styles['button-icon'])}>arrow_forward</span>
            </button>
          </div>
          <div className={cn(styles['option-divider'])}>
            <span className={cn(styles['divider-line'])}></span>
            <span>or</span>
            <span className={cn(styles['divider-line'])}></span>
          </div>
          <div className={cn(styles['add-client-option'])}>
            <div className={cn(styles['option-header'])}>
              <span className={cn('material-icons', styles['option-icon'])}>email</span>
              <h3>{t('sidebar:addclientmodal:options:invitation:title')}</h3>
            </div>
            <p>{t('sidebar:addclientmodal:options:invitation:bestfor')}</p>
            <ul>
              <li>{t('sidebar:addclientmodal:options:invitation:description')}</li>
            </ul>
            <button
              id="add-client-invite"
              className={cn(styles['add-client-button'])}
              onClick={onInviteBusiness}
            >
              {t('sidebar:addclientmodal:options:invitation:button')}
              <span className={cn('material-icons', styles['button-icon'])}>arrow_forward</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteClientModal;
