import React from 'react';
import cn from 'classnames';
import styles from './CurrencyInput.module.scss';
import ReactCurrencyInput from 'react-currency-input-field';

type CurrencyInputProps = {
  prefix?: string;
  value: string | number;
  disabled?: boolean;
  precision?: number;
  className?: string;
  placeHolder?: string;
  onChange?: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ prefix, value = 0, disabled, precision, className, placeHolder = '0', onChange }, ref) => {
    return (
      <div className={styles.wrapper}>
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <ReactCurrencyInput
          ref={ref}
          disabled={disabled}
          className={cn(styles.input, className)}
          placeholder={placeHolder}
          value={value}
          decimalScale={precision}
          decimalsLimit={precision}
          onValueChange={(v) => onChange?.(v ?? '0')}
          // the following is important to ensure that the displayed and returned values use the expected format by our systems
          decimalSeparator="."
          groupSeparator=","
        />
      </div>
    );
  }
);

export default CurrencyInput;
