import { env } from '_shared/utils';
import { XApplicationSubdomainTypes } from '_shared/utils/application';
import { useMemo } from 'react';

export const useApplication = () => {
  const applicationName = useMemo(() => {
    const subdomain = window.location.hostname.split('.')[0];

    if (
      subdomain === XApplicationSubdomainTypes.Advisors ||
      subdomain === XApplicationSubdomainTypes.Brokers
    ) {
      return subdomain;
    }
    return env('REACT_APP_APPLICATION_TYPE');
  }, []);

  const isBrokerApplication = useMemo(
    () => applicationName === XApplicationSubdomainTypes.Brokers,
    [applicationName]
  );

  const isAdvisorApplication = useMemo(
    () => applicationName === XApplicationSubdomainTypes.Advisors,
    [applicationName]
  );

  return { applicationName, isBrokerApplication, isAdvisorApplication };
};
