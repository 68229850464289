import NotFound from 'components/notfound';
import routes from 'router/index.routes';
import { loginRoutes } from 'pages/login/login.routes';
import { registerRoutes } from 'pages/register/register.routes';
import { registerIsvRoutes } from 'pages/registerIsv/registerIsv.routes';
import { migrateUserRoutes } from 'pages/migrateUser/migrateUser.routes';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';

export const appRoutes: RouteObject[] = [
  ...routes,
  ...registerRoutes,
  ...registerIsvRoutes,
  ...loginRoutes,
  ...migrateUserRoutes,
  {
    path: RoutePath.notFound,
    element: <NotFound />,
  },
];
