import { useMemo } from 'react';
import cn from 'classnames';
import Spinner from 'components/spinner';
import styles from 'pages/deals/assets/pipelineView.module.scss';
import { getDealsStatistics, QueryKeys } from '_shared/api/businesses';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import { formatToKebabCase } from '_shared/utils/casing';
import { useAppState } from 'store';
import { IContext } from 'store/types';
import { appSpecific } from '_shared/utils/application';
import { TPipelineStatistic } from './types';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { UPDATE_DEALS } from 'store/deals/types';
import { Filters } from '../../types';
import { useQuery } from '@tanstack/react-query';

const PipelineView = ({
  filters,
  companyId,
  isClientSpecificDeals,
  disabled,
  onStatusesChange,
}: {
  filters: Filters;
  companyId?: string;
  isClientSpecificDeals?: boolean;
  disabled?: boolean;
  onStatusesChange: any;
}) => {
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const {
    ownerIds,
    companyName,
    creatorTypes,
    LastProgressedFilterType,
    LastProgressedFrom,
    LastProgressedTo,
    hideCompleted,
  } = filters;

  const { data, isLoading } = useQuery({
    queryKey: [
      QueryKeys.GetDealsStatistics,
      isClientSpecificDeals,
      companyId,
      ownerIds,
      companyName,
      creatorTypes,
      LastProgressedFilterType,
      LastProgressedFrom,
      LastProgressedTo,
    ],
    queryFn: () => fetchDealStatistics(),
  });

  const fetchDealStatistics = async () => {
    const userIdType = appSpecific('brokerIds', 'advisorIds');
    const filterQueryToBeReturned: any = {
      [userIdType]: ownerIds,
      CompanyName: companyName,
      CompanyId: isClientSpecificDeals ? companyId : undefined,
      CreatorTypes: creatorTypes,
    };

    if (LastProgressedFilterType) {
      filterQueryToBeReturned['LastProgressedFilterType'] = LastProgressedFilterType.toLowerCase();
    }

    if (LastProgressedFrom) {
      filterQueryToBeReturned['LastProgressedFrom'] = LastProgressedFrom;
    }

    if (LastProgressedTo) {
      filterQueryToBeReturned['LastProgressedTo'] = LastProgressedTo;
    }

    if (!LastProgressedFrom && !LastProgressedTo) {
      delete filterQueryToBeReturned.LastProgressedFilterType;
    }

    return getDealsStatistics(filterQueryToBeReturned);
  };

  const pipelineStats = useMemo(() => {
    const totalDeals = data?.data.statistics.reduce(
      (accumulativeTotal: number, dealsStatistic: { status: string; total: number }) =>
        dealsStatistic.total + accumulativeTotal,
      0
    );

    return data?.data.statistics
      ?.filter(
        (statistic: any) =>
          !hideCompleted ||
          (hideCompleted &&
            !['closedwon', 'closedlost'].includes(statistic.status.toLocaleLowerCase()))
      )
      .map((statistic: any) => ({
        ...statistic,
        percentageOfTotal: (100 * statistic.total) / totalDeals,
      }));
  }, [data, filters]);

  const handleSetSelectedStatus = (status: string) => {
    const selectedStatuses: string[] = filters.statuses || [];

    const validSelectedStatuses = selectedStatuses.includes(status)
      ? selectedStatuses.filter((selectedStatus) => selectedStatus !== status)
      : [...selectedStatuses, status];

    onStatusesChange(validSelectedStatuses);

    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        selectedStatus: selectedStatuses.includes(status)
          ? selectedStatuses.filter((selectedStatus) => selectedStatus !== status)
          : [...selectedStatuses, status],
      },
    });
    sendAmplitudeData(AmplitudeTrackingEnum.applicationsstatusbartoggle, {
      status: selectedStatuses.includes(status) ? 'Off' : 'On',
      statusSelected: status,
    });
  };

  return (
    <div className={cn(styles['pipeline-view-container'])}>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <div className={cn(styles['pipeline-view'])}>
          {pipelineStats?.map((column: TPipelineStatistic) => {
            const statusColor = formatToKebabCase(spaceBetweenTitleCaseWords(column.status));
            return (
              <div
                key={column.status}
                className={cn(
                  styles['pipeline-column-wrapper'],
                  {
                    [styles['pipeline-column-wrapper--selected']]: filters.statuses?.includes?.(
                      column.status
                    ),
                  },
                  { [styles['pipeline-column-wrapper--disabled']]: disabled }
                )}
                onClick={() => !disabled && handleSetSelectedStatus(column.status)}
              >
                <div
                  className={cn(styles['pipeline-column'], styles[statusColor])}
                  data-height={column.percentageOfTotal}
                  style={{ height: `${column.percentageOfTotal}%` }}
                ></div>
                <div className={cn(styles['pipeline-column-info'])}>
                  <div className={cn(styles['pipeline-label'])}>
                    {spaceBetweenTitleCaseWords(column.status)}
                  </div>
                  <div className={cn(styles['pipeline-stat'])}>{column.total}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PipelineView;
