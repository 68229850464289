import { FieldMetaDataPropType, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import styles from '../../assets/roleManagement.module.scss';

export type CurrentAssignedRolesType = {
  userId: string;
  roles: string[];
};

export const customRoleFields = (
  item?: CurrentAssignedRolesType,
  availableRoles?: any,
  isUserAdmin?: boolean
): Array<FieldMetaDataPropType> => {
  return [
    {
      id: `${item?.userId}-roles`,
      name: 'roleIds',
      validationtype: ValidationTypes.MULTISELECT,
      options: availableRoles?.map(({ name: preModifiedName }: { name: string }) => {
        const modifiedName = spaceBetweenTitleCaseWords(preModifiedName);
        return {
          value: preModifiedName,
          label: modifiedName || preModifiedName,
        };
      }),
      disableRemove: spaceBetweenTitleCaseWords('SuperAdmin'),
      value: item?.roles.map((role: string) => {
        return spaceBetweenTitleCaseWords(role) || null;
      }),
      fieldType: FieldTypes.MULTISELECT,
      disabled: !isUserAdmin,
      classNames: {
        field: styles['roles-field'],
        label: styles['roles-label'],
      },
    },
  ];
};
