/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import styles from 'pages/fundingMatches/assets/productApplication.module.scss';
import { ActionType } from 'pages/fundingMatches/store/productApplication.reducer';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Modal from 'components/modal';
import UploadDocument from '../../../documentLibrary/components/uploadDocument';
import ProductApplicationHook from 'pages/fundingMatches/hooks/productApplication.hook';
import EditApplicationFormSections from 'pages/companyApplicationForm/components/editApplicationFormSections';
import EditApplicationFormHook from 'pages/companyApplicationForm/hooks/editApplicationForm.hook';
import RequiredDocuments from '../requiredDocuments';
import { OfferTypes } from 'pages/deals/types';
import { useTranslation } from 'react-i18next';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import Spinner from 'components/spinner';
import ErrorComponent from 'components/error';
import ApplicationHeading from 'pages/companyApplicationForm/components/applicationHeading';
import history from '_shared/history';
import { RoutePath } from '_shared/routes';
import IncompleteApplicationWarningModal from 'pages/deals/components/IncompleteApplicationWarningModal';
import { getApplicationMenuDetails } from 'pages/companyApplicationForm/components/applicationMenu/utils';
// document library upload component needs to move to /src/components/uploadDocument

const ProductApplication = ({
  providedCompanyId,
  providedProductId,
  providedOfferType,
  isClientSoleTrader,
  logo,
  provider,
  productName,
}: {
  providedCompanyId?: any;
  providedProductId?: any;
  providedOfferType?: any;
  isClientSoleTrader?: boolean;
  logo?: string;
  provider?: string;
  productName?: string;
}) => {
  const {
    id: routeCompanyId,
    productId: routeProductId,
    offerType: routeOfferType,
  } = useParams() as {
    id: string;
    productId: string;
    offerType: string;
  };

  const isPageView = routeCompanyId && routeProductId && routeOfferType;

  const companyId = providedCompanyId || routeCompanyId;
  const productId = providedProductId || routeProductId;
  const offerType = providedOfferType || routeOfferType;

  const { state, dispatch, handleSubmission, fetchDocumentList } = ProductApplicationHook({
    companyId: companyId,
    productId: productId,
  });
  const { t } = useTranslation();

  const [showIncompleteApplicationWarningModal, setShowIncompleteApplicationWarningModal] =
    React.useState(false);

  const {
    state: editApplicationFromState,
    customHandleChange,
    handleMenuSelect,
    addNewAddress,
    addNewApplicant,
    copyRegisteredAddress,
    removeApplicant,
    removeAddress,
    errors,
    metadata,
    handleSubmit,
    handleExport,
    ableToSave,
  } = EditApplicationFormHook({
    companyId: companyId || '',
    applicationId: state.application.applicationId,
    application: state.application,
    dontFetchApplication: true,
  });

  const submitDisabled =
    editApplicationFromState.loading.saving ||
    state.loading.application ||
    state.loading.requiredDocuments;

  const numOfSubmittedDocuments = state.requiredDocuments.filter(
    (document: any) => document.isDocumentUploaded
  ).length;

  const applicationCompletionPercentage = useMemo(
    () => getApplicationMenuDetails(metadata).completionPercentage,
    [metadata]
  );

  const onSubmitClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const isIncompleteApplication =
      applicationCompletionPercentage < 100 ||
      state.requiredDocuments.filter((document: any) => !document.isDocumentUploaded).length > 0;

    if (isIncompleteApplication) {
      setShowIncompleteApplicationWarningModal(true);
    } else {
      void submitApplication();
    }
  };

  const onConfirmIncompleteApplicationSubmit = async () => {
    await submitApplication();
    setShowIncompleteApplicationWarningModal(false);
  };

  const submitApplication = async () => {
    const docsCompletionPercentage = Math.round(
      (numOfSubmittedDocuments / state.requiredDocuments.length) * 100
    );
    const appFormFields = getApplicationMenuDetails(metadata).menu.reduce(
      (acc, item) => acc + item.totalCount,
      0
    );

    handleSubmit(null);
    await handleSubmission();

    sendAmplitudeData(AmplitudeTrackingEnum.submittolender, {
      productType: offerType,
      docsRequired: state.requiredDocuments.length.toString(),
      docsCompleted: docsCompletionPercentage + '%',
      appFormFields: appFormFields.toString(),
      appFormCompleted: applicationCompletionPercentage + '%',
    });

    ableToSave(false);

    isPageView && history.push(RoutePath.applications);
  };

  const FunderHeader = () => {
    return (
      <>
        {logo && <img alt="logo" src={logo} className={cn(styles.logo)} />}
        <div className={cn(styles['names-container'])}>
          {provider && <h1 className={cn(styles['provider-name'])}>{provider}</h1>}
          {productName && <span className={cn(styles['product-name'])}>{productName}</span>}
        </div>
      </>
    );
  };

  return (
    <div className={cn('wrapper', styles['product-applications'])}>
      <div className={cn(styles['title-container'])}>{<FunderHeader />}</div>
      <RequiredDocuments
        {...{
          requiredDocuments: state.requiredDocuments,
          dispatch,
          companyId: companyId,
          loading: state.loading.requiredDocuments,
        }}
      />

      <div className={cn(styles.section)}>
        <div className={cn(styles['section-heading'])}>
          <h3>Part 2) Complete application forms</h3>
          {!state.loading.application && !state.error.application && (
            <ApplicationHeading
              {...{
                ableToSave: editApplicationFromState.ableToSave,
                hasSubmittedApplication: Boolean(
                  editApplicationFromState.applicationForm?.data?.isEligibleForPDFDownload
                ),
                saving: editApplicationFromState.loading.saving,
                handleSubmit,
                handleExport,
              }}
            />
          )}
        </div>
        <hr className={cn(styles.divider)} />
        {(state.loading.application && (
          <div className={cn(styles['loading-container'])}>
            <Spinner size="large" />
            <span>
              {t('home:companydetails:fundingmatches:fundingrequirements:loadingapplication')}
            </span>
          </div>
        )) ||
          (state.error.application && (
            <ErrorComponent
              message={t(
                'home:companydetails:fundingmatches:fundingrequirements:applicationformerror'
              )}
            />
          )) || (
            <EditApplicationFormSections
              {...{
                state: editApplicationFromState,
                metadata,
                customHandleChange,
                handleMenuSelect,
                addNewAddress,
                addNewApplicant,
                copyRegisteredAddress,
                removeApplicant,
                removeAddress,
                handleSubmit,
                handleExport,
                errors,
                isClientSoleTrader,
              }}
            />
          )}
      </div>
      <div className={cn(styles.section)}>
        <div className={cn(styles['section-heading'])}>
          <h3>Part 3) Submit</h3>
        </div>
        <hr className={cn(styles.divider)} />
        <div className={cn(styles['section-content'])}>
          <p>Once you're happy with the above information, click here to Submit to lenders</p>
          <Button
            id="submit-to-lenders"
            ariaLabel="submit-to-lenders"
            clickHandler={onSubmitClick}
            loading={state.isSubmittingToLenders}
            className={styles['submit-form-button']}
            disabled={submitDisabled}
            buttonStyleType={
              submitDisabled ? ButtonStyleTypeEnum.DISABLED : ButtonStyleTypeEnum.PRIMARY
            }
          >
            {(offerType === OfferTypes.LOANS && 'Submit to lenders') || 'Submit to Swoop'}
          </Button>
        </div>
      </div>
      <IncompleteApplicationWarningModal
        isModalOpen={showIncompleteApplicationWarningModal}
        numOfSubmittedDocs={numOfSubmittedDocuments}
        numOfRequiredDocs={state.requiredDocuments.length}
        applicationPercentage={applicationCompletionPercentage}
        onConfirm={onConfirmIncompleteApplicationSubmit}
        onModalClose={() => setShowIncompleteApplicationWarningModal(false)}
      />
      {state.documentUploadModal.documentUploadModalOpen && (
        <Modal
          show={state.documentUploadModal.documentUploadModalOpen}
          handleClose={() =>
            dispatch({
              type: ActionType.SET_DOCUMENT_UPLOAD_MODAL_OPEN,
              payload: {
                documentUploadModalOpen: false,
                title: '',
                category: '',
              },
            })
          }
        >
          <div className={styles['document-upload-modal-wrapper']}>
            <UploadDocument
              {...{
                companyId: companyId || '',
                fetchDocumentList,
                documentTitle: state.documentUploadModal.title,
                documentCategory: state.documentUploadModal.category,
                uploadedDocuments: state.uploadedDocuments,
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProductApplication;
