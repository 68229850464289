import cn from 'classnames';
import styles from 'pages/documentLibrary/assets/documentLibrary.module.scss';
import { useTranslation } from 'react-i18next';
import { SelectAllDocumentItemsType } from '../../types';
import Spinner from 'components/spinner';
import TextInput from 'components/field/text';

type DocumentTableHeadingPropTypes = {
  searchDocumentsValue: string;
  selectAllDocumentItems: Array<SelectAllDocumentItemsType>;
  handleOnSearchDocuments: any;
  selectedDocumentsLength: number;
  isAccountingDocuments: boolean;
};

const DocumentTableHeading = ({
  searchDocumentsValue,
  selectAllDocumentItems,
  handleOnSearchDocuments,
  selectedDocumentsLength,
  isAccountingDocuments,
}: DocumentTableHeadingPropTypes) => {
  const { t } = useTranslation();
  return (
    <div data-testid="table-heading" className={cn(styles['document-table-heading'])}>
      <div>
        <h3 className={cn('sw-h3', styles['document-table-title'])}>
          {t(`home:companydetails:documentlibrary:manage`)}
        </h3>
      </div>
      <div className={cn(styles['action-container'])}>
        <span className={cn(styles.search)}>
          <TextInput
            {...{
              metadata: {
                value: searchDocumentsValue,
                type: 'text',
                tabIndex: 1,
                placeholder: t('home:companydetails:documentlibrary:search'),
              },
              errors: [],
              handleChange: handleOnSearchDocuments,
            }}
          />
          <i className={cn('material-icons', styles.icon)}>search</i>
        </span>
        {selectAllDocumentItems?.map((item: any) => {
          const selectedItem = item.id === 'download-selected';
          const itemLabel =
            (selectedDocumentsLength &&
              t(`home:companydetails:documentlibrary:${item.i18n}:active`)) ||
            t(`home:companydetails:documentlibrary:${item.i18n}:inactive`);
          return selectedItem ? (
            <button
              key={item.id}
              onClick={item.clickHandler}
              data-label={itemLabel}
              className={cn(
                styles['multi-select-action'],
                (selectedDocumentsLength && styles['multi-select-action-active-tooltip']) ||
                  styles['multi-select-action-inactive-tooltip']
              )}
              disabled={!selectedDocumentsLength}
            >
              {(item.hasOwnProperty('loading') && item.loading && <Spinner size="small" />) || (
                <span
                  className={cn(
                    'material-icons',
                    selectedDocumentsLength && styles['multi-select-action-active']
                  )}
                >
                  {item.icon}
                </span>
              )}
            </button>
          ) : (
            <button
              key={item.id}
              onClick={item.clickHandler}
              data-label={itemLabel}
              className={cn(
                styles['multi-select-action'],
                (selectedDocumentsLength &&
                  isAccountingDocuments &&
                  styles['multi-select-action-active-tooltip']) ||
                  styles['multi-select-action-inactive-tooltip']
              )}
              disabled={!selectedDocumentsLength || isAccountingDocuments}
            >
              {(item.hasOwnProperty('loading') && item.loading && <Spinner size="small" />) || (
                <span
                  className={cn(
                    'material-icons',
                    selectedDocumentsLength &&
                      !isAccountingDocuments &&
                      styles['multi-select-action-active']
                  )}
                >
                  {item.icon}
                </span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentTableHeading;
