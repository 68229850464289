import { TablePropType } from 'components/table/type';
import i18next from 'i18next';
import { displayStringValue } from '_shared/utils/string';
import styles from './deleteModal.module.scss';

export const deleteCompanyFields = (data: Array<{ [key: string]: any }>): TablePropType => {
  return {
    header: [
      {
        id: 'companyName',
        value: i18next.t('home:deletemodal:fields:company:companyname'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'signupDate',
        value: i18next.t('home:deletemodal:fields:company:signupdate'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'fundingManager',
        value: i18next.t('home:deletemodal:fields:company:fundingmanager'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t('No client to delete'),
    items: data.map((item: { [key: string]: any }) => {
      return [
        {
          id: `${item?.id}-companyName`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item?.name ? displayStringValue(item?.name) : 'N/A',
        },
        {
          id: `${item?.id}-signupDate`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item.signUpDate ? new Date(item.signUpDate).toLocaleDateString() : 'N/A',
        },
        {
          id: `${item?.id}-fundingManager`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item?.fundingManagerName ? item?.fundingManagerName : 'N/A',
        },
      ];
    }),
  };
};

export const deleteApplicationFields = (data: Array<{ [key: string]: any }>): TablePropType => {
  return {
    header: [
      {
        id: 'providerName',
        value: i18next.t('home:deletemodal:fields:application:providername'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'productName',
        value: i18next.t('home:deletemodal:fields:application:productname'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'dateCreated',
        value: i18next.t('home:deletemodal:fields:application:datecreated'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t('No application to delete'),
    items: data.map((item: { [key: string]: any }) => {
      return [
        {
          id: `${item?.id}-providerName`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item?.providerName ? item?.providerName : 'N/A',
        },
        {
          id: `${item?.id}-productName`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item?.productName ? item?.productName : 'N/A',
        },
        {
          id: `${item?.id}-dateCreated`,
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: item.dateCreated ? new Date(item.dateCreated).toLocaleDateString() : 'N/A',
        },
      ];
    }),
  };
};
