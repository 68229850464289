import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './assets/registerIsv.module.scss';
import BrokerPortalLogo from 'assets/images/broker-portal-logo.svg';
import AdvisorPortalLogo from 'assets/images/advisor-portal-logo-white.svg';
import RegisterImage from 'assets/images/register-image.png';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { postTokenIsv } from '_shared/api/users';
import { RegisterIsv } from './types';
import { isBrokerApplication, applicationType } from '_shared/utils/application';
import Spinner from 'components/spinner';

const RegisterUserIsv = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token') || '';
  const { t } = useTranslation();
  const register = 'register?';
  const convertUser = 'convert-user?';
  const navigateToLogin = () => {
    navigate('/login');
  };

  const resolveMsToken = async (fields: RegisterIsv) => {
    try {
      if (!token && token.length <= 0) throw new Error('token is empty');
      const { data } = await postTokenIsv({
        ...fields,
        applicationType: applicationType,
        msToken: token,
      });
      let urlToRedirect = await getSwoopRedirectUrl(data);
      if (urlToRedirect && data.indexOf(register) > 0) {
        navigate(`/${register}${urlToRedirect}`);
      } else {
        navigate(`/${convertUser}${urlToRedirect}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getSwoopRedirectUrl = async (isvUrl: string) => {
    if (isvUrl && isvUrl.startsWith('http')) {
      let slices = isvUrl.split(register);
      if (isvUrl.indexOf(register) > 0 && slices && slices.length > 0) {
        return slices[1];
      } else {
        slices = isvUrl.split(convertUser);
        if (slices && slices.length > 0) {
          return slices[1];
        }
      }
    }
  };

  useEffect(() => {
    if (!token && token.length <= 0) navigateToLogin();

    resolveMsToken({ applicationType: applicationType, msToken: token });
  });

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles['image-container'])}>
        <div className={cn(styles['image-content-container'])}>
          <img
            src={isBrokerApplication ? BrokerPortalLogo : AdvisorPortalLogo}
            alt={isBrokerApplication ? 'register-broker-portal' : 'register-advisor-portal'}
            className={cn(
              isBrokerApplication ? styles['broker-portal-logo'] : styles['advisor-portal-logo']
            )}
          />
          <img
            src={RegisterImage}
            alt="register-isv"
            className={cn(styles['register-isv-image'])}
          />
        </div>
      </div>
      <div className={cn(styles['content-isv-container'])}>
        <div className={cn(styles['signin-isv-container'])}>
          <span>{t('register:alreadyhaveaccount')}</span>
          <Button
            id="register-signin-isv"
            ariaLabel="register-signin-isv"
            type="submit"
            clickHandler={navigateToLogin}
            tabIndex={6}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            {t('common:signin')}
          </Button>
        </div>
        <div className={cn(styles['field-isv-container'])}>
          <Spinner size="large" />
          <h2>{t('register:isvProgram')}</h2>
        </div>
      </div>
    </div>
  );
};

export default RegisterUserIsv;
