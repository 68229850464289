import cn from 'classnames';
import { displayStringValue } from '_shared/utils/string';
import styles from '../assets/financialInformation.module.scss';
import { CashFlowProjectionItem, CashFlowProjectionItems, GraphTypesEnum } from '../types';
import { roundTwoDecimal } from '_shared/utils/rounding';
import { TablePropType } from 'components/table/type';
import i18next from 'i18next';
import { getMonthName } from '_shared/utils/date';
import { convertToCurrency } from '_shared/utils/currency';

const months = Array.from({ length: 12 }, (item, i) => {
  return new Date(0, i).toLocaleString('en-US', { month: 'short' });
});

export const financialOverviewFields = (data: any) => {
  const profitData = data?.profit?.financialProfits.map(
    ({ periodName, ebitda }: { periodName: string; ebitda: number }) => {
      return {
        label: periodName,
        value: ebitda,
      };
    }
  );

  const incomeData = data?.income?.financialIncomes.map(
    ({ income, periodName }: { income: number; periodName: string }) => {
      return {
        label: periodName,
        value: income,
      };
    }
  );

  const indexMonth = months.findIndex((month) => {
    const name = data?.grossProfit?.financialGrossProfitMargin.find(
      ({ periodName }: { periodName: string }) => periodName.includes('YE')
    )?.periodName;

    return (name && name.includes(month)) || 0;
  });

  const indexChange = (indexMonth < 12 && indexMonth + 1) || 1;
  const xAxis = [...months.slice(indexChange), ...months.slice(0, indexChange)];

  const grossProfitData = data?.grossProfit?.financialGrossProfitMargin?.map(
    ({
      periodName,
      grossProfitMargins,
    }: {
      periodName: string;
      grossProfitMargins: Array<{ grossProfitMargin: number; month: number }>;
    }) => {
      return {
        id: periodName,
        color: 'hsl(31, 70%, 50%)',
        data: xAxis.map((axisValue: string) => {
          const { grossProfitMargin } =
            grossProfitMargins.find(({ month }) => months[month - 1] === axisValue) || {};
          return {
            x: axisValue,
            y: (grossProfitMargin && Math.round(grossProfitMargin)) || null,
          };
        }),
      };
    }
  );

  const transLabel = 'home:companydetails:finance:overview';

  const interestCoverageMessage =
    (data?.interestCoverage?.isEBITDANullOrZero &&
      `${transLabel}:interestcoverageratio:message:noearnings`) ||
    (data?.interestCoverage?.isInterestNullOrZero &&
      `${transLabel}:interestcoverageratio:message:nointerest`) ||
    (data?.interestCoverage?.interestCoverageRatio < 1 &&
      `${transLabel}:interestcoverageratio:message:lessthanone`) ||
    (data?.interestCoverage?.interestCoverageRatio >= 1 &&
      data?.interestCoverage?.interestCoverageRatio < 3 &&
      `${transLabel}:interestcoverageratio:message:oneandthree`) ||
    (data?.interestCoverage?.interestCoverageRatio >= 3 &&
      data?.interestCoverage?.interestCoverageRatio < 6 &&
      `${transLabel}:interestcoverageratio:message:threeandsix`) ||
    `${transLabel}:interestcoverageratio:message:abovesix`;

  const currentRatioMessage =
    (data?.currentRatio?.currentRatio < 0 && `${transLabel}:currentratio:message:lessthanzero`) ||
    (data?.currentRatio?.currentRatio >= 0 &&
      data?.currentRatio?.currentRatio < 1 &&
      `${transLabel}:currentratio:message:zerotoone`) ||
    (data?.currentRatio?.currentRatio >= 1 &&
      data?.currentRatio?.currentRatio < 2 &&
      `${transLabel}:currentratio:message:onetotwo`) ||
    `${transLabel}:currentratio:message:abovetwo`;

  const equityRatioMessage =
    (data?.equityRatio?.equityRatio < 0 && `${transLabel}:returnonequity:message:negative`) ||
    (data?.equityRatio?.limitedEquity &&
      ((data?.equityRatio?.equityRatio <= 70 && `${transLabel}:returnonequity:message:neutral`) ||
        `${transLabel}:returnonequity:message:positive`)) ||
    (data?.equityRatio?.equityRatio <= 30 && `${transLabel}:returnonequity:message:neutral`) ||
    `${transLabel}:returnonequity:message:positive`;

  return [
    {
      id: 'financial-info-overview-gross-profit-margin',
      label: `${transLabel}:grossprofitmargin:label`,
      description: `${transLabel}:grossprofitmargin:description`,
      graph: GraphTypesEnum.LINE,
      graphData: grossProfitData || [],
    },
    {
      id: 'financial-info-overview-income',
      label: `${transLabel}:income:label`,
      description: `${transLabel}:income:description`,
      graph: GraphTypesEnum.BAR,
      graphData: incomeData || [],
    },
    {
      id: 'financial-info-overview-profit',
      label: `${transLabel}:profit:label`,
      description: `${transLabel}:profit:description`,
      graph: GraphTypesEnum.BAR,
      graphData: profitData || [],
    },
    {
      id: 'financial-info-overview-interest-coverage-ratio',
      label: `${transLabel}:interestcoverageratio:label`,
      description: `${transLabel}:interestcoverageratio:description`,
      value:
        data?.interestCoverage?.interestCoverageRatio > 0
          ? roundTwoDecimal(data?.interestCoverage?.interestCoverageRatio)
          : 0,
      percentage: 100,
      isPositive: data?.interestCoverage?.interestCoverageRatio >= 3,
      graph: GraphTypesEnum.PERCENTAGE,
      message: interestCoverageMessage,
    },
    {
      id: 'financial-info-overview-current-ratio',
      label: `${transLabel}:currentratio:label`,
      description: `${transLabel}:currentratio:description`,
      percentage: 100,
      value:
        (data?.equityRatio?.equityRatio > 0 && roundTwoDecimal(data?.currentRatio?.currentRatio)) ||
        0,
      graph: GraphTypesEnum.PERCENTAGE,
      isPositive: data?.currentRatio?.currentRatio >= 1,
      message: currentRatioMessage,
    },
    {
      id: 'financial-info-overview-roe',
      label: `${transLabel}:returnonequity:label`,
      description: `${transLabel}:returnonequity:description`,
      percentage:
        (data?.equityRatio?.equityRatio > 0 && Math.round(data?.equityRatio?.equityRatio)) || 0,
      value:
        (data?.equityRatio?.equityRatio > 0 &&
          `${roundTwoDecimal(data?.equityRatio?.equityRatio)}%`) ||
        '0%',
      graph: GraphTypesEnum.PERCENTAGE,
      isPositive:
        (data?.equityRatio?.equityRatio > 70 && data?.equityRatio.limitedEquity) ||
        data?.equityRatio?.equityRatio > 30,
      message: equityRatioMessage,
    },
  ];
};

export const profitLossField: (data: any) => TablePropType = (data: any) => {
  const profitLossData = (Array.isArray(data) && data) || [];
  const headings =
    Array.isArray(data) &&
    data.map((statement: any) => {
      return {
        value: statement?.periodName,
        classname: {
          header: styles['th-header'],
          value: styles['header-label'],
        },
      };
    });

  return {
    header: [
      {
        value: `Currency = ${i18next.t('currency:symbol')}`,
        classname: {
          header: cn(styles['th-header']),
          value: styles['header-label-left'],
        },
      },
      ...(headings || []),
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: [
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Revenue',
          id: 'revenue',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.revenue?.value.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Revenue Growth %',
          id: 'revenueGrowthPercent',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults?.revenueGrowthPercent)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Direct Cost',
          id: 'directCosts',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.directCosts?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Gross Profit',
          id: 'grossProfit',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.grossProfit?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Gross Profit %',
          id: 'gpPercent',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.gpPercent)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Overheads',
          id: 'overheads',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.overheads)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'EBITDA',
          id: 'ebitda',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.ebitda?.toLocaleString(), { fallback: '-' }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'EBITDA %',
          id: 'ebitdaPercent',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.ebitdaPercent)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Depreciation',
          id: 'depreciation',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.depreciation)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Amortisation',
          id: 'amortisation',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.amortisation)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Impairments',
          id: 'impairments',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.impairments)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Operating Profit',
          id: 'operatingProfit',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.operatingProfit?.value?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Operating Profit %',
          id: 'operatingProfitPercent',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults?.operatingProfitPercent?.value)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Interest',
          id: 'interest',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.interest?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-subheading-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Profit Before Tax',
          id: 'profitBeforeTax',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.profitBeforeTax?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: cn(styles['tr-cell-subheading'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Tax',
          id: 'tax',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.tax?.toLocaleString(), { fallback: '-' }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Net Profit',
          id: 'netProfit',
        },
        ...profitLossData?.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.netProfit?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
    ],
  };
};

export const balanceSheetField: (data: any) => TablePropType = (data: any) => {
  const balanceSheetData = (Array.isArray(data) && data) || [];
  const headings = balanceSheetData.map((statement: any) => {
    return {
      value: statement?.periodName,
      classname: {
        header: styles['th-header'],
        value: styles['header-label'],
      },
    };
  });

  return {
    header: [
      {
        value: `Currency = ${i18next.t('currency:symbol')}`,
        classname: {
          header: styles['th-header'],
          value: styles['header-label-left'],
        },
      },
      ...(headings || []),
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: [
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Tangible Assets',
          id: 'tangibleAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.tangibleAssets)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Intangible Assets',
          id: 'intangibleAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.intangibleAssets)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Fixed Assets',
          id: 'fixedAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.fixedAssets?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(
              styles['tr-cell-subheading-label'],
              styles['tr-cell-border-bottom'],
              styles['tr-cell-border-top']
            ),
          },
          default: 'Non-Current Assets',
          id: 'nonCurrentAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.nonCurrentAssets?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: cn(
                styles['tr-cell-subheading'],
                styles['tr-cell-border-bottom'],
                styles['tr-cell-border-top']
              ),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Trade Debtors',
          id: 'tradeDebtors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.tradeDebtors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Stock',
          id: 'stock',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(roundTwoDecimal(periodResults.stock)?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Cash',
          id: 'cash',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(roundTwoDecimal(periodResults.cash)?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other Debtors',
          id: 'otherDebtors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.otherDebtors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Total Current Assets',
          id: 'totalCurrentAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.totalCurrentAssets?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Current Debt',
          id: 'currentDebt',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.currentDebt)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Trade Creditors',
          id: 'tradeCreditors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.tradeCreditors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other Creditors',
          id: 'otherCreditors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.otherCreditors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Total Current Liabilities',
          id: 'totalCurrentLiabilities',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.totalCurrentLiabilities?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Non Current Debt',
          id: 'nonCurrentDebt',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.nonCurrentDebt)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Non Current Trade Creditors',
          id: 'nonCurrentTradeCreditors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.nonCurrentTradeCreditors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other Non Current Creditors',
          id: 'otherNonCurrentCreditors',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.otherNonCurrentCreditors)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Non Current Liabilities',
          id: 'nonCurrentLiabilities',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.nonCurrentLiabilities?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(
              styles['tr-cell-subheading-label'],
              styles['tr-cell-border-bottom'],
              styles['tr-cell-border-top']
            ),
          },
          default: 'Net Assets',
          id: 'netAssets',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.netAssets?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: cn(
                styles['tr-cell-subheading'],
                styles['tr-cell-border-bottom'],
                styles['tr-cell-border-top']
              ),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Equity',
          id: 'equity',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(roundTwoDecimal(periodResults.equity)?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-label'],
          },
          default: 'Profit and Loss',
          id: 'profitAndLoss',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(
              roundTwoDecimal(periodResults.protitAndLoss)?.toLocaleString(),
              {
                fallback: '-',
              }
            ),
            classname: {
              cell: styles['tr-cell'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other',
          id: 'other',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(roundTwoDecimal(periodResults.other)?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Total Equity',
          id: 'totalEquity',
        },
        ...balanceSheetData.map((periodResults: any) => {
          return {
            default: displayStringValue(periodResults?.totalEquity?.toLocaleString(), {
              fallback: '-',
            }),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
    ],
  };
};

export const cashFlowProjectionFields: (data: CashFlowProjectionItems) => TablePropType = (
  data: CashFlowProjectionItems
) => {
  const cashflowProjectionData = (Array.isArray(data) && data) || [];
  const headings = cashflowProjectionData.map((item: CashFlowProjectionItem) => {
    return {
      value: getMonthName(item?.month) + ' ' + String(item?.year).slice(2),
      classname: {
        header: styles['th-header'],
        value: styles['header-label'],
      },
    };
  });

  return {
    header: [
      {
        value: '',
        classname: {
          header: styles['th-header'],
          value: styles['header-label-left'],
        },
      },
      ...(headings || []),
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: [
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Opening balance',
          id: 'openingBalance',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.openingBalance),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-subheading-label']),
          },
          default: 'Cash in',
          id: 'cashIn',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashIn?.total),
            classname: {
              cell: cn(styles['tr-cell-subheading']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Invoices',
          id: 'invoices',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashIn?.invoice),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Income',
          id: 'income',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashIn?.income),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other',
          id: 'cashInOther',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashIn?.other),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-subheading-label']),
          },
          default: 'Cash out',
          id: 'cashOut',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashOut?.total),
            classname: {
              cell: cn(styles['tr-cell-subheading']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Bills',
          id: 'bills',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashOut?.bills),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Expenses',
          id: 'expenses',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashOut?.expenses),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: cn(styles['tr-cell-label'], styles['tr-cell-border-bottom']),
          },
          default: 'Other',
          id: 'cashOutOther',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.cashOut?.other),
            classname: {
              cell: cn(styles['tr-cell'], styles['tr-cell-border-bottom']),
            },
          };
        }),
      ],
      [
        {
          classname: {
            cell: styles['tr-cell-subheading-label'],
          },
          default: 'Closing balance',
          id: 'closingBalance',
        },
        ...cashflowProjectionData.map((item: CashFlowProjectionItem) => {
          return {
            default: convertToCurrency(item?.closeBalance),
            classname: {
              cell: styles['tr-cell-subheading'],
            },
          };
        }),
      ],
    ],
  };
};
