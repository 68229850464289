import { TaskActionTypes, TaskState, UPDATE_TASK } from './types';

export const initialTaskState: TaskState = {
  modalContext: null,
  taskId: '',
  selectedTask: null,
  refreshTasks: () => {},
  activityData: [],
  pageNumber: 1,
  filter: {
    orderBy: '',
    direction: 'desc',
  },
  searchBy: '',
  selectedFilters: null,
  companyName: '',
};

export function tasksReducer(state: TaskState, action: TaskActionTypes): TaskState {
  switch (action.type) {
    case UPDATE_TASK: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
