import classNames from 'classnames';
import styles from './error.module.scss';

export default function ErrorComponent(props: { message: string }) {
  return (
    <main className={classNames(styles['error-container'])}>
      <div className={classNames(styles.error)}>
        <i className={classNames('material-icons')}>error</i>
        <span>{props.message}</span>
      </div>
    </main>
  );
}
