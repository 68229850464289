import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { isBrokerApplication } from '_shared/utils/application';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import styles from './AddUser.module.scss';
import { Role } from 'config/roles';

const defaultStyles = {
  control: styles['add-user-control'],
  error: '',
};

const swoopGroupBrokerLabelOrder = ['Broker', 'BrokerManager', 'Admin'];
const swoopGroupAdvisorLabelOrder = ['Advisor', 'AdvisorManager', 'ExternalAdmin'];
const swoopGroupLabelOrder = isBrokerApplication
  ? swoopGroupBrokerLabelOrder
  : swoopGroupAdvisorLabelOrder;

const swoopGroupLabelOrderFunction = (a: Role, b: Role) => {
  const firstLabel = swoopGroupLabelOrder.findIndex((x) => {
    return x === a.name;
  });
  const secondLabel = swoopGroupLabelOrder.findIndex((y) => {
    return y === b.name;
  });
  return firstLabel < secondLabel ? -1 : 1;
};

export const inviteBrokerFields = (roles: Role[]) => {
  return [
    {
      id: 'invite-broker-role',
      name: 'role',
      placeholder: 'Select Role',
      value: '',
      required: true,
      options: roles
        .filter((role: Role) => ['Broker', 'BrokerManager', 'ExternalAdmin'].includes(role.name))
        .map((role: Role) => {
          return {
            id: role.id,
            label:
              spaceBetweenTitleCaseWords(role.name) &&
              spaceBetweenTitleCaseWords(role.name.replace('External', '')),
            value: role.name,
          };
        }),
      fieldType: FieldTypes.SELECT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'invite-broker-email',
      name: 'email',
      placeholder: 'Email',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.EMAIL,
      type: 'email',
      classNames: {
        ...defaultStyles,
      },
    },
  ];
};

export const inviteAdvisorFields = (roles: Role[]) => {
  return [
    {
      id: 'invite-advisor-role',
      name: 'role',
      placeholder: 'Select Role',
      value: '',
      required: true,
      options: roles
        .filter((role: Role) => ['Advisor', 'AdvisorManager', 'ExternalAdmin'].includes(role.name))
        .sort(swoopGroupLabelOrderFunction)
        .map((role: Role) => {
          return {
            id: role.id,
            label:
              spaceBetweenTitleCaseWords(role.name) &&
              spaceBetweenTitleCaseWords(role.name.replace('External', '')),
            value: role.name,
          };
        }),
      fieldType: FieldTypes.SELECT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'invite-advisor-email',
      name: 'email',
      placeholder: 'Email',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.EMAIL,
      type: 'email',
      classNames: {
        ...defaultStyles,
      },
    },
  ];
};

export const inviteGroupFields = () => {
  return [
    {
      id: 'demo-group-nb',
      name: 'demoGroup',
      fieldType: FieldTypes.READONLY,
      label: '*N.B. - If this is a demo account, please make the group name "DEMO - Group X"',
      classNames: {
        label: styles['add-user-label'],
      },
    },
    {
      id: 'invite-group-name',
      name: 'groupName',
      placeholder: 'Group Name',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'invite-group-email',
      name: 'email',
      placeholder: 'Email',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.EMAIL,
      type: 'email',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'application-type',
      name: 'applicationType',
      placeholder: 'ApplicationType',
      value: '',
      required: true,
      options: [
        {
          label: 'Broker application',
          value: 'BrokerApplication',
        },
        {
          label: 'Advisor application',
          value: 'AdvisorApplication',
        },
      ],
      fieldType: FieldTypes.SELECT,
      classNames: {
        ...defaultStyles,
      },
    },
  ];
};
