import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import GroupManagement from '.';

export const groupManagementRoutes: RouteObject[] = [
  {
    path: RoutePath.groupManagement,
    element: withTransaction('GroupManagement', 'component')(<GroupManagement />),
  },
];
