import cn from 'classnames';
import styles from 'pages/documentLibrary/assets/documentLibrary.module.scss';
import { useTranslation } from 'react-i18next';
import Upload from 'components/field/upload';
import { AmplitudeTrackingEnum } from 'config/amplitude';
import { NewDocumentType, PdfTypesEnum } from 'pages/documentLibrary/types';
import NewDocument from '../newDocument';
import Spinner from 'components/spinner';
import UploadDocumentHook from '../../hooks/UploadDocument.hook';
import BlockNavigationModal from 'components/BlockNavigationModal';
import features from 'config/features';
import { UploadedDocumenType } from '../newDocument/types';
import React, { createRef, useEffect, useState } from 'react';

const UploadDocument = ({
  companyId,
  fetchDocumentList,
  documentTitle,
  documentCategory,
  uploadedDocuments,
}: {
  companyId: string;
  fetchDocumentList: Function;
  documentTitle?: string;
  documentCategory?: string;
  uploadedDocuments?: UploadedDocumenType[];
}) => {
  const [savingDocument, setSavingDocument] = useState(false);
  const [postDocumentAPIHasFinished, setPostDocumentsHasFinished] = useState(0);

  const { t } = useTranslation();
  const uploadedDocumentRef: any = React.useRef([]);

  const { state, uploadFiles, updateTransactions, handleNewFileSubmit, handleCancelDocument } =
    UploadDocumentHook({
      companyId,
      fetchDocumentList,
    });

  uploadedDocumentRef.current = state.newDocuments.map(
    (_, i) => uploadedDocumentRef.current[i] ?? createRef()
  );

  let referenceOfUploadedDocumentsByFileName: string[] = [];

  const handleSavingMultipleDocuments = () => {
    setSavingDocument(true);
    // eslint-disable-next-line array-callback-return
    state.newDocuments.map((newDocument: NewDocumentType) => {
      for (let i = 0; i < state.newDocuments.length; i++) {
        const latestDocumentState: any = returnLatestDocumentState(i);
        if (isDocumentNotADuplicateByGivenFileName(latestDocumentState?.fileName)) {
          saveDocumentAndStoreFileNameRefrence(latestDocumentState, i);
        }
      }
    });
  };

  const returnLatestDocumentState = (currentuploadedDocumentRefIndex: number) => {
    let latestDocumentState: any =
      uploadedDocumentRef.current[
        currentuploadedDocumentRefIndex
      ].current?.returnsMostReccentDocumentState();
    return latestDocumentState;
  };

  const isDocumentNotADuplicateByGivenFileName = (fileNameToCheckIfDuplicate: string) => {
    if (referenceOfUploadedDocumentsByFileName.indexOf(fileNameToCheckIfDuplicate) <= -1) {
      return true;
    } else {
      return false;
    }
  };

  const saveDocumentAndStoreFileNameRefrence = (
    latestDocumentState: any,
    currentuploadedDocumentRefIndex: number
  ) => {
    uploadedDocumentRef.current[currentuploadedDocumentRefIndex].current?.newDocumentSave(
      latestDocumentState,
      currentuploadedDocumentRefIndex
    );

    if (latestDocumentState) {
      referenceOfUploadedDocumentsByFileName.push(latestDocumentState.fileName);
    }
  };

  useEffect(() => {
    if (state.newDocuments.length === 0 || Boolean(referenceOfUploadedDocumentsByFileName)) {
      setSavingDocument(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDocumentAPIHasFinished]);

  return (
    <>
      <h3 className={cn('sw-h3', styles['upload-header'])}>
        {t(`home:companydetails:documentlibrary:upload:title`)}
      </h3>
      <div className={cn(styles['upload-container'])}>
        <Upload
          handleChange={uploadFiles}
          name="document-upload"
          label="Document Upload"
          className={{ control: styles.upload }}
          hideFileValues={true}
          tracking={AmplitudeTrackingEnum.uploaddocument}
          toolTipContent={t('home:companydetails:documentlibrary:upload:documentuploadfiletooltip')}
          acceptedFileTypes={'image/*,.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx'}
          multiple={true}
        />
        {features.bankIntegrationEnabled && (
          <button
            className={cn(
              styles['update-button'],
              !state.financialStatus[PdfTypesEnum.TRUELAYER] && styles['update-button-tooltip'],
              state.financialStatus[PdfTypesEnum.TRUELAYER] && styles.active
            )}
            data-label={t('home:companydetails:documentlibrary:upload:truelayertooltip')}
            onClick={() => updateTransactions(PdfTypesEnum.TRUELAYER)}
            disabled={!state.financialStatus[PdfTypesEnum.TRUELAYER]}
          >
            {(state.updatingTransactions.trueLayer && <Spinner size="alternate" />) || (
              <>
                <span className="material-icons">picture_as_pdf</span>
                <span>Update</span>
                <span>{t('home:companydetails:documentlibrary:upload:updatetruelayer')}</span>
              </>
            )}
          </button>
        )}
      </div>
      {state.newDocuments.length === 0 ? (
        <></>
      ) : (
        <div className={cn(styles['document-upload-headers'])}>
          <span>
            {t('home:companydetails:documentlibrary:upload:newdocumentupload:categoryheader')}
          </span>
          <span>
            {t('home:companydetails:documentlibrary:upload:newdocumentupload:titleheader')}
          </span>
          <span>
            {t('home:companydetails:documentlibrary:upload:newdocumentupload:filenameheader')}
          </span>
          <button onClick={handleSavingMultipleDocuments}>
            {(savingDocument && <Spinner size="small" />) || 'Save'}
          </button>
        </div>
      )}
      {state.newDocuments.map((newDocument: NewDocumentType, i) => {
        return (
          <NewDocument
            key={newDocument.id}
            {...{
              ...newDocument,
              companyId: companyId || '',
              save: handleNewFileSubmit,
              ...((documentTitle && { selectedTitle: documentTitle }) || {}),
              ...((documentCategory && { selectedCategory: documentCategory }) || {}),
              uploadedDocuments,
            }}
            ref={uploadedDocumentRef.current[i]}
            callback={setPostDocumentsHasFinished}
            onCancel={() => {
              handleCancelDocument(newDocument.id);
            }}
          />
        );
      })}
      <BlockNavigationModal shouldBlock={state.newDocuments?.length > 0} />
    </>
  );
};

export default UploadDocument;
