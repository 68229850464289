import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../assets/login.module.scss';
import favicon from 'assets/images/swoop-favicon.svg';
import BrokerPortalLogo from 'assets/images/swoop-for-brokers.svg';
import AdvisorPortalLogo from 'assets/images/swoop-for-advisors.svg';
import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';

const LoginSidebar = () => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.welcome)}>
      <img src={favicon} alt="Swoop Favicon" />
      <div className={styles.greeting}>
        <p>{t('login:title')}</p>
        {isBrokerApplication && <img src={BrokerPortalLogo} alt="Broker Portal Logo" />}
        {isAdvisorApplication && <img src={AdvisorPortalLogo} alt="Advisor Portal Logo" />}
      </div>
    </div>
  );
};

export default LoginSidebar;
