import { OfferTypes } from 'pages/deals/types';
import { TOption } from '_shared/utils/form';
import { numberWithCommas } from '_shared/utils/currency';

export const availableProducts = (
  products: Record<string, number | string>[],
  isSwoopGroupUser: boolean
) => {
  const nonSwoopUserProducts = products.filter((product: Record<string, number | string>) => {
    return product.type !== OfferTypes.EQUITY;
  });

  const groupAvailableProducts = isSwoopGroupUser ? products : nonSwoopUserProducts;

  return groupAvailableProducts;
};

export const parseMultiSelect = (value: string) => {
  try {
    const values = JSON.parse(value);
    return values.map((option: TOption) => option.value);
  } catch (error) {
    return (value && String(value)?.split(',')) || [];
  }
};

export const parseDatePicker = (value: string) => {
  const date = new Date(value);
  return String(date) === 'Invalid Date' || !String(value).length ? null : date;
};

export const formatCurrency = (value: string) => {
  return value && String(value).includes(',') ? value : numberWithCommas(String(value));
};
