import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { useEffect, useReducer } from 'react';
import { useAppState } from 'store';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import {
  getApplication,
  getLinkedApplicationsList,
  postSubmitApplication,
} from '_shared/api/applications';
import { getCompanyDocuments } from '_shared/api/documents';
import { getFundingMatchesDebt } from '_shared/api/fundingMatches';
import { getApplicationFormProduct } from '_shared/api/products';
import {
  ActionType,
  productApplicationReducer,
  ProductApplicationType,
} from '../store/productApplication.reducer';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ProductApplicationHook = ({
  companyId,
  productId,
}: {
  companyId: string;
  productId: string;
}) => {
  const [state, dispatch] = useReducer(productApplicationReducer, {
    requiredDocuments: [],
    uploadedDocuments: [],
    productDocuments: [],
    linkedApplications: [],
    application: {},
    product: {},
    loading: {
      requiredDocuments: false,
      application: false,
    },
    error: {
      requiredDocuments: false,
      application: false,
    },
    isSubmittingToLenders: false,
    documentUploadModal: {
      documentUploadModalOpen: false,
      title: '',
      category: '',
    },
  } as ProductApplicationType);
  const store = useAppState();
  const { t } = useTranslation();

  const fetchDocumentList = async (id: string) => {
    const { data } = await getCompanyDocuments(companyId);
    dispatch({
      type: ActionType.SET_UPLOADED_DOCUMENTS,
      payload: data,
    });
  };

  const fetchProduct = async (id: string) => {
    const { data } = await getApplicationFormProduct(productId);
    dispatch({
      type: ActionType.SET_PRODUCT,
      payload: data,
    });
  };

  const fetchApplication = async (applicationId: string) => {
    try {
      const { data } = await getApplication(companyId, applicationId);
      dispatch({
        type: ActionType.SET_APPLICATION,
        payload: {
          ...data,
          applicationId,
          id: applicationId,
        },
      });
    } catch (e) {
      dispatch({
        type: ActionType.SET_ERROR,
        payload: { application: true },
      });
    }
  };

  const getLinkedApplications = async (companyId: string) => {
    const { data: linkedApplications } = await getLinkedApplicationsList(companyId, false);

    dispatch({
      type: ActionType.SET_LINKED_APPLICATIONS,
      payload: linkedApplications,
    });

    return linkedApplications;
  };

  const fetchDocumentRequiredData = async () => {
    try {
      return await Promise.all([
        getLinkedApplications(companyId),
        fetchDocumentList(companyId),
        fetchProduct(productId),
      ]);
    } catch (e) {
      dispatch({
        type: ActionType.SET_ERROR,
        payload: {
          application: true,
        },
      });
    }
  };

  const fetchData = async () => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          requiredDocuments: true,
          application: true,
        },
      });
      const data = await fetchDocumentRequiredData();

      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          requiredDocuments: false,
        },
      });

      const linkedApplications = (data?.length && data[0]) || [];
      const application = linkedApplications
        // @ts-ignore
        .sort((a: any, b: any) => new Date(b.dateModified) - new Date(a.dateModified))
        .find((linkedApplication: any) => linkedApplication.productId === productId);

      if (application) {
        dispatch({
          type: ActionType.SET_APPLICATION,
          payload: {
            applicationId: application.applicationId,
            id: application.applicationId,
          },
        });
        await fetchApplication(application.applicationId);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          application: false,
          requiredDocuments: false,
        },
      });
    }
  };

  useEffect(() => {
    if (!companyId || !productId) return;
    // loading documents
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, productId]);

  const handleSubmission = async () => {
    dispatch({
      type: ActionType.SET_IS_SUBMITTING_TO_LEADERS,
      payload: true,
    });
    try {
      const {
        data: { fundingRequired },
      } = await getFundingMatchesDebt(companyId);
      await postSubmitApplication(companyId, productId, {
        documents: state.requiredDocuments?.map((doc) => {
          return {
            id: doc.uploadedDocumentId,
            name: doc.uploadedDocumentName,
          };
        }),
        currency: i18next.t('currency:symbol'),
        amountRequested: fundingRequired || 0,
        applicationId: state.application.applicationId,
        productId,
      });

      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:deals:dealdetailsmodal:toast:applicationformsubmitted') },
      });

      sendAmplitudeData(AmplitudeTrackingEnum.submitapplicationtolender, {
        productId,
        productName: state.product?.productName,
      });
    } catch (error) {
      console.error(error);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t('home:deals:dealdetailsmodal:toast:applicationformfailedsubmitted'),
        },
      });
    } finally {
      dispatch({
        type: ActionType.SET_IS_SUBMITTING_TO_LEADERS,
        payload: false,
      });
    }
  };

  return {
    state,
    dispatch,
    handleSubmission,
    fetchDocumentList,
  };
};

export default ProductApplicationHook;
