import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  useForm,
  SubmitHandler,
  Controller,
  UseFormClearErrors,
  FieldErrors,
  Control,
  UseFormWatch,
} from 'react-hook-form';
import styles from './DealInfoForm.module.scss';
import TextInput from 'components/v2/TextInput/TextInput';
import Select from 'components/v2/Select/Select';
import CurrencyInput from 'components/v2/CurrencyInput/CurrencyInput';
import Datepicker from 'components/v2/DatePicker/DatePicker';
import Radio from 'components/v2/Radio/Radio';
import RadioGroup from 'components/v2/RadioGroup/RadioGroup';
import { dealTypesWithoutSubtypesValue } from '../../constant';
import { closedLostReasons, dealsStatusList, dealStatusLabels } from '_shared/utils/constants';
import { formatCurrency } from '_shared/utils/currency';
import { calculateNetFee, convertType } from '../../utils';
import { convertToLocalDateFormat } from '_shared/utils/date';
import Button from 'components/button';
import { Deal, DealStatus, DealTypes } from 'pages/deals/types';
import { getDealFieldValue } from '_shared/utils/deals';
import { APIUsersByRoleType } from 'pages/users/type';
import useDealTypes from '_shared/hooks/useDealTypes';

export interface IFormInput {
  Type: string;
  Owner: string;
  OwnerName: string;
  OwnerPhotoUrl: string;
  Subtype: string;
  Amount: string;
  Stage: DealStatus;
  FunderComission: string;
  ClosedDate: string;
  ClientFee: string;
  IntroducerFee: string;
  ValuerDetails: string;
  RebrokeOpportunity: 'true' | 'false';
  RebrokeDate: string;
  ClosedLostReason: string;
  ClosedLostDetail: string;
  WorthRevisiting: 'true' | 'false';
  RevisitDate: string;
}

type DealInfoFormProps = {
  deal: Deal;
  users: APIUsersByRoleType[];
  values: IFormInput;
  lastModifiedDate: string;
  isSaving?: boolean;
  advisorView?: boolean;
  onSave: SubmitHandler<IFormInput>;
  onViewStatusHistory: () => void;
};

type ClosedFieldsProps = {
  advisorView: boolean;
  values: IFormInput;
  control: Control<IFormInput>;
  errors: FieldErrors<IFormInput>;
  clearErrors: UseFormClearErrors<IFormInput>;
  watch: UseFormWatch<IFormInput>;
};

const ClosedWonFields: React.FC<ClosedFieldsProps> = ({
  advisorView,
  values,
  control,
  errors,
  watch,
}) => {
  const hasRebroke = watch('RebrokeOpportunity') === 'true';

  return (
    <>
      <div className={styles.field}>
        <label>Rebroke Opportunity</label>
        {advisorView ? (
          <span>{values.RebrokeOpportunity}</span>
        ) : (
          <Controller
            name="RebrokeOpportunity"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                radioClassName={cn({ [styles.error]: Boolean(errors['RebrokeOpportunity']) })}
              >
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </RadioGroup>
            )}
          />
        )}
      </div>
      {hasRebroke &&
        (advisorView ? (
          <div className={styles.field}>
            <label>Rebroke Date</label>
            <span>{convertToLocalDateFormat(values.RebrokeDate)}</span>
          </div>
        ) : (
          <div className={styles.field}>
            <label htmlFor="deal-info-form-rebroke-date">Rebroke Date</label>
            <Controller
              name="RebrokeDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  id="deal-info-form-rebroke-date"
                  className={cn({ [styles.error]: Boolean(errors['RebrokeDate']) })}
                />
              )}
            />
          </div>
        ))}
    </>
  );
};

const ClosedLostFields: React.FC<ClosedFieldsProps> = ({
  advisorView,
  values,
  control,
  errors,
  watch,
  clearErrors,
}) => {
  const worthRevisiting = watch('WorthRevisiting') === 'true';
  const isOtherClosedLostReason = watch('ClosedLostReason') === 'Other';

  return (
    <>
      <label>
        Closed Lost Reason
        {advisorView ? (
          <span>{values.ClosedLostReason}</span>
        ) : (
          <Controller
            name="ClosedLostReason"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={closedLostReasons}
                placeholder="Select reason"
                className={cn({ [styles.error]: Boolean(errors['ClosedLostReason']) })}
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors('ClosedLostDetail');
                }}
              />
            )}
          />
        )}
      </label>
      <label>
        Closed Lost Detail
        {advisorView ? (
          <span>{values.ClosedLostDetail}</span>
        ) : (
          <Controller
            name="ClosedLostDetail"
            control={control}
            rules={{ required: isOtherClosedLostReason }}
            render={({ field }) => (
              <TextInput
                {...field}
                className={cn({ [styles.error]: Boolean(errors['ClosedLostDetail']) })}
              />
            )}
          />
        )}
      </label>
      <div className={styles.field}>
        <label>Worth Revisiting</label>
        {advisorView ? (
          <span>{values.WorthRevisiting === 'true' ? 'Yes' : 'No'}</span>
        ) : (
          <Controller
            name="WorthRevisiting"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                radioClassName={cn({ [styles.error]: Boolean(errors['WorthRevisiting']) })}
              >
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </RadioGroup>
            )}
          />
        )}
      </div>
      {worthRevisiting &&
        (advisorView ? (
          <div className={styles.field}>
            <label>Revisit Date</label>
            <span>{convertToLocalDateFormat(values.RevisitDate)}</span>
          </div>
        ) : (
          <div className={styles.field}>
            <label htmlFor="deal-info-form-revisit-date">Revisit Date</label>
            <Controller
              name="RevisitDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  id="deal-info-form-revisit-date"
                  className={cn({ [styles.error]: Boolean(errors['RevisitDate']) })}
                />
              )}
            />
          </div>
        ))}
    </>
  );
};

const DealInfoForm: React.FC<DealInfoFormProps> = ({
  deal,
  users,
  values,
  lastModifiedDate,
  isSaving = false,
  advisorView = false,
  onSave,
  onViewStatusHistory,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    reset,
    formState: { isDirty, errors },
  } = useForm<IFormInput>({ values, mode: 'all' });

  const type = watch('Type');
  const funderCommission = watch('FunderComission');
  const clientFee = watch('ClientFee');
  const introducerFee = watch('IntroducerFee');
  const status = watch('Stage');

  const { types, subtypesByType } = useDealTypes();

  const netFee = useMemo(
    () =>
      calculateNetFee(
        parseFloat(funderCommission),
        parseFloat(clientFee),
        parseFloat(introducerFee)
      ),
    [funderCommission, clientFee, introducerFee]
  );

  const usersById = useMemo<Record<string, APIUsersByRoleType>>(() => {
    const result = users.reduce<Record<string, APIUsersByRoleType>>(
      (acc, user) => ({
        ...acc,
        [user.securityId]: user,
      }),
      {}
    );

    if (!deal) return result;

    const ownerId = getDealFieldValue(deal, 'Opportunity.Owner');
    const ownerName = getDealFieldValue(deal, 'Opportunity.OwnerName');
    const ownerPhotoUrl = getDealFieldValue(deal, 'Opportunity.OwnerPhotoUrl');

    if (ownerId && !result[ownerId]) {
      result[ownerId] = {
        userId: ownerId,
        securityId: ownerId,
        fullName: ownerName || ownerId,
        picture: ownerPhotoUrl ?? '',
      };
    }

    return result;
  }, [users, deal]);

  const ownerOptions = useMemo(() => {
    const options: Array<{ label: string; value: string }> = Object.values(usersById).map(
      (user: any) => ({
        label: user.fullName,
        value: user.securityId,
      })
    );

    // For non-auth0 users, their IDs can only be matched to the owner field by their userId property
    // So, the options produced above will not include these users, and therefore we need to add them manually
    if (values.Owner !== '' && !values.Owner.startsWith('auth0')) {
      const user = users.find((user) => user.userId === values.Owner);
      const isAlreadyAdded = options.some((option) => option.value === values.Owner);

      if (user && !isAlreadyAdded) {
        options.push({ label: user.fullName!, value: values.Owner });
      }
    }

    return options.sort((a, b) => a.label.localeCompare(b.label));
  }, [users, usersById, values.Owner]);

  const subtypeOptions = useMemo(() => {
    const options =
      subtypesByType[type as DealTypes]?.map(({ label, value }) => ({
        label,
        value,
      })) ?? [];

    return options.sort((a, b) => a.label.localeCompare(b.label));
  }, [type, subtypesByType]);

  const getUserNameById = (userId: string) => {
    return usersById[userId]?.fullName ?? '';
  };

  const subtypeLabelById = (subtypeId: string) => {
    const subtype = subtypeOptions.find((subtype: any) => subtype.value === subtypeId);
    return subtype?.label ?? '';
  };

  // Reset form when values change to populate the form with the new values
  useEffect(() => reset(values), [values]);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <fieldset className={styles.fieldset}>
        <label>
          Type
          <span>{convertType(values.Type)}</span>
        </label>
        <label>
          Owner
          {advisorView ? (
            <span>{getUserNameById(values.Owner)}</span>
          ) : (
            <Controller
              name="Owner"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={ownerOptions}
                  placeholder="Select owner"
                  className={cn({ [styles.error]: Boolean(errors['Owner']) })}
                  onChange={(e) => {
                    const user = usersById[e.target.value];

                    field.onChange(e);
                    setValue('OwnerName', user.fullName!);
                    setValue('OwnerPhotoUrl', user.picture!);
                  }}
                />
              )}
            />
          )}
        </label>
        <label>
          Subtype
          <span>
            {dealTypesWithoutSubtypesValue.includes(type)
              ? 'N/A'
              : subtypeLabelById(values.Subtype)}
          </span>
        </label>
        <label>
          Amount
          {advisorView ? (
            <span>{formatCurrency(values.Amount, t('currency:symbol'))}</span>
          ) : (
            <Controller
              name="Amount"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  prefix={t('currency:symbol')}
                  precision={2}
                  className={cn({ [styles.error]: Boolean(errors['Amount']) })}
                />
              )}
            />
          )}
        </label>
        <div className={styles.field}>
          <div className={styles['field-label-container']}>
            <label htmlFor="deal-info-form-status">Status</label>
            <button
              className={cn(styles['history-icon'], styles['tooltip'])}
              data-label={t('home:statushistorymodal:tooltip')}
              onClick={(e) => {
                e.preventDefault();
                onViewStatusHistory();
              }}
            >
              <i className="material-icons">history</i>
            </button>
          </div>
          {advisorView ? (
            <span>{dealStatusLabels[values.Stage]}</span>
          ) : (
            <Controller
              name="Stage"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  id="deal-info-form-status"
                  options={Object.values(dealsStatusList)}
                  placeholder="Select status"
                  className={cn({ [styles.error]: Boolean(errors['Stage']) })}
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.value === DealStatus.ClosedWon) {
                      setValue('ClosedDate', new Date().toISOString().split('T')[0]);
                    } else {
                      setValue('ClosedDate', values.ClosedDate);
                    }
                  }}
                />
              )}
            />
          )}
        </div>
        {!advisorView && (
          <label>
            Funder Commission
            <Controller
              name="FunderComission"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  prefix={t('currency:symbol')}
                  precision={2}
                  className={cn({ [styles.error]: Boolean(errors['FunderComission']) })}
                />
              )}
            />
          </label>
        )}
        {!advisorView && (
          <div className={styles.field}>
            <label htmlFor="deal-info-form-close-date">Estimated Close Date</label>
            <Controller
              name="ClosedDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Datepicker
                  {...field}
                  id="deal-info-form-close-date"
                  className={cn({ [styles.error]: Boolean(errors['ClosedDate']) })}
                />
              )}
            />
          </div>
        )}
        {!advisorView && (
          <label>
            Client Fee
            <Controller
              name="ClientFee"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  prefix={t('currency:symbol')}
                  precision={2}
                  className={cn({ [styles.error]: Boolean(errors['ClientFee']) })}
                />
              )}
            />
          </label>
        )}
        <label>
          Last Modified Date<span>{convertToLocalDateFormat(lastModifiedDate)}</span>
        </label>
        <label>
          Introducer Fee
          {advisorView ? (
            <span>{formatCurrency(values.IntroducerFee, t('currency:symbol'))}</span>
          ) : (
            <Controller
              name="IntroducerFee"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  prefix={t('currency:symbol')}
                  precision={2}
                  className={cn({ [styles.error]: Boolean(errors['IntroducerFee']) })}
                />
              )}
            />
          )}
        </label>
        <label>
          Valuer Details
          {advisorView ? (
            <span>{values.ValuerDetails}</span>
          ) : (
            <Controller
              name="ValuerDetails"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  className={cn({ [styles.error]: Boolean(errors['ValuerDetails']) })}
                />
              )}
            />
          )}
        </label>
        {!advisorView && (
          <label>
            Net Fee
            <span>{formatCurrency(netFee, t('currency:symbol'), 2)}</span>
          </label>
        )}
        {status === DealStatus.ClosedWon && (
          <ClosedWonFields
            advisorView={advisorView}
            control={control}
            values={values}
            errors={errors}
            clearErrors={clearErrors}
            watch={watch}
          />
        )}

        {status === DealStatus.ClosedLost && (
          <ClosedLostFields
            advisorView={advisorView}
            control={control}
            values={values}
            errors={errors}
            clearErrors={clearErrors}
            watch={watch}
          />
        )}
      </fieldset>

      <div className={styles.footer}>
        {isDirty && (
          <Button type="submit" loading={isSaving}>
            {t('home:deals:dealdetailsmodal:button:savebutton')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default DealInfoForm;
