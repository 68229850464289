export type NewDocumentTypeProps = {
  companyId: string;
  id: string;
  selectedCategory: string;
  selectedTitle: string;
  fileName: string;
  file: File;
  save: any;
  uploadedDocuments?: UploadedDocumenType[];
  callback?: Function;
  onCancel: () => void;
};

export enum NewDocumentFieldEnum {
  CATEGORY = 'Category',
  TITLE = 'Title',
  FILENAME = 'Filename',
}

export type UploadedDocumenType = {
  documentId: string;
  documentSize: number;
  name: string;
  title: string;
  type: string;
  uploadedDate: string;
};
