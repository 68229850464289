import React, { MutableRefObject } from 'react';

// References:
// https://mayursinhsarvaiya.medium.com/how-to-merge-refs-in-react-component-d5e4623b6924
// https://rooks.vercel.app/docs/useForkRef

type ForwardedRef<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export function mergeRefs<T = any>(...refs: ForwardedRef<T>[]): React.RefCallback<T> {
  return (node: T) => {
    for (const ref of refs) {
      if (ref) {
        if (typeof ref === 'function') ref(node);
        if ('current' in ref) ref.current = node;
      }
    }
  };
}
