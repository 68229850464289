import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';

export const businessInsuranceFormFields = [
  {
    id: 'insurancePolicyRequested',
    label: 'What insurance policy is your client looking for?',
    fieldType: FieldTypes.TEXTAREA,
    validationtype: ValidationTypes.TEXT,
    hasCustomRequiredErrorMessage: true,
    required: true,
    name: 'insurancePolicyRequested',
    value: '',
  },
  {
    id: 'insuranceStartDate',
    label: 'When is your client looking to take out this insurance',
    fieldType: FieldTypes.RADIO,
    validationtype: ValidationTypes.RADIO,
    hasCustomRequiredErrorMessage: false,
    required: true,
    name: 'insuranceStartDate',
    options: [
      {
        label: 'As soon as possible',
        value: 'As soon as possible',
        name: 'As soon as possible',
        parentId: 'insuranceStartDate',
      },
      {
        label: "I don't know",
        value: "I don't know",
        name: "I don't know",
        parentId: 'insuranceStartDate',
      },
      {
        label: "When I'm due for renewal",
        value: "When I'm due for renewal",
        name: "When I'm due for renewal",
        parentId: 'insuranceStartDate',
      },
    ],
    value: '',
  },
];
