import { useEffect, useReducer } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  getFundingForm,
  getFundingFormV2,
  putFundingForm,
  getFundingMatchesDebt,
  getFundingForms,
  putDynamicFundingFormFields,
  getCompletedFundingForms,
  deleteDynamicFundingForm,
  putFundingFormV2,
} from '_shared/api/fundingMatches';
import validation from '_shared/fieldValidation';
import {
  fundingMatchesReducer,
  FundingRequirementsActionType,
  FundingRequirementsType,
  TTab,
} from '../store/fundingRequirements.reducer';
import { ActiveTabs } from '../../companyOverview/components/fundingRequirements/types';
import useForm from '_shared/hooks/useForm';
import { useAppState } from 'store';
import { TOAST_MESSAGE, TOAST_ERROR_MESSAGE } from 'store/toast/types';
import { useTranslation } from 'react-i18next';
import {
  formatGoalsFormsValues,
  generateFundingFields,
  generateFundingFieldValues,
  TOption,
} from '_shared/utils/form';
import { LoanRequirementsFields } from '../fields';
import { fundingPurposes, fundingRequiredTimescale } from '_shared/utils/constants';
import { parseStringValues } from 'pages/fundingMatches/utils';
import { getCompanyScoreHistoryByForm } from '_shared/api/formScore';
import { convertToLocalDateTime } from '_shared/utils/date';
import { ScoreGoalsType } from '../types';
import { SWOOP_GROUP_ID } from '_shared/utils/application';
import { useApplication } from '_shared/hooks/useApplication';
import { QueryKeys } from '_shared/api/businesses';
import { env } from '_shared/utils';

export const fetchFormData = async (version: string, activeTab: string, companyId: string) => {
  const { data } =
    version === 'v2'
      ? await getFundingFormV2(activeTab, companyId)
      : await getFundingForm(activeTab, companyId);
  return version === 'v2' ? data.sections : data;
};

const FundingRequirementsHook = (companyId: string, defaultTab?: string) => {
  const initialState = {
    [ActiveTabs.LOANS]: {
      fields: [],
      data: {},
      error: false,
      loading: true,
      actionType: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
      formId: ActiveTabs.LOANS,
    },
    [ActiveTabs.DYNAMIC]: {
      fields: [],
      data: {},
      error: false,
      loading: true,
      actionType: FundingRequirementsActionType.SET_DYNAMIC_FORM,
      formId: null,
    },
    formOptions: undefined,
    tabs: [],
    completedFundingForms: [],
    activeTab: defaultTab ?? null,
    activeTabDetails: {},
    isSaving: false,
    ableToSave: false,
    formMigrationModalOpen: false,
    scoreGoals: [],
  } as FundingRequirementsType;

  const [state, dispatch] = useReducer(fundingMatchesReducer, initialState);
  const { state: globalState, dispatch: globalDispatch } = useAppState();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const activeTab = state[state.activeTab as ActiveTabs] || state[ActiveTabs.DYNAMIC];
  const currentTabData = state[state.activeTab as ActiveTabs] || state[ActiveTabs.DYNAMIC];
  const { isBrokerApplication } = useApplication();
  const isSwoopGroupBroker = isBrokerApplication && globalState.system.groupId === SWOOP_GROUP_ID;

  const fetchDebtRequirements = async (id: string) => {
    try {
      const { data } = await getFundingMatchesDebt(id);

      if (state.activeTab === ActiveTabs.LOANS) {
        dispatch({
          type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
          payload: { fields: LoanRequirementsFields(data), data },
        });
      }

      if (data?.fundingPurposes?.length) {
        dispatch({
          type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
          payload: { enabled: true },
        });

        dispatch({
          type: FundingRequirementsActionType.SET_ACTIVE_TAB,
          payload: ActiveTabs.LOANS,
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
        payload: { loading: false, error: true },
      });
    } finally {
      dispatch({
        type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
        payload: { loading: false },
      });
    }
  };

  const getForm = async () => {
    try {
      if (state.activeTab === ActiveTabs.LOANS || !state.activeTab) {
        await fetchDebtRequirements(companyId);
      } else {
        dispatch({
          type: activeTab.actionType,
          payload: { loading: true },
        });

        const version = env('REACT_APP_DYNAMIC_FORMS_ENDPOINTS_VERSION');
        const formData = await fetchFormData(version, state.activeTab, companyId);

        const form =
          version === 'v2'
            ? {
                displayName: formData[0]?.displayName,
                formId: formData[0]?.formId,
                formSections: formData,
                name: formData[0]?.name,
              }
            : formData;

        const error = !form.formSections?.length;
        dispatch({
          type: FundingRequirementsActionType.SET_DYNAMIC_FORM,
          payload: { error: error },
        });

        if (!error) {
          dispatch({
            type: activeTab.actionType,
            payload: {
              formId: form?.formId,
              form: form,
              fields: generateFundingFields(form),
              data: generateFundingFieldValues(form),
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: activeTab.actionType,
        payload: { error: true },
      });
    } finally {
      dispatch({
        type: activeTab.actionType,
        payload: { loading: false },
      });
    }
  };

  const getScoreGoals = async () => {
    if (!companyId || !currentTabData.formId) return;

    try {
      const { data } = await getCompanyScoreHistoryByForm(companyId, currentTabData.formId);
      const formattedCompanyScores = data
        .map((obj: ScoreGoalsType) => ({
          ...obj,
          periodStart: convertToLocalDateTime(obj.periodStart),
        }))
        .sort(
          (a: ScoreGoalsType, b: ScoreGoalsType) =>
            Date.parse(b.periodStart) - Date.parse(a.periodStart)
        );

      dispatch({
        type: FundingRequirementsActionType.SET_SCORE_GOALS,
        payload: formattedCompanyScores || [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const selectTab = (selectedTab: string) => {
    dispatch({
      type: FundingRequirementsActionType.SET_ACTIVE_TAB,
      payload: selectedTab,
    });

    if (selectedTab !== ActiveTabs.LOANS) {
      dispatch({
        type: FundingRequirementsActionType.SET_DYNAMIC_FORM,
        payload: {
          formId: state.tabs.find((tab: TTab) => tab.formId === selectedTab)?.formId,
        },
      });
    }
  };

  const saveSuccess = () => {
    globalDispatch({
      type: TOAST_MESSAGE,
      payload: { toastMessage: t('home:companydetails:overview:fundingrequirements:success') },
    });
  };

  const saveFailed = () => {
    globalDispatch({
      type: TOAST_ERROR_MESSAGE,
      payload: { toastMessage: t('home:companydetails:overview:fundingrequirements:failure') },
    });
  };

  const findFieldOptions = (fieldId: string): TOption[] => {
    return activeTab?.fields?.find(({ id }: { id: string }) => fieldId === id)?.options || [];
  };

  const handleGoalsValuesObject = (values: { [key: string]: any }) => {
    const valuesToSave: { fieldId: string; value: string }[] = [];

    Object.keys(values).forEach((key) => {
      const formId = currentTabData.data[key]?.formFieldId || '';
      const formFieldId = currentTabData.data[key]?.formFieldId;
      const dataType = currentTabData.data[key]?.dataType?.toLowerCase() || '';
      const fieldOptions = findFieldOptions(formFieldId);
      const field = currentTabData?.fields?.find(({ id }: { id: string }) => formFieldId === id);

      if (currentTabData.data[key]?.dataType === 'array' && field.type == 'checkbox') {
        if (Array.isArray(values[key])) {
          values[key] = values[key]
            ?.map((value: string | number) => fieldOptions.find((field) => field.value === value))
            ?.map((field: TOption | undefined) => {
              if (field === undefined) return;

              return field.value;
            });
        } else {
          values[key] = values[key]?.split(',')?.map((value: string) => {
            return value.trim();
          });
        }
      } else if (currentTabData.data[key]?.dataType === 'array' && Array.isArray(values[key])) {
        values[key] = values[key]
          ?.map((value: string | number) => fieldOptions.find((field) => field.value === value))
          ?.map((field: TOption | undefined) => {
            if (field === undefined) return;

            return {
              value: field.value,
              label: field.label,
            };
          });
      } else if (currentTabData.data[key]?.dataType === 'array' && !Array.isArray(values[key])) {
        values[key] = values[key]?.split(',')?.map((value: string) => {
          return {
            value: value.trim(),
            label: value.trim(),
          };
        });
      }

      valuesToSave.push({
        fieldId: formId,
        value: formatGoalsFormsValues(values[key], dataType),
      });
    });

    return valuesToSave;
  };

  const normaliseDebtData = (data: any) => {
    const foundTimescale =
      fundingRequiredTimescale.find(
        (timescale: TOption) => timescale.value === data?.fundingRequiredTimescale
      )?.label ?? '';

    data.fundingRequiredTimescale = data?.pageMetadata?.fundingRequiredTimescales?.find(
      (timescale: TOption) =>
        parseStringValues(timescale.label) === parseStringValues(foundTimescale)
    )?.value;
    data.sectors = data?.sectors
      ?.map((sector: string) =>
        data?.pageMetadata?.sectors?.find(
          (option: TOption) => parseStringValues(option.label) === parseStringValues(sector)
        )
      )
      .filter((sector?: TOption) => sector);
    data.firstCommercialSale = data?.firstCommercialSale
      ? new Date(data?.firstCommercialSale * 1000).toISOString()
      : undefined;
    data.turnoverLastYear = data.turnoverLastYear ? parseInt(data.turnoverLastYear) : '';

    return {
      ...data,
      fundingPurposes:
        data?.fundingPurposes
          ?.map((purpose: string) => {
            const foundPurpose = fundingPurposes.find(
              ({ label }: { label: string }) =>
                parseStringValues(label) === parseStringValues(purpose)
            );

            // strip old funding purposes which map to a new funding form
            if (!foundPurpose || foundPurpose.formId) {
              return null;
            } else {
              return parseStringValues(foundPurpose?.label);
            }
          })
          .filter((fundingPurpose: string | null) => fundingPurpose) || [],
    };
  };

  const mapLegacyLoanFormKeys = (key: string) => {
    const keys: { [key: string]: string } = {
      fundingRequired: 'fundingAmount',
      fundingRequiredTimescale: 'whenFundingNeeded',
      fundingPurposeSummary: 'fundingNeedsDescription',
      isTrading: 'isCurrentlyTrading',
      firstCommercialSale: 'tradingStartDate',
      profitableLastYear: 'makeProfitLastFinancialYear',
      cardPaymentsAccepted: 'takesPaymentsViaCardMachine',
      invoicesSent: 'sendInvoicesToCustomers',
      currentlyBank: 'mainBank',
    };

    return keys[key] || key;
  };

  const handleLoansFormMigration = async (selectedFormId: string) => {
    try {
      dispatch({
        type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
        payload: { loading: true },
      });
      dispatch({
        type: FundingRequirementsActionType.SET_IS_SAVING,
        payload: { isSaving: true },
      });
      closeFormMigrationModal();
      const newNormalisedData = normaliseDebtData({ ...currentTabData?.data });

      await putDynamicFundingFormFields(currentTabData?.data?.companyId, {
        formIds: [selectedFormId],
        isFormCompleted: true,
        sections: [
          {
            id: '00000000-0000-0000-0000-000000000000',
            isCompleted: true,
            fieldValues: Object.keys(newNormalisedData)
              .filter(
                (key) =>
                  ![
                    'pageMetadata',
                    'companyId',
                    'fundingPurposes',
                    'isDataComplete',
                    'cardPaymentAverageMonthlyRevenue',
                    'invoiceAverageMonthlyRevenue',
                  ].includes(key)
              )
              .map((key) => ({
                name: mapLegacyLoanFormKeys(key),
                value: Array.isArray(newNormalisedData?.[key])
                  ? JSON.stringify(newNormalisedData?.[key])
                  : newNormalisedData?.[key]?.toString(),
              }))
              .filter((field) => field.value),
          },
        ],
      });

      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });

      if (state.completedFundingForms.includes(state[ActiveTabs.LOANS].formId)) {
        await deleteDynamicFundingForm(companyId, state[ActiveTabs.LOANS].formId);
      }

      await generateTabs();
      await getForm();
      dispatch({
        type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
        payload: { loading: false },
      });
      dispatch({
        type: FundingRequirementsActionType.SET_IS_SAVING,
        payload: { isSaving: false },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
        payload: { loading: false, error: true },
      });
      dispatch({
        type: FundingRequirementsActionType.SET_IS_SAVING,
        payload: { isSaving: false },
      });
    }
  };

  const saveRequirements = async (values: { [key: string]: any }) => {
    try {
      dispatch({
        type: FundingRequirementsActionType.SET_IS_SAVING,
        payload: { isSaving: true },
      });

      let goalValuesToSave: { [key: string]: any } | null = {};

      goalValuesToSave = handleGoalsValuesObject(values);

      const apiData = {
        isFormCompleted: true,
        sections: currentTabData.form.formSections.map((section: any) => {
          return {
            id: section.formSectionId,
            isCompleted: true,
            fieldValues: goalValuesToSave?.filter((field: any) =>
              section.formFields.find((sectionItem: any) => {
                if (sectionItem.fieldId === field.fieldId)
                  return {
                    value: field.value,
                    fieldId: field.fieldId,
                  };
                return false;
              })
            ),
          };
        }),
      };

      const version = env('REACT_APP_DYNAMIC_FORMS_ENDPOINTS_VERSION');
      const response =
        version === 'v2'
          ? await putFundingFormV2(currentTabData.formId, companyId, apiData)
          : await putFundingForm(currentTabData.formId, companyId, apiData);

      if (response?.status === 204) {
        saveSuccess();

        if (!state.completedFundingForms.includes(currentTabData.formId)) {
          dispatch({
            type: FundingRequirementsActionType.SET_COMPLETED_FORMS,
            payload: [...state.completedFundingForms, currentTabData.formId],
          });
        }
      }
    } catch (error) {
      console.error(error);
      saveFailed();
    } finally {
      dispatch({
        type: FundingRequirementsActionType.SET_IS_SAVING,
        payload: { isSaving: false },
      });

      dispatch({
        type: FundingRequirementsActionType.SET_ABLE_TO_SAVE,
        payload: { ableToSave: false },
      });
    }
  };

  const { handleChange, handleSubmit, metadata, errors } = useForm(
    {},
    currentTabData?.fields || [],
    saveRequirements,
    validation
  );

  const customChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!state.ableToSave) {
      dispatch({
        type: FundingRequirementsActionType.SET_ABLE_TO_SAVE,
        payload: { ableToSave: true },
      });
    }
    await handleChange(event);
  };

  const generateTabs = async () => {
    try {
      const { data: debtFormData } = await getFundingMatchesDebt(companyId);
      if (state.activeTab === ActiveTabs.LOANS) {
        dispatch({
          type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
          payload: { fields: LoanRequirementsFields(debtFormData), debtFormData },
        });
      }

      if (!defaultTab) {
        dispatch({
          type: FundingRequirementsActionType.SET_DEBT_REQUIREMENTS,
          payload: { enabled: true },
        });

        dispatch({
          type: FundingRequirementsActionType.SET_ACTIVE_TAB,
          payload: ActiveTabs.LOANS,
        });
      }

      const { data: formsList } = await getFundingForms();
      const { data: completedFormsList } = await getCompletedFundingForms(companyId);

      const tabs = formsList?.map((tab: any) => ({
        label: tab?.displayName,
        formId: tab?.formId,
        type: tab?.type,
        name: tab?.name,
      }));

      const completedFormsIdList = completedFormsList?.map(
        (form: { formId: string }) => form.formId
      );

      const isSignUpDatePastLoansMigrationDate = () => {
        return (
          new Date(globalState?.company?.companyData?.signUpDate) <=
          new Date('2023-04-26T00:00:00.000Z')
        );
      };

      if (
        (!defaultTab &&
          completedFormsList.find(({ type }: { type: string }) => type === 'Loan') &&
          isSignUpDatePastLoansMigrationDate()) ||
        (!completedFormsList.length && isSignUpDatePastLoansMigrationDate())
      ) {
        tabs.unshift({
          label: 'Loans',
          formId: ActiveTabs.LOANS,
        });
        completedFormsIdList.push(ActiveTabs.LOANS);
      }

      dispatch({
        type: FundingRequirementsActionType.SET_COMPLETED_FORMS,
        payload: completedFormsIdList,
      });

      dispatch({
        type: FundingRequirementsActionType.SET_TABS,
        payload: tabs.sort((item: TTab) => {
          if (completedFormsIdList.includes(item.formId) && item.label !== 'Loans') return -1;
          else return 0;
        }),
      });
      dispatch({
        type: FundingRequirementsActionType.SET_ACTIVE_TAB,
        payload: defaultTab ?? tabs[0].formId,
      });
    } catch (error) {
      console.error(error);
      globalDispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:companydetails:overview:fundingrequirements:error') },
      });
    }
  };

  const openFormMigrationModal = () => {
    dispatch({
      type: FundingRequirementsActionType.SET_FORM_MIGRATION_MODAL_OPEN,
      payload: true,
    });
  };

  const closeFormMigrationModal = () => {
    dispatch({
      type: FundingRequirementsActionType.SET_FORM_MIGRATION_MODAL_OPEN,
      payload: false,
    });
  };

  useEffect(() => {
    const fetchForm = async () => {
      if (!state.tabs.length) return;
      await getForm();
    };
    fetchForm().catch((e) => {
      console.error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeTab, state.tabs.length]);

  useEffect(() => {
    const generateTabsAsync = async () => {
      if (!companyId) return;
      await generateTabs();
    };
    generateTabsAsync().catch((e) => {
      console.error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    const fetchScoreGoals = async () => {
      await getScoreGoals();
    };
    fetchScoreGoals().catch((e) => {
      console.error(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, state.activeTab, currentTabData.formId]);

  return {
    tabs: state.tabs,
    // @ts-ignore
    completedFundingForms: [...new Set(state.completedFundingForms)],
    loading: currentTabData.loading,
    formMigrationModalOpen: state.formMigrationModalOpen,
    closeFormMigrationModal,
    openFormMigrationModal,
    error: currentTabData.error,
    activeTab: state.activeTab,
    selectTab,
    handleChange: customChangeHandler,
    handleSubmit,
    handleLoansFormMigration,
    metadata,
    formErrors: errors,
    isSaving: state.isSaving,
    ableToSave: state.ableToSave,
    activeTabDetails: state.activeTabDetails,
    scoreGoalsHistory: state.scoreGoals,
    isSwoopGroupBroker,
  };
};

export default FundingRequirementsHook;
