import { editApplicationFormfields } from 'pages/companyApplicationForm/fields/editApplicationForm.fields';

export const convertFormValuesToCompanyServiceSchema = (companyServiceFields: any[]) => {
  return companyServiceFields.reduce((total, current) => {
    const rootId = current.ids.rootId;
    const childId = current.ids.childId;
    return {
      ...total,
      [rootId]: [...(total[rootId] || []), { name: current.Name, value: current.Value, childId }],
    };
  }, {});
};

export const combineApiDataFields = (data: any) => {
  const clonedSection = { ...data };
  const childSections = clonedSection.applicationExtendedSectionDetails.reduce(
    (total: any, section: any) => {
      return [
        ...total,
        ...section.childrenDetails.map((childDetail: any) => {
          return {
            ...childDetail,
            rootId: section.rootId,
            displayName: section.displayName,
            subHeading: childDetail.displayName,
          };
        }),
      ];
    },
    []
  );

  clonedSection.combinedSectionDetails = [
    ...clonedSection.applicationSectionDetails.map((section: any) => {
      return {
        ...section,
        childId: undefined, // API returns a nonsense childId here, so we remove it.
      };
    }),
    ...[...clonedSection.applicationExtendedSectionDetails, ...childSections],
  ];

  return clonedSection;
};

export const newApplicant = async (
  companyId: string,
  data: any,
  currentFormValues: { [key: string]: string }
) => {
  const contactId = `new-contact-${Math.random()}`;

  const extendedSectionTemplate = {
    ...data.applicationExtendedSectionDetails[0],
  };

  const childDetails =
    (extendedSectionTemplate.childrenDetails.length &&
      extendedSectionTemplate.childrenDetails[0]) ||
    null;

  if (childDetails) {
    // create new child detail
    const contactAddressId = `new-address-${Math.random()}`;

    // reset child details
    extendedSectionTemplate.childrenDetails =
      (contactAddressId && [
        {
          ...childDetails,
          childId: contactAddressId,
          fieldValueDetails: childDetails.fieldValueDetails.map((field: any) => {
            return {
              ...field,
              value: '',
            };
          }),
        },
      ]) ||
      [];
  }

  extendedSectionTemplate.fieldValueDetails = extendedSectionTemplate.fieldValueDetails.map(
    (field: any) => {
      return {
        ...field,
        value: '',
      };
    }
  );

  extendedSectionTemplate.rootId = contactId;

  const applicationExtendedSectionDetails = [
    ...data.applicationExtendedSectionDetails,
    ...((Object.keys(extendedSectionTemplate).length && [extendedSectionTemplate]) || []),
  ];

  const combinedData = combineApiDataFields({
    ...data,
    applicationExtendedSectionDetails,
  });

  const fieldsMetadata =
    (combinedData.combinedSectionDetails.length &&
      editApplicationFormfields(combinedData.combinedSectionDetails)) ||
    [];

  injectCurrentValuesIntoMetadata(fieldsMetadata, currentFormValues);

  return {
    combinedData,
    fieldsMetadata,
  };
};

export const newAddress = async (
  companyId: string,
  rootId: string,
  data: any,
  currentFormValues: { [key: string]: string }
) => {
  const extendedDetails = [...data.applicationExtendedSectionDetails];

  const sectionIndex = extendedDetails.findIndex(
    (extendedDetail: any) => extendedDetail.rootId === rootId
  );

  const [childDetailTemplate] =
    (sectionIndex !== -1 && extendedDetails[sectionIndex].childrenDetails) || [];

  if (childDetailTemplate) {
    // get new address id
    const contactAddressId = `new-address-${Math.random()}`;

    extendedDetails[sectionIndex].childrenDetails = [
      ...extendedDetails[sectionIndex].childrenDetails,
      {
        ...childDetailTemplate,
        childId: contactAddressId,
        fieldValueDetails: childDetailTemplate.fieldValueDetails.map((field: any) => {
          return {
            ...field,
            value: '',
          };
        }),
      },
    ];

    const combinedData = combineApiDataFields({
      ...data,
      extendedDetails,
    });

    const fieldsMetadata =
      (combinedData.combinedSectionDetails.length &&
        editApplicationFormfields(combinedData.combinedSectionDetails)) ||
      [];

    injectCurrentValuesIntoMetadata(fieldsMetadata, currentFormValues);

    return {
      combinedData,
      fieldsMetadata,
    };
  }

  return {};
};

export const copyAddress = (data: any, currentFormValues: { [key: string]: any }) => {
  const clonedData = data;
  const companyAddressSector = clonedData.applicationSectionDetails.find(
    (section: any) => section.displayName === 'Company Address'
  );

  let regAddressHolder = {} as { [key: string]: string };
  let updatedAddressValues = {} as { [key: string]: string };
  const updatedFields = companyAddressSector?.fieldValueDetails?.map((field: any) => {
    if (field.name.includes('Registered')) {
      const currentValue = Object.entries(currentFormValues).find(([key, _]) => {
        return key.includes(field.name);
      });

      regAddressHolder = {
        ...regAddressHolder,
        [field.displayName]: (currentValue && currentValue[1]) || field.value,
      };

      return { ...field, value: (currentValue && currentValue[1]) || field.value };
    }

    if (field.name.includes('Trading')) {
      const id = `${companyAddressSector.sectionId}_${companyAddressSector.rootId}_${companyAddressSector.childId}*${field.name}`;
      updatedAddressValues = {
        ...updatedAddressValues,
        [id]: regAddressHolder[field.displayName],
      };

      return {
        ...field,
        value: regAddressHolder[field.displayName],
      };
    }

    return field;
  });

  companyAddressSector.fieldValueDetails = updatedFields;

  clonedData.applicationSectionDetails = clonedData.applicationSectionDetails.map(
    (section: any) => {
      return (section.displayName === 'Company Address' && companyAddressSector) || section;
    }
  );

  const combinedData = combineApiDataFields(clonedData);

  return {
    fields: editApplicationFormfields(combinedData.combinedSectionDetails),
    combinedData,
    updatedAddressValues,
  };
};

const injectCurrentValuesIntoMetadata = (fieldsMetadata: any, currentFormValues: any) => {
  Object.entries(currentFormValues).forEach(([key, value]: any) => {
    const [id] = key.split('*');
    const relevantFieldIndex = fieldsMetadata.findIndex((metadata: any) => metadata.id === id);
    const relevantChildFieldIndex =
      relevantFieldIndex &&
      fieldsMetadata[relevantFieldIndex].children.findIndex(
        (childField: any) => childField.id === key
      );
    if (relevantFieldIndex !== -1 && relevantChildFieldIndex !== -1) {
      fieldsMetadata[relevantFieldIndex].children[relevantChildFieldIndex].value = value;
    }
  });
};

export const flattenData = (data: Array<any>) => {
  return data.reduce((total, current) => {
    return [...total, ...current?.fieldValueDetails];
  }, []);
};

export const getRelevantMenuSections = (sectionsMetadata: any) => {
  return sectionsMetadata
    .filter(
      (section: any) =>
        (section.rootId && !section.childId) || (!section.rootId && !section.childId)
    )
    .map((section: any, index: number, sections: any[]) => {
      return {
        ...section,
        name:
          sections.length > 1
            ? `${section.name} (${index + 1} of ${sections.length})`
            : section.name,
        subSections: sectionsMetadata
          .filter(
            (possibleSubSection: any) =>
              possibleSubSection.childId && section.rootId === possibleSubSection.rootId
          )
          .map((subSection: any, index: number, subSections: any[]) => {
            return {
              ...subSection,
              subHeading:
                subSections.length > 0
                  ? `${subSection.subHeading} (${index + 1} of ${subSections.length})`
                  : subSection.subHeading,
            };
          }),
      };
    });
};

export const sortContactAddressByDateMoveIn = (data: any[]) => {
  return data.map((extendedDetails: any) => {
    const sortedChildDetails =
      extendedDetails.childrenDetails.length > 1 &&
      extendedDetails.childrenDetails.sort((a: any, b: any) => {
        const aDate =
          a.fieldValueDetails.find((aField: any) => aField.name === 'ContactAddress.MoveInDate') ||
          {};
        const bDate =
          b.fieldValueDetails.find((bField: any) => bField.name === 'ContactAddress.MoveInDate') ||
          {};

        return aDate?.value > bDate?.value ? -1 : 1;
      });
    return {
      ...extendedDetails,
      childrenDetails: sortedChildDetails || extendedDetails.childrenDetails,
    };
  });
};
