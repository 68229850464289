import classNames from 'classnames';
import React from 'react';
import AccordionItem from './accordionItem';
import { AccordionItemPropType } from './types';

const Accordion: React.FC<any> = ({ items, compact = false, darkHeader = false }) => {
  return (
    <div className={classNames('accordion-container')}>
      {items?.map((item: AccordionItemPropType) => {
        return (
          <AccordionItem
            title={item?.title}
            content={item?.content}
            loading={item?.loading}
            collapsedByDefault={item?.collapsedByDefault}
            saveButton={item?.saveButton}
            ableToSave={item?.ableToSave}
            compact={compact}
            darkHeader={darkHeader}
            key={item?.title}
          />
        );
      })}
    </div>
  );
};

export default Accordion;
