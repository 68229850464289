import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { getProducts } from '_shared/api/products';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import Button from 'components/button';
import styles from './AddNonMatchedFunderPanel.module.scss';
import Pagination from 'components/pagination';
import usePagination from '_shared/hooks/usePagination';
import Spinner from 'components/spinner';
import ToggleButtonGroup from 'components/v2/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'components/v2/ToggleButton/ToggleButton';
import TextInput from 'components/v2/TextInput/TextInput';
import useDebounce from '_shared/hooks/useDebounce';
import Select from 'components/v2/Select';
import { DealTypes, OfferTypeSingular } from '../../types';
import useDealTypes from '_shared/hooks/useDealTypes';

type AddNonMatchedFunderPanelProps = {
  processingProductId?: string;
  inDealProductIds: string[];
  addDealLabel?: string;
  readonly: boolean;
  isSwoopGroupUser: boolean;
  onAddToDeal: (opportunityId: string) => void;
};

type Category = 'all' | OfferTypeSingular;

const AddNonMatchedFunderPanel: React.FC<AddNonMatchedFunderPanelProps> = ({
  processingProductId,
  inDealProductIds,
  addDealLabel = 'Add to deal',
  readonly,
  isSwoopGroupUser,
  onAddToDeal,
}) => {
  const pagination = usePagination();
  const { subtypesByType } = useDealTypes();

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [category, setCategory] = useState<Category>('all');
  const [subcategory, setSubcategory] = useState<string>('all');
  const [productName, setProductName] = useState('');
  const debouncedSearch = useDebounce(productName, 500);
  const [products, setProducts] = useState<any[]>([]);

  const isSwoopDisplayedType = useMemo(() => {
    if (isSwoopGroupUser && category === 'all') {
      return [];
    } else if (!isSwoopGroupUser && category === 'all') {
      return [OfferTypeSingular.Loan, OfferTypeSingular.Grant];
    }
    return [category];
  }, [isSwoopGroupUser, category]);

  const subcategoryOptions = useMemo(() => {
    if (category === OfferTypeSingular.Loan) {
      return [{ label: 'All', value: 'all' }, ...subtypesByType[DealTypes.LOANS]!];
    }

    if (category === OfferTypeSingular.Equity) {
      return [{ label: 'All', value: 'all' }, ...subtypesByType[DealTypes.EQUITY]!];
    }

    return [];
  }, [category, subtypesByType]);

  useEffect(() => {
    const fetchProducts = async () => {
      const { currentPage } = pagination;

      try {
        setIsLoading(true);
        const { data } = await getProducts({
          pageNumber: currentPage - 1,
          pageSize: pageSize,
          onlyActive: true,
          productName: debouncedSearch.trim(),
          type: isSwoopDisplayedType,
          subcategory: subcategory === 'all' ? [] : [subcategory],
        });

        pagination.setTotalCount(data.paging.totalCount);
        setProducts(data.results);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchProducts();
  }, [pagination.currentPage, pageSize, debouncedSearch, isSwoopDisplayedType, subcategory]);

  return (
    <div>
      <div className={styles.filters}>
        <ToggleButtonGroup
          className={styles['category-selector']}
          value={category}
          onChange={(v) => {
            setCategory(v);
            setSubcategory('all');
          }}
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value={OfferTypeSingular.Loan}>Loans</ToggleButton>
          <ToggleButton value={OfferTypeSingular.Equity}>Equity</ToggleButton>
          <ToggleButton value={OfferTypeSingular.Grant}>Grants</ToggleButton>
        </ToggleButtonGroup>

        {(category === OfferTypeSingular.Loan || category === OfferTypeSingular.Equity) && (
          <Select
            className={styles['sub-category-selector']}
            value={subcategory ?? ''}
            options={subcategoryOptions}
            placeholder="Filter by subcategory"
            onChange={(e) => {
              setSubcategory(e.target.value);
            }}
          />
        )}

        <div className={styles.spacer} />

        <TextInput
          value={productName}
          placeholder="Search"
          className={styles['search-input']}
          onChange={(e) => {
            pagination.setCurrentPage(1);
            setProductName(e.target.value);
          }}
        />
      </div>

      {isLoading ? (
        <div className={styles.loading}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>Product Provider</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.productId}>
                  <TableCell>{product.tradingName || product.providerName || 'N/A'}</TableCell>
                  <TableCell>{product.productName}</TableCell>
                  <TableCell>
                    {!readonly &&
                      (inDealProductIds.includes(product.productId) ? (
                        <Button className={cn(styles['in-deal-btn'])}>In Deal</Button>
                      ) : (
                        <Button
                          className={cn(styles['add-deal-btn'])}
                          clickHandler={() => onAddToDeal(product.productId)}
                          loading={processingProductId === product.productId}
                          disabled={!!processingProductId}
                        >
                          {addDealLabel}
                        </Button>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            {...pagination}
            handlePageSize={(e) => setPageSize(parseInt(e.target.value))}
            pageSize={pageSize}
          />
        </>
      )}
    </div>
  );
};

export default AddNonMatchedFunderPanel;
