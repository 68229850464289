import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import { TOAST_MESSAGE } from 'store/toast/types';
import {
  deleteCompanyDocument,
  getDownloadCompanyDocument,
  getAccountingDocuments,
  putCompanyDocumentTitle,
  putCompanyDocumentType,
} from '_shared/api/documents';
import useDebounce from '_shared/hooks/useDebounce';
import { downloadDocumentLink } from '_shared/utils';
import { APIDocumentType, SelectAllDocumentItemsType } from '../types';
import { documentFields } from '../fields/documentTable.fields';
import { documentTableActionType, documentTableReducer } from '../store/documentTable.reducer';

const DocumentTableHook = ({ companyId, documentList, fetchDocumentList }: any) => {
  const initialState = {
    documents: {},
    data: documentList,
    editDocuments: [],
    documentsToDelete: [],
    documentsToDownload: {},
    selectedDocuments: [],
    selectAll: false,
    isDeleteModalOpen: false,
    previewDocument: '',
    error: false,
    searchTerm: '',
    reload: false,
    loading: {
      fetchDocuments: true,
      isDownloading: false,
      isSaving: false,
      isDeleting: false,
    },
    filter: {
      orderBy: '',
      direction: 'desc',
    },
  };

  const [state, dispatch] = useReducer(documentTableReducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);
  const store = useAppState();
  const { t } = useTranslation();

  const handleOnHeaderSort = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    event.preventDefault();
    const orderBy = event.currentTarget.getAttribute('data-name');

    dispatch({
      type: documentTableActionType.SET_ORDER,
      payload: {
        filter: orderBy,
        getDocumentActions: getDocumentActions(),
        isAdminOrSuperAdmin: Boolean(store.state.system.isAdminOrSuperAdmin?.()),
      },
    });
  };

  const handleOnSearchDocuments = (event: any) => {
    event.preventDefault();
    dispatch({
      type: documentTableActionType.SET_SEARCH_TERM,
      payload: {
        searchTerm: event.target?.value,
      },
    });
  };

  const searchDocuments = (searchTerm: string) => {
    const filteredData =
      (searchTerm &&
        state.data.filter((document: APIDocumentType) => {
          return (
            document.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            document.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            document.type.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })) ||
      state.data;

    dispatch({
      type: documentTableActionType.SET_DOCUMENTS,
      payload: {
        documents: documentFields({
          data: filteredData,
          editDocuments: state.editDocuments,
          selectedDocuments: state.selectedDocuments,
          actions: getDocumentActions(),
          order: { order: state.order, direction: state.direction },
          isAdminOrSuperAdmin: Boolean(store.state.system?.isAdminOrSuperAdmin?.()),
        }),
        loading: {
          ...state.loading,
          fetchDocuments: false,
        },
      },
    });
  };

  // initialise document fields with given data
  const setDocumentFields = (data: Array<APIDocumentType>) => {
    dispatch({
      type: documentTableActionType.SET_DOCUMENTS,
      payload: {
        documents: documentFields({
          data,
          editDocuments: state.editDocuments,
          selectedDocuments: state.selectedDocuments,
          actions: getDocumentActions(),
          order: { order: '', direction: '' },
          isAdminOrSuperAdmin: Boolean(store.state.system?.isAdminOrSuperAdmin?.()),
        }),
        data,
        loading: {
          ...state.loading,
          fetchDocuments: false,
        },
      },
    });
  };

  // document actions that can be found in the document table list
  const getDocumentActions = () => {
    return {
      delete: handleDeleteDocument,
      download: handleDownloadDocument,
      select: handleSelectDocument,
      selectAll: handleSelectAll,
      edit: handleEditDocument,
      saveEdit: handleSaveEdit,
      preview: handlePreviewDocument,
      changeField: handleEditFieldChange,
      sort: handleOnHeaderSort,
    };
  };

  const handleDeleteDocument = (event: any) => {
    const [docToDelete] = event.currentTarget.getAttribute('data-id')?.split('_');
    dispatch({
      type: documentTableActionType.SET_DOCUMENTS_TO_DELETE,
      payload: [docToDelete],
    });
  };

  const handleDeleteSelectedDocuments = () => {
    dispatch({
      type: documentTableActionType.SET_DOCUMENTS_TO_DELETE,
      payload: state.selectedDocuments,
    });
  };

  // toggle delete document modal
  const setIsDeleteModalOpen = (value: boolean = false) => {
    dispatch({
      type: documentTableActionType.SET_IS_DELETE_MODAL_OPEN,
      payload: Boolean(value),
    });
  };

  const handleSelectDocument = async (event: any) => {
    dispatch({
      type: documentTableActionType.SET_DOCUMENTS_TO_DOWNLOAD,
      payload: {
        documentId: event.currentTarget.getAttribute('id'),
        getDocumentActions: getDocumentActions(),
        isAdminOrSuperAdmin: Boolean(store.state.system.isAdminOrSuperAdmin?.()),
      },
    });
  };

  const handleSelectAll = async (event: any) => {
    dispatch({
      type: documentTableActionType.SET_SELECT_ALL,
      payload: {
        getDocumentActions: getDocumentActions(),
        isAdminOrSuperAdmin: Boolean(store.state.system.isAdminOrSuperAdmin?.()),
      },
    });
  };

  // sets documentId that will be previewed
  const handlePreviewDocument = (event: any) => {
    dispatch({
      type: documentTableActionType.SET_PREVIEW_DOCUMENT,
      payload: (event && event.currentTarget.getAttribute('data-id')) || '',
    });
  };

  const handleEditFieldChange = (event: any) => {
    const { id, name, value } = event.currentTarget;
    dispatch({
      type: documentTableActionType.CHANGE_DOCUMENT,
      payload: {
        id,
        name,
        value,
        getDocumentActions: getDocumentActions(),
        isAdminOrSuperAdmin: Boolean(store.state.system.isAdminOrSuperAdmin?.()),
      },
    });
  };

  const handleEditDocument = (event: any) => {
    const editEventId = event.currentTarget.getAttribute('data-id');
    const [editId] = editEventId.split('_');

    dispatch({
      type: documentTableActionType.SET_EDIT_DOCUMENTS,
      payload: [editId],
    });
  };

  const handleEditSelectedDocuments = () => {
    dispatch({
      type: documentTableActionType.SET_EDIT_DOCUMENTS,
      payload: state.selectedDocuments,
    });
  };

  const handleSaveEdit = async (event: any) => {
    const [saveDocumentEvent] = event.currentTarget.getAttribute('data-id')?.split('_');
    store.dispatch({
      type: TOAST_MESSAGE,
      payload: { toastMessage: t('common:changessaved') },
    });

    const { documentId, type, title } =
      state.editDocuments.find(
        (document: APIDocumentType) => saveDocumentEvent === document.documentId
      ) || {};
    if (!companyId || !documentId) return;

    try {
      await Promise.all([
        putCompanyDocumentTitle(companyId, documentId, {
          documentTitle: title || '',
        }),
        putCompanyDocumentType(companyId, documentId, {
          documentType: type || '',
        }),
      ]);
      await fetchDocumentList(companyId || '');
      dispatch({
        type: documentTableActionType.SET_EDIT_DOCUMENTS,
        payload: [saveDocumentEvent],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleDownloadDocument = (event: any) => {
    const [documentId] = event.currentTarget.getAttribute('data-id')?.split('_');

    dispatch({
      type: documentTableActionType.SET_DOWNLOAD_DOCUMENT,
      payload: documentId,
    });
  };

  const downloadDocument = async (document: APIDocumentType) => {
    const { data } = await getDownloadCompanyDocument(companyId, document.documentId);
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    downloadDocumentLink(url, document.name);
  };

  const downloadAccountingDocuments = async (document: APIDocumentType) => {
    const { data } = await getAccountingDocuments(companyId, document.documentId);
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    downloadDocumentLink(url, document.name);
  };

  // downloads selected documents
  const handleDownloadSelectedDocuments = async () => {
    if (!companyId) return;

    dispatch({
      type: documentTableActionType.SET_LOADING,
      payload: {
        isDownloading: true,
      },
    });

    const downloadPromises = state.selectedDocuments.map(async (documentId: string) => {
      const { name, isBrokerDocument } = state.data.find(
        (document: APIDocumentType) => documentId === document.documentId
      );
      const { data } = isBrokerDocument
        ? await getAccountingDocuments(companyId, documentId)
        : await getDownloadCompanyDocument(companyId, documentId);

      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      downloadDocumentLink(url, name);
    });

    await Promise.all(downloadPromises);

    sendAmplitudeData(AmplitudeTrackingEnum.downloaddocument);
    dispatch({
      type: documentTableActionType.SET_DOCUMENTS_TO_DOWNLOAD,
      payload: {
        getDocumentActions: getDocumentActions(),
      },
    });

    dispatch({
      type: documentTableActionType.SET_LOADING,
      payload: {
        isDownloading: false,
      },
    });
  };

  // deletes selected document
  const confirmDelete = async () => {
    try {
      dispatch({
        type: documentTableActionType.SET_LOADING,
        payload: {
          isDeleting: true,
        },
      });
      const deletePromises = state.documentsToDelete.map(async (document: any) => {
        return await deleteCompanyDocument(companyId, document.documentId);
      });

      await Promise.all(deletePromises);

      await fetchDocumentList(companyId);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:companydetails:documentlibrary:delete:toast') },
      });
      setIsDeleteModalOpen(false);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({
        type: documentTableActionType.SET_LOADING,
        payload: {
          isDeleting: false,
        },
      });
    }
  };

  const selectAllDocumentItems: Array<SelectAllDocumentItemsType> = [
    {
      id: 'download-selected',
      icon: 'cloud_download',
      i18n: 'download',
      clickHandler: handleDownloadSelectedDocuments,
      loading: state.loading.isDownloading,
    },
    {
      id: 'edit-selected',
      icon: 'edit',
      i18n: 'edit',
      clickHandler: handleEditSelectedDocuments,
    },
    ...((store.state.system.isAdminOrSuperAdmin && [
      {
        id: 'delete-selected',
        icon: 'delete',
        i18n: 'delete',
        clickHandler: handleDeleteSelectedDocuments,
      },
    ]) ||
      []),
  ];

  useEffect(() => {
    if (!companyId) return;
    setDocumentFields(documentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.editDocuments, documentList]);

  useEffect(() => {
    if (!companyId || !state.documentToDownload) return;
    if (state?.documentToDownload?.isBrokerDocument) {
      downloadAccountingDocuments(state.documentToDownload);
    } else {
      downloadDocument(state.documentToDownload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.documentToDownload]);

  useEffect(() => {
    searchDocuments(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return {
    state,
    handleOnSearchDocuments,
    selectAllDocumentItems,
    setIsDeleteModalOpen,
    confirmDelete,
    handlePreviewDocument,
  };
};

export default DocumentTableHook;
