import { useEffect, useMemo, useState } from 'react';
import styles from '../../assets/creditSafeReport.module.scss';
import { getCreditSafeFinancialReport, getCreditSafeReport } from '_shared/api/businesses';
import Spinner from 'components/spinner';
import CustomVarianceTable from './customVarianceTable';
import CreditSafeKeyInfoSection from './creditSafeKeyInfoSection';
import { getCurrencySymbolFromCurrencyCode } from './util';
import { formatToCurrency } from '_shared/utils/currency';

type CreditSafeReportProps = {
  companyId: string;
};

export type KeyInfoType = {
  title: string;
  value: string;
}[];

export type KeyInfomationType = {
  companyName: string;
  companyNumber: string;
  companyRegistration: string;
  riskScore: string;
  internationalScore: string;
  creditLimit: string;
  contactLimit: string;
  companyStatus: string;
  creditLimitCurrency: string;
  contactLimitCurrency: string;
};

export type FinancialReportType = {
  [key: string]: any;
};

export type TableItemStructureType = {
  label: string;
  valueKey: string;
  icon?: boolean;
  currencySymbol?: boolean;
}[];

const CreditSafeReport = (props: CreditSafeReportProps) => {
  const { companyId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    keyInfoError?: string;
    financialError?: string;
  }>({
    keyInfoError: '',
    financialError: '',
  });
  const [showCreditReport, setShowCreditReport] = useState<boolean>(false);
  const [showCreditSafeFinancialReport, setShowCreditSafeFinancialReport] =
    useState<boolean>(false);

  const [keyInfomationSection, setkeyInfomationSection] = useState<KeyInfomationType>({
    companyName: '',
    companyNumber: '',
    companyRegistration: '',
    riskScore: '',
    internationalScore: '',
    creditLimit: '',
    contactLimit: '',
    companyStatus: '',
    creditLimitCurrency: '',
    contactLimitCurrency: '',
  });
  const [financialReport, setFinancialReport] = useState<FinancialReportType[]>([]);

  useEffect(() => {
    fetchCreditSafeReport(companyId);
    fetchCreditSafeFinancialReport(companyId);
  }, [companyId]);

  const fetchCreditSafeReport = async (companyId: string) => {
    setLoading(true);
    setErrorMessage({ ...errorMessage, keyInfoError: '' });
    try {
      const { data: creditSafeResponse } = await getCreditSafeReport(companyId);

      if (Object.keys(creditSafeResponse).length === 0) {
        setShowCreditReport(false);
      } else {
        setkeyInfomationSection({
          companyName: creditSafeResponse.companyName,
          companyNumber: creditSafeResponse.companyNumber,
          companyRegistration: creditSafeResponse.companyRegistration,
          riskScore: creditSafeResponse.riskScore,
          internationalScore: creditSafeResponse.internationalScore,
          creditLimit: creditSafeResponse.creditLimit,
          contactLimit: creditSafeResponse.contactLimit,
          companyStatus: creditSafeResponse.status,
          creditLimitCurrency: creditSafeResponse.creditLimitCurrency,
          contactLimitCurrency: creditSafeResponse.contactLimitCurrency,
        });

        setShowCreditReport(true);
      }
    } catch (error) {
      console.error('Error fetching CreditSafe report:', error);
      setErrorMessage({
        ...errorMessage,
        keyInfoError:
          'Unable to generate Credit Safe Key Infomation Summary. Please refresh and try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCreditSafeFinancialReport = async (companyId: string) => {
    setLoading(true);
    setErrorMessage({ ...errorMessage, financialError: '' });
    try {
      const { data: creditSafeFinancialResponse } = await getCreditSafeFinancialReport(companyId);

      if (creditSafeFinancialResponse.length === 0) {
        setShowCreditSafeFinancialReport(false);
      } else {
        setFinancialReport(creditSafeFinancialResponse);
        setShowCreditSafeFinancialReport(true);
      }
    } catch (error) {
      console.error('Error fetching CreditSafe financial report:', error);
      setErrorMessage({
        ...errorMessage,
        financialError:
          'Unable to generate Credit Safe financial report. Please refresh and try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const keyInfoCardData: KeyInfoType = [
    { title: 'Risk Score', value: keyInfomationSection.riskScore },
    { title: 'International Score', value: keyInfomationSection.internationalScore },
    { title: 'Credit Limit', value: keyInfomationSection.creditLimit },
    { title: 'Contact Limit', value: keyInfomationSection.contactLimit },
    { title: 'Status', value: keyInfomationSection.companyStatus },
  ];

  const profitAndLossTable: TableItemStructureType = [
    { label: 'Weeks', valueKey: 'numberOfWeeks', icon: false, currencySymbol: false },
    { label: 'Currency', valueKey: 'currency', icon: false, currencySymbol: false },
    {
      label: 'Consolidated A/Cs',
      valueKey: 'consolidatedAccounts',
      icon: false,
      currencySymbol: false,
    },
    { label: 'Turnover', valueKey: 'profitLoss.revenue' },
    { label: 'Wages & Salaries', valueKey: 'profitLoss.wagesAndSalaries' },
    { label: 'Operating Profit', valueKey: 'profitLoss.operatingProfit' },
    { label: 'Depreciation', valueKey: 'profitLoss.depreciation' },
    { label: 'Pre Tax Profit', valueKey: 'profitLoss.profitBeforeTax' },
    { label: 'Taxation', valueKey: 'profitLoss.tax' },
    { label: 'Profit After Tax', valueKey: 'profitLoss.profitAfterTax' },
    { label: 'Dividends Payable', valueKey: 'profitLoss.dividends' },
    { label: 'Retained Profit', valueKey: 'profitLoss.retainedProfit' },
  ];

  const balanceSheetTable: TableItemStructureType = [
    { label: 'Cash', valueKey: 'balanceSheet.cash' },
    { label: 'Net Assets', valueKey: 'balanceSheet.netAssets' },
    { label: 'Total Current Assets', valueKey: 'balanceSheet.totalCurrentAssets' },
    { label: 'Total Current Liabilities', valueKey: 'balanceSheet.totalCurrentLiabilities' },
    { label: 'Total Long Term Liabilities', valueKey: 'balanceSheet.totalLongTermLiabilities' },
  ];

  const otherFinancialsTable: TableItemStructureType = [
    { label: 'Net Worth', valueKey: 'otherFinancials.netWorth' },
    { label: 'Working Capital', valueKey: 'otherFinancials.workingCapital' },
  ];

  const ratiosTable: TableItemStructureType = [
    {
      label: 'Pre-tax profit margin %',
      valueKey: 'ratios.preTaxProfitMargin',
      currencySymbol: false,
    },
    { label: 'Current Ratio', valueKey: 'ratios.currentRatio', currencySymbol: false },
    {
      label: 'Sales/Networking Capital',
      valueKey: 'ratios.salesOrNetWorkingCapital',
      currencySymbol: false,
    },
    { label: 'Gearing %', valueKey: 'ratios.gearing', currencySymbol: false },
    { label: 'Equity in %', valueKey: 'ratios.equityInPercentage', currencySymbol: false },
    { label: 'Creditor Days', valueKey: 'ratios.creditorDays', currencySymbol: false },
    {
      label: 'Liquidity/Acid Test',
      valueKey: 'ratios.liquidityRatioOrAcidTest',
      currencySymbol: false,
    },
    {
      label: 'Return on capital employment %',
      valueKey: 'ratios.returnOnCapitalEmployed',
      currencySymbol: false,
    },
    {
      label: 'Return on Total Assets Employed %',
      valueKey: 'ratios.returnOnTotalAssetsEmployed',
      currencySymbol: false,
    },
    { label: 'Current Debt Ratio', valueKey: 'ratios.currentDebtRatio', currencySymbol: false },
    { label: 'Total Debt Ratio', valueKey: 'ratios.totalDebtRatio', currencySymbol: false },
    {
      label: 'Stock Turnover Ratio %',
      valueKey: 'ratios.stockTurnoverRatio',
      currencySymbol: false,
    },
    {
      label: 'Return on Net Assets Employed %',
      valueKey: 'ratios.returnOnNetAssetsEmployed',
      currencySymbol: false,
    },
  ];

  const getCurrencySymbol = (title: string) => {
    let currencySymbol = '';

    if (title === 'Credit Limit') {
      currencySymbol = getCurrencySymbolFromCurrencyCode(keyInfomationSection.creditLimitCurrency);
    } else if (title === 'Contact Limit') {
      currencySymbol = getCurrencySymbolFromCurrencyCode(keyInfomationSection.contactLimitCurrency);
    }

    return currencySymbol;
  };

  const displayCreditAndContactLimits: string[] = useMemo(
    () =>
      keyInfoCardData.map(({ title, value }) => {
        if (!value) return '-';

        if (['Credit Limit', 'Contact Limit'].includes(title)) {
          return value === 'No limit'
            ? 'No limit'
            : `${getCurrencySymbol(title)} ${formatToCurrency(value)}`;
        }

        return value;
      }),
    [keyInfoCardData]
  );

  if (loading) {
    return (
      <div className={styles['loading-spinner']}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      {errorMessage.keyInfoError ? (
        <div className={styles['error-message']}>
          <strong>Sorry, something went wrong:</strong>
          <div>{errorMessage.keyInfoError}</div>
        </div>
      ) : (
        <>
          {showCreditReport ? (
            <CreditSafeKeyInfoSection
              keyInfomationSection={keyInfomationSection}
              keyInfoCardData={keyInfoCardData}
              displayCreditAndContactLimits={displayCreditAndContactLimits}
            />
          ) : (
            <div className={styles['warning-message']}>
              <span>Unable to generate a Credit Safe report for this company.</span>
            </div>
          )}
        </>
      )}

      {errorMessage.financialError ? (
        <div className={styles['error-message']}>
          <strong>Sorry, something went wrong:</strong>
          <div>{errorMessage.financialError}</div>
        </div>
      ) : (
        <>
          {showCreditSafeFinancialReport ? (
            <>
              <CustomVarianceTable
                title="Profit & Loss"
                financialReportData={financialReport}
                tableStructure={profitAndLossTable}
              />
              <br />
              <CustomVarianceTable
                title="Balance Sheet"
                financialReportData={financialReport}
                tableStructure={balanceSheetTable}
              />
              <br />
              <CustomVarianceTable
                title="Other Financials"
                financialReportData={financialReport}
                tableStructure={otherFinancialsTable}
              />
              <br />
              <CustomVarianceTable
                title="Ratios"
                financialReportData={financialReport}
                tableStructure={ratiosTable}
              />
            </>
          ) : (
            <div className={styles['warning-message']}>
              <span>
                Sorry, there is no financial report right now. Try refreshing the page and check
                back soon.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreditSafeReport;
