import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal';
import cn from 'classnames';
import styles from 'pages/deals/assets/dealsNotification.module.scss';
import Checkbox from 'components/field/checkbox';
import Button from 'components/button';
import { appSpecific, isBrokerApplication } from '_shared/utils/application';

type Props = {
  isOpen: boolean;
  handleClose: (dontShowAgain: boolean) => void;
};

const DealsNotification = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  return (
    <Modal show={isOpen} handleClose={handleClose} testId={'deals-notification'}>
      <div className={cn(styles['deals-notification-modal'])}>
        <h3>
          {appSpecific(
            t('home:deals:dealsnotificationmodal:brokertitle'),
            t('home:deals:dealsnotificationmodal:advisortitle')
          )}
        </h3>
        <p className={cn(styles['info'])}>
          {appSpecific(
            t('home:deals:dealsnotificationmodal:brokerdescription'),
            t('home:deals:dealsnotificationmodal:advisordescription')
          )}
        </p>
        {isBrokerApplication && (
          <section className={cn(styles['notes-section'])}>
            <h4>{t('home:deals:dealsnotificationmodal:notessection:header')}</h4>
            <p className={cn(styles['info'])}>
              {t('home:deals:dealsnotificationmodal:notessection:note')}
            </p>
          </section>
        )}
        <div className={cn(styles['actions-container'])}>
          <Checkbox
            id={'deals-notification-checkbox'}
            name={'deals-notification-checkbox'}
            handleChange={() => setDontShowAgain(!dontShowAgain)}
            selected={dontShowAgain}
            label={t('home:deals:dealsnotificationmodal:checkboxlabel')}
          />
          <Button
            ariaLabel={'deals-notification-confirm'}
            id={'deals-notification-confirm'}
            clickHandler={() => handleClose(dontShowAgain)}
          >
            {t('home:deals:dealsnotificationmodal:closemodal')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealsNotification;
