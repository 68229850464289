import { withTransaction } from '@elastic/apm-rum-react';
import { productApplicationFormRoutes } from 'pages/productApplicationForm/productApplicationForm.routes';
import { productDocumentsRoutes } from 'pages/productDocuments/productDocuments.routes';
import { productInformationRoutes } from 'pages/productInformation/productInformation.routes';
import { productInsightsRoutes } from 'pages/productInsights/productInsights.routes';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import ProductDetailsRoute from '.';

export const productDetailsRoutes: RouteObject[] = [
  {
    path: RoutePath.productdetails,
    element: withTransaction('ProductDetailsRoute', 'component')(<ProductDetailsRoute />),
    children: [
      ...productInformationRoutes,
      ...productApplicationFormRoutes,
      ...productDocumentsRoutes,
      ...productInsightsRoutes,
    ],
  },
];
