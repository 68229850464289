import Modal from 'components/modal';
import FundingRequirements from '../../../companyOverview/components/fundingRequirements';
import styles from './EditRequirementModal.module.scss';

type EditRequirementModalProps = {
  isModalOpen: boolean;
  onClose?: () => void;
  companyId: string;
  subtypeId: string;
};

const EditRequirementModal: React.FC<EditRequirementModalProps> = ({
  isModalOpen,
  onClose,
  companyId,
  subtypeId,
}) => {
  return (
    <Modal show={isModalOpen} handleClose={onClose}>
      <div className={styles.modal}>
        <FundingRequirements companyId={companyId} defaultTab={subtypeId} />
      </div>
    </Modal>
  );
};

export default EditRequirementModal;
