import { useMemo } from 'react';
import useGetSubTypeMetaData from '_shared/api/marketplaceApi/useGetSubTypeMetaData';
import { DealTypes } from 'pages/deals/types';
import { dealsTypeList } from 'pages/deals/constant';

type SubtypeEntry = { label: string; value: string };

const useDealTypes = () => {
  const { data: loansData, isLoading: isLoansLoading } = useGetSubTypeMetaData(DealTypes.LOANS);
  const { data: equityData, isLoading: isEquityLoading } = useGetSubTypeMetaData(DealTypes.EQUITY);
  const { data: taxData, isLoading: isTaxLoading } = useGetSubTypeMetaData(DealTypes.TAX);
  const { data: insuranceData, isLoading: isInsuranceLoading } = useGetSubTypeMetaData(
    DealTypes.INSURANCE
  );
  const { data: bankAccountData, isLoading: isBankAccountLoading } = useGetSubTypeMetaData(
    DealTypes.BANKACCOUNT
  );
  const { data: fxData, isLoading: isFxLoading } = useGetSubTypeMetaData(DealTypes.FX);

  const isLoading =
    isLoansLoading ||
    isEquityLoading ||
    isTaxLoading ||
    isInsuranceLoading ||
    isBankAccountLoading ||
    isFxLoading;

  const subtypesByType = useMemo<Record<DealTypes, SubtypeEntry[] | null>>(
    () => ({
      [DealTypes.LOANS]: loansData?.data.dealSubTypeLoan ?? [],
      [DealTypes.EQUITY]: equityData?.data.dealSubTypeEquity ?? [],
      [DealTypes.TAX]: taxData?.data.dealSubTypeTax ?? [],
      [DealTypes.INSURANCE]: insuranceData?.data.dealSubTypeInsurance ?? [],
      [DealTypes.BANKACCOUNT]: bankAccountData?.data.dealSubTypeBankAccount ?? [],
      [DealTypes.FX]: fxData?.data.dealSubTypesFX ?? [],
      [DealTypes.GRANTS]: null,
      [DealTypes.PENSION]: null,
      [DealTypes.ENERGY]: null,
      [DealTypes.CREDITCARD]: null,
      [DealTypes.NONE]: null,
    }),
    [loansData, equityData, taxData, insuranceData, bankAccountData, fxData]
  );

  const isInternalType = (type: DealTypes) =>
    [DealTypes.LOANS, DealTypes.EQUITY, DealTypes.GRANTS].includes(type);

  return {
    isLoading,
    isInternalType,
    types: dealsTypeList,
    subtypesByType,
  };
};

export default useDealTypes;
