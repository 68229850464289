import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export const getSavingsProductApplicationStatus = (companyId: string, productName: string) =>
  BrokerAPI.get(BrokerAPIURL.getSavingsProductApplicationStatus(companyId, productName));

export const postSavingsProductApplication = (
  companyId: string,
  productName: string,
  payload: any = {}
) => BrokerAPI.post(BrokerAPIURL.postSavingsProductApplication(companyId, productName), payload);
