import styles from 'pages/companyApplicationForm/assets/editApplicationForm.module.scss';
import Field from 'components/field';
import cn from 'classnames';
import React from 'react';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';

const ApplicationFields = ({
  sections,
  sectionHeading,
  handleChange,
  errors,
  actions,
  isClientSoleTrader,
}: any) => {
  const hasMainAction = sections.find((section: any) => section.rootId);
  const isCompanyDetails = Boolean(
    sections.find((section: any) => section.label === 'Company Address')
  );

  return (
    <div className={cn(styles['section-container'])}>
      <div className={cn(styles['section-header'])}>
        <h3>{sectionHeading}</h3>
        {(hasMainAction && (
          <Button
            id="add-new-address"
            ariaLabel="add-new-address"
            clickHandler={actions.addNewApplicant}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            Add new applicant
          </Button>
        )) ||
          null}
        {(isCompanyDetails && (
          <Button
            id="copy-registered-address"
            ariaLabel="copy-registered-address"
            clickHandler={actions.copyRegisteredAddress}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            Copy registered address to trading address
          </Button>
        )) ||
          null}
      </div>
      <div className={cn(styles['fields-container'])}>
        {sections.length &&
          sections.map((section: any) => {
            return (
              <React.Fragment key={section.id}>
                {sections.length > 1 && (
                  <div className={cn(styles['subSection-header'])}>
                    <h4>{section.name}</h4>
                    {(section.rootId.includes('new-contact') && (
                      <Button
                        id="remove-applicant"
                        ariaLabel="remove-applicant"
                        clickHandler={() => actions.removeApplicant(section.rootId)}
                        buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
                      >
                        Remove applicant
                      </Button>
                    )) ||
                      null}
                  </div>
                )}
                {section.children.map((field: any) => (
                  <Field
                    {...{
                      key: field.id,
                      metadata: {
                        ...field,
                        disabled: field.label === 'Incorporation date' && !isClientSoleTrader,
                      },
                      handleChange,
                      errors,
                    }}
                  />
                ))}
                {(section.subSections.length &&
                  section.subSections.map((subSection: any, index: number) => {
                    return (
                      <React.Fragment key={subSection.id}>
                        <div className={cn(styles['subSection-header'])}>
                          <h4>{subSection.subHeading}</h4>
                          <div className={cn(styles['subSection-actions'])}>
                            {(subSection.childId.includes('new-address') && index >= 1 && (
                              <Button
                                id="delete-new-address"
                                ariaLabel="delete-new-address"
                                clickHandler={() =>
                                  actions.removeAddress(section.rootId, subSection.childId)
                                }
                                buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
                              >
                                Remove address
                              </Button>
                            )) ||
                              null}
                            <Button
                              id="add-new-address"
                              ariaLabel="add-new-address"
                              clickHandler={() => actions.addNewAddress(section.rootId)}
                              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
                            >
                              Add new address
                            </Button>
                          </div>
                        </div>
                        {subSection.children.map((subField: any) => (
                          <Field
                            {...{
                              key: subField.id,
                              metadata: subField,
                              handleChange,
                              errors,
                            }}
                          />
                        ))}
                      </React.Fragment>
                    );
                  })) ||
                  null}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default ApplicationFields;
