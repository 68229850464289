import cn from 'classnames';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import { FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';
import { SavingsProductsFormProps } from 'pages/companySavingsProducts/types';
import Field from 'components/field';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import styles from './Form.module.scss';

const SavingsProductsForm = ({
  forwardedRef,
  fields,
  submitCallback,
  title,
  submitButtonText,
  isSubmitDisabled,
}: SavingsProductsFormProps) => {
  const { handleChange, handleSubmit, errors, metadata } = useForm(
    {},
    fields,
    submitCallback,
    validation
  );

  const renderField = (field: FieldMetaDataPropType) => {
    return (
      <Field
        key={field.name}
        {...{
          metadata: {
            ...field,
            classNames: {
              field: styles['field'],
              value:
                field.fieldType === FieldTypes.TEXTAREA
                  ? styles['text-area']
                  : styles['field-value'],
              control: styles['field-value'],
              ...field.classNames,
            },
            disabled: isSubmitDisabled,
          },
          errors: errors || [],
          handleChange: handleChange,
        }}
      />
    );
  };

  return (
    <div
      className={cn('wrapper', styles.wrapper)}
      data-testid="savings-products-form"
      ref={forwardedRef}
    >
      {metadata?.map((field) => renderField(field))}
      <Button
        clickHandler={handleSubmit}
        ariaLabel={`${title} button`}
        buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
        id="savings-products-form-button"
        style={{
          height: '52px',
          justifyContent: 'center',
          fontSize: '16px',
          width: 'fit-content',
          marginTop: '16px',
          backgroundColor: isSubmitDisabled ? '#2E9C8E' : '#003e52',
        }}
        disabled={isSubmitDisabled}
      >
        <p>{!isSubmitDisabled ? submitButtonText : 'Waiting for reply'}</p>
      </Button>
    </div>
  );
};

export default SavingsProductsForm;
