import React, { useRef, useState } from 'react';
import cn from 'classnames';
import useBlur from '_shared/hooks/useBlur';
import styles from './DealSubTypeFilter.module.scss';
import Checkbox from 'components/field/checkbox';
import { DealSubTypeFilterProps } from './types';

const DealSubTypeFilter = ({
  label,
  dealSubTypes,
  selectedDealSubType,
  onChange,
  disabled,
}: DealSubTypeFilterProps) => {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const onCheckboxChange = (selectedValue: string) => {
    onChange(
      selectedDealSubType.includes(selectedValue)
        ? selectedDealSubType.filter((value) => value !== selectedValue)
        : [...selectedDealSubType, selectedValue]
    );
  };

  useBlur(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div className={cn(styles['deal-subtype-filter-selector'])}>
      <div
        data-testid="deal-subtype-filter-dropdown-container"
        className={cn(styles['deal-subtype-filter-dropdown-container'])}
      >
        <button
          data-testid="deal-subtype-filter-dropdown-button"
          className={cn(styles['deal-subtype-filter-dropdown-button'])}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>
            {label ?? 'Selected'} ({selectedDealSubType?.length})
          </span>
          <i className={'material-icons'}>expand_more</i>
        </button>
        {(isDropdownOpen && (
          <div
            ref={dropdownRef}
            className={cn(styles['deal-subtype-filter-dropdown'])}
            data-testid="deal-subtype-filter-filter-dropdown"
          >
            <div className={cn(styles['dropdown-checkbox-container'])}>
              {dealSubTypes.map((dealSubType) => {
                return (
                  <label key={dealSubType.value} className={cn(styles['dropdown-checkbox-label'])}>
                    <Checkbox
                      {...{
                        id: dealSubType.value.toString(),
                        name: dealSubType.value.toString(),
                        handleChange: () => onCheckboxChange(dealSubType.value),
                        selected: selectedDealSubType.includes(dealSubType.value),
                        disabled,
                      }}
                    />
                    <span>{dealSubType.label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default DealSubTypeFilter;
