import { CurrencyCode, currencySymbolMap } from '_shared/utils/currency';
import styles from '../../assets/creditSafeReport.module.scss';

export const getStatusClass = (title: string, value: any) => {
  switch (title) {
    case 'Risk Score':
      return riskScoreStatus(value);
    case 'International Score':
      return internationalScoreStatus(value);
    case 'Credit Limit':
    case 'Contact Limit':
      return value && value !== 'No limit' && value !== '0'
        ? styles['status-success']
        : styles['status-undetermined'];
    case 'Status':
      return value === 'Active' ? styles['status-success'] : styles['status-undetermined'];
    default:
      return styles['status-error'];
  }
};

const riskScoreStatus = (score: string | number) => {
  const numericScore = Number(score);

  if (!isNaN(numericScore)) {
    if (numericScore < 30) {
      return styles['status-undetermined'];
    } else if (numericScore >= 30) {
      return styles['status-success'];
    }
  } else {
    return styles['status-undetermined'];
  }

  return styles['status-error'];
};

const internationalScoreStatus = (score: string) => {
  if (score === 'A' || score === 'B' || score === 'C') {
    return styles['status-success'];
  } else if (score === 'D' || score === 'E' || score === '') {
    return styles['status-undetermined'];
  } else {
    return styles['status-error'];
  }
};

// @tonote: Credit safe will mainly be Uk companies, but edge case is Australia & Canada.
export const getCurrencySymbolFromCurrencyCode = (currencyCode: string | CurrencyCode) => {
  return currencySymbolMap[currencyCode as CurrencyCode] ?? '£';
};
