import { Role } from 'config/roles';

// Describing the shape of the system's slice of state
export interface SystemState {
  currentUser?: CurrentUserType | undefined;
  accessToken?: string;
  groupId?: string;
  selectedGroups?: GroupType[];
  exposedGroups?: GroupType[];
  roles?: Role[];
  hasOnboardedGroups?: boolean;
  hasOnboardedDeals?: boolean;
  isSuperAdmin?: () => boolean;
  isAdminOrSuperAdmin?: () => boolean;
  isMarketPlaceManager?: () => boolean;
  isMarketPlaceManagerOrSuperAdmin?: () => boolean;
  isBroker?: () => boolean;
  isBrokerRoleType?: () => boolean;
  isAccountantRoleType?: () => boolean;
  hasOneOfRoles?: (roles: string[]) => boolean;
  isUserManager?: () => boolean;
}

export type CurrentUserType = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  picture?: string;
  salesforceId?: string;
  referredBy?: string;
  authId?: string;
  roles?: string[];
  groupId?: string;
  groupLogoUrl?: string;
  exposedGroups?: GroupType[];
  companies?: CurrentUserCompaniesType[];
  dateCreated?: string;
  createdBy?: string;
  dateModified?: string;
  modifiedBy?: string;
  disabledModalsList?: string[];
  userId?: string;
};

type CurrentUserCompaniesType = {
  companyId: string;
  companyName: string;
  companyNumber: string;
};

export type GroupType = {
  groupId: string;
  groupName: string;
};

export type ClientType = {
  id: string;
  name: string;
};

export type TCountry = 'unitedkingdom' | 'australia' | 'canada';

// Describing the different ACTION NAMES available
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const DISABLE_MODAL = 'DISABLE_MODAL';

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION;
  payload?: Partial<SystemState>;
}

interface DisableModalAction {
  type: typeof DISABLE_MODAL;
  payload: string;
}

export type SystemActionTypes = UpdateSessionAction | DisableModalAction;
