import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';

type FundingNeedProps = {
  fundingReasonDescription: React.ReactElement;
};

const FundingNeed: React.FC<FundingNeedProps> = ({ fundingReasonDescription }) => {
  return (
    <div className={styles['funding-need']}>
      <h2>Funding need</h2>
      <p>{fundingReasonDescription}</p>
    </div>
  );
};

export default FundingNeed;
