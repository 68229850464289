import { useEffect, useState } from 'react';
import cn from 'classnames';
import { ButtonStyleTypeEnum } from 'components/button/type';
import styles from '../../assets/historyModal.module.scss';
import Button from 'components/button';
import Modal from 'components/modal';
import { HistoryModalEnum, HistoryModalProps } from './type';
import Table from 'components/table';
import Spinner from 'components/spinner';
import { getCompanyAssignmentsHistory, getCompanyStatusHistory } from '_shared/api/businesses';

const HistoryModal = ({
  historyState,
  onModalClose,
  companyId,
  opportunityId,
  initialTableFieldState,
  tableFields,
  historyApiEndpoint,
  modalTitle,
}: HistoryModalProps) => {
  const [historyTableEntries, setHistoryTableEntries] = useState<any>(initialTableFieldState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchTableData() {
      let queryParams: any = [companyId];

      if (historyApiEndpoint === getCompanyStatusHistory) {
        queryParams.push(opportunityId);
      } else if (historyApiEndpoint === getCompanyAssignmentsHistory) {
        queryParams = [
          ...queryParams,
          historyState == HistoryModalEnum.Broker ? 'Broker' : 'AccountOwner',
        ];
      }

      const historyData = await historyApiEndpoint(...queryParams);

      const historyTableFieldData = tableFields(historyData.data, historyState ?? historyState);

      setHistoryTableEntries(historyTableFieldData);

      setIsLoading(false);
    }
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyState]);

  return (
    <Modal show={Boolean(historyState)} handleClose={onModalClose} testId={'history-modal'}>
      {isLoading ? (
        <div className={cn(styles['history-modal-loading-container'])}>
          <Spinner size="large" />
        </div>
      ) : (
        <div className={cn(styles['history-modal-wrapper'])}>
          <header className={cn(styles['header-and-icon-container'])}>
            <div>
              <h3 className={cn(styles['history-modal-header'])}>{modalTitle}</h3>
            </div>
            <div className={cn(styles['history-modal-header-icon'])}>
              <span className={cn('material-icons', styles['header-icon'])}>history</span>
            </div>
          </header>
          <div className={cn(styles['history-table'])}>
            <Table {...historyTableEntries} />
          </div>
          <div className={cn(styles['history-modal-actions-container'])}>
            <Button
              id="history-modal-cancel"
              className={cn(styles['action-button'])}
              ariaLabel="history-modal-cancel"
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              tabIndex={0}
              clickHandler={() => onModalClose()}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default HistoryModal;
