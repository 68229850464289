import cn from 'classnames';
import styles from 'pages/companyApplicationForm/assets/editApplicationForm.module.scss';
import { getApplicationMenuDetails } from './utils';

const ApplicationMenu = ({
  metadata,
  selectedMenuItem,
  handleMenuSelect,
}: {
  metadata: any;
  selectedMenuItem: string;
  handleMenuSelect: Function;
}) => {
  const { menu, completionPercentage } = getApplicationMenuDetails(metadata);

  return (
    <aside data-testid="application-menu" className={cn(styles.menu)}>
      {menu?.length &&
        menu.map(
          ({
            id,
            label,
            count,
            totalCount,
          }: {
            id: string;
            label: string;
            count: number;
            totalCount: number;
          }) => {
            return (
              <button
                id={id}
                key={label}
                className={cn(styles['menu-item'], selectedMenuItem === label && styles.active)}
                onClick={() => handleMenuSelect(label || '')}
              >
                <span>{label}</span>
                <span>
                  {count}/{totalCount}
                </span>
              </button>
            );
          }
        )}
      <div className={cn(styles.progress)}>
        <progress max="100" value={completionPercentage}></progress>
        <span>{completionPercentage || 0}%</span>
      </div>
    </aside>
  );
};

export default ApplicationMenu;
