import cn from 'classnames';
import styles from './chooseActivityModal.module.scss';
import Button from 'components/button';
import Modal from 'components/modal';
import React from 'react';
import { newActivityOptions } from '../utils';
import { ACTIVITY_CATEGORY, ChooseActivityModalPropTypes } from '../types';
import { useAppState } from 'store';
import { TaskModalEnum, UPDATE_TASK } from 'store/tasks/types';

const ChooseActivityModal = ({
  show,
  closeModal,
  chooseActivity,
}: ChooseActivityModalPropTypes) => {
  const { state: appState, dispatch: globalDispatch } = useAppState();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    closeModal();
    if (event.currentTarget.id === ACTIVITY_CATEGORY.TASK) {
      globalDispatch({
        type: UPDATE_TASK,
        payload: {
          ...appState.tasks,
          modalContext: TaskModalEnum.ADD,
          selectedTask: null,
        },
      });
    } else {
      chooseActivity(event.currentTarget.id as ACTIVITY_CATEGORY);
    }
  };

  return (
    <Modal show={show} handleClose={() => closeModal(false)}>
      <div data-testid="choose-activity-modal" className={cn(styles['choose-activity-container'])}>
        <h1 className={'sw-h1'}>Choose an Activity</h1>
        {newActivityOptions.map((activityOpt) => {
          return (
            <Button
              key={activityOpt.id}
              id={activityOpt.id}
              ariaLabel={activityOpt.label}
              className={styles['activity']}
              clickHandler={handleClick}
            >
              <i className={'material-icons'}>{activityOpt.icon}</i>
              <span>{activityOpt.label}</span>
            </Button>
          );
        })}
      </div>
    </Modal>
  );
};

export default ChooseActivityModal;
