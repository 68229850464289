import styles from './insightCard.module.scss';
import cn from 'classnames';
import { decisionTimeInsightId, fundingRequiredTimescaleMetadata } from '_shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { getMatchesByType, getInsightsByType, updateInsight } from '_shared/api/fundingMatches';
import { IInsight, IInsightCardProps } from './type';
import icons from 'config/icons';
import { useAppState } from 'store';
import { TOAST_MESSAGE } from 'store/toast/types';
import {
  ActionType,
  FundingMatchesContext,
} from 'pages/fundingMatches/store/fundingMatches.reducer';
import { contentFields } from 'pages/fundingMatches/fields/fundingMatches.fields';

const InsightCard = ({ insight, companyId, currentSubtype }: IInsightCardProps) => {
  const { t } = useTranslation();
  const { dispatch: globalDispatch } = useAppState();
  const { dispatch } = useContext(FundingMatchesContext);
  const [hidden, setHidden] = useState<boolean>(false);

  const speedName =
    insight?.id === decisionTimeInsightId &&
    fundingRequiredTimescaleMetadata.find((timescale: any) => {
      return Number(timescale.value) === Number(insight?.value);
    })?.label;

  const setOperator = (insight: IInsight) => {
    return insight?.operator === '-' ? 'subtracting' : 'adding';
  };

  const handleAccept = async (insight: IInsight) => {
    await updateInsight(insight?.performActionUrl);
    const opp = await getMatchesByType(companyId, currentSubtype);
    await new Promise((resolve) => setTimeout(resolve, 500)); //after getting recommendation result we need to wait a bit then call insights on the matches page
    if (opp.data.opportunities.length) {
      await getInsightsByType(companyId, currentSubtype);
    }

    dispatch({
      type: ActionType.SET_FUNDING_MATCHES_DATA,
      payload: {
        data: opp?.data?.opportunities,
      },
    });
    dispatch({
      type: ActionType.SET_FUNDING_MATCHES_BY_OFFER_TYPE,
      payload: contentFields(opp?.data?.opportunities),
    });
    globalDispatch({
      type: TOAST_MESSAGE,
      payload: {
        toastMessage: t('home:companydetails:fundingmatches:fundingInsights:toast'),
      },
    });
    setHidden(!hidden);
  };

  const cardName =
    insight?.id === decisionTimeInsightId ? 'decisionTimeCard' : 'fundingRequiredCard';

  return (
    <>
      {insight && (
        <div className={cn(styles[hidden ? 'hidden' : 'insight-container'])}>
          <div className={cn(styles['card'])} data-testid={cardName}>
            <div className={cn(styles['content'])}>
              {insight?.id === decisionTimeInsightId ? (
                <img
                  className={cn(styles['icon'])}
                  src={icons.decisionTimeIcon}
                  alt={t('home:companydetails:fundingmatches:fundingInsights:fundingRequired:alt')}
                />
              ) : (
                <img
                  className={cn(styles['icon'])}
                  src={icons.fundingRequiredIcon}
                  alt={t('home:companydetails:fundingmatches:fundingInsights:fundingRequired:alt')}
                />
              )}
              {insight?.id === decisionTimeInsightId ? (
                <div className={cn(styles['copy'])}>
                  <div className={cn(styles['title'])}>
                    {' '}
                    {t('home:companydetails:fundingmatches:fundingInsights:decisionTime:title')}
                  </div>
                  <span
                    className={cn(styles['description'])}
                  >{`If you increase the timing when you need the funding to ${speedName} you will see ${insight?.newMatchesCount} more matches.`}</span>
                </div>
              ) : (
                <div className={cn(styles['copy'])}>
                  <div className={cn(styles['title'])}>
                    {' '}
                    {t('home:companydetails:fundingmatches:fundingInsights:fundingRequired:title')}
                  </div>
                  <span
                    className={cn(styles['description'])}
                  >{`Adjust your funding required amount by ${setOperator(insight)} ${t(
                    'currency:symbol'
                  )}${insight?.value} to see ${insight?.newMatchesCount} other products.`}</span>
                </div>
              )}
            </div>
            <div className={cn(styles['buttons'])}>
              <button className={cn(styles['btn-primary'])} onClick={() => handleAccept(insight)}>
                <i className={cn(['material-icons'], cn(styles['icon']), cn(styles['yellow']))}>
                  lightbulb
                </i>
                <span>
                  {insight?.id === decisionTimeInsightId
                    ? `Increase to ${speedName}`
                    : `Adjust to ${t('currency:symbol')}${insight?.valueAfterUpdate}`}
                </span>
                <i className={cn(['material-icons'], cn(styles['icon']), cn(styles['white']))}>
                  arrow_forward
                </i>
              </button>
              <button className={cn(styles['btn-link'])} onClick={() => setHidden(!hidden)}>
                Not interested
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InsightCard;
