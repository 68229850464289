import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, matchPath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import cn from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import {
  deleteDeal,
  getCompanyInfo,
  getDeal,
  postDealApplication,
  putDeal,
  QueryKeys,
} from '_shared/api/businesses';
import useToastMessage from '_shared/hooks/useToastMessage';
import { Deal, DealStatus, DealTypes } from 'pages/deals/types';
import Accordion from 'components/v2/Accordion/Accordion';
import DealInfoForm, { IFormInput } from './DealInfoForm';
import HighCommissionModal from 'components/highCommissionModal';
import { calculateNetFee } from '../../utils';
import styles from './DealDetailsPage.module.scss';
import FunderTable, { UpdatePayload } from './FunderTable';
import AddMatchedFunderPanel from './AddMatchedFunderPanel';
import { dealsStatusList } from '_shared/utils/constants';
import {
  isAdvisorApplication,
  isBrokerApplication,
  SWOOP_GROUP_ID,
} from '_shared/utils/application';
import DocumentationSection from './DocumentationSection';
import StatusHistoryModal from './StatusHistoryModal';
import AddNonMatchedFunderPanel from './AddNonMatchedFunderPanel';
import ToggleButtonGroup from 'components/v2/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from 'components/v2/ToggleButton/ToggleButton';
import { RoutePath } from '_shared/routes';
import { putProductApplication, updateApplicationStatus } from '_shared/api/products';
import ApplicationDeleteModal from './ApplicationDeleteModal';
import { deleteApplication as _deleteApplication } from '_shared/api/applications';
import EditRequirementModal from './EditRequirementModal';
import Spinner from 'components/spinner';
import Menu from 'components/menu';
import MenuItem from 'components/menu/MenuItem';
import DealDeleteModal from './DealDeleteModal';
import logo from '../../../../assets/images/hubspot-logo.svg';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { Role, UserRoles } from 'config/roles';
import useGetUsersByRole from '_shared/api/roles/useGetUsersByRole';
import { getDealFieldValue } from '_shared/utils/deals';
import { dealTypeSubtypeBackendMappings } from 'pages/deals/constant';
import useDealTypes from '_shared/hooks/useDealTypes';

enum MatchToggleOption {
  Matches = 'matches',
  AllFunders = 'all-funders',
}

type DealDeleteModalMeta = {
  ownerName: string;
  companyName: string;
  type: string;
  status: string;
  numOfFunders: number;
};

export const DealDetailsPage = () => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const queryClient = useQueryClient();

  const { companyId, dealId } = useParams();
  const { state } = useAppState();
  const { t } = useTranslation();
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<any>(null);

  const [deal, setDeal] = useState<Deal | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [productIdBeingAdded, setProductIdBeingAdded] = useState<string>('');
  const [dealToSave, setDealToSave] = useState<IFormInput | null>(null);
  const [showDealDeleteModal, setShowDealDeleteModal] = useState(false);
  const [isDeletingDeal, setIsDeletingDeal] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const [applicationToUpdate, setApplicationToUpdate] = useState<{
    applicationId: string;
    opportunityId: string;
    payload: UpdatePayload;
  } | null>(null);
  const [applicationToDelete, setApplicationToDelete] = useState<any>(null);
  const [isSavingApplication, setIsSavingApplication] = useState(false);
  const [isDeletingApplication, setIsDeletingApplication] = useState(false);

  const [editingSubtypeId, setEditingSubtypeId] = useState<string | null>(null);

  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showNonMatchedFunders, setShowNonMatchedFunders] = useState<MatchToggleOption>(
    MatchToggleOption.Matches
  );
  const isSwoopGroupUser = state.system.groupId === SWOOP_GROUP_ID;

  const currentRoleId =
    state.system?.roles?.find((role: Role) => {
      let roleName;
      if (isBrokerApplication) {
        roleName = UserRoles.BROKER;
      } else if (isAdvisorApplication) {
        roleName = UserRoles.ADVISOR;
      }
      return role.name === roleName;
    })?.id ?? '';

  const { data: usersData } = useGetUsersByRole(currentRoleId);
  const { types: dealTypes, subtypesByType: dealSubtypesByType } = useDealTypes();

  const companyName = companyInfo?.companyName ?? 'Unknown';

  const dealSubtypeLabel = (dealType: string, dealSubtype: string = '') => {
    let label;

    if (dealSubtypesByType[dealType as DealTypes]) {
      label = dealSubtypesByType[dealType as DealTypes]?.find(
        ({ value }: any) => value === dealSubtype
      )?.label;
    }

    if (!label) {
      label = dealTypes.find(({ value }) => value === dealType)?.label;
    }

    return label ?? 'Unknown';
  };

  const dealTitle = useMemo(() => {
    if (!deal) return null;

    const dealType = getDealFieldValue(deal, 'Opportunity.Type') ?? '';
    const dealSubtypeId = getDealFieldValue(deal, 'Opportunity.Subtype') ?? '';

    return dealSubtypeLabel(dealType, dealSubtypeId);
  }, [deal, dealSubtypesByType]);

  const dealLastModifiedDate = useMemo(
    () => (deal ? getDealFieldValue(deal, 'Opportunity.DateModified') ?? '' : ''),
    [deal]
  );

  const isClientSoleTrader = useMemo(
    () => !!deal && getDealFieldValue(deal, 'Company.RegistrationNumber') === 'SoleTrader',
    [deal]
  );

  const inDealApplications = useMemo(
    () =>
      deal?.applications.filter(
        ({ applicationStatus }: any) =>
          ![dealsStatusList['ClosedWon'].value, dealsStatusList['ClosedLost'].value].includes(
            applicationStatus
          )
      ) ?? [],
    [deal]
  );

  const inDealProductIds = useMemo(
    () => inDealApplications.map(({ productId }: any) => productId) ?? [],
    [inDealApplications]
  );

  const formDefaultValues = useMemo<IFormInput>(() => {
    const valuesByFieldName = deal
      ? deal.fieldValues.reduce((acc: any, { name, value }) => {
          acc[name] = value;
          return acc;
        }, {})
      : {};

    return {
      Type: valuesByFieldName['Opportunity.Type'] ?? '',
      Owner: valuesByFieldName['Opportunity.Owner'] ?? '',
      OwnerName: valuesByFieldName['Opportunity.OwnerName'] ?? '',
      OwnerPhotoUrl: valuesByFieldName['Opportunity.OwnerPhotoUrl'] ?? '',
      Subtype: valuesByFieldName['Opportunity.Subtype'] ?? '',
      // DMS-329: A temporary fix for the issue where the Stage field can be missing in some deals in prod
      Stage: valuesByFieldName['Opportunity.Stage'] ?? DealStatus.Created,
      ClosedDate: valuesByFieldName['Opportunity.ClosedDate'] ?? '',
      ValuerDetails: valuesByFieldName['Opportunity.ValuerDetails'] ?? '',
      Amount: valuesByFieldName['Opportunity.Amount'] ?? '0',
      FunderComission: valuesByFieldName['Opportunity.FunderComission'] ?? '0',
      ClientFee: valuesByFieldName['Opportunity.ClientFee'] ?? '0',
      IntroducerFee: valuesByFieldName['Opportunity.IntroducerFee'] ?? '0',
      RebrokeOpportunity: valuesByFieldName['Opportunity.RebrokeOpportunity'] ?? '',
      RebrokeDate: valuesByFieldName['Opportunity.RebrokeDate'] ?? '',
      ClosedLostDetail: valuesByFieldName['Opportunity.ClosedLostDetail'] ?? '',
      ClosedLostReason: valuesByFieldName['Opportunity.ClosedLostReason'] ?? '',
      RevisitDate: valuesByFieldName['Opportunity.RevisitDate'] ?? '',
      WorthRevisiting: valuesByFieldName['Opportunity.WorthRevisiting'] ?? '',
    };
  }, [deal]);

  const dealDeleteModalMeta = useMemo<DealDeleteModalMeta | null>(() => {
    if (!deal) return null;

    const dealTypeValue = getDealFieldValue(deal, 'Opportunity.Type');
    const dealSubtypeValue = getDealFieldValue(deal, 'Opportunity.Subtype');

    const typeLabel = dealSubtypeLabel(dealTypeValue!, dealSubtypeValue!);

    return {
      type: typeLabel,
      numOfFunders: deal.applications.length,
      companyName: companyInfo?.companyName ?? 'Unknown',
      status: getDealFieldValue(deal, 'Opportunity.Stage') ?? '',
      ownerName: getDealFieldValue(deal, 'Opportunity.OwnerName') ?? '',
    };
  }, [dealSubtypesByType, deal, companyInfo]);

  const applicationCommissionModalMeta = useMemo<{
    funder: string;
    productName: string;
  } | null>(() => {
    if (!deal?.applications || !applicationToUpdate) return null;

    const application = deal.applications.find(
      ({ id }) => id === applicationToUpdate.applicationId
    );

    return {
      funder: application?.providerName ?? '',
      productName: application?.productName ?? '',
    };
  }, [deal, applicationToUpdate]);

  const hubSpotLink = useMemo(() => {
    if (!deal) return null;

    const isInHubSpot = getDealFieldValue(deal, 'Opportunity.IsInHubSpot') === 'True';

    return isInHubSpot ? getDealFieldValue(deal, 'Opportunity.HubSpotLink') : null;
  }, [deal]);

  const goBack = () => {
    const isFromClientDealList = !!matchPath(RoutePath.companydeal, pathname);

    if (locationState?.from && locationState?.search) {
      return navigate(`${locationState.from}${locationState.search}`);
    }

    if (isFromClientDealList) {
      return navigate(RoutePath.companydeals.replace(':id', companyId!));
    }

    return navigate(RoutePath.deals);
  };

  const onDealSave = useCallback((values: IFormInput) => {
    const amount = parseFloat(values.Amount);
    const funderCommission = parseFloat(values.FunderComission);
    const clientFee = parseFloat(values.ClientFee);
    const introducerFee = parseFloat(values.IntroducerFee);
    const netFee = calculateNetFee(funderCommission, clientFee, introducerFee);
    const feeThreshold = amount * 0.1;

    const hasHighCommission = [funderCommission, clientFee, introducerFee, netFee].some(
      (fee) => fee > feeThreshold
    );

    if (netFee < 0) {
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:invalidnetfee'));
      return;
    }

    if (hasHighCommission) {
      setDealToSave(values);
    } else {
      void saveDeal(values);
    }
  }, []);

  const saveDeal = async (values: IFormInput) => {
    const fieldFilter = ([fieldName]: [string, string]) => {
      const closedLostFields = [
        'ClosedLostDetail',
        'ClosedLostReason',
        'RevisitDate',
        'WorthRevisiting',
      ];
      const closedWonFields = ['RebrokeOpportunity', 'RebrokeDate'];

      if (fieldName === 'Subtype' && dealSubtypesByType[values.Type as DealTypes] === null) {
        return false;
      }

      if (values.Stage === DealStatus.ClosedLost && values.WorthRevisiting === 'false') {
        return !closedWonFields.includes(fieldName) && fieldName !== 'RevisitDate';
      } else if (values.Stage === DealStatus.ClosedLost) {
        return !closedWonFields.includes(fieldName);
      } else if (values.Stage === DealStatus.ClosedWon && values.RebrokeOpportunity === 'false') {
        return !closedLostFields.includes(fieldName) && fieldName !== 'RebrokeDate';
      } else if (values.Stage === DealStatus.ClosedWon) {
        return !closedLostFields.includes(fieldName);
      }

      return !closedLostFields.concat(closedWonFields).includes(fieldName);
    };

    const fieldsToUpdate = Object.entries(values)
      .filter(fieldFilter)
      .map(([name, Value]) => ({
        Name: `Opportunity.${name}`,
        Value,
        readonly: false,
      }));

    if (dealSubtypesByType[values.Type as DealTypes] === null && !values.Subtype) {
      fieldsToUpdate.push({
        Name: 'Opportunity.Subtype',
        Value: dealTypeSubtypeBackendMappings[values.Type],
        readonly: false,
      });
    }

    // Adding fields that are not in the form
    fieldsToUpdate.push({
      Name: 'Opportunity.NetFee',
      Value: calculateNetFee(
        parseFloat(values.FunderComission),
        parseFloat(values.ClientFee),
        parseFloat(values.IntroducerFee)
      ).toString(),
      readonly: false,
    });

    try {
      setDealToSave(null);
      setIsSaving(true);
      await putDeal(companyId!, dealId!, { fieldsToUpdate });

      successToastMessage(t('home:deals:dealdetailsmodal:toast:updatesuccess'));
      void fetchCompanyAndDeal();
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });
    } catch (e) {
      console.error(e);
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:updatefailed'));
    } finally {
      setIsSaving(false);
    }
  };

  const addFunderToDeal = async (productId: string) => {
    try {
      setProductIdBeingAdded(productId);
      await postDealApplication(companyId!, productId, {
        productName: '',
        applicationStatus: 'Created',
        productCategory: 'BusinessLoan',
        opportunityId: dealId,
      });

      successToastMessage(
        t('home:companydetails:fundingmatches:nonmatchedproducts:modal:toast:addfunderesuccess')
      );

      // Unfortunately, on the backend,
      // the deal is not updated immediately after a funder is added to the deal
      await new Promise((resolve) => setTimeout(resolve, 5000));

      void fetchCompanyAndDeal();
    } catch (e) {
      console.error(e);
      errorToastMessage(
        t('home:companydetails:fundingmatches:nonmatchedproducts:modal:toast:addfunderfailed')
      );
    } finally {
      setProductIdBeingAdded('');
    }
  };

  const fetchCompanyAndDeal = useCallback(async () => {
    try {
      setIsLoading(true);
      const [{ data: companyInfo }, { data: deal }] = await Promise.all([
        getCompanyInfo(companyId!),
        getDeal(companyId!, dealId!),
      ]);

      setCompanyInfo(companyInfo);
      setDeal(deal);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [companyId, dealId]);

  const onUpdateApplication = async (
    applicationId: string,
    opportunityId: string,
    payload: UpdatePayload
  ) => {
    const netFee = calculateNetFee(
      payload.funderCommission,
      payload.clientFee,
      payload.introducerFee
    );
    const feeThreshold = payload.fundingAmount * 0.1;

    const hasHighCommission = [
      payload.funderCommission,
      payload.clientFee,
      payload.introducerFee,
      netFee,
    ].some((fee) => fee > feeThreshold);

    if (netFee < 0) {
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:invalidnetfee'));
      return;
    }

    if (hasHighCommission) {
      setApplicationToUpdate({ applicationId, opportunityId, payload });
    } else {
      void updateApplication(applicationId, opportunityId, payload);
    }
  };

  const updateApplication = async (
    applicationId: string,
    opportunityId: string,
    { status, ...payload }: UpdatePayload
  ) => {
    const closeDate = getDealFieldValue(deal!, 'Opportunity.ClosedDate') || new Date();

    const netFee = calculateNetFee(
      payload.funderCommission,
      payload.clientFee,
      payload.introducerFee
    );

    try {
      setIsSavingApplication(true);
      // These calls must be done in sequence due to a concurrency issue on the backend (DMS-486)
      await putProductApplication(companyId!, applicationId, {
        ...payload,
        netFee,
        closeDate,
      });
      await updateApplicationStatus(companyId!, applicationId, {
        Status: status,
        OpportunityId: opportunityId,
      });
      successToastMessage(t('home:deals:dealdetailsmodal:toast:updatefundersuccess'));

      // Unfortunately, on the backend,
      // the deal is not updated immediately after the application is updated
      await new Promise((resolve) => setTimeout(resolve, 2000));
      void fetchCompanyAndDeal();
      setApplicationToUpdate(null);
    } catch (error) {
      console.error(error);
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:updatefunderfailed'));
    } finally {
      setIsSavingApplication(false);
    }
  };

  const onDeleteApplication = async (applicationId: string) => {
    const application = deal!.applications.find(({ id }: any) => id === applicationId);
    setApplicationToDelete(application);
  };

  const deleteApplication = async () => {
    try {
      setIsDeletingApplication(true);

      await _deleteApplication(companyId!, applicationToDelete.id);
      successToastMessage(t('home:deals:dealdetailsmodal:toast:removedfundersuccess'));

      // Unfortunately, on the backend,
      // the deal is not updated immediately after the application is deleted
      await new Promise((resolve) => setTimeout(resolve, 2000));
      void fetchCompanyAndDeal();

      setApplicationToDelete(null);
    } catch (error) {
      console.error(error);
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:removedfunderfailed'));
    } finally {
      setIsDeletingApplication(false);
    }
  };

  const onDealDeleteConfirm = async () => {
    setIsDeletingDeal(true);

    try {
      await deleteDeal(companyId!, dealId!);
      successToastMessage(t('home:deals:dealdetailsmodal:toast:deletesucess'));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      goBack();

      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });
    } catch (e) {
      console.error(e);
      errorToastMessage(t('home:deals:dealdetailsmodal:toast:deletefailed'));
    } finally {
      setIsDeletingDeal(false);
    }
  };

  useEffect(() => {
    void fetchCompanyAndDeal();
  }, []);

  return (
    <>
      {isLoading && (
        <div className={styles['loading-screen']}>
          <Spinner size="large" />
        </div>
      )}

      <div className={cn({ [styles['hidden']]: isLoading })}>
        <div className={styles.nav}>
          <button onClick={goBack}>
            <i className="material-icons">arrow_back</i>Back
          </button>
          <h1 className={styles.title}>
            {companyName} - {dealTitle}
          </h1>
          <div className={styles.spacer} />
          {isSwoopGroupUser && hubSpotLink && (
            <Button
              className={styles['hubspot-button']}
              clickHandler={() => {
                window.open(hubSpotLink, '_blank');
              }}
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              icon={<img alt="hubspot-logo" src={logo} className={styles['hubspot-logo']} />}
            >
              Deal Details
            </Button>
          )}
          <button
            className={styles['delete-btn']}
            onClick={(e) => {
              setMenuAnchorEl(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <i className="material-icons">more_horiz</i>
          </button>
        </div>

        <Accordion className={styles['section']} title="Info" defaultExpanded>
          <DealInfoForm
            deal={deal!}
            users={usersData?.data.results ?? []}
            values={formDefaultValues}
            lastModifiedDate={dealLastModifiedDate}
            isSaving={isSaving}
            advisorView={isAdvisorApplication}
            onSave={onDealSave}
            onViewStatusHistory={() => setShowStatusHistory(true)}
          />
        </Accordion>
        <Accordion className={styles['section']} title="Funders" defaultExpanded>
          <FunderTable
            applications={deal?.applications ?? []}
            onUpdate={onUpdateApplication}
            onDelete={onDeleteApplication}
            disabled={isSavingApplication || isDeletingApplication}
            advisorView={isAdvisorApplication}
          />
        </Accordion>
        {isBrokerApplication && (
          <Accordion className={styles['section']} title="Add New Funders">
            <div className={styles['add-funders-bar']}>
              <ToggleButtonGroup
                value={showNonMatchedFunders}
                onChange={(v) => setShowNonMatchedFunders(v as MatchToggleOption)}
              >
                <ToggleButton value={MatchToggleOption.Matches}>Matches</ToggleButton>
                <ToggleButton value={MatchToggleOption.AllFunders}>All Funders</ToggleButton>
              </ToggleButtonGroup>
            </div>

            {showNonMatchedFunders === MatchToggleOption.AllFunders ? (
              <AddNonMatchedFunderPanel
                inDealProductIds={inDealProductIds}
                isSwoopGroupUser={state.system.groupId === SWOOP_GROUP_ID}
                readonly={isAdvisorApplication}
                processingProductId={productIdBeingAdded}
                onAddToDeal={addFunderToDeal}
              />
            ) : (
              <AddMatchedFunderPanel
                companyId={companyId!}
                inDealProductIds={inDealProductIds}
                isSwoopGroupUser={state.system.groupId === SWOOP_GROUP_ID}
                readonly={isAdvisorApplication}
                processingProductId={productIdBeingAdded}
                onAddToDeal={addFunderToDeal}
                onEditRequirements={(id) => {
                  setEditingSubtypeId(id);
                }}
              />
            )}
          </Accordion>
        )}

        {isBrokerApplication && (
          <Accordion className={styles['section']} title="Required Documentation">
            <DocumentationSection
              companyId={companyId!}
              applications={inDealApplications}
              isClientSoleTrader={isClientSoleTrader}
            />
          </Accordion>
        )}
      </div>

      <StatusHistoryModal
        isOpen={showStatusHistory}
        onClose={() => setShowStatusHistory(false)}
        companyId={companyId!}
        opportunityId={dealId!}
      />

      {dealDeleteModalMeta && (
        <DealDeleteModal
          isModalOpen={showDealDeleteModal}
          companyName={dealDeleteModalMeta.companyName}
          ownerName={dealDeleteModalMeta.ownerName}
          status={dealDeleteModalMeta.status}
          type={dealDeleteModalMeta.type}
          numOfFunders={dealDeleteModalMeta.numOfFunders}
          isLoading={isDeletingDeal}
          onClose={() => setShowDealDeleteModal(false)}
          onConfirm={onDealDeleteConfirm}
        />
      )}

      <ApplicationDeleteModal
        isModalOpen={!!applicationToDelete}
        isLoading={isDeletingApplication}
        onClose={() => setApplicationToDelete(null)}
        onConfirm={deleteApplication}
        providerName={applicationToDelete?.providerName}
        productName={applicationToDelete?.productName}
        dateCreated={applicationToDelete?.dateCreated}
      />

      {editingSubtypeId && (
        <EditRequirementModal
          isModalOpen
          companyId={companyId!}
          subtypeId={editingSubtypeId}
          onClose={() => {
            setEditingSubtypeId(null);
            void fetchCompanyAndDeal();
          }}
        />
      )}

      {applicationToUpdate && (
        <HighCommissionModal
          isModalOpen
          isLoading={isSavingApplication}
          onClose={() => setApplicationToUpdate(null)}
          onConfirm={() =>
            updateApplication(
              applicationToUpdate.applicationId,
              applicationToUpdate.opportunityId,
              applicationToUpdate.payload
            )
          }
          companyName={companyName}
          funder={applicationCommissionModalMeta?.funder}
          productName={applicationCommissionModalMeta?.productName}
          amount={applicationToUpdate.payload.fundingAmount}
          funderCommission={applicationToUpdate.payload.funderCommission}
          clientFee={applicationToUpdate.payload.clientFee}
          introducerFee={applicationToUpdate.payload.introducerFee}
        />
      )}

      {dealToSave && (
        <HighCommissionModal
          isModalOpen
          onClose={() => setDealToSave(null)}
          onConfirm={() => saveDeal(dealToSave)}
          companyName={companyName}
          dealType={dealSubtypeLabel(dealToSave.Type, dealToSave.Subtype)}
          amount={parseFloat(dealToSave.Amount)}
          funderCommission={parseFloat(dealToSave.FunderComission)}
          clientFee={parseFloat(dealToSave.ClientFee)}
          introducerFee={parseFloat(dealToSave.IntroducerFee)}
        />
      )}

      <Menu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuItem icon="delete" onClick={() => setShowDealDeleteModal(true)}>
          Delete Deal
        </MenuItem>
      </Menu>
    </>
  );
};
