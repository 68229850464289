import classNames from 'classnames';
import Spinner from 'components/spinner';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { useEffect, useRef, useState } from 'react';
import { AccordionItemPropType } from '../types';
import styles from './accordionItem.module.scss';

const AccordionItem: React.FC<any> = ({
  title,
  content,
  collapsedByDefault = true,
  saveButton,
  ableToSave,
  loading,
  compact = false,
  darkHeader = false,
}: AccordionItemPropType) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsedByDefault);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  // Maintain content height while loading
  useEffect(() => {
    if (ref.current?.clientHeight && ref.current?.clientHeight !== height) {
      setHeight(ref?.current?.clientHeight);
    }
  }, [content, ref]);

  return (
    <div key={title} className={classNames(styles['accordion-item-wrapper'])}>
      {title && (
        <div className={classNames(styles['header-container'])}>
          <div
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              sendAmplitudeData(AmplitudeTrackingEnum.accordiansectiontoggle, {
                section: title,
                toggleDirection: !isCollapsed ? 'Collapse' : 'Expand',
              });
            }}
            className={classNames([
              styles['title-and-icon-container'],
              { [styles['title-and-icon-container--dark']]: darkHeader },
            ])}
          >
            <span
              className={classNames(
                'material-icons',
                styles['dropdown-icon'],
                !isCollapsed && styles['dropdown-icon-active']
              )}
            >
              arrow_forward_ios
            </span>
            <h3
              className={classNames(
                'sw-h3',
                styles['title'],
                !isCollapsed && styles['title--active']
              )}
            >
              {title}
            </h3>
          </div>
          {ableToSave && Boolean(saveButton) ? saveButton : null}
        </div>
      )}

      {loading ? (
        <div
          data-testid="accordion-item-loading"
          className={classNames(styles['loading-container'])}
          style={{ height }}
        >
          <span className={styles.loading}>
            <Spinner size="large" />
          </span>
        </div>
      ) : (
        !isCollapsed && (
          <div
            className={classNames([
              styles['accordion-item-content'],
              { [styles['accordion-item-content--compact']]: compact },
            ])}
            ref={ref}
          >
            {content}
          </div>
        )
      )}
    </div>
  );
};

export default AccordionItem;
