// Adjusts the position of the elements list (top or bottom).
export function checkPosition(
  element: HTMLElement | null,
  reposition: Function,
  closestElementToCheck?: string
) {
  if (!element) return;

  let heightToCompare = window.innerHeight;
  if (closestElementToCheck) {
    heightToCompare = element.closest(closestElementToCheck)?.clientHeight || 0;
  }

  const spaceAbove = element.getBoundingClientRect().top;
  const spaceBelow = heightToCompare - element.getBoundingClientRect().bottom + 100;

  if (spaceBelow > spaceAbove) {
    reposition(element, 'bottom');
  } else {
    reposition(element, 'up');
  }
}
