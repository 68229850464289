import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';

import { FormFieldEssentials } from 'pages/companyEssentials/types';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { convertToCurrency } from '_shared/utils/currency';
import SummarisedJourney from '../summarisedJourney';

type MachineryEquipmentEssentialsProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

const MachineryEquipmentEssentials: React.FC<MachineryEquipmentEssentialsProps> = ({
  fetchedData,
  dateCreated,
  editDetails,
  journeyDisplayName,
  journeyName,
}) => {
  const fieldsConfig = [
    {
      fieldName: 'hasBeenProfitable',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value
              ? 'Profitable in the last 12 months'
              : 'Not profitable in the last 12 months'}
          </p>
        ),
    },
    {
      fieldName: 'equipmentType',
      render: (field: FormFieldEssentials) => {
        const conditionField = getFieldByName('equipmentCondition');
        const condition = conditionField?.value
          ? conditionField.value.toString().toLowerCase()
          : '';

        const assetPurchasePriceField = getFieldByName('assetPurchasePrice');
        const assetPurchasePrice = assetPurchasePriceField?.value
          ? convertToCurrency(assetPurchasePriceField.value.toString())
          : '';

        let financeText = '';
        if (field.value) {
          const equipmentCondition = condition ? condition : '';
          const equipmentType = field.value ? field.value.toString().toLowerCase() : '';
          const priceText = assetPurchasePrice ? `for ${assetPurchasePrice}` : '';

          financeText = `Is looking to finance ${equipmentCondition} ${equipmentType} ${priceText}`;
        }

        return (
          financeText !== '' && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {financeText}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'IsReadyToOrder',
      render: (field: FormFieldEssentials) => {
        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value ? `Ready to order` : 'Not ready to order'}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'fundsAvailableToContribute',
      render: (field: FormFieldEssentials) => {
        const fundsText = field.value ? convertToCurrency(field.value.toString()) : '';
        const contributionText = field.value
          ? `Has ${fundsText} to contribute`
          : 'Has no funds available to contribute';

        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {contributionText}
            </p>
          )
        );
      },
    },
  ];

  const getFieldByName = (name: string) => fetchedData.find((field) => field.name === name);

  return <SummarisedJourney data={fetchedData} fields={fieldsConfig} />;
};

export default MachineryEquipmentEssentials;
