import { ApplicationFieldType, companyFieldDefinitionsApi, formSectionApi } from '../types';

export const applicationFormsMetadata = (companyFieldDefinitions: companyFieldDefinitionsApi) => {
  const { fieldDefinitions, formSections } = companyFieldDefinitions;

  return (
    (formSections.length &&
      formSections.map((formSection: formSectionApi) => {
        const formFields = fieldDefinitions
          .filter((fieldDef: any) => {
            return fieldDef.formSectionId === formSection.formSectionId;
          })
          .sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

        return {
          id: formSection.formSectionId,
          name: formSection.displayName,
          displayOrder: formSection.displayOrder,
          selectAll: false,
          fields: applicationFields(formFields),
        };
      })) ||
    []
  );
};

export const applicationFields = (
  items: Array<{ [key: string]: any }>
): Array<ApplicationFieldType> => {
  return items.map((item: any): ApplicationFieldType => {
    return {
      id: item.fieldDefinitionId,
      name: item.name,
      displayName: alterFieldDisplayName(item.displayName, item.name),
      displayOrder: item.displayOrder,
      isRequired: false,
    };
  });
};

const alterFieldDisplayName = (displayName: string, name: string) => {
  if (name.includes('TradingAddress')) {
    return `${displayName} (Trading)`;
  } else if (name.includes('RegisteredAddress')) {
    return `${displayName} (Registered)`;
  } else {
    return displayName;
  }
};

export const defaultFieldDefinitions = [
  'Company.RegistrationNumber',
  'Company.RegisteredName',
  'Company.RegisteredAddressLine1',
  'Company.RegisteredAddressCity',
  'Company.RegisteredAddressCountry',
  'Company.RegisteredAddressPostcode',
];
