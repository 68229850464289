import styles from './AddTaskModal.module.scss';
import { ValidationTypes, FieldMetaDataPropType, FieldTypes } from '_shared/fieldValidation/types';
import { AutocompleteActionType } from 'components/field/autocomplete/type';
import { getCompanies } from '_shared/api/businesses';
import { isAdvisorApplication } from '_shared/utils/application';
import { getUsersByRole } from '_shared/api/roles';
import { Role, UserRoles } from 'config/roles';
import { displayStringValue } from '_shared/utils/string';
import {
  CompanyAutoCompleteType,
  TaskType,
  UserAutoCompleteType,
} from 'pages/tasksManagement/types';

const companiesCallBack = async (_action: AutocompleteActionType, companyName: string) => {
  if (!companyName || typeof companyName !== 'string') return [];

  const { data } = await getCompanies({ companyName });
  const { results: searchedCompanies } = data;

  return searchedCompanies.map((company: CompanyAutoCompleteType) => {
    return {
      label: company.name,
      value: company.name,
      id: company.id,
    };
  });
};

export const addTaskModalFields = (
  roles?: Role[],
  task?: TaskType | null
): Array<FieldMetaDataPropType> => {
  const usersCallBack = async (_action: AutocompleteActionType, userName: string) => {
    const brokerRole = roles?.find((role: Role) => role.name === UserRoles.BROKER);
    const advisorRole = roles?.find((role: Role) => role.name === UserRoles.ADVISOR);

    if (!brokerRole?.id || !advisorRole?.id) return;

    const [{ data: brokerUsers }, { data: advisorUsers }] = await Promise.all([
      getUsersByRole(brokerRole.id),
      getUsersByRole(advisorRole.id),
    ]);

    const userList = isAdvisorApplication ? advisorUsers.results : brokerUsers.results;

    const users = userList?.filter((user: UserAutoCompleteType) => {
      return user.fullName.toLowerCase().includes(userName.toString().toLowerCase());
    });

    return users.map((filteredUser: UserAutoCompleteType) => {
      return {
        label: filteredUser.fullName,
        value: filteredUser.fullName,
        id: filteredUser.securityId,
      };
    });
  };
  return [
    {
      id: 'companyName',
      name: 'companyName',
      label: 'Client Name',
      placeholder: 'Client Name',
      callBack: companiesCallBack,
      fieldType: FieldTypes.AUTOCOMPLETE,
      validationtype: ValidationTypes.AUTOCOMPLETE,
      value: task?.companyName ? task?.companyName : '',
      options: [],
      tabIndex: 1,
      required: true,
      classNames: {
        control: styles['autocomplete-input'],
      },
    },
    {
      id: 'task-subject',
      name: 'subject',
      label: 'Subject',
      validationtype: ValidationTypes.TEXT,
      value: task?.subject ? task?.subject : '',
      placeholder: 'Subject',
      required: true,
      type: 'text',
      fieldType: FieldTypes.TEXT,
    },
    {
      id: 'task-detail',
      name: 'content',
      label: 'Details (optional)',
      validationtype: ValidationTypes.TEXT,
      value: task?.content ? task?.content : '',
      placeholder: 'Details (optional)',
      type: 'text',
      fieldType: FieldTypes.EDITOR,
      classNames: {
        field: styles['content-field'],
      },
    },
    {
      id: 'task-assignee',
      name: 'assignedToId',
      label: 'Assignee',
      value: task?.assignedToFullName ? task?.assignedToFullName : '',
      placeholder: 'Assignee',
      callBack: usersCallBack,
      fieldType: FieldTypes.AUTOCOMPLETE,
      validationtype: ValidationTypes.AUTOCOMPLETE,
      options: [],
      required: true,
      tabIndex: 1,
      classNames: {
        control: styles['autocomplete-input'],
      },
    },
    {
      id: 'task-due-date',
      name: 'dueDate',
      label: 'Due Date',
      validationtype: ValidationTypes.DATE,
      value: task?.dueDate ? task?.dueDate : '',
      required: true,
      placeholder: '',
      fieldType: FieldTypes.DATEPICKER,
    },
    {
      id: 'task-status',
      name: 'status',
      label: 'Status',
      validationtype: ValidationTypes.SELECT,
      required: true,
      value: task?.taskStatus ? task?.taskStatus : '',
      placeholder: 'Task status',
      options: [
        {
          label: displayStringValue('ToDo', { applySentenceCase: true }),
          value: 'ToDo',
        },
        {
          label: displayStringValue('InProgress', { applySentenceCase: true }),
          value: 'InProgress',
        },
        {
          label: 'Done',
          value: 'Done',
        },
      ],
      fieldType: FieldTypes.SELECT,
    },
  ];
};
