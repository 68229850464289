import cn from 'classnames';
import Spinner from 'components/spinner';
import Table from 'components/table';
import styles from 'pages/fundingMatches/assets/productApplication.module.scss';
import { field } from 'pages/fundingMatches/fields/productApplicationRequiredDocuments.field';
import { ActionType } from 'pages/fundingMatches/store/productApplication.reducer';
import { useTranslation } from 'react-i18next';

const RequiredDocuments = ({ requiredDocuments, dispatch, companyId, loading }: any) => {
  const { t } = useTranslation();

  const DocumentTable = () => {
    return (
      (requiredDocuments?.length && (
        <Table
          {...field(requiredDocuments, (event: any) => {
            dispatch({
              type: ActionType.SET_DOCUMENT_UPLOAD_MODAL_OPEN,
              payload: {
                documentUploadModalOpen: true,
                title: event.target.getAttribute('data-id'),
                category: event.target.getAttribute('data-name'),
              },
            });
          })}
          loading={false}
          emptyMessage={t('home:companies:search:empty')}
        />
      )) || <div className={cn(styles['no-required-documents'])}>No Required Documents</div>
    );
  };

  return (
    <>
      <div className={cn(styles.section)}>
        <div className={cn(styles['section-heading'])}>
          <h3>Part 1) Required documents</h3>
        </div>
        <hr className={cn(styles.divider)} />
        {(loading && (
          <div className={cn(styles['loading-container'])}>
            <Spinner size="large" />
            <span>
              {t('home:companydetails:fundingmatches:fundingrequirements:loadingrequireddocuments')}
            </span>
          </div>
        )) || <DocumentTable />}
      </div>
    </>
  );
};

export default RequiredDocuments;
