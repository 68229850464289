import cn from 'classnames';
import { InfoBox, SavingsProductsQuickInfoBoxProps } from 'pages/companySavingsProducts/types';
import styles from './QuickInfoBox.module.scss';
import AmountIcon from '../../assets/amount-icon.svg';
import PurposeIcon from '../../assets/purpose-icon.svg';
import SpeedIcon from '../../assets/speed-icon.svg';
import SuitableforIcon from '../../assets/suitablefor-icon.svg';
import CostIcon from '../../assets/cost-icon.svg';

export default function SavingsProductsQuickInfoBox({
  descriptions,
}: Readonly<SavingsProductsQuickInfoBoxProps>) {
  const items: InfoBox[] = [
    { title: 'Amount', icon: AmountIcon, description: descriptions?.amount },
    { title: 'Purpose', icon: PurposeIcon, description: descriptions?.purpose },
    { title: 'Speed', icon: SpeedIcon, description: descriptions?.speed },
    { title: 'Suitable for', icon: SuitableforIcon, description: descriptions?.suitablefor },
    { title: 'Cost', icon: CostIcon, description: descriptions?.cost },
  ].filter((box) => box.description); // remove boxes which do not have descriptions

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      {items.map((box) => (
        <div key={box.title} className={cn('infobox', styles['infobox'])}>
          <div className={cn('title-container', styles['title-container'])}>
            <img src={box.icon} alt={`${box.title} icon`} />
            <h3>{box.title}</h3>
          </div>
          <p className={styles['description']}>{box.description}</p>
        </div>
      ))}
    </div>
  );
}
