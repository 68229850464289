import { displayStringValue, formatAddress } from '_shared/utils/string';
import { convertToLocalDateFormat } from '_shared/utils/date';
import styles from 'pages/companyOverview/assets/companyOverview.module.scss';
import {
  FieldMetaDataPropType,
  FieldTypes,
  ActionTypes,
  ReadOnlyTypes,
} from '_shared/fieldValidation/types';
import { AmplitudeTrackingEnum } from 'config/amplitude';
import features from 'config/features';

const defaultStyles = {
  field: styles['field-holder'],
  label: styles.label,
};

export const companyAccountDateString = ({
  dueBy,
  lastAccountsMadeUpTo,
  nextAccountsMadeUpTo,
  incorporationDate,
}: {
  dueBy?: string;
  lastAccountsMadeUpTo?: string;
  nextAccountsMadeUpTo?: string;
  incorporationDate?: string;
}) => {
  const date = new Date();
  const isIncorpDateLessThanOneYear =
    incorporationDate &&
    new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()).getTime() -
      new Date(incorporationDate).getTime() <
      0;

  if (isIncorpDateLessThanOneYear) {
    return 'There are no previous accounts available for this business';
  }

  const next =
    (nextAccountsMadeUpTo &&
      `Next accounts made up to ${convertToLocalDateFormat(nextAccountsMadeUpTo)}, `) ||
    '';

  const due = (dueBy && `due by ${convertToLocalDateFormat(dueBy)}. `) || '';
  const last =
    (lastAccountsMadeUpTo &&
      `Last accounts made up to ${convertToLocalDateFormat(lastAccountsMadeUpTo)}`) ||
    '';

  return `${next}${due}${last}`;
};

export function companyInfoFields(initialValue: {
  [key: string]: any;
}): Array<FieldMetaDataPropType> {
  const companyStatus = initialValue?.companyStatus && initialValue?.companyStatus?.toLowerCase();
  const [industry] = initialValue?.industries || [];

  const companySize = initialValue?.companySize && initialValue?.companySize.toLowerCase();

  return [
    {
      id: 'companyoverview-companyname',
      name: 'companyname',
      label: 'home:companydetails:overview:info:name',
      value: initialValue.companyName || 'missing',
      required: true,
      placeholder: 'login:username:placeholder',
      type: 'text',
      fieldType: FieldTypes.READONLY,
      trailingIcon: 'done',
      classNames: {
        ...defaultStyles,
        value: (!initialValue.companyName && styles.missing) || '',
      },
    },
    {
      id: 'companyoverview-businessmodel',
      name: 'businessmodel',
      value: initialValue.businessModel || 'missing',
      label: 'home:companydetails:overview:info:businessmodel',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue.businessModel || styles.missing,
      },
    },
    {
      id: 'companyoverview-companynumber',
      name: 'companynumber',
      label: 'home:companydetails:overview:info:companynumber',
      value: initialValue?.companyNumber,
      link: `https://find-and-update.company-information.service.gov.uk/company/${initialValue?.companyNumber}`,
      fieldType: FieldTypes.READONLY,
      type:
        features.companyNumberLinkEnabled && !['SoleTrader'].includes(initialValue.companyNumber)
          ? ReadOnlyTypes.ANCHOR
          : ReadOnlyTypes.TEXT,
      tracking: AmplitudeTrackingEnum.companynumberlink,
      classNames: {
        ...defaultStyles,
        value: (initialValue.companyNumber && styles['text-link']) || styles.missing,
      },
    },
    {
      id: 'companyoverview-sic',
      name: 'sic',
      value: industry?.sicCode || 'missing',
      label: 'home:companydetails:overview:info:siccode',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        value: industry?.sicCode || styles.missing,
      },
    },
    {
      id: 'companyoverview-status',
      name: 'status',
      value: initialValue.companyStatus,
      label: 'home:companydetails:overview:info:status',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue.companyStatus ? styles[companyStatus] : styles.missing,
      },
    },
    {
      id: 'companyoverview-industry',
      name: 'industry',
      value: industry?.industryName,
      label: 'home:companydetails:overview:info:industry',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: industry?.industryName || styles.missing,
      },
    },
    {
      id: 'companyoverview-accounts',
      name: 'accounts',
      value: companyAccountDateString(initialValue.accounts || {}),
      label: 'home:companydetails:overview:info:accounts',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'companyoverview-description',
      name: 'description',
      value: industry?.description,
      label: 'home:companydetails:overview:info:industrydescription',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: industry?.description || styles.missing,
      },
    },
    {
      id: 'companyoverview-charges',
      name: 'charges',
      value: initialValue?.numberOfCharges,
      label: 'home:companydetails:overview:info:charges',
      classNames: {
        ...defaultStyles,
        control: initialValue?.numberOfCharges ?? styles.missing,
      },
      fieldType: FieldTypes.READONLY,
      action: (initialValue?.numberOfCharges > 0 && ActionTypes.ALERT) || undefined,
    },
    {
      id: 'companyoverview-incorp',
      name: 'incorporationdate',
      value: initialValue.incorporationDate
        ? convertToLocalDateFormat(initialValue.incorporationDate)
        : 'missing',
      label: 'home:companydetails:overview:info:incorporationdate',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.incorporationDate || styles.missing,
      },
    },
    {
      id: 'companyoverview-tradingage',
      name: 'tradingage',
      value: initialValue?.tradingAgeRanges || 'missing',
      label: 'home:companydetails:overview:info:tradingage',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.tradingAgeRanges || styles.missing,
      },
    },
    {
      id: 'companyoverview-companysize',
      name: 'companysize',
      value: initialValue?.companySize || 'missing',
      label: 'home:companydetails:overview:info:companysize',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.companySize ? styles[companySize] : styles.missing,
      },
    },
    {
      id: 'companyoverview-businesstype',
      name: 'businesstype',
      value: initialValue.legelEntityType?.replace(/_/gi, ' ') || 'missing',
      label: 'home:companydetails:overview:info:businesstype',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.legelEntityType || styles.missing,
      },
    },
    {
      id: 'companyoverview-currentbank',
      name: 'currentbank',
      fieldType: FieldTypes.READONLY,
      value: displayStringValue(initialValue?.currentBank, {
        applyTitleCase: true,
        replaceWithSpace: '_',
        fallback: 'missing',
      }),
      label: 'home:companydetails:overview:info:currentbank',
      classNames: {
        ...defaultStyles,
        control: initialValue?.currentBank || styles.missing,
      },
    },
    {
      id: 'companyoverview-registeredaddress',
      name: 'registeredaddress',
      value: formatAddress(initialValue.registeredAddress),
      label: 'home:companydetails:overview:info:registeredaddress',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.registeredAddress || styles.missing,
      },
    },
    {
      id: 'companyoverview-website',
      name: 'website',
      value: initialValue.website?.replace(/_/gi, ' ') || 'missing',
      label: 'home:companydetails:overview:info:website',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.website || styles.missing,
      },
    },
    {
      id: 'companyoverview-tradingaddress',
      name: 'tradingaddress',
      value: formatAddress(initialValue.tradingAddress) || 'missing',
      label: 'home:companydetails:overview:info:tradingaddress',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
        control: initialValue?.tradingAddress || styles.missing,
      },
    },
  ];
}
