import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useRoutes } from 'react-router-dom';
import { appRoutes } from 'app.routes';
import { useEffect, useState } from 'react';
import { env } from '_shared/utils';
import { getCurrentUser } from '_shared/api/users';
import { UPDATE_SESSION } from 'store/system/types';
import { useAppState } from 'store';

function App() {
  const { state, dispatch } = useAppState();
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    if (state.system.currentUser) {
      const connect = new HubConnectionBuilder()
        .withUrl(
          `${env('REACT_APP_BASE_URI')}/notification?access_token=${state.system.accessToken}`
        )
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }
  }, [state.system.currentUser, state.system.accessToken]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('groupAccessChanged', async (message) => {
            const { data: currentUser } = await getCurrentUser();
            dispatch({
              type: UPDATE_SESSION,
              payload: {
                exposedGroups: currentUser?.exposedGroups,
              },
            });
          });
        })
        .catch((error) => console.error(error));
    }
  }, [connection]);

  const routing = useRoutes(appRoutes);
  return <>{routing}</>;
}

export default App;
