export const constructMenu = (metadata: any) => {
  const preMenu = metadata.reduce((total: any, current: any) => {
    const name = current.name;
    return {
      ...total,
      [name]: {
        displayOrder: current.displayOrder,
        children: [
          ...(total[name]?.children || []),
          ...current.children.map((field: any) => {
            return {
              ...field,
              displayOrder: current.displayOrder,
            };
          }),
        ],
      },
    };
  }, []);

  return Object.entries(preMenu)
    .map(([sectionName, values]: any) => {
      return {
        id: sectionName.replace(/\s/g, '-').toLowerCase(),
        label: sectionName,
        count: values?.children?.filter(
          (value: any) => value.type === 'checkbox' || Boolean(value.value)
        ).length,
        totalCount: values?.children?.length || 0,
        displayOrder: values.displayOrder,
      };
    })
    .sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));
};

export const getCompletionPercentage = (menu: any) => {
  const completeionPercentageDefault = {
    count: 0,
    totalCount: 0,
  };

  const { count, totalCount } =
    (menu.length &&
      menu.reduce(
        (
          total: {
            count: number;
            totalCount: number;
          },
          current: any
        ) => {
          return {
            count: total.count + current.count,
            totalCount: total.totalCount + current.totalCount,
          };
        },
        completeionPercentageDefault
      )) ||
    completeionPercentageDefault;

  return totalCount === 0 ? 0 : Math.round((count * 100) / totalCount);
};

export const getApplicationMenuDetails = (metadata: any) => {
  const menu = constructMenu(metadata);
  const completionPercentage = getCompletionPercentage(menu);

  return { menu, completionPercentage };
};
