import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './config/i18n';
import './assets/styles/index.scss';
import * as serviceWorker from './serviceWorker';

import { initAmplitude } from './config/amplitude';
import { initApm } from './config/apm-rum';
import { initSentry } from './config/sentry';
import { initHotjar } from './config/hotjar';
import { appReducers, AppStateProvider } from 'store';
import CustomRouter from 'customRouter';
import history from '_shared/history';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60000,
    },
  },
});

initApm();
initAmplitude();
initSentry();
initHotjar();

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider reducer={appReducers}>
      <QueryClientProvider client={queryClient}>
        <CustomRouter history={history}>
          <App />
          <ToastContainer limit={1} />
        </CustomRouter>
      </QueryClientProvider>
    </AppStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
