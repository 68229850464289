import React, { ReactNode } from 'react';
import styles from './FormSection.module.scss';
import FormQuestion, { FormQuestionProps } from './FormQuestion';

export type FormSectionProps = {
  title: string;
  children?: ReactNode;
  onAdd?: (ids: string[]) => void;
};

const FormSection: React.FC<FormSectionProps> = ({ title, children, onAdd }) => {
  const questions = React.Children.map(children, (child) => {
    if (React.isValidElement<FormQuestionProps>(child) && child.type === FormQuestion) {
      return React.cloneElement(child, {
        ...child.props,
        key: child.props.id,
        onAdd: (id: string) => onAdd?.([id]),
      });
    }

    return null;
  });

  return (
    <div className={styles['form-section']}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{questions}</div>
    </div>
  );
};

export default FormSection;
