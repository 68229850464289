import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';

export type Role = {
  id: string;
  name: UserRoles;
  description?: string;
};

export enum UserRoles {
  ACCOUNTANT = 'Accountant',
  ADMIN = 'Admin',
  ADVISOR = 'Advisor',
  ADVISOR_MANAGER = 'AdvisorManager',
  BROKER = 'Broker',
  BROKER_MANAGER = 'BrokerManager',
  EXTERNAL_ADMIN = 'ExternalAdmin',
  MARKETPLACE_MANAGER = 'MarketplaceManager',
  SUPER_ADMIN = 'SuperAdmin',
  BASE_USER = 'User',
  IAM_ADMIN = 'IamAdmin',
}

export enum BrokerUserRoles {
  BROKER = 'Broker',
  BROKER_MANAGER = 'BrokerManager',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'SuperAdmin',
}

export enum AdvisorUserRoles {
  ADVISOR = 'Advisor',
  ADVISOR_MANAGER = 'AdvisorManager',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'SuperAdmin',
}

export const determinApplicationRoles = () => {
  if (isBrokerApplication) {
    return BrokerUserRoles;
  } else if (isAdvisorApplication) {
    return AdvisorUserRoles;
  } else {
    return UserRoles;
  }
};

export const currentApplicationRoles = determinApplicationRoles();
