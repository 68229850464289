import { RouteObject } from 'react-router-dom';
import { RoutePath } from '../../_shared/routes';
import { withTransaction } from '@elastic/apm-rum-react';
import Bookmark from './Bookmark';

export const bookmarkRoutes: RouteObject[] = [
  {
    path: RoutePath.addBookmark,
    element: withTransaction('bookmark', 'component')(<Bookmark />),
  },
  {
    path: RoutePath.editBookmark,
    element: withTransaction('bookmark', 'component')(<Bookmark />),
  },
];
