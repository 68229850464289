import { TablePropType } from 'components/table/type';

export type dealsStateType = {
  redirectToReferrer: boolean;
  loading: boolean;
  isSearching: boolean;
  from: any;
  field: TablePropType;
  items: any[];
  hidePipeline: boolean;
};

export enum ActionType {
  DATA_LOADER,
  SET_ITEMS,
  TOGGLE_PIPELINE,
}

export type Action = {
  type: ActionType;
  payload?: any;
};

export function dealReducer(state: dealsStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          filteredDataLoading: false,
        };
      }
      return {
        ...state,
        loading: action.payload,
        filteredDataLoading: action.payload,
      };
    case ActionType.SET_ITEMS:
      if (action.payload.items.length > 0) {
        return {
          ...state,
          items: [...state.items, ...action.payload.items],
          field: action.payload.field,
          fetchApplications: false,
        };
      }
      return {
        ...state,
        totalApplicationsFetched: true,
        field: action.payload.field,
      };
    case ActionType.TOGGLE_PIPELINE:
      return {
        ...state,
        hidePipeline: !state.hidePipeline,
      };
    default:
      return state;
  }
}
