import { ResponsiveBar } from '@nivo/bar';

const colors = ['#0F334A', '#51AAED', '#46C8B8'] as Array<string>;
const getColor = (bar: any) => {
  return colors[bar.index];
};

export const BarGraph = ({ data /* see data tab */ }: any) => (
  <ResponsiveBar
    data={data}
    keys={['value']}
    indexBy="label"
    colors={getColor}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    padding={0.7}
    valueScale={{ type: 'linear' }}
    axisBottom={{
      tickPadding: 5,
      tickRotation: 0,
    }}
    axisLeft={{
      tickValues: 5,
    }}
    animate={true}
    enableLabel={false}
    layers={[
      'grid',
      'axes',
      'bars',
      'markers',
      'legends',
      'annotations',
      ({ bars }) => {
        return (
          <g>
            {bars.map(({ x, y, data, width }) => (
              <text
                x={width / 2}
                y={-12}
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fontFamily: 'sans-serif', fontSize: 11, fill: colors[0] }}
                transform={`translate(${x}, ${y})`}
              >{`${data.value ?? ''}`}</text>
            ))}
          </g>
        );
      },
    ]}
  />
);
