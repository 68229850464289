import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Modal from 'components/modal';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import styles from './DealDeleteModal.module.scss';
import { dealStatusLabels } from '_shared/utils/constants';
import { DealStatus } from 'pages/deals/types';

type DealDeleteModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  ownerName: string;
  companyName: string;
  type: string;
  status: string;
  numOfFunders: number;
  isLoading?: boolean;
};

const DealDeleteModal = ({
  isModalOpen,
  onClose,
  onConfirm,
  ownerName,
  companyName,
  type,
  status,
  numOfFunders,
  isLoading,
}: DealDeleteModalProps) => {
  const { t } = useTranslation();

  const statusLabel = dealStatusLabels[status as DealStatus] ?? 'N/A';

  return (
    <Modal show={isModalOpen} handleClose={onClose}>
      <div className={styles.modal}>
        <h3>{t('home:dealdeletionconfirmmodal:title')}</h3>

        <p className={styles['warning']}>
          {t('home:dealdeletionconfirmmodal:description', {
            numOfFunders,
          })}
        </p>

        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('home:dealdeletionconfirmmodal:owner')}</TableCell>
              <TableCell>{t('home:dealdeletionconfirmmodal:company')}</TableCell>
              <TableCell>{t('home:dealdeletionconfirmmodal:subtype')}</TableCell>
              <TableCell>{t('home:dealdeletionconfirmmodal:status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{ownerName}</TableCell>
              <TableCell>{companyName}</TableCell>
              <TableCell>{type}</TableCell>
              <TableCell>{statusLabel}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className={cn(styles['actions'])}>
          <Button buttonStyleType={ButtonStyleTypeEnum.SECONDARY} clickHandler={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            clickHandler={onConfirm}
            loading={isLoading}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealDeleteModal;
