export type ProductInsightsFieldType = {
  productId?: string;
  tradingName?: string;
  likes?: ProductInsightsFieldMetaData;
  dislikes?: ProductInsightsFieldMetaData;
  applicationProcess?: ProductInsightsFieldMetaData;
};

export type ProductInsightsFieldMetaData = {
  value: string;
  createdDate: string;
  createdByName: string;
  createdByPhotoUrl: string;
  modifiedDate: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedByPhotoUrl: string;
  createdById: string;
};

export enum ProductInsightsField {
  likes = 'likesValue',
  dislikes = 'dislikesValue',
  applicationProcess = 'applicationProcessValue',
}
