import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './TextInput.module.scss';

type TextInputProps = {
  value: string;
  placeholder?: string;
  className?: string;
  leadingIcon?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ value, placeholder, className, leadingIcon, onChange, onBlur }, ref) => {
    return (
      <div className={cn(styles['input-container'], className)}>
        {leadingIcon && (
          <i
            aria-labelledby="leading-icon"
            className={cn('material-icons', styles['leading-icon'])}
          >
            {leadingIcon}
          </i>
        )}
        <input
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  }
);

export default TextInput;
