import { GloabalActionsType } from './types';
import { productsReducer } from './products/reducers';
import { companyReducer } from './company/reducers';
import { systemReducer } from './system/reducers';
import { toastReducer } from './toast/reducers';
import { tasksReducer } from './tasks/reducers';
import { dealsReducer } from './deals/reducers';

const combineReducers = (reducers: any) => {
  return (state: any, action: GloabalActionsType) => {
    return Object.keys(reducers).reduce((acc, prop: any) => {
      return {
        ...acc,
        [prop]: reducers[prop](acc[prop], action),
      };
    }, state);
  };
};

export const appReducers = combineReducers({
  product: productsReducer,
  company: companyReducer,
  deals: dealsReducer,
  system: systemReducer,
  tasks: tasksReducer,
  toast: toastReducer,
});
