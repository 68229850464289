import { ACTIVITY_CATEGORY, ACTIVITY_TYPE } from '../types';

export const newActivityOptions = [
  {
    id: ACTIVITY_CATEGORY.TASK,
    label: 'New Task',
    value: ACTIVITY_CATEGORY.TASK,
    icon: 'add_task',
  },
  { id: ACTIVITY_CATEGORY.CALL, label: 'New Call', value: ACTIVITY_CATEGORY.CALL, icon: 'call' },
  { id: ACTIVITY_CATEGORY.EMAIL, label: 'New Email', value: ACTIVITY_CATEGORY.EMAIL, icon: 'mail' },
  {
    id: ACTIVITY_CATEGORY.NOTE,
    label: 'New Note',
    value: ACTIVITY_CATEGORY.NOTE,
    icon: 'description',
  },
];

export const activityTypeIcons = {
  [ACTIVITY_TYPE.NOTE]: 'description',
  [ACTIVITY_TYPE.EMAIL]: 'mail',
  [ACTIVITY_TYPE.TASK]: 'add_task',
  [ACTIVITY_TYPE.CALL_SUCCESS]: 'call',
  [ACTIVITY_TYPE.CALL_FAILURE]: 'call',
};

export const getNewActivityLabelById = (activityCategoryId: ACTIVITY_CATEGORY) => {
  const activity = newActivityOptions.find(({ id }) => id === activityCategoryId);
  return activity?.label;
};

export const getActivityTypeFromCategory = (
  category: ACTIVITY_CATEGORY,
  state?: { callStatus: string }
) => {
  switch (category) {
    case ACTIVITY_CATEGORY.NOTE:
      return ACTIVITY_TYPE.NOTE;
    case ACTIVITY_CATEGORY.EMAIL:
      return ACTIVITY_TYPE.EMAIL;
    case ACTIVITY_CATEGORY.TASK:
      return ACTIVITY_TYPE.TASK;
    case ACTIVITY_CATEGORY.CALL:
      return (
        (state?.callStatus === 'true' && ACTIVITY_TYPE.CALL_SUCCESS) ||
        (state?.callStatus === 'false' && ACTIVITY_TYPE.CALL_FAILURE) ||
        undefined
      );
    default:
      return ACTIVITY_TYPE.NOTE;
  }
};
