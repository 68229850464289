import React, { useState } from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';
import { APIDocumentType } from 'pages/documentLibrary/types';
import { getAccountingDocuments, getDownloadCompanyDocument } from '_shared/api/documents';
import Spinner from 'components/spinner';
import PreviewDocument from 'pages/documentLibrary/components/previewDocument';

import DocumentsIcon from 'assets/images/icon/documents.svg';

type DocumentsAttachedProps = {
  documents: APIDocumentType[];
  companyId: string;
};

const pdfTypesPreview = ['application/pdf', 'application/octet-stream'];
const acceptedDocTypeForPreview = [...pdfTypesPreview, 'image/jpeg', 'image/png'];

const DocumentsAttached: React.FC<DocumentsAttachedProps> = ({ documents, companyId }) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [previewDocument, setPreviewDocument] = useState<APIDocumentType | null>(null);

  const fetchDocument = async (id: string, doc: APIDocumentType) => {
    try {
      setDocumentLoading(true);
      const { data } = doc.isBrokerDocument
        ? await getAccountingDocuments(id, doc.documentId)
        : await getDownloadCompanyDocument(id, doc.documentId);

      if (!acceptedDocTypeForPreview.includes(data.type)) {
        const blob = new Blob([data], { type: data.type });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = doc.name || 'downloaded_file';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setPreviewDocument(doc);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDocumentLoading(false);
    }
  };

  const handleClosePreview = () => {
    setPreviewDocument(null);
  };

  return (
    <div className={cn([styles['card'], styles['documents-attached']])}>
      <img
        src={DocumentsIcon}
        alt="documents"
        className={cn(styles['custom-icon'], styles['documents'])}
      />
      <div className={styles['container-details']}>
        <p className={styles['label']}>Documents attached</p>
        <p className={styles['sub-label']}>
          Ensure files are scanned for viruses and only open if from a trusted source. Stay safe!
        </p>
        <div className={styles['container-doc-links']}>
          {documents
            .filter((doc: any) => doc.type.toLowerCase() !== 'thumbnail')
            .map((doc: any) => (
              <div className={styles['container-link']}>
                <a
                  key={doc.documentId}
                  target="_blank"
                  rel="noreferrer"
                  className={styles['document-link']}
                  onClick={() => fetchDocument(companyId || '', doc)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      fetchDocument(companyId || '', doc);
                    }
                  }}
                >
                  {doc.name}
                </a>
                <span>({doc.title !== 'N/A' ? doc.title : doc.type})</span>
              </div>
            ))}
        </div>

        {documentLoading && (
          <div className={'error-container'}>
            <Spinner size="large" />
          </div>
        )}

        {previewDocument && (
          <PreviewDocument
            document={previewDocument}
            isOpen={true}
            close={handleClosePreview}
            companyId={companyId ?? ''}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentsAttached;
