import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import styles from 'pages/login/assets/login.module.scss';
import Login from './login';

import Footer from 'components/footer';
import LoginSidebar from './components/loginSidebar';

function LoginRoute() {
  const location = useLocation();

  const [state] = useState({
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
  });

  if (state.redirectToReferrer) {
    return <Navigate to={state.from} />;
  }

  return (
    <div className={styles.container}>
      <LoginSidebar />
      <div className={styles.content}>
        <Login />
        <Footer />
      </div>
    </div>
  );
}

export default LoginRoute;
