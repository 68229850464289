import { withTransaction } from '@elastic/apm-rum-react';
import { Navigate, RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import UserManagement from '.';
import { useAppState } from 'store';

export const UserManagementProtectedRoute = () => {
  const store = useAppState();

  const isUserIamAdmin = Boolean(store.state.system.isUserManager?.());

  if (!isUserIamAdmin) {
    return <Navigate to={RoutePath.userslist} />;
  }
  return <UserManagement />;
};

export const userManagementRoutes: RouteObject[] = [
  {
    path: RoutePath.usermanagement,
    element: withTransaction('UserManagement', 'component')(<UserManagementProtectedRoute />),
  },
];
