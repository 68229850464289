import { convertToCurrency } from '_shared/utils/currency';
import styles from 'pages/companyOverview/assets/companyOverview.module.scss';
import { AccountInfoFields } from '../types';
import { FieldMetaDataPropType, FieldTypes, ReadOnlyTypes } from '_shared/fieldValidation/types';
import features from 'config/features';

const defaultStyles = {
  field: styles['field-holder'],
  label: styles.label,
};

export function accountInfoFields(initialValue: any): AccountInfoFields {
  return {
    info: [
      {
        id: 'overview-accountinfo-live-opportunities',
        name: 'live-opportunities',
        label: 'home:companydetails:overview:accountinfo:liveopportunities',
        value: initialValue?.liveOpportunities || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-leadscore',
        name: 'lead-score',
        label: 'home:companydetails:overview:accountinfo:leadscore',
        value: initialValue?.leadScore || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
          control: (initialValue?.leadScore && styles[initialValue?.leadScore.toLowerCase()]) || '',
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-funded-opportunities',
        name: 'funded-opportunities',
        label: 'home:companydetails:overview:accountinfo:fundedopportunities',
        value: initialValue?.fundedOpportunities || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-source',
        name: 'source',
        label: 'home:companydetails:overview:accountinfo:source',
        value: initialValue?.accountSource || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-total-funded',
        name: 'total-dunded',
        label: 'home:companydetails:overview:accountinfo:totalfundedamount',
        value: convertToCurrency(initialValue?.totalFundedAmount) || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-source-detail',
        name: 'source-detail',
        label: 'home:companydetails:overview:accountinfo:sourcedetail',
        value: initialValue?.sourceDetail || '-',
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-total-swoop-fee',
        name: 'swoop-fee',
        label: 'home:companydetails:overview:accountinfo:totalswoopfee',
        value: convertToCurrency(initialValue?.totalSwoopFeeGenerated),
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-energy-savings',
        name: 'energy-savings',
        label: 'home:companydetails:overview:accountinfo:energysavings',
        value:
          (initialValue?.energySavings && 'done') ||
          (initialValue?.energySavings === false && 'clear') ||
          'No bank data',
        fieldType: FieldTypes.READONLY,
        type:
          (typeof initialValue?.energySavings !== 'boolean' && ReadOnlyTypes.TEXT) ||
          ReadOnlyTypes.ICON,
        classNames: {
          ...defaultStyles,
          value:
            (initialValue?.energySavings && styles.positive) ||
            (initialValue?.energySavings === false && styles.negative) ||
            '',
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-fx-savings',
        name: 'fx-savings',
        label: 'home:companydetails:overview:accountinfo:fxsavings',
        value:
          (initialValue?.fxSavings && 'done') ||
          (initialValue?.fxSavings === false && 'clear') ||
          'No bank data',
        fieldType: FieldTypes.READONLY,
        type:
          (typeof initialValue?.fxSavings !== 'boolean' && ReadOnlyTypes.TEXT) ||
          ReadOnlyTypes.ICON,
        classNames: {
          ...defaultStyles,
          value:
            (initialValue?.fxSavings && styles.positive) ||
            (initialValue?.fxSavings === false && styles.negative) ||
            '',
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-account-status',
        name: 'account-status',
        label: 'home:companydetails:overview:accountinfo:accountstatus',
        value: initialValue?.accountStatus,
        fieldType: FieldTypes.READONLY,
        classNames: {
          ...defaultStyles,
        },
        hidden: !features.specificAccountInfoEnabled,
      },
      {
        id: 'overview-accountinfo-r-and-d',
        name: 'r-and-d',
        label: 'home:companydetails:overview:accountinfo:rdsavings',
        value:
          (initialValue?.rAndDSavings && 'done') ||
          (initialValue?.rAndDSavings === false && 'clear') ||
          'No data',
        fieldType: FieldTypes.READONLY,
        type:
          (typeof initialValue?.rAndDSavings !== 'boolean' && ReadOnlyTypes.TEXT) ||
          ReadOnlyTypes.ICON,
        classNames: {
          ...defaultStyles,
          value:
            (initialValue?.rAndDSavings && styles.positive) ||
            (initialValue?.rAndDSavings === false && styles.negative) ||
            '',
        },
        hidden: !features.specificAccountInfoEnabled,
      },
    ],
  };
}

export function accountInfoCheckboxFields(
  data: any,
  isUserBroker: boolean
): Array<FieldMetaDataPropType> {
  return [
    {
      id: 'isBlacklisted',
      name: 'isBlacklisted',
      label: 'Is client blacklisted?',
      value: data.isBlacklisted,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'isNotTrustPilot',
      name: 'isNotTrustPilot',
      label: 'Client does not want to provide a review?',
      value: data.isNotTrustPilot,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'hasRaisedComplaint',
      name: 'hasRaisedComplaint',
      label: 'Has client previously raised a complaint?',
      value: data.hasRaisedComplaint,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'hasGivenFundingApplicationConsent',
      name: 'hasGivenFundingApplicationConsent',
      label: 'Has client given funding application consent?',
      value: data.hasGivenFundingApplicationConsent,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'isEnhancedDueDiligence',
      name: 'isEnhancedDueDiligence',
      label: 'Is client flagged for enhanced due diligence?',
      value: data.isEnhancedDueDiligence,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'canFundingManagersContactClient',
      name: 'canFundingManagersContactClient',
      label: 'Are Swoop Funding Managers allowed to contact this client?',
      value: data.canFundingManagersContactClient,
      disabled: !isUserBroker,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        ...defaultStyles,
      },
    },
  ];
}

export function utmParameterFields(data: any): Array<FieldMetaDataPropType> {
  return [
    {
      id: 'source',
      name: 'source',
      label: 'Source',
      value: data.source ? data.source : 'N/A',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'medium',
      name: 'medium',
      label: 'Medium',
      value: data.medium ? data.medium : 'N/A',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'campaign',
      name: 'campaign',
      label: 'Campaign',
      value: data.campaign ? data.campaign : 'N/A',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'term',
      name: 'term',
      label: 'Term',
      value: data.term ? data.term : 'N/A',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'content',
      name: 'content',
      label: 'Content',
      value: data.content ? data.content : 'N/A',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultStyles,
      },
    },
  ];
}
