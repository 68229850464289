import { useEffect, useState } from 'react';
import LoginSidebar from './components/loginSidebar';
import { resetPassword } from '_shared/api/login';
import Footer from 'components/footer';
import styles from './assets/login.module.scss';
import Spinner from 'components/spinner';
import { RoutePath } from '_shared/routes';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import Field from 'components/field';
import Button from 'components/button';
import { fields } from './fields/forgotpassword.fields';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Card from 'components/card';

// @tonote: This is the original forgot password journey, kept here as a feature flag.
function ForgotPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const [state, setstate] = useState({
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
    goToCheckEmail: '',
  });
  const emailInstructions = async (values: any) => {
    setstate({ ...state, loading: true });
    try {
      await resetPassword({
        email: values.email,
      });

      setstate({ ...state, goToCheckEmail: values.email });
      return;
    } catch (e) {
      setstate({ ...state, loading: false });
    }
    setstate({ ...state, loading: false });
  };

  const { handleChange, handleSubmit, errors, metadata } = useForm(
    {},
    fields,
    emailInstructions,
    validation
  );
  if (state.goToCheckEmail) {
    return (
      <Navigate
        to={{
          pathname: RoutePath.forgotpasswordcheckemail,
        }}
        state={{
          email: state.goToCheckEmail,
          path: location.pathname,
        }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <LoginSidebar />
      <div className={styles.content}>
        <Card>
          <div className={cn(styles['login-form-container'])}>
            <h1 className="sw-h1">{t('login:forgotpassword:title')}</h1>
            <h4 className="sw-h4">{t('login:forgotpassword:instructions')}</h4>
            <form onSubmit={handleSubmit} noValidate>
              {metadata.map((data) => {
                return (
                  <Field
                    key={data.id}
                    metadata={data}
                    errors={errors}
                    handleChange={handleChange}
                    className={{
                      control: styles.relative,
                    }}
                  />
                );
              })}
              <hr className="sw-divider" />
              <div className={styles['action-container']}>
                <Button
                  ariaLabel="Button to sign in to Broker Portal"
                  id="forgot-password"
                  buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
                  className={styles['submit-button']}
                  type="submit"
                  loading={state.loading}
                  disabled={state.loading}
                >
                  {state.loading ? t('common:pleasewait') : t('login:forgotpassword:submit')}
                </Button>
              </div>
            </form>
          </div>
        </Card>
        <Footer />
      </div>
    </div>
  );
}

// @tonote: This is the new forgot password journey.
function ForgotPasswordV2() {
  const [isLoading, setIsLoading] = useState(false);
  const [showOldJourney, setShowOldJourney] = useState(false);

  useEffect(() => {
    const resetPasswordRedirect = async () => {
      setIsLoading(true);
      try {
        // @tonote: Nicolas did not want to change this endpoint to accept an empty email as we might
        // want to rollback to the previous version that requires an email.
        const { data: redirectURL } = await resetPassword({
          email: 'admin@swoopfunding.com',
        });

        if (redirectURL) {
          window.location.href = redirectURL;
        } else {
          setShowOldJourney(true);
        }
      } catch (e) {
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    resetPasswordRedirect();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoginSidebar />
        <div className={styles.content}>
          {isLoading && (
            <>
              <Spinner size="large" />
              <p>Loading...</p>
            </>
          )}
          <Footer />
        </div>
      </div>
    );
  }

  return showOldJourney ? <ForgotPassword /> : null;
}

export default ForgotPasswordV2;
