import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';
import { formatDate } from 'pages/companyEssentials/utils';
import { useTranslation } from 'react-i18next';

type BusinessDetailsProps = {
  turnover?: string | null;
  tradingSinceDate?: string | null;
  fundingNeed?: string | null;
  livingSituation?: string | null;
  companyName?: string | null;
  companyNumber?: string | null;
  businessWebsite?: string | null;
  accounts?: {
    nextAccountsMadeUpTo?: string | null;
    dueBy?: string | null;
    lastAccountsMadeUpTo?: string | null;
  } | null;
  buttonHandler: () => void;
};

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
  turnover,
  tradingSinceDate,
  fundingNeed,
  livingSituation,
  companyName,
  companyNumber,
  businessWebsite,
  accounts,
  buttonHandler,
}) => {
  const { t } = useTranslation();

  const isNumeric = (value: any): boolean => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };

  return (
    <div className={cn([styles['card'], styles['business-details']])}>
      {turnover && (
        <>
          <h4>{turnover}</h4>
          <p className={styles['label']}>Revenue in the last 12 months</p>
          <hr />
        </>
      )}
      <div className={styles['container-details']}>
        {tradingSinceDate && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Trading since</p>
            <p className={cn([styles['right'], styles['label']])}>{tradingSinceDate}</p>
          </div>
        )}
        {fundingNeed && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Funding need</p>
            <p className={cn([styles['right'], styles['label']])}>{`${t(
              'currency:symbol'
            )}${fundingNeed}`}</p>
          </div>
        )}
        {companyName && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Companies house</p>
            <p className={cn([styles['right'], styles['label']])}>
              {companyNumber && isNumeric(companyNumber) ? (
                <a
                  href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {companyName}
                </a>
              ) : (
                companyName
              )}
            </p>
          </div>
        )}
        {livingSituation && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Living situation</p>
            <p className={cn([styles['right'], styles['label']])}>{livingSituation}</p>
          </div>
        )}
        {businessWebsite && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Website</p>
            <p className={cn([styles['right'], styles['label']])}>
              <a href={businessWebsite} target="_blank">
                {businessWebsite}
              </a>
            </p>
          </div>
        )}
      </div>

      {(tradingSinceDate ||
        fundingNeed ||
        (companyNumber && companyNumber !== undefined) ||
        livingSituation ||
        businessWebsite) && <hr />}

      <div className={styles['container-details']}>
        {accounts?.nextAccountsMadeUpTo && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Accounts made up to</p>
            <p className={cn([styles['right'], styles['label']])}>
              {formatDate(accounts.nextAccountsMadeUpTo, 'long')}
            </p>
          </div>
        )}
        {accounts?.dueBy && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Due by</p>
            <p className={cn([styles['right'], styles['label']])}>
              {formatDate(accounts.dueBy, 'long')}
            </p>
          </div>
        )}
        {accounts?.lastAccountsMadeUpTo && (
          <div className={styles['detail']}>
            <p className={cn([styles['left'], styles['label']])}>Last accounts made up</p>
            <p className={cn([styles['right'], styles['label']])}>
              {formatDate(accounts.lastAccountsMadeUpTo, 'long')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessDetails;
