import features from 'config/features';

const companyRoutes = {
  companies: '/companies',
  companydetails: '/company/:id',
  companysavingsproducts: '/company/:id/savings-products',
  companyoverview: '/company/:id/overview',
  companyfundingmatches: '/company/:id/funding-matches',
  companyfinance: '/company/:id/finance',
  companyapplicationform: '/company/:id/applications',
  companydeals: '/company/:id/deals',
  companydeal: '/company/:companyId/deal/:dealId',
  companyintegrations: '/company/:id/integrations',
  companydocumentlibrary: '/company/:id/documents',
  companyeditapplicationform: '/company/:id/applications/:applicationId/edit',
  companyviewapplicationform: '/applicationForm/:id/companyApplications/:applicationId',
  companyfundingmatchesdetails: '/company/:id/funding-matches/:offerType/:subType',
  companyfundingmatchesproductapplication:
    '/company/:id/funding-matches/:offerType/product-application/:productId',
  ...(features.essentialsEnabled ? { companyessentials: '/company/:id/essentials' } : {}),
};

const dealsRoutes = {
  deals: '/deals',
  dealdetails: '/deal/:dealId/company/:companyId',
};

const productRoutes = {
  products: '/products',
  productdetails: '/product/:id',
  productinformation: '/product/:id/information',
  productdocuments: '/product/:id/documents',
  productapplicationform: '/product/:id/application-form',
  productinsights: '/product/:id/insights',
};

const profileRoutes = {
  yourprofile: '/yourprofile',
};

const userRoutes = {
  userslist: '/users',
  userdetails: '/user/:id',
  usermanagement: '/usermanagement',
};

const authenticationRoutes = {
  login: '/login',
  register: '/register',
  registerIsv: '/registerIsv',
  migrateuser: '/convert-user',
  forgotpassword: '/forgotpassword',
  forgotpasswordcheckemail: '/forgotpasswordcheckemail',
  forgotpasswordcallback: '/azure-ad-password-reset',
};
const tasksRoutes = {
  tasks: '/tasks',
};

const groupManagementRoutes = {
  groupManagement: '/group-profile',
};

const bookmarksRoutes = {
  addBookmark: '/bookmark',
  editBookmark: '/bookmark/:id',
};

export const RoutePath: {
  [key: string]: string;
} = {
  home: '/',
  notFound: '*',
  ...authenticationRoutes,
  ...companyRoutes,
  ...productRoutes,
  ...profileRoutes,
  ...userRoutes,
  ...tasksRoutes,
  ...groupManagementRoutes,
  ...dealsRoutes,
  ...bookmarksRoutes,
};

export const requiresNoAuthRoutes = Object.values(authenticationRoutes);
