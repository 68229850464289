import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';

import { FormFieldEssentials } from 'pages/companyEssentials/types';
import { convertToCurrency } from '_shared/utils/currency';
import SummarisedJourney from '../summarisedJourney';

type UnsecuredProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

const Unsecured: React.FC<UnsecuredProps> = ({
  fetchedData,
  dateCreated,
  editDetails,
  journeyDisplayName,
  journeyName,
}) => {
  const fieldsConfig = [
    {
      fieldName: 'issuesInvoices',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value ? 'Sends invoices to customers' : 'Does not send invoices to customers'}
          </p>
        ),
    },
    {
      fieldName: 'paysVat',
      render: (field: FormFieldEssentials) => {
        const vatAmountField = getFieldByName('companyVatPaymentAmount');
        const vatAmount = vatAmountField?.value
          ? convertToCurrency(vatAmountField.value.toString())
          : '';

        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value
                ? `Pays VAT ${vatAmount ? `(${vatAmount} per quarter)` : ''}`
                : 'Does not pay VAT'}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'takesCardPayments',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value ? 'Takes card payments' : 'Does not take card payments'}
          </p>
        ),
    },
    {
      fieldName: 'hasBeenProfitable',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value
              ? 'Profitable in the last 12 months'
              : 'Not profitable in the last 12 months'}
          </p>
        ),
    },
    {
      fieldName: 'hasExistingLoan',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'close' : 'check'}
            </span>
            {field.value ? 'Has existing loans' : 'Does not have existing loans'}
          </p>
        ),
    },
  ];

  const getFieldByName = (name: string) => fetchedData.find((field) => field.name === name);

  return <SummarisedJourney data={fetchedData} fields={fieldsConfig} />;
};

export default Unsecured;
