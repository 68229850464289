import React from 'react';
import styles from './ToggleButton.module.scss';
import cn from 'classnames';

export type ToggleButtonProp = {
  value: string;
  active?: boolean;
  onClick?: (value: string) => void;
  children: React.ReactNode;
};

const ToggleButton: React.FC<ToggleButtonProp> = ({ onClick, value, active, children }) => {
  return (
    <button
      className={cn(styles['toggle-button'], { [styles.active]: active })}
      onClick={() => onClick?.(value)}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
