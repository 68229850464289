import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Link, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AmplitudeTrackingEnum } from 'config/amplitude';
import { RoutePath } from '_shared/routes';
import { getBusinessAccountInfo, getCompanyInfo } from '_shared/api/businesses';
import TabList from 'components/v2/TabList';
import Tab from 'components/v2/Tab';
import styles from './assets/companyDetails.module.scss';
import {
  currentCompanyReducer,
  CompanyDetailsContext,
  currentCompanyInitialState,
  CompanyActionType,
} from './store/companyDetails.reducer';
import logo from '../../assets/images/hubspot-logo.svg';
import { useAppState } from 'store';
import { UPDATE_COMPANY } from 'store/company/types';
import Activities from 'components/activities';
import AccountWarningBanner from 'pages/companyOverview/components/accountWarningBanner';
import features from 'config/features';
import { IContext } from 'store/types';
import { SWOOP_GROUP_ID } from '_shared/utils/application';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { getCompletedFundingForms, getFundingForms } from '_shared/api/fundingMatches';
import { allowedEssentialsFormNames } from 'pages/companyEssentials/formComponentConfig';
import { FundingFormType } from 'pages/companyEssentials/types';

const CompanyDetailsRoute: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const store = useAppState();
  const initialState = {
    redirectToReferrer: false,
    from: location.state || { pathname: RoutePath.companies },
    company: { companyName: location.state?.name || store.state.company.companyName || '' },
    activeKey: '',
  };

  const [localState, setLocalState] = useState(initialState);
  const { state: globalState }: IContext = useAppState();
  const isSwoopGroupUser = globalState.system.groupId === SWOOP_GROUP_ID;
  const [state, dispatch] = useReducer(currentCompanyReducer, currentCompanyInitialState);
  const currentCompanyId = params.id!;

  const [fundingForms, setFundingForms] = useState<FundingFormType[]>([]);

  const tabItems = useMemo(() => {
    const tabs = [
      'overview',
      'fundingmatches',
      'finance',
      'integrations',
      'documentlibrary',
      'deals',
    ];

    if (features.essentialsEnabled && state.completedFormsLength > 0) tabs.unshift('essentials');

    if (features.savingsProductsEnabled) tabs.push('savingsproducts');

    return tabs.map((tab) => {
      const route = RoutePath[`company${tab}`];
      const routeLink = route?.replace(':id', currentCompanyId).replace(':offerType', 'Loans');
      const routeKeys: { [index: string]: string[] } = {
        overview: [RoutePath.companyoverview.replace(':id', currentCompanyId)],
        fundingmatches: [RoutePath.companyfundingmatches.replace(':id', currentCompanyId)],
        finance: [RoutePath.companyfinance.replace(':id', currentCompanyId)],
        integrations: [RoutePath.companyintegrations.replace(':id', currentCompanyId)],
        documentlibrary: [RoutePath.companydocumentlibrary.replace(':id', currentCompanyId)],
        deals: [RoutePath.companydeals.replace(':id', currentCompanyId)],
        savingsproducts: [RoutePath.companysavingsproducts.replace(':id', currentCompanyId)],
      };

      if (features.essentialsEnabled && state.completedFormsLength > 0) {
        routeKeys.essentials = [RoutePath.companyessentials.replace(':id', currentCompanyId)];
      }

      let numberOfLiveItems;
      if (tab === 'documentlibrary') {
        numberOfLiveItems = state.accountInfoData.documents ?? 0;
      } else if (tab === 'deals') {
        numberOfLiveItems = state.accountInfoData.liveOpportunities ?? 0;
      }

      const tabTitle = t(`home:companydetails:${tab}:title`);
      const tabCount = numberOfLiveItems !== undefined ? ` (${numberOfLiveItems})` : '';

      return {
        keys: routeKeys[tab],
        title: <Link to={routeLink}>{`${tabTitle}${tabCount}`}</Link>,
        tracking: Object(AmplitudeTrackingEnum)[`${tab}tab`],
      };
    });
  }, [
    t,
    currentCompanyId,
    state.accountInfoData.documents,
    state.accountInfoData.liveOpportunities,
    state.completedFormsLength,
  ]);

  useEffect(() => {
    setLocalState((ls) => ({ ...ls, activeKey: location.pathname }));
  }, [location.pathname]);

  useEffect(() => {
    dispatch({ type: CompanyActionType.SET_ID, payload: currentCompanyId });
    return () => {};
  }, [currentCompanyId, store.dispatch, store.state.system]);

  const getInfo = async () => {
    try {
      const { data } = await getCompanyInfo(currentCompanyId);
      const { data: accountInfoData } = await getBusinessAccountInfo(currentCompanyId);
      dispatch({ type: CompanyActionType.SET_COMPANY, payload: { data: data } });
      dispatch({ type: CompanyActionType.TOGGLE_LOADER, payload: false });

      store.dispatch({
        type: UPDATE_COMPANY,
        payload: {
          ...store.state.company,
          companyName: data.companyName ?? '',
          companyId: currentCompanyId,
        },
      });

      dispatch({
        type: CompanyActionType.SET_ACCOUNT_INFO_DATA,
        payload: accountInfoData,
      });
    } catch (e) {
      dispatch({ type: CompanyActionType.SET_COMPANY, payload: { error: true } });
      dispatch({ type: CompanyActionType.TOGGLE_LOADER, payload: false });
    }
  };

  useEffect(() => {
    const fetchFundingForms = async () => {
      try {
        const fundingFormResult = await getFundingForms();
        setFundingForms(fundingFormResult.data);
      } catch (error) {
        console.error('Error fetching funding forms:', error);
      }
    };

    fetchFundingForms();
  }, []);

  useEffect(() => {
    if (!currentCompanyId || fundingForms.length === 0) return;

    getInfo();

    fetchCompletedForms(currentCompanyId);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId, fundingForms]);

  const fetchCompletedForms = async (id: string) => {
    try {
      const { data: completedFormsList } = await getCompletedFundingForms(id);

      const filteredForms = completedFormsList?.filter((completedForm: any) => {
        const matchedForm = fundingForms.find(
          (fundingForm) => fundingForm.formId === completedForm.formId
        );
        const formName = matchedForm ? matchedForm.name : null;
        return formName && allowedEssentialsFormNames.includes(formName);
      });

      dispatch({
        type: CompanyActionType.SET_COMPLETED_FORMS_LENGTH,
        payload: filteredForms.length,
      });
    } catch (error) {
      console.error('Error fetching completed forms:', error);
    }
  };

  if (localState.redirectToReferrer) {
    return <Navigate to={localState.from} />;
  }

  return (
    <div className={classNames(styles['page-wrapper'])}>
      <div className={classNames(styles['company-header'])}>
        {store.state?.company.companyName && (
          <h5 className={classNames(styles.title)}>{store.state.company.companyName}</h5>
        )}
        <Activities companyId={currentCompanyId} />
        {isSwoopGroupUser && state.company.data?.isInHubSpot && (
          <Button
            className={styles['hubspot-button']}
            clickHandler={() => {
              window.open(state.company.data.hubSpotLink, '_blank');
            }}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            icon={<img alt="hubspot-logo" src={logo} className={styles['hubspot-logo']} />}
          >
            Company Details
          </Button>
        )}
        <AccountWarningBanner accountInfoData={state.accountInfoData} />
      </div>
      <TabList value={localState.activeKey} onChange={() => {}}>
        {tabItems.map((tab) => (
          <Tab value={tab.keys[0]} key={tab.keys[0]}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <div className={classNames(styles.content)}>
        <CompanyDetailsContext.Provider value={{ state, dispatch, refreshCompanyData: getInfo }}>
          <Outlet />
        </CompanyDetailsContext.Provider>
      </div>
    </div>
  );
};

export default CompanyDetailsRoute;
