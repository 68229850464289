export enum ContactModalEnum {
  ADD = 'add-contact',
  EDIT = 'edit-contact',
  DELETE = 'delete-contact',
}

export type TContactModalProps = {
  contactModalContext: ContactModalEnum | null;
  setContactModalContext: Function;
  selectedContact: TContactProps | null;
  setSelectedContact: Function;
  companyId: string;
  fetchContactsInfo: Function;
};

export type TContactProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  contactNumber?: string;
  email?: string;
  roleInCompany?: string;
  createdDate?: Date;
};
