import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FieldPropType } from '../type';
import styles from './textarea.module.scss';

function Textarea(
  { metadata, handleChange, errors }: FieldPropType,
  ref: React.Ref<HTMLTextAreaElement>
) {
  const { t } = useTranslation();

  return (
    <textarea
      id={metadata.id}
      name={metadata.name}
      placeholder={t(metadata.placeholder || '')}
      ref={ref}
      tabIndex={0}
      aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
      aria-describedby={`error-${metadata.name}`}
      className={classNames(styles.textarea, metadata?.classNames?.value, !!errors && styles.error)}
      value={metadata.value}
      onChange={handleChange}
      disabled={metadata.disabled || false}
      required={metadata.required}
    />
  );
}

export default React.forwardRef(Textarea);
