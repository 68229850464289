import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './assets/migrateUser.module.scss';
import BrokerPortalLogo from 'assets/images/broker-portal-logo.svg';
import AdvisorPortalLogo from 'assets/images/advisor-portal-logo-white.svg';
import RegisterImage from 'assets/images/register-image.png';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { putUserMigrate } from '_shared/api/users';
import {
  isAdvisorApplication,
  isBrokerApplication,
  applicationName,
} from '_shared/utils/application';
import Spinner from 'components/spinner';
import { useAppState } from 'store';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';

const Register = () => {
  const store = useAppState();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search.replace('+', '%2B'));
  const token = urlParams.get('token') || '';
  const email = urlParams.get('email') || '';

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const navigateToLogin = () => {
    if (email && email?.length) {
      navigate(`/login?email=${email}`);
    }
  };

  const submitRegister = async () => {
    try {
      setLoading(true);
      const { status } = await putUserMigrate(token);
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: {
          toastMessage: t('migrateuser:success'),
        },
      });
      if (status === 204) {
        navigateToLogin();
      }
    } catch (e) {
      console.error(e);
      store.dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: {
          toastMessage: t('migrateuser:failure'),
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!email || !token) navigateToLogin();
  }, [email && token]);

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles['image-container'])}>
        <div className={cn(styles['image-content-container'])}>
          {(isBrokerApplication && (
            <img
              src={BrokerPortalLogo}
              alt="upgrade-broker-portal"
              className={cn(styles['broker-portal-logo'])}
            />
          )) ||
            (isAdvisorApplication && (
              <img
                src={AdvisorPortalLogo}
                alt="upgrade-advisor-portal"
                className={cn(styles['advisor-portal-logo'])}
              />
            ))}
          <img src={RegisterImage} alt="upgrade" className={cn(styles['upgrade-image'])} />
        </div>
      </div>
      <div className={cn(styles['content-container'])}>
        <div className={cn(styles['content-upgrade-container'])}>
          <span>{t('register:alreadyhaveaccount')}</span>
          <Button
            id="upgrade-signin"
            ariaLabel="upgrade-signin"
            type="submit"
            clickHandler={navigateToLogin}
            tabIndex={6}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
          >
            {t('common:signin')}
          </Button>
        </div>
        {loading ? (
          <div className={cn(styles['field-container'])}>
            <h2>Upgrading to Swoop for {applicationName()}!</h2>
            <p>
              Please wait while we make the necessary changes to upgrade you to Swoop for{' '}
              {applicationName()}.
            </p>
            <Spinner size="large" />
          </div>
        ) : (
          <div className={cn(styles['field-container'])}>
            <h2>
              Hey {email} - Welcome to Swoop for {applicationName()}!
            </h2>

            <p>
              To take advantage of this New and Improved platform, we need to upgrade your account
              from our previous system.
            </p>

            <b>What's involved?</b>
            <p>
              No change to your login details - you will use the same email and password as before.
            </p>
            <p>
              Any company you previously registered will still be accessible within the new system
            </p>

            <b>Please note</b>
            <p>
              By migrating, you'll no longer be able to access app.swoopfunding.com - once you've
              tried the new app we don't think that'll be a problem!
            </p>

            <p>
              Please confirm you'd like to continue with this upgrade and we'll make the required
              changes.
            </p>
            <Button
              id="upgrade-submit"
              ariaLabel="upgrade-submit"
              type="submit"
              tabIndex={5}
              clickHandler={submitRegister}
              loading={loading}
            >
              {t('migrateuser:submit')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
