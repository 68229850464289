import React, { forwardRef } from 'react';
import cn from 'classnames';
import styles from './Select.module.scss';

type SelectOption = {
  label: string;
  value: string;
};

interface SelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  value: string;
  options: SelectOption[];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { value, options, placeholder = 'Select one', disabled, className, onChange, onBlur, ...attrs },
    ref
  ) => {
    return (
      <select
        ref={ref}
        className={cn(styles.select, className)}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...attrs}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }
);

export default Select;
