import React from 'react';
import cn from 'classnames';
import styles from './Table.module.scss';

type TableProps = {
  className?: string;
  children: React.ReactNode;
};

const Table: React.FC<TableProps> = ({ className, children }) => {
  return <table className={cn(styles.table, className)}>{children}</table>;
};

export default Table;
