import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Tab.module.scss';

export type TabProps = {
  value: string;
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

const Tab: React.FC<TabProps> = ({ children, isActive, onClick }) => {
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClick?.();
    }
  };

  return (
    <div
      role="tab"
      aria-selected={isActive}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyPress}
      className={classNames(styles.tab, {
        [styles['active']]: isActive,
      })}
    >
      {children}
    </div>
  );
};

export default Tab;
