import { ACTIVITY_TYPE } from '../types';

export type NewActivityStateType = {
  activity: string;
  isCollapsed: boolean;
  isSaving: boolean;
  wantsToDeleteWithoutSaving: boolean;
  //   callStatus: ACTIVITY_TYPE.CALL_SUCCESS | ACTIVITY_TYPE.CALL_FAILURE | null;
  callStatus: string | undefined;
};
export enum ActionType {
  SET_ACTIVITY,
  SET_IS_SAVING,
  SET_IS_COLLAPSED,
  SET_WANTS_TO_DELETE,
  SET_CALL_STATUS,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function newActivityReducer(state: NewActivityStateType, action: Action) {
  switch (action.type) {
    case ActionType.SET_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
      };

    case ActionType.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload.isSaving,
      };

    case ActionType.SET_IS_COLLAPSED:
      return {
        ...state,
        isCollapsed: action.payload.isCollapsed,
      };

    case ActionType.SET_WANTS_TO_DELETE:
      return {
        ...state,
        wantsToDeleteWithoutSaving: action.payload.wantsToDeleteWithoutSaving,
      };

    case ActionType.SET_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload.callStatus,
      };

    default:
      return state;
  }
}
