import { convertToLocalDateFormat } from '_shared/utils/date';
import { formatCurrency } from '_shared/utils/currency';
import { TablePropType } from 'components/table/type';
import { RoutePath } from '_shared/routes';
import styles from 'pages/deals/assets/deals.module.scss';
import { TLinkTarget } from 'components/table/cell/type';
import { XApplicationTypes } from '_shared/utils/application';
import { creatorTypeList, dealsTypeList, dealTypesWithoutSubtypesValue } from '../constant';

export type DealsFieldsType = {
  items: Array<{ [key: string]: any }>;
  headerCb: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  order: { order: string; direction: string };
  isClientSpecificDeals: boolean;
  onMenuExpand?: (ele: HTMLElement, item: { [key: string]: any }) => void;
  currencySymbol: string;
};

export const dealsField = ({
  items,
  headerCb,
  order = { order: '', direction: '' },
  isClientSpecificDeals,
  onMenuExpand,
  currencySymbol,
}: DealsFieldsType): TablePropType => {
  const { order: sortOrder, direction } = order || {};

  const updatedItems: any = items?.map((item: any) => {
    const creatorType = creatorTypeList.find((creator) => creator.value === item?.creatorType);

    const subtypeLabel =
      (dealTypesWithoutSubtypesValue.includes(item?.type)
        ? dealsTypeList.find(({ value }) => value === item?.type)?.label
        : item?.subType) || 'N/A';

    return [
      {
        id: item.id,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: item?.ownerName ? item?.ownerName : 'N/A',
      },
      {
        id: item.companyId,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: !isClientSpecificDeals && item?.companyName,
        link: RoutePath.companyoverview.replace(':id', item.companyId),
        linkTarget: TLinkTarget.BLANK,
      },

      {
        id: `${item.id}-creator`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: creatorType?.label,
      },
      {
        id: `${item.id}-subtype`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: subtypeLabel,
      },
      {
        id: `${item.id}-stage`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        readOnlyStatus: true,
        status: item.dealsStatusList[item.status]?.label,
      },
      {
        id: `${item.id}-last-progressed`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: convertToLocalDateFormat(item.lastProgressed),
        direction: (Boolean(sortOrder && sortOrder.includes('lastProgressed')) && direction) || '',
        isSortable: true,
        callback: headerCb,
        sortBy: 'lastProgressed',
      },
      {
        id: `${item.id}-last-contact`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: item?.hasOwnProperty('lastContactDate')
          ? convertToLocalDateFormat(item.lastContactDate)
          : 'N/A',
      },
      {
        id: `${item.id}-amount`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('amount')) && direction) || '',
        isSortable: true,
        callback: headerCb,
        default: formatCurrency(item.amount, currencySymbol, 2),
        sortBy: 'amount',
      },
      {
        id: `${item.id}-funder-commission`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: item?.hasOwnProperty('funderComission')
          ? formatCurrency(item.funderComission, currencySymbol, 2)
          : '',
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        id: `${item.id}-client-fee`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: item?.hasOwnProperty('clientFee')
          ? formatCurrency(item.clientFee, currencySymbol, 2)
          : '',
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        id: `${item.id}-introducer-fee`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: formatCurrency(item?.introducerFee, currencySymbol, 2),
      },
      {
        id: `${item.id}-net-fee`,
        classname: {
          cell: styles['tr-cell'],
          value: styles['tr-value'],
        },
        default: item?.hasOwnProperty('netFee')
          ? formatCurrency(item?.netFee, currencySymbol, 2)
          : '',
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        classname: {
          cell: styles['tr-cell-label'],
        },
        id: `${item.id}-actions`,
        icon: 'more_horiz',
        actionCb: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
          e.stopPropagation();
          onMenuExpand?.(e.target as HTMLElement, item);
        },
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
    ].filter((item: any) => item.disabled !== true);
  });

  const fields = {
    header: [
      {
        value: 'Owner',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: !isClientSpecificDeals && 'Company',
        sortBy: 'companyName',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('companyName')) && direction) || '',
        isSortable: isClientSpecificDeals ? false : true,
        callback: headerCb,
      },
      {
        value: 'Creator',
        sortBy: 'creatorType',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('creatorType')) && direction) || '',
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Subtype',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Status',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Progressed',
        sortBy: 'lastProgressed',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('lastProgressed')) && direction) || '',
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Contacted',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Amount',
        sortBy: 'amount',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
          value: styles['th-value'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('amount')) && direction) || '',
        isSortable: true,
        callback: headerCb,
      },
      {
        value: 'Funder Commission',
        classname: {
          header: styles['th-header'],
        },
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        value: 'Client Fee',
        classname: {
          header: styles['th-header'],
        },
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        value: 'Introducer',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Net Fee',
        classname: {
          header: styles['th-header'],
        },
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
      {
        value: 'actions',
        classname: {
          header: styles['th-header'],
        },
        displayOnApp: XApplicationTypes.BrokerApplication,
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    emptyMessage: 'No deals found',
    classname: { table: styles.table },
    items: updatedItems,
  };

  return fields;
};
