export type AutocompleteType = {
  placeholder: string;
  callBack: Function;
  handleChange: any;
  name: any;
  defaultItems: any;
  className?: string;
  value?: any;
  multiselectTag?: boolean;
  multiselectTagList?: string;
  tabIndex?: number;
  errors: { [key: string]: any };
  enableAutofocus?: boolean;
  disabled?: boolean;
};
export enum AutocompleteActionType {
  FETCH,
  SELECTED,
}
