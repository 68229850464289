import React, { useImperativeHandle, useReducer, useState } from 'react';
import cn from 'classnames';
import styles from 'pages/documentLibrary/assets/newDocument.module.scss';
import Field from 'components/field';
import { NewDocumentTypeProps, UploadedDocumenType } from './types';
import { ActionType, newDocumentReducer } from 'pages/documentLibrary/store/newDocument.reducer';
import { NewDocumentFields } from 'pages/documentLibrary/fields/newDocument.fields';
import { postCompanyDocument } from '_shared/api/documents';
import DuplicateDocumentModal from '../duplicateDocumentModal';
import { DuplicateDocumenType } from '../duplicateDocumentModal/types';

const NewDocument = React.forwardRef((props: NewDocumentTypeProps, ref: any) => {
  const { onCancel } = props;
  const [fileName, fileType] = props.fileName.split('.');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [duplicateDocuments, setDuplicateDocuments] = useState<DuplicateDocumenType[]>([]);

  const [state, dispatch] = useReducer(newDocumentReducer, {
    id: props.id,
    category: props.selectedCategory || '',
    title: props.selectedTitle || '',
    fileName: fileName,
    fileType: fileType,
    file: props.file,
    saving: false,
    errorMessage: {},
  });

  useImperativeHandle(ref, () => ({
    newDocumentSave: handleSave,
    returnsMostReccentDocumentState: returnsRecentDocumentState,
  }));

  const returnsRecentDocumentState = () => {
    return state;
  };

  const generateRandomNumberToTriggerUseEffectInParentComponent = () => {
    return Math.random();
  };

  const handleSave = async (documentToAccept: any) => {
    const duplicateFiles = props.uploadedDocuments?.filter((doc: UploadedDocumenType) => {
      const trimmedFileName = doc.name?.split('.').slice(0, -1).join('.');
      return trimmedFileName === state.fileName;
    });

    let errors = {};
    dispatch({
      type: ActionType.SET_ERROR_MESSAGE,
      payload: errors,
    });

    if (Object.keys(errors).length) return;

    if (duplicateFiles && Boolean(duplicateFiles.length)) {
      setOpenModal(Boolean(duplicateFiles.length));
      setDuplicateDocuments(duplicateFiles);
      props.callback?.(generateRandomNumberToTriggerUseEffectInParentComponent());
    } else {
      try {
        const formData = new FormData();
        formData.append('file', state.file, `${state.fileName}.${state.fileType}`);

        if (!state.category) {
          formData.append('documentType', 'Other');
        } else {
          formData.append('documentType', state.category);
        }

        if (!state.title) {
          formData.append('title', 'N/A');
        } else {
          formData.append('title', state.title);
        }

        const { status } = await postCompanyDocument(props.companyId, formData);

        if (status === 202) {
          props.save(props.id);
        }
      } catch (e) {
        console.error(e);
      } finally {
        props.callback?.(generateRandomNumberToTriggerUseEffectInParentComponent());
      }
    }
  };

  const docFields = NewDocumentFields(state);

  const handleChange = (event: any) => {
    const { id, value } = event?.target || {};
    dispatch({
      type: ActionType.SET_FIELDS,
      payload: {
        [id]: value,
      },
    });
  };

  return (
    <div className={cn(styles['new-upload-section'])}>
      {docFields.map((field: any) => {
        return (
          <Field
            key={field.id}
            metadata={field}
            errors={state.errorMessage}
            handleChange={handleChange}
          />
        );
      })}
      <span onClick={onCancel} className={cn('material-icons', styles['cancel-icon'])}>
        cancel
      </span>
      <DuplicateDocumentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        duplicateDocuments={duplicateDocuments}
        fileName={state.fileName}
      />
    </div>
  );
});

export default NewDocument;
