import { useAppState } from 'store';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import { IContext } from 'store/types';

// This is a custom hook that returns a function that can be used to dispatch a toast message
const useToastMessage = () => {
  const { dispatch: globalDispatch }: IContext = useAppState();

  const errorToastMessage = (message: string) => {
    globalDispatch({
      type: TOAST_ERROR_MESSAGE,
      payload: { toastMessage: message },
    });
  };

  const successToastMessage = (message: string) => {
    globalDispatch({
      type: TOAST_MESSAGE,
      payload: { toastMessage: message },
    });
  };

  return {
    successToastMessage,
    errorToastMessage,
  };
};

export default useToastMessage;
