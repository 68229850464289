import React from 'react';
import classNames from 'classnames';
import { HeaderPropType } from './type';
import styles from './header.module.scss';
import Field from 'components/field';

const Header: React.FC<HeaderPropType> = (props: HeaderPropType) => {
  return (
    <th
      className={classNames(styles.header, props.classname.header, {
        [styles.cursor]: props.isSortable,
        [styles.tooltip]: Boolean(props?.tooltip),
      })}
      data-name={props.sortBy}
      data-label={props?.tooltip}
      onClick={props.callback}
      colSpan={props.colspan || 1}
    >
      <div className={classNames(props.isSortable && styles['sort-value'], props.classname.value)}>
        {(props.fieldMetadata && (
          <Field metadata={props.fieldMetadata} errors={[]} handleChange={props.actionCb} />
        )) || (
          <span className={classNames(styles.label, props.classname.label)}>{props.value}</span>
        )}
        {props.isSortable && (
          <span className={classNames(styles['icon-container'])}>
            <i
              data-sort="asc"
              className={classNames(
                'material-icons',
                styles['icon-up'],
                !props.direction && props.hasSortedColumn && styles['icon-inactive'],
                props.direction === 'desc' && styles['icon-inactive'],
                props.classname.icon
              )}
            >
              keyboard_arrow_right
            </i>
            <i
              data-sort="desc"
              className={classNames(
                'material-icons',
                styles['icon-down'],
                !props.direction && props.hasSortedColumn && styles['icon-inactive'],
                props.direction === 'asc' && styles['icon-inactive'],
                props.classname.icon
              )}
            >
              keyboard_arrow_right
            </i>
          </span>
        )}
      </div>
    </th>
  );
};

export default Header;
