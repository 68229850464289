import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './BusinessInsurance.module.scss';
import icon from '../../assets/business-insurance-icon.svg';
import SavingsProductsForm from 'pages/companySavingsProducts/components/Form';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';
import RiskIcon from '../../assets/businessInsurancePage/risk.svg';
import BusinessFinanceIcon from '../../assets/businessInsurancePage/business-finance.svg';
import ShieldIcon from '../../assets/businessInsurancePage/shield.svg';
import ChangeIcon from '../../assets/businessInsurancePage/change.svg';
import IndemnityInsuranceIcon from '../../assets/businessInsurancePage/indemnity-insurance.svg';
import PublicLiabilityIcon from '../../assets/businessInsurancePage/public-liability-insurance.svg';
import EmployersIcon from '../../assets/businessInsurancePage/employers.svg';
import CyberDataIcon from '../../assets/businessInsurancePage/cyber-and-data.svg';
import LegalProtectionIcon from '../../assets/businessInsurancePage/legal-protection.svg';
import { businessInsuranceFormFields } from './form.fields';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';

const BusinessInsurance: React.FC = () => {
  const formRef = useRef<HTMLDivElement | null>(null);
  const { isLoading, isAvailable, postProductApplication, scrollToForm } =
    useSavingsProducts('business-insurance');
  const [formFields, setFormFields] = useState<FieldMetaDataPropType[] | null>(null);
  const benefitList = [
    {
      id: 'benefit-1',
      icon: RiskIcon,
      title: 'Protect against risk',
      description:
        'All businesses face a level of risk as part of daily operations. Business insurance is available to help protect your client’s business against the impact of unexpected events or possible claims.',
    },
    {
      id: 'benefit-2',
      icon: BusinessFinanceIcon,
      title: 'Legal or industry requirement',
      description:
        'Every business is different, and certain types of insurance – like employers’ liability insurance – may be legally required based on your client’s business profile. It’s up to them to ensure they have the relevant cover in place.',
    },
    {
      id: 'benefit-3',
      icon: ShieldIcon,
      title: 'Specialised\ncover',
      description:
        'Some industries, projects or activities might require specific or specialist insurance policies. Make sure your client buys the right products to reflect the risks and requirements of their business.',
    },
    {
      id: 'benefit-4',
      icon: ChangeIcon,
      title: 'Change in business circumstance',
      description:
        'As your client’s business evolves – growing and taking on new staff –  their business profile, operations and needs may change. Make sure their business is protected against unexpected events and potential claims.',
    },
  ];

  const insuranceTypes = [
    {
      id: 'insurance-type-1',
      icon: IndemnityInsuranceIcon,
      title: 'Indemnity insurance',
      description:
        'Indemnity insurance helps protect and defend your client’s business in situations where it is claimed that they have caused financial loss or reputational damage. Examples include professional negligence, breaching copyright, losing or damaging documents etc.',
    },
    {
      id: 'insurance-type-2',
      icon: PublicLiabilityIcon,
      title: 'Public liability insurance',
      description:
        'Public liability insurance is designed to help shield your client’s business against unforeseen costs resulting from third party injury or damage to property. This includes circumstances like a member of the public making a claim after experiencing an injury on their property.',
    },
    {
      id: 'insurance-type-3',
      icon: EmployersIcon,
      title: 'Employers’ liability insurance',
      description:
        'A legal requirement for most businesses in the UK with employees, Employers’ liability insurance reflects the responsibility your client has for the health and safety of their employees at work, protecting them against the cost of potential compensations claims due to work-related illness or injury.',
    },
    {
      id: 'insurance-type-4',
      icon: CyberDataIcon,
      title: 'Cyber and data insurance',
      description:
        'Also known as cyber liability insurance, or data protection insurance, this type of insurance protects your client’s business against the impact of cyber-crimes like fraud, hacking and data theft. It also provides cover to help manage the impact of sensitive data breaches and GDPR non-compliance claims.',
    },
    {
      id: 'insurance-type-5',
      icon: LegalProtectionIcon,
      title: 'Legal protection insurance',
      description:
        'Legal expenses insurance covers your client for a wide range of things that might hinder business like a contractual dispute with a supplier. This type of cover also includes free access to expert tax and legal advice.',
    },
  ];

  useEffect(() => {
    if (!isAvailable) {
      // reset form fields after submit
      return setFormFields(businessInsuranceFormFields.map((field) => ({ ...field })));
    }
    setFormFields(businessInsuranceFormFields);
  }, [isAvailable]);

  const handleSubmit = async (values: { [key: string]: string }) => {
    await postProductApplication('business-insurance', values);
  };

  const businessInsuranceBenefits = useMemo(() => {
    return benefitList.map((benefit) => (
      <div className={styles['benefit-container']} key={benefit.id}>
        <img className={styles.icon} src={benefit.icon} alt="benefit icon" />
        <div className={styles.title}>{benefit.title}</div>
        <div className={styles.description}>{benefit.description}</div>
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessInsuranceTypes = useMemo(() => {
    return insuranceTypes.map((insuranceType) => (
      <div className={styles['insurance-types-container']} key={insuranceType.id}>
        <div className={styles['insurance-type-header']}>
          <img className={styles.icon} src={insuranceType.icon} alt="benefit icon" />
          <div className={styles.title}>{insuranceType.title}</div>
        </div>
        <div className={styles.description}>{insuranceType.description}</div>
      </div>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Business insurance"
        icon={icon}
        ctaText="Request more info"
        ctaCallback={() => scrollToForm(formRef.current, 50)}
        isCtaDisabled={!isAvailable}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h2>
            With tailored business insurance your client can build a level of cover to protect
            against the specific risks their business could face.
          </h2>
          <h2>Why should your client think about business insurance?</h2>
          <div className={styles['benefits-wrapper']}>{businessInsuranceBenefits}</div>

          <h2>What types of insurance are available?</h2>
          <div className={styles['insurance-types-wrapper']}>{businessInsuranceTypes}</div>

          <div className={cn('divider', styles.divider)} />
          <h2 className={styles['form-title']}>Let's get you set up</h2>
          {formFields && (
            <SavingsProductsForm
              isSubmitDisabled={!isAvailable}
              forwardedRef={formRef}
              fields={formFields}
              submitCallback={handleSubmit}
              title="Get in touch"
              submitButtonText="Get in touch"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessInsurance;
