import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';
import { FormFieldEssentials } from 'pages/companyEssentials/types';

type SummarisedJourneyProps = {
  data: FormFieldEssentials[];
  fields: {
    fieldName: string;
    render: (field: FormFieldEssentials) => any;
  }[];
};

const SummarisedJourney: React.FC<SummarisedJourneyProps> = ({ data, fields }) => {
  const getFieldByName = (name: string) => data.find((field) => field.name === name);

  return (
    <div className={cn(styles['container-fields'])}>
      {fields.map(({ fieldName, render }) => {
        const field = getFieldByName(fieldName);
        return field ? <div key={fieldName}>{render(field)}</div> : null;
      })}
    </div>
  );
};

export default SummarisedJourney;
