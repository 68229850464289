import React, { MouseEventHandler } from 'react';
import styles from './Sidebar.module.scss';
import { Link, matchPath, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { RoutePath } from '_shared/routes';
import { To } from '@remix-run/router/history';
import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';
import features from 'config/features';

type SidebarProps = {
  showAddNewSlideOut: boolean;
  showBookmarks: boolean;
  toggleBookmarks: (show: boolean) => void;
  toggleAddNewSlideOut: (show: boolean) => void;
  onAddBookmark: () => void;
};

type SidebarItemProps = {
  icon: string;
  title: string;
  to: To;
  active?: boolean;
};

const AddNewButton: React.FC<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  active: boolean;
}> = ({ onClick, active }) => {
  return (
    <button
      className={cn(styles['add-new-btn'], { [styles.active]: active })}
      onClick={onClick}
      // Added this specifically to prevent useBlur hook from closing the add new slide out in parent component
      onMouseDownCapture={(e) => e.stopPropagation()}
    >
      <i className={cn('material-icons', styles.icon)}>add_circle</i>
      <span className={styles.title}>Add new</span>
    </button>
  );
};

const BookmarksButton: React.FC<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  active: boolean;
}> = ({ onClick, active }) => {
  return (
    <button
      className={cn(styles['bookmarks-btn'], { [styles.active]: active })}
      onClick={onClick}
      // Added this specifically to prevent useBlur hook from closing the bookmarks in parent component
      onMouseDownCapture={(e) => e.stopPropagation()}
    >
      <i className={cn('material-icons', styles.icon)}>{active ? 'bookmark' : 'bookmark_border'}</i>
      <span className={styles.title}>Bookmarks</span>
    </button>
  );
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, title, active, to }) => {
  const isExternal = typeof to === 'string' && to.startsWith('http');
  return (
    <Link
      to={to}
      className={cn(styles['sidebar-item'], { [styles.active]: active })}
      target={isExternal ? '_blank' : undefined}
    >
      <i className={cn(active ? 'material-icons' : 'material-icons-outlined', styles.icon)}>
        {icon}
      </i>
      <span className={styles.title}>{title}</span>
    </Link>
  );
};

const Sidebar: React.FC<SidebarProps> = ({
  showAddNewSlideOut,
  showBookmarks,
  toggleBookmarks,
  toggleAddNewSlideOut,
}) => {
  const { pathname } = useLocation();

  const colleaguesPath = isBrokerApplication
    ? RoutePath.userslist.replace(':type', 'broker')
    : RoutePath.userslist.replace(':type', 'advisor');

  const helpUrl = isBrokerApplication
    ? 'https://swoopfunding.com/brokers/'
    : 'https://swoopfunding.com/advisors/help';

  const isCompanyDeals = matchPath({ path: RoutePath.companydeal + '/*' }, pathname);

  const checkActive = (...path: string[]) => {
    return showBookmarks || showAddNewSlideOut
      ? false
      : path.some((p) => matchPath({ path: p }, pathname));
  };

  return (
    <div className={styles.sidebar}>
      <AddNewButton
        onClick={() => toggleAddNewSlideOut(!showAddNewSlideOut)}
        active={showAddNewSlideOut}
      />

      <SidebarItem
        icon="store"
        title="Clients"
        to={RoutePath.companies}
        active={
          !isCompanyDeals && checkActive(RoutePath.companies, RoutePath.companydetails + '/*')
        }
      />

      <SidebarItem
        icon="widgets"
        title="Deals"
        to={RoutePath.deals}
        active={checkActive(
          RoutePath.deals + '/*',
          RoutePath.dealdetails + '/*',
          RoutePath.companydeal + '/*'
        )}
      />

      <SidebarItem
        icon="library_add_check"
        title="Tasks"
        to={RoutePath.tasks}
        active={checkActive(RoutePath.tasks + '/*')}
      />

      {(isBrokerApplication || features.sidebarProductsEnabled) && (
        <SidebarItem
          icon="category"
          title="Products"
          to={RoutePath.products}
          active={checkActive(RoutePath.products, RoutePath.productdetails + '/*')}
        />
      )}

      <SidebarItem
        icon="manage_accounts"
        title="Profile"
        to={RoutePath.groupManagement}
        active={checkActive(RoutePath.groupManagement + '/*')}
      />

      <SidebarItem
        icon="contacts"
        title="Colleagues"
        to={colleaguesPath}
        active={checkActive(RoutePath.userslist, RoutePath.userdetails + '/*')}
      />

      {isAdvisorApplication && features.sidebarEducationLink && (
        <SidebarItem
          icon="local_library"
          title="Education"
          to="https://hub.swoopfunding.com/advisor-training"
        />
      )}

      <BookmarksButton
        onClick={() => toggleBookmarks(!showBookmarks)}
        active={showBookmarks || checkActive(RoutePath.addBookmark + '/*')}
      />

      <SidebarItem icon="contact_support" title="Help & FAQ" to={helpUrl} />
    </div>
  );
};

export default Sidebar;
