import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useAppState, clearState } from 'store';
import { LSKeys } from '_shared/utils/constants';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { useTranslation } from 'react-i18next';
import Loader from 'components/loader';
import styles from './assets/navbar.module.scss';
import logo from '../../assets/images/swoop-logo-white.svg';
import { UPDATE_SESSION } from 'store/system/types';
import Multiselect from 'components/field/multiselect';
import { initialState as initialSystemState } from 'store/system/reducers';
import { UserModalEnum } from 'layouts/type';
import { capitalizeFirstLetter } from '_shared/utils/string';
import { applicationName } from '_shared/utils/application';
import AddUserModal from 'layouts/components/AddUserModal/AddUserModal';
import Menu from '../menu';
import MenuItem from '../menu/MenuItem';

const Navbar = (props: any) => {
  const location = useLocation();
  const { state: appState, dispatch: globalDispatch } = useAppState();

  const [userModalContext, setUserModalContext] = useState<UserModalEnum | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [pageLoader, setPageLoader] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userName = useMemo(() => {
    const currentUser = appState.system.currentUser;
    return (
      (currentUser?.firstName || currentUser?.lastName) &&
      [
        ...((currentUser?.firstName && [currentUser?.firstName]) || []),
        ...((currentUser?.lastName && [currentUser?.lastName]) || []),
      ].join(' ')
    );
  }, [appState.system]);

  const userPicture = useMemo(() => appState.system.currentUser?.picture, [appState.system]);

  const signout = () => {
    setPageLoader(true);
    clearState(LSKeys.swoopAnalytics);
    globalDispatch({
      type: UPDATE_SESSION,
      payload: {
        ...initialSystemState,
        hasOnboardedGroups: appState?.system.hasOnboardedGroups,
        hasOnboardedDeals: appState?.system.hasOnboardedDeals,
      },
    });

    navigate(RoutePath.login, { replace: true });
  };

  if (pageLoader) {
    return <Loader active />;
  }

  const defaultGroup = {
    groupName: 'My group',
    groupId: appState?.system?.groupId || '',
  };

  const groups = [
    defaultGroup,
    ...(appState?.system?.exposedGroups?.sort((a, b) => a.groupName.localeCompare(b.groupName)) ||
      []),
  ];

  if (groups.length > 1) {
    groups.unshift({
      groupName: 'All',
      groupId: 'all',
    });
  }

  const parseGroupDataOption = (string: string) => {
    try {
      return JSON.parse(string);
    } catch (error) {
      return string;
    }
  };

  const updateSelectedGroups = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGroup = parseGroupDataOption(event.target.dataset.option as string);
    let currentlySelectedGroups = appState?.system?.selectedGroups || [];

    if (currentlySelectedGroups.find((group) => group.groupName === selectedGroup.label)) {
      currentlySelectedGroups = currentlySelectedGroups.filter(
        (group) => group.groupName !== selectedGroup.label
      );
    } else {
      currentlySelectedGroups = [
        ...currentlySelectedGroups.filter((group) => group.groupName !== 'All'),
        { groupName: selectedGroup.label, groupId: selectedGroup.value },
      ];
    }

    if (!currentlySelectedGroups.length) {
      currentlySelectedGroups = [{ groupName: 'All', groupId: 'all' }];
    }

    if (!selectedGroup) return;
    globalDispatch({
      type: UPDATE_SESSION,
      payload: {
        ...appState,
        selectedGroups: currentlySelectedGroups,
      },
    });

    window.location.reload();
  };

  const showGroupSelect = () => {
    const currentPath = location.pathname;
    return Boolean(
      groups?.length > 1 &&
        [
          Boolean(matchPath({ path: RoutePath.companies }, currentPath)),
          Boolean(matchPath({ path: RoutePath.applications + '/*' }, currentPath)),
          Boolean(matchPath({ path: RoutePath.deals + '/*' }, currentPath)),
        ].includes(true)
    );
  };

  const sideBarLogo = () => {
    if (appState.system.currentUser?.groupLogoUrl && !props.activeInMobile) {
      return (
        <div className={cn(styles['group-logo-holder'])}>
          <img
            alt="logo"
            src={appState.system.currentUser?.groupLogoUrl}
            className={cn(styles.logo)}
          />
        </div>
      );
    } else if (!props.activeInMobile) {
      return (
        <div className={cn(styles['main-logo-holder'])}>
          <img alt="logo" src={logo} className={cn(styles.logo)} />
          {t('app:name', { appType: capitalizeFirstLetter(applicationName()) })}
        </div>
      );
    } else return null;
  };

  return (
    <header className={cn(styles['header-container'])}>
      <div className={cn(styles['page-headers'])}>{sideBarLogo()}</div>
      <div className={cn(styles.actions)}>
        {showGroupSelect() && (
          <div className={cn(styles['group-selection-container'])}>
            <Multiselect
              {...{
                metadata: {
                  id: 'group-select',
                  name: 'group-select',
                  value: appState?.system?.selectedGroups?.map(({ groupName, groupId }) => {
                    return {
                      label: groupName,
                      value: groupId,
                    };
                  }),
                  classNames: {
                    value: styles['group-selection'],
                  },
                  selectValue: true,
                  options: groups.map(({ groupName, groupId }) => {
                    return {
                      label: groupName,
                      value: groupId,
                    };
                  }),
                },
                errors: {},
                handleChange: updateSelectedGroups,
              }}
            />
          </div>
        )}
        <button
          data-testid="test-is-more-options-open"
          className={styles['more-options']}
          onClick={(e) => {
            e.stopPropagation();
            setMenuAnchorEl(e.currentTarget);
          }}
        >
          {userPicture ? (
            <img src={userPicture} alt="profile" />
          ) : (
            <i className={cn('material-icons', styles['icon-profile'])}>person</i>
          )}
          {userName || t('sidebar:tertiary:yourprofile')}
          <i className="material-icons"> more_vert</i>
        </button>

        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          {appState.system.isSuperAdmin?.() && (
            <MenuItem icon="email" onClick={() => setUserModalContext(UserModalEnum.GROUP)}>
              Invite Group
            </MenuItem>
          )}
          <MenuItem icon="input" onClick={signout}>
            Sign out
          </MenuItem>
        </Menu>
      </div>

      {(Boolean(userModalContext) && (
        <AddUserModal {...{ userModalContext, setUserModalContext }} />
      )) ||
        null}
    </header>
  );
};

export default Navbar;
