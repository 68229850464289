import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { env } from '_shared/utils';
import {
  getCompletedFundingForms,
  getFundingForm,
  getFundingForms,
  getFundingFormV2,
} from '_shared/api/fundingMatches';
import { getAccountingStatus, getBankAccountStatus } from '_shared/api/financialInfo';
import { RoutePath } from '_shared/routes';

import styles from './assets/companyEssentials.module.scss';
import classNames from 'classnames';
import { ContactType, FormFieldEssentials } from './types';
import {
  formatDate,
  formatDateDots,
  formatIndustries,
  getValueByFieldName,
  renderTextWithLineBreaks,
} from './utils';
import formComponentConfig from './formComponentConfig';
import {
  CompanyActionType,
  CompanyDetailsContext,
} from 'pages/companyDetails/store/companyDetails.reducer';
import Spinner from 'components/spinner';

import { useTranslation } from 'react-i18next';
import {
  documentLibraryActionType,
  documentLibraryReducer,
} from 'pages/documentLibrary/store/documentLibrary.reducer';
import { getCompanyDocuments } from '_shared/api/documents';

import FundingNeed from './components/fundingNeed';
import IndustryDetails from './components/industryDetails';
import ContactDetails from './components/contactDetails';
import ContactAddress from './components/contactAddress';
import BusinessDetails from './components/businessDetails';
import DocumentsAttached from './components/documentsAttached';
import { APIDocumentType } from 'pages/documentLibrary/types';
import IntegrationsDetails from './components/integrationsDetails';
import AccountOwner from './components/accountOwner';

type FundingFormType = {
  formId?: string;
  name?: string;
  dateCreated?: Date;
  displayName?: string;
  type?: string;
  icon?: string;
  description?: string;
  route?: string;
  order?: number;
};

type CompletedForm = {
  companyId: string;
  formId: string;
  type: string;
  dateCreated: string;
};

type FormData = {
  id: string;
  fetchedData: FormFieldEssentials[];
  dateCreated: string;
  journeyDisplayName: string;
  journeyName: string;
};

const Essentials: React.FC = () => {
  const { state, dispatch: companyDetailsDispatch } = useContext(CompanyDetailsContext);
  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [documentLibraryState, dispatch] = useReducer(documentLibraryReducer, {
    data: [] as APIDocumentType[],
    loading: true,
    error: false,
  });

  const [completedForms, setCompletedForms] = useState<CompletedForm[]>([]);
  const [formData, setFormData] = useState<FormData[]>([]);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<ContactType>({});
  const [address, setAddress] = useState<string>('');
  const [companyNumber, setCompanyNumber] = useState<string | undefined>('');
  const [companyName, setCompanyName] = useState<string | undefined>('');
  const [businessWebsite, setBusinessWebsite] = useState<string | null>('');
  const [livingSituation, setLivingSituation] = useState<string | null | undefined>(null);
  const [turnover, setTurnover] = useState<string | null>(null);
  const [fundingNeed, setFundingNeed] = useState<string | null>('');
  const [fundingReasonDescription, setFundingReasonDescription] =
    useState<React.ReactElement | null>(null);
  const [accountingIntegrationStatus, setAccountingIntegrationStatus] = useState<{
    [key: string]: string;
  }>({});
  const [bankIntegrationStatus, setBankIntegrationStatus] = useState<{
    [key: string]: string;
  }>({});
  const [integrations, setIntegrations] = useState<string>('');
  const [allowedDocuments, setAllowedDocuments] = useState<APIDocumentType[]>([]);
  const [industries, setIndustries] = useState<React.ReactElement | null>(null);
  const [tradingAs, setTradingAs] = useState<React.ReactElement | null>(null);
  const [tradingSinceDate, setTradingSinceDate] = useState<string | null>(null);

  useEffect(() => {
    companyDetailsDispatch({
      type: CompanyActionType.SET_HAS_NAVIGATED_TO_ESSENTIALS,
      payload: true,
    });
  }, []);

  // Fetch company details and setup form data
  useEffect(() => {
    if (!state) return;
    setContact(state?.accountInfoData?.contacts?.[0] || {});
    setCompanyNumber(state?.company?.data?.companyNumber);
    setCompanyName(state?.company?.data?.companyName);
    buildAddress();
  }, [state]);

  useEffect(() => {
    if (!companyId) return;

    const fetchCompanyDetails = async () => {
      try {
        await getCompletedForms(companyId);
        await getStatus(companyId);
        await getBankStatus(companyId);
        await fetchDocumentList(companyId);
      } catch (error) {
        console.error('Error fetching company details: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);

  // Fetch funding forms data based on completed forms
  useEffect(() => {
    if (!completedForms.length) return;

    const fetchFormData = async () => {
      try {
        const response = await getFundingForms();
        const formsList = response.data;
        const allFormData = await getAllFormData(completedForms, formsList);
        setFormData(allFormData);
      } catch (error) {
        console.error('Error fetching form data: ', error);
      }
    };

    fetchFormData();
  }, [completedForms]);

  // Update various fields based on fetched formData
  useEffect(() => {
    if (!formData.length) return;

    let updatedTurnover = getLabelFromOptions('turnoverLastYearRange');
    if (!updatedTurnover) {
      const turnoverFromField = getValueByFieldName(formData, 'turnoverLastYear');
      if (turnoverFromField) {
        updatedTurnover = `${t('currency:symbol')}${turnoverFromField}`;
      }
    }
    setTurnover(updatedTurnover);

    const fundingReasonDesc = getValueByFieldName(formData, 'fundingNeedsDescription');
    if (fundingReasonDesc) setFundingReasonDescription(renderTextWithLineBreaks(fundingReasonDesc));

    const industryList = formatIndustries(formData, gotoOverview);
    if (industryList) setIndustries(industryList);

    const tradingAsValue = getValueByFieldName(formData, 'tradingAs');
    if (tradingAsValue) setTradingAs(renderTextWithLineBreaks(tradingAsValue));

    setBusinessWebsite(getValueByFieldName(formData, 'businessWebsite'));
    setLivingSituation(getLabelFromOptions('livingSituation'));
    setFundingNeed(getValueByFieldName(formData, 'fundingAmount'));

    getTradingDate();
  }, [formData]);

  // Document list handler
  useEffect(() => {
    if (documentLibraryState.data.length > 0) {
      const propertyDocs = documentLibraryState.data.filter(
        (doc: APIDocumentType) => doc.type.toLowerCase() !== 'thumbnail'
      );
      setAllowedDocuments(propertyDocs);
    }
  }, [documentLibraryState]);

  // Handle integration status
  useEffect(() => {
    if (accountingIntegrationStatus?.status || bankIntegrationStatus?.status) {
      const integrationsList: string[] = [];
      if (accountingIntegrationStatus.status === 'Connected')
        integrationsList.push(accountingIntegrationStatus.providerName ?? '');
      if (bankIntegrationStatus.status === 'ProcessingCompleted')
        integrationsList.push(bankIntegrationStatus.bankName ?? '');
      setIntegrations(integrationsList.join(', '));
    }
  }, [accountingIntegrationStatus, bankIntegrationStatus]);

  const getCompletedForms = async (id: string) => {
    const { data } = await getCompletedFundingForms(id);
    setCompletedForms(data);
  };

  const getStatus = async (id: string) => {
    // not sure why getAccountingStatus is sometimes undefined instead of a function so it sometimes throws an error.
    // we check if it's a function before calling it to avoid the error
    if (typeof getAccountingStatus === 'function') {
      try {
        const { data } = await getAccountingStatus(id);
        setAccountingIntegrationStatus(data);
      } catch (error) {
        console.error('Error fetching accounting status:', error);
      }
    } else {
      console.warn('getAccountingStatus is not a function');
    }
  };

  const getBankStatus = async (id: string) => {
    // not sure why getBankAccountStatus is sometimes undefined instead of a function so it sometimes throws an error.
    // we check if it's a function before calling it to avoid the error
    if (typeof getBankAccountStatus === 'function') {
      try {
        const response = await getBankAccountStatus(id);
        setBankIntegrationStatus(
          response?.data?.length === 0 ? { status: 'NotIntegrated' } : response?.data?.[0]
        );
      } catch (error) {
        console.error('Error fetching bank account integration status:', error);
      }
    } else {
      console.warn('getBankAccountStatus is not a function');
    }
  };

  const fetchDocumentList = async (id: string) => {
    try {
      const { data } = await getCompanyDocuments(id);
      dispatch({ type: documentLibraryActionType.SET_DOCUMENTS, payload: { data } });
    } catch (error) {
      console.error('Error fetching documents: ', error);
      dispatch({ type: documentLibraryActionType.SET_DOCUMENTS, payload: { error: true } });
    }
  };

  const getAllFormData = async (completedForms: CompletedForm[], formsList: FundingFormType[]) => {
    const results: FormData[] = [];

    for (const form of completedForms) {
      try {
        const data = await getData(form.formId);
        const formDetails = formsList.find((f) => f.formId === form.formId);
        results.push({
          id: form.formId,
          fetchedData: data,
          dateCreated: form.dateCreated,
          journeyDisplayName: formDetails?.displayName ?? '',
          journeyName: formDetails?.name ?? '',
        });
      } catch (error) {
        console.error(`Error fetching data for form ${form.formId}:`, error);
      }
    }

    return results;
  };

  const getData = async (id: string) => {
    const version = env('REACT_APP_DYNAMIC_FORMS_ENDPOINTS_VERSION');
    const fetchedformData = await fetchFormData(version, id, companyId!);

    const form =
      version === 'v2'
        ? {
            displayName: fetchedformData[1]?.displayName,
            formId: fetchedformData[1]?.formId,
            formSections: fetchedformData,
            name: fetchedformData[1]?.name,
          }
        : fetchedformData;

    const allFormFields = form.formSections.reduce(
      (acc: any[], section: any) => [...acc, ...section.formFields],
      []
    );
    return allFormFields.map((field: FormFieldEssentials) => ({
      ...field,
      value: tryParseJSON(field.value),
    }));
  };

  const tryParseJSON = (value: any) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const fetchFormData = async (version: string, activeTab: string, companyId: string) => {
    const { data } =
      version === 'v2'
        ? await getFundingFormV2(activeTab, companyId)
        : await getFundingForm(activeTab, companyId);
    return version === 'v2' ? data.sections : data;
  };

  const buildAddress = () => {
    const companyAddress = [
      state?.company?.data?.registeredAddress?.address,
      state?.company?.data?.registeredAddress?.city,
      state?.company?.data?.registeredAddress?.county,
      state?.company?.data?.registeredAddress?.postcode,
      state?.company?.data?.registeredAddress?.stateCode,
      state?.company?.data?.registeredAddress?.country,
    ].filter(Boolean);

    setAddress(companyAddress.join(', '));
  };

  const getLabelFromOptions = (fieldName: string): string | null => {
    const fieldValue = getValueByFieldName(formData, fieldName);
    const fieldOptions = formData
      .flatMap((item) => item.fetchedData || [])
      .find((field) => field.name === fieldName)?.options;
    const selectedOption = fieldOptions?.find((option) => option.value === fieldValue);
    return selectedOption?.label ?? null;
  };

  const getTradingDate = (): void => {
    const incorporationDate = state?.company?.data?.incorporationDate?.trim();
    const tradingStartDate = getValueByFieldName(formData, 'tradingStartDate');

    const dateToSet = incorporationDate || tradingStartDate;
    setTradingSinceDate(formatDateDots(dateToSet));
  };

  const gotoOverview = () => {
    if (companyId) {
      navigate(RoutePath.companyoverview.replace(':id', companyId));
    }
  };

  const editDetailsHandler = () => {
    gotoOverview();
  };

  if (loading) {
    return (
      <div className="error-container">
        <Spinner size="large" />
      </div>
    );
  }
  return (
    <div className={styles['container']}>
      <section className={styles['content-left']}>
        {fundingReasonDescription && (
          <FundingNeed fundingReasonDescription={fundingReasonDescription} />
        )}

        {(industries || tradingAs || state?.accountInfoData?.referredBy) && (
          <IndustryDetails
            industries={industries}
            businessType={tradingAs}
            source={state.accountInfoData.referredBy}
          />
        )}

        {contact && Object.keys(contact).length > 0 && (
          <ContactDetails contact={contact} buttonHandler={editDetailsHandler} />
        )}

        {/* insert journey responses here for each type of journey required */}
        {formData.map((form) => {
          const SpecificComponent = formComponentConfig[form.journeyName];
          return (
            SpecificComponent && (
              <div key={form.id} className={classNames(styles['container-journey-responses'])}>
                <h2>
                  <span>‘{form.journeyDisplayName}’</span> journey details
                </h2>
                {form.dateCreated && (
                  <p className={classNames(styles['label'])}>
                    Completed on: {formatDate(form.dateCreated, 'short')}
                  </p>
                )}

                <SpecificComponent
                  fetchedData={form.fetchedData}
                  dateCreated={formatDate(form.dateCreated, 'short')}
                  editDetails={editDetailsHandler}
                  journeyDisplayName={form.journeyDisplayName}
                  journeyName={form.journeyName}
                />
              </div>
            )
          );
        })}

        {address !== '' && <ContactAddress address={address} />}
      </section>

      <section className={styles['content-right']}>
        {(turnover ||
          tradingSinceDate ||
          fundingNeed ||
          livingSituation ||
          (companyName && companyName !== '') ||
          (companyNumber && companyNumber !== '') ||
          businessWebsite ||
          (state?.company?.data?.accounts &&
            Object.keys(state.company.data.accounts).length > 0)) && (
          <BusinessDetails
            turnover={turnover}
            tradingSinceDate={tradingSinceDate}
            fundingNeed={fundingNeed}
            livingSituation={livingSituation}
            companyName={companyName}
            companyNumber={companyNumber}
            businessWebsite={businessWebsite}
            accounts={state?.company?.data?.accounts}
            buttonHandler={editDetailsHandler}
          />
        )}

        {allowedDocuments && allowedDocuments.length > 0 && companyId && (
          <DocumentsAttached documents={allowedDocuments} companyId={companyId} />
        )}

        <IntegrationsDetails integrations={integrations} />

        {state?.accountInfoData?.fundingManagerName &&
          state?.accountInfoData?.fundingManagerName !== '' && (
            <AccountOwner fundingManagerName={state?.accountInfoData?.fundingManagerName} />
          )}
      </section>
    </div>
  );
};

export default Essentials;
