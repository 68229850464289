import cn from 'classnames';
import styles from '../../assets/passwordManagement.module.scss';
import Button from 'components/button';
import { ActionType } from 'pages/userDetails/reducer';
import { useTranslation } from 'react-i18next';
import { postForgetPassword } from '_shared/api/users';
import { useState } from 'react';
import Spinner from 'components/spinner';
import useToastMessage from '_shared/hooks/useToastMessage';

type PasswordManagementProps = {
  userDetailsState: any;
  userDetailsDispatch: any;
  securityId: string;
};

const PasswordManagement = ({
  userDetailsState,
  userDetailsDispatch,
  securityId,
}: PasswordManagementProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const usersEmail = userDetailsState.userDetailsInfo.email ?? 'their email';
  const firstName = userDetailsState.userDetailsInfo.firstName ?? '';
  const lastName = userDetailsState.userDetailsInfo.lastName ?? '';
  const usersFullName = firstName || lastName ? `${firstName} ${lastName}` : 'the users';

  const forgotPasswordHandler = async (securityId: string) => {
    try {
      setLoading(true);

      if (!securityId) return;

      await postForgetPassword(securityId);

      successToastMessage(t('home:colleagues:details:toast:resetpasswordsuccess'));
    } catch (error) {
      userDetailsDispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
      errorToastMessage('Failed to send password reset email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['forgot-password-container']}>
      <h3>Reset Password</h3>
      <p>
        When resetting a user's password, an email will be sent to
        <strong> {usersEmail} </strong> with instructions to complete the password reset process.
      </p>

      <Button
        ariaLabel={'forgot-password-btn'}
        id={'forgot-password-btn'}
        className={styles['forgot-password-btn']}
        clickHandler={() => forgotPasswordHandler(securityId)}
      >
        {loading ? <Spinner size={'small'} /> : `Send a reset password email to ${usersFullName}`}
      </Button>

      <p>
        Upon sending the reset email, it becomes the user's responsibility to complete the password
        reset process. Once the user successfully resets their password, their previous password
        will be invalidated.
      </p>
    </div>
  );
};

export default PasswordManagement;
