import cn from 'classnames';
import styles from '../../assets/cacheManagement.module.scss';
import Button from 'components/button';
import { ActionType } from 'pages/userDetails/reducer';
import { useTranslation } from 'react-i18next';
import { postFlushCache } from '_shared/api/users';
import { useState } from 'react';
import Spinner from 'components/spinner';
import useToastMessage from '_shared/hooks/useToastMessage';

type CacheManagementProps = {
  userDetailsState: any;
  userDetailsDispatch: any;
  securityId: string;
};

const CacheManagement = ({
  userDetailsState,
  userDetailsDispatch,
  securityId,
}: CacheManagementProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const firstName = userDetailsState.userDetailsInfo.firstName ?? '';
  const lastName = userDetailsState.userDetailsInfo.lastName ?? '';

  const usersFullName = firstName || lastName ? `${firstName} ${lastName}` : 'the users';

  const flushCacheHandler = async (securityId: string) => {
    try {
      setLoading(true);

      if (!securityId) return;
      await postFlushCache(securityId);
      successToastMessage(t('home:colleagues:details:toast:flushcachesuccess'));
    } catch (error) {
      userDetailsDispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
      errorToastMessage(t('Failed to refresh users cache'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['flush-cache-container']}>
      <h3>Flush Cache</h3>
      <p>
        Flushing a users cache clears stored data related to the user, providing a clean slate for
        improved system performance.
      </p>

      <p>
        Please note that flushing the cache might temporarily impact the user's experience as the
        system rebuilds the cache with the latest information.
      </p>

      <Button
        ariaLabel={'flush-cache-btn'}
        id={'flush-cache-btn'}
        className={styles['flush-cache-btn']}
        clickHandler={() => flushCacheHandler(securityId)}
      >
        {loading ? <Spinner size={'small'} /> : `Flush ${usersFullName} cache`}
      </Button>
    </div>
  );
};

export default CacheManagement;
