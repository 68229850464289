import { TablePropType } from 'components/table/type';
import styles from './assets/users.module.scss';
import { APIUsersByRoleType, UserDetailsFieldType } from './type';

export const fields = (
  items: Array<APIUsersByRoleType>,
  order: { sortBy: string; direction: string },
  handleOnHeaderSort: any
): TablePropType => {
  const { sortBy: sortOrder, direction } = order || {};
  const updatedItems = items.map((item: APIUsersByRoleType) => {
    return [
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        image: item.picture,
        id: item.securityId,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.fullName,
        id: `${item.securityId}-name`,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.email,
        id: `${item.securityId}-email`,
      },
    ];
  });

  return {
    header: [
      {
        id: 'picture',
        value: ' ',
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'name',
        value: 'Name',
        isSortable: true,
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('fullName')) && direction) || '',
        callback: handleOnHeaderSort,
        sortBy: 'fullName',
      },
      {
        id: 'email',
        value: 'Email',
        isSortable: true,
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
        direction: (Boolean(sortOrder && sortOrder.includes('email')) && direction) || '',
        callback: handleOnHeaderSort,
        sortBy: 'email',
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: updatedItems,
  };
};

export const userDetailsFields = (item: APIUsersByRoleType): UserDetailsFieldType[] => {
  return [
    {
      classname: {
        cell: styles['tr-cell'],
        value: styles['td-value'],
      },
      value: item.firstName || '',
      id: `${item.userId}-firstName`,
      label: 'First Name',
    },
    {
      classname: {
        cell: styles['tr-cell'],
        value: styles['td-value'],
      },
      value: item.lastName || '',
      id: `${item.userId}-lastName`,
      label: 'Last Name',
    },
    {
      classname: {
        cell: styles['tr-cell'],
        value: styles['td-value'],
      },
      value: item.email || '',
      id: `${item.userId}-email`,
      label: 'Email',
    },
  ];
};
