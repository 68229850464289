import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import FundingMatches from '.';
import FundingMatchesComponent from './components/fundingMatches';
import FundingMatchesOfferDetails from './components/fundingMatchesOfferDetails';
import ProductApplication from 'pages/fundingMatches/components/productApplication';
import { withTransaction } from '@elastic/apm-rum-react';

export const fundingMatchesRoutes: RouteObject[] = [
  {
    path: RoutePath.companyfundingmatches,
    element: withTransaction('FundingMatches', 'component')(<FundingMatches />),
    children: [
      {
        path: RoutePath.companyfundingmatchesdetails,
        element: withTransaction(
          'FundingMatchesOfferDetails',
          'component'
        )(<FundingMatchesOfferDetails />),
      },
      {
        path: RoutePath.companyfundingmatchesproductapplication,
        element: withTransaction('ProductApplication', 'component')(<ProductApplication />),
      },
      {
        path: RoutePath.companyfundingmatches,
        element: withTransaction(
          'FundingMatchesComponent',
          'component'
        )(<FundingMatchesComponent />),
      },
    ],
  },
];
