import { useQuery } from '@tanstack/react-query';
import { getSubTypeMetaData } from './index';
import { DealTypes } from 'pages/deals/types';
import { QueryKeys as MarketplaceQueryKeys } from '_shared/api/marketplaceApi';

type DealTypeWithSubtypes =
  | DealTypes.LOANS
  | DealTypes.EQUITY
  | DealTypes.TAX
  | DealTypes.INSURANCE
  | DealTypes.BANKACCOUNT
  | DealTypes.FX;

const useGetSubTypeMetaData = (dealType: DealTypes) => {
  const subTypeKeyMap: Record<DealTypeWithSubtypes, string> = {
    [DealTypes.LOANS]: 'dealSubTypeLoan',
    [DealTypes.EQUITY]: 'dealSubTypeEquity',
    [DealTypes.TAX]: 'dealSubTypeTax',
    [DealTypes.INSURANCE]: 'dealSubTypeInsurance',
    [DealTypes.BANKACCOUNT]: 'dealSubTypeBankAccount',
    [DealTypes.FX]: 'dealSubTypeFX',
  };

  const subTypeKey = subTypeKeyMap[dealType as DealTypeWithSubtypes];

  return useQuery({
    queryKey: [MarketplaceQueryKeys.GetSubTypeMetaData, subTypeKey],
    queryFn: () => getSubTypeMetaData(subTypeKey),
    staleTime: Infinity,
  });
};

export default useGetSubTypeMetaData;
