import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  text: string;
  children: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const anchorRef = React.useRef<HTMLSpanElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (anchorRef.current && tooltipRef.current) {
      const { bottom: anchorBottom, left: anchorLeft } = anchorRef.current.getBoundingClientRect();
      const { width: menuWidth, height: menuHeight } = tooltipRef.current.getBoundingClientRect();
      const { clientWidth, clientHeight } = document.documentElement;

      const top =
        anchorBottom + menuHeight > clientHeight ? clientHeight - menuHeight : anchorBottom;

      setPosition({
        top,
        left: Math.min(
          anchorLeft + anchorRef.current.offsetWidth / 2 - menuWidth / 2,
          clientWidth - menuWidth
        ),
      });
    }
  }, [anchorRef.current, show]);

  return (
    <>
      <span ref={anchorRef} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </span>
      {show &&
        createPortal(
          <div ref={tooltipRef} className={styles.tooltip} style={position}>
            {text}
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
