import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'components/v2/Select/Select';
import ProductApplication from 'pages/fundingMatches/components/productApplication';
import styles from './DocumentationSection.module.scss';

type DocumentationSectionProps = {
  companyId: string;
  applications: any[];
  isClientSoleTrader: boolean;
};

const DocumentationSection: React.FC<DocumentationSectionProps> = ({
  companyId,
  applications,
  isClientSoleTrader,
}) => {
  const { t } = useTranslation();
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>('');

  const applicationOptions = applications.map((application) => ({
    label: application.providerName,
    value: application.id,
  }));

  const selectedApplication = useMemo(
    () => applications.find((application) => application.id === selectedApplicationId) ?? null,
    [selectedApplicationId, applications]
  );

  const convertType = (type: string) => {
    switch (type) {
      case 'Loans':
        return 'Loan';
      case 'Equity':
        return 'Equity';
      default:
        return 'Grant';
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <label>
          <span>{t('home:deals:dealdetailsmodal:requireddocumentslabel')}</span>
          <Select
            value={selectedApplicationId}
            options={applicationOptions}
            onChange={(e) => setSelectedApplicationId(e.target.value)}
          />
        </label>
      </div>

      <div>
        {selectedApplication && (
          <ProductApplication
            providedCompanyId={companyId}
            providedProductId={selectedApplication.productId}
            providedOfferType={convertType(selectedApplication.Type)}
            isClientSoleTrader={isClientSoleTrader}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentationSection;
