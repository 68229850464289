import { FieldMetaDataPropType, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import {
  banksDic,
  countriesDic,
  districtsDic,
  sectorsDic,
  stateAndNationDic,
} from '_shared/utils/constants';
import { ProductInformationFieldType } from '../types';
import {
  parseDistrictsFromApi,
  parseHasPersonalGuaranteeFromApi,
  parseIndustryExcludedFromApi,
  parseProfibilityBasisFromApi,
  parseStateFromApi,
} from '../utils';
import styles from 'pages/productInformation/assets/productInformation.module.scss';
import { currencyIcon } from '_shared/utils/currency';

export const allProductCategories = [
  'Loan',
  'Grant',
  'Equity',
  'ResearchAndDevelopmentTaxCredit',
  'Savings',
];

export const allSubCategories = [
  'InvoiceFinance',
  'SupplierFinance',
  'OrderFinance',
  'AssetFinanceLeasing',
  'Overdrafts',
  'TermLoan',
  'WorkingCapitalLoan',
  'MerchantFinance',
  'CrowdfundingLoan',
  'MboFinance',
  'CommercialMortgage',
  'CreditCards',
  'StartupLoan',
  'RAndDTaxLoan',
  'Other',
  'SEIS',
  'EIS',
  'VCT',
  'VC',
  'Angel',
  'PrivateEquity',
  'FamilyOffice',
  'CrowdfundingEquity',
  'PitchReview',
  'SwoopServices',
  'Undefined',
];

export const allLoanSubCategories = [
  'InvoiceFinance',
  'SupplierFinance',
  'OrderFinance',
  'AssetFinanceLeasing',
  'Overdrafts',
  'TermLoan',
  'WorkingCapitalLoan',
  'MerchantFinance',
  'CrowdfundingLoan',
  'MboFinance',
  'CommercialMortgage',
  'CreditCards',
  'StartupLoan',
  'RAndDTaxLoan',
  'Other',
];
export const allEquitySubCategories = [
  'SEIS',
  'EIS',
  'VCT',
  'VC',
  'Angel',
  'PrivateEquity',
  'FamilyOffice',
  'CrowdfundingEquity',
  'PitchReview',
  'SwoopServices',
];

export const setFieldToReadOnly = (fieldType: any, cannotEdit: boolean) => {
  return { disabled: (cannotEdit && true) || false };
};

export const newProductFields = (
  product: ProductInformationFieldType,
  providerList: Array<string>,
  canEditProducts: boolean
): Array<
  FieldMetaDataPropType & {
    productType: string[];
    subType: string[] | string;
    productSection: string;
  }
> => {
  const districts = parseDistrictsFromApi(product?.specificRegionDefinition || []);
  const nations = parseStateFromApi(product?.specificRegionDefinition || []);
  const personalGuarantee = parseHasPersonalGuaranteeFromApi(product.hasPersonalGuarantee);
  const profitabilityBasis = parseProfibilityBasisFromApi(product.profitabilityBasis);
  const isSeisEligible = Boolean(product.investmentSchemes?.length);
  const isIndustryExcluded = parseIndustryExcludedFromApi(product.industriesExcluded);
  const isSpecificDistrict = Boolean(districts?.length);
  const leadingCurrencyIcon = currencyIcon();

  return [
    {
      id: 'productName',
      label: 'Product Name',
      value: product.productName,
      fieldType: FieldTypes.TEXT,
      name: 'productName',
      productType: allProductCategories,
      subType: allSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
    },
    {
      id: 'opportunityProviderId',
      label: 'Name of Provider',
      value: product.opportunityProviderId,
      fieldType: FieldTypes.SELECT,
      name: 'opportunityProviderId',
      productType: allProductCategories,
      subType: allSubCategories,
      placeholder: '',
      required: true,
      options: providerList?.map((field: any) => {
        return {
          id: field.value,
          label: field.label,
          value: field.value,
        };
      }),
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
      productSection: 'general',
    },

    {
      id: 'briefProductDescription',
      label: 'Brief Product Description',
      value: product.briefProductDescription ?? '',
      placeholder: '100 words max',
      fieldType: canEditProducts ? FieldTypes.EDITOR : FieldTypes.READONLY,
      name: 'briefProductDescription',
      productType: allProductCategories,
      subType: allSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
      classNames: {
        field: canEditProducts ? styles.editor : styles.readOnly,
      },
    },
    {
      id: 'detailedDescription',
      value: product.detailedDescription ?? '',
      label: 'Detailed Product Description',
      placeholder: '500 words max',
      fieldType: canEditProducts ? FieldTypes.EDITOR : FieldTypes.READONLY,
      name: 'detailedDescription',
      productType: allProductCategories,
      subType: allSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
      classNames: {
        field: canEditProducts ? styles.editor : styles.readOnly,
      },
    },
    {
      id: 'isProductWithoutSubmitFlow',
      label: 'Is the user allowed to Submit for Swoop review?',
      value: product.isProductWithoutSubmitFlow,
      name: 'isProductWithoutSubmitFlow',
      fieldType: FieldTypes.RADIO,
      selectionKey: product.isProductWithoutSubmitFlow + '',
      productType: allProductCategories,
      subType: allSubCategories,
      options: [
        {
          parentId: 'isProductWithoutSubmitFlow',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isProductWithoutSubmitFlow',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'productWithoutSubmitFlowLink',
      label: 'If No, link to the lending product to be used on product page',
      value: product.productWithoutSubmitFlowLink,
      name: 'productWithoutSubmitFlowLink',
      fieldType: FieldTypes.TEXT,
      productType: allProductCategories,
      subType: allSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
    },
    {
      id: 'isCovid19',
      label: 'Is this a Covid-19 Product?',
      value: product.isCovid19,
      name: 'isCovid19',
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      productType: ['Loan'],
      selectionKey: product.isCovid19 + '',
      subType: allLoanSubCategories,
      options: [
        {
          parentId: 'isCovid19',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isCovid19',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'isInstantEligibilityChecker',
      label: 'Is this product an instant eligibility checker?',
      value: product.isInstantEligibilityChecker,
      name: 'isInstantEligibilityChecker',
      fieldType: FieldTypes.RADIO,
      selectionKey: product.isInstantEligibilityChecker + '',
      productType: ['Loan'],
      subType: ['Overdrafts', 'TermLoan'],
      options: [
        {
          parentId: 'isInstantEligibilityChecker',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isInstantEligibilityChecker',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'speed',
      label: 'Speed of Procurement',
      value: product.speed,
      name: 'speed',
      fieldType: FieldTypes.SELECT,
      placeholder: '',
      required: true,
      productType: ['Equity', 'Grant'],
      subType: allEquitySubCategories + '',
      options: [
        {
          label: '< 1 week',
          value: 1,
        },
        {
          label: '1 week - 1 month',
          value: 2,
        },
        {
          label: '1 month - 3 months',
          value: 3,
        },
        {
          label: '3 months - 6 months',
          value: 4,
        },
        {
          label: '6 months+',
          value: 5,
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
    },
    {
      id: 'speed',
      label: 'Speed of Procurement',
      value: product.speed,
      name: 'speed',
      fieldType: FieldTypes.SELECT,
      placeholder: '',
      required: true,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      options: [
        {
          label: '< 1 week',
          value: 1,
        },
        {
          label: '1 week - 1 month',
          value: 2,
        },
        {
          label: '1 month - 3 months',
          value: 3,
        },
        {
          label: '3 months - 6 months',
          value: 4,
        },
        {
          label: '6 months+',
          value: 5,
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
    },
    {
      id: 'penaltyCharged',
      label: 'Do you charge a penalty for loans that are settled early?',
      value: product.penaltyCharged,
      name: 'penaltyCharged',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.penaltyCharged + '',
      options: [
        {
          parentId: 'penaltyCharged',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'penaltyCharged',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'penaltyCalculation',
      label: 'How is it calculated?',
      value: product.penaltyCalculation ?? '',
      name: 'penaltyCalculation',
      fieldType: FieldTypes.TEXTAREA,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
      dependsOn: {
        name: 'penaltyCharged',
        value: 'true',
      },
    },
    {
      id: 'interestRateType',
      label: 'What type is your interest rate?',
      value: product.interestRateType,
      name: 'interestRateType',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.interestRateType + '',
      options: [
        {
          parentId: 'interestRateType',
          name: 'true',
          label: 'Annually',
          value: 'true',
        },
        {
          parentId: 'interestRateType',
          name: 'false',
          label: 'Monthly',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'minInterestRate',
      label: 'Minimum Interest Rate Charged (%)',
      value: product.minInterestRate,
      type: 'number',
      validationtype: ValidationTypes.NUMERIC,
      fieldType: FieldTypes.TEXT,
      name: 'minInterestRate',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      min: 0,
      max: 100,
    },
    {
      id: 'maxInterestRate',
      label: 'Maximum Interest Rate Charged (%)',
      value: product.maxInterestRate,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'maxInterestRate',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
      max: 100,
    },
    {
      id: 'hasSetupFeeOrArrangementFee',
      label: 'Do you charge a setup fee/arrangement fee?',
      value: product.hasSetupFeeOrArrangementFee,
      name: 'hasSetupFeeOrArrangementFee',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.hasSetupFeeOrArrangementFee + '',
      options: [
        {
          parentId: 'hasSetupFeeOrArrangementFee',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasSetupFeeOrArrangementFee',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'successFee',
      label: 'Arrangement Success Fee',
      value: product.successFee,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'successFee',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
      dependsOn: {
        name: 'hasSetupFeeOrArrangementFee',
        value: 'true',
      },
    },
    {
      id: 'arrangementFeeMin',
      label: 'Arrangement Fee Minimum',
      value: product.arrangementFeeMin,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'arrangementFeeMin',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasSetupFeeOrArrangementFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'arrangementFeeStandard',
      label: 'Arrangement Fee Standard',
      value: product.arrangementFeeStandard,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'arrangementFeeStandard',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasSetupFeeOrArrangementFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'arrangementFeeMax',
      label: 'Arrangement Fee Maximum',
      value: product.arrangementFeeMax,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'arrangementFeeMax',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasSetupFeeOrArrangementFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'hasMonthlyAdministrationFee',
      label: 'Do you charge a monthly administration fee?',
      value: product.hasMonthlyAdministrationFee,
      name: 'hasMonthlyAdministrationFee',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.hasMonthlyAdministrationFee + '',
      options: [
        {
          parentId: 'hasMonthlyAdministrationFee',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasMonthlyAdministrationFee',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'monthlyFeeMin',
      label: 'Monthly Fee Minimum',
      value: product.monthlyFeeMin,
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      name: 'monthlyFeeMin',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasMonthlyAdministrationFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'monthlyFeeStandard',
      label: 'Monthly Fee Standard',
      value: product.monthlyFeeStandard,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'monthlyFeeStandard',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasMonthlyAdministrationFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'monthlyFeeMax',
      label: 'Monthly Fee Maximum',
      value: product.monthlyFeeMax,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'monthlyFeeMax',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: {
        name: 'hasMonthlyAdministrationFee',
        value: 'true',
      },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'debtorNoticeOfAssignment',
      label: 'Must the Debtor receive a Notice of Assignment?',
      value: product.debtorNoticeOfAssignment,
      name: 'debtorNoticeOfAssignment',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.debtorNoticeOfAssignment + '',
      options: [
        {
          parentId: 'debtorNoticeOfAssignment',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'debtorNoticeOfAssignment',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'balancesVerificationWithDebtorCompanies',
      label: 'Do you verify Balances with Debtor Companies?',
      value: product.balancesVerificationWithDebtorCompanies,
      name: 'balancesVerificationWithDebtorCompanies',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.balancesVerificationWithDebtorCompanies + '',
      options: [
        {
          parentId: 'balancesVerificationWithDebtorCompanies',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'balancesVerificationWithDebtorCompanies',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'shouldBeCandidateMeeting',
      label: 'Is it mandatory that you meet with the applicant prior to Approval?',
      value: product.shouldBeCandidateMeeting,
      name: 'shouldBeCandidateMeeting',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: ['AssetFinanceLeasing'],
      selectionKey: product.shouldBeCandidateMeeting + '',
      options: [
        {
          parentId: 'shouldBeCandidateMeeting',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'shouldBeCandidateMeeting',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'isSiteVisit',
      label: 'Is it mandatory that you conduct a site visit prior to approval?',
      value: product.isSiteVisit,
      name: 'isSiteVisit',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: ['AssetFinanceLeasing'],
      selectionKey: product.isSiteVisit + '',
      options: [
        {
          parentId: 'isSiteVisit',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSiteVisit',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'generalComments',
      label: 'General Comments',
      value: product.generalComments ?? '',
      name: 'generalComments',
      fieldType: FieldTypes.TEXTAREA,
      placeholder: '100 words max',
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      productSection: 'general',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
    },
    {
      id: 'hasPersonalGuarantee',
      label: 'Are Personal Guarantees mandatory?',
      value: personalGuarantee,
      name: 'hasPersonalGuarantee',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: personalGuarantee + '',
      options: [
        {
          parentId: 'hasPersonalGuarantee',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasPersonalGuarantee',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'companyStructures',
      label: 'What company structures is the product/facility available to?',
      name: 'companyStructures',
      value: product.companyStructures,
      fieldType: FieldTypes.MULTISELECT,
      productType: allProductCategories,
      subType: allSubCategories,
      required: true,
      validationtype: ValidationTypes.MULTISELECT,
      options: [
        {
          label: 'Private Limited Company',
          value: 'Private Limited Company',
        },
        {
          label: 'LLP',
          value: 'LLP',
        },
        {
          label: 'Unlimited Company (Sole Trader)',
          value: 'Unlimited Company (Sole Trader)',
        },
        {
          label: 'PLC',
          value: 'PLC',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
    },
    {
      id: 'socialEnterprise',
      label: 'Is this product only available to Social Enterprises?',
      value: product.socialEnterprise,
      name: 'socialEnterprise',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      selectionKey: product.socialEnterprise + '',
      options: [
        {
          parentId: 'socialEnterprise',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'socialEnterprise',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'countryOfRegistration',
      label: 'This product/facility is available to companies registered in which countries?',
      value: product.countryOfRegistration,
      name: 'countryOfRegistration',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      options: [
        ...countriesDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
    },
    {
      id: 'countryOfRegistration',
      label: 'In what countries must a business be registered, to qualify for Investment?',
      value: product.countryOfRegistration,
      name: 'countryOfRegistration',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Equity', 'Grant'],
      subType: allEquitySubCategories + '',
      options: [
        ...countriesDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
    },
    {
      id: 'isSpecificRegion',
      label: 'Is product only available in specific states/nations?',
      value: product.isSpecificRegion,
      name: 'isSpecificRegion',
      fieldType: FieldTypes.RADIO,
      selectionKey: product.isSpecificRegion + '',
      type: 'radio',
      productType: ['Loan', 'Equity', 'Grant'],
      subType: allSubCategories,
      options: [
        {
          parentId: 'isSpecificRegion',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSpecificRegion',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'specificRegionDefinition',
      label: 'If yes, which states/nations?',
      value: nations,
      name: 'specificRegionDefinition',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Loan', 'Equity', 'Grant'],
      subType: allSubCategories,
      options: [
        ...stateAndNationDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: {
        name: 'isSpecificRegion',
        value: 'true',
      },
    },
    {
      id: 'isSpecificDistrict',
      label: 'Is product only available in specific districts?',
      value: isSpecificDistrict,
      name: 'isSpecificDistrict',
      fieldType: FieldTypes.RADIO,
      selectionKey: isSpecificDistrict + '',
      type: 'radio',
      productType: ['Loan', 'Equity', 'Grant'],
      subType: allSubCategories,
      options: [
        {
          parentId: 'isSpecificDistrict',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSpecificDistrict',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
      dependsOn: { name: 'isSpecificRegion', value: 'true' },
    },
    {
      id: 'specificDistrictDefinition',
      label: 'If yes, which districts?',
      value: districts || [],
      name: 'specificDistrictDefinition',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Loan', 'Equity', 'Grant'],
      subType: allSubCategories,
      options: [
        ...districtsDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: { name: 'isSpecificDistrict', value: 'true' },
    },
    {
      id: 'isSpecificBank',
      label: 'Is this product bank specific?',
      value: product.isSpecificBank,
      name: 'isSpecificBank',
      fieldType: FieldTypes.RADIO,
      selectionKey: product.isSpecificBank + '',
      type: 'radio',
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      options: [
        {
          parentId: 'isSpecificBank',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSpecificBank',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'specificBankDefinition',
      label: 'If yes, which bank?',
      value: product.specificBankDefinition,
      name: 'specificBankDefinition',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      options: [
        ...banksDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: { name: 'isSpecificBank', value: 'true' },
    },
    //below fields should be here but currently not returned from BE
    // {
    //   id: 'isSpecificBankOther',
    //   label: 'Is this product Other bank specific?',  //not returning from BP BE
    //   value: product.isSpecificBankOther,
    //   name: 'isSpecificBankOther',
    //   fieldType: FieldTypes.RADIO,
    //   productType: allProductCategories,
    //   subType: allSubCategories,
    //   selectionKey: product.isSpecificBankOther + ',',
    //   options: [
    //     {
    //       parentId: 'isSpecificBankOther',
    //       name: 'true',
    //       label: 'Yes',
    //       value: 'true',
    //     },
    //     {
    //       parentId: 'isSpecificBankOther',
    //       name: 'false',
    //       label: 'No',
    //       value: 'false',
    //     },
    //   ],
    //   productSection: 'eligibility',
    // ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    // },
    // {
    //   id: 'specificBankDefinitionOther',
    //   label: 'Please enter the Other specific name of your current bank',
    //   value: product.specificBankDefinitionOther,
    //   name: 'specificBankDefinitionOther',
    //   required: true,
    //   fieldType: FieldTypes.TEXT,
    //   productType: allProductCategories,
    //   subType: allSubCategories,
    //   productSection: 'eligibility',
    // ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
    //   dependsOn: { name: 'isSpecificBankOther', value: 'true' },
    // },

    {
      id: 'minSize',
      label: 'Minimum Facility / Investment size',
      value: product.minSize,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'minSize',
      productType: ['Loan', 'Grant', 'Equity'],
      subType: allSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 1,
    },
    {
      id: 'maxSize',
      label: 'Maximum Facility / Investment size',
      value: product.maxSize,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'maxSize',
      productType: ['Loan', 'Grant', 'Equity'],
      subType: allSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 1,
    },
    {
      id: 'minTerm',
      label: 'Minimum Facility Term in Months',
      value: product.minTerm,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'minTerm',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
      max: 120,
    },
    {
      id: 'maxTerm',
      label: 'Maximum Facility Term in Months',
      value: product.maxTerm,
      type: 'number',
      fieldType: FieldTypes.TEXT,
      name: 'maxTerm',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
      max: 120,
    },
    {
      id: 'minTurnover',
      label: 'Minimum turnover of applicant business?',
      value: product.minTurnover,
      name: 'minTurnover',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      leadingIcon: leadingCurrencyIcon,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'isProfitable',
      label: 'Does the business need to be profitable?',
      value: product.isProfitable,
      name: 'isProfitable',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan', 'Grant'],
      subType: allLoanSubCategories + '',
      selectionKey: product.isProfitable + '',
      options: [
        {
          parentId: 'isProfitable',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isProfitable',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },

    {
      id: 'minProfitability',
      label:
        'What is the minimum profitability figure you will consider?(For pre-revenue or loss making companies put large negative value)',
      value: product.minProfitability,
      name: 'minProfitability',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Loan', 'Grant'],
      subType: allLoanSubCategories + '',
      leadingIcon: leadingCurrencyIcon,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      dependsOn: { name: 'isProfitable', value: 'true' },
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'profitabilityBasis',
      label: 'Based on which measure?',
      value: profitabilityBasis,
      name: 'profitabilityBasis',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan', 'Grant', 'ResearchAndDevelopmentTaxCredit', 'Savings'],
      subType: allLoanSubCategories + '',
      selectionKey: profitabilityBasis + '',
      options: [
        {
          parentId: 'profitabilityBasis',
          name: 'true',
          label: 'Net Profit',
          value: 'true',
        },
        {
          parentId: 'profitabilityBasis',
          name: 'false',
          label: 'EBITDA',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'isIndustryExcluded',
      label: 'Are there industries that the product is not available to?',
      value: isIndustryExcluded,
      name: 'isIndustryExcluded',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: isIndustryExcluded + '',
      options: [
        {
          parentId: 'isIndustryExcluded',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isIndustryExcluded',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'industriesExcluded',
      label: 'If yes, which industries?',
      value: product.industriesExcluded,
      name: 'industriesExcluded',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      options: [
        ...sectorsDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: { name: 'isIndustryExcluded', value: 'true' },
    },
    {
      id: 'isHomeOwner',
      label: 'Does this product require home ownership?',
      value: product.isHomeOwner,
      name: 'isHomeOwner',
      fieldType: FieldTypes.RADIO,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      selectionKey: product.isHomeOwner + '',
      options: [
        {
          parentId: 'isHomeOwner',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isHomeOwner',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'minMonthlyRecurringRevenue',
      label: 'Minimum monthly recurring revenue',
      value: product.minMonthlyRecurringRevenue,
      name: 'minMonthlyRecurringRevenue',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Equity', 'Loan'],
      subType: allSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'minTradingAge',
      label: 'Minimum trading age(months)?',
      value: product.minTradingAge,
      name: 'minTradingAge',
      fieldType: FieldTypes.TEXT,
      placeholder: '',
      required: true,
      type: 'number',
      productType: allProductCategories,
      subType: allSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
      max: 240,
    },
    {
      id: 'isSpecificBusinessSector',
      label: 'Is your fund specific to a particular sector?',
      value: product.isSpecificBusinessSector,
      name: 'isSpecificBusinessSector',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity', 'Grant'],
      subType: allEquitySubCategories + '',
      selectionKey: product.isSpecificBusinessSector + '',
      options: [
        {
          parentId: 'isSpecificBusinessSector',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSpecificBusinessSector',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'specificBusinessSectorDefinition',
      label: 'Please specify all sectors that are covered',
      value: product.specificBusinessSectorDefinition,
      name: 'specificBusinessSectorDefinition',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Equity', 'Grant'],
      subType: allEquitySubCategories + '',
      options: [
        ...sectorsDic.map(({ label }: { label: string }) => {
          return { name: label, value: label, label: label };
        }),
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: { name: 'isSpecificBusinessSector', value: 'true' },
    },
    {
      id: 'isSpecificBusinessModel',
      label: 'Is your fund specific to a particular business model?',
      value: product.isSpecificBusinessModel,
      name: 'isSpecificBusinessModel',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity', 'Grant'],
      subType: allEquitySubCategories + '',
      selectionKey: product.isSpecificBusinessModel + '',
      options: [
        {
          parentId: 'isSpecificBusinessModel',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'isSpecificBusinessModel',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'specificBusinessModelDefinition',
      label: 'Please specify all business models that are covered',
      value: product.specificBusinessModelDefinition,
      name: 'specificBusinessModelDefinition',
      fieldType: FieldTypes.MULTISELECT,
      productType: ['Grant', 'Equity'],
      subType: allEquitySubCategories + '',
      options: [
        {
          label: 'B2C',
          value: 'B2C',
        },
        {
          label: 'B2B',
          value: 'B2B',
        },
        {
          label: 'B2B2C',
          value: 'B2B2C',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.MULTISELECT, !canEditProducts),
      dependsOn: { name: 'isSpecificBusinessModel', value: 'true' },
    },
    {
      id: 'minMonthlyPurhcaseOrders',
      label:
        'What is the minimum size of average monthly invoices or ledger (purchase orders) you will finance?',
      value: product.minMonthlyPurhcaseOrders,
      name: 'minMonthlyPurhcaseOrders',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Loan'],
      subType: ['InvoiceFinance'],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'maxMonthlyPurhcaseOrders',
      label:
        'What is the maximum size of average monthly invoices or ledger (purchase orders) you will finance?',
      value: product.maxMonthlyPurhcaseOrders,
      name: 'maxMonthlyPurhcaseOrders',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Loan'],
      subType: ['InvoiceFinance'],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'closingDate',
      label: 'Application Closing Date',
      value: (canEditProducts && product.closingDate) || product.closingDate?.split('T', 1),
      name: 'closingDate',
      fieldType: FieldTypes.DATEPICKER,
      type: 'datepicker',
      productType: ['Grant'],
      subType: '',
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.DATEPICKER, !canEditProducts),
    },
    {
      id: 'otherBusinessMatchingCriteria',
      label:
        'Are there any other criteria a business must meet that you would like us to be aware of?',
      value: product.otherBusinessMatchingCriteria ?? '',
      name: 'otherBusinessMatchingCriteria',
      fieldType: FieldTypes.TEXTAREA,
      productType: ['Loan', 'Equity'],
      subType: allSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
    },
    {
      id: 'loanSecurity',
      label: 'What Loan Security is required?',
      value: product.loanSecurity ?? '',
      name: 'loanSecurity',
      fieldType: FieldTypes.TEXTAREA,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXTAREA, !canEditProducts),
    },
    {
      id: 'minimumCreditScore',
      label: 'Minumum Credit Score',
      value: product.minimumCreditScore,
      name: 'minimumCreditScore',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'creditBureauName',
      label: 'Credit Bureau',
      value: product.creditBureauName,
      name: 'creditBureauName',
      fieldType: FieldTypes.SELECT,
      placeholder: '',
      required: true,
      productType: ['Loan'],
      subType: allLoanSubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
      options: [
        {
          label: 'Credit Safe',
          value: 'Credit Safe',
        },
        {
          label: 'Equifax',
          value: 'Equifax',
        },
        {
          label: 'Experian',
          value: 'Experian',
        },
        {
          label: 'Transunion',
          value: 'Transunion',
        },
        {
          label: 'Crediva',
          value: 'Crediva',
        },
        {
          label: 'Irish Credit Bureau',
          value: 'Irish Credit Bureau',
        },
      ],
    },
    {
      id: 'minimumCustomers',
      label: 'What is the minimum amount of customers a business must have to qualify?',
      value: product.minimumCustomers,
      name: 'minimumCustomers',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Equity'],
      subType: allEquitySubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'cacOrLtvRatio',
      label: 'What is the maximum CAC/LTV ratio that business must have to qualify?',
      value: product.cacOrLtvRatio,
      name: 'cacOrLtvRatio',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Equity'],
      subType: allEquitySubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'seisEligible',
      label: 'Does the company have to be SEIS/EIS/VCT eligible?',
      value: isSeisEligible,
      name: 'seisEligible',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      selectionKey: isSeisEligible + '',
      options: [
        {
          parentId: 'seisEligible',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'seisEligible',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'investmentSchemes',
      label: 'If yes, which investment scheme?',
      value: product.investmentSchemes,
      name: 'investmentSchemes',
      fieldType: FieldTypes.SELECT,
      placeholder: '',
      required: true,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      options: [
        {
          label: 'EIS',
          value: 1,
        },
        {
          label: 'SEIS',
          value: 2,
        },
        {
          label: 'VCT',
          value: 3,
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
      validationtype: ValidationTypes.SELECT,
      dependsOn: { name: 'seisEligible', value: 'true' },
    },
    {
      id: 'hasMinAmountRaisedPreviously',
      label: 'Has the company previously raised any amount?',
      value: product.hasMinAmountRaisedPreviously,
      name: 'hasMinAmountRaisedPreviously',
      fieldType: FieldTypes.RADIO,
      productType: ['Equity'],
      subType: allEquitySubCategories,
      selectionKey: product.hasMinAmountRaisedPreviously + '',
      options: [
        {
          parentId: 'hasMinAmountRaisedPreviously',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'hasMinAmountRaisedPreviously',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'minAmountRaisedPreviously',
      label: 'Amount raised previously',
      value: product.minAmountRaisedPreviously,
      name: 'minAmountRaisedPreviously',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Equity'],
      subType: allEquitySubCategories,
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      dependsOn: { name: 'hasMinAmountRaisedPreviously', value: 'true' },
      min: 0,
    },
    {
      id: 'newHiring',
      label: 'Minimum new hires required to qualify for funding?',
      value: product.newHiring,
      name: 'newHiring',
      fieldType: FieldTypes.TEXT,
      type: 'number',
      productType: ['Grant'],
      subType: '',
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'rAndDRequirement',
      label: 'Required to spend on new technology and processes?',
      value: product.rAndDRequirement,
      name: 'rAndDRequirement',
      fieldType: FieldTypes.RADIO,
      productType: ['Grant'],
      subType: '',
      selectionKey: product.rAndDRequirement + '',
      options: [
        {
          parentId: 'rAndDRequirement',
          name: 'true',
          label: 'Yes',
          value: 'true',
        },
        {
          parentId: 'rAndDRequirement',
          name: 'false',
          label: 'No',
          value: 'false',
        },
      ],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.RADIO, !canEditProducts),
    },
    {
      id: 'rAndDRequirementAmount',
      label: 'Amount required to spend on new technology and processes?',
      value: product.rAndDRequirementAmount,
      name: 'rAndDRequirementAmount',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Grant'],
      subType: '',
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      dependsOn: { name: 'rAndDRequirement', value: 'true' },
      min: 0,
    },

    {
      id: 'terminalRevenueMin',
      label: 'What is the minimum average monthly revenue to qualify for merchant finance?',
      value: product.terminalRevenueMin,
      name: 'terminalRevenueMin',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Loan'],
      subType: ['MerchantFinance'],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
    {
      id: 'terminalRevenueMax',
      label: 'What is the maximum average monthly revenue to qualify for merchant finance?',
      value: product.terminalRevenueMax,
      name: 'terminalRevenueMax',
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      productType: ['Loan'],
      subType: ['MerchantFinance'],
      productSection: 'eligibility',
      ...setFieldToReadOnly(FieldTypes.TEXT, !canEditProducts),
      validationtype: ValidationTypes.NUMERIC,
      min: 0,
    },
  ];
};

export const productCategoryFields = (
  productCategory: string = 'Loan',
  canEditProducts: boolean
) => {
  return {
    id: 'productCategoryFields',
    name: 'productCategoryFields',
    label: 'Category',
    value: productCategory,
    fieldType: FieldTypes.SELECT,
    placeholder: 'Product Category',
    required: true,
    ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
    options: [
      {
        name: 'Loan',
        value: 'Loan',
        label: 'Loan',
      },
      {
        name: 'Equity',
        value: 'Equity',
        label: 'Equity',
      },
      {
        name: 'Grant',
        value: 'Grant',
        label: 'Grants',
      },
    ],
    classNames: {
      value: styles['product-type-select'],
    },
  };
};

export const loanSubCategoryFields = (
  loanSubCategory: string = 'Invoice Finance',
  canEditProducts: boolean
) => {
  return {
    id: 'loanSubCategoryFields',
    name: 'loanSubCategoryFields',
    label: 'Sub-category',
    value: loanSubCategory,
    fieldType: FieldTypes.SELECT,
    placeholder: 'Product Subcategory',
    required: true,
    ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
    options: [
      {
        value: 'InvoiceFinance',
        label: 'Invoice Finance',
      },
      {
        value: 'SupplierFinance',
        label: 'Supplier Finance',
      },
      {
        value: 'OrderFinance',
        label: 'Order Finance',
      },
      {
        value: 'AssetFinanceLeasing',
        label: 'Asset Finance Leasing',
      },
      {
        value: 'Overdrafts',
        label: 'Overdrafts',
      },
      {
        value: 'TermLoan',
        label: 'Term Loan',
      },
      {
        value: 'WorkingCapitalLoan',
        label: 'Working Capital Loan',
      },
      {
        value: 'MerchantFinance',
        label: 'Merchant Finance',
      },
      {
        value: 'CrowdfundingLoan',
        label: 'Crowdfunding Loan',
      },
      {
        value: 'MboFinance',
        label: 'MBO Finance',
      },
      {
        value: 'CommercialMortgage',
        label: 'Commercial Mortgage',
      },
      {
        value: 'CreditCards',
        label: 'Credit Cards',
      },
      {
        value: 'StartupLoan',
        label: 'Startup Loan',
      },
      {
        value: 'RAndDTaxLoan',
        label: 'R&D Tax Loan',
      },
    ],
    // classNames: {
    //   control: styles[`sub-product-type-loan`],
    // },
  };
};

export const equitySubCategoryFields = (
  equitySubCategory: string = 'SEIS',
  canEditProducts: boolean
) => {
  return {
    id: 'equitySubCategoryFields',
    name: 'equitySubCategoryFields',
    label: 'Sub-category',
    value: equitySubCategory,
    fieldType: FieldTypes.SELECT,
    placeholder: 'Product Subcategory',
    required: true,
    ...setFieldToReadOnly(FieldTypes.SELECT, !canEditProducts),
    options: [
      {
        value: 'SEIS',
        label: 'SEIS',
      },
      {
        value: 'EIS',
        label: 'EIS',
      },
      {
        value: 'VCT',
        label: 'VCT',
      },
      {
        value: 'VC',
        label: 'VC',
      },
      {
        value: 'Angel',
        label: 'Angel',
      },
      {
        value: 'PrivateEquity',
        label: 'Private Equity',
      },
      {
        value: 'FamilyOffice',
        label: 'Family Office',
      },
      {
        value: 'CrowdfundingEquity',
        label: 'Crowdfunding Equity',
      },
      {
        value: 'PitchReview',
        label: 'Pitch Review',
      },
      {
        value: 'SwoopServices',
        label: 'Swoop Services',
      },
    ],
    // classNames: {
    //   control: styles[`sub-product-type-equity`],
    // },
  };
};
