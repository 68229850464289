import { env } from '_shared/utils';

// Used for header identification of application type
export enum XApplicationTypes {
  BrokerApplication = 'BrokerApplication',
  AdvisorApplication = 'AdvisorApplication',
}

export enum XApplicationSubdomainTypes {
  Brokers = 'brokers',
  Advisors = 'advisors',
}

export enum XCountryTypes {
  UNITEDKINGDOM = 'unitedkingdom',
  AUSTRALIA = 'australia',
  CANADA = 'canada',
}

export const filterByApplicationType = <T extends { displayOnApp?: XApplicationTypes }>(
  items: T[]
): T[] => {
  return items.filter((item) => {
    if (item?.displayOnApp) {
      return item.displayOnApp === applicationType;
    } else {
      return true;
    }
  });
};

export const applicationName = () => {
  const subdomain = window.location.hostname.split('.')[0];

  if (
    subdomain === XApplicationSubdomainTypes.Advisors ||
    subdomain === XApplicationSubdomainTypes.Brokers
  ) {
    return subdomain;
  }
  return env('REACT_APP_APPLICATION_TYPE');
};

export const isBrokerApplication = applicationName() === XApplicationSubdomainTypes.Brokers;

export const isAdvisorApplication = applicationName() === XApplicationSubdomainTypes.Advisors;

export const SWOOP_GROUP_ID = '77afb219-9221-4132-8667-36fe60ec23a3';
export const SDR_GROUP_ID = 'd458ee37-5f83-4981-818f-3c4caecf1f04';

export const applicationType = appSpecific(
  XApplicationTypes.BrokerApplication,
  XApplicationTypes.AdvisorApplication
);

export function appSpecific<TBrokerValue, TAdvisorValue>(
  brokerValue: TBrokerValue,
  advisorValue: TAdvisorValue
): TBrokerValue | TAdvisorValue {
  if (isAdvisorApplication) {
    return advisorValue;
  } else {
    // default is broker application
    return brokerValue;
  }
}
