import React from 'react';
import styles from './TableHead.module.scss';

type TableHeadProps = {
  children: React.ReactNode;
};

const TableHead: React.FC<TableHeadProps> = ({ children }) => {
  return <thead className={styles.thead}>{children}</thead>;
};

export default TableHead;
