import React from 'react';

export type ButtonPropType = {
  ariaLabel?: string;
  clickHandler?: any;
  className?: string;
  buttonStyleType?: ButtonStyleTypeEnum;
  tabIndex?: number;
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  dataTestId?: string;
  icon?: React.ReactNode | string;
};

export enum ButtonStyleTypeEnum {
  PRIMARY,
  SECONDARY,
  WARNING,
  DISABLED,
}
