import classNames from 'classnames';
import styles from 'layouts/components/AddUserModal/AddClient.module.scss';
import Field from 'components/field';
import UserSelect from 'components/userSelect';
import { FieldMetaDataPropType, LayoutTypes } from '_shared/fieldValidation/types';
import { useEffect, useMemo, useState } from 'react';
import { APIUsersByRoleType } from 'pages/users/type';
import { Role, UserRoles } from 'config/roles';
import { useAppState } from 'store';
import { appSpecific } from '_shared/utils/application';
import { getUsersByRole } from '_shared/api/roles';
import { useTranslation } from 'react-i18next';

type MetadataType = {
  metadata: Array<FieldMetaDataPropType>;
  errors?: { [key: string]: any };
  handleChange?: any;
  handleManualChange?: any;
};

const AddClient = (props: MetadataType) => {
  const { state: globalState } = useAppState();
  const [users, setUsers] = useState<APIUsersByRoleType[]>([]);
  const [currentSelectedUser, setCurrentSelectedUser] = useState<APIUsersByRoleType>();
  const { t } = useTranslation();

  const fetchUsers = async () => {
    try {
      let role;
      let userType = appSpecific(UserRoles.BROKER, UserRoles.ADVISOR);
      if (userType === UserRoles.BROKER) {
        role = globalState.system?.roles?.find((role: Role) => role.name === UserRoles.BROKER);
      } else if (userType === UserRoles.ADVISOR) {
        role = globalState.system?.roles?.find((role: Role) => role.name === UserRoles.ADVISOR);
      }

      if (!role?.id) return;

      const { data } = await getUsersByRole(role.id);

      setUsers(data?.results || []);
      const currentUser = data?.results.find(
        (user: APIUsersByRoleType) => user.userId === globalState?.system?.currentUser?.userId
      );
      setCurrentSelectedUser(currentUser);
      props.handleManualChange('assignedUserSecurityId', currentUser?.securityId);
    } catch (e) {
      console.error(e);
    }
  };

  const assignUser = async (selectedUser: APIUsersByRoleType) => {
    const relevantUserDetails =
      users.find((user: any) => {
        return user.userId === selectedUser.userId;
      }) || null;

    if (!relevantUserDetails?.userId) return;

    setCurrentSelectedUser(relevantUserDetails);
    props.handleManualChange('assignedUserSecurityId', relevantUserDetails.securityId);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Updated the metadata to group grouped and inline fields into arrays
  const groupedMetadata = useMemo(
    () =>
      props.metadata.reduce<(FieldMetaDataPropType | FieldMetaDataPropType[])[]>(
        (result, field) => {
          const lastEntry = result[result.length - 1];

          if (!field.layout || !Object.values(LayoutTypes).includes(field.layout)) {
            result.push(field);
            return result;
          }

          if (field.layout === LayoutTypes.INLINE) {
            field.classNames = {
              ...field.classNames,
              field: styles['combined-field'],
              label: styles['combined-field-label'],
              value: styles['combined-field-value'],
            };
          }

          if (Array.isArray(lastEntry) && lastEntry[lastEntry.length - 1].layout === field.layout) {
            lastEntry.push(field);
          } else {
            result.push([field]);
          }
          return result;
        },
        []
      ),
    [props.metadata]
  );

  if (!props.metadata.length) return <></>;

  const renderField = (field: FieldMetaDataPropType) => {
    return (
      <Field
        key={field.name}
        {...{
          metadata: {
            ...field,
            classNames: {
              field: styles['field'],
              value: styles['field-value'],
              control: styles['field-value'],
              ...field.classNames,
            },
          },
          errors: props.errors || [],
          handleChange: props.handleChange,
        }}
      />
    );
  };

  return (
    <div className={classNames(styles['container'])} data-testid="add-client-test">
      {groupedMetadata.map((entry, index) =>
        Array.isArray(entry) ? (
          <div
            key={`grouped-content-${index}`}
            className={
              entry[0].layout === LayoutTypes.GROUPED
                ? styles['cant-find-company-container']
                : styles['combined-field-container']
            }
          >
            {entry.map((field) => renderField(field))}
          </div>
        ) : (
          renderField(entry)
        )
      )}

      {(users.length && (
        <UserSelect
          label={t(
            appSpecific(
              'home:companydetails:overview:accountinfo:fundingmanager',
              'home:companydetails:overview:accountinfo:accountowner'
            )
          )}
          className={styles['no-border']}
          selectedUser={currentSelectedUser}
          users={users}
          onSelect={(user: APIUsersByRoleType) => assignUser(user)}
        />
      )) ||
        null}
    </div>
  );
};

export default AddClient;
