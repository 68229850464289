import cn from 'classnames';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { userReducer, ActionType, initialState } from './reducer';
import { useParams } from 'react-router-dom';
import { getUser } from '_shared/api/users';
import { userDetailFields } from './userDetails.field';
import styles from './assets/users.module.scss';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import Field from 'components/field';
import { getAvailableRolesList } from '_shared/api/roles';
import { useAppState } from 'store';
import { isBrokerApplication } from '_shared/utils/application';
import RoleManagement from 'pages/userManagement/components/roleManagement';
import { customRoleFields } from 'pages/userManagement/components/roleManagement/roleManagement.field';
import PasswordManagement from 'pages/userManagement/components/passwordManagement';
import CacheManagement from 'pages/userManagement/components/cacheManagement';
import DangerZoneManagement from 'pages/userManagement/components/dangerZoneManagement';
import Accordion from 'components/v2/Accordion/Accordion';

const UserDetails: React.FC = () => {
  const store = useAppState();
  const [state, dispatch] = useReducer(userReducer, initialState);
  const [refreshRolesTrigger, setRefreshRolesTrigger] = useState(false);
  const { id: userId = '' } = useParams() as { id?: string };
  const applicationType = isBrokerApplication ? 'BrokerApplication' : 'AdvisorApplication';
  const isUserAdmin = Boolean(store.state.system.isAdminOrSuperAdmin?.());

  const getRelevantUserDetails = async () => {
    try {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: true,
        },
      });

      if (!userId) {
        return;
      }

      const { data } = await getUser(userId);
      const { data: availableRoles } = await getAvailableRolesList(applicationType);

      dispatch({
        type: ActionType.SET_USER_DETAIL_INFO,
        payload: data,
      });

      dispatch({
        type: ActionType.SET_CURRENT_USER_ROLES,
        payload: data.roles,
      });

      dispatch({
        type: ActionType.SET_AVAILABLE_USER_ROLES,
        payload: availableRoles,
      });

      dispatch({
        type: ActionType.SET_USER_DETAIL_FIELDS,
        payload: {
          userDetailFields: userDetailFields({ ...data }),
        },
      });

      dispatch({
        type: ActionType.SET_CUSTOM_USER_ROLES,
        payload: {
          customUserRoles: customRoleFields(
            { userId, roles: data.roles },
            availableRoles,
            isUserAdmin
          ),
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
    } finally {
      dispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };

  const updateCurrentUserRoles = useCallback(() => {
    setRefreshRolesTrigger((prev) => !prev);
  }, []);

  useEffect(() => {
    getRelevantUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, refreshRolesTrigger]);

  return (
    <div className={cn('wrapper', styles['users-container'])}>
      <div className={styles['header']}>
        <h2 className={cn('sw-h2')}>Colleague Details</h2>
      </div>

      <div className={styles['user-fields']} data-testid="user-details">
        {state.userDetailFields.map((field: FieldMetaDataPropType) => {
          return (
            <Field
              {...{
                key: field.id,
                metadata: field,
                errors: [],
                handleChange: () => undefined,
              }}
            />
          );
        })}
      </div>

      {store?.state?.system?.isUserManager?.() && (
        <>
          <div className={styles['colleague-management-intro']}>
            <h2 className={cn('sw-h2')}>Colleague Management</h2>
            <p className={cn(styles['sub-text'])}>
              A dedicated space to simplify the management of colleague account and roles.
            </p>
          </div>
          <Accordion className={styles['section']} title="Role Management" defaultExpanded>
            <RoleManagement
              userDetailsState={state}
              userDetailsDispatch={dispatch}
              securityId={userId}
              refreshCurrentUserRoles={updateCurrentUserRoles}
            />
          </Accordion>
          <Accordion className={styles['section']} title="Password Management">
            <PasswordManagement
              userDetailsState={state}
              userDetailsDispatch={dispatch}
              securityId={userId}
            />
          </Accordion>
          <Accordion className={styles['section']} title="Cache Management">
            <CacheManagement
              userDetailsState={state}
              userDetailsDispatch={dispatch}
              securityId={userId}
            />
          </Accordion>
          <Accordion className={styles['section']} title="Danger Zone">
            <DangerZoneManagement
              userDetailsState={state}
              userDetailsDispatch={dispatch}
              securityId={userId}
            />
          </Accordion>
        </>
      )}

      {/* Broker and Advisor Admins should be able to just see the Role Management */}
      {applicationType &&
        store?.state?.system?.isAdminOrSuperAdmin?.() &&
        !store?.state?.system?.isUserManager?.() && (
          <Accordion className={styles['section']} title="Role Management" defaultExpanded>
            <RoleManagement
              userDetailsState={state}
              userDetailsDispatch={dispatch}
              securityId={userId}
              refreshCurrentUserRoles={updateCurrentUserRoles}
            />
          </Accordion>
        )}
    </div>
  );
};

export default UserDetails;
