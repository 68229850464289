import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import ProductInformation from '.';

export const productInformationRoutes: RouteObject[] = [
  {
    path: RoutePath.productinformation,
    element: withTransaction('ProductInformation', 'component')(<ProductInformation />),
  },
];
