import { LinkedApplicationType, NewApplicationType } from '../types';
import { createContext } from 'react';

export type applicationsStateType = {
  linkedApplications: {
    data: Array<LinkedApplicationType>;
    loading: boolean;
    error: boolean;
    selectedMenu: string;
  };
  newApplications: {
    data: Array<NewApplicationType>;
    loading: boolean;
    error: boolean;
    showOnlyMatches: boolean;
    showOnlyMatchesToggleCount: number;
  };
  refresh: number;
};

export const applicationFormInitialState = {
  linkedApplications: {
    data: [],
    loading: true,
    error: false,
    selectedMenu: '',
  },
  newApplications: {
    data: [],
    loading: true,
    error: false,
    showOnlyMatches: true,
    showOnlyMatchesToggleCount: 0,
  },
  refresh: 0,
};

export enum ApplicationFormActionType {
  SET_NEW_APPLICATIONS,
  SET_LINKED_APPLICATIONS,
  TOGGLE_SHOW_ONLY_MATCHES,
  SET_REFRESH,
  SET_EDIT_APPLICATIN_SELECTED_MENU,
}
export type Action = {
  type: ApplicationFormActionType;
  payload: any;
};

export function applicationFormReducer(state: applicationsStateType, action: Action) {
  switch (action.type) {
    case ApplicationFormActionType.SET_NEW_APPLICATIONS:
      return {
        ...state,
        newApplications: { ...state.newApplications, ...action.payload },
      };

    case ApplicationFormActionType.SET_LINKED_APPLICATIONS:
      return {
        ...state,
        linkedApplications: { ...state.linkedApplications, ...action.payload },
      };
    case ApplicationFormActionType.TOGGLE_SHOW_ONLY_MATCHES:
      return {
        ...state,
        newApplications: {
          ...state.linkedApplications,
          showOnlyMatches: !state.newApplications.showOnlyMatches,
          showOnlyMatchesToggleCount: state.newApplications.showOnlyMatchesToggleCount + 1,
        },
      };

    case ApplicationFormActionType.SET_REFRESH:
      return {
        ...state,
        refresh: state.refresh + 1,
      };

    case ApplicationFormActionType.SET_EDIT_APPLICATIN_SELECTED_MENU:
      return {
        ...state,
        linkedApplications: { ...state.linkedApplications, selectedMenu: action.payload },
      };

    default:
      return state;
  }
}

export const ApplicationFormContext = createContext<{
  state: applicationsStateType;
  dispatch: React.Dispatch<Action>;
}>({ state: applicationFormInitialState, dispatch: () => {} });
