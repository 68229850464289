import { useQuery } from '@tanstack/react-query';
import { getUsersByRole } from './index';

const useGetUsersByRole = (roleId: string) => {
  return useQuery({
    queryKey: ['getUsersByRole', roleId],
    queryFn: () => getUsersByRole(roleId),
    enabled: !!roleId,
    staleTime: Infinity,
  });
};

export default useGetUsersByRole;
