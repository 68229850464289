import { formattedCountry } from '_shared/utils/constants';
import { UserActivationStatus } from 'pages/userDetails/type';

const authUri = {
  login: '/api/v1/auth/token',
  logout: '/api/v1/auth/logout',
  resetPassword: '/api/v1/auth/reset-password',
};

const userUri = {
  getCurrentUser: '/api/v1/user/current',
  postRegister: '/api/v1/user/register',
  getUsers: '/api/v1/user/list',
  putUserMigrate: '/api/v1/user/migrate',
  getExistingUser(email: string) {
    return `/api/v1/user/exists?userEmail=${encodeURIComponent(email)}`;
  },
  getUser(userId: string) {
    return `/api/v1/user/${userId}`;
  },
  postTokenIsv: '/api/v1/user/registerIsv',
  patchUserModal: (modalName: string) => `/api/v1/user/UserModal/${modalName}/Disable`,
};

const iamUserUri = {
  getUsersByEmailAddress(emailAddress: string) {
    return `/api/v1/iam/users?email=${encodeURIComponent(emailAddress)}`;
  },
  postForgotPassword(securityId: string) {
    return `/api/v1/iam/users/${securityId}/forgot-password`;
  },
  postFlushCache(securityId: string) {
    return `/api/v1/iam/users/${securityId}/flush-cache`;
  },
  patchActivationStatus(securityId: string, userStatus: UserActivationStatus) {
    return `/api/v1/iam/users/${securityId}/status/${userStatus}`;
  },
  patchUserRoles(securityId: string) {
    return `/api/v1/iam/users/${securityId}/roles`;
  },
};

const rolesUri = {
  getRoles: '/api/v1/roles/list?IncludeTotal=true&PageNumber=0',
  getUsersByRole: (roleId: string) =>
    `/api/v1/roles/${roleId}/users?IncludeTotal=true&PageNumber=0&PageSize=1000`,
  putUserRoles: (userSecurityId: string) => `/api/v1/roles/assign/${userSecurityId}`,
  getUserRoles: (userSecurityId: string) => `/api/v1/roles/list/${userSecurityId}/user`,
  getAvailableRolesList: (applicationType: string) => `/api/v1/roles/${applicationType}/list`,
};

const notesUri = {
  getNotes: '/api/v1/notes',
  getCompanyNotes: (companyId: string) => `/api/v1/notes/${companyId}/list`,
  getNote: (noteId: string) => `/api/v1/notes/${noteId}`,
  postCreateNote: (companyId: string) => `/api/v1/notes/${companyId}/createNote`,
  putUpdateNote: (companyId: string, noteId: string) =>
    `/api/v1/notes/${companyId}/updateNote/${noteId}`,
  deleteNote: (companyId: string, noteId: string) =>
    `/api/v1/notes/${companyId}/deleteNote/${noteId}`,
};

const activityUri = {
  getActivities: `/api/v1/business/activity`,
  postActivity: (companyId: string) => `/api/v1/business/${companyId}/activity`,
  putActivity: (companyId: string, activityId: string) =>
    `/api/v1/business/${companyId}/activity/${activityId}`,
  deleteActivity: (companyId: string, activityId: string) =>
    `/api/v1/business/${companyId}/activity/${activityId}`,
};

const opportunityUri = {
  getDeals: '/api/v1/business/opportunities',
  postDeal: (companyId: string) => `/api/v1/business/${companyId}/opportunity`,
  putDeal: (companyId: string, opportunityId: string) =>
    `/api/v1/business/${companyId}/opportunity/${opportunityId}`,
  deleteDeal: (companyId: string, opportunityId: string) =>
    `/api/v1/business/${companyId}/opportunity/${opportunityId}`,
  getDeal: (companyId: string, opportunityId: string) =>
    `/api/v1/business/${companyId}/opportunity/${opportunityId}`,
  getDealsStatistics: '/api/v1/business/opportunities/statistics',
  postDealApplication: (companyId: string, productId: string) =>
    `/api/v1/business/${companyId}/application/${productId}`,
};

const businessUri = {
  getCompanies: '/api/v1/business',
  getApplications: (companyId: string) =>
    `/api/v1/product/${companyId}/applications?companyId=${companyId}`,
  getFieldDefinitions: '/api/v1/business/fieldDefinitions',
  postCreateCompany: '/api/v1/business/create-company',
  getClientSearchCompanies: (companyName: string) =>
    `/api/v1/business/client-search-companies/${companyName}`,
  getCompanyInfo: (id: string) => `/api/v1/business/${id}/companyInfo`,
  getCompanyChargeDetails: (id: string) => `/api/v1/business/${id}/chargeDetails`,
  getBusinessFinancialSnapshot: (companyId: string) =>
    `/api/v1/business/${companyId}/financialSnapshot`,
  getBusinessPeopleInfo: (companyId: string) => `/api/v1/business/${companyId}/peopleInfo`,
  getBusinessAccountInfo: (companyId: string) => `/api/v1/business/${companyId}/accountInfo`,
  putAccountInfo: (companyId: string) => `/api/v1/business/${companyId}/account`,
  postContact: (companyId: string) => `/api/v1/business/${companyId}/contacts`,
  postContactAddress: (companyId: string, contactId: string) =>
    `/api/v1/business/${companyId}/contacts/${contactId}/address`,
  deleteContact: (companyId: string, contactId: string) =>
    `/api/v1/business/${companyId}/companyContacts/${contactId}`,
  getCreditReports: (companyId: string) => `/api/v1/business/${companyId}/credit-reports`,
  putAssignUser: (companyId: string) => `/api/v1/business/${companyId}/assignUser`,
  unassignUser: (companyId: string) => `/api/v1/business/${companyId}/unassignUser`,
  deleteCompany: (companyId: string) => `/api/v1/business/${companyId}`,
  getAssignmentHistory: (companyId: string, historyType: string) =>
    `/api/v1/business/${companyId}/assignments-history?historyType=${historyType}`,
  getCompanyStatusHistory: (companyId: string, opportunityId: string) =>
    `/api/v1/business/${companyId}/opportunity/${opportunityId}/statusHistory`,
};

const contactsUri = {
  getCompanyContacts: (companyId: string) => `/api/v1/contact/${companyId}/companyContacts`,
  postCompanyContact: (companyId: string) => `/api/v1/contact/${companyId}/companyContacts`,
  putCompanyContact: (companyId: string, companyContactId: string) =>
    `/api/v1/contact/${companyId}/companyContacts/${companyContactId}`,
  deleteCompanyContact: (companyId: string, companyContactId: string) =>
    `/api/v1/contact/${companyId}/companyContacts/${companyContactId}`,
};

const documentsUri = {
  getCompanyDocuments: (companyId: string) => `/api/v1/documents/${companyId}/list`,
  getDownloadCompanyDocument: (companyId: string, documentId: string) =>
    `/api/v1/documents/${companyId}/download/${documentId}`,
  getAccountingDocuments: (companyId: string, documentId: string) =>
    `/api/v1/documents/broker/${companyId}/download/${documentId}`,
  putCompanyDocumentType: (companyId: string, documentId: string) =>
    `/api/v1/documents/${companyId}/changeType/${documentId}`,
  putCompanyDocumentTitle: (companyId: string, documentId: string) =>
    `/api/v1/documents/${companyId}/changeTitle/${documentId}`,
  postCompanyDocument: (companyId: string) => `/api/v1/documents/${companyId}/upload`,
  deleteCompanyDocument: (companyId: string, documentId: string) =>
    `/api/v1/documents/${companyId}/delete/${documentId}`,
  getDownloadApplication: (companyId: string, applicationId: string, productId: string) =>
    `/api/v1/documents/${companyId}/pdf/${productId}/applicationForm/${applicationId}`,
  getGenerateTrueLayerDocuments: (companyId: string) =>
    `/api/v1/documents/${companyId}/pdf/bankStatements`,
  getAgedCreditorsDocument: (companyId: string) =>
    `/api/v1/documents/${companyId}/pdf/agedCreditors`,
  getAgedDebtorsDocument: (companyId: string) => `/api/v1/documents/${companyId}/pdf/agedDebtors`,
  getRatiosAndKPIDocument: (companyId: string) =>
    `/api/v1/documents/${companyId}/pdf/ratiosAndKpis`,
  getManagementAccountsDocument: (companyId: string) =>
    `/api/v1/documents/${companyId}/pdf/management-accounts`,
};

const financialInfoUri = {
  getFinancialInfoProfit: (companyId: string) => `/api/v1/financialInfo/${companyId}/profit`,
  getTrueLayerStatus: (companyId: string) => `/api/v1/financialInfo/${companyId}/truelayer/status`,
  getFinancialInfoIncome: (companyId: string) => `/api/v1/financialInfo/${companyId}/income`,
  getFinancialInfoGrossProfit: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/grossProfitMargin`,
  getFinancialInfoInterestCoverage: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/ratio/interestCoverage`,
  getFinancialInfoEquityRatio: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/ratio/equity`,
  getFinancialInfoCurrentRatio: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/ratio/current`,
  getFinancialInfoProfitLossStatement: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/sheets/profitAndLoss`,
  getFinancialInfoBalanceSheet: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/sheets/balanceSheet`,
  getFinancialInfoOnboardingQuestions: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/onboardingQuestions`,
  postAccountingIntegration: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/accounting/onboard`,
  getAccountingStatus: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/accounting/status`,
  getBankAccountStatus: (companyId: string) => `/api/v1/bankMandate/${companyId}/list`,
  getActualCashflowForecast: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/cashflow/actualforecast`,
  getCashflowShortterm: (companyId: string) =>
    `/api/v1/financialInfo/${companyId}/cashflow/shortterm`,
};

const fundingMatchesUri = {
  getFundingRequirements: (companyId: string) =>
    `/api/v1/fundingMatches/${companyId}/fundingRequirements`,
  getFundingMatches: (companyId: string) => `/api/v1/fundingMatches/${companyId}/fundingMatches`,
  fundingMatchesEquity: (companyId: string) => `/api/v1/fundingMatches/${companyId}/equity`,
  fundingMatchesDebt: (companyId: string) => `/api/v1/fundingMatches/${companyId}/debt`,
  fundingMatchesGrant: (companyId: string) => `/api/v1/fundingMatches/${companyId}/grant`,
  getFundingForms: `/api/v1/forms`,
  getCompletedFundingForms: (companyId: string) => `api/v1/forms/goals-completed/${companyId}`,
  fundingForm: (formId: string, companyId: string) =>
    `/api/v1/forms/${formId}/field-values/${companyId}`,
  fundingFormV2: (formId: string, companyId: string) =>
    `/api/v2/forms/${formId}/field-values/${companyId}`,
  dynamicFundingForm: (companyId: string) => `/api/v1/forms/${companyId}`,
  dynamicFundingFormFields: (companyId: string) => `/api/v1/forms/field-values/${companyId}`,
  deleteDynamicFundingForm: (formId: string, companyId: string) =>
    `/api/v1/forms/${formId}/${companyId}`,

  getFundingFormOptions: () => `/api/v1/business/metadata?countryName=${formattedCountry}`,
  getFundingMatchesByType: (companyId: string, type: string) =>
    `/api/v1/fundingMatches/${companyId}/matches/${type}`,
  getMatchesByType: (companyId: string, type: string) =>
    `/api/v2/fundingMatches/${companyId}/matches/${type}`,
  getInsightsByType: (companyId: string, type: string) =>
    `/api/v2/fundingMatches/${companyId}/insights/${type}`,
  updateInsight: (url: string) => `${url}`,
};

const applicationFormUri = {
  postInvitationToFillApplicationForm: (productId: string) =>
    `/api/v1/applicationForm/${productId}/sendInvitationToFillApplicationForm`,
  getLinkedCompanyApplications: (companyId: string, isClosed: boolean) =>
    `/api/v1/applicationForm/${companyId}/companyApplications?fetchClosed=${isClosed}`,
  getCompanyApplications: (companyId: string) => `/api/v1/applicationForm/${companyId}/list`,
  getApplication: (companyId: string, applicationId: string) =>
    `/api/v1/applicationForm/${companyId}/companyApplications/${applicationId}`,
  getApplicationProduct: (productId: string) => `/api/v1/product/${productId}`,
  putUpdateApplication: (companyId: string, opportunityId: string) =>
    `/api/v1/applicationForm/${companyId}/opportunity/${opportunityId}`,
  postCreateApplication: (companyId: string, productId: string) =>
    `/api/v1/applicationForm/${companyId}/createApplication/${productId}`,
  putApplicationContacts: (companyId: string, contactId: string) =>
    `/api/v1/applicationForm/${companyId}/contacts/${contactId}`,
  putApplicationContactAddress: (companyId: string, contactId: string, contactAddressId: string) =>
    `/api/v1/applicationForm/${companyId}/contacts/${contactId}/addresses/${contactAddressId}`,
  postSubmitApplication: (companyId: string, productId: string) => {
    return `/api/v1/applicationForm/${companyId}/submitApplication/${productId}`;
  },
  deleteApplication: (companyId: string, applicationId: string) => {
    return `/api/v1/business/${companyId}/application/${applicationId}`;
  },
  getApplicationsStatistics: '/api/v1/product/applications/statistics',
};

const groupUri = {
  postInviteGroupAdmin: '/api/v1/group/invite-group-admin',
  postInviteGroupUser: '/api/v1/group/invite-group-user',
  postInviteGroupCompany: '/api/v1/group/invite-group-company',
  getGroup: (groupId: string) => `/api/v1/group/${groupId}`,
  putGroup: (groupId: string) => `/api/v1/group/${groupId}`,
  putGroupLogo: (groupId: string) => `/api/v1/group/${groupId}/logo`,
};

const productsUri = {
  getProductList: '/api/v1/product',
  putProduct: `/api/v1/product/updateProduct`,
  getProduct: (productId: string) => `/api/v1/product/${productId}`,
  putApplicationFormDefinition: (productId: string) =>
    `/api/v1/product/${productId}/application-form-definition`,
  getApplicationFormDefinition: (productId: string) =>
    `/api/v1/product/${productId}/application-form-definition`,
  getProviderOptions: '/api/v1/product/options/providers',
  updateApplicationStatus: (companyId: string, applicationId: string) =>
    `/api/v1/product/${companyId}/applications/${applicationId}/status`,
  putProductApplication: (companyId: string, applicationId: string) =>
    `/api/v1/product/${companyId}/applications/${applicationId}`,
  getProductInsights: (productId: string) => `/api/v1/product/${productId}/insights`,
  putProductInsights: (productId: string) => `/api/v1/product/${productId}/insights`,
};

const formScoreUri = {
  getCompanyScore: (companyId: string) => `/api/v1/form/score/${companyId}`,
  getCompanyFormScore: (companyId: string, formId: string) =>
    `/api/v1/form/score/${companyId}/form/${formId}`,
  getFormScoreHistory: (companyId: string) => `/api/v1/form/score/history/${companyId}`,
  getCompanyScoreHistoryByForm: (companyId: string, formId: string) =>
    `/api/v1/form/score/history/${companyId}/form/${formId}`,
};

export const ProductsServiceURL = {
  getProducts: `/api/products`,
  getProduct: (productId: string) => `/api/v1/product/${productId}`,
  getProductApplicationFormDefinitions: (productId: string) =>
    `/api/products/${productId}/applicationformdefinition`,
  putProduct: `/api/v1/product/updateProduct`,
};

export const MarketplaceAPIURL = {
  getMetaData: `/api/v1/Metadata`,
};

const savingsProducts = {
  getSavingsProductApplicationStatus: (companyId: string, productName: string) =>
    `api/v1/product-page/submission-exists/${companyId}/${productName}`,
  postSavingsProductApplication: (companyId: string, productName: string) =>
    `api/v1/product-page/submission-create/${companyId}/${productName}`,
};

const creditSafeUrl = {
  getCreditSafeReport: (companyId: string) => `api/v1/business/${companyId}/creditsafe`,
  getCreditSafeFinancialReport: (companyId: string) =>
    `api/v1/business/${companyId}/creditsafe/financial`,
};

// eslint-disable import/named
export const BrokerAPIURL = {
  ...authUri,
  ...userUri,
  ...iamUserUri,
  ...rolesUri,
  ...notesUri,
  ...activityUri,
  ...opportunityUri,
  ...businessUri,
  ...groupUri,
  ...documentsUri,
  ...financialInfoUri,
  ...fundingMatchesUri,
  ...applicationFormUri,
  ...productsUri,
  ...contactsUri,
  ...ProductsServiceURL,
  ...formScoreUri,
  ...savingsProducts,
  ...creditSafeUrl,
};
