import React from 'react';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import Select from 'components/v2/Select';
import TextInput from 'components/v2/TextInput';
import Button from 'components/button';
import icons from './icons.json';
import styles from './Bookmark.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToastMessage from '_shared/hooks/useToastMessage';
import useBookmarks from '_shared/hooks/useBookmarks';

type FormInput = {
  icon: string;
  label: string;
  address: string;
};

const iconOptions = icons.map((icon) => ({ label: icon, value: icon }));

const Bookmark: React.FC = () => {
  const { id } = useParams();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { successToastMessage } = useToastMessage();
  const { getBookmarkById, addBookmark, updateBookmark } = useBookmarks();

  const editingBookmark = id ? getBookmarkById(id) : null;

  const {
    handleSubmit,
    watch,
    control,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormInput>({
    mode: 'all',
    values: {
      icon: editingBookmark?.icon ?? icons[0],
      label: editingBookmark?.label ?? '',
      address: editingBookmark?.address ?? 'https://',
    },
  });

  const onSubmit = (values: FormInput) => {
    if (editingBookmark) {
      updateBookmark({
        ...values,
        id: editingBookmark.id,
      });

      successToastMessage('Bookmark has been saved');
    } else {
      addBookmark(values);
      resetForm();
      successToastMessage('Bookmark has been added');
    }

    if (locationState?.from) {
      navigate(locationState.from);
    }
  };

  const selectedIcon = watch('icon');

  return (
    <div>
      <h1>{editingBookmark ? 'Edit Bookmark' : 'Create Bookmark'}</h1>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>Icon</span>
          <div className={styles['icon-select']}>
            <Controller
              name="icon"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={iconOptions}
                  placeholder="Select icon"
                  className={cn({ [styles.error]: Boolean(errors['icon']) })}
                />
              )}
            />
            {selectedIcon && <i className={cn('material-icons', styles.icon)}>{selectedIcon}</i>}
          </div>
        </label>
        <label>
          <span>Name</span>
          <Controller
            name="label"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput {...field} className={cn({ [styles.error]: Boolean(errors['label']) })} />
            )}
          />
        </label>
        <label>
          <span>Address</span>
          <Controller
            name="address"
            control={control}
            rules={{ required: true, pattern: { value: /https?:\/\/.+/, message: 'Invalid URL' } }}
            render={({ field }) => (
              <TextInput
                {...field}
                className={cn({ [styles.error]: Boolean(errors['address']) })}
              />
            )}
          />
        </label>
        <div className={styles.actions}>
          <Button className={styles.submit}>Save bookmark</Button>
        </div>
      </form>
    </div>
  );
};

export default Bookmark;
