import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { ModalType } from './type';
import styles from './modal.module.scss';
import Loader from 'components/loader';
import { LoaderType } from 'components/loader/type';

const Modal = ({
  show,
  children,
  handleClose,
  testId = '',
  fullPage = false,
  previewPage = false,
  loading = false,
}: ModalType) => {
  const modalContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  document.addEventListener(
    'keydown',
    function (event) {
      if (event.key === 'Escape' && show) {
        handleClose(false);
      }
    },
    { once: true }
  );

  const showHideClassName = show ? 'display-block' : 'display-none';

  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <div
            role="dialog"
            aria-modal="true"
            aria-hidden={!show}
            aria-labelledby="modal-dialog"
            data-testid={`modal-test-${testId}`}
            className={classNames(styles[showHideClassName])}
          >
            <div className={styles['modal-overlay']} id="modal-overlay" />
            <div
              ref={modalContent}
              className={classNames(styles.modal, styles[showHideClassName], {
                [styles['fullsize-modal']]: fullPage,
                [styles['preview-modal']]: previewPage,
              })}
            >
              <section className={styles['modal-main']}>
                <Loader active={loading} loaderStyle={LoaderType.COMPONENT_LOADER} />
                {!loading && (
                  <button className={styles['close-modal']} onClick={() => handleClose(false)}>
                    <span className={classNames('material-icons', styles['close-icon'])}>
                      clear
                    </span>
                  </button>
                )}
                {!loading && children}
              </section>
            </div>
          </div>,
          document.body
        )}
      {!show && null}
    </>
  );
};

export default Modal;
