import { FieldMetaDataPropType, FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { DebtRequirementsType } from '../components/fundingRequirements/types';
import { getYearsList } from '_shared/utils/date';
import { getFirstCommercialSaleComponents } from 'pages/fundingMatches/utils';
import { convertToCurrency, currencyIcon } from '_shared/utils/currency';
import { banksDic, fundingRequiredTimescale, monthsDic } from '_shared/utils/constants';
import { findValueInMetadata } from '_shared/utils/form';

const defaultClassNames = {};

export const LoanRequirementsFields = (data: DebtRequirementsType): FieldMetaDataPropType[] => {
  const [firstCommercialSaleMonth, firstCommercialSaleYear] = getFirstCommercialSaleComponents(
    data?.firstCommercialSale as number
  );

  const leadingCurrencyIcon = currencyIcon();
  return [
    {
      id: 'funding-requirements-funding-purposes',
      label: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
      placeholder: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
      name: 'fundingPurposes',
      value:
        (data?.fundingPurposes?.length &&
          data?.fundingPurposes.map((purpose: any) => {
            return findValueInMetadata(data.pageMetadata.fundingPurposes, purpose);
          })) ||
        [],
      validationtype: ValidationTypes.MULTISELECT,
      fieldType: FieldTypes.READONLY,
      options: data.pageMetadata?.fundingPurposes?.map((opt) => {
        return {
          label: opt.label,
          value: opt.label,
        };
      }),
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'fundingRequired',
      name: 'fundingRequired',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequired',
      value: data.fundingRequired ? convertToCurrency(data.fundingRequired) : ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.NUMERIC,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'turnoverLastYear',
      name: 'turnoverLastYear',
      label: 'home:companydetails:fundingmatches:fields:loans:turnoverlastyear',
      value: data.turnoverLastYear ? convertToCurrency(data.turnoverLastYear) : ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.NUMERIC,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'sectors',
      label: 'home:companydetails:fundingmatches:fields:loans:sectors',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:sectors',
      name: 'sectors',
      value:
        (data.sectors?.length &&
          data.sectors.map((sector) => {
            return findValueInMetadata(data.pageMetadata.sectors, sector);
          })) ||
        [],
      validationtype: ValidationTypes.MULTISELECT,
      fieldType: FieldTypes.READONLY,
      options: data.pageMetadata.sectors.map(({ label }: { label: string }) => {
        return {
          label,
          value: label,
        };
      }),
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'fundingRequiredTimescale',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequiredtimescale',
      name: 'fundingRequiredTimescale',
      placeholder: 'funding timeframe',
      value: data.fundingRequiredTimescale || ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.SELECT,
      options: [...fundingRequiredTimescale],
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'currentlybank',
      label: 'home:companydetails:fundingmatches:fields:loans:currentlybank',
      name: 'currentlyBank',
      value: data?.currentlyBank || ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.SELECT,
      placeholder: 'select bank',
      options: banksDic,
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'fundingPurposeSummary',
      name: 'fundingPurposeSummary',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingpurposesummary',
      value: data.fundingPurposeSummary || ' - ',
      fieldType: FieldTypes.READONLY,
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'isTrading',
      label: 'home:companydetails:fundingmatches:fields:grants:istrading',
      value: data?.isTrading ? 'Yes' : 'No',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.RADIO,
      name: 'isTrading',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'tradingSinceYear',
      label: 'Trading since (Year)',
      name: 'firstCommercialSaleYear',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:year',
      value: firstCommercialSaleYear || ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.SELECT,
      classNames: {
        ...defaultClassNames,
      },
      dependsOn: {
        name: 'isTrading',
        value: 'true',
      },
      options: getYearsList().map((year) => {
        return {
          value: `${year}`,
          label: `${year}`,
        };
      }),
    },
    {
      id: 'tradingSinceMonth',
      label: 'Trading since (Month)',
      name: 'firstCommercialSaleMonth',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:month',
      value: firstCommercialSaleMonth || ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.SELECT,
      options: [...monthsDic],
      dependsOn: {
        name: 'isTrading',
        value: 'true',
      },
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'isHomeowner',
      label: 'home:companydetails:fundingmatches:fields:loans:ishomeowner',
      value: data.isHomeowner ? 'Yes' : 'No',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.RADIO,
      name: 'isHomeowner',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'profitablelastyear',
      label: 'home:companydetails:fundingmatches:fields:loans:profitablelastyear',
      value: data?.profitableLastYear ? 'Yes' : 'No',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.RADIO,
      name: 'profitablelastyear',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'acceptscardpayment',
      label: 'home:companydetails:fundingmatches:fields:loans:acceptscardpayment',
      value: data?.cardPaymentsAccepted ? 'Yes' : 'No',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.RADIO,
      name: 'cardPaymentsAccepted',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'invoicessent',
      label: 'home:companydetails:fundingmatches:fields:loans:invoicessent',
      value: data?.invoicesSent ? 'Yes' : 'No',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.RADIO,
      name: 'invoicesSent',
      classNames: {
        ...defaultClassNames,
      },
    },
    {
      id: 'monthlyRecurringRevenue',
      name: 'monthlyRecurringRevenue',
      label: 'home:companydetails:fundingmatches:fields:loans:recurringrevenue',
      value: data.monthlyRecurringRevenue || ' - ',
      fieldType: FieldTypes.READONLY,
      validationtype: ValidationTypes.NUMERIC,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultClassNames,
      },
    },
  ];
};
