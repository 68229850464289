import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import styles from 'pages/companyOverview/assets/companyOverview.module.scss';

export const AccountBalanceDropdown: React.FC<any> = ({ options }): any => {
  const dropdownRef = useRef<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const openFilters = () => {
    setIsDropdownOpen(true);
  };

  const selectOption = (selectedFilter: any) => {
    setIsDropdownOpen(false);
    setSelected(selectedFilter);
  };

  return (
    <div className={classnames(styles['dropdown-container'])}>
      <button
        onClick={openFilters}
        className={classnames(styles['balance_active'], styles['financial-value'])}
        onBlur={() => setIsDropdownOpen(false)}
      >
        <span>{selected.value}</span>
        <span className={classnames('material-icons', styles['icon'])}>expand_more</span>
      </button>
      {
        <ul
          ref={dropdownRef}
          tabIndex={0}
          className={classnames(styles['dropdown'], isDropdownOpen && styles['active'])}
        >
          {options.map(({ label, value }: any) => {
            return (
              <li
                key={label}
                value={label}
                onMouseDown={() => selectOption({ label, value })}
                className={classnames(styles['filter-option'])}
              >
                <span className={classnames(label === selected.label && styles.selected)}>
                  {label}
                </span>
              </li>
            );
          })}
        </ul>
      }
    </div>
  );
};
