import { useState } from 'react';
import styles from './tooltip.module.scss';
import { TooltipPropsType } from './types';
import cn from 'classnames';

const Tooltip: React.FC<TooltipPropsType> = ({ content, children, delay, className }) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={cn(styles['tooltip-wrapper'])}
      data-testid={'tooltip'}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && <div className={cn(styles['tooltip-tip'], className)}>{content}</div>}
    </div>
  );
};

export default Tooltip;
