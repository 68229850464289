import { ICompanyFilter } from 'pages/companies/components/filters/types';

export interface CompanyState {
  companyId: string;
  companyIndexState: string;
  companyName: string;
  searchBy: string;
  selectedFilters: ICompanyFilter | null;
  pageNumber: number;
  refreshIndexPage: number;
  selectedUserIds?: string[];
  filter: {
    orderBy: string;
    direction: string;
    pageSize?: string | number;
  };
  companyData: Record<string, number | string>;
}

// Describing the different ACTION NAMES available
export const UPDATE_COMPANY = 'UPDATE_COMPANY';

interface UpdateCompanyAction {
  type: typeof UPDATE_COMPANY;
  payload?: CompanyState;
}

export type CompanyActionTypes = UpdateCompanyAction;
