import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import Loader from 'components/loader';
import { fields } from './fields/login.fields';
import styles from 'pages/login/assets/login.module.scss';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Button from 'components/button';
import LoginHook from './hooks/login.hook';
import Field from 'components/field';
import Card from 'components/card';
import { Link } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { sendAmplitudeData, AmplitudeTrackingEnum } from 'config/amplitude';

function Login() {
  const location = useLocation();
  const [state, setstate] = useState({
    redirectToReferrer: false,
    loading: false,
    from: location.state || { from: { pathname: '/' } },
    goToNext: false,
    errorMessage: null,
    currentUser: {},
    pageLoader: false,
    showPassword: false,
  });
  const { submitLogin } = LoginHook();

  const { t } = useTranslation();

  const signin = async (values: any) => {
    setstate({ ...state, loading: true });
    try {
      await submitLogin(values);

      setstate({ ...state, loading: false });

      sendAmplitudeData(AmplitudeTrackingEnum.login, {}, true);

      return null;
    } catch (e: any) {
      let newState = {};
      if (e.response && e.response.status === 401) {
        newState = {
          ...state,
          errorMessage: t('error:incorrectunamepassword'),
          loading: false,
        };
      } else if (e.response && ![403, 500].includes(e.response.status)) {
        newState = {
          ...state,
          errorMessage: e.response.data.Message,
          loading: false,
        };
      } else if (!e.response) {
        newState = { ...state, loading: false };
      }
      setstate({ ...state, ...newState });
      return null;
    }
  };

  const { handleChange, handleSubmit, errors, metadata } = useForm({}, fields, signin, validation);

  const handleShowHidePassword = () => {
    setstate({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  if (state.redirectToReferrer) {
    return <Navigate to={state.from} />;
  }

  const totalErrors = state.errorMessage && {
    email: state.errorMessage,
    password: state.errorMessage,
  };

  return (
    <>
      {state.pageLoader && <Loader active />}
      {!state.pageLoader && (
        <Card>
          <div className={cn(styles['login-form-container'])}>
            <h1>{t('Sign in')}</h1>
            <form className={cn(styles['login-form'])} onSubmit={handleSubmit} noValidate>
              <div className={cn(styles['form-inputs'])}>
                <Field
                  metadata={metadata[0]}
                  errors={totalErrors || errors}
                  handleChange={handleChange}
                />
                <div className={cn(styles['password-container'])}>
                  <div className={cn(styles['show-password-container'])}>
                    <input tabIndex={1} type="checkbox" onChange={handleShowHidePassword} />
                    <span className={'material-icons'}>
                      {(state.showPassword && 'visibility') || 'visibility_off'}
                    </span>
                  </div>
                </div>
                <Field
                  metadata={{ ...metadata[1], type: (state.showPassword && 'text') || 'password' }}
                  errors={totalErrors || errors}
                  handleChange={handleChange}
                />
              </div>
              <div
                className={cn(styles['action-container'], styles['action-container-space-between'])}
              >
                <Link to={RoutePath.forgotpassword}>{t('login:forgotpassword:title')}</Link>
                <Button
                  ariaLabel="Button to sign in to Broker Portal"
                  id="sign-in"
                  buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
                  type="submit"
                  loading={state.loading}
                  className={styles['submit-button']}
                >
                  {t('common:signin')}
                </Button>
              </div>
            </form>
          </div>
        </Card>
      )}
    </>
  );
}

export default Login;
