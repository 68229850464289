import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { convertToLocalDateFormat } from '_shared/utils/date';
import { spaceBetweenTitleCaseWords } from '_shared/utils/string';
import { ACTIVITY_ORDER, ActivityType, APIActivityType, ACTIVITY_TYPE } from './types';

export const activitiesFields = (
  data: Array<APIActivityType>,
  userId: string
): Array<ActivityType> => {
  return data.map((activity: APIActivityType) => {
    return {
      id: activity.id,
      timestamp:
        (activity.dateModified && convertToLocalDateFormat(activity.dateModified)) ||
        (activity.dateCreated && convertToLocalDateFormat(activity.dateCreated)),
      user: activity.fullName,
      activityType: activity.activityType,
      content: activity.content,
      picture: activity.picture,
      isMyNote: activity.createdBy === userId,
      taskStatus: activity.taskStatus,
      dateCreated: convertToLocalDateFormat(activity.dateCreated),
      dateModified: convertToLocalDateFormat(activity.dateModified),
      dueDate: convertToLocalDateFormat(activity.dueDate),
      subject: activity.subject,
      toAddress: activity.toAddress,
      fromAddress: activity.fromAddress,
      assignedToFullName: activity.assignedToFullName,
    };
  });
};

export const orderMetadata = (value: string) => {
  return {
    id: 'activities-order',
    name: 'activities-order',
    placeholder: 'home:companydetails:activities:note:order',
    value,
    options: Object.values(ACTIVITY_ORDER).map((order: string) => {
      return {
        label: order,
        value: order,
      };
    }),
  };
};

export const filterActivitiesFields = (value: string) => {
  return {
    id: 'activities-filter',
    name: 'activityTypes',
    placeholder: 'home:companydetails:activities:note:filter',
    value,
    options: Object.values(ACTIVITY_TYPE).map((filterType: string) => {
      return {
        label: spaceBetweenTitleCaseWords(filterType),
        value: filterType,
      };
    }),
  };
};

export const newCallMetadata = {
  id: 'call-status',
  label: 'Call Status',
  value: undefined,
  selectionKey: '',
  fieldType: FieldTypes.RADIO,
  validationtype: ValidationTypes.RADIO,
  type: 'radio',
  name: 'callStatus',
  required: true,
  options: [
    {
      parentId: 'callStatus',
      name: 'true',
      label: 'Success',
      value: 'true',
    },
    {
      parentId: 'callStatus',
      name: 'false',
      label: 'Missed',
      value: 'false',
    },
  ],
};
