// eslint-disable-next-line import/named
import { BrokerAPIURL } from '_shared/url';
import { resetXGroupHeaders } from '_shared/utils/grouping';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export enum QueryKeys {
  GetUserByRole = 'getUserByRole',
}

export const getRoles = () => BrokerAPI.get(BrokerAPIURL.getRoles);
export const getUsersByRole = (roleId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getUsersByRole(roleId), resetXGroupHeaders());
};
export const putUserRoles = (userSecurityId: string, data: { [key: string]: any }) => {
  return BrokerAPI.post(BrokerAPIURL.putUserRoles(userSecurityId), data);
};
export const getUserRoles = (userSecurityId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getUserRoles(userSecurityId));
};
export const getAvailableRolesList = (applicationType: string) => {
  return BrokerAPI.get(BrokerAPIURL.getAvailableRolesList(applicationType), resetXGroupHeaders());
};
