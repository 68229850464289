/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
import Spinner from 'components/spinner';
import { TablePropType } from './type';
import styles from './table.module.scss';
import { HeaderPropType } from './header/type';
import Header from './header';
import { CellPropType } from './cell/type';
import Cell from './cell';
import { filterByApplicationType } from '_shared/utils/application';

const Table: React.FC<TablePropType> = (props: TablePropType) => {
  const hasSortedColumn = Boolean(
    props.header?.find((header: HeaderPropType) => Boolean(header.direction))
  );

  return (
    <div
      id="table-container"
      className={classNames(styles['table-container'], props?.['table-container'])}
    >
      <table
        className={classNames(
          styles['sw-table'],
          styles['-hover'],
          styles.table,
          props.classname?.table
        )}
      >
        {props.header?.length > 0 && (
          <thead>
            <tr>
              {filterByApplicationType(props.header).map((headerProps: HeaderPropType) => (
                <Header
                  key={headerProps.id || headerProps.value}
                  {...headerProps}
                  hasSortedColumn={hasSortedColumn}
                />
              ))}
            </tr>
          </thead>
        )}
        {!props.fullTableLoader && (
          <>
            {(props.items?.length > 0 && (
              <tbody data-testid="table-body">
                {props.items.map((item: CellPropType[]) => (
                  <tr
                    key={item[0].id}
                    className={classNames(styles.row, props.row.classname)}
                    onClick={() => {
                      props?.rowCallBack?.(item[0].id || '', item[1].id || '');
                    }}
                  >
                    {filterByApplicationType(item).map((cellProp: CellPropType, index: number) => (
                      <Cell id={`${item[0].id}-cell`} key={cellProp?.id || index} {...cellProp} />
                    ))}
                  </tr>
                ))}
                {/* Adding extra table row if number of elements is less than 10 so all the tables have the same height. */}
                {props.items.length < 10 && <tr />}
              </tbody>
            )) || (
              <tbody data-testid="table-body">
                {(!props.loading && (
                  <tr className={classNames(styles.row, props.row?.classname, styles.emptyState)}>
                    <td colSpan={props.header?.length}>{props.emptyMessage}</td>
                  </tr>
                )) ||
                  null}
              </tbody>
            )}
          </>
        )}
        <tfoot>
          {props.loading && (
            <tr>
              <td colSpan={props.header?.length}>
                <div className={classNames(styles.loader)}>
                  <Spinner size="small" />
                </div>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
