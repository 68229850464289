import { APIDocumentType, NewDocumentType } from '../types';

export type uploadDocumentStateType = {
  newDocuments: Array<NewDocumentType>;
  data: Array<APIDocumentType>;
  updatingTransactions: {
    trueLayer: boolean;
  };
  financialStatus: {
    trueLayer: boolean;
  };
};

export enum uploadDocumentActionType {
  SET_NEW_DOCUMENTS,
  REMOVE_NEW_DOCUMENTS,
  SET_UPDATING_TRANSACTIONS,
  SET_FINANCIAL_STATUS,
}

export type Action = {
  type: uploadDocumentActionType;
  payload: any;
};

export function uploadDocumentReducer(state: uploadDocumentStateType, action: Action) {
  switch (action.type) {
    case uploadDocumentActionType.SET_NEW_DOCUMENTS:
      return { ...state, newDocuments: [...state.newDocuments, action.payload] };
    case uploadDocumentActionType.REMOVE_NEW_DOCUMENTS:
      const updatedNewDocuments = state.newDocuments.filter(
        (newDocument: NewDocumentType) => newDocument.id !== action.payload
      );

      return {
        ...state,
        newDocuments: [...updatedNewDocuments],
        reload: true,
      };
    case uploadDocumentActionType.SET_UPDATING_TRANSACTIONS:
      return {
        ...state,
        updatingTransactions: { ...state.updatingTransactions, ...action.payload },
      };

    case uploadDocumentActionType.SET_FINANCIAL_STATUS:
      return {
        ...state,
        financialStatus: { trueLayer: action.payload.trueLayer },
      };

    default:
      return state;
  }
}
