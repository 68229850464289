import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { RoutePath } from '_shared/routes';
import useMediaQuery from '_shared/hooks/useMediaQuery';
import swoopBlueLogo from 'assets/images/swoop-logo-blue.svg';
import styles from './notfound.module.scss';

function NotFound() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 767px)');
  return (
    <div className={classNames('sw-center', styles['main-content'])}>
      {isMobile && <img alt="logo" height="58" width="158" src={swoopBlueLogo} />}
      <h1 className="sw-h1">{t('common:notfound:title')}</h1>
      <h4 className="sw-h4">{t('common:notfound:instructions')}</h4>
      <div className={styles['action-container']}>
        <Link to={RoutePath.login}>
          <button
            type="submit"
            className={classNames('sw-button primary', styles['sign-in-button'])}
          >
            {t('common:notfound:goback')}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
