import { convertTimestampToLongMonthAndYear } from '_shared/utils/date';

export const readableGroupName = (groupName: string) => {
  return groupName.split(/(?=[A-Z])/).join(' ');
};

export const parseStringValues = (purpose: string) => {
  return purpose.toLocaleLowerCase().replaceAll(' ', '').replaceAll('/', '').replaceAll('-', '');
};

export const getFirstCommercialSaleComponents = (firstCommercialSale: number) => {
  return (
    (firstCommercialSale && convertTimestampToLongMonthAndYear(firstCommercialSale).split(' ')) ||
    []
  );
};
