import { TPeopleInfo } from '../types';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';

export type OverviewStateType = {
  companyInfo: {
    fields: Array<FieldMetaDataPropType>;
    loading: boolean;
    error: boolean;
  };
  peopleInfo: TPeopleInfo;
};

export enum LocalActionType {
  SET_COMPANY_INFO_FIELDS,
  SET_PEOPLE_INFO_FIELDS,
}

export type Action = {
  type: LocalActionType;
  payload: any;
};

export function overviewReducer(state: OverviewStateType, action: Action) {
  switch (action.type) {
    case LocalActionType.SET_COMPANY_INFO_FIELDS:
      return {
        ...state,
        companyInfo: { ...state.companyInfo, ...action.payload },
      };
    case LocalActionType.SET_PEOPLE_INFO_FIELDS:
      return {
        ...state,
        peopleInfo: { ...state.peopleInfo, ...action.payload },
      };
    default:
      return state;
  }
}
