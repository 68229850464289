import React from 'react';
import Modal from 'components/modal';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomMenuItem } from '../type';
import Button from '../../components/button';
import { ButtonStyleTypeEnum } from '../../components/button/type';
import Table from 'components/v2/Table';
import TableBody from '../../components/v2/TableBody';
import TableHead from '../../components/v2/TableHead';
import TableRow from '../../components/v2/TableRow';
import TableCell from '../../components/v2/TableCell';
import styles from './DeleteBookmarkModal.module.scss';

type DeleteBookmarkModalProps = {
  bookmark: CustomMenuItem;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteBookmarkModal: React.FC<DeleteBookmarkModalProps> = ({
  show,
  onClose,
  onConfirm,
  bookmark,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} handleClose={onClose}>
      <div className={styles.modal}>
        <h3>{t('home:customlinkmodal:title')}</h3>
        <p className={styles.warning}>{t('home:customlinkmodal:description')}</p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{bookmark.label}</TableCell>
              <TableCell>{bookmark.address}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={cn(styles['actions'])}>
          <Button
            id="delete-modal-cancel"
            ariaLabel="delete-modal-cancel"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button
            id="delete-modal-action"
            ariaLabel="delete-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            className={cn(styles['delete'])}
            tabIndex={0}
            clickHandler={onConfirm}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteBookmarkModal;
