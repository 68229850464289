import { putCompanyDocumentTitle, putCompanyDocumentType } from '_shared/api/documents';
import { docTypes } from '_shared/utils/constants';
import { documentFields } from 'pages/documentLibrary/fields/documentTable.fields';
import { APIDocumentType, DocumentField } from 'pages/documentLibrary/types';

export type documentTableStateType = {
  documents: any;
  data: Array<APIDocumentType>;
  selectedDocType: string;
  previewDocument: string;
  editDocuments: Array<APIDocumentType>;
  isDeleteModalOpen: boolean;
  documentsToDelete?: Array<DocumentField>;
  documentToDownload: DocumentField;
  selectedDocuments: Array<string>;
  selectAll: boolean;
  isBrokerDocument: boolean;
  isDownloading: boolean;
  loading: {
    fetchDocuments: boolean;
    isDownloading: boolean;
    isSaving: boolean;
    isDeleting: boolean;
  };
  error: boolean;
  searchTerm: string;
  reload: boolean;
  filter: { orderBy: string; direction: string };
};

export enum documentTableActionType {
  SET_LOADING,
  SET_DOCUMENTS,
  SET_DOCUMENT,
  REMOVE_DOCUMENT,
  SET_SELECTED_DOCUMENT_TYPE,
  SET_IS_DELETE_MODAL_OPEN,
  SET_DOCUMENTS_TO_DELETE,
  UPDATE_DOCUMENT_COUNT,
  SET_EDIT_DOCUMENTS,
  SET_DOWNLOAD_DOCUMENT,
  SET_PREVIEW_DOCUMENT,
  SAVE_EDIT,
  CHANGE_DOCUMENT,
  SET_UPDATING_TRANSACTIONS,
  SET_SEARCH_TERM,
  SET_FINANCIAL_STATUS,
  SET_RELOAD,
  SET_DOCUMENTS_TO_DOWNLOAD,
  SET_IS_DOWNLOADING,
  SET_SELECT_ALL,
  SET_ORDER,
}

export type Action = {
  type: documentTableActionType;
  payload: any;
};

function filterArray(array: any, object: any, key: string) {
  var index = array.findIndex((o: any) => o[key] === object[key]);
  if (index === -1) array.push(object);
  else array.splice(index, 1);
  return array;
}

const orderByFieldsConversion = {
  'Uploaded Date': 'uploadedDate',
  Category: 'type',
  Title: 'title',
} as any;

const sortFunction = (data: any[], sortColumn: string, direction: string) => {
  if (direction === 'asc') {
    return data.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1));
  } else {
    return data.sort((a, b) => (a[sortColumn] > b[sortColumn] ? -1 : 1));
  }
};

export function documentTableReducer(state: documentTableStateType, action: Action) {
  switch (action.type) {
    case documentTableActionType.SET_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    case documentTableActionType.SET_DOCUMENTS:
      return {
        ...state,
        ...action.payload,
      };

    case documentTableActionType.SET_ORDER:
      const orderByValue = orderByFieldsConversion[action.payload.filter];
      const newDirection = state.filter.direction === 'desc' ? 'asc' : 'desc';
      const sortedData =
        (orderByValue && sortFunction([...state.data], orderByValue, newDirection)) || state.data;

      return {
        ...state,
        documents: documentFields({
          data: sortedData,
          editDocuments: state.editDocuments,
          selectedDocuments: state.documents,
          actions: action.payload.getDocumentActions,
          order: {
            order: action.payload.filter,
            direction: newDirection,
          },
          isAdminOrSuperAdmin: action.payload.isAdminOrSuperAdmin,
        }),
        filter: {
          sortBy: action.payload.filter,
          direction: newDirection,
        },
      };

    case documentTableActionType.REMOVE_DOCUMENT:
      const docsWithRemoved = [
        ...state.documents[state.selectedDocType].filter((doc: any) => doc.id !== action.payload),
      ];

      return {
        ...state,
        documents: { ...state.documents, [state.selectedDocType]: docsWithRemoved },
        documentsToDelete: [],
      };

    case documentTableActionType.SET_EDIT_DOCUMENTS:
      const relevantDocuments = state.data.filter((document: APIDocumentType) =>
        action.payload.includes(document.documentId)
      );

      const documentsToEdit =
        (state.editDocuments.length &&
          relevantDocuments.reduce((total: any, document: any) => {
            return [...total, ...filterArray([...state.editDocuments], document, 'documentId')];
          }, [])) ||
        relevantDocuments;

      return {
        ...state,
        editDocuments: documentsToEdit,
        selectedDocuments: state.selectedDocuments.filter(
          (selectedDocument: string) => !action.payload.includes(selectedDocument)
        ),
      };

    case documentTableActionType.SET_SELECTED_DOCUMENT_TYPE:
      return {
        ...state,
        selectedDocType: action.payload,
      };

    case documentTableActionType.SET_IS_DELETE_MODAL_OPEN:
      return {
        ...state,
        isDeleteModalOpen: action.payload,
      };

    case documentTableActionType.SET_DOCUMENTS_TO_DELETE:
      const documentsToDelete =
        (action.payload.length &&
          action.payload.map((docId: string) => {
            return state.data.find((document) => document.documentId === docId);
          })) ||
        [];

      return {
        ...state,
        documentsToDelete: documentsToDelete,
        isDeleteModalOpen: documentsToDelete.length && true,
      };

    case documentTableActionType.SET_DOWNLOAD_DOCUMENT:
      const documentToDownload = state.data.find(
        (document: APIDocumentType) => document.documentId === action.payload
      );
      return {
        ...state,
        documentToDownload: documentToDownload || {},
      };

    case documentTableActionType.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      };

    case documentTableActionType.SET_PREVIEW_DOCUMENT:
      return {
        ...state,
        previewDocument: action.payload,
      };

    case documentTableActionType.CHANGE_DOCUMENT:
      const { id, name: documentName, value, getDocumentActions } = action.payload;

      const updatedData = state.editDocuments.map((document: APIDocumentType) => {
        // if changing category type, select and set a default title
        const { label } = (documentName === 'type' && [...docTypes[value]][0]) || {};
        return (
          (document.documentId === id && {
            ...document,
            [documentName]: value,
            ...((documentName === 'type' && { title: label }) || {}),
          }) ||
          document
        );
      });

      return {
        ...state,
        editDocuments: updatedData,
        documents: documentFields({
          data: state.data,
          editDocuments: updatedData,
          selectedDocuments: state.documents,
          actions: getDocumentActions,
          order: { order: state.filter.orderBy, direction: state.filter.direction },
          isAdminOrSuperAdmin: action.payload.isAdminOrSuperAdmin,
        }),
      };

    case documentTableActionType.SAVE_EDIT:
      const { documentId, type, title } =
        state.editDocuments.find((document: APIDocumentType) =>
          action.payload.selectedDocument.includes(document.documentId)
        ) || {};

      const companyId = action.payload.companyId;

      if (!companyId || !documentId) return state;

      putCompanyDocumentTitle(companyId, documentId, {
        documentTitle: title || '',
      }).catch((e) => console.error(e));
      putCompanyDocumentType(companyId, documentId, {
        documentType: type || '',
      }).catch((e) => console.error(e));

      return state;

    case documentTableActionType.SET_FINANCIAL_STATUS:
      return {
        ...state,
        financialStatus: { trueLayer: action.payload.trueLayer },
      };

    case documentTableActionType.SET_RELOAD:
      return {
        ...state,
        reload: action.payload.reload,
      };

    case documentTableActionType.SET_IS_DOWNLOADING:
      return {
        ...state,
        isDownloading: action.payload.isDownloading,
      };

    //select all document in the table
    case documentTableActionType.SET_SELECT_ALL:
      const updatedDocumentsSelected =
        (!state.selectAll && state.data.map((document) => document.documentId)) || [];

      return {
        ...state,
        selectedDocuments: updatedDocumentsSelected,
        documents: documentFields({
          data: state.data,
          editDocuments: state.editDocuments,
          selectedDocuments: updatedDocumentsSelected,
          selectAll: !state.selectAll,
          actions: action.payload.getDocumentActions,
          order: { order: state.filter.orderBy, direction: state.filter.direction },
          isAdminOrSuperAdmin: action.payload.isAdminOrSuperAdmin,
        }),
        selectAll: !state.selectAll,
      };

    // picking files one by one in the document list
    case documentTableActionType.SET_DOCUMENTS_TO_DOWNLOAD:
      const { documentId: selectedDocumentId, getDocumentActions: documentActions } =
        action.payload;
      // get relevant document id from existing documents
      const { documentId: relevantDocumentId } =
        (selectedDocumentId &&
          state.data.find((document: APIDocumentType) =>
            selectedDocumentId.includes(document.documentId)
          )) ||
        {};

      // if empty action, clear list
      if (!relevantDocumentId) {
        return {
          ...state,
          selectedDocuments: [],
          documents: documentFields({
            data: state.data,
            editDocuments: state.editDocuments,
            selectedDocuments: [],
            actions: documentActions,
            order: { order: state.filter.orderBy, direction: state.filter.direction },
            isAdminOrSuperAdmin: action.payload.isAdminOrSuperAdmin,
          }),
        };
      }

      const newDocumentToDownloadList =
        (!state.selectedDocuments.includes(relevantDocumentId) && [
          ...state.selectedDocuments,
          relevantDocumentId,
        ]) ||
        state.selectedDocuments.filter(
          (documentToDownloadId: string) => documentToDownloadId !== relevantDocumentId
        );

      return {
        ...state,
        selectedDocuments: newDocumentToDownloadList,
        documents: documentFields({
          data: state.data,
          editDocuments: state.editDocuments,
          selectedDocuments: newDocumentToDownloadList,
          actions: documentActions,
          order: { order: state.filter.orderBy, direction: state.filter.direction },
          isAdminOrSuperAdmin: action.payload.isAdminOrSuperAdmin,
        }),
      };

    default:
      return state;
  }
}
