import React from 'react';
import classNames from 'classnames';
import styles from './graph.module.scss';

export const PercentageGraph = (props: any) => {
  return (
    <div className={classNames(styles['flex-wrapper'])}>
      <svg viewBox="0 0 36 36" className={classNames(styles['circular-chart'])}>
        <path
          className={classNames(styles['circle-bg'])}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={classNames(
            styles['circle'],
            props.isPositive ? styles['positive-stroke'] : styles['negative-stroke']
          )}
          strokeDasharray={`${props.percentage} ${100 - props.percentage}`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text
          x="18"
          y="20.35"
          fontSize="2rem"
          className={classNames(
            styles['percentage'],
            props.isPositive ? styles.positive : styles.negative
          )}
        >
          {props.value}
        </text>
      </svg>
    </div>
  );
};
