import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Overview from '.';

export const companyOverviewRoutes: RouteObject[] = [
  {
    path: RoutePath.companyoverview,
    element: withTransaction('Overview', 'component')(<Overview />),
  },
];
