import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Button from 'components/button';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import { getCompanyStatusHistory } from '_shared/api/businesses';
import styles from './StatusHistoryModal.module.scss';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import { convertToLocalDateTimeFormat } from '_shared/utils/date';
import { dealsStatusList } from '_shared/utils/constants';

type HistoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  opportunityId: string;
};

type HistoryEntry = {
  date: string;
  opportunityStage: number;
};

const HistoryModal: React.FC<HistoryModalProps> = ({
  isOpen,
  onClose,
  companyId,
  opportunityId,
}) => {
  const { t } = useTranslation();
  const [historyEntries, setHistoryEntries] = useState<HistoryEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getStatusText = (status: number) =>
    Object.values(dealsStatusList).find(({ value }: any) => value === status.toString())?.label ??
    status;

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await getCompanyStatusHistory(companyId, opportunityId);
          setHistoryEntries(data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [companyId, isOpen, opportunityId]);

  return (
    <Modal show={isOpen} handleClose={onClose} testId={'history-modal'}>
      {isLoading ? (
        <div className={cn(styles['history-modal-loading-container'])}>
          <Spinner size="large" />
        </div>
      ) : (
        <div className={cn(styles['history-modal-wrapper'])}>
          <header className={cn(styles['header-and-icon-container'])}>
            <div>
              <h3 className={cn(styles['history-modal-header'])}>
                {t('home:statushistorymodal:title')}
              </h3>
            </div>
            <div className={cn(styles['history-modal-header-icon'])}>
              <span className={cn('material-icons', styles['header-icon'])}>history</span>
            </div>
          </header>
          <div className={cn(styles['history-table'])}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Stage</TableCell>
                  <TableCell>Date Changed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyEntries.map((entry) => (
                  <TableRow key={entry.date}>
                    <TableCell>{getStatusText(entry.opportunityStage)}</TableCell>
                    <TableCell>{convertToLocalDateTimeFormat(entry.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={cn(styles['history-modal-actions-container'])}>
            <Button
              className={cn(styles['action-button'])}
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              clickHandler={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default HistoryModal;
