import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import styles from 'pages/deals/assets/DealDeletionConfirmModal.module.scss';
import { TablePropType } from 'components/table/type';
import { useMemo } from 'react';
import Table from 'components/table';
import { DealDeletionConfirmModalProps } from './types';

const headerStyling = {
  classname: {
    header: styles['th-header'],
    label: styles['th-label'],
  },
};

const DealDeletionConfirmModal = ({
  isModalOpen,
  dealDetails,
  isLoading,
  onConfirm,
  onModalClose,
}: DealDeletionConfirmModalProps) => {
  const { t } = useTranslation();
  const tableMetaData = useMemo<TablePropType>(
    () => ({
      row: { classname: styles['tr-row'] },
      classname: { table: styles.table },
      header: [
        {
          id: 'owner',
          value: t('home:dealdeletionconfirmmodal:owner'),
          ...headerStyling,
        },
        {
          id: 'company',
          value: t('home:dealdeletionconfirmmodal:company'),
          ...headerStyling,
        },
        {
          id: 'subtype',
          value: t('home:dealdeletionconfirmmodal:subtype'),
          ...headerStyling,
        },
        {
          id: 'status',
          value: t('home:dealdeletionconfirmmodal:status'),
          ...headerStyling,
        },
      ],
      items: [
        [
          {
            id: 'owner',
            default: dealDetails?.ownerName,
          },
          {
            id: 'company',
            default: dealDetails?.companyName,
          },
          {
            id: 'subtype',
            default: dealDetails?.subtype,
          },
          {
            id: 'status',
            default: dealDetails?.status,
          },
        ],
      ],
    }),
    [dealDetails]
  );

  return (
    <Modal show={isModalOpen} handleClose={onModalClose} testId="deal-deletion-confirm-modal">
      <div className={cn(styles['deal-deletion-confirm-modal'])}>
        <h3>{t('home:dealdeletionconfirmmodal:title')}</h3>

        <p className={styles['warning']}>
          {t('home:dealdeletionconfirmmodal:description', {
            numOfFunders: dealDetails?.numOfApplications,
          })}
        </p>

        <Table {...tableMetaData} />

        <div className={cn(styles['actions'])}>
          <Button
            id="deal-deletion-cancel-btn"
            ariaLabel="cancel-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
            clickHandler={onModalClose}
          >
            {t('common:cancel')}
          </Button>

          <Button
            id="deal-deletion-confirm-btn"
            ariaLabel="cancel-modal-action"
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            className={cn(styles['delete'])}
            tabIndex={0}
            clickHandler={onConfirm}
            loading={isLoading}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealDeletionConfirmModal;
