import React, { useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/button';
import styles from 'pages/companyOverview/assets/accountDetails.module.scss';
import { useTranslation } from 'react-i18next';
import ReadOnly from 'components/field/readOnly';
import Field from 'components/field/';
import {
  AutoCompleteTypes,
  FieldMetaDataPropType,
  FieldTypes,
} from '_shared/fieldValidation/types';
import Checkbox from 'components/field/checkbox';
import { AccountInfoCheckBoxFieldsType, AccountInfoFields } from 'pages/companyOverview/types';
import {
  CompanyActionType,
  CompanyDetailsContext,
} from 'pages/companyDetails/store/companyDetails.reducer';
import { useAppState } from 'store';
import { Role, UserRoles } from 'config/roles';
import { getUsersByRole } from '_shared/api/roles';
import {
  getCompanyAssignmentsHistory,
  putAccountInfo,
  putAssignUser,
  QueryKeys,
  unassignUser,
} from '_shared/api/businesses';
import Spinner from 'components/spinner';
import features from 'config/features';
import {
  isBrokerApplication,
  isAdvisorApplication,
  appSpecific,
  SWOOP_GROUP_ID,
} from '_shared/utils/application';
import {
  accountInfoCheckboxFields,
  accountInfoFields,
  utmParameterFields,
} from 'pages/companyOverview/fields/accountInfo.fields';
import { TOAST_MESSAGE } from 'store/toast/types';
import { APIUsersByRoleType } from 'pages/users/type';
import UserSelect from 'components/userSelect';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { convertToLocalDateFormat } from '_shared/utils/date';
import { HistoryModalEnum } from '../historyModal/type';
import HistoryModal from '../historyModal';
import { HistoryEntriesTable } from '../historyModal/fields/assignmentHistory.field';
import { companyAccountStatusOptions } from '_shared/utils/constants';

const AccountDetails = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();
  const { state: globalState } = useAppState();
  const { state, dispatch } = useContext(CompanyDetailsContext);
  const [initialUsersList, setIntitalUsers] = useState<APIUsersByRoleType[]>([]);
  const [ableToSave, setAbleToSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [checkBoxFields, setCheckBoxFields] = useState<AccountInfoCheckBoxFieldsType[]>([]);
  const [businessAccountInfoFields, setBusinessAccountInfoFields] = useState<AccountInfoFields>(
    accountInfoFields({})
  );
  const [historyModalContext, setHistoryModalContext] = useState<HistoryModalEnum | null>(null);
  const [utmParameters, setUTMParameters] = useState<FieldMetaDataPropType[]>(
    utmParameterFields({})
  );
  const [accountInfoData, setAccountInfoData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const store = useAppState();
  const { t } = useTranslation();
  const isUserBroker = Boolean(store.state.system?.isBroker?.());
  const visibleAccountInfoFields = businessAccountInfoFields?.info?.filter((data) => !data.hidden);
  const lastAssignmentDate = () => {
    if (
      globalState?.company?.companyData?.fundingManagerLastAssignmentDate ||
      globalState?.company?.companyData?.accountOwnerLastAssignmentDate
    ) {
      const lastAssignedDate = isBrokerApplication
        ? convertToLocalDateFormat(
            globalState?.company?.companyData?.fundingManagerLastAssignmentDate
          )
        : convertToLocalDateFormat(
            globalState?.company?.companyData?.accountOwnerLastAssignmentDate
          );

      return lastAssignedDate;
    }
  };

  const userOptions = useMemo<APIUsersByRoleType[]>(() => {
    if (!accountInfoData) return initialUsersList;

    const result = [...initialUsersList];

    // When the owner is set through HubSpot, it's possible that the owner is outside of the current group and therefore not in the list of users
    // In this case, we need to look up the owner id and name from the company details and add it to the owner options
    const currentFundingManagerName = accountInfoData.fundingManagerName;
    const currentFundingManagerId = appSpecific(
      accountInfoData.fundingManagerSecurityId,
      accountInfoData.accountOwnerSecurityId
    );
    const currentFundingManagerPicture = accountInfoData.fundingManagerPictureUrl;
    const isExistingFundingManager = initialUsersList.find(
      (user) => user.securityId === currentFundingManagerId
    );

    if (currentFundingManagerId && !isExistingFundingManager) {
      result.push({
        userId: currentFundingManagerId,
        securityId: currentFundingManagerId,
        fullName: currentFundingManagerName,
        picture: currentFundingManagerPicture,
      });
    }

    return result;
  }, [accountInfoData, initialUsersList]);

  const currentSelectedUser = useMemo<APIUsersByRoleType | null>(
    () =>
      selectedUserId
        ? userOptions.find((user) => user.securityId === selectedUserId) ?? null
        : null,
    [selectedUserId, userOptions]
  );

  const fetchBusinessAccountInfo = async (id: string) => {
    setIsLoading(true);
    try {
      const data = state.accountInfoData;
      setBusinessAccountInfoFields(accountInfoFields(data));
      setUTMParameters(utmParameterFields(data?.userUtm));
      setAccountInfoData(data);
      setSelectedUserId(appSpecific(data.fundingManagerSecurityId, data.accountOwnerSecurityId));

      dispatch({
        type: CompanyActionType.SET_ACCOUNT_INFO_DATA,
        payload: data,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const brokerRole = globalState.system?.roles?.find(
        (role: Role) => role.name === UserRoles.BROKER
      );
      const advisorRole = globalState.system?.roles?.find(
        (role: Role) => role.name === UserRoles.ADVISOR
      );

      if (!brokerRole?.id || !advisorRole?.id) return;

      const [{ data: brokerUsers }, { data: advisorUsers }] = await Promise.all([
        getUsersByRole(brokerRole.id),
        getUsersByRole(advisorRole.id),
      ]);

      let usersListToChooseAssignment;

      if (isAdvisorApplication) {
        usersListToChooseAssignment = advisorUsers.results;
      } else {
        usersListToChooseAssignment = brokerUsers.results;
      }

      // lookup list for user assignment
      setIntitalUsers(usersListToChooseAssignment);
    } catch (e) {
      console.error(e);
    }
  };

  const assignUser = async (selectedUser: APIUsersByRoleType | null) => {
    setAbleToSave(true);
    setSelectedUserId(selectedUser ? selectedUser.securityId : null);
  };

  const handleIsTestAccountChange = async (event: React.MouseEvent<HTMLInputElement>) => {
    try {
      const updatedValue = event.currentTarget.checked;

      dispatch({
        type: CompanyActionType.SET_ACCOUNT_INFO_DATA,
        payload: {
          ...state.accountInfoData,
          isTestAccount: updatedValue,
        },
      });
      setAbleToSave(true);
    } catch (e) {
      console.error(e);
    }
  };

  const selectCheckBox = async (event: React.MouseEvent<HTMLInputElement>) => {
    try {
      const updatedCheckboxes = checkBoxFields.map((checkbox: any) => {
        return (
          (checkbox.name === event.currentTarget.name && {
            ...checkbox,
            value: event.currentTarget.checked,
          }) ||
          checkbox
        );
      });

      const updatedCheckBoxValues = updatedCheckboxes.reduce((total: any, current: any) => {
        return {
          ...total,
          [current.name]: current.value,
        };
      }, {});

      dispatch({
        type: CompanyActionType.SET_ACCOUNT_INFO_DATA,
        payload: { ...state.accountInfoData, ...updatedCheckBoxValues },
      });
      setAbleToSave(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleGenericChangeEvents = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAccountInfoData = { ...state.accountInfoData };
    newAccountInfoData[event.target.name] = event.target.value;
    dispatch({
      type: CompanyActionType.SET_ACCOUNT_INFO_DATA,
      payload: newAccountInfoData,
    });
    setAbleToSave(true);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await putAccountInfo(companyId, state.accountInfoData);
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
      void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });

      const userRole = appSpecific(UserRoles.BROKER, UserRoles.ADVISOR);

      if (selectedUserId) {
        await putAssignUser(companyId, {
          userSecurityId: selectedUserId,
          userRole: userRole,
          companyId,
        });
      } else {
        await unassignUser(companyId, {
          userRole: userRole,
          companyId,
        });
      }
      store.dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:companydetails:overview:accountinfo:checkbox:updated') },
      });
      setAbleToSave(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const alternateUserReadOnly = () => {
    return (
      <>
        {(isAdvisorApplication && accountInfoData?.fundingManagerName && (
          <div className={cn(styles['alternate-user'])}>
            {accountInfoData.fundingManagerPictureUrl && (
              <img src={accountInfoData.fundingManagerPictureUrl} alt="alternate-user-logo" />
            )}
            <span>{accountInfoData?.fundingManagerName}</span>
          </div>
        )) ||
          (isBrokerApplication && accountInfoData?.accountOwnerName && (
            <div className={cn(styles['alternate-user'])}>
              {accountInfoData.accountOwnerPictureUrl && (
                <img src={accountInfoData.accountOwnerPictureUrl} alt="alternate-user-logo" />
              )}
              <span>{accountInfoData?.accountOwnerName}</span>
            </div>
          )) ||
          'Unassigned'}
      </>
    );
  };

  useEffect(() => {
    if (!accountInfoData) return;
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfoData]);

  useEffect(() => {
    if (!companyId || !state.accountInfoData) return;

    fetchBusinessAccountInfo(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.accountInfoData, companyId]);

  useEffect(() => {
    setCheckBoxFields(accountInfoCheckboxFields(state.accountInfoData, isUserBroker));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.accountInfoData]);

  return (
    <>
      {(isLoading && (
        <div className={cn(styles['loading-container'])}>
          <Spinner size="large" />
        </div>
      )) || (
        <>
          <section className={cn(styles.section)}>
            <div className={cn(styles['edit-account-info'])}>
              <div className={cn(styles['checkbox-field'])}>
                <span>
                  {t('home:companydetails:overview:accountinfo:checkbox:testaccountlabel')}
                </span>
                <Checkbox
                  {...{
                    id: 'isTestAccount',
                    name: 'isTestAccount',
                    selected: state.accountInfoData?.isTestAccount,
                    handleChange: handleIsTestAccountChange,
                  }}
                />
              </div>
              <ReadOnly
                {...{
                  key: 'signUpDate',
                  metadata: {
                    id: 'signUpDate',
                    label: 'Sign-Up Date',
                    name: 'signUpDate',
                    value: Boolean(state.accountInfoData.createdDate)
                      ? convertToLocalDateFormat(state.accountInfoData.createdDate)
                      : 'missing',
                    classNames: {
                      field: cn(styles['date-field']),
                      control: cn({
                        [styles['date-field-missing']]: !Boolean(state.accountInfoData.createdDate),
                      }),
                    },
                  },
                  errors: [],
                }}
              />
              <Field
                key={'overview-accountinfo-account-status'}
                metadata={{
                  id: 'overview-accountinfo-account-status',
                  name: 'companyAccountStatus',
                  label: 'Account status',
                  value: state.accountInfoData?.companyAccountStatus || '',
                  fieldType: appSpecific(FieldTypes.SELECT, FieldTypes.READONLY),
                  classNames: {
                    field: styles['field-holder'],
                    label: styles.label,
                  },
                  options: companyAccountStatusOptions,
                }}
                errors={[]}
                handleChange={handleGenericChangeEvents}
              />
              <Field
                key={'overview-accountinfo-account-status-details'}
                metadata={{
                  id: 'overview-accountinfo-account-status-details',
                  name: 'companyAccountStatusDetail',
                  label: 'Details of account status',
                  value: state.accountInfoData?.companyAccountStatusDetail || '',
                  fieldType: appSpecific(FieldTypes.TEXT, FieldTypes.READONLY),
                  classNames: {
                    field: styles['field-holder'],
                    label: styles.label,
                  },
                }}
                errors={[]}
                handleChange={handleGenericChangeEvents}
              />
              {isBrokerApplication && (
                <Field
                  key={'overview-accountinfo-referred'}
                  metadata={{
                    id: 'overview-accountinfo-referred',
                    name: 'referredBy',
                    label: 'home:companydetails:overview:accountinfo:referredby',
                    value: state.accountInfoData.referredBy || '',
                    fieldType: appSpecific(FieldTypes.TEXT, FieldTypes.READONLY),
                    classNames: {
                      field: styles['field-holder'],
                      label: styles.label,
                    },
                    autoComplete: AutoCompleteTypes.AUTOCOMPLETEOFF,
                  }}
                  errors={[]}
                  handleChange={handleGenericChangeEvents}
                />
              )}
              {features.assignUsersEnabled && (
                <UserSelect
                  label={t(
                    appSpecific(
                      'home:companydetails:overview:accountinfo:fundingmanager',
                      'home:companydetails:overview:accountinfo:accountowner'
                    )
                  )}
                  selectedUser={currentSelectedUser}
                  users={userOptions}
                  loading={isSaving}
                  onSelect={(user: APIUsersByRoleType | null) => assignUser(user)}
                  historicalData={{
                    callback: () =>
                      setHistoryModalContext(
                        appSpecific(HistoryModalEnum.Broker, HistoryModalEnum.Advisor)
                      ),
                    tooltip: t(
                      appSpecific(
                        'home:companydetails:overview:accountinfo:history:tooltips:broker',
                        'home:companydetails:overview:accountinfo:history:tooltips:advisor'
                      )
                    ),
                  }}
                  canUnassign={appSpecific(true, false)}
                />
              )}
              {features.assignUsersEnabled && (
                <ReadOnly
                  {...{
                    key: 'read-only-assigned-user',
                    metadata: {
                      id: 'overview-accountinfo-live-opportunities',
                      name: 'overview-accountinfo-live-opportunities',
                      label: appSpecific(
                        'home:companydetails:overview:accountinfo:accountowner',
                        'home:companydetails:overview:accountinfo:fundingmanager'
                      ),
                      toolTip: true,
                      toolTipContent: t(
                        appSpecific(
                          'home:applicationsClient:tooltips:broker:accountowner',
                          'home:applicationsClient:tooltips:broker:fundingmanager'
                        )
                      ),
                      fieldType: FieldTypes.READONLY,
                      classNames: {
                        field: styles['field-holder'],
                        label: styles.label,
                        labelToolTip: styles['label-tooltip'],
                        icon: 'info-icon',
                      },
                    },
                    children: alternateUserReadOnly(),
                    historicalData: {
                      callback: () =>
                        setHistoryModalContext(
                          appSpecific(HistoryModalEnum.Advisor, HistoryModalEnum.Broker)
                        ),
                      tooltip: t(
                        appSpecific(
                          'home:companydetails:overview:accountinfo:history:tooltips:advisor',
                          'home:companydetails:overview:accountinfo:history:tooltips:broker'
                        )
                      ),
                    },
                    errors: [],
                    className: {
                      field: styles['field-holder'],
                      label: styles['edit-label'],
                    },
                  }}
                />
              )}
              <ReadOnly
                {...{
                  key: 'lastAssignmentDate',
                  metadata: {
                    id: 'lastAssignmentDate',
                    label: 'Last Assignment Date',
                    name: 'lastAssignmentDate',
                    value: lastAssignmentDate() ?? 'missing',
                    classNames: {
                      field: cn(styles['date-field']),
                      control: cn({ [styles['date-field-missing']]: !lastAssignmentDate() }),
                    },
                  },
                  errors: [],
                }}
              />
              {isBrokerApplication &&
                checkBoxFields.map((data: any) => {
                  return (
                    <div key={data.id} className={cn(styles['checkbox-field'])}>
                      <span>{data.label}</span>
                      <Checkbox
                        {...{
                          id: data.id,
                          name: data.name,
                          selected: data.value,
                          handleChange: selectCheckBox,
                          disabled: data.disabled,
                        }}
                      />
                    </div>
                  );
                })}
              {visibleAccountInfoFields.map((data: FieldMetaDataPropType) => {
                return (
                  <ReadOnly
                    {...{
                      key: data.id,
                      metadata: data,
                      errors: [],
                      className: {
                        field: styles['field-holder'],
                        label: styles['edit-label'],
                      },
                    }}
                  />
                );
              })}
              {store.state.system.groupId === SWOOP_GROUP_ID &&
                utmParameters.map((data: FieldMetaDataPropType) => {
                  return (
                    <ReadOnly
                      {...{
                        key: data.id,
                        metadata: data,
                        errors: [],
                        className: {
                          field: styles['field-holder'],
                          label: styles['edit-label'],
                        },
                      }}
                    />
                  );
                })}
            </div>
            {Boolean(historyModalContext) && (
              <HistoryModal
                historyState={historyModalContext}
                onModalClose={setHistoryModalContext}
                companyId={companyId}
                initialTableFieldState={HistoryEntriesTable([], null)}
                tableFields={HistoryEntriesTable}
                historyApiEndpoint={getCompanyAssignmentsHistory}
                modalTitle={t(
                  'home:companydetails:overview:accountinfo:history:historymodal:title'
                )}
              />
            )}
          </section>
          <div className={cn(styles['save-container'])}>
            <Button
              id="save-funding-requirements"
              ariaLabel="save-funding-requirements"
              className={cn(styles['save-button'])}
              clickHandler={handleSubmit}
              loading={isSaving}
              disabled={!ableToSave}
              buttonStyleType={
                ableToSave ? ButtonStyleTypeEnum.PRIMARY : ButtonStyleTypeEnum.DISABLED
              }
            >
              {t('home:productdetails:documents:save')}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetails;
