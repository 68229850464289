import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IDealsFiltersProps } from './types';
import { isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';
import { Role, UserRoles } from 'config/roles';
import { getUsersByRole, QueryKeys } from '_shared/api/roles';
import useAutoFocus from '_shared/hooks/useAutoFocus';
import { useAppState } from 'store';
import UserToggle from 'components/userToggle';
import Spinner from 'components/spinner';
import styles from 'pages/deals/assets/filters.module.scss';
import StatusToggle from 'components/statusToggle';
import Switch from 'components/switch';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import CreateDealModal from '../createDealModal';
import { UPDATE_DEALS } from 'store/deals/types';
import { dealsStatusList } from '_shared/utils/constants';
import Tooltip from 'components/tooltip';
import AdvancedDatePicker from 'components/field/advancedDatePicker';
import features from 'config/features';
import CreatorFilter from '../CreatorFilter';
import { CreatorType } from 'pages/deals/types';
import { IContext } from 'store/types';
import DealSubTypeFilter from '../dealSubTypeFilter/DealSubTypeFilter';
import { useQuery } from '@tanstack/react-query';

const creatorFilterOptions = [
  {
    label: 'Brokers',
    value: CreatorType.Broker,
  },
  {
    label: 'Advisors',
    value: CreatorType.Advisor,
  },
  {
    label: 'Client',
    value: CreatorType.Client,
  },
];

const DealsFilters: React.FC<IDealsFiltersProps> = ({
  filters,
  companyId,
  isSearching,
  isClientSpecificDeals,
  isCreateDealOpen,
  setIsCreateDealOpen,
  onClearFilters,
  onOwnerIdsChange,
  onStatusesChange,
  onHideCompletedChange,
  onCreatorTypeChange,
  onDealSubTypeChange,
  displayPipelineView,
  handleOnSearchDeals,
  handleTotalCount,
  handeUpdateLastProgressedFilters,
  hidePipeline,
  subtypeOptions,
}: IDealsFiltersProps) => {
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const { t } = useTranslation();

  const includesCompletedStatuses = () =>
    globalState.deals?.selectedStatus?.includes(dealsStatusList['ClosedWon'].value) ||
    globalState.deals?.selectedStatus?.includes(dealsStatusList['ClosedLost'].value);

  const currentRoleId =
    globalState.system?.roles?.find((role: Role) => {
      let roleName;
      if (isBrokerApplication) {
        roleName = UserRoles.BROKER;
      } else if (isAdvisorApplication) {
        roleName = UserRoles.ADVISOR;
      }
      return role.name === roleName;
    })?.id ?? '';

  const { data: usersData } = useQuery({
    queryKey: [QueryKeys.GetUserByRole, currentRoleId],
    queryFn: () => getUsersByRole(currentRoleId),
    staleTime: Infinity,
  });

  const handleSetSelectedUsers = useCallback(
    (selectedUserIds: string[]) => {
      handleTotalCount(0);

      onOwnerIdsChange(selectedUserIds);

      sendAmplitudeData(AmplitudeTrackingEnum.applicationsownerfilter, {
        selected: selectedUserIds.length.toString(),
      });
    },
    [onOwnerIdsChange, handleTotalCount]
  );

  const handleSetSelectedStatus = useCallback(
    (selectedStatuses: string[]) => {
      handleTotalCount(0);

      onStatusesChange(selectedStatuses);

      globalDispatch({
        type: UPDATE_DEALS,
        payload: {
          ...globalState.deals,
          selectedStatus: selectedStatuses,
          hideCompletedDeals: false,
        },
      });

      sendAmplitudeData(AmplitudeTrackingEnum.applicationsstatusfilter, {
        selected: selectedStatuses.length.toString(),
        statusSelected: selectedStatuses,
      });
    },
    [globalDispatch, handleTotalCount, globalState.deals]
  );

  const handleCreatorFilterChange = useCallback(
    (selectedCreatorTypes: CreatorType[]) => {
      globalDispatch({
        type: UPDATE_DEALS,
        payload: {
          ...globalState.deals,
          selectedCreatorTypes,
        },
      });

      onCreatorTypeChange(selectedCreatorTypes);

      sendAmplitudeData(AmplitudeTrackingEnum.applicationscreatorfilter, {
        selected: selectedCreatorTypes.length.toString(),
        selectedCreatorTypes: selectedCreatorTypes.map((creatorType) => creatorType.toString()),
      });
    },
    [globalDispatch, globalState.deals]
  );

  const handleDealSubTypeFilterChange = useCallback(
    (selectedDealSubTypes: string[]) => {
      globalDispatch({
        type: UPDATE_DEALS,
        payload: {
          ...globalState.deals,
          ...selectedDealSubTypes,
        },
      });

      onDealSubTypeChange(selectedDealSubTypes);

      sendAmplitudeData(AmplitudeTrackingEnum.applicationsdealsubtypefilter, {
        selected: selectedDealSubTypes.length.toString(),
        selectedDealSubTypes: selectedDealSubTypes,
      });
    },
    [globalDispatch, globalState.deals]
  );

  const openCreateDealModal = useCallback(() => {
    setIsCreateDealOpen(true);
    sendAmplitudeData(AmplitudeTrackingEnum.startapplication);
  }, [setIsCreateDealOpen]);

  const toggleHideCompleted = () => {
    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        hideCompletedDeals: !globalState.deals.hideCompletedDeals,
      },
    });

    onHideCompletedChange(!globalState.deals.hideCompletedDeals);

    sendAmplitudeData(AmplitudeTrackingEnum.applicationscompletedtoggle, {
      status: globalState.deals.hideCompletedDeals ? 'Off' : 'On',
    });
  };

  const formatToggleUserList = () => {
    return usersData?.data.results
      .map((user: any) => {
        return {
          id: user.securityId,
          name: user.fullName,
          picture: user.picture,
        };
      })
      .sort((a: any, _: any) => (a.id === globalState.system.currentUser?.authId ? -1 : 1));
  };

  const formatToggleStatusList = () => {
    return Object.keys(dealsStatusList).map((key) => {
      return {
        id: key,
        name: key,
        label: dealsStatusList[key].label,
        value: key,
      };
    });
  };

  const handleClearFilters = () => {
    onClearFilters();
    globalDispatch({
      type: UPDATE_DEALS,
      payload: {
        ...globalState.deals,
        selectedStatus: [],
        selectedCreatorTypes: [],
        lastProgressedFilterType: '',
        lastProgressedFrom: null,
        lastProgressedTo: null,
      },
    });
    sendAmplitudeData(AmplitudeTrackingEnum.applicationsclearfilter);
  };

  const searchInputRef = useAutoFocus();

  return (
    <>
      <div className={cn(styles['filters-container'])}>
        <div className={cn(styles['quick-filters-container'])}>
          {(features.assignUsersEnabled &&
            usersData?.data?.results.length &&
            isBrokerApplication && (
              <UserToggle
                label={t('home:deals:filter:usertoggle')}
                users={formatToggleUserList()}
                selectedUsers={filters.ownerIds}
                setSelectedUsers={handleSetSelectedUsers}
                placeholder={'Selected'}
                disabled={isSearching}
              />
            )) ||
            null}
          <div className={cn(styles['status-filter-container'])}>
            {t('home:deals:filter:statustoggle')}
            <StatusToggle
              statuses={formatToggleStatusList()}
              selectedStatus={filters.statuses || []}
              setSelectedStatus={handleSetSelectedStatus}
              disabled={isSearching}
            />
          </div>
          <div className={cn(styles['status-filter-container'])}>
            {t('home:deals:filter:creatorfilter')}
            <CreatorFilter
              creatorTypes={creatorFilterOptions}
              selectedCreatorTypes={filters.creatorTypes}
              onChange={handleCreatorFilterChange}
              disabled={isSearching}
            />
          </div>
          <div className={cn(styles['status-filter-container'])}>
            {t('home:deals:filter:dealSubTypeFilter')}
            <DealSubTypeFilter
              dealSubTypes={subtypeOptions}
              selectedDealSubType={filters.subtypes}
              onChange={handleDealSubTypeFilterChange}
              disabled={isSearching}
            />
          </div>
          <div className={cn(styles['date-filter-container'])}>
            <label className={cn(styles['date-filter-label'])}>
              {t('home:applications:filter:lastmodifiedfilterheader')}
              <Tooltip content={t('home:applications:filter:lastmodifiedtooltip')}>
                <span className={cn('material-icons', styles['tooltip-icon'])}>help</span>
              </Tooltip>
            </label>
            <AdvancedDatePicker
              placeholder={t('home:applications:filter:lastmodifiedplaceholder')}
              showCalendarIcon={true}
              shouldCloseOnSelect={false}
              filters={filters}
              handeUpdateLastProgressedFilters={handeUpdateLastProgressedFilters}
            />
          </div>
          <div className={cn(styles['clear-filters-button'])}>
            <button onClick={handleClearFilters}>Clear</button>
          </div>
        </div>
        <div className={cn(styles['search-and-toggles-container'])}>
          <div className={cn(styles['toggle'])}>
            <Switch
              {...{
                id: 'toggle-only-active',
                key: `toggle-only-active-${filters.hideCompleted?.toString()}`,
                onChange: () => toggleHideCompleted(),
                disabled: includesCompletedStatuses() || isSearching,
                checked: filters.hideCompleted,
                label: t('home:deals:filter:hidecompletedlabel'),
                name: 'toggle-only-active',
                tooltip: t('home:deals:filter:hidecompletedtooltip'),
              }}
            />
          </div>
          <button
            className={cn(styles['pipeline-view-button'], {
              [styles['selected']]: hidePipeline,
            })}
            onClick={() => displayPipelineView()}
            data-label={t('home:deals:filter:hidepipelinetooltip')}
          >
            <i className={cn('material-icons')}>bar_chart</i>
          </button>
          {!isClientSpecificDeals ? (
            <span className={cn(styles.search)}>
              <input
                type="text"
                tabIndex={1}
                className={cn(styles.input)}
                placeholder={t('home:deals:filter:searchdealsplaceholder')}
                onChange={handleOnSearchDeals}
                defaultValue={filters.companyName}
                ref={searchInputRef}
                data-cy="deals-search-input"
              />
              {isSearching && (
                <span className={cn(styles.loading)}>
                  <Spinner size="small" />
                </span>
              )}
              {!isSearching && <i className={cn('material-icons', styles.icon)}>search</i>}
            </span>
          ) : (
            <div className={cn(styles['button-container'])}>
              {isBrokerApplication && (
                <Button
                  id="start-deal-button"
                  ariaLabel="start-deal-button"
                  clickHandler={openCreateDealModal}
                  className={cn(styles['start-deal-button'])}
                  buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
                >
                  {t('home:deals:filter:startnewdealbutton')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Delay rendering of the modal to prevent unnecessary getUsers call */}
      {isCreateDealOpen && (
        <CreateDealModal
          companyId={companyId}
          isCreateDealOpen={isCreateDealOpen}
          setIsCreateDealOpen={setIsCreateDealOpen}
        />
      )}
    </>
  );
};

export default DealsFilters;
