import cn from 'classnames';
import styles from './newActivity.module.scss';
import { getNewActivityLabelById } from '../utils';
import { ACTIVITY_CATEGORY } from '../types';

type NewActivityHeaderPropTypes = {
  toggleIsCollapsed: () => void;
  handleDeleteActivity: () => void;
  activityCategory: ACTIVITY_CATEGORY;
};

const NewActivityHeader = ({
  toggleIsCollapsed,
  handleDeleteActivity,
  activityCategory,
}: NewActivityHeaderPropTypes) => {
  return (
    <div className={cn(styles['header'])}>
      <span>{getNewActivityLabelById(activityCategory)}</span>
      <div className={cn(styles['actions'])}>
        <button onClick={toggleIsCollapsed} className={'material-icons'}>
          minimize
        </button>
        <button onClick={handleDeleteActivity} className={'material-icons'}>
          clear
        </button>
      </div>
    </div>
  );
};

export default NewActivityHeader;
