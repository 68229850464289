import React from 'react';
import styles from './TableRow.module.scss';

type TableRowProps = {
  children: React.ReactNode;
  onClick?: any;
};

const TableRow: React.FC<TableRowProps> = ({ children, onClick }) => {
  return (
    <tr className={styles['table-row']} onClick={onClick}>
      {children}
    </tr>
  );
};

export default TableRow;
