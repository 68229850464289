import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './CompanySavingsProducts.module.scss';
import SavingsProductsMenu from './components/Menu';
import ResearchAndDevelopment from './pages/researchAndDevelopment';
import BusinessSavingsAccounts from './pages/businessSavingsAccounts';
import CreditScoreImprovements from './pages/creditScoreImprovements';
import ForeignExchange from './pages/foreignExchange';
import Pension from './pages/pension';
import BusinessInsurance from './pages/businessInsurance';
import InstantVatAdvance from './pages/instantVatAdvance';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

const SavingsProducts = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const navigate = useNavigate();

  const savingsProducts: { [key: string]: { component: any; label: string; type: string } } = {
    'research-and-development': {
      component: <ResearchAndDevelopment />,
      label: 'Research & development',
      type: 'tools',
    },
    'instant-vat-advance': {
      component: <InstantVatAdvance />,
      label: 'Instant VAT advance',
      type: 'tools',
    },
    'credit-score-improvements': {
      component: <CreditScoreImprovements />,
      label: 'Credit score improvements',
      type: 'creditHealth',
    },
    'business-savings-accounts': {
      component: <BusinessSavingsAccounts />,
      label: 'Business savings accounts',
      type: 'banking',
    },
    pension: {
      component: <Pension />,
      label: 'Pension',
      type: 'banking',
    },
    'foreign-exchange': {
      component: <ForeignExchange />,
      label: 'Foreign exchange',
      type: 'dayToDaySavings',
    },
    'business-insurance': {
      component: <BusinessInsurance />,
      label: 'Business insurance',
      type: 'insurance',
    },
  };

  const handleSelectedProduct = (selectedProduct: string) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('product', selectedProduct);

    navigate(`?${newSearchParams.toString()}`);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedProduct = queryParams.get('product');

  useEffect(() => {
    if (selectedProduct && Object.keys(savingsProducts).includes(selectedProduct)) {
      setSelectedMenuItem(selectedProduct);
    } else if (!selectedMenuItem) {
      setSelectedMenuItem('research-and-development');
    }
  });

  // reduce to { type: [ { id, label }, ... ] }
  const menuItemsByType: { [key: string]: { id: string; label: string }[] } = Object.keys(
    savingsProducts
  ).reduce((acc, key) => {
    const { label, type } = savingsProducts[key];

    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push({ id: key, label });
    return acc;
  }, {} as Record<string, { id: string; label: string }[]>);

  return (
    <div className={cn('wrapper', styles.wrapper)} data-testid="savings-products-page">
      <SavingsProductsMenu
        menuItems={menuItemsByType}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={handleSelectedProduct}
      />
      {selectedMenuItem && savingsProducts[selectedMenuItem].component}
    </div>
  );
};

export default SavingsProducts;
