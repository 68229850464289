import React, { useReducer, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CompanyDetailsContext } from 'pages/companyDetails/store/companyDetails.reducer';
import {
  getFinancialInformationTab,
  getFinancialInfoProfitLossStatement,
  getFinancialInfoBalanceSheet,
  getActualCashflowForecast,
  getCashflowShortterm,
} from '_shared/api/financialInfo';
import styles from './assets/financialInformation.module.scss';
import {
  financialOverviewFields,
  profitLossField,
  balanceSheetField,
  cashFlowProjectionFields,
} from './fields/financialinformation.field';
import {
  financialInfoReducer,
  FinancialInfoActionType,
} from './store/financialInformation.reducer';
import Table from 'components/table';
import { GraphTypesEnum } from './types';
import { LineGraph } from 'components/graph/LineGraph';
import { BarGraph } from 'components/graph/BarGraph';
import { PercentageGraph } from 'components/graph/PercentageGraph';
import ErrorComponent from 'components/error';
import { getCreditReports } from '_shared/api/businesses';
import { creditPassportValues, monthFilterOptions } from '_shared/utils/constants';
import { roundTwoDecimal } from '_shared/utils/rounding';
import features from 'config/features';
import Accordion from 'components/accordion';
import { convertToCurrency } from '_shared/utils/currency';
import { convertToLocalDateFormat } from '_shared/utils/date';
import Select from 'components/field/select';
import CashflowForecastingGraph from './components/cashflowForecastingGraph';
import Spinner from 'components/spinner';

const FinancialInformation: React.FC = () => {
  const location = useLocation();
  const { id: companyId } = useParams();
  const initialLocalState = {
    loading: false,
    from: location.state || { from: { pathname: '/' } },
    financialInfo: { financialInfoCards: financialOverviewFields([]), loading: true, error: false },
    profitLoss: {
      profitLossField: profitLossField({}),
      loading: true,
      error: false,
      isEmpty: false,
    },
    balanceSheet: {
      balanceSheetField: balanceSheetField({}),
      loading: true,
      error: false,
      isEmpty: false,
    },
    creditReports: {
      creditPassportBadgeUrl: undefined,
      loading: true,
    },
    cashflowForecast: {
      data: [],
      fields: [],
      loading: true,
      error: false,
      filter: {
        actualMonths: 12,
        forecastMonths: 12,
      },
    },
    cashflowShortterm: {
      data: {
        bills: 0,
        cashToday: 0,
        invoices: 0,
        lastAvailableDate: '',
      },
      loading: true,
      error: false,
    },
  };

  const { t } = useTranslation();
  const [localState, localDispatch] = useReducer(financialInfoReducer, initialLocalState);
  const { state } = useContext(CompanyDetailsContext);

  const financialInformation = async (id: string) => {
    try {
      const [
        { data: income },
        { data: grossProfit },
        { data: profit },
        { data: interestCoverage },
        { data: equityRatio },
        { data: currentRatio },
      ] = await getFinancialInformationTab(id);

      profit?.financialProfits?.reverse();
      income?.financialIncomes?.reverse();

      localDispatch({
        type: FinancialInfoActionType.SET_FINANCIAL_INFO_CARDS,
        payload: {
          financialInfoCards: financialOverviewFields({
            income,
            grossProfit,
            profit,
            interestCoverage,
            equityRatio,
            currentRatio,
          }),
          loading: false,
        },
      });
    } catch (e) {
      localDispatch({
        type: FinancialInfoActionType.SET_FINANCIAL_INFO_CARDS,
        payload: { loading: false, error: true },
      });
    }
  };

  const financialInfoProfitLossStatement = async (id: string) => {
    try {
      const { data } = await getFinancialInfoProfitLossStatement(id);
      data?.financialProfitAndLoss?.reverse();
      localDispatch({
        type: FinancialInfoActionType.SET_PROFIT_LOSS_FIELD,
        payload: {
          profitLossField: profitLossField(data?.financialProfitAndLoss),
          loading: false,
          isEmpty:
            (Array.isArray(data?.financialProfitAndLoss) &&
              Boolean(data?.financialProfitAndLoss.length === 0)) ||
            false,
        },
      });
    } catch (e) {
      localDispatch({
        type: FinancialInfoActionType.SET_PROFIT_LOSS_FIELD,
        payload: { loading: false, error: true },
      });
    }
  };

  const financialInfoBalanceSheet = async (id: string) => {
    try {
      const { data } = await getFinancialInfoBalanceSheet(id);
      data?.financialBalanceSheets?.reverse();
      localDispatch({
        type: FinancialInfoActionType.SET_BALANCE_SHEET_FIELD,
        payload: {
          balanceSheetField: balanceSheetField(data?.financialBalanceSheets),
          loading: false,
          isEmpty:
            (Array.isArray(data?.financialBalanceSheets) &&
              Boolean(data?.financialBalanceSheets.length === 0)) ||
            false,
        },
      });
    } catch (e) {
      localDispatch({
        type: FinancialInfoActionType.SET_BALANCE_SHEET_FIELD,
        payload: { loading: false, error: true },
      });
    }
  };

  const creditReports = async (id: string) => {
    localDispatch({
      type: FinancialInfoActionType.SET_CREDIT_REPORTS,
      payload: {
        loading: true,
      },
    });
    try {
      const { data } = await getCreditReports(id);

      const ratingLabel = data?.rating?.fullScore?.label;
      const pd = data?.rating?.fullScore?.pd;
      const rating =
        ratingLabel &&
        creditPassportValues.find(({ label }: { label: string }) => label === ratingLabel);

      localDispatch({
        type: FinancialInfoActionType.SET_CREDIT_REPORTS,
        payload: {
          loading: false,
          creditPassportBadgeUrl: data?.badge?.badgeUrl,
          descriptions: { ...rating, pd },
        },
      });
    } catch (e: any) {
      localDispatch({
        type: FinancialInfoActionType.SET_CREDIT_REPORTS,
        payload: {
          loading: false,
          error: e?.response?.status !== 404,
        },
      });
    }
  };

  const parsePdNumber = (pd: number | string | undefined): number | string => {
    if (!pd) return 'N/A';
    return `${roundTwoDecimal(+pd * 100)}%`;
  };

  const emptyComponent = (message: string) => (
    <div className={classNames(styles['empty-message'])}>{message}</div>
  );

  const fetchCashFlowForecast = async () => {
    if (!companyId) return;

    localDispatch({
      type: FinancialInfoActionType.SET_ACTUAL_CASHFLOW_FORECAST,
      payload: {
        loading: true,
      },
    });

    try {
      const { data } = await getActualCashflowForecast(companyId, {
        actualMonths: localState.cashflowForecast.filter.actualMonths,
        forecastMonths: localState.cashflowForecast.filter.forecastMonths,
      });

      localDispatch({
        type: FinancialInfoActionType.SET_ACTUAL_CASHFLOW_FORECAST,
        payload: {
          data: data,
          fields: cashFlowProjectionFields(data),
          loading: false,
          error: false,
        },
      });
    } catch (e) {
      console.error(e);
      localDispatch({
        type: FinancialInfoActionType.SET_ACTUAL_CASHFLOW_FORECAST,
        payload: {
          error: true,
          loading: false,
        },
      });
    }
  };

  const fetchShortTermCashFlow = async () => {
    if (!companyId) return;

    localDispatch({
      type: FinancialInfoActionType.SET_CASHFLOW_SHORTTERM,
      payload: {
        loading: true,
      },
    });

    try {
      const { data } = await getCashflowShortterm(companyId);

      localDispatch({
        type: FinancialInfoActionType.SET_CASHFLOW_SHORTTERM,
        payload: {
          data: data,
          loading: false,
          error: false,
        },
      });
    } catch (e) {
      console.error(e);
      localDispatch({
        type: FinancialInfoActionType.SET_CASHFLOW_SHORTTERM,
        payload: {
          error: true,
          loading: false,
        },
      });
    }
  };

  useEffect(() => {
    if (!companyId) {
      return () => {};
    }

    fetchShortTermCashFlow();
    financialInformation(companyId);
    financialInfoProfitLossStatement(companyId);
    financialInfoBalanceSheet(companyId);

    if (features.creditReportsEnabled) {
      creditReports(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCashFlowForecast();
  }, [
    companyId,
    localState.cashflowForecast.filter.actualMonths,
    localState.cashflowForecast.filter.forecastMonths,
  ]);

  const selectFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    localDispatch({
      type: FinancialInfoActionType.SET_CASHFLOW_FORECAST_FILTER,
      payload: {
        [name]: value,
      },
    });
  };

  const financeErrorMessage = () => {
    return <ErrorComponent message={t('home:companydetails:finance:errormessage')} />;
  };

  const financialInfoItems = [
    {
      title: t('home:companydetails:finance:cashflowforecasting:title'),
      collapsedByDefault: false,
      content: (localState.cashflowShortterm.loading && (
        <div className={classNames(styles['loading-container'])}>
          <Spinner size="large" />
        </div>
      )) ||
        (localState.cashflowForecast.error && financeErrorMessage()) || (
          <div className={classNames(styles['cash-flow-forecasting'])}>
            <div>
              <span className={classNames(styles['cash-flow-sub-title'])}>
                {t('home:companydetails:finance:cashflowforecasting:subtitle')}
              </span>
            </div>
            <div>
              <h3 className={classNames('sw-h3', styles['short-term-header'])}>
                {t('home:companydetails:finance:cashflowforecasting:shorttermcashflow:title')}
              </h3>
              <div className={classNames(styles['short-term-container'])}>
                <div className={classNames(styles['cashflow-column'])}>
                  <span className={classNames(styles['column-header'])}>
                    {t(
                      'home:companydetails:finance:cashflowforecasting:shorttermcashflow:cashavailable'
                    )}
                  </span>
                  <span className={classNames(styles['column-value'])}>
                    {convertToCurrency(localState.cashflowShortterm.data?.cashToday)}
                  </span>
                  <span className={classNames(styles['column-detail'])}>
                    {localState.cashflowShortterm.data?.lastAvailableDate &&
                      `As of ${convertToLocalDateFormat(
                        localState.cashflowShortterm.data?.lastAvailableDate
                      )}`}
                  </span>
                </div>
                <div className={classNames(styles['cashflow-column'])}>
                  <span className={classNames(styles['column-header'])}>
                    {t(
                      'home:companydetails:finance:cashflowforecasting:shorttermcashflow:invoices'
                    )}
                  </span>
                  <span className={classNames(styles['column-value'])}>
                    {convertToCurrency(localState.cashflowShortterm.data?.invoices)}
                  </span>
                  <span className={classNames(styles['column-detail'])}>
                    {localState.cashflowShortterm.data?.invoiceNumber &&
                      `${localState.cashflowShortterm.data?.invoiceNumber} invoices`}
                  </span>
                </div>
                <div className={classNames(styles['cashflow-column'])}>
                  <span className={classNames(styles['column-header'])}>
                    {t('home:companydetails:finance:cashflowforecasting:shorttermcashflow:bills')}
                  </span>
                  <span className={classNames(styles['column-value'])}>
                    {convertToCurrency(localState.cashflowShortterm.data?.bills)}
                  </span>
                  <span className={classNames(styles['column-detail'])}>
                    {localState.cashflowShortterm.data?.billsNumber &&
                      `${localState.cashflowShortterm.data?.billsNumber} bills`}
                  </span>
                </div>
              </div>
            </div>
            <div className={classNames(styles['actual-projected-cashflow'])}>
              <div className={classNames(styles['header-and-filters'])}>
                <div className={classNames(styles['projected-cashflow-header-container'])}>
                  <span className={classNames(styles['projected-cashflow-header'])}>
                    {t(
                      'home:companydetails:finance:cashflowforecasting:actualprojectedcashflow:title'
                    )}
                  </span>
                  <span className={classNames(styles['cashflow-legend'])}>
                    <span className={classNames(styles['cash-in'])}>
                      {t(
                        'home:companydetails:finance:cashflowforecasting:actualprojectedcashflow:cashin'
                      )}
                    </span>
                    <span className={classNames(styles['cash-out'])}>
                      {t(
                        'home:companydetails:finance:cashflowforecasting:actualprojectedcashflow:cashout'
                      )}
                    </span>
                  </span>
                </div>
                <div className={classNames(styles['cashflow-forecast-filters'])}>
                  <span className={classNames(styles['select-container'])}>
                    <span>
                      {t(
                        'home:companydetails:finance:cashflowforecasting:actualprojectedcashflow:historyfilter'
                      )}
                    </span>
                    <Select
                      {...{
                        metadata: {
                          id: 'actual-months-select',
                          name: 'actualMonths',
                          options: monthFilterOptions,
                          value: localState.cashflowForecast.filter.actualMonths,
                          classNames: { value: classNames(styles['filter-select']) },
                        },
                        errors: [],
                        handleChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
                          selectFilter(event),
                      }}
                    />
                  </span>
                  <span className={classNames(styles['select-container'])}>
                    <span>
                      {t(
                        'home:companydetails:finance:cashflowforecasting:actualprojectedcashflow:forecastfilter'
                      )}
                    </span>
                    <Select
                      {...{
                        metadata: {
                          id: 'forecast-months-select',
                          name: 'forecastMonths',
                          options: monthFilterOptions,
                          value: localState.cashflowForecast.filter.forecastMonths,
                          classNames: { value: classNames(styles['filter-select']) },
                        },
                        errors: [],
                        handleChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
                          selectFilter(event),
                      }}
                    />
                  </span>
                </div>
              </div>
              <CashflowForecastingGraph data={localState.cashflowForecast?.data} />
              <div className={classNames(styles['cashflow-projection-table-container'])}>
                <h3 className={classNames(styles['cashflow-table-title'])}>
                  {t(
                    'home:companydetails:finance:cashflowforecasting:cashflowactualprojection:title'
                  )}
                </h3>
                <Table
                  {...localState.cashflowForecast.fields}
                  loading={localState.cashflowForecast.loading}
                />
              </div>
            </div>
          </div>
        ),
    },
    {
      title: t('home:companydetails:finance:insights:title'),
      collapsedByDefault: true,
      content: (
        <div className={classNames(styles['overview-cards'])}>
          {localState?.financialInfo?.financialInfoCards?.map((card: any) => {
            return (
              <div key={card.id} className={classNames(styles['card'])}>
                <span className={classNames(styles['card-header'])}>{t(card.label)}</span>
                {((card.graphData?.length || card.value !== undefined) && (
                  <div className={classNames(styles['card-graph-holder'])}>
                    {card.graph === GraphTypesEnum.BAR && <BarGraph data={card.graphData} />}
                    {card.graph === GraphTypesEnum.LINE && <LineGraph data={card.graphData} />}
                    {card.graph === GraphTypesEnum.PERCENTAGE && (
                      <>
                        <PercentageGraph
                          value={card.value}
                          percentage={card.percentage}
                          isPositive={card.isPositive}
                        />
                        <span
                          className={classNames(
                            styles['graph-message'],
                            (card.isPositive && styles.positive) || styles.negative
                          )}
                        >
                          {t(card.message)}
                        </span>
                      </>
                    )}
                  </div>
                )) || (
                  <div className={classNames(styles['card-graph-error-holder'])}>
                    {financeErrorMessage()}
                  </div>
                )}
                <span className={classNames(styles['card-description'])}>
                  {t(card.description)}
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: t('home:companydetails:finance:profitloss:title'),
      collapsedByDefault: true,
      content: ((localState.profitLoss.isEmpty || localState.profitLoss.error) &&
        financeErrorMessage()) || (
        <>
          <span className={classNames(styles['beta-warning-label'])}>
            {t('home:companydetails:finance:profitloss:betawarning')}
            <span className={classNames('material-icons', styles['warning-icon'])}>warning</span>
          </span>
          <Table
            {...localState.profitLoss.profitLossField}
            loading={localState.profitLoss.loading}
          />
        </>
      ),
    },
    {
      title: t('home:companydetails:finance:balancesheet:title'),
      collapsedByDefault: true,
      content: ((localState.balanceSheet.isEmpty || localState.balanceSheet.error) &&
        financeErrorMessage()) || (
        <>
          <span className={classNames(styles['beta-warning-label'])}>
            {t('home:companydetails:finance:profitloss:betawarning')}
            <span className={classNames('material-icons', styles['warning-icon'])}>warning</span>
          </span>
          <Table
            {...localState.balanceSheet.balanceSheetField}
            loading={localState.balanceSheet.loading}
          />
        </>
      ),
    },
    features.creditReportsEnabled && {
      title: t('home:companydetails:overview:creditreports:title'),
      collapsedByDefault: true,
      content: (
        <div className={classNames(styles['credit-reports'])}>
          {localState.creditReports.creditPassportBadgeUrl && (
            <div className={classNames(styles['credit-report-container'])}>
              <img
                src={localState.creditReports.creditPassportBadgeUrl}
                alt="credit report badge"
              />
              <div className={classNames(styles['credit-report-description'])}>
                <span className={classNames(styles['description-heading'])}>
                  <span>Credit Quality: </span>
                  <span>{localState.creditReports?.descriptions?.gradeAsPerCreditPbaassport}</span>
                </span>
                <span className={classNames(styles['description-subheading'])}>
                  {localState.creditReports?.descriptions?.yourGradeExplainer}
                </span>
                <span className={classNames(styles['description'])}>
                  <span>Financial Assessment: </span>
                  <span>{localState.creditReports?.descriptions?.financialAssessment}</span>
                </span>
                <span className={classNames(styles['description'])}>
                  <span>Behavioural Assessment: </span>
                  <span>{localState.creditReports?.descriptions?.behaviouralAssessment}</span>
                </span>
                <span className={classNames(styles['description'])}>
                  <span>Probability of Default: </span>
                  <span>{parsePdNumber(localState.creditReports?.descriptions?.pd)}</span>
                </span>
              </div>
            </div>
          )}
          {!localState.creditReports.creditPassportBadgeUrl && (
            <ErrorComponent
              message={t('home:companydetails:overview:creditreports:notlinked', {
                companyName: state.company?.data?.companyName,
              })}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={classNames(styles['financial-info'])} data-testid="financial-information">
      <Accordion items={financialInfoItems} compact />
    </div>
  );
};

export default FinancialInformation;
