import { convertToLocalDateFormat } from '_shared/utils/date';
import styles from 'pages/companyOverview/assets/companyOverview.module.scss';
import { CompanyOwnerType, DirectorType, StakeholderType } from '../types';

import i18next from 'i18next';
import { TablePropType } from 'components/table/type';
import { convertToCurrency } from '_shared/utils/currency';

const defaultCellStyles = {
  cell: styles['tr-cell'],
  value: styles['td-value'],
};

const defaultHeaderStyles = {
  header: styles['th-header'],
  value: styles['header-label'],
};

const directResignationDateSort = (a: DirectorType, b: DirectorType): any => {
  if (!a.resignedDate && b.resignedDate) {
    return -2;
  }

  if (a.resignedDate && !b.resignedDate) {
    return 2;
  }

  if (!a.resignedDate && !b.resignedDate) {
    return a.directorName > b.directorName ? 1 : -1;
  }
  return (a?.resignedDate && b?.resignedDate && a?.resignedDate > b.resignedDate && -1) || 1;
};

export function directorFields(directors: Array<DirectorType>): TablePropType {
  directors.sort(directResignationDateSort);

  return {
    header: [
      {
        id: 'director-name',
        value: i18next.t('home:companydetails:overview:people:name'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'director-dob',
        value: i18next.t('home:companydetails:overview:people:dob'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'director-appointments',
        value: i18next.t('home:companydetails:overview:people:appointments'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'director-flag',
        value: i18next.t('home:companydetails:overview:people:flag'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'director-resignation-date',
        value: i18next.t('home:companydetails:overview:people:resignationdate'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'director-source',
        value: i18next.t('home:companydetails:overview:people:source'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t('home:companydetails:overview:people:emptystate'),
    items: directors.map((director: DirectorType) => {
      return [
        {
          id: `${director?.directorName}-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: director?.directorName,
        },
        {
          id: `${director?.directorName}-dob`,
          classname: {
            ...defaultCellStyles,
          },
          default: convertToLocalDateFormat(director?.directorDateOfBirth),
        },
        {
          id: `${director?.directorName}-appointments`,
          classname: {
            ...defaultCellStyles,
          },
          default: director?.numberOfAppointments,
        },
        {
          id: `${director?.directorName}-flag`,
          classname: {
            ...defaultCellStyles,
            value: styles['director-flag'],
            icon: (director.flag && styles.negative) || '',
          },
          tooltip:
            director.flag &&
            i18next.t('home:companydetails:overview:people:flagdescription').toString(),
          ...((director.flag && { icon: 'flag' }) || {}),
        },
        {
          id: `${director?.directorName}-resignation-date`,
          classname: {
            ...defaultCellStyles,
          },
          default:
            (director?.resignedDate && convertToLocalDateFormat(director?.resignedDate)) || '-',
        },
        {
          id: `${director?.directorName}-link`,
          classname: {
            ...defaultCellStyles,
          },
          anchor: director?.link,
          default: 'Companies House',
        },
      ];
    }),
  };
}

export function stakeholderFields(
  stakeholders: Array<StakeholderType & CompanyOwnerType>,
  actionCb?: Function
): TablePropType {
  return {
    header: [
      {
        id: 'stakeholder-name',
        value: i18next.t('home:companydetails:overview:people:name'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'stakeholder-nationality',
        value: i18next.t('home:companydetails:overview:people:nationality'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'stakeholder-shareholderperc',
        value: i18next.t('home:companydetails:overview:people:shareholderpercentage'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'stakeholder-votingperc',
        value: i18next.t('home:companydetails:overview:people:votingpercentage'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'stakeholder-source',
        value: i18next.t('home:companydetails:overview:people:source'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: stakeholders.map((stakeholder: StakeholderType & CompanyOwnerType) => {
      const isCompanyOwner =
        stakeholder.hasOwnProperty('nationality') ||
        stakeholder.hasOwnProperty('name') ||
        stakeholder.hasOwnProperty('shareholdingPercentage') ||
        stakeholder.hasOwnProperty('votingPercentage');

      const id = stakeholder?.name || `${stakeholder?.firstName} ${stakeholder?.lastName}`;

      return [
        {
          id: `${id}-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: id,
        },
        {
          id: `${id}-nationality`,
          classname: {
            ...defaultCellStyles,
          },
          default: (isCompanyOwner && stakeholder?.nationality) || 'unknown',
        },
        {
          id: `${id}-shareholder-perc`,
          classname: {
            ...defaultCellStyles,
          },
          default: (isCompanyOwner && stakeholder?.shareholdingPercentage) || 'unknown',
        },
        {
          id: `${id}-voting-perc`,
          classname: {
            ...defaultCellStyles,
          },
          default: (isCompanyOwner && stakeholder?.votingPercentage) || 'unknown',
        },
        {
          id: `${id}-company-owner-link`,
          classname: {
            ...defaultCellStyles,
            ...((!isCompanyOwner && { value: styles['company-owner-link'] }) || {}),
          },
          default: (isCompanyOwner && 'Companies House') || 'Swoop Funding',
          ...((!isCompanyOwner &&
            actionCb && {
              actionCb: () => actionCb(stakeholder),
            }) ||
            {}),
        },
      ];
    }),
  };
}

export function selectedCompanyOwnerFields(data: CompanyOwnerType | null) {
  if (!data) return [];

  return [
    {
      id: 'home:companydetails:overview:people:companyowner:title',
      label: 'home:companydetails:overview:people:companyowner:title',
      value: data.title,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:fullname',
      label: 'home:companydetails:overview:people:companyowner:fullname',
      value: `${data.firstName} ${data.lastName}`,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:gender',
      label: 'home:companydetails:overview:people:companyowner:gender',
      value: data.gender,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:dateofbirth',
      label: 'home:companydetails:overview:people:companyowner:dateofbirth',
      value: convertToLocalDateFormat(data.dateOfBirth),
    },
    {
      id: 'home:companydetails:overview:people:companyowner:employmentstatus',
      label: 'home:companydetails:overview:people:companyowner:employmentstatus',
      value: data.employmentStatus,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:income',
      label: 'home:companydetails:overview:people:companyowner:income',
      value: convertToCurrency(data.income),
    },
    {
      id: 'home:companydetails:overview:people:companyowner:personalcreditlimit',
      label: 'home:companydetails:overview:people:companyowner:personalcreditlimit',
      value: convertToCurrency(data.personalCreditLimit),
    },
    {
      id: 'home:companydetails:overview:people:companyowner:residentialstatus',
      label: 'home:companydetails:overview:people:companyowner:residentialstatus',
      value: data.residentialStatus,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:buildingnumber',
      label: 'home:companydetails:overview:people:companyowner:buildingnumber',
      value: data.buildingNumber,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:street',
      label: 'home:companydetails:overview:people:companyowner:street',
      value: data.street,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:city',
      label: 'home:companydetails:overview:people:companyowner:city',
      value: data.city,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:postcode',
      label: 'home:companydetails:overview:people:companyowner:postcode',
      value: data.postcode,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:state',
      label: 'home:companydetails:overview:people:companyowner:state',
      value: data.state,
    },
    {
      id: 'home:companydetails:overview:people:companyowner:country',
      label: 'home:companydetails:overview:people:companyowner:country',
      value: data.country,
    },
  ];
}
