import React from 'react';
import styles from '../../assets/journeys.module.scss';
import cn from 'classnames';

import { FormFieldEssentials } from 'pages/companyEssentials/types';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { convertToCurrency } from '_shared/utils/currency';
import SummarisedJourney from '../summarisedJourney';

type SoftAssetsEssentialsProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

const SoftAssetsEssentials: React.FC<SoftAssetsEssentialsProps> = ({
  fetchedData,
  dateCreated,
  editDetails,
  journeyDisplayName,
  journeyName,
}) => {
  const fieldsConfig = [
    {
      fieldName: 'hasBeenProfitable',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value
              ? 'Profitable in the last 12 months'
              : 'Not profitable in the last 12 months'}
          </p>
        ),
    },
    {
      fieldName: 'softAssetType',
      render: (field: FormFieldEssentials) => {
        const conditionField = getFieldByName('assetCondition');
        const condition = conditionField?.value
          ? conditionField.value.toString().toLowerCase()
          : '';

        const assetPurchasePriceField = getFieldByName('assetPurchasePrice');
        const assetPurchasePrice = assetPurchasePriceField?.value
          ? `for ${convertToCurrency(assetPurchasePriceField.value.toString())}`
          : '';

        const assetType = field.value && field.value !== undefined ? field.value.toString() : '';
        const assetDescription =
          field.value && field.value !== undefined
            ? `Looking for ${condition} ${assetType} ${assetPurchasePrice}`
            : '';

        return (
          assetDescription !== '' && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value && field.value !== undefined ? 'check' : 'close'}
              </span>
              {assetDescription}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'IsReadyToOrder',
      render: (field: FormFieldEssentials) => {
        return (
          field.value !== undefined && (
            <p>
              <span className={cn('material-icons-outlined', styles['industry-icon'])}>
                {field.value ? 'check' : 'close'}
              </span>
              {field.value ? `Ready to order` : 'Not ready to order'}
            </p>
          )
        );
      },
    },
    {
      fieldName: 'fundsAvailableToContribute',
      render: (field: FormFieldEssentials) =>
        field.value !== undefined && (
          <p>
            <span className={cn('material-icons-outlined', styles['industry-icon'])}>
              {field.value ? 'check' : 'close'}
            </span>
            {field.value && field.value !== '0'
              ? `${convertToCurrency(field.value.toString())} available cash for asset`
              : 'Has no funds available to contribute'}
          </p>
        ),
    },
  ];

  const getFieldByName = (name: string) => fetchedData.find((field) => field.name === name);

  return <SummarisedJourney data={fetchedData} fields={fieldsConfig} />;
};

export default SoftAssetsEssentials;
