import { useEffect, useReducer } from 'react';
import { getBusinessPeopleInfo } from '_shared/api/businesses';
import { directorFields, stakeholderFields } from '../fields';
import { companyInfoFields } from '../fields/companyInfo.fields';
import { LocalActionType, overviewReducer } from '../store/companyOverview.reducer';
import { CompanyOwnerType, DirectorType } from '../types';

const OverviewHook = ({ companyId, state }: any) => {
  const initialLocalState = {
    companyInfo: {
      fields: companyInfoFields(state.company?.data || {}),
      loading: true,
      error: state.company?.error || false,
    },
    peopleInfo: {
      data: {},
      directors: directorFields([]),
      stakeholders: stakeholderFields([]),
      loading: true,
      error: false,
      showOnlycurrentDirectors: true,
      selectedCompanyOwner: null,
    },
  };

  const [localState, localDispatch] = useReducer(overviewReducer, initialLocalState);

  const businessPeopleInfo = async (id: string) => {
    try {
      const { data } = await getBusinessPeopleInfo(id);

      localDispatch({
        type: LocalActionType.SET_PEOPLE_INFO_FIELDS,
        payload: {
          data,
          loading: false,
          directors: directorFields(
            (data?.directors || []).filter((director: DirectorType) => !director.resignedDate)
          ),
          stakeholders: stakeholderFields(
            [...data?.stakeholders, ...data.companyOwners],
            setShowCompanyOwnerModal
          ),
        },
      });
    } catch (e) {
      localDispatch({
        type: LocalActionType.SET_PEOPLE_INFO_FIELDS,
        payload: {
          loading: false,
          error: true,
        },
      });
    }
  };

  const handleToggleOnlyCurrentDirectors = () => {
    const directors =
      (!localState.peopleInfo.showOnlycurrentDirectors &&
        localState.peopleInfo.data.directors.filter((director: DirectorType) => {
          return !director.resignedDate;
        })) ||
      localState.peopleInfo.data.directors;

    localDispatch({
      type: LocalActionType.SET_PEOPLE_INFO_FIELDS,
      payload: {
        showOnlycurrentDirectors: !localState.peopleInfo.showOnlycurrentDirectors,
        directors: directorFields(directors),
      },
    });
  };

  const setShowCompanyOwnerModal = (companyOwner: CompanyOwnerType | null = null) => {
    localDispatch({
      type: LocalActionType.SET_PEOPLE_INFO_FIELDS,
      payload: { selectedCompanyOwner: companyOwner },
    });
  };

  useEffect(() => {
    if (!companyId) return;

    businessPeopleInfo(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    localDispatch({
      type: LocalActionType.SET_COMPANY_INFO_FIELDS,
      payload: {
        fields: companyInfoFields(state.company?.data || {}),
      },
    });
  }, [state.company?.data]);

  return {
    localState,
    handleToggleOnlyCurrentDirectors,
    setShowCompanyOwnerModal,
  };
};

export default OverviewHook;
