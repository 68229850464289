import { TablePropType } from 'components/table/type';
export interface ITaskFilter {
  label: string;
  param: string;
  count?: number;
  value: any;
}

export type tasksStateType = {
  redirectToReferrer: boolean;
  loading: boolean;
  isSearching: boolean;
  from: any;
  field: TablePropType;
  pageNumber: number;
  items: any;
  availableFilters: Array<ITaskFilter>;
  companyName: string;
  searchBy: string;
  selectedFilter: {
    name: string;
    value: number;
  };
  filter: {
    orderBy: string;
    direction: string;
    dateCreated: string;
    pageSize: number;
  };
};

export enum ActionType {
  DATA_LOADER,
  SET_FILTERS,
  SET_ITEMS,
  SET_SEARCH,
  SET_SEARCH_BY,
  SET_LOCAL_SEARCH,
}

export type Action = {
  type: ActionType;
  payload: any;
};

export function tasksReducer(state: tasksStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          filteredDataLoading: false,
        };
      }
      return {
        ...state,
        loading: action.payload,
        filteredDataLoading: action.payload,
      };

    case ActionType.SET_FILTERS:
      if (action.payload.orderBy) {
        return {
          ...state,
          filter: action.payload,
        };
      }

      return {
        ...state,
        selectedFilter: action.payload.selectedFilter || state.selectedFilter,
        availableFilters: action.payload.availableFilters || state.availableFilters,
      };

    case ActionType.SET_ITEMS:
      if (action.payload.items.length > 0) {
        return {
          ...state,
          items: [...action.payload.items],
          field: action.payload.field,
          fetchCompanies: false,
        };
      }
      return {
        ...state,
        totalCompaniesFetched: true,
        field: action.payload.field,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        companyName: action.payload.companyName,
      };

    case ActionType.SET_LOCAL_SEARCH:
      return {
        ...state,
        field: action.payload.field,
      };

    case ActionType.SET_SEARCH_BY:
      return {
        ...state,
        searchBy: action.payload.searchBy,
      };
    default:
      return state;
  }
}
