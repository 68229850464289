import React, { useEffect, useReducer, useRef } from 'react';
import cn from 'classnames';
import useBlur from '_shared/hooks/useBlur';
import useDebounce from '_shared/hooks/useDebounce';
import styles from './assets/userToggle.module.scss';
import { User, UserToggleProps } from './types';
import { ActionType, userToggleReducer } from './store';
import Checkbox from 'components/field/checkbox';
import { capitalizeFirstLetter } from '_shared/utils/string';
import { applicationName } from '_shared/utils/application';

const UserToggle = ({
  label,
  placeholder,
  users,
  selectedUsers,
  setSelectedUsers,
  disabled,
}: UserToggleProps) => {
  const dropdownRef = useRef(null);

  const [state, dispatch] = useReducer(userToggleReducer, {
    isDropdownOpen: false,
    dropdownUsers: users,
    searchUser: '',
  });

  useBlur(dropdownRef, () => {
    dispatch({
      type: ActionType.SET_IS_DROPDOWN_OPEN,
      payload: { isDropdownOpen: false },
    });
  });

  const debouncedSearchTerm = useDebounce(state.searchUser, 500);

  const toggleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget?.id;
    const updatedSelectedusers = (selectedUsers.includes(id) &&
      selectedUsers.filter((userId: any) => userId !== id)) || [...selectedUsers, id];

    setSelectedUsers(updatedSelectedusers);
  };

  const handleSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.SET_SEARCH_USER,
      payload: event.target.value,
    });
  };

  const dropdownSelectedCount = () => {
    return (state.dropdownUsers || []).reduce((total: number, current: User) => {
      return total + (selectedUsers.includes(current.id) ? 1 : 0);
    }, 0);
  };

  useEffect(() => {
    const filteredDropdownUsers = users.filter((user: User) => {
      return user.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });

    dispatch({
      type: ActionType.SET_DROPDOWN_USERS,
      payload: filteredDropdownUsers,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={cn(styles['user-selector'])}>
      {(users.length && (
        <div
          data-testid="user-dropdown-container"
          className={cn(styles['user-dropdown-container'])}
        >
          {label}
          <button
            data-testid="user-dropdown-button"
            className={cn(styles['user-dropdown-button'])}
            onClick={() =>
              dispatch({
                type: ActionType.SET_IS_DROPDOWN_OPEN,
                payload: { isDropdownOpen: true },
              })
            }
          >
            <span>
              {placeholder} ({dropdownSelectedCount()})
            </span>
            <i className={'material-icons'}>expand_more</i>
          </button>
          {(state.isDropdownOpen && (
            <div
              ref={dropdownRef}
              className={cn(styles['user-dropdown'])}
              data-testid="user-search-container"
            >
              <input
                type="text"
                onChange={handleSearchUser}
                placeholder={`Search ${capitalizeFirstLetter(applicationName())}...`}
                className={cn(styles['user-search'])}
                value={state.searchUser}
              />
              <div className={cn(styles['dropdown-checkbox-container'])}>
                {(state.dropdownUsers.length &&
                  state.dropdownUsers.map((user: User) => {
                    return (
                      <label key={user.id} className={cn(styles['dropdown-checkbox-label'])}>
                        <Checkbox
                          {...{
                            id: user.id,
                            name: user.name,
                            handleChange: toggleSelected,
                            selected: selectedUsers.includes(user.id),
                            disabled,
                          }}
                        />
                        <img
                          src={user.picture}
                          alt="user"
                          className={cn(styles['profile-picture'])}
                        />
                        {(user.name && <span>{user.name}</span>) || null}
                      </label>
                    );
                  })) || (
                  <div className={cn(styles['dropdown-checkbox-empty'])}>No Brokers Found</div>
                )}
              </div>
            </div>
          )) ||
            null}
        </div>
      )) ||
        null}
    </div>
  );
};

export default UserToggle;
