import { BrokerAPI } from '_shared/api';
import { BrokerAPIURL } from '_shared/url';

export const getCompanyDocuments = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCompanyDocuments(companyId));

export const getDownloadCompanyDocument = (companyId: string, documentId: string) =>
  BrokerAPI.get(BrokerAPIURL.getDownloadCompanyDocument(companyId, documentId), {
    responseType: 'blob',
  });
export const getAccountingDocuments = (companyId: string, documentId: string) =>
  BrokerAPI.get(BrokerAPIURL.getAccountingDocuments(companyId, documentId), {
    responseType: 'blob',
  });
export const updateTrueLayerDocuments = (companyId: string, params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getGenerateTrueLayerDocuments(companyId), { params });

export const getAgedCreditorsDocument = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getAgedCreditorsDocument(companyId));

export const getAgedDebtorsDocument = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getAgedDebtorsDocument(companyId));

export const getRatiosAndKPIDocument = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getRatiosAndKPIDocument(companyId));

export const getManagementAccountsDocument = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getManagementAccountsDocument(companyId));

export const putCompanyDocumentType = (
  companyId: string,
  documentId: string,
  data: { documentType: string }
) => BrokerAPI.put(BrokerAPIURL.putCompanyDocumentType(companyId, documentId), data);

export const putCompanyDocumentTitle = (
  companyId: string,
  documentId: string,
  data: {
    documentTitle: string;
  }
) => BrokerAPI.put(BrokerAPIURL.putCompanyDocumentTitle(companyId, documentId), data);

export const postCompanyDocument = (companyId: string, data: any) =>
  BrokerAPI.post(BrokerAPIURL.postCompanyDocument(companyId), data);

export const deleteCompanyDocument = (companyId: string, documentId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteCompanyDocument(companyId, documentId));

export const getDownloadApplication = (
  companyId: string,
  applicationId: string,
  productId: string
) =>
  BrokerAPI.get(BrokerAPIURL.getDownloadApplication(companyId, applicationId, productId), {
    responseType: 'blob',
  });
