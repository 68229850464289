import { withTransaction } from '@elastic/apm-rum-react';
import { companyDealsRoutes } from 'pages/companyApplicationForm/companyApplicationForm.routes';
import { companyOverviewRoutes } from 'pages/companyOverview/companyOverview.routes';
import { documentLibraryRoutes } from 'pages/documentLibrary/documentLibrary.routes';
import { financialInformationRoutes } from 'pages/financialInformation/financialInformation.routes';
import { fundingMatchesRoutes } from 'pages/fundingMatches/fundingMatches.routes';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import CompanyDetailsRoute from '.';
import { integrationsRoutes } from 'pages/integrations/integrations.routes';
import { companySavingsProductsRoutes } from 'pages/companySavingsProducts/companySavingsProducts.routes';
import { companyEssentialsRoutes } from 'pages/companyEssentials/companyEssentials.routes';

export const companyDetailsRoutes: RouteObject[] = [
  {
    path: RoutePath.companydetails,
    element: withTransaction('CompanyDetailsRoute', 'component')(<CompanyDetailsRoute />),
    children: [
      ...companyEssentialsRoutes,
      ...companyOverviewRoutes,
      ...fundingMatchesRoutes,
      ...financialInformationRoutes,
      ...companyDealsRoutes,
      ...documentLibraryRoutes,
      ...integrationsRoutes,
      ...companySavingsProductsRoutes,
    ],
  },
];
