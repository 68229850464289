import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';
import { banksDic, monthsDic } from '_shared/utils/constants';
import { convertToShortenedCurrency, currencyIcon } from '_shared/utils/currency';
import styles from 'pages/fundingMatches/assets/fundingMatches.module.scss';
import { getYearsList } from '_shared/utils/date';
import { getFirstCommercialSaleComponents, parseStringValues } from '../utils';
import features from 'config/features';

const defaultStyles = {
  field: styles.field,
  label: styles.label,
  control: styles.control,
};

export const debtFields = (data: any) => {
  const [firstCommercialSaleMonth, firstCommercialSaleYear] = getFirstCommercialSaleComponents(
    data?.firstCommercialSale
  );

  const leadingCurrencyIcon = currencyIcon();

  return [
    {
      id: 'funding-requirements-funding-purposes',
      label: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
      placeholder: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
      name: 'fundingPurposes',
      value:
        (data?.fundingPurposes?.length &&
          data?.fundingPurposes?.map((purpose: string) => parseStringValues(purpose)).join(',') +
            ',') ||
        '',
      validationtype: ValidationTypes.CHECKBOX,
      fieldType: FieldTypes.CHECKBOX,
      required: true,
      type: 'checkbox',
      selectionKey:
        (data?.fundingPurposes?.length &&
          data?.fundingPurposes?.map((purpose: string) => parseStringValues(purpose)).join(',') +
            ',') ||
        '',
      options: data.pageMetadata?.fundingPurposes?.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: parseStringValues(x.label),
          value: parseStringValues(x.label),
          parentId: 'fundingPurposes',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'fundingRequired',
      name: 'fundingRequired',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequired',
      value: data.fundingRequired,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'turnoverLastYear',
      name: 'turnoverLastYear',
      label: 'home:companydetails:fundingmatches:fields:loans:turnoverlastyear',
      value: data.turnoverLastYear,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'sectors',
      label: 'home:companydetails:fundingmatches:fields:loans:sectors',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:sectors',
      name: 'sectors',
      type: 'checkbox',
      value: (data?.sectors?.length && data?.sectors.join(',') + ',') || '',
      validationtype: ValidationTypes.CHECKBOX,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      selectionKey:
        (data?.sectors?.length &&
          data?.sectors?.map((sector: string) => parseStringValues(sector)).join(',') + ',') ||
        '',
      options: data.pageMetadata.sectors.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: parseStringValues(x.label),
          value: parseStringValues(x.label),
          parentId: 'sectors',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'fundingRequiredTimescale',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequiredtimescale',
      name: 'fundingRequiredTimescale',
      placeholder: 'funding timeframe',
      value: data.fundingRequiredTimescale,
      fieldType: FieldTypes.SELECT,
      validationtype: ValidationTypes.SELECT,
      required: true,
      options: [...data.pageMetadata.fundingRequiredTimescales],
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'currentlybank',
      label: 'home:companydetails:fundingmatches:fields:loans:currentlybank',
      name: 'currentlybank',
      value: data?.currentlyBank,
      fieldType: FieldTypes.SELECT,
      required: true,
      validationtype: ValidationTypes.SELECT,
      placeholder: 'select bank',
      options: banksDic,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'fundingPurposeSummary',
      name: 'fundingPurposeSummary',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingpurposesummary',
      value: data.fundingPurposeSummary,
      fieldType: FieldTypes.TEXTAREA,
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'isTrading',
      label: 'home:companydetails:fundingmatches:fields:grants:istrading',
      value: Boolean(data?.isTrading),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'isTrading',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'tradingsince',
      label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:title',
      name: 'tradingsince',
      dependsOn: {
        name: 'isTrading',
        value: true,
      },
      children: [
        {
          id: 'tradingSinceMonth',
          label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:month',
          name: 'firstCommercialSaleMonth',
          placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:month',
          value: firstCommercialSaleMonth,
          fieldType: FieldTypes.SELECT,
          validationtype: ValidationTypes.SELECT,
          required: true,
          options: [...monthsDic],
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'tradingSinceYear',
          label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:title',
          name: 'firstCommercialSaleYear',
          placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:year',
          value: firstCommercialSaleYear,
          fieldType: FieldTypes.SELECT,
          validationtype: ValidationTypes.SELECT,
          required: true,
          options: getYearsList().map((year) => {
            return {
              value: year,
              label: year,
            };
          }),
          classNames: {
            ...defaultStyles,
          },
        },
      ],
    },
    {
      id: 'isHomeowner',
      label: 'home:companydetails:fundingmatches:fields:loans:ishomeowner',
      value: data.isHomeowner,
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'isHomeowner',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'profitablelastyear',
      label: 'home:companydetails:fundingmatches:fields:loans:profitablelastyear',
      value: Boolean(data?.profitableLastYear),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'profitablelastyear',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'cardPaymentsAccepted',
      label: 'home:companydetails:fundingmatches:fields:loans:acceptscardpayment',
      value: Boolean(data?.cardPaymentsAccepted),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'cardPaymentsAccepted',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'invoicesSent',
      label: 'home:companydetails:fundingmatches:fields:loans:invoicessent',
      value: Boolean(data?.invoicesSent),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'invoicesSent',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'invoiceAverageMonthlyRevenue',
      name: 'invoiceAverageMonthlyRevenue',
      label: 'Average monthly invoice revenue',
      value: data.invoiceAverageMonthlyRevenue,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
      dependsOn: { name: 'invoicesSent', value: true },
    },
    {
      id: 'monthlyRecurringRevenue',
      name: 'monthlyRecurringRevenue',
      label: 'home:companydetails:fundingmatches:fields:loans:recurringrevenue',
      value: data.monthlyRecurringRevenue,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
  ];
};

export const debtFieldsEmpty = () => {
  return {
    fields: [
      {
        id: 'funding-requirements-funding-purposes',
        label: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
        placeholder: 'home:companydetails:fundingmatches:fundingrequirements:fundingpurposes',
        name: 'fundingPurposes',
        fieldType: 4,
      },
      {
        id: 'fundingRequired',
        name: 'fundingRequired',
        label: 'home:companydetails:fundingmatches:fields:loans:fundingrequired',
        fieldType: 0,
      },
      {
        id: 'turnoverLastYear',
        name: 'turnoverLastYear',
        label: 'home:companydetails:fundingmatches:fields:loans:turnoverlastyear',
        fieldType: 0,
      },
      {
        id: 'sectors',
        label: 'home:companydetails:fundingmatches:fields:loans:sectors',
        placeholder: 'home:companydetails:fundingmatches:fields:loans:sectors',
        name: 'sectors',
        fieldType: 4,
      },
      {
        id: 'fundingRequiredTimescale',
        label: 'home:companydetails:fundingmatches:fields:loans:fundingrequiredtimescale',
        name: 'fundingRequiredTimescale',
        placeholder: 'funding timeframe',
        fieldType: 2,
      },
      {
        id: 'currentlybank',
        label: 'home:companydetails:fundingmatches:fields:loans:currentlybank',
        name: 'currentlybank',
        fieldType: 2,
      },
      {
        id: 'fundingPurposeSummary',
        name: 'fundingPurposeSummary',
        label: 'home:companydetails:fundingmatches:fields:loans:fundingpurposesummary',
        fieldType: 1,
      },
      {
        id: 'isTrading',
        label: 'home:companydetails:fundingmatches:fields:grants:istrading',
        fieldType: 5,
      },
      {
        id: 'tradingsince',
        label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:title',
        name: 'tradingsince',
      },
      {
        id: 'isHomeowner',
        label: 'home:companydetails:fundingmatches:fields:loans:ishomeowner',
        fieldType: 5,
      },
      {
        id: 'profitablelastyear',
        label: 'home:companydetails:fundingmatches:fields:loans:profitablelastyear',
        fieldType: 5,
        name: 'profitablelastyear',
      },
      {
        id: 'cardPaymentsAccepted',
        label: 'home:companydetails:fundingmatches:fields:loans:acceptscardpayment',
        fieldType: 5,
        name: 'cardPaymentsAccepted',
      },
      {
        id: 'invoicessent',
        label: 'home:companydetails:fundingmatches:fields:loans:invoicessent',
        fieldType: 5,
        name: 'invoicessent',
      },
      {
        id: 'monthlyRecurringRevenue',
        name: 'monthlyRecurringRevenue',
        label: 'home:companydetails:fundingmatches:fields:loans:recurringrevenue',
        fieldType: 0,
      },
    ],
  };
};

export const equityFields = (data: any) => {
  const leadingCurrencyIcon = currencyIcon();
  return [
    {
      id: 'fundingRequired',
      name: 'fundingRequired',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequired',
      value: data.fundingRequired,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'region',
      label: 'home:companydetails:fundingmatches:fields:equity:regions',
      name: 'region',
      placeholder: 'select region',
      value: data.region,
      fieldType: FieldTypes.SELECT,
      validationtype: ValidationTypes.SELECT,
      required: true,
      options: [...data.pageMetadata.regions],
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'businessSummary',
      name: 'businessSummary',
      label: 'home:companydetails:fundingmatches:fields:equity:businesssummary',
      value: data.businessSummary,
      fieldType: FieldTypes.TEXTAREA,
      validationtype: ValidationTypes.TEXT,
      required: true,
      type: 'text',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'businessModels',
      label: 'home:companydetails:fundingmatches:fields:equity:businessmodels',
      placeholder: 'home:companydetails:fundingmatches:fields:equity:businessmodels',
      name: 'businessModels',
      type: 'checkbox',
      value: (data?.businessModels || []).join(','),
      required: true,
      validationtype: ValidationTypes.CHECKBOX,
      fieldType: FieldTypes.CHECKBOX,
      selectionKey:
        (data?.businessModels?.length && (data?.businessModels || []).join(',') + ',') || '',
      options: data.pageMetadata.businessModels.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: x.label,
          value: x.label,
          parentId: 'businessModels',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'website',
      name: 'website',
      label: 'home:companydetails:fundingmatches:fields:equity:website',
      value: data.website,
      fieldType: FieldTypes.TEXTAREA,
      validationtype: ValidationTypes.TEXT,
      required: true,
      type: 'text',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'turnoverLastYear',
      name: 'turnoverLastYear',
      label: 'home:companydetails:fundingmatches:fields:loans:turnoverlastyear',
      value: data.turnoverLastYear,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'seis/eis',
      label: 'home:companydetails:fundingmatches:fields:equity:approvedschemes',
      name: 'approvedScheme',
      placeholder: 'select SEIS/EIS',
      value: data.approvedScheme,
      fieldType: FieldTypes.SELECT,
      options: [...data.pageMetadata.approvedSchemes],
      classNames: {
        ...defaultStyles,
      },
      enabled: features.seisEisEnabled,
    },
    {
      id: 'fundingRaisedPreviously',
      label: 'home:companydetails:fundingmatches:fields:equity:fundingraisedpreviously',
      value: Boolean(data?.fundingRaisedPreviously),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'fundingRaisedPreviously',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'monthlyRecurringRevenue',
      name: 'monthlyRecurringRevenue',
      label: 'home:companydetails:fundingmatches:fields:loans:recurringrevenue',
      value: data.monthlyRecurringRevenue,
      fieldType: FieldTypes.TEXT,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'sectors',
      label: 'home:companydetails:fundingmatches:fields:loans:sectors',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:sectors',
      name: 'sectors',
      type: 'checkbox',
      value: (data?.sectors?.length && data?.sectors.join(',') + ',') || '',
      fieldType: FieldTypes.CHECKBOX,
      selectionKey:
        (data?.sectors?.length &&
          data?.sectors?.map((sector: string) => parseStringValues(sector)).join(',') + ',') ||
        '',
      options: data.pageMetadata.sectors.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: parseStringValues(x.label),
          value: parseStringValues(x.label),
          parentId: 'sectors',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'applicationAdviceRequested',
      label: 'home:companydetails:fundingmatches:fields:equity:applicationadvicerequested',
      value: Boolean(data?.applicationAdviceRequested),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'applicationAdviceRequested',
      classNames: {
        ...defaultStyles,
      },
    },
  ].filter((field) => field.enabled !== false);
};
export const grantsFields = (data: any) => {
  const [firstCommercialSaleMonth, firstCommercialSaleYear] = getFirstCommercialSaleComponents(
    data?.firstCommercialSale
  );
  const leadingCurrencyIcon = currencyIcon();
  return [
    {
      id: 'fundingRequired',
      name: 'fundingRequired',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingrequired',
      value: data.fundingRequired,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'fundingPurposes',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingpurposes',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:fundingpurposes',
      name: 'fundingPurposes',
      value: (data?.fundingPurposes?.length && data.fundingPurposes.join(',') + ',') || '',
      validationtype: ValidationTypes.CHECKBOX,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      type: 'checkbox',
      selectionKey:
        (data?.fundingPurposes?.length &&
          data?.fundingPurposes?.map((purpose: string) => parseStringValues(purpose)).join(',') +
            ',') ||
        '',
      options: data.pageMetadata.fundingPurposes.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: parseStringValues(x.label),
          value: parseStringValues(x.label),
          parentId: 'fundingPurposes',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'fundingPurposeSummary',
      name: 'fundingPurposeSummary',
      label: 'home:companydetails:fundingmatches:fields:loans:fundingpurposesummary',
      value: data.fundingPurposeSummary,
      fieldType: FieldTypes.TEXTAREA,
      validationtype: ValidationTypes.TEXT,
      required: true,
      type: 'text',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'turnoverLastYear',
      name: 'turnoverLastYear',
      label: 'home:companydetails:fundingmatches:fields:loans:turnoverlastyear',
      value: data.turnoverLastYear,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.NUMERIC,
      required: true,
      leadingIcon: leadingCurrencyIcon,
      type: 'number',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'sectors',
      label: 'home:companydetails:fundingmatches:fields:loans:sectors',
      placeholder: 'home:companydetails:fundingmatches:fields:loans:sectors',
      name: 'sectors',
      type: 'checkbox',
      value: (data?.sectors?.length && data.sectors.join(',') + ',') || '',
      validationtype: ValidationTypes.CHECKBOX,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      selectionKey:
        (data?.sectors?.length &&
          data?.sectors?.map((sector: string) => parseStringValues(sector)).join(',') + ',') ||
        '',
      options: data.pageMetadata.sectors.map((x: { label: string; value: string }) => {
        return {
          ...x,
          name: parseStringValues(x.label),
          value: parseStringValues(x.label),
          parentId: 'sectors',
        };
      }),
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'isTrading',
      label: 'home:companydetails:fundingmatches:fields:grants:istrading',
      value: Boolean(data?.isTrading),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'isTrading',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'tradingsince',
      label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:title',
      name: 'tradingsince',
      dependsOn: {
        name: 'isTrading',
        value: true,
      },
      children: [
        {
          id: 'tradingSinceMonth',
          label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:month',
          name: 'firstCommercialSaleMonth',
          placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:month',
          value: firstCommercialSaleMonth,
          fieldType: FieldTypes.SELECT,
          validationtype: ValidationTypes.SELECT,
          required: true,
          options: [...monthsDic],
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'tradingSinceYear',
          label: 'home:companydetails:fundingmatches:fields:loans:tradingsince:title',
          name: 'firstCommercialSaleYear',
          placeholder: 'home:companydetails:fundingmatches:fields:loans:tradingsince:year',
          value: firstCommercialSaleYear,
          fieldType: FieldTypes.SELECT,
          validationtype: ValidationTypes.SELECT,
          required: true,
          options: getYearsList().map((year) => {
            return {
              value: year,
              label: year,
            };
          }),
          classNames: {
            ...defaultStyles,
          },
        },
      ],
    },
    {
      id: 'registeredAddressLine',
      name: 'registeredAddress',
      label: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:title',
      children: [
        {
          id: 'registeredAddressLine',
          name: 'registeredAddress.line',
          label: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:line',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:line',
          value: data.registeredAddress?.line,
          fieldType: FieldTypes.TEXT,
          validationtype: ValidationTypes.TEXT,
          required: true,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'registeredAddressCity',
          name: 'registeredAddress.city',
          label: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:city',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:city',
          value: data.registeredAddress?.city,
          fieldType: FieldTypes.TEXT,
          validationtype: ValidationTypes.TEXT,
          required: true,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'registeredAddressPostcode',
          name: 'registeredAddress.postcode',
          label: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:postcode',
          placeholder:
            'home:companydetails:fundingmatches:fields:grants:registeredaddress:postcode',
          value: data.registeredAddress?.postcode,
          fieldType: FieldTypes.TEXT,
          validationtype: ValidationTypes.TEXT,
          required: true,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'registeredAddressCountry',
          name: 'registeredAddress.country',
          label: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:country',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:registeredaddress:country',
          value: data.registeredAddress?.country,
          fieldType: FieldTypes.TEXT,
          validationtype: ValidationTypes.TEXT,
          required: true,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
      ],
    },
    {
      id: 'tradingAddressIsDifferent',
      label: 'home:companydetails:fundingmatches:fields:grants:tradingaddressdifferent',
      value: Boolean(data?.tradingAddressIsDifferent),
      fieldType: FieldTypes.RADIO,
      type: 'radio',
      name: 'tradingAddressIsDifferent',
      classNames: {
        ...defaultStyles,
      },
    },
    {
      id: 'tradingAddressLine',
      name: 'tradingAddress',
      label: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:title',
      dependsOn: {
        name: 'tradingAddressIsDifferent',
        value: true,
      },
      children: [
        {
          id: 'tradingAddressLine',
          name: 'tradingAddress.line',
          label: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:line',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:line',
          value: data.tradingAddress?.line,
          fieldType: FieldTypes.TEXT,
          required: true,
          validationtype: ValidationTypes.TEXT,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'tradingAddressCity',
          name: 'tradingAddress.city',
          label: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:city',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:city',
          value: data.tradingAddress?.city,
          fieldType: FieldTypes.TEXT,
          required: true,
          validationtype: ValidationTypes.TEXT,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'tradingAddressPostcode',
          name: 'tradingAddress.postcode',
          label: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:postcode',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:postcode',
          value: data.tradingAddress?.postcode,
          fieldType: FieldTypes.TEXT,
          required: true,
          validationtype: ValidationTypes.TEXT,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
        {
          id: 'tradingAddressCountry',
          name: 'tradingAddress.country',
          label: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:country',
          placeholder: 'home:companydetails:fundingmatches:fields:grants:tradingaddress:country',
          value: data.tradingAddress?.country,
          fieldType: FieldTypes.TEXT,
          required: true,
          validationtype: ValidationTypes.TEXT,
          type: 'text',
          classNames: {
            ...defaultStyles,
          },
        },
      ],
    },
  ];
};

export const contentFields = (data: any[]): any => {
  if (!data || !data.length) return [];
  return data?.reduce((total: any, curr: any) => {
    return {
      ...total,
      [curr.subtype || 'General']: [...(total[curr.subtype || 'General'] || []), getFields(curr)],
    };
  }, {});
};

const getFields = (currentField: any): any => {
  return {
    ...currentField,
    id: currentField.id,
    logoUri: currentField.logoUri,
    seeMoreLink: '',
    ratesFrom: '',
    decisionTime: '',
    funding: `${convertToShortenedCurrency(currentField.minSize || 0)} - ${
      (currentField.maxSize && convertToShortenedCurrency(currentField.maxSize)) || 'N/a'
    }`,
  };
};

export const defaultDebtFields = {
  isDataComplete: false,
  isTrading: false,
  profitableLastYear: false,
  isHomeowner: false,
  cardPaymentsAccepted: false,
  invoicesSent: false,
};

export const defaultEquityFields = {
  applicationAdviceRequested: false,
  fundingRaisedPreviously: false,
};

export const defaultGrantFields = {
  tradingAddressIsDifferent: false,
  isTrading: false,
};
