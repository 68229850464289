export type APIActivityType = {
  activityType: ACTIVITY_TYPE;
  companyId: string;
  createdBy: string;
  dateCreated: string;
  dateModified: string;
  fullName: string;
  id: string;
  modifiedBy: string;
  picture: string;
  content: string;
  taskStatus: string;
  dueDate: string;
  subject: string;
  fromAddress?: string;
  toAddress?: string;
  assignedToFullName: string;
};

export type ActivityPropTypes = {
  activity: ActivityType;
  handleEditActivity?: (activityId: string) => void;
  handleEditOnChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleDeleteActivity?: (activityId: string) => void;
};

export type ActivityDropdownPropTypes = {
  activities: ActivityType[];
  order: any;
  filter: string;
  loading: boolean;
  actions: {
    handleChangeOrder: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleDeleteActivity: (activityId: string) => void;
    handleEditActivity: (activityId: string) => void;
    openChooseActivityModal: (isOpen: boolean) => void;
    toggleOpenActivityDropdown: () => void;
    handleFilterActivities: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  };
};

export type ChooseActivityModalPropTypes = {
  show: boolean;
  closeModal: (isOpen?: boolean) => void;
  chooseActivity: (activityCategory: ACTIVITY_CATEGORY) => void;
};

export type DeleteActivityPropTypes = {
  activity: ActivityType;
  closeDeleteModal: () => void;
  handleConfirmDelete: () => void;
  loading: boolean;
};

export type EditActivityPropTypes = {
  activity: ActivityType;
  closeEditModal: () => void;
  handleConfirmEdit: (activity: ActivityType) => void;
  loading: boolean;
};

export type NewActivityPropTypes = {
  deleteNewActivity: () => void;
  companyId: string;
  refresh: (id: string) => void;
  activityCategory: ACTIVITY_CATEGORY;
};

export type NewActivityContentPropTypes = {
  saveActivity: () => void;
  isCollapsed: boolean;
  activity: ActivityType;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isSaving: boolean;
  activityCategory: ACTIVITY_CATEGORY;
  handleCallStatus: any;
  callStatus: string | undefined;
};

export type APINoteResponse = {
  results: Array<APIActivityType>;
  paging: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
  };
};

export type ActivityType = {
  id: string;
  timestamp: string | number | Date;
  user: string;
  content: string;
  picture: string;
  isMyNote: boolean;
  dateCreated: string | number | Date;
  dateModified: string | number | Date;
  activityType: ACTIVITY_TYPE;
  taskStatus: string;
  dueDate: string | number | Date;
  subject: string;
  fromAddress?: string;
  toAddress?: string;
  assignedToFullName: string;
};

export enum ACTIVITY_ORDER {
  newestFirst = 'Newest First',
  oldestFirst = 'Oldest First',
  userAtoZ = 'User A to Z',
  userZtoA = 'User Z to A',
}

export enum ACTIVITY_CATEGORY {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  NOTE = 'NOTE',
  TASK = 'TASK',
  APPLICATION = 'APPLICATION',
}

export enum ACTIVITY_TYPE {
  NOTE = 'Note',
  TASK = 'Task',
  EMAIL = 'Email',
  CALL_SUCCESS = 'CallSuccess',
  CALL_FAILURE = 'CallFailure',
}
