import cn from 'classnames';
import styles from '../../assets/companyOverview.module.scss';
import { useTranslation } from 'react-i18next';
import { FinancialSnapshotFields } from 'pages/companyOverview/types';
import { AccountBalanceDropdown } from '../balanceDropdown';
import { getBusinessFinancialSnapshot } from '_shared/api/businesses';
import { useEffect, useState } from 'react';
import { financialSnapshotFields } from 'pages/companyOverview/fields/financialSnapshot.fields';

const FinancialSnapshotCard = ({ companyId }: { [key: string]: any }) => {
  const [financialSnapshotField, setFinancialSnapshotField] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { t } = useTranslation();

  const fetchFinancialSnapshot = async (id: string) => {
    setIsLoading(true);
    try {
      const { data } = await getBusinessFinancialSnapshot(id);
      setFinancialSnapshotField(financialSnapshotFields(data));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!companyId) return;

    fetchFinancialSnapshot(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      <div className={cn(styles['financial-container'])} data-testid="financial-snapshot">
        {financialSnapshotField?.map(({ id, label, value, details }: FinancialSnapshotFields) => {
          return (
            <div data-testid="financial-snapshot" key={id} className={cn(styles['financial-item'])}>
              <span className={cn(styles['financial-heading'])}>{t(label)}</span>
              <div className={cn(styles['financial-value-holder'])}>
                {value || value === 0 ? (
                  <>
                    {(Array.isArray(value) && <AccountBalanceDropdown options={value} />) || (
                      <span className={cn(styles['financial-value'])}>{value}</span>
                    )}
                    {details?.performance && (
                      <div className={cn(styles['financial-details'])}>
                        <span
                          className={cn((details.isPositive && styles.positive) || styles.negative)}
                        >
                          {details.icon && (
                            <img
                              src={details.icon}
                              alt="performance"
                              className={cn(styles['performance-icon'])}
                            />
                          )}
                          {details.performance}
                        </span>
                        <span>
                          {t('home:companydetails:overview:financialsnapshot:vsprevious')}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <span className={cn(styles['missing'])}>missing</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FinancialSnapshotCard;
