import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './CreditScoreImprovements.module.scss';
import icon from '../../assets/credit-score-improvements-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import { InfoboxDesciptions } from '../../types';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';
import ProcessSteps from 'pages/companySavingsProducts/components/ProcessSteps';

const CreditScoreImprovements: React.FC = () => {
  const { isLoading, postProductApplication } = useSavingsProducts('credit-score-improvements');

  const infoboxes: InfoboxDesciptions = {
    amount: null,
    purpose:
      'Improve chances of accessing competitive interest rates,  supplier terms and success when tendering for business.',
    speed: 'Within 3 days for successful improvement from proposal',
    suitablefor:
      'Ltd companies within the UK - there is typically a higher chance of success with those who have filed at least 1 set of company accounts.',
    cost: 'Price on application and only payable upon success',
  };

  const handleSubmit = async () => {
    window.open(
      'https://lightbulbcredit.com/lightbulb-swoop-free-credit-insight',
      '_blank',
      'noopener'
    );
    await postProductApplication('credit-score-improvements');
  };

  const processSteps = [
    {
      title: "Request your client's free report above",
      description:
        "This will include your client's scores, credit limits and additional credit information.",
    },
    {
      title: 'Book in a meeting',
      description:
        'One of our specialists will walk your client through the information and see what improvements could be made',
    },
    {
      title: 'Receive a proposal ',
      description:
        "Showing your client's target score improvement and success fee (your client only pays if it works!)",
    },
  ];

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Credit score improvements"
        icon={icon}
        ctaText="Request your client's report"
        ctaCallback={handleSubmit}
        isCtaDisabled={false}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h2>
            Swoop's trusted partner could help your client's business improve its credit rating.
          </h2>
          <p>
            Our partner is the only business in the UK that provides data from Credit Safe,
            Experian, Equifax, Dun & Bradstreet, and Red Flag Alert. They offer insights into your
            client's financial world for free, helping your client's business access funding more
            easily, and improve their working capital.
          </p>

          <h2>What are the next steps?</h2>
          <ProcessSteps steps={processSteps} />

          <h2>Tips on how to improve your credit score over time:</h2>
          <p>1. Make sure you file your accounts on time (or early)</p>
          <p>2. Make sure you're paying suppliers on time, and within the terms</p>
          <p>3. Pay off any CCJs (County Court Judgments)</p>
          <p>4. Make sure your information is correct and up-to-date (addresses, ownership etc.)</p>
        </div>
        <SavingsProductsQuickInfoBox descriptions={infoboxes} />
      </div>
    </div>
  );
};

export default CreditScoreImprovements;
