import { useEffect, useMemo, useState } from 'react';

import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import { formatMetadata } from '_shared/utils/formMetadaFormatter';
import classNames from 'classnames';
import styles from 'layouts/components/AddUserModal/EmailClient.module.scss';
import Field from 'components/field';
import { TOption } from '_shared/utils/form';
import { useAppState } from 'store';

type MetadataType = {
  showWhitelabelField: boolean;
  fundingReasonOptions: TOption[];
  metadata: Array<FieldMetaDataPropType>;
  errors?: { [key: string]: any };
  handleChange?: any;
};

// Not using the Field component with a JSON file with the config for this form.
//A lot of specific styles and configs were necessary here to make this component as close possible as the designs and using that approach (Field component + JSON file)
// was tricky and needed more work around to make everything work as expected.
const EmailClient = (metadata: MetadataType, errors: { [key: string]: any }, handleChange: any) => {
  const { state: appState } = useAppState();
  const [indexByKey, setIndexByKey] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    if (metadata.metadata.length && !Object.keys(indexByKey).length)
      setIndexByKey(formatMetadata(metadata.metadata));
  }, [indexByKey, metadata]);

  const groupOptions = useMemo<TOption[]>(
    () => [
      { label: 'My group', value: appState.system.groupId || '' },
      ...appState.system.exposedGroups!.map((group) => ({
        label: group.groupName,
        value: group.groupId,
      })),
    ],
    [appState.system.exposedGroups, appState.system?.groupId]
  );

  const fundingReasonOptions = useMemo<TOption[]>(
    () => [{ label: 'Unselected', value: '' }, ...metadata.fundingReasonOptions],
    [metadata.fundingReasonOptions]
  );

  return (
    <>
      {!!metadata.metadata.length && !!Object.keys(indexByKey).length && (
        <div className={classNames(styles['container'])} data-testid="email-client-test">
          <div className={classNames(styles['wrapper'])}>
            {/* Upper section */}
            <div className={classNames(styles['upper-section'])}>
              {/* First name field */}
              <div className={classNames(styles['upper-section-field-container'])}>
                <Field
                  {...{
                    metadata: metadata.metadata[indexByKey['firstName']],
                    errors: metadata?.errors || [],
                    handleChange: metadata?.handleChange,
                  }}
                />
              </div>

              {/* Last name field */}
              <div className={classNames(styles['upper-section-field-container'])}>
                <Field
                  {...{
                    metadata: metadata.metadata[indexByKey['lastName']],
                    errors: metadata?.errors || [],
                    handleChange: metadata?.handleChange,
                  }}
                />
              </div>
            </div>

            {/* Email field */}
            <div className={classNames(styles['main-section-field-container'])}>
              <Field
                {...{
                  metadata: metadata.metadata[indexByKey['email']],
                  errors: metadata?.errors || [],
                  handleChange: metadata?.handleChange,
                }}
              />
            </div>

            {appState.system.exposedGroups && appState.system.exposedGroups.length > 0 ? (
              <div className={classNames(styles['main-section-field-container'])}>
                <Field
                  {...{
                    metadata: {
                      ...metadata.metadata[indexByKey['groupId']],
                      options: groupOptions,
                    },
                    errors: metadata?.errors || [],
                    handleChange: metadata?.handleChange,
                  }}
                />
              </div>
            ) : null}

            <div className={classNames(styles['main-section-field-container'])}>
              <Field
                {...{
                  metadata: {
                    ...metadata.metadata[indexByKey['fundingReason']],
                    options: fundingReasonOptions,
                  },
                  errors: metadata?.errors || [],
                  handleChange: metadata?.handleChange,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailClient;
