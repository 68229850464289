// eslint-disable-next-line import/named
import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export const getCompanyScore = (companyId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getCompanyScore(companyId));
};
export const getCompanyFormScore = (companyId: string, formId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getCompanyFormScore(companyId, formId));
};
export const getFormScoreHistory = (companyId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getFormScoreHistory(companyId));
};
export const getCompanyScoreHistoryByForm = (companyId: string, formId: string) => {
  return BrokerAPI.get(BrokerAPIURL.getCompanyScoreHistoryByForm(companyId, formId));
};
