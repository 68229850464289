import { TablePropType } from 'components/table/type';
import React, { createContext } from 'react';
import { fields } from './users.field';
import { APIUsersByRoleType } from './type';

export type usersStateType = {
  redirectToReferrer: boolean;
  loading: boolean;
  fields: TablePropType;
  order: { sortBy: string; direction: string };
  data: APIUsersByRoleType[];
  error: boolean;
  searchUser: string;
};

export const initialState = {
  redirectToReferrer: false,
  loading: true,
  fields: fields([], { sortBy: '', direction: '' }, () => {}),
  order: { sortBy: '', direction: 'desc' },
  data: [],
  error: false,
  searchUser: '',
};

export enum ActionType {
  DATA_LOADER,
  SET_SEARCH_QUERYTIMER,
  SET_BROKERS,
  SET_SEARCH_BROKER,
  SET_ORDER,
  SET_SEARCH_FIELDS,
  SET_LOADING,
  SET_ERRORS,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function userReducer(state: usersStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATA_LOADER:
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          filteredDataLoading: false,
        };
      }
      return {
        ...state,
        loading: action.payload,
      };

    case ActionType.SET_SEARCH_QUERYTIMER:
      return {
        ...state,
        timeoutId: null,
      };

    case ActionType.SET_BROKERS:
      return {
        ...state,
        fields: { ...state.fields, ...action.payload.fields },
        data: action.payload.data || state.data,
        pageSize: action.payload.pageSize,
      };

    case ActionType.SET_SEARCH_BROKER:
      return {
        ...state,
        searchUser: action.payload || '',
      };

    case ActionType.SET_ORDER:
      const direction = state.order.direction === 'desc' ? 'asc' : 'desc';
      return {
        ...state,
        order: { ...action.payload, direction },
      };

    case ActionType.SET_SEARCH_FIELDS:
      return {
        ...state,
        fields: { ...state.fields, ...action.payload.fields },
      };

    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case ActionType.SET_ERRORS:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export const UsersContext = createContext<{
  state: usersStateType;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => {} });
