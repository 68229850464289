import React from 'react';
import { ResponsiveLine } from '@nivo/line';

export const LineGraph = ({ data }: any) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 100,
    }}
    colors={['#0F334A', '#51AAED', '#46C8B8']}
    enablePoints
    enableGridX={false}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: 36,
      legendPosition: 'middle',
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendPosition: 'middle',
    }}
    legends={[
      {
        anchor: 'top-left',
        direction: 'row',
        justify: false,
        translateX: 1,
        translateY: -40,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
      },
    ]}
  />
);
