import { FieldTypes, ValidationTypes } from '_shared/fieldValidation/types';

export const rndFormFields = [
  {
    id: 'rndSpendLastYear',
    fieldType: FieldTypes.CURRENCY,
    validationtype: ValidationTypes.CURRENCY,
    hasCustomRequiredErrorMessage: true,
    required: true,
    label:
      "What's your client's estimated R&D (research and development) spend in the last financial year?",
    name: 'rndSpendLastYear',
    value: '',
  },
  {
    id: 'hasCt600BeenFiled',
    label: "Has your client's CT600 (Corporation Tax for Company Tax Return) been filed?",
    fieldType: FieldTypes.RADIO,
    validationtype: ValidationTypes.RADIO,
    hasCustomRequiredErrorMessage: true,
    required: true,
    name: 'hasCt600BeenFiled',
    options: [
      { label: 'Yes', value: 'true', name: 'true', parentId: 'hasCt600BeenFiled' },
      { label: 'No', value: 'false', name: 'false', parentId: 'hasCt600BeenFiled' },
    ],
    value: '',
  },
  {
    id: 'hasClaimedRndBefore',
    label: 'Has your client filed an R&D claim before?',
    fieldType: FieldTypes.RADIO,
    validationtype: ValidationTypes.RADIO,
    hasCustomRequiredErrorMessage: true,
    required: true,
    name: 'hasClaimedRndBefore',
    options: [
      { label: 'Yes', value: 'true', name: 'true', parentId: 'hasClaimedRndBefore' },
      { label: 'No', value: 'false', name: 'false', parentId: 'hasClaimedRndBefore' },
    ],
    value: '',
  },
  {
    id: 'taxCreditsPaidDuringPeriod',
    label:
      'How much was paid to HMRC in the period that your client is looking to get tax credits?',
    fieldType: FieldTypes.CURRENCY,
    validationtype: ValidationTypes.CURRENCY,
    hasCustomRequiredErrorMessage: true,
    required: true,
    name: 'taxCreditsPaidDuringPeriod',
    tooltip:
      'This is the total of all P32 monthly amounts for the year - but a rough estimate will do great for now.',
    value: '',
  },
  {
    id: 'reasonForEligibility',
    label: 'Give us a brief summary of why your client is eligible for tax credits.',
    fieldType: FieldTypes.TEXTAREA,
    validationtype: ValidationTypes.TEXT,
    hasCustomRequiredErrorMessage: true,
    required: true,
    name: 'reasonForEligibility',
    value: '',
  },
];
