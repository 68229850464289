/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import useDebounce from '_shared/hooks/useDebounce';
import classNames from 'classnames';
import Downshift from 'downshift';

import { AutocompleteType, AutocompleteActionType } from './type';
import { TextformatType } from '_shared/fieldValidation/types';
import { checkPosition } from '_shared/utils/controlSelectMenuStyle';
import styles from './autocomplete.module.scss';

function FetchItems({
  getMenuProps,
  getItemProps,
  inputValue,
  callBack,
  defaultItems,
  className,
  onLoading,
  parentInputName,
}: any) {
  const [state, setstate] = React.useState({
    defaultItems,
    items: [],
    loading: false,
  });
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  React.useEffect(() => {
    onLoading(false);

    async function getItems() {
      const searchResult = await callBack(AutocompleteActionType.FETCH, debouncedSearchTerm);
      setstate({ ...state, items: searchResult, loading: false });
      onLoading(false);
    }

    if (debouncedSearchTerm) {
      setstate({ ...state, loading: true });
      onLoading(true);
      getItems();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  React.useEffect(() => {
    if (state.items?.length > 0) {
      const parentElement = document.getElementsByName(parentInputName);

      checkPosition(parentElement[0], (element: HTMLElement, position: string) => {
        const listElement = document.getElementById(`${parentInputName}-list`);

        // Setting border radius for list and main input based on the positino of the elements on the screen.
        if (listElement && position === 'bottom') {
          listElement.style.bottom = '';

          parentElement[0].style.borderBottomLeftRadius = '0';
          parentElement[0].style.borderBottomRightRadius = '0';
          listElement.style.borderTopLeftRadius = '0';
          listElement.style.borderTopRightRadius = '0';
        } else if (listElement && position === 'UP') {
          listElement.style.bottom = '36px';

          parentElement[0].style.borderTopLeftRadius = '0';
          parentElement[0].style.borderTopRightRadius = '0';
          listElement.style.borderBottomLeftRadius = '0';
          listElement.style.borderBottomRightRadius = '0';
        }
      });
    }
  }, [getItemProps, parentInputName, state.items]);

  return !state.loading && state.items?.length > 0 ? (
    <ul
      {...getMenuProps({
        className: classNames(styles['autosuggestion-list'], className?.list),
        id: `${parentInputName}-list`,
      })}
    >
      {state.items.map((item: { value: any; label: string }, index: any) => (
        <li
          {...getItemProps({
            key: item.value,
            index,
            item,
          })}
        >
          {item.label}
        </li>
      ))}
    </ul>
  ) : null;
}

function Autocomplete(props: AutocompleteType) {
  const [state, setstate] = React.useState<{ [key: string]: any }>({
    defaultItems: props.defaultItems,
    initialItemLoaded: false,
    itemsForTag: (props.multiselectTagList && props.multiselectTagList.split(',')) || [],
  });
  const [loadingState, setLoadingState] = useState(false as boolean);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const clearSelectionRef = React.useRef<() => void>(() => {});

  React.useEffect(() => {
    if (!state.initialItemLoaded) {
      setstate({
        ...state,
        initialItemLoaded: true,
        defaultItems: props.defaultItems,
      });
    }

    return () => {};
  }, [props.defaultItems, state]);

  React.useEffect(() => {
    if (props.disabled && clearSelectionRef.current) {
      clearSelectionRef.current();
    }
  }, [props.disabled]);

  const handleOnTagClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();

    setstate({
      ...state,
      itemsForTag: state.itemsForTag.filter(
        (x: string) => x !== event.currentTarget.getAttribute('data-name')
      ),
    });

    setTimeout(() => {
      props.handleChange({ target: inputRef.current });
    }, 1000);
  };

  const getInitialInputValue = () => {
    if (['string', 'number'].includes(typeof props.value)) {
      return props.value;
    } else {
      return state.defaultItems?.length > 0 ? state.defaultItems[0].value : '';
    }
  };

  return (
    <>
      <div className={classNames(styles['autocomplete-wrapper'])}>
        {state.initialItemLoaded && (
          <Downshift
            onChange={(selection, stateHelpers) => {
              if (!state.itemsForTag.find((x: string) => x === selection?.label)) {
                setstate({
                  ...state,
                  itemsForTag: state.itemsForTag.concat(selection?.label),
                });
              }

              setTimeout(() => {
                props.handleChange({ target: inputRef.current });
              }, 1000);

              if (selection) {
                props.callBack(AutocompleteActionType.SELECTED, selection);
              }
            }}
            itemToString={(item) => {
              if (item && item.formatCb) return item.formatCb(item);
              return item ? item.value : '';
            }}
            initialInputValue={getInitialInputValue()}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem,
              clearSelection,
            }) => {
              clearSelectionRef.current = clearSelection;

              return (
                <div>
                  <div className={classNames(styles.autocomplete)}>
                    <input
                      id={`${props.name}-input`}
                      data-testid={`test-${props.name}`}
                      data-customfield={TextformatType.AUTOCOMPLETE}
                      data-parentid={props.name}
                      data-selectedtags={selectedItem}
                      data-selecteditem={JSON.stringify(selectedItem)}
                      name={props.name}
                      aria-invalid={props?.name && props.errors[props.name] ? 'true' : 'false'}
                      aria-describedby={`error-${props.name}`}
                      ref={inputRef}
                      {...getInputProps({
                        className: classNames(styles.input, props.className),
                        onChange: props.handleChange(),
                        disabled: props.disabled,
                        onBlur: () => {
                          if (!inputValue) {
                            clearSelection();
                          }
                        },
                      })}
                      placeholder={props.placeholder}
                      tabIndex={props.tabIndex}
                      disabled={props.disabled}
                    />
                    <span className={classNames(styles.holder)}>
                      {loadingState ? (
                        <span className={classNames(styles.loading)} />
                      ) : (
                        <span className={classNames(styles.icon, 'material-icons')}>search</span>
                      )}
                    </span>
                  </div>

                  {isOpen && (
                    <FetchItems
                      getItemProps={getItemProps}
                      getInputProps={getItemProps}
                      getMenuProps={getMenuProps}
                      inputValue={inputValue}
                      callBack={props.callBack}
                      defaultItems={props.defaultItems}
                      className={props.className}
                      onLoading={(newState: boolean) => setLoadingState(newState)}
                      parentInputName={props.name}
                    />
                  )}
                </div>
              );
            }}
          </Downshift>
        )}
        {props.multiselectTag && (
          <div className={classNames(styles['tag-container'])}>
            {state.itemsForTag.map((tag: any) => (
              <span className={classNames(styles['tag-holder'])} key={tag}>
                {tag}
                <i
                  role="button"
                  tabIndex={0}
                  data-name={tag}
                  className={classNames('material-icons')}
                  onClick={handleOnTagClose}
                >
                  close
                </i>
              </span>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
export default Autocomplete;
