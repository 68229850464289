import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CurrencyInputType } from './type';
import { currencyToNumber, formatToCurrency } from '_shared/utils/currency';
import styles from 'components/field/currencyInput/currencyInput.module.scss';
import i18next from 'i18next';

const CurrencyInput = React.forwardRef(
  (
    { metadata, returnZero, errors, handleChange, disabled, value }: CurrencyInputType,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [currentValue, setCurrentValue] = useState<string>('');

    function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
      if (isNaN(Number(e.target.value.replace(/,/g, '')))) {
        return;
      }

      e.target.value = JSON.stringify(currencyToNumber(e.target.value));
      handleChange(e);
    }

    useEffect(() => {
      setCurrentValue(formatToCurrency(value || '', returnZero));
    }, [returnZero, value]);

    return (
      <div className={classNames(styles['currency-input-wrapper'])}>
        <input
          id={metadata.id}
          ref={ref}
          data-testid={`test-${metadata.id}`}
          name={metadata.name}
          tabIndex={metadata.tabIndex}
          aria-invalid={metadata.name && errors[metadata.name] ? 'true' : 'false'}
          aria-describedby={`error-${metadata.name}`}
          value={currentValue}
          onChange={changeHandler}
          disabled={disabled}
          placeholder={metadata.placeholder}
        />
        <span>{i18next.t('currency:symbol')}</span>
      </div>
    );
  }
);

export default CurrencyInput;
