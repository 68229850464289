import classNames from 'classnames';
import styles from './SplitPane.module.scss';
import React, { ReactNode, useState } from 'react';

type SplitPaneProps = {
  left: ReactNode;
  right: ReactNode;
  leftPaneSize?: number; // size of the left pane in percentage
};

const SplitPane: React.FC<SplitPaneProps> = ({ left, right, leftPaneSize = 80 }) => {
  const [rightPaneVisible, setRightPaneVisible] = useState(true);

  const containerStyle = {
    '--left-pane-size': `${leftPaneSize}%`,
  } as React.CSSProperties;

  return (
    <div
      className={classNames(styles['split-pane-container'], {
        [styles['right-pane-visible']]: rightPaneVisible,
      })}
      style={containerStyle}
    >
      <div className={styles['split-pane-left']}>
        {left}
        <button
          className={styles['toggle-button']}
          onClick={() => setRightPaneVisible(!rightPaneVisible)}
          data-testid="test-toggle-button"
        >
          <span className={styles['icon']}>
            <i className={'material-icons'}>
              {rightPaneVisible ? 'chevron_right' : 'chevron_left'}
            </i>
            <i className={'material-icons'}>
              {rightPaneVisible ? 'chevron_right' : 'chevron_left'}
            </i>
          </span>
        </button>
      </div>
      <div className={styles['split-pane-right']}>{rightPaneVisible ? right : null}</div>
    </div>
  );
};

export default SplitPane;
