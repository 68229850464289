import { toast } from 'react-toastify';
import { ToastActionTypes, TOAST_MESSAGE, TOAST_ERROR_MESSAGE, ToastState } from './types';

export const initialState: ToastState = {
  toastMessage: '',
};

export function toastReducer(state = initialState, action: ToastActionTypes): ToastState {
  switch (action.type) {
    case TOAST_MESSAGE: {
      toast.dismiss();
      toast(action.payload.toastMessage, {
        hideProgressBar: true,
        className: 'sw-toast',
        closeButton: false,
        position: 'bottom-center',
      });
      return { ...state, ...action.payload };
    }
    case TOAST_ERROR_MESSAGE: {
      toast.dismiss();
      toast(action.payload.toastMessage, {
        hideProgressBar: true,
        className: 'sw-toast sw-toast-error',
        closeButton: false,
        position: 'bottom-center',
        type: 'error',
      });
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
