import Unsecured from './components/unsecured';
import MachineryEquipment from './components/machineryAndEquipment';
import VehicleEssentials from './components/vehicle';
import SoftAssetsEssentials from './components/softAssets';
import PropertyEssentials from './components/property';

import { FormFieldEssentials } from './types';

type FormComponentProps = {
  fetchedData: FormFieldEssentials[];
  dateCreated: string | null;
  editDetails: () => void;
  journeyDisplayName: string;
  journeyName: string;
};

// UK
const formComponentConfig: { [key: string]: React.FC<FormComponentProps> } = {
  businessExpansion: Unsecured,
  debtRefinance: Unsecured,
  importExportFinance: Unsecured,
  improveCashflow: Unsecured,
  inventoryFinance: Unsecured,
  machineryAndEquipment: MachineryEquipment,
  vehicleFinance: VehicleEssentials,
  softAssetFinance: SoftAssetsEssentials,
  propertyFinance: PropertyEssentials,
};

export const allowedEssentialsFormNames = [
  'businessExpansion',
  'debtRefinance',
  'importExportFinance',
  'improveCashflow',
  'inventoryFinance',
  'machineryAndEquipment',
  'vehicleFinance',
  'softAssetFinance',
  'propertyFinance',
];

export default formComponentConfig;
