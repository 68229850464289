import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './InstantVatAdvance.module.scss';
import icon from '../../assets/instant-vat-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import { InfoboxDesciptions } from '../../types';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';
import ProcessSteps from 'pages/companySavingsProducts/components/ProcessSteps';

const InstantVatAdvance: React.FC = () => {
  const { isLoading, postProductApplication } = useSavingsProducts('instant-vat-advance');

  const infoboxes: InfoboxDesciptions = {
    amount: '£1,000 to £1m',
    purpose: 'Improve cashflow.',
    speed: '60min',
    suitablefor: 'All businesses.',
    cost: '3% + VAT',
  };

  const handleSubmit = async () => {
    window.open('https://www.adsum-works.com/?partner_id=swoop', '_blank', 'noopener');
    await postProductApplication('instant-vat-advance');
  };

  const processSteps = [
    {
      title: 'Sign up',
      description:
        'The initial set up is quick and easy. Just let us know where you want your client’s VAT refund sent to and provide access to their government gateway..',
    },
    {
      title: 'Submit your VAT return',
      description:
        'Once your client’s account set up is finalised, submit their VAT return however you normally do. Their current business and accounting processes don’t need to change!',
    },
    {
      title: 'Get your VAT refund within 60 minutes',
      description:
        'That’s it! Get them a VAT refund in their bank account within 60 minutes of submitting the return.',
    },
  ];

  const informationalItems = [
    { title: 'VAT-registered, UK company', icon: 'check_circle', id: 'check_circle' },
    { title: '2 past VAT refunds paid by HMRC', icon: 'check_circle', id: 'check_circle' },
  ];

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Instant VAT advance"
        icon={icon}
        ctaText="Book a meeting"
        ctaCallback={handleSubmit}
        isCtaDisabled={false}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h2>Make VAT refunds work for your client - instantly</h2>
          <p>
            Sign up your client before they submit their VAT return to HMRC and get them a refund
            within 60 minutes with our partner{' '}
            <a
              href="https://www.adsum-works.com/?partner_id=swoop"
              target="_blank"
              rel="noopener noreferrer"
              className={cn(styles['adsum-keyword'])}
            >
              Adsum.
            </a>
          </p>
          <div className={cn(styles['fact-box'])}>
            <div className={cn(styles['fact'])}>
              <h1>£160m</h1>
              <p>Advanced in VAT refunds and tax credits so far</p>
            </div>
            <div className={cn(styles['fact'])}>
              <h1>100%</h1>
              <p>HMRC Success</p>
            </div>
          </div>
          <br />
          <h2>How instant VAT refunds work for UK companies</h2>
          <ProcessSteps steps={processSteps} />
          <h2>How do I know if my client’s VAT refund is eligible?</h2>
          <p>
            To qualify for an Instant VAT Refund, your client’s company must be a UK company that
            has received at least 2 VAT refunds in full from HMRC.
          </p>
          <br />
          <div className={cn(styles['informational-content-container'])}>
            {informationalItems.map((item) => {
              return (
                <div className={cn(styles['informational-text'])} key={item.title}>
                  <span className={cn('material-icons', styles['check-icon'])}>{item.icon}</span>
                  <h1>{item.title}</h1>
                </div>
              );
            })}
          </div>
        </div>
        <SavingsProductsQuickInfoBox descriptions={infoboxes} />
      </div>
    </div>
  );
};

export default InstantVatAdvance;
