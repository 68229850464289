import { useAppState } from 'store';
import styles from '../../assets/roleManagement.module.scss';
import cn from 'classnames';
import useForm from '_shared/hooks/useForm';
import validation from '_shared/fieldValidation';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';
import Field from 'components/field';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import Spinner from 'components/spinner';
import { ActionType } from 'pages/userDetails/reducer';
import { parseToExternalAdmin } from '_shared/utils/roles';
import { Role, UserRoles } from 'config/roles';
import useToastMessage from '_shared/hooks/useToastMessage';
import { patchUserRoles } from '_shared/api/users';
import { putUserRoles } from '_shared/api/roles';

type RoleManagementProps = {
  userDetailsState: any;
  userDetailsDispatch: any;
  securityId: string;
  refreshCurrentUserRoles: any;
};

const removeIamAdminRoleFromRolesDropdown = (metadata: FieldMetaDataPropType[]) => {
  metadata[0].value = metadata[0].value?.filter((role: string) => role !== 'Iam Admin');
  return metadata;
};

const RoleManagement = ({
  userDetailsState,
  userDetailsDispatch,
  securityId,
  refreshCurrentUserRoles,
}: RoleManagementProps) => {
  const [ableToSave, setAbleToSave] = useState<boolean>(false);
  const { t } = useTranslation();

  const store = useAppState();
  const isUserAdmin = Boolean(store.state.system.isAdminOrSuperAdmin?.());
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const handleSaveChanges = async () => {
    if (!values?.roleIds?.length) {
      errorToastMessage(t('home:colleagues:details:toast:selectrole'));
      return;
    }

    try {
      userDetailsDispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: true,
        },
      });

      const roleNames = values.roleIds?.flatMap((value: string) => {
        if (value.includes(' ')) value = value.split(' ').join('');

        const existingRoles = userDetailsState.currentUserRoles.filter((existingRole: string) => {
          return (
            parseToExternalAdmin(existingRole, store.state.system.currentUser?.groupId) === value
          );
        });
        const roles = userDetailsState.availableUserRoles.filter(
          (availableRole: { [key: string]: any }) => {
            return availableRole.name === value;
          }
        );
        const names = [
          ...existingRoles.map((role: Role) => role.name),
          ...roles.map((role: { name: string }) => role.name),
        ];
        return names.filter((name) => name !== undefined);
      });

      if (userDetailsState.currentUserRoles.includes(UserRoles.IAM_ADMIN)) {
        roleNames.push(UserRoles.IAM_ADMIN);
      }

      if (userDetailsState.currentUserRoles.includes(UserRoles.SUPER_ADMIN)) {
        roleNames.push(UserRoles.SUPER_ADMIN);
      }

      if (store.state.system.isUserManager?.()) {
        await patchUserRoles(securityId, { roles: roleNames });
      } else {
        const roleIdsToAdd = userDetailsState.availableUserRoles.reduce(
          (result: any[], role: any) => {
            return roleNames.includes(role.name) ? [...result, role.id] : result;
          },
          []
        );
        await putUserRoles(securityId, { RoleIds: roleIdsToAdd });
      }

      successToastMessage(t('home:colleagues:details:toast:assignedsucces'));

      refreshCurrentUserRoles();

      setAbleToSave(false);
    } catch (error) {
      console.error(error);
      userDetailsDispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
      errorToastMessage(t('home:colleagues:details:toast:assignfailed'));
    } finally {
      userDetailsDispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };

  const SaveChanges = useCallback(() => {
    return (
      <div className={styles['save-btn-container']}>
        <button
          className={cn(styles['save-button'], !ableToSave && styles.inactive)}
          onClick={handleSubmit}
          disabled={!ableToSave}
        >
          {userDetailsState.loading ? <Spinner size="small" /> : 'Save role changes'}
        </button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsState.loading, ableToSave]);

  const { handleChange, handleSubmit, errors, metadata, values } = useForm(
    {},
    userDetailsState.customUserRoles,
    handleSaveChanges,
    validation
  );

  useEffect(() => {
    if (!Object.keys(values).length) {
      setAbleToSave(false);
      return;
    }
    setAbleToSave(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className={styles['role-management-container']}>
      <h3>Roles</h3>
      <p>
        Roles within the application dictate the permissions granted to a colleague and outlines
        their access and actions within the platform.
      </p>

      <h3>Custom Role Assignment</h3>
      <p>Tailor roles to specific colleague needs.</p>

      <p className={cn(styles['warnng-text'])}>
        The <strong>Super Admin</strong> role should <strong>ONLY</strong> ever be granted to
        authorised <strong> company personnel</strong>.
      </p>

      <div>
        {(metadata.length &&
          removeIamAdminRoleFromRolesDropdown(metadata).map((field: FieldMetaDataPropType) => {
            return (
              <Field
                {...{
                  key: field.id,
                  metadata: field,
                  errors: errors,
                  handleChange: handleChange,
                }}
              />
            );
          })) ||
          'No Roles assigned'}

        {isUserAdmin ? SaveChanges() : <></>}
      </div>
    </div>
  );
};

export default RoleManagement;
