import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './ResearchAndDevelopment.module.scss';
import icon from '../../assets/rnd-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import { InfoboxDesciptions } from '../../types';
import SavingsProductsForm from 'pages/companySavingsProducts/components/Form';
import { rndFormFields } from './form.fields';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';
import { useEffect, useRef, useState } from 'react';
import { FieldMetaDataPropType } from '_shared/fieldValidation/types';

const ResearchAndDevelopment: React.FC = () => {
  const formRef = useRef<HTMLDivElement | null>(null);
  const { isLoading, isAvailable, postProductApplication, scrollToForm } = useSavingsProducts(
    'research-and-development'
  );
  const [formFields, setFormFields] = useState<FieldMetaDataPropType[] | null>(null);

  useEffect(() => {
    if (!isAvailable) {
      // reset form fields after submit
      return setFormFields(rndFormFields.map((field) => ({ ...field })));
    }
    setFormFields(rndFormFields);
  }, [isAvailable]);

  const infoboxes: InfoboxDesciptions = {
    amount:
      '18.6% of your qualifying R&D for SME R&D tax relief and 12% for Research and Development Expenditure Credit (RDEC).',
    speed: 'Receive cash 4-8 weeks after filing your claim for tax credits.',
    purpose: 'Government tax relief to encourage R&D',
    suitablefor:
      'Limited companies carrying out R&D in science and technology (including IT – it’s a broad definition).',
    cost: 'Varies depending on the size of your claim',
  };

  const handleSubmit = async (values: { [key: string]: string }) => {
    await postProductApplication('research-and-development', values);
  };

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Research & development"
        icon={icon}
        ctaText="Check eligibility"
        ctaCallback={() => scrollToForm(formRef.current)}
        isCtaDisabled={!isAvailable}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h3>
            R&D tax credits are a cash payment from the government to encourage companies to conduct
            research and development, as well as other innovative projects.
          </h3>
          <p>
            If your client’s business innovates and carries out research and development, the
            government has help in the form of grants and tax credits.
            <br />
            <br />
            This type of funding will only come back to your client after they’ve spent the money.
            Many businesses don’t carry out all the R&D they should, because the upfront costs are
            holding them back.
            <br />
            This is where Swoop comes in. Not only can we help your client make sure they get the
            tax credits they’re eligible for, but we can also help them get grants for innovation.
            <br />
            <br />
            Our trusted specialists help your client maximise their claim by identifying their
            innovative projects while simplifying the process and taking care of your claim for
            them.
            <br />
            <br />
            Here are the kinds of projects and activities that would be eligible for R&D tax
            credits:
            <br />
            <br />
            Overcoming technical challenges
            <br />
            Creating and testing prototypes
            <br />
            Streamlining processes
            <br />
            Trialling new or substituting materials
            <br />
            Developing bespoke software
            <br />
            Trial and error industry firsts
          </p>
          <br />
          <div className={cn('divider', styles.divider)} />
          <h3>Check if we can help your client’s R&D claim by answering a few simple questions</h3>
          {formFields && (
            <SavingsProductsForm
              isSubmitDisabled={!isAvailable}
              forwardedRef={formRef}
              fields={formFields}
              submitCallback={handleSubmit}
              title="Research and development"
              submitButtonText="Check eligibility"
            />
          )}
        </div>
        <SavingsProductsQuickInfoBox descriptions={infoboxes} />
      </div>
    </div>
  );
};

export default ResearchAndDevelopment;
