import { TablePropType } from 'components/table/type';

export enum SORTTYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum SORTBY {
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  FUNDER_NAME = 'FUNDER_NAME',
  PRODUCT_SUBCATEGORY = 'PRODUCT_SUBCATEGORY',
}

export type productsStateType = {
  redirectToReferrer: boolean;
  loading: boolean;
  from: any;
  field: TablePropType;
  items: any;
  pageNumber: number;
  filter: {
    pageSize: number;
    onlyActive: boolean;
    type: string;
    subcategory: string;
  };
  products: {
    data: any;
    loading: boolean;
    error: boolean;
    isEmpty: boolean;
  };
  searchProduct: string;
};

export enum ActionType {
  DATALOADER,
  SETSEARCHQUERYTIMER,
  SETPRODUCTS,
  SETSEARCHPRODUCT,
  TOGGLE_ENABLED,
  SET_PAGE_SIZE,
  SET_TYPE_FILTER,
  SET_SUBTYPE_FILTER,
}
export type Action = {
  type: ActionType;
  payload: any;
};

export function productsReducer(state: productsStateType, action: Action) {
  switch (action.type) {
    case ActionType.DATALOADER:
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          filteredDataLoading: false,
        };
      }
      return {
        ...state,
        loading: action.payload,
      };

    case ActionType.SETSEARCHQUERYTIMER:
      return {
        ...state,
        timeoutId: null,
      };

    case ActionType.SETPRODUCTS:
      if (action.payload.products.length > 0) {
        return {
          ...state,
          products: { ...state.products, isEmpty: true },
        };
      }
      return {
        ...state,
        products: { ...state.products, ...action.payload.products },
      };

    case ActionType.SETSEARCHPRODUCT:
      return {
        ...state,
        searchProduct: action.payload || '',
      };
    case ActionType.TOGGLE_ENABLED:
      return {
        ...state,
        filter: { ...state.filter, onlyActive: !state.filter.onlyActive },
      };
    case ActionType.SET_PAGE_SIZE:
      return {
        ...state,
        filter: { ...state.filter, pageSize: action.payload.pageSize },
      };
    case ActionType.SET_TYPE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, type: action.payload.type },
      };
    case ActionType.SET_SUBTYPE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, subcategory: action.payload.subcategory },
      };
    default:
      return state;
  }
}
