import cn from 'classnames';
import SavingsProductsHeader from 'pages/companySavingsProducts/components/Header';
import styles from './BusinessSavingsAccounts.module.scss';
import icon from '../../assets/business-savings-accounts-icon.svg';
import SavingsProductsQuickInfoBox from '../../components/QuickInfoBox';
import { InfoboxDesciptions } from '../../types';
import useSavingsProducts from 'pages/companySavingsProducts/hooks/useSavingsProducts.hook';

const BusinessSavingsAccounts: React.FC = () => {
  const { isLoading, postProductApplication } = useSavingsProducts('business-savings-accounts');

  const infoboxes: InfoboxDesciptions = {
    amount: 'Any surplus funds (min. £50,000)',
    purpose:
      'Generate market-leading savings rates on surplus funds, all from one centralised platform.',
    speed:
      'Choose from instant access, notice accounts, or fixed terms (e.g. 6 months, 12 months, 24 months).',
    suitablefor:
      'Limited companies with surplus funds sitting in low interest-bearing bank accounts (e.g. current accounts) looking to achieve higher savings rates.',
    cost: 'Depends on the size of the deposit (max. 0.25%)',
  };

  const handleSubmit = async () => {
    window.open('https://swoopfunding.akonihub.com/home', '_blank', 'noopener');
    await postProductApplication('business-savings-accounts');
  };

  return (
    <div className={cn('wrapper', styles.wrapper)}>
      <SavingsProductsHeader
        title="Business Savings Accounts"
        icon={icon}
        ctaText="Register your client"
        ctaCallback={handleSubmit}
        isCtaDisabled={false}
        isLoading={isLoading}
      />
      <div className={cn('divider', styles.divider)} />

      <div className={cn(styles.container)}>
        <div className={cn('text-container', styles['text-content'])}>
          <h2>
            Given current high interest rates, now's the time to put your client's company's surplus
            funds to work with leading business savings accounts - the smart way to make their money
            make more money, faster.
          </h2>
          <p>
            Small and medium enterprises (SMEs) who don't have a business savings account could be
            missing out on an easy windfall that could add £1,000's or £10,000's to their bottom
            line. The latest figures from UK Finance show that £186.1 billion of SME funds are
            sitting dormant in current accounts, which often pay no or little interest. The Centre
            for Economic and Business Research (CEBR) estimate that this is causing these businesses
            to miss out on £4.2 billion in extra interest each year. So, why doesn't every business
            have a savings account? Not everyone is aware of the products available to them, plus
            business owners worry about the complexity in managing multiple accounts, as well as
            minimum deposit sizes and lengthy fixed terms.
            <br />
            <br />
            But... Swoop is here to help!{' '}
            <a href="https://swoopfunding.akonihub.com/home">Our aggregator tool</a> combines the
            best available savings products based on your client's needs, allowing you to manage
            thier savings from one place, secure FSCS deposit protection, and maximise their
            interest rate.
          </p>
          <h2>What is a business savings account?</h2>
          <p>
            It's a business bank account available to businesses - also known as a 'business deposit
            account'. Business savings accounts are the perfect place for SMEs to store their
            surplus cash and earn extra money from saver's interest.
          </p>
          <h2>How do they work?</h2>
          <p>
            Business savings accounts can be opened by sole traders, partnerships, or limited
            companies. They may function as a twin to your client's regular business current
            account, acting as a second, interest-paying account with the same bank, or they can be
            opened as stand-alone savings accounts held at a completely different financial
            institution.
            <br />
            <br />
            Business savings accounts will typically require a minimum starting deposit at opening.
            Depending on the type of account selected, this can vary from £1 up to £10,000 or more.
            Most business savings accounts will also limit their maximum balance - anywhere from
            £85,000 up to a few million pounds. Interest earned on your client's savings balance is
            usually paid into their account on either a monthly or annual basis.
          </p>
          <h2>Does your client even need a business savings account?</h2>
          <p>
            If they have spare cash sitting idle in their business current account - where it
            typically earns zero interest - then yes, they do. Amid{' '}
            <a href="https://swoopfunding.com/uk/blog/how-to-boost-cash-flow/">
              a tough economic climate
            </a>{' '}
            and rising business costs, it makes no sense to miss any opportunity to collect what is
            essentially free cash.
          </p>

          <h2>What types of accounts are available?</h2>
          <p>
            The most common types of business savings account are easy-access, fixed-rate, and
            notice accounts.
          </p>
          <h3>Easy access account</h3>
          <p>
            The business savings account that does what it says on the tin. Save money, earn
            interest, give your client access to their money whenever they need it. Easy access
            accounts give business savers the most flexibility, but they will typically offer a
            lower rate of interest than other types of account and have a lower maximum savings
            ceiling - often set at £85,000. Easy-access account interest rates are usually variable,
            so they can go up or down. Interest can be paid monthly or annually.
          </p>
          <h3>Fixed-rate account</h3>
          <p>
            Also called a 'business savers bond account', fixed rate accounts are for businesses who
            know they won't need their funds at short notice. Savings are locked away for a set
            period, usually one or two years, and there is usually no interim access available.
            Fixed-rate accounts offer higher interest than easy access accounts, with two-years
            earning more than one-year accounts. The required opening deposit is also typically
            higher than for a flexible account, but the maximum savings ceiling may be as high as
            £2,000,000. Interest rates are fixed, so they remain stable throughout the savings term.
            Interest can be paid monthly or annually.
          </p>
          <h3>Notice account</h3>
          <p>
            Notice accounts are a hybrid of the easy-access and fixed-rate products. With a notice
            account, your client agrees to provide 45, 100, or more days' notice to the bank before
            making a withdrawal. The longer their agreed notice period, the higher the interest rate
            they receive. Notice accounts usually require a higher opening deposit than easy-access
            accounts, and the maximum savings ceiling can be as high as £2,000,000. Interest rates
            are variable, so they may fluctuate up or down. Interest can be paid monthly or
            annually.
          </p>
          <h2>Current rates on offer</h2>
          <table className={cn('table', styles.table)}>
            <thead>
              <tr className={cn('table-head', styles['table-head'])}>
                <th>EASY ACCESS</th>
                <th>6-MONTH FIXED</th>
                <th>1-YEAR FIXED</th>
              </tr>
            </thead>
            <tbody>
              <tr className={cn('table-row', styles['table-row'])}>
                <td>3-4%</td>
                <td>5-6%</td>
                <td>6-7%</td>
              </tr>
            </tbody>
          </table>
          <h2>Why use Swoop's aggregator tool?</h2>
          <p>
            Swoop has worked to develop an{' '}
            <a href="https://swoopfunding.akonihub.com/home">aggregation tool</a> which enables
            businesses to manage their cash from a single savings dashboard. Your client will find
            easy and fast access to rates from a variety of highly-rated banks. Businesses make a
            single deposit to a hub account and can then access multiple savings accounts. The range
            of choice on offer means your client could be earning more interest on thier cash across
            multiple FSCS-protected bank deposits.
          </p>
          <h2>Things to consider when choosing</h2>
          <h3>How much access to their money does your client get?</h3>
          <p>
            If you think they will need access to their savings at short notice, an easy-access
            account is probably best for them. These accounts will usually offer lower interest
            rates, but they'll be able to withdraw their money whenever they want. If you think they
            won't need the funds for a while, you could consider a fixed-rate account that pays a
            higher rate of interest.
          </p>
          <h3>What is the interest rate?</h3>
          <p>
            Clearly, you want your client's savings to earn as much interest as possible. The longer
            they are prepared to lock their funds away, the higher the interest they will usually
            receive. However, as said above, the need for good interest must be balanced with your
            client's potential need to access their funds.
          </p>
          <h3>Are there penalties for withdrawing money?</h3>
          <p>
            Most business savings accounts will incur charges and fees for servicing and standard
            transactions, but some accounts (fixed-rate and notice), may also levy an 'early
            withdrawal penalty' for taking cash out before the end of the savings term. Some
            fixed-accounts will not allow early withdrawals at all.
          </p>
          <h3>How does my client manage their account: online, in branch, by post etc?</h3>
          <p>
            Some business savings account are online access only, others may offer or even require
            your client to manage their account by post or a visit to the bank branch, and some
            accounts will offer all types of access. Online accounts are typically more convenient,
            incur lower fees, and are easier to manage - important considerations for busy small
            businesses.
          </p>
          <h3>Is there a minimum amount my client will need to deposit to open the account?</h3>
          <p>
            Opening deposits can vary significantly by account type and provider. Pick an account
            that gives them the services they are looking for with a deposit requirement that suits
            thier business cashflow.
          </p>
        </div>
        <SavingsProductsQuickInfoBox descriptions={infoboxes} />
      </div>
    </div>
  );
};

export default BusinessSavingsAccounts;
