import React from 'react';
import { FormFieldEssentials } from './types';
import styles from './assets/companyEssentials.module.scss';

type FormData = {
  id: string;
  fetchedData: FormFieldEssentials[];
  dateCreated: string;
  journeyDisplayName: string;
  journeyName: string;
};

type Option = {
  value?: string;
  label?: string;
};

export const formatDate = (dateString: string, monthLength: 'long' | 'short') => {
  if (dateString) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: monthLength,
      year: 'numeric',
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formattedDate;
  } else {
    return null;
  }
};

export const formatDateDots = (dateString: string | null) => {
  if (dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  } else {
    return null;
  }
};

export const renderTextWithLineBreaks = (text: any): React.ReactElement => {
  const str = String(text); // Convert to string if not already

  // If the text is empty, null, or undefined, return an empty React Fragment
  if (str === 'null' || str === 'undefined' || !str) {
    return <></>;
  }

  const generateKey = (line: string) => {
    // Remove spaces and punctuation, then use the line as a key
    return line.replace(/[^\w]/g, '') || Math.random().toString(36).slice(2, 9); // 2nd part in case the string is empty
  };

  return (
    <>
      {str.split('\n').map((line, index) => (
        <React.Fragment key={generateKey(line)}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export const getValueByFieldName = (formData: FormData[], fieldName: string): string | null => {
  for (const form of formData) {
    const foundItem = form.fetchedData.find(
      (field: FormFieldEssentials) => field.name === fieldName
    );
    if (foundItem) {
      return handleFoundItem(fieldName, foundItem.value);
    }
  }
  return null; // If no value is found
};

const handleFoundItem = (fieldName: string, value: any): string | null => {
  if (typeof value === 'string') {
    return handleStringValue(fieldName, value);
  } else if (typeof value === 'number') {
    return handleNumberValue(value);
  } else if (
    Array.isArray(value) &&
    value.every((item) => item && typeof item.label === 'string')
  ) {
    return handleArrayValue(value);
  }
  return null;
};

const handleStringValue = (fieldName: string, value: string): string => {
  // Check if the field is 'businessWebsite' and handle the URL logic
  if (fieldName === 'businessWebsite') {
    return value.startsWith('http://') || value.startsWith('https://') ? value : `http://${value}`;
  }
  return value;
};

const handleNumberValue = (value: number): string => {
  return value.toLocaleString();
};

const handleArrayValue = (value: Option[]): string => {
  // Check if array elements are objects with 'label' property
  const labels = value.map((item) => item.label);
  return labels.join(',\n'); // Concatenate labels into a string
};

export const formatIndustries = (
  industries: FormData[],
  navigate: () => void
): React.ReactElement | null => {
  const sectorsFields = industries
    .map((industry) => industry.fetchedData.find((field) => field.name === 'sectors'))
    .filter((field) => Array.isArray(field?.value));

  if (sectorsFields.length === 0) {
    return null;
  }

  // Combine all sector values and filter out duplicates by the 'label'
  const sectors = sectorsFields.reduce((acc, field) => {
    if (Array.isArray(field?.value)) {
      field?.value.forEach((sector) => {
        if (!acc.some((item) => item.label === sector.label)) {
          acc.push(sector);
        }
      });
    }
    return acc;
  }, [] as { label: string }[]);

  if (sectors.length === 0) {
    return null;
  } else if (sectors.length === 1 && typeof sectors[0]?.label === 'string') {
    return <>{sectors[0].label}</>;
  } else if (sectors.length > 1 && typeof sectors[0]?.label === 'string') {
    const industryCount = sectors.length - 1;
    return (
      <>
        {sectors[0].label} (
        <a
          className={styles['industry-details__link']}
          onClick={() => navigate()}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigate();
            }
          }}
          tabIndex={0}
          href="#"
          aria-label={`Show ${industryCount} more industries`} // Keep aria-label for accessibility
        >
          {`+${industryCount}`}
        </a>
        )
      </>
    );
  } else {
    return null;
  }
};
