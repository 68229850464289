import { useCallback, useEffect, useMemo, useState } from 'react';
import { dealsStatusList, pageSizes } from '_shared/utils/constants';
import { FilterType } from 'components/field/advancedDatePicker/type';
import { getDateBySearchString } from 'components/field/advancedDatePicker/utils/utils';
import { useSearchParams } from 'react-router-dom';
import { CreatorType, Filters, FilterSearchParam } from '../types';

const useDealSearchParams = (defaultParams?: Partial<Record<FilterSearchParam, string | null>>) => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const [isInitialised, setIsInitialised] = useState(false);

  // Functions to get and sanitize search params

  const getOrderBy = () => {
    const orderByParam = searchParams.get(FilterSearchParam.OrderBy);

    const isValidParam = ['creatorType', 'companyName', 'amount', 'lastProgressed'].some(
      (param) => param === orderByParam
    );

    return isValidParam ? (orderByParam as string) : 'lastProgressed';
  };

  const getDirection = () => {
    const directionParam = searchParams.get(FilterSearchParam.Direction) ?? '';

    return directionParam && ['asc', 'desc'].includes(directionParam)
      ? (directionParam as 'asc' | 'desc')
      : 'desc';
  };

  const getPageSize = () => {
    const pageSizeParam = searchParams.get(FilterSearchParam.PageSize);
    const isValidPageSize = pageSizes.some(({ value }) => value === pageSizeParam);

    return isValidPageSize ? Number(pageSizeParam) : 20;
  };

  const getCompanyName = () => {
    return searchParams.get(FilterSearchParam.CompanyName) ?? '';
  };

  const getPageNumber = () => {
    return Number(searchParams.get(FilterSearchParam.PageNumber)) || 1;
  };

  const getOwnerIds = () => {
    const ownerIdsParam = searchParams.get(FilterSearchParam.OwnerIds);
    return ownerIdsParam ? ownerIdsParam.split(',') : [];
  };

  const getHideCompleted = () => {
    return searchParams.get(FilterSearchParam.HideCompleted) === 'true';
  };

  const getStatus = () => {
    return (searchParams.get(FilterSearchParam.Statuses)?.split(',') ?? []).reduce<string[]>(
      (result, status) => (dealsStatusList[status] ? [...result, status] : result),
      []
    );
  };

  const getLastProgressedFilterType = () => {
    const lastProgressedTypeParam = searchParams.get(FilterSearchParam.LastProgressedFilterType);
    const isValidParam =
      lastProgressedTypeParam &&
      Object.values<string>(FilterType).includes(lastProgressedTypeParam);

    return isValidParam ? (lastProgressedTypeParam as FilterType) : null;
  };

  const getLastProgressedFrom = () => {
    const lastProgressedFromParam = searchParams.get(FilterSearchParam.LastProgressedFrom) ?? '';
    const filterType = getLastProgressedFilterType();
    const fromDate = getDateBySearchString(
      lastProgressedFromParam,
      filterType === FilterType.Before
    );

    return isNaN(fromDate.getTime()) ? null : fromDate;
  };

  const getLastProgressedTo = () => {
    const lastProgressedToParam = searchParams.get(FilterSearchParam.LastProgressedTo) ?? '';
    const toDate = getDateBySearchString(lastProgressedToParam, true);

    return isNaN(toDate.getTime()) ? null : toDate;
  };

  const getCreatorTypes = () => {
    return (searchParams
      .get(FilterSearchParam.CreatorTypes)
      ?.split(',')
      .filter((creatorType) => Object.values<string>(CreatorType).includes(creatorType)) ??
      []) as CreatorType[];
  };

  const getDealSubTypes = () => {
    if (!searchParams.get(FilterSearchParam.subtypes)) return [];

    return searchParams.get(FilterSearchParam.subtypes)?.split(',') ?? [];
  };

  // Below is an object that is used by components that require filtering info for rendering purposes
  // Calculated based on the search params
  const filters = useMemo<Filters>(
    () => ({
      orderBy: getOrderBy(),
      direction: getDirection(),
      pageSize: getPageSize(),
      pageNumber: getPageNumber(),
      ownerIds: getOwnerIds(),
      statuses: getStatus(),
      hideCompleted: getHideCompleted(),
      companyName: getCompanyName(),
      LastProgressedFilterType: getLastProgressedFilterType(),
      LastProgressedFrom: getLastProgressedFrom(),
      LastProgressedTo: getLastProgressedTo(),
      creatorTypes: getCreatorTypes(),
      subtypes: getDealSubTypes(),
    }),
    [searchParams]
  );

  // Below is an object that is for making the opportunity API call
  // Calculated based on the `filters` object above
  const queries = useMemo<Partial<Filters>>(() => {
    const outputFilters: Partial<Filters> = {
      orderBy: filters.orderBy,
      direction: filters.direction,
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber,
      ownerIds: filters.ownerIds,
      statuses: filters.statuses,
      hideCompleted: filters.hideCompleted,
      creatorTypes: filters.creatorTypes,
      subtypes: filters.subtypes,
    };

    if (filters.companyName) {
      outputFilters.companyName = filters.companyName;
    }

    const { LastProgressedFilterType, LastProgressedFrom, LastProgressedTo } = filters;

    if (LastProgressedFilterType === FilterType.Between && LastProgressedFrom && LastProgressedTo) {
      outputFilters.LastProgressedFilterType = LastProgressedFilterType;
      outputFilters.LastProgressedFrom = LastProgressedFrom;
      outputFilters.LastProgressedTo = LastProgressedTo;
    } else if (
      [FilterType.Before, FilterType.After].includes(LastProgressedFilterType!) &&
      LastProgressedFrom
    ) {
      outputFilters.LastProgressedFilterType = LastProgressedFilterType;
      outputFilters.LastProgressedFrom = LastProgressedFrom;
    }

    return outputFilters;
  }, [filters]);

  // Call this to update any search params
  // Set `null` to remove a param
  const setSearchParams = useCallback(
    (params: Partial<Record<FilterSearchParam, string | null>>) => {
      const _params: typeof params = { ...params };
      const currentSearchParams = Object.fromEntries(searchParams.entries());
      // Allow removal of params by setting them to null
      Object.entries(_params).forEach(([key, value]) => {
        if (value === null) {
          delete currentSearchParams[key];
          delete _params[key as FilterSearchParam];
        }
      });

      _setSearchParams(
        {
          ...currentSearchParams,
          ...(_params as Record<FilterSearchParam, string>),
        },
        { replace: true }
      );
    },
    [_setSearchParams, searchParams]
  );

  // Call this to clear all search params
  const clearSearchParams = useCallback(() => {
    _setSearchParams({}, { replace: true });
  }, []);

  // Apply default params before marking as initialised
  useEffect(() => {
    const hasExistingParams = Array.from(searchParams.entries()).length > 0;

    if (!isInitialised && defaultParams && !hasExistingParams) {
      setSearchParams(defaultParams);
    }

    setIsInitialised(true);
  }, []);

  return { isInitialised, filters, queries, setSearchParams, clearSearchParams };
};

export default useDealSearchParams;
