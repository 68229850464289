import styles from '../../assets/creditSafeReport.module.scss';
import cn from 'classnames';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import { FinancialReportType, TableItemStructureType } from './creditSafeReport';
import { getCurrencySymbolFromCurrencyCode } from './util';
import { formatToCurrency } from '_shared/utils/currency';

type CustomVarianceTableProps = {
  title: string;
  financialReportData: FinancialReportType[];
  tableStructure: TableItemStructureType;
};

const CustomVarianceTable = (props: CustomVarianceTableProps) => {
  const { title, financialReportData, tableStructure } = props;

  const displayDateHeaders = (givenDateHeader: any) => {
    const dateWithoutTime = givenDateHeader.split(' ')[0];

    const [month, day, year] = dateWithoutTime.split('/');
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const getNestedValue = (obj: any, path: string) => {
    if (!path) return undefined;
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const getCurrencySymbolFromRowData = (foundCurrencyCode: string, value: any) => {
    if (!foundCurrencyCode || !value || (typeof value === 'object' && value.value === undefined)) {
      return '';
    }
    return getCurrencySymbolFromCurrencyCode(foundCurrencyCode) || '';
  };

  const displayFormattedValue = (rowData: any, value: any) => {
    if (value === undefined) return '-';

    if (rowData.valueKey.startsWith('ratios')) {
      return typeof value === 'object' ? value.value : value || '-';
    }

    if (typeof value === 'object') {
      return formatToCurrency(parseFloat(value.value)) || '-';
    }

    return value || '-';
  };

  return (
    <>
      <span className={styles['table-title']}>{title}</span>

      <div className={styles['table-wrapper']}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell className={styles['dummy-cell']} />
              {financialReportData.map((financialReport: any, index: number) => (
                <TableCell key={index} className={styles['date-headers']}>
                  {displayDateHeaders(financialReport.yearEndDate)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableStructure.map((rowData: any) => (
              <TableRow key={rowData.label}>
                <TableCell className={styles['first-column']}>
                  {rowData.icon !== false && (
                    <span className={cn('material-icons')}>trending_up</span>
                  )}
                  <span>{rowData.label ? rowData.label : 'N/A'}</span>
                </TableCell>

                {financialReportData.map((financialReport: any, index: number) => {
                  const value = getNestedValue(financialReport, rowData.valueKey);

                  return (
                    <TableCell key={index} className={styles['date-cell']}>
                      <div className={styles['date-columns']}>
                        {/* Left content: Show value */}
                        <div className={styles['left-content']}>
                          {rowData.currencySymbol !== false &&
                            getCurrencySymbolFromRowData(financialReport.currency, value)}
                          {displayFormattedValue(rowData, value)}
                        </div>

                        {/* Right content: Show percentage and icon */}
                        {value && typeof value === 'object' && (
                          <div
                            className={cn(styles['right-content'], {
                              [styles['up-green']]: value.direction === 'up',
                              [styles['down-red']]: value.direction === 'down',
                            })}
                          >
                            {value.percentage ? value.percentage + '%' : ''}

                            {value.direction && (
                              <span
                                className={cn('material-icons', styles['icon'], {
                                  [styles['up-green']]: value.direction === 'up',
                                  [styles['down-red']]: value.direction === 'down',
                                })}
                              >
                                {value.direction === 'down' ? 'arrow_downward' : 'arrow_upward'}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default CustomVarianceTable;
