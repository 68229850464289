import React, { ReactNode } from 'react';
import styles from './FormQuestionGroup.module.scss';
import cn from 'classnames';
import FormQuestion, { FormQuestionProps } from './FormQuestion';
import FormSection, { FormSectionProps } from './FormSection';

type FormQuestionGroupProps = {
  icon?: string;
  title: string;
  className?: string;
  children?: ReactNode;
  onAdd?: (ids: string[]) => void;
};

const FormQuestionGroup: React.FC<FormQuestionGroupProps> = ({
  icon,
  title,
  className,
  onAdd,
  children,
}) => {
  const sectionsOrQuestions = React.Children.map(children, (child) => {
    if (React.isValidElement<FormSectionProps>(child) && child.type === FormSection) {
      return React.cloneElement(child, {
        ...child.props,
        key: child.props.title,
        onAdd: (ids: string[]) => onAdd?.(ids),
      });
    }

    if (React.isValidElement<FormQuestionProps>(child) && child.type === FormQuestion) {
      return React.cloneElement(child, {
        ...child.props,
        key: child.props.id,
        onAdd: (id: string) => onAdd?.([id]),
      });
    }

    return null;
  });

  const questionIds = React.Children.toArray(children).reduce<string[]>((result, child) => {
    if (React.isValidElement<FormSectionProps>(child) && child.type === FormSection) {
      return [
        ...result,
        ...React.Children.toArray(child.props.children)
          .filter((c) => React.isValidElement(c) && c.type === FormQuestion)
          .map((c) => (c as React.ReactElement<FormQuestionProps>).props.id),
      ];
    }

    if (React.isValidElement<FormQuestionProps>(child) && child.type === FormQuestion) {
      return [...result, child.props.id];
    }

    return result;
  }, []);

  return (
    <div className={cn(styles['form-question-group'], className)}>
      <div className={styles.header}>
        {icon && <i className={cn('material-icons', styles.icon)}>{icon}</i>}
        <div className={styles.title}>{title}</div>
        <div className={styles.spacer} />
        <button className={styles['add-btn']} onClick={() => onAdd?.(questionIds)}>
          <i className="material-icons">add</i> Add all
        </button>
      </div>
      <div className={styles.content}>{sectionsOrQuestions}</div>
    </div>
  );
};

export default FormQuestionGroup;
