export type stateType = {
  id: string;
  category: string;
  title: string;
  fileName: string;
  fileType: string;
  file: File;
  saving: boolean;
  errorMessage: { category: string; title: string };
};

export enum ActionType {
  SET_FIELDS,
  SET_SAVING,
  SET_ERROR_MESSAGE,
}

export function newDocumentReducer(
  state: stateType,
  action: {
    type: ActionType;
    payload: any;
  }
) {
  switch (action.type) {
    case ActionType.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case ActionType.SET_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case ActionType.SET_SAVING:
      return {
        ...state,
        saving: action.payload.saving,
      };

    default:
      return state;
  }
}
