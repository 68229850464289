import Table from 'components/table';
import { useTranslation } from 'react-i18next';
import { APIDocumentType } from 'pages/documentLibrary/types';
import DocumentTableHook from 'pages/documentLibrary/hooks/DocumentTable.hook';
import DeleteDocument from 'pages/documentLibrary/components/deleteDocument';
import PreviewDocument from 'pages/documentLibrary/components/previewDocument';
import DocumentTableHeading from 'pages/documentLibrary/components/tableHeading';

const DocumentTable = ({ companyId, documentList, loading, error, fetchDocumentList }: any) => {
  const { t } = useTranslation();

  const {
    state,
    selectAllDocumentItems,
    handleOnSearchDocuments,
    setIsDeleteModalOpen,
    confirmDelete,
    handlePreviewDocument,
  } = DocumentTableHook({
    companyId: companyId || '',
    documentList: documentList || [],
    fetchDocumentList,
  });

  const isAccountingDocuments =
    !!documentList &&
    !!documentList
      .filter((doc: APIDocumentType) =>
        state.selectedDocuments?.some((Id: string) => doc.documentId === Id)
      )
      ?.find((doc: APIDocumentType) => doc.isBrokerDocument);

  return (
    <>
      <DocumentTableHeading
        {...{
          searchDocumentsValue: state.searchTerm,
          selectAllDocumentItems,
          handleOnSearchDocuments,
          selectedDocumentsLength: state.selectedDocuments.length,
          isAccountingDocuments,
        }}
      />
      <Table {...state.documents} emptyMessage={t('home:companydetails:documentlibrary:empty')} />
      {(state.isDeleteModalOpen && (
        <DeleteDocument
          {...{
            documents: state.documentsToDelete,
            setIsDeleteModalOpen,
            isDeleteModalOpen: state.isDeleteModalOpen,
            confirmDelete,
            isDeleting: state.loading.isDeleting,
          }}
        />
      )) ||
        null}
      {(state.previewDocument && (
        <PreviewDocument
          document={state.data.find((document: APIDocumentType) =>
            state.previewDocument.includes(document.documentId)
          )}
          isOpen={state.previewDocument}
          close={handlePreviewDocument}
          companyId={companyId || ''}
        />
      )) ||
        null}
    </>
  );
};

export default DocumentTable;
