import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import Tasks from '.';

export const tasksRoutes: RouteObject[] = [
  {
    path: RoutePath.tasks,
    element: withTransaction('Tasks', 'component')(<Tasks />),
  },
];
