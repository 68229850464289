import React from 'react';
import cn from 'classnames';
import styles from './FormSection.module.scss';
import Menu from '../menu';
import MenuItem from '../menu/MenuItem';

type Question = {
  id: string;
  text: string;
  displayOrder: number;
  isRequired: boolean;
};

type FormSectionProps = {
  icon: string;
  title: string;
  subtitle?: string;
  questions: Question[];
  onDelete: (ids: string[]) => void;
};

type QuestionEntryProps = {
  question: Question;
  onDelete: (id: string) => void;
};

const QuestionEntry: React.FC<QuestionEntryProps> = ({ question, onDelete }) => {
  return (
    <div className={styles['question-entry']}>
      <span className={styles['question-label']}>
        {question.text}
        {/* @todo: this will be reverted in the future (DMS-143)
        {question.isRequired && <span className={styles.required}>*</span>} */}
      </span>
      {question.isRequired ? (
        <span className={styles['not-removable']}>Not removable</span>
      ) : (
        <button onClick={() => onDelete(question.id)} className={styles.btn}>
          <i className="material-icons">close</i>
        </button>
      )}
    </div>
  );
};

const FormSection: React.FC<FormSectionProps> = ({
  title,
  subtitle,
  icon,
  questions,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onDeleteAll = () => {
    onDelete(questions.map((question) => question.id));
  };

  return (
    <>
      <div className={styles['form-section']}>
        <div className={styles.header}>
          <div className={cn(styles.icon, 'material-icons')}>{icon}</div>
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
          <button
            className={styles.btn}
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <i className="material-icons">more_vert</i>
          </button>
        </div>
        {questions.length > 0 && (
          <div className={styles.questions}>
            {questions.map((question) => (
              <QuestionEntry
                key={question.id}
                question={question}
                onDelete={(id: string) => onDelete([id])}
              />
            ))}
          </div>
        )}
      </div>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left' }}
      >
        <MenuItem className={styles['delete-btn']} onClick={onDeleteAll}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default FormSection;
