import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from 'pages/companies/assets/filters.module.scss';
import { ICompaniesFiltersProps, SearchByOptions } from './types';
import UserToggle from 'components/userToggle';
import { getUsersByRole, QueryKeys } from '_shared/api/roles';
import { useAppState } from 'store';
import { Role, UserRoles } from 'config/roles';
import { useTranslation } from 'react-i18next';
import { UPDATE_COMPANY } from 'store/company/types';
import { appSpecific, isAdvisorApplication, isBrokerApplication } from '_shared/utils/application';
import TextInput from 'components/field/text';
import features from 'config/features';
import Spinner from 'components/spinner';
import Select from 'components/field/select';
import { useQuery } from '@tanstack/react-query';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';

const searchByOptions = [
  { value: SearchByOptions.CompanyName, label: 'Company Name' },
  { value: SearchByOptions.ContactName, label: 'Contact Name' },
  { value: SearchByOptions.ContactEmail, label: 'Contact Email' },
  { value: SearchByOptions.ContactNumber, label: 'Contact Number' },
];

const CompaniesFilters: React.FC<ICompaniesFiltersProps> = ({
  isSearching,
  onSearch,
}: ICompaniesFiltersProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useAppState();
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    state?.company?.selectedUserIds || []
  );

  const [keyword, setKeyword] = useState('');
  const [searchBy, setSearchBy] = useState<SearchByOptions>(SearchByOptions.CompanyName);
  const [prevSearchCriteria, setPrevSearchCriteria] = useState<{
    keyword: string;
    searchBy: SearchByOptions;
  }>({
    searchBy: SearchByOptions.CompanyName,
    keyword: '',
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  const isDirty =
    keyword !== prevSearchCriteria?.keyword || searchBy !== prevSearchCriteria?.searchBy;

  const currentRoleId =
    state.system?.roles?.find((role: Role) => {
      let roleName;
      if (isBrokerApplication) {
        roleName = UserRoles.BROKER;
      } else if (isAdvisorApplication) {
        roleName = UserRoles.ADVISOR;
      }
      return role.name === roleName;
    })?.id ?? '';

  const { data: usersData } = useQuery({
    queryKey: [QueryKeys.GetUserByRole, currentRoleId],
    queryFn: () => getUsersByRole(currentRoleId),
    staleTime: Infinity,
  });

  const handleSetSelectedUsers = (selectedUserIds: string[]) => {
    setSelectedUsers(selectedUserIds);
    const isFilteredOnlyBySelf =
      selectedUserIds.length === 1 && selectedUserIds.includes(state.system.currentUser!.authId!);

    dispatch({
      type: UPDATE_COMPANY,
      payload: {
        ...state.company,
        selectedUserIds,
      },
    });

    sendAmplitudeData(AmplitudeTrackingEnum.clientsfundingmanagerfilter, {
      selected: selectedUserIds.length.toString(),
      filterOnlyBySelf: isFilteredOnlyBySelf.toString(),
    });
  };

  const handleClearFilters = () => {
    setKeyword('');
    setSelectedUsers([]);
    setPrevSearchCriteria((prev) => ({ ...prev, keyword: '' }));
    onSearch('', searchBy);
    dispatch({
      type: UPDATE_COMPANY,
      payload: {
        ...state.company,
        selectedUserIds: [],
      },
    });
  };

  const formatToggleUserList = () => {
    return usersData?.data.results
      .map((user: any) => {
        return {
          id: user.securityId,
          name: user.fullName,
          picture: user.picture,
        };
      })
      .sort((a: any, _: any) => (a.id === state.system.currentUser?.authId ? -1 : 1));
  };

  const amplitudeSearchTracker = (searchBy: string) => {
    sendAmplitudeData(AmplitudeTrackingEnum.clientssearchfilter, {
      searchType: searchBy,
    });
  };

  useEffect(() => {
    if (!isSearching) searchInputRef.current?.focus();
  }, [isSearching]);

  useEffect(() => {
    if (keyword.length === 0) {
      onSearch('', searchBy);
      setPrevSearchCriteria((prev) => ({ ...prev, keyword: '' }));
    }
  }, [keyword]);

  return (
    <div className={cn(styles['filters-container'])}>
      <div className={cn(styles['quick-filters-container'])}>
        {(features.assignUsersEnabled && usersData?.data.results.length && isBrokerApplication && (
          <div className={cn(styles['assign-users-container'])}>
            <UserToggle
              label={t(
                appSpecific(
                  'home:companydetails:overview:accountinfo:fundingmanager',
                  'home:companydetails:overview:accountinfo:accountowner'
                )
              )}
              users={formatToggleUserList()}
              selectedUsers={selectedUsers}
              setSelectedUsers={handleSetSelectedUsers}
              placeholder={'Selected'}
              disabled={isSearching}
            />
          </div>
        )) ||
          null}
        <div className={cn(styles['clear-filters-button'])}>
          <button onClick={handleClearFilters} disabled={isSearching}>
            Clear
          </button>
        </div>
      </div>
      <span className={cn(styles.search)}>
        <div className={styles['search-input-container']}>
          <TextInput
            {...{
              metadata: {
                id: 'search-companies',
                type: 'text',
                placeholder: 'Search',
                value: keyword,
                tabIndex: 1,
                disabled: isSearching,
              },
              errors: [],
              onEnter: () => {
                if (keyword.length >= 3) {
                  setPrevSearchCriteria({ keyword, searchBy });
                  onSearch(keyword, searchBy);
                  amplitudeSearchTracker(searchBy);
                }
              },
              handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeyword(e.target.value);
              },
            }}
            ref={searchInputRef}
          />
          <div className={styles['search-btn-container']}>
            {isSearching ? (
              <span className={styles.loading}>
                <Spinner size="small" />
              </span>
            ) : (
              <button
                className={styles['search-btn']}
                disabled={!isDirty || keyword.length < 3}
                onClick={() => {
                  setPrevSearchCriteria({ keyword, searchBy });
                  onSearch(keyword, searchBy);
                  amplitudeSearchTracker(searchBy);
                }}
              >
                <i className="material-icons">search</i>
              </button>
            )}
          </div>
        </div>
        <Select
          metadata={{
            options: searchByOptions,
            disabled: isSearching,
            classNames: { value: styles['search-by-select'] },
          }}
          errors={{}}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSearchBy(e.target.value as SearchByOptions)
          }
        />
      </span>
    </div>
  );
};

export default CompaniesFilters;
