import cn from 'classnames';
import styles from './newActivity.module.scss';
import { useTranslation } from 'react-i18next';

type NewActivityConfirmDeletePropType = {
  isCollapsed: boolean;
  cancelDeleteActivity: () => void;
  confirmDeleteActivity: () => void;
};

const NewActivityConfirmDelete = ({
  isCollapsed,
  cancelDeleteActivity,
  confirmDeleteActivity,
}: NewActivityConfirmDeletePropType) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles['delete-without-saving-container'], isCollapsed && styles['collapsed'])}
    >
      <span>{t('home:companydetails:activities:new:confirmdelete')}</span>
      <div>
        <button className={cn(styles['cancel-button'])} onClick={cancelDeleteActivity}>
          {t('common:cancel')}
        </button>
        <button className={cn(styles['delete-button'])} onClick={confirmDeleteActivity}>
          {t('common:delete')}
        </button>
      </div>
    </div>
  );
};

export default NewActivityConfirmDelete;
