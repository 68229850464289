import React from 'react';

import styles from '../../assets/companyEssentials.module.scss';
import cn from 'classnames';

import IndustryIcon from 'assets/images/icon/industry.svg';
import BusinessTypeIcon from 'assets/images/icon/business-type.svg';
import SourceIcon from 'assets/images/icon/source.svg';

type IndustryDetailsProps = {
  industries?: React.ReactElement | null;
  businessType?: React.ReactElement | null;
  source?: string;
};

const IndustryDetails: React.FC<IndustryDetailsProps> = ({ industries, businessType, source }) => {
  return (
    <div className={styles['industry-details']}>
      {industries && (
        <div className={styles['industry-details__industry']}>
          <h4>Industry</h4>
          <p>{industries}</p>
          <img
            src={IndustryIcon}
            alt="industry"
            className={cn(styles['custom-icon'], styles['industry'])}
          />
        </div>
      )}
      {businessType && (
        <div className={styles['funding-need']}>
          <h4>Business type</h4>
          <p>{businessType}</p>
          <img
            src={BusinessTypeIcon}
            alt="business type"
            className={cn(styles['custom-icon'], styles['business-type'])}
          />
        </div>
      )}
      {source && (
        <div className={styles['industry-details__source']}>
          <h4>Source</h4>
          <p>{source}</p>
          <img
            src={SourceIcon}
            alt="source"
            className={cn(styles['custom-icon'], styles['source'])}
          />
        </div>
      )}
    </div>
  );
};

export default IndustryDetails;
