import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import LoginRoute from '.';
import ForgotPasswordRoute from './forgotpassword';
import ForgotPasswordCheckEmailRoute from './forgotpassword.checkemail';
import ForgotPasswordCallbackRoute from './forgotpasswordcallback';

export const loginRoutes: RouteObject[] = [
  {
    path: RoutePath.login,
    element: withTransaction('LoginRoute', 'component')(<LoginRoute />),
  },
  {
    path: RoutePath.forgotpassword,
    element: withTransaction('ForgotPassword', 'component')(<ForgotPasswordRoute />),
  },
  {
    path: RoutePath.forgotpasswordcheckemail,
    element: withTransaction(
      'ForgotPasswordCheckEmail',
      'component'
    )(<ForgotPasswordCheckEmailRoute />),
  },
  {
    path: RoutePath.forgotpasswordcallback,
    element: withTransaction(
      'ForgotPasswordCallback',
      'component'
    )(<ForgotPasswordCallbackRoute />),
  },
];
