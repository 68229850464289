import styles from 'pages/documentLibrary/assets/documentLibrary.module.scss';
import cn from 'classnames';
import { APIDocumentType, DocumentActionType } from '../types';
import { TablePropType } from 'components/table/type';
import i18next from 'i18next';
import { docTypeCategoryOptions, docTypes } from '_shared/utils/constants';
import { FieldTypes } from '_shared/fieldValidation/types';
import { convertToLocalDateFormat } from '_shared/utils/date';

const defaultHeaderStyles = {
  header: cn(styles['th-header']),
  value: styles['header-label-left'],
};

const defaultCellStyles = {
  cell: styles['tr-cell-label'],
};

export const documentFields = ({
  data,
  editDocuments,
  selectedDocuments,
  selectAll,
  actions,
  order,
}: {
  data: Array<APIDocumentType>;
  editDocuments: Array<APIDocumentType>;
  selectedDocuments: Array<string>;
  selectAll?: boolean;
  actions: DocumentActionType;
  order: { order: string; direction: string };
  isAdminOrSuperAdmin: boolean;
}): TablePropType => {
  const { order: sortOrder, direction } = order || {};
  return {
    header: [
      {
        id: `actions-download`,
        fieldMetadata: {
          id: 'select-download',
          type: 'checkbox',
          fieldType: FieldTypes.CHECKBOX,
          selectionKey: (selectAll && 'select-download,') || ',',
          options: [{ name: 'select-download', value: 'select-download', label: '' }],
          classNames: {
            checkbox: {
              checkbox: styles.checkbox,
              isChecked: styles['is-checked'],
              inputCheckbox: styles['input-checkbox'],
              holder: styles.holder,
              icon: styles.icon,
            },
          },
        },
        value: '',
        actionCb: actions?.selectAll,
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:category'),
        classname: {
          ...defaultHeaderStyles,
        },
        direction:
          (Boolean(
            sortOrder &&
              sortOrder.includes(i18next.t('home:companydetails:documentlibrary:headers:category'))
          ) &&
            direction) ||
          '',
        isSortable: true,
        callback: actions.sort,
        sortBy: i18next.t('home:companydetails:documentlibrary:headers:category'),
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:title'),
        classname: {
          ...defaultHeaderStyles,
        },
        direction:
          (Boolean(
            sortOrder &&
              sortOrder.includes(i18next.t('home:companydetails:documentlibrary:headers:title'))
          ) &&
            direction) ||
          '',
        isSortable: true,
        callback: actions.sort,
        sortBy: i18next.t('home:companydetails:documentlibrary:headers:title'),
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:filename'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        value: i18next.t('home:companydetails:documentlibrary:headers:uploadeddate'),
        classname: {
          ...defaultHeaderStyles,
        },
        direction:
          (Boolean(
            sortOrder &&
              sortOrder.includes(
                i18next.t('home:companydetails:documentlibrary:headers:uploadeddate')
              )
          ) &&
            direction) ||
          'desc',
        isSortable: true,
        callback: actions.sort,
        sortBy: i18next.t('home:companydetails:documentlibrary:headers:uploadeddate'),
      },
      {
        id: 'header-right-download',
        value: ' ',
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'header-right-edit',
        value: ' ',
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'header-right-delete',
        value: ' ',
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {},
    classname: {
      table: styles.table,
    },
    items: data.map((document: APIDocumentType) => {
      const isEdit =
        editDocuments.find(
          ({ documentId }: { documentId: string }) => documentId === document.documentId
        ) || undefined;

      if (isEdit) {
        const titleOptions = (isEdit.type && [...docTypes[isEdit.type]]) || [
          ...docTypes[Object.keys(docTypes)[0]],
        ];
        return [
          {
            id: `${isEdit.documentId}_actions-edit-cancel`,
            default: 'cancel',
            actionCb: actions.edit,
            classname: {
              ...defaultCellStyles,
              link: styles['cancel-action-link'],
            },
          },
          {
            id: `${isEdit.documentId}-category`,
            fieldMetadata: {
              id: isEdit.documentId,
              name: 'type',
              fieldType: FieldTypes.SELECT,
              placeholder: isEdit.type,
              options: docTypeCategoryOptions,
              classNames: {
                control: styles.control,
              },
            },
            actionCb: actions.changeField,
            classname: {
              ...defaultCellStyles,
            },
          },
          {
            id: `${isEdit.documentId}-title`,
            fieldMetadata: {
              id: isEdit.documentId,
              name: 'title',
              fieldType:
                isEdit.type === docTypeCategoryOptions[5].label
                  ? FieldTypes.TEXT
                  : FieldTypes.SELECT,
              value: isEdit.title,
              placeholder: '',
              options: titleOptions,
              classNames: {
                control: styles.control,
              },
            },
            actionCb: actions.changeField,
            classname: {
              ...defaultCellStyles,
            },
          },
          {
            id: `${isEdit.documentId}-file-name`,
            default: isEdit.name,
            classname: {
              ...defaultCellStyles,
            },
          },
          {
            id: `${isEdit.documentId}-upload-date`,
            default: convertToLocalDateFormat(isEdit.uploadedDate),
            classname: {
              ...defaultCellStyles,
            },
          },
          {
            id: `${isEdit.documentId}_actions-save`,
            actionCb: actions.saveEdit,
            default: 'save',
            colspan: 3,
            classname: {
              ...defaultCellStyles,
              link: styles['save-action-link'],
            },
          },
        ];
      }

      return [
        {
          id: `${document.documentId}-actions-download`,
          fieldMetadata: {
            id: document.documentId,
            type: 'checkbox',
            fieldType: FieldTypes.CHECKBOX,
            selectionKey: (selectedDocuments?.length && `${selectedDocuments.join(',')},`) || ',',
            options: [{ name: document.documentId, value: document.documentId, label: '' }],
            classNames: {},
          },
          actionCb: actions.select,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-category`,
          default: document.type,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-title`,
          default: document.title,
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}-file-name`,
          default: document.name,
          actionCb: actions.preview,
          classname: {
            ...defaultCellStyles,
            cell: styles['file-name'],
          },
        },
        {
          id: `${document.documentId}-upload-date`,
          default: convertToLocalDateFormat(document.uploadedDate),
          classname: {
            ...defaultCellStyles,
          },
        },
        {
          id: `${document.documentId}_actions-edit`,
          icon: 'edit',
          actionCb: document.isBrokerDocument ? '' : actions.edit,
          tooltip: document.isBrokerDocument ? 'accountancy data generated automatically' : '',
          classname: {
            ...defaultCellStyles,
            cell: document.isBrokerDocument ? styles['disabled-icon'] : styles['action-link'],
          },
        },
        {
          id: `${document.documentId}_actions-download`,
          icon: 'cloud_download',
          actionCb: actions.download,
          classname: {
            ...defaultCellStyles,
            cell: styles['action-link'],
          },
        },
        {
          id: `${document.documentId}_actions-delete`,
          icon: 'delete',
          actionCb: document.isBrokerDocument ? '' : actions.delete,
          tooltip: document.isBrokerDocument ? 'accountancy data generated automatically' : '',
          classname: {
            ...defaultCellStyles,
            cell: document.isBrokerDocument ? styles['disabled-icon'] : styles['action-link'],
          },
        },
      ];
    }),
  };
};
