import cn from 'classnames';
import styles from './assets/userManagement.module.scss';
import TextInput from 'components/field/text';
import { FieldTypes } from '_shared/fieldValidation/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { useEffect, useState } from 'react';
import useAutoFocus from '_shared/hooks/useAutoFocus';
import useDebounce from '_shared/hooks/useDebounce';
import { APIUserManagementByRoleType } from './type';
import { getUsersByEmailAddress } from '_shared/api/users';
import * as EmailValidator from 'email-validator';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';

const UserManagement: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [data, setData] = useState<APIUserManagementByRoleType | null>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchInputRef = useAutoFocus();
  const debouncedSearchTerm = useDebounce(searchQuery, 800);

  const fetchUserBySearch = async (searchQuery: string) => {
    try {
      const isEmailValid: boolean = EmailValidator.validate(debouncedSearchTerm);
      if (!isEmailValid) {
        setErrorMessage(`Could not find the user with the email: ${searchQuery}`);
        return;
      }

      setErrorMessage('');

      const { data: foundUser } = await getUsersByEmailAddress(searchQuery);

      if (!foundUser) {
        setErrorMessage('No user found for the entered email.');
        return;
      }

      setData(foundUser);
    } catch (error) {
      setErrorMessage('Error: Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setErrorMessage('');

    setSearchQuery(event.target.value);
    setIsLoading(true);
  };

  const goToDetails = () => {
    if (!data) return;
    navigate(RoutePath.userdetails.replace(':id', data.securityId));
  };

  useEffect(() => {
    if (debouncedSearchTerm === '') {
      setData(null);
      setIsLoading(false);
      return;
    }
    fetchUserBySearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={cn('wrapper', styles['user-management-container'])}>
      <div className={cn(styles.users)} data-testid="user-management-list">
        <div className={cn(styles['header-wrapper'])}>
          <div className={cn(styles['headers'])}>
            <h2 className={cn('sw-h2')}>{t('home:usermanagement:title')}</h2>
            <p>{t('home:usermanagement:description')}</p>
          </div>
        </div>

        <TextInput
          {...{
            metadata: {
              id: 'search-users',
              placeholder: t('home:usermanagement:search:placeholder'),
              fieldType: FieldTypes.TEXT,
              value: searchQuery,
              trailingIcon: 'search',
              isLoading: isLoading,
            },
            errors: [],
            handleChange: handleSearch,
          }}
          ref={searchInputRef}
        />

        <div className={styles['table-wrapper']}>
          {data && !errorMessage && !isLoading && (
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Security ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={data.securityId} onClick={goToDetails}>
                  <TableCell>{data.email}</TableCell>
                  <TableCell>{data.fullName}</TableCell>
                  <TableCell>{data.securityId}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}

          {errorMessage && (
            <div className={styles['invalid-email-error']}>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
