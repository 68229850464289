import packageJson from '../../package.json';
import { init } from '@elastic/apm-rum';
import { env } from '_shared/utils';

export const initApm = () => {
  init({
    serviceName: 'portal-frontend',
    serverUrl: env('REACT_APP_APM_SERVER_URL'),
    serviceVersion: packageJson.version,
    environment: env('REACT_APP_APM_ENVIRONMENT'),
    distributedTracingOrigins: ['https://api.brokers.dev.internal.swoopfunding.com'],
  });
};
