import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import styles from 'pages/groupManagement/assets/groupManagement.module.scss';
import { FieldTypes } from '_shared/fieldValidation/types';
import Button from 'components/button';
import Field from 'components/field';
import { getGroup, putGroup } from '_shared/api/group';
import { DisableModal, GroupInfoType } from './types';
import { TOAST_ERROR_MESSAGE, TOAST_MESSAGE } from 'store/toast/types';
import Modal from 'components/modal';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Tooltip from 'components/tooltip';
import { DISABLE_MODAL, UPDATE_SESSION } from 'store/system/types';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { IContext } from 'store/types';
import Spinner from 'components/spinner';
import { patchUserModal } from '_shared/api/users';

const GroupManagement: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: appState, dispatch }: IContext = useAppState();
  const [loading, setLoading] = useState<boolean>(true);
  const isUserAdmin = Boolean(appState.system.isAdminOrSuperAdmin?.());
  const [groupState, setGroupState] = useState<GroupInfoType>({
    name: '',
    logoUrl: '',
    footer: '',
  });
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) return;
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadFiles = async (files: File[]) => {
    if (!isUserAdmin) {
      dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:groupprofile:toast:adminonly') },
      });
      return null;
    }

    const newLogo = files.filter(({ type, size }: { type: string; size: number }) => {
      if (
        ![
          'image/svg+xml',
          'image/svg',
          'image/svgz',
          'image/svg-xml',
          'image/jpeg',
          'image/jpg',
          'image/png',
        ].includes(type) ||
        size > 20e6
      ) {
        dispatch({
          type: TOAST_ERROR_MESSAGE,
          payload: { toastMessage: t('home:groupprofile:toast:specificfile') },
        });
        return false;
      }
      return true;
    });

    const convertedLogo = await convertBase64(newLogo[0]);

    setGroupState({ ...groupState, logoUrl: convertedLogo });
  };

  const saveGroupUpdate = async () => {
    if (!appState?.system?.groupId) return;
    if (!groupState.name) {
      dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:groupprofile:toast:entername') },
      });
      return;
    }

    setLoading(true);
    try {
      await putGroup(appState.system.groupId, groupState);
      dispatch({
        type: UPDATE_SESSION,
        payload: {
          ...appState.system,
          currentUser: {
            ...appState.system.currentUser,
            groupLogoUrl: groupState.logoUrl,
          },
        },
      });
      dispatch({
        type: TOAST_MESSAGE,
        payload: { toastMessage: t('home:groupprofile:toast:savesuccess') },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: TOAST_ERROR_MESSAGE,
        payload: { toastMessage: t('home:groupprofile:toast:savefailed') },
      });
    } finally {
      setLoading(false);
      if (!appState.system.hasOnboardedGroups) {
        setSaved(true);
      }
    }
  };

  const handleNotificationClose = async () => {
    setIsNotificationOpen(false);

    dispatch({
      type: DISABLE_MODAL,
      payload: DisableModal.WELCOME_MODAL,
    });

    try {
      // Notify backend to disable modal so that the modal is marked as disabled in /current endpoint response
      await patchUserModal(DisableModal.WELCOME_MODAL);
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentGroup = async () => {
    if (!appState?.system?.groupId) return;

    setLoading(true);
    try {
      const { data } = await getGroup(appState.system.groupId);

      setGroupState({
        name: data.name,
        logoUrl: data.logoUrl,
        footer: data.emailFooter,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isNotificationModalDisabled = appState.system.currentUser?.disabledModalsList?.includes(
      DisableModal.WELCOME_MODAL
    );

    if (!isNotificationModalDisabled) {
      setIsNotificationOpen(true);
    }
  }, []);

  const closeSavedModal = () => {
    setSaved(false);
    dispatch({
      type: UPDATE_SESSION,
      payload: {
        hasOnboardedGroups: true,
      },
    });
    navigate(RoutePath.companies);
  };

  return (
    <div className={cn('wrapper', styles['group-management-container'])}>
      <div className={cn(styles['save-button-container'])}>
        {isUserAdmin && (
          <Button
            key={'save-button'}
            id={'save-button'}
            ariaLabel={'save-button'}
            className={styles['save-button']}
            clickHandler={saveGroupUpdate}
          >
            <span>{t('home:groupprofile:savebutton')}</span>
          </Button>
        )}
      </div>
      <div className={cn(styles['group-management-fields'])}>
        <div className={cn(styles['group-management-field'])}>
          <label>{t('home:groupprofile:fields:groupname')}</label>
          <Field
            {...{
              metadata: {
                id: 'group-name',
                name: 'group-name',
                value: groupState.name || '',
                fieldType: FieldTypes.TEXT,
                disabled: !isUserAdmin,
                classNames: { control: styles['group-name-input'] },
              },
              errors: [],
              handleChange: (e: any) => {
                setGroupState({ ...groupState, name: e.target.value });
              },
            }}
          />
        </div>
        <div className={cn(styles['group-management-field'])}>
          <label>{t('home:groupprofile:fields:logo')}</label>
          <div className={cn(styles['upload-logo-container'])}>
            {!loading ? (
              <img alt="group-logo" src={groupState.logoUrl} className={cn(styles['group-logo'])} />
            ) : (
              <Spinner size="large" />
            )}
            <Field
              {...{
                metadata: {
                  id: 'group-logo-upload',
                  name: 'group-logo-upload',
                  fieldType: FieldTypes.UPLOAD,
                  hideFileValues: true,
                  disabled: !isUserAdmin,
                  format: t('home:groupprofile:uploaddescription'),
                  toolTipContent: t(
                    'home:companydetails:documentlibrary:upload:profileuploadfiletooltip'
                  ),
                  acceptedFileTypes: '.jpg, .jpeg, .png, .svg, .svgz, .svg-xml, .svg+xml',
                },
                errors: [],
                handleChange: uploadFiles,
                className: { field: styles['group-logo-upload'] },
              }}
            />
          </div>
        </div>
        <div className={cn(styles['group-management-field'])}>
          <label className={cn(styles['label-and-tooltip'])}>
            {t('home:groupprofile:fields:emailfooter')}
            <Tooltip
              content={t('home:groupprofile:tooltip:emailfooter')}
              className={cn(styles['tooltip'])}
            >
              <span className={cn('material-icons', styles['tooltip-icon'])}>info</span>
            </Tooltip>
          </label>
          <Field
            {...{
              metadata: {
                id: 'email-footer',
                name: 'email-footer',
                value: groupState.footer || '',
                fieldType: FieldTypes.TEXTAREA,
                disabled: !isUserAdmin,
                classNames: { value: styles['group-email-footer'] },
              },
              errors: [],
              handleChange: (e: any) => {
                setGroupState({ ...groupState, footer: e.target.value });
              },
            }}
          />
        </div>
      </div>
      <Modal show={isNotificationOpen} handleClose={handleNotificationClose}>
        <div className={cn(styles['group-management-modal'])}>
          <div className={cn(styles['modal-header'])}>
            <h3>{t('home:groupprofile:welcomemodal:title')}</h3>
          </div>
          <div className={cn(styles['modal-description'])}>
            <span>{t('home:groupprofile:welcomemodal:details')}</span>
          </div>
          <div className={cn(styles['modal-button-container'])}>
            <Button
              id="group-modal-button"
              className={cn(styles['action-button'])}
              ariaLabel="group-modal-button"
              buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
              tabIndex={0}
              clickHandler={handleNotificationClose}
            >
              {t('home:groupprofile:welcomemodal:button')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal show={saved} handleClose={closeSavedModal}>
        <div className={cn(styles['changes-saved-modal'])}>
          <div className={cn(styles['modal-header'])}>
            <h3>{t('home:groupprofile:savedmodal:title')}</h3>
          </div>
          <div className={cn(styles['modal-description'])}>
            <span>{t('home:groupprofile:savedmodal:details')}</span>
          </div>
          <div className={cn(styles['modal-button-container'])}>
            <Button
              id="group-saved-modal-button"
              className={cn(styles['action-button'])}
              ariaLabel="group-saved-modal-button"
              buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
              tabIndex={0}
              clickHandler={closeSavedModal}
            >
              {t('home:groupprofile:savedmodal:button')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GroupManagement;
