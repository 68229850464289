import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getMonthName } from '_shared/utils/date';
import { CashFlowProjectionItem, CashFlowProjectionItems } from '../types';

const CashflowForecastingGraph: React.FC<{ data: CashFlowProjectionItems }> = ({ data }) => {
  const options = {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
    },
    grid: {
      left: 50,
      right: 40,
      top: 40,
      bottom: 60,
    },
    dataset: {
      dimensions: ['date', 'cash in', 'cash out'],
      source: data?.map((item: CashFlowProjectionItem) => ({
        date: getMonthName(item?.month) + ' ' + String(item?.year).slice(2),
        'cash in': item?.cashIn?.total,
        'cash out': item?.cashOut?.total,
      })),
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [
      { type: 'bar', color: '#2E9C8E' },
      { type: 'bar', color: '#003E52' },
    ],
  };

  return <ReactECharts option={options} />;
};

export default CashflowForecastingGraph;
