import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'store';
import cn from 'classnames';
import {
  inviteGroupFields,
  inviteBrokerFields,
  inviteAdvisorFields,
} from 'layouts/components/AddUserModal/invitation.fields';
import {
  postInviteGroupAdmin,
  postInviteGroupUser,
  postInviteGroupCompany,
} from '_shared/api/group';
import { AmplitudeTrackingEnum, sendAmplitudeData } from 'config/amplitude';
import { ButtonStyleTypeEnum } from 'components/button/type';
import { emailClientFields } from 'layouts/components/AddUserModal/EmailClient.fields';
import { postCreateCompany, putAssignUser, QueryKeys } from '_shared/api/businesses';
import { FormFieldPropType } from '_shared/fieldValidation/types';
import { addClientFields } from 'layouts/components/AddUserModal/AddClient.fields';
import { UPDATE_COMPANY } from 'store/company/types';
import { TOAST_MESSAGE } from 'store/toast/types';
import { UserModalEnum } from 'layouts/type';
import EmailClient from './EmailClient';
import validation from '_shared/fieldValidation';
import AddClient from './AddClient';
import useForm from '_shared/hooks/useForm';
import styles from 'layouts/components/AddUserModal/AddUser.module.scss';
import Button from 'components/button';
import Field from 'components/field';
import Modal from 'components/modal';
import {
  applicationType,
  appSpecific,
  applicationName,
  SWOOP_GROUP_ID,
} from '_shared/utils/application';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import { getExistingUser } from '_shared/api/users';
import { countries } from '_shared/utils/constants';
import { getDynamicFormsMetadata, getWhiteLabelByGroupId } from '_shared/api/marketplaceApi';
import { getFundingForms } from '_shared/api/fundingMatches';
import { TOption } from '_shared/utils/form';
import { useQueryClient } from '@tanstack/react-query';

type TAddUserModalProps = {
  userModalContext: UserModalEnum | null;
  setUserModalContext: Function;
};

export const removeSeparatorsAndParseToNumber = (numberAsString: string) => {
  const currencyRegex = /^\d+(((,\d{3}){0,9})?(\.\d{0,2})?)$/;
  if (numberAsString && !currencyRegex.test(numberAsString)) {
    return;
  }
  return parseFloat(numberAsString.replace(/[.,\s]/g, ''));
};

const addClientValuesToBeExcluded = [
  'cantFindCompany',
  'company',
  'stateCode',
  'districtBasedOnCounty',
  'districtBasedOnStateCode',
];

const AddUserModal = ({ userModalContext, setUserModalContext }: TAddUserModalProps) => {
  const { state: appState, dispatch } = useAppState();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userFields, setUserFields] = useState<FormFieldPropType[]>([]);
  const [fundingReasonOptions, setFundingReasonOptions] = useState<TOption[]>([]);
  const dynamicFormsMetadataEntities = [
    'mainCountries',
    'regions',
    'countries',
    'counties',
    'districts',
    'companyStructure',
    'sectors',
    'fundingRequiredTimescales',
    'companyAccountStatuses',
  ];

  const findFieldOptions = (fieldId: string): TOption[] => {
    return userFields.find((field) => fieldId === field.id)?.options || [];
  };

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);

      const getCompanySourceType = () => {
        if (!values.cantFindCompany) return 'FromRegistrar';
        if (values.isSoleTrader) return 'SoleTrader';
        return 'Entered';
      };

      const getCountryFullName = (countryCode: string) => {
        return (
          countries.find((country) => country.cca2 === countryCode || country.cca3 === countryCode)
            ?.name || countryCode
        );
      };

      const getCompanyData = (values: any) => {
        const company = {
          sourceType: getCompanySourceType(),
          companyName:
            values.company && !Boolean(values.cantFindCompany)
              ? values.company.label
              : values.companyName,
          companyNumber:
            values.company && !Boolean(values.cantFindCompany)
              ? values.company.metaData.companyRegistrationNumber
              : values.companyNumber,
          country:
            values.company && !Boolean(values.cantFindCompany)
              ? getCountryFullName(values.company.metaData.countryCode)
              : values.country,
        };

        const contactDetails = {
          firstName: values.firstName,
          lastName: values.lastName,
          contactNumber: values.contactNumber || '',
          email: values.email || '',
          roleInCompany: values.roleInCompany,
        };

        let filteredValues: { [key: string]: any } = {};
        Object.keys(values).forEach((key) => {
          if (Object.keys(company).includes(key)) return;
          if (Object.keys(contactDetails).includes(key)) return;
          if (addClientValuesToBeExcluded.includes(key)) return;

          // Used to convert arrays of strings to arrays of key, value pairs
          if (Array.isArray(values[key])) {
            const fieldOptions = findFieldOptions(key);

            const updatedArrayValues: any = values[key]
              .map((value: string | number) => fieldOptions.find((field) => field.value === value))
              .map((field: TOption) => {
                return {
                  value: field.value,
                  label: field.label,
                };
              });

            values[key] = JSON.stringify(updatedArrayValues) || '';
          }

          filteredValues[key] = values[key];
        });

        const district = values.districtBasedOnCounty || values.districtBasedOnStateCode;
        if (district) filteredValues = { ...filteredValues, district };

        return {
          ...company,
          ...filteredValues,
          primaryContact: contactDetails,
          companyStage: '',
          stateCode: values.stateCode || values.state || values.province,
        };
      };

      let response;

      switch (userModalContext) {
        case UserModalEnum.MIGRATEEXISTINGUSER:
          response = await postInviteGroupAdmin(values);
          break;
        case UserModalEnum.INVITEEXISTINGUSER:
          response = await postInviteGroupUser({
            ...values,
            applicationType,
          });
          break;
        case UserModalEnum.BROKER:
          response = await getExistingUser(values.email);
          if (!response.data) {
            // user doesn't exist so we can invite them directly
            response = await postInviteGroupUser({
              ...values,
              applicationType: 'BrokerApplication',
            });
          } else {
            // user exits, show migration popup
            setUserModalContext(UserModalEnum.INVITEEXISTINGUSER);
          }
          break;
        case UserModalEnum.ADVISOR:
          response = await getExistingUser(values.email);
          if (!response.data) {
            // user doesn't exist so we can invite them directly
            response = await postInviteGroupUser({
              ...values,
              applicationType: 'AdvisorApplication',
            });
          } else {
            // user exits, show migration popup
            setUserModalContext(UserModalEnum.INVITEEXISTINGUSER);
          }
          break;
        case UserModalEnum.GROUP:
          response = await getExistingUser(values.email);
          if (!response.data) {
            // user doesn't exist so we can invite them directly
            response = await postInviteGroupAdmin(values);
          } else {
            // user exits, show migration popup
            setUserModalContext(UserModalEnum.MIGRATEEXISTINGUSER);
          }
          break;
        case UserModalEnum.ADDBUSINESS:
          response = await postCreateCompany({
            ...getCompanyData(values),
            applicationType: appSpecific('BrokerApplication', 'AdvisorApplication'),
          });

          if (values.assignedUserSecurityId) {
            const companyId = response.data;
            await putAssignUser(companyId, {
              userSecurityId: values.assignedUserSecurityId,
              userRole: appSpecific('Broker', 'Advisor'),
              companyId: companyId,
            });
          }

          void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetDeals] });
          void queryClient.invalidateQueries({ queryKey: [QueryKeys.GetCompanies] });

          break;
        case UserModalEnum.INVITEBUSINESS:
          response = await postInviteGroupCompany({
            ...values,
            applicationType: appSpecific('BrokerApplication', 'AdvisorApplication'),
          });
          break;
        default:
          throw new Error('Unknown');
      }

      if (
        [
          UserModalEnum.BROKER,
          UserModalEnum.GROUP,
          UserModalEnum.INVITEBUSINESS,
          UserModalEnum.ADVISOR,
          UserModalEnum.MIGRATEEXISTINGUSER,
          UserModalEnum.INVITEEXISTINGUSER,
        ].includes(userModalContext) &&
        response.status === 204
      ) {
        dispatch({
          type: TOAST_MESSAGE,
          payload: { toastMessage: t('register:invitationsent') },
        });

        setUserModalContext(null);
      } else if (userModalContext === UserModalEnum.ADDBUSINESS && response.status === 201) {
        dispatch({
          type: TOAST_MESSAGE,
          payload: { toastMessage: t('register:clientadded') },
        });

        dispatch({
          type: UPDATE_COMPANY,
          payload: {
            ...appState.company,
            refreshIndexPage: appState.company.refreshIndexPage + 1,
          },
        });
        setUserModalContext(null);
      }

      if (userModalContext === UserModalEnum.ADDBUSINESS) {
        sendAmplitudeData(AmplitudeTrackingEnum.addclientendmanual, { journey: 'Manual' });
        navigate(RoutePath.companyfundingmatches.replace(':id', response.data));
      } else if (userModalContext === UserModalEnum.INVITEBUSINESS) {
        sendAmplitudeData(AmplitudeTrackingEnum.addclientendinvite, { journey: 'Invite' });
      } else if (userModalContext === UserModalEnum.BROKER) {
        sendAmplitudeData(AmplitudeTrackingEnum.addbrokerend, {
          brokerType: AddUserMetadata[0]?.value,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const {
    handleChange: AddUserHandleChange,
    handleManualChange: AddUserManualChange,
    handleSubmit: AddUserSubmit,
    errors: AddUserErrors,
    metadata: AddUserMetadata,
  } = useForm({}, userFields, handleSubmit, validation);

  const modalsList = {
    'migrate-existing-user': {
      title: 'sidebar:migrateexistinguser:title',
      headerLogo: 'email',
      buttonText: 'Send upgrade invite',
      wrapperClass: 'migrate-existing-user-container',
      component: null,
    },
    'invite-existing-user': {
      title: 'sidebar:inviteexistinguser:title',
      headerLogo: 'email',
      buttonText: 'Send upgrade invite',
      wrapperClass: 'migrate-existing-user-container',
      component: null,
    },
    brokerage: {
      title: 'sidebar:invitebrokerage:title',
      headerLogo: 'email',
      buttonText: '',
      wrapperClass: 'add-user-modal-container',
      component: null,
    },
    advisor: {
      title: 'sidebar:inviteuser:title',
      headerLogo: 'email',
      buttonText: 'sidebar:inviteuser:title',
      wrapperClass: 'invite-broker-wrapper',
      component: null,
    },
    group: {
      title: 'sidebar:invitegroup:title',
      headerLogo: 'email',
      buttonText: 'sidebar:invitegroup:title',
      wrapperClass: 'invite-broker-wrapper',
      component: null,
    },
    broker: {
      title: 'sidebar:inviteuser:title',
      headerLogo: 'email',
      buttonText: 'sidebar:inviteuser:title',
      wrapperClass: 'invite-broker-wrapper',
      component: null,
    },
    'add-business': {
      title: 'sidebar:addbusiness:title',
      headerLogo: 'person_add',
      buttonText: 'Add client',
      wrapperClass: 'add-user-modal-container',
      component: (
        <div className={cn(styles['add-client-modal-content-container'])}>
          <AddClient
            metadata={AddUserMetadata}
            errors={AddUserErrors}
            handleChange={AddUserHandleChange}
            handleManualChange={AddUserManualChange}
          />
        </div>
      ),
    },
    'invite-business': {
      title: 'sidebar:invitebusiness:title',
      headerLogo: 'email',
      buttonText: 'Invite',
      wrapperClass: 'invite-client-email-wrapper',
      component: (
        <div className={cn(styles['add-client-modal-content-container'])}>
          <EmailClient
            fundingReasonOptions={fundingReasonOptions}
            showWhitelabelField={appState.system.groupId === SWOOP_GROUP_ID}
            metadata={AddUserMetadata}
            errors={AddUserErrors}
            handleChange={AddUserHandleChange}
          />
        </div>
      ),
    },
  };

  const filterEntities = useCallback((entities: any) => {
    let requiredEntities: any = {};
    Object.entries(entities).forEach(([key, value]) => {
      if (dynamicFormsMetadataEntities.includes(key) || key === 'fundingForms') {
        requiredEntities[key] = value;
      }
    });
    return requiredEntities;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataForAddClient = async () => {
    try {
      setIsLoading(true);

      const [dynamicFormsMetadata, fundingForms, whitelabelRes] = await Promise.all([
        getDynamicFormsMetadata(dynamicFormsMetadataEntities),
        getFundingForms(),
        getWhiteLabelByGroupId(appState.system.groupId!).catch(() => {}), // ignore errors as swoop group has no access to this info
      ]);

      const disabledFundingReasons =
        whitelabelRes?.data?.config && appState.system.groupId !== SWOOP_GROUP_ID
          ? JSON.parse(whitelabelRes.data.config).journey?.disabledGoals ?? []
          : [];

      dynamicFormsMetadata.data['fundingForms'] = fundingForms.data
        .filter(({ name }: any) => !disabledFundingReasons.includes(name))
        .map((item: any) => {
          return {
            label: item.displayName,
            value: item.formId,
          };
        });

      setUserFields(addClientFields(filterEntities(dynamicFormsMetadata.data)));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataForInviteClient = async () => {
    try {
      setIsLoading(true);

      const [fundingForms, whitelabelRes] = await Promise.all([
        getFundingForms(),
        getWhiteLabelByGroupId(appState.system.groupId!).catch(() => {}), // ignore errors as swoop group has no access to this info
      ]);

      const disabledFundingReasons =
        whitelabelRes?.data?.config && appState.system.groupId !== SWOOP_GROUP_ID
          ? JSON.parse(whitelabelRes.data.config).journey?.disabledGoals ?? []
          : [];

      const options = fundingForms.data
        .filter(({ name }: any) => !disabledFundingReasons.includes(name))
        .map((item: any) => {
          return {
            label: item.displayName,
            value: item.name,
          };
        });

      setFundingReasonOptions(options);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const roles = appState?.system?.roles ?? [];
    const userFields = {
      [UserModalEnum.INVITEEXISTINGUSER]: [],
      [UserModalEnum.MIGRATEEXISTINGUSER]: AddUserMetadata.map((item) => {
        item.disabled = true;
        return item;
      }).filter((item: any) => item.name !== 'demoGroup'),
      [UserModalEnum.BROKER]: inviteBrokerFields(roles),
      [UserModalEnum.ADVISOR]: inviteAdvisorFields(roles),
      [UserModalEnum.ADDBUSINESS]: [],
      [UserModalEnum.INVITEBUSINESS]: emailClientFields(),
      [UserModalEnum.GROUP]: inviteGroupFields(),
    };

    if (userModalContext === UserModalEnum.ADDBUSINESS) {
      fetchDataForAddClient();
    } else if (userModalContext === UserModalEnum.INVITEBUSINESS) {
      fetchDataForInviteClient();
      setUserFields(userFields[userModalContext || UserModalEnum.GROUP]);
    } else {
      setUserFields(userFields[userModalContext || UserModalEnum.GROUP]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModalContext]);

  return (
    <Modal
      testId={userModalContext?.toString()}
      show={Boolean(userModalContext)}
      loading={isLoading}
      handleClose={() => setUserModalContext(null)}
    >
      <div className={cn(styles[modalsList[userModalContext || 'broker'].wrapperClass])}>
        <div className={cn(styles['add-user-wrapper'])}>
          <div className={cn(styles['add-user-modal-header-container'])}>
            <h3 className={cn(styles['add-user-modal-header'])}>
              {t(modalsList[userModalContext || 'broker'].title)}
            </h3>
            <span className={cn(styles['add-user-modal-header-icon-container'])}>
              <span className={cn('material-icons')}>
                {modalsList[userModalContext || 'broker'].headerLogo}
              </span>
            </span>
          </div>

          <header className={cn(styles['header-mobile'])}>
            <span className={cn('material-icons')} onClick={() => setUserModalContext(null)}>
              arrow_back
            </span>
            <h3>{t(modalsList[userModalContext || 'broker'].title)}</h3>
            <span />
          </header>
          {userModalContext === UserModalEnum.MIGRATEEXISTINGUSER && (
            <div>
              <p>This user already has an account in the marketplace.</p>
              <p>
                By sending this invite to Swoop for {AddUserMetadata[2]?.value}, the user will be
                given the option to upgrade their account and retire their marketplace account.
              </p>
              <p>Please confirm whether you'd like to send this upgrade invite</p>
            </div>
          )}
          {userModalContext === UserModalEnum.INVITEEXISTINGUSER && (
            <div>
              <p>This user already has an account that is not part of your group.</p>
              <p>
                By sending this invite to join your Swoop for {applicationName()} account, the user
                will be able to see and interact with all accounts within your group.{' '}
              </p>
              <p>
                In addition to this, any accounts they had previously uploaded will be migrated into
                your account.
              </p>
              <p>Only send this invite if you are confident it is going to the correct person.</p>
              <p>Please confirm whether you'd like to send this upgrade invite</p>
            </div>
          )}
          {/* This can be optmized in the future once all the modals follows the same pattern. */}
          {(AddUserMetadata.length && userModalContext === UserModalEnum.ADDBUSINESS) ||
          userModalContext === UserModalEnum.INVITEBUSINESS ? (
            modalsList[userModalContext || 'broker'].component
          ) : (
            <div className={cn(styles['add-user-modal-content-container'])}>
              {AddUserMetadata.map((AddUserMetadata: any) => {
                return (
                  <Field
                    key={AddUserMetadata.id}
                    metadata={AddUserMetadata}
                    errors={AddUserErrors}
                    callBack={AddUserMetadata.callBack}
                    handleChange={AddUserHandleChange}
                  />
                );
              })}
            </div>
          )}

          {(userModalContext === UserModalEnum.BROKER ||
            userModalContext === UserModalEnum.ADVISOR) && (
            <div className={cn(styles['invite-colleague-warning-container'])}>
              <span className={cn(styles['invite-warning'])}>
                The new colleague will be able to view all data in your portal, please ensure you’re
                sending the invite to the correct recipient
              </span>
            </div>
          )}
          <div className={cn(styles['add-user-modal-actions-container'])}>
            <Button
              id="Add-user-cancel"
              className={cn(styles['cancel'])}
              ariaLabel="Add-user-cancel"
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              tabIndex={0}
              clickHandler={() => setUserModalContext(null)}
            >
              Cancel
            </Button>
            <Button
              id="add-user"
              ariaLabel="add-user"
              loading={submitting}
              clickHandler={AddUserSubmit}
              tabIndex={0}
            >
              {t(modalsList[userModalContext || 'broker'].buttonText)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
