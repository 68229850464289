import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';
import { clearState } from 'store';
import { LSKeys } from '_shared/utils/constants';
import { deleteXGroupHeaders } from '_shared/utils/grouping';
import { applicationType } from '_shared/utils/application';

const login = async (data: any) => {
  const params = {
    username: data.username,
    password: data.password,
  };

  const tokenResult = await BrokerAPI.post(BrokerAPIURL.login, params);

  return {
    ...tokenResult,
    data: {
      ...tokenResult.data,
      accessToken: tokenResult.data.accessToken,
    },
  };
};

const logout = () => {
  clearState(LSKeys.swoopAnalytics);
  return BrokerAPI.post(BrokerAPIURL.logout, deleteXGroupHeaders());
};
const resetPassword = async (data: { email: string }) =>
  BrokerAPI.post(BrokerAPIURL.resetPassword, { email: data.email, applicationType });

export { login, logout, resetPassword };
