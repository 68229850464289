import React, { forwardRef } from 'react';
import styles from './RadioGroup.module.scss';
import { RadioProps } from '../Radio/Radio';

export type RadioGroupProps = {
  name: string;
  value: string;
  children: React.ReactNode;
  radioClassName?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const RadioGroup: React.FC<RadioGroupProps> = forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ name, value, children, radioClassName, onChange, onBlur }, ref) => {
    const radios = React.Children.map(children, (child) => {
      if (React.isValidElement<RadioProps>(child)) {
        return React.cloneElement(
          child,
          {
            ...child.props,
            name,
            checked: child.props.value === value,
            className: radioClassName,
            onChange,
            onBlur,
          },
          child.props.children
        );
      }
    });

    return (
      <div ref={ref} className={styles['radio-group']}>
        {radios}
      </div>
    );
  }
);

export default RadioGroup;
