import i18n from 'config/i18n';

export type ProductApplicationType = {
  uploadedDocuments: any[];
  productDocuments: any[];
  requiredDocuments: any[];
  loading: {
    requiredDocuments: boolean;
    application: boolean;
  };
  error: {
    requiredDocuments: boolean;
    application: boolean;
  };
  application: any;
  product: any;
  documentUploadModal: {
    documentUploadModalOpen: boolean;
    title: string;
    category: string;
  };
  isSubmittingToLenders: boolean;
};

export enum ActionType {
  SET_UPLOADED_DOCUMENTS,
  SET_PRODUCT_DOCUMENTS,
  SET_LOADING,
  SET_ERROR,
  SET_APPLICATION,
  SET_PRODUCT,
  SET_LINKED_APPLICATIONS,
  SET_DOCUMENT_UPLOAD_MODAL_OPEN,
  SET_IS_SUBMITTING_TO_LEADERS,
}

export type Action = {
  type: ActionType;
  payload: any;
};

const requiredDocuments = (product: any, uploadedDocuments: any[]) => {
  const productRequiredDocuments = {
    additionalDocuments: product.additionalDocuments,
    hasBankStatements: product.hasBankStatements,
    bankStatements: product.bankStatements,
    hasManagementAccounts: product.hasManagementAccounts,
    hasVATReturns: product.hasVATReturns,
    hasPersonalGuarantee: product.hasPersonalGuarantee,
    hasBusinessPlan: product.hasBusinessPlan,
    hasPitchDeck: product.hasPitchDeck,
    hasFinancialModel: product.hasFinancialModel,
    hasFinancialForecast: product.hasFinancialForecast,
    isSummaryAgedDebtors: product.isSummaryAgedDebtors,
    isDetailedAgedDebtors: product.isDetailedAgedDebtors,
    hasTaxClearance: product.hasTaxClearance,
    hasFixedAsset: product.hasFixedAsset,
    isOutstandingDebt: product.isOutstandingDebt,
    hasShareAndDirectorsRegister: product.hasShareAndDirectorsRegister,
    hasCertificateOfIncorporation: product.hasCertificateOfIncorporation,
    cashFlowForecast: product.cashFlowForecast,
    hasSalie: product.hasSalie,
    isSummaryAgedCreditors: product.isSummaryAgedCreditors,
    isDetailedAgedCreditors: product.isDetailedAgedCreditors,
    filedAuditAccounts: product.filedAuditAccounts,
  };

  return requiredDocumentsAdapter({
    documentsList: uploadedDocuments,
    requiredDocuments: productRequiredDocuments,
    isPrivateLimitedCompany: true,
    opportunity: product,
  });
};

const productRequiredDocumentsDic = {
  hasBankStatements: {
    3: { label: 'Bank Statements', type: 'Financials', title: '3 Months Company Bank Statements' },
    6: { label: 'Bank Statements', type: 'Financials', title: '6 Months Company Bank Statements' },
    12: {
      label: 'Bank Statements',
      type: 'Financials',
      title: '12 Months Company Bank Statements',
    },
  },
  filedAuditAccounts: { title: 'Filed Accounts', type: 'Financials', label: 'Filed Accounts' },
  proofOfIdentity: {
    label: 'Proof of Identity',
    title: 'Proof of Identity',
    type: 'Identification',
  },
  proofOfAddress: {
    label: 'Proof of Address (Dated within last 3 months)',
    title: 'Proof of Address (Dated within last 3 months)',
    type: 'Identification',
  },
  hasManagementAccounts: {
    label: 'Management Accounts',
    type: 'Financials',
    title: 'Management Accounts',
  },
  hasVATReturns: {
    label: i18n.t('general:vatreturns'),
    type: 'Financials',
    title: i18n.t('general:vatreturns'),
  },
  hasFixedAsset: {
    label: i18n.t('home:products:fields:fixedAssetSchedule:label'),
    type: 'Financials',
    title: i18n.t('home:products:fields:fixedAssetSchedule:label'),
  },
  hasCertificateOfIncorporation: {
    label: 'A Certified Copy of the Certificate of Incorporation',
    type: 'Ownership',
    title: 'A Certified Copy of the Certificate of Incorporation',
  },
  isOutstandingDebt: {
    type: 'Financials',
    label: 'A Schedule of Outstanding Debt',
    title: 'A Schedule of Outstanding Debt',
  },
  hasPersonalGuarantee: {
    label: 'I confirm I am willing to offer a Personal Guarantee',
    type: 'Other',
    title: 'Personal Guarantee',
  },
  isSummaryAgedDebtors: {
    label: 'Summary Aged Debtors Report',
    type: 'Financials',
    title: 'Summary Aged Debtors Report',
  },
  isDetailedAgedDebtors: {
    label: 'Detailed Aged Debtors Report',
    type: 'Financials',
    title: 'Detailed Aged Debtors Report',
  },
  isSummaryAgedCreditors: {
    label: 'Summary Aged Creditors Report',
    type: 'Financials',
    title: 'Summary Aged Creditors Report',
  },
  isDetailedAgedCreditors: {
    label: 'Detailed Aged Creditors Report',
    type: 'Financials',
    title: 'Detailed Aged Creditors Report',
  },
  hasBusinessPlan: { label: 'Business Plan', type: 'FundingDocuments', title: 'A Business Plan' },
  hasPitchDeck: { label: 'Pitch Deck', type: 'FundingDocuments', title: 'Pitch Deck' },
  hasFinancialModel: {
    label: 'Financial Model',
    type: 'FundingDocuments',
    title: 'A Financial Model',
  },
  hasFinancialForecast: {
    label: 'Financial Forecast',
    type: 'FundingDocuments',
    title: 'Financial Forecast',
  },
  hasSalie: {
    label: 'Statement of Assets and Liabilities and Income and Expenditure',
    type: 'Financials',
    title: 'Statement of Assets & Liabilities & Income & Expenditure',
  },
  cashFlowForecast: { label: 'Cashflow Forecast', type: 'Financials', title: 'Cashflow Forecast' },
} as any;

const requiredDocumentsAdapter = ({
  documentsList,
  requiredDocuments,
  isPrivateLimitedCompany,
  opportunity,
}: any) => {
  return Object.entries(requiredDocuments)
    .filter(([key, value]) => {
      let isRequired;
      switch (key) {
        case 'hasPersonalGuarantee':
          isRequired =
            value === 'Yes' || (value === 'YesForPrivateLimitedCompany' && isPrivateLimitedCompany);
          break;
        case 'hasBusinessPlan ':
          // For Equity must be always true
          isRequired = opportunity.type === 'Grant' ? true : value;
          break;
        case 'hasPitchDeck ':
          // For Equity must be always true
          isRequired = opportunity.type === 'Equity' ? true : value;
          break;
        default:
          isRequired = value;
      }
      return isRequired && key in productRequiredDocumentsDic;
    })
    .map(([key]: any) => {
      let document: any;
      switch (key) {
        case 'hasPersonalGuarantee':
          document = {
            ...productRequiredDocumentsDic[key],
            isCheckBox: true,
          };
          break;
        case 'hasBankStatements':
          document = productRequiredDocumentsDic[key][opportunity?.bankStatements || 12];
          break;
        case 'filedAuditAccounts':
          document = productRequiredDocumentsDic[key];
          break;
        default:
          document = productRequiredDocumentsDic[key];
      }
      const uploadedDocuments = [
        ...documentsList,
        { title: 'Personal Guarantee', type: 'Other' },
      ].filter((doc) => doc.title === document.title && doc.type === document.type);
      const uploadedDocumentNames = uploadedDocuments.map((doc) => doc.name);
      const foundDocument = documentsList.find(
        (doc: any) =>
          (doc.title.toLowerCase() === document.title.toLowerCase() &&
            doc.type.toLowerCase() === document.type.toLowerCase()) ||
          (doc.title.toLowerCase().startsWith(document.title.toLowerCase()) &&
            doc.type.toLowerCase() === document.type.toLowerCase())
      );
      return {
        ...document,
        key,
        uploadedDocumentNames,
        isDocumentUploaded: Boolean(foundDocument),
        uploadedDocumentId: foundDocument?.documentId,
        uploadedDocumentName: foundDocument?.name,
      };
    });
};

export function productApplicationReducer(state: ProductApplicationType, action: Action) {
  switch (action.type) {
    case ActionType.SET_APPLICATION:
      return {
        ...state,
        application: action.payload,
      };
    case ActionType.SET_LINKED_APPLICATIONS:
      return {
        ...state,
        linkedApplications: action.payload,
      };
    case ActionType.SET_UPLOADED_DOCUMENTS:
      return {
        ...state,
        uploadedDocuments: action.payload,
        requiredDocuments: requiredDocuments(state.product, action.payload),
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        error: { ...state.error, ...action.payload },
      };
    case ActionType.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        requiredDocuments: requiredDocuments(action.payload, state.uploadedDocuments),
      };
    case ActionType.SET_PRODUCT_DOCUMENTS:
      return {
        ...state,
        productDocuments: { ...state.productDocuments, ...action.payload },
      };
    case ActionType.SET_DOCUMENT_UPLOAD_MODAL_OPEN:
      return {
        ...state,
        documentUploadModal: {
          ...state.documentUploadModal,
          documentUploadModalOpen: action.payload.documentUploadModalOpen,
          title: action.payload.title,
          category: action.payload.category,
        },
      };
    case ActionType.SET_IS_SUBMITTING_TO_LEADERS:
      return {
        ...state,
        isSubmittingToLenders: action.payload,
      };
    default:
      return state;
  }
}
