export type productInformationStateType = {
  isSaving: boolean;
  isError: boolean;
  ableToSave: boolean;
  productCategory: string;
  subCategory: string;
};

export enum Action {
  SET_IS_SAVING,
  SET_IS_ERROR,
  SET_ABLE_TO_SAVE,
  SET_PRODUCT_CATEGORY,
  SET_SUB_CATEGORY,
}

export function productInformationReducer(
  state: productInformationStateType,
  action: {
    type: Action;
    payload?: any;
  }
) {
  switch (action.type) {
    case Action.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload,
      };
    case Action.SET_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case Action.SET_ABLE_TO_SAVE:
      return {
        ...state,
        ableToSave: action.payload,
      };
    case Action.SET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload,
      };
    case Action.SET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload,
      };

    default:
      return state;
  }
}
