import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styles from './loader.module.scss';
import { ILoader, LoaderType } from './type';

const Loader = ({ text, active, loaderStyle = LoaderType.PAGE_LOADER }: ILoader) => {
  const showHideClassName = () => {
    if (loaderStyle === LoaderType.PAGE_LOADER) {
      return active ? 'display-block' : 'display-none';
    }
    return active ? 'display-flex' : 'display-none';
  };

  const { t } = useTranslation();

  if (loaderStyle === LoaderType.PAGE_LOADER)
    return ReactDOM.createPortal(
      <div aria-live="polite" className={classNames(styles[showHideClassName()])}>
        <div className={styles['loader-overlay']} id="loader-overlay" />
        <div className={classNames(styles.loader, styles[showHideClassName()])}>
          <section className={styles['loader-main']}>
            <p className={styles['loader-holder']}>
              <svg className={styles.spinner} viewBox="0 0 50 50">
                <circle
                  className={styles.path}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                />
              </svg>
            </p>
            <p>{text || t('common:onemoment')}</p>
          </section>
        </div>
      </div>,
      document.body
    );

  return (
    <div className={classNames(styles['component-loader'], styles[showHideClassName()])}>
      <section className={styles['loader-main']}>
        <p className={styles['loader-holder']}>
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        </p>
        <p>{text || t('common:onemoment')}</p>
      </section>
    </div>
  );
};

export default Loader;
