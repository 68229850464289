import cn from 'classnames';
import styles from './textEditor.module.scss';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const addToDefaultStyles = (customStyles: any = []) => {
  return cn(styles['generic-action'], customStyles);
};

export const editorOptions = {
  options: ['inline', 'fontSize', 'list', 'textAlign'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { className: addToDefaultStyles(styles.bold) },
    italic: { className: addToDefaultStyles() },
    underline: { className: addToDefaultStyles() },
    strikethrough: { className: addToDefaultStyles() },
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 18, 24, 36],
    className: addToDefaultStyles(),
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
    unordered: { className: addToDefaultStyles() },
    ordered: { className: addToDefaultStyles() },
    indent: { className: addToDefaultStyles() },
    outdent: { className: addToDefaultStyles() },
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right', 'justify'],
    left: { className: addToDefaultStyles() },
    center: { className: addToDefaultStyles() },
    right: { className: addToDefaultStyles() },
    justify: { className: addToDefaultStyles() },
  },
};

export const convertHtmlToEditorState = (htmlContent: string): EditorState => {
  if (!htmlContent) {
    return EditorState.createEmpty();
  }

  const { contentBlocks, entityMap } = htmlToDraft(htmlContent);

  // @tonote: Hubspot syncs an empty whitespace div when syncing notes. This filters out any empty blocks.
  const filteredBlocks = contentBlocks.filter((block) => {
    const text = block.getText().trim();
    return text !== '' && text !== '\n';
  });

  const contentState = ContentState.createFromBlockArray(filteredBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

export const getPlainTextFromEditorState = (editorState: EditorState): string => {
  return editorState.getCurrentContent().getPlainText();
};
