import { TablePropType } from 'components/table/type';
import classNames from 'classnames';
import styles from 'pages/products/assets/products.module.scss';

export const field = (items: Array<{ [key: string]: any }>): TablePropType => {
  const updatedItems = items.map((item: { [key: string]: any }) => {
    return [
      {
        classname: {
          cell: styles['tr-cell'],
          value: classNames(styles['td-value-center'], {
            [styles.isEnabled]: item.isEnabled,
          }),
        },
        icon: 'radio_button_checked',
        id: item.productId,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.tradingName ? item.tradingName : item.providerName,
        id: `${item.id}-${item.providerName}`,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.productName,
        id: `${item.id}-${item.productName}`,
      },
      {
        classname: {
          cell: classNames(styles['tr-cell'], styles.tooltip),
          value: styles['td-value'],
        },
        default: item.type,
        id: `${item.id}-${item.type}`,
      },
      {
        classname: {
          cell: styles['tr-cell'],
          value: styles['td-value'],
        },
        default: item.subcategory,
        id: `${item.id}-${item.subCategory}`,
      },
    ];
  });

  return {
    header: [
      {
        value: 'Enabled',
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        value: 'Provider',
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        value: 'Product Name',
        classname: {
          header: classNames(styles['th-header']),
          label: styles['th-label'],
        },
      },
      {
        value: 'Type',
        classname: {
          header: classNames(styles['th-header']),
          label: styles['th-label'],
        },
      },
      {
        value: 'Sub Type',
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    items: updatedItems,
  };
};
