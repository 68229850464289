import {
  fieldDependencyTypes,
  FieldTypes,
  LayoutTypes,
  ValidationTypes,
} from '_shared/fieldValidation/types';
import { getClientSearchCompanies } from '_shared/api/businesses';
import { AutocompleteActionType } from 'components/field/autocomplete/type';
import { TCountry } from 'store/system/types';
import { env } from '_shared/utils';
import styles from './AddClient.module.scss';
import i18next from 'i18next';

const reactAppCountry: TCountry = env('REACT_APP_COUNTRY');

type valueLabelList = {
  value: string;
  label: string;
}[];

export interface IEntities {
  sectors: valueLabelList;
  countries: valueLabelList;
  fundingRequiredTimescales: valueLabelList;
  mainCountries: valueLabelList;
  regions: valueLabelList;
  counties: valueLabelList;
  districts: valueLabelList;
  districtsWithoutCounty: valueLabelList;
  companyStructure: valueLabelList;
  fundingForms?: valueLabelList;
  companyAccountStatuses: valueLabelList;
}

const callBack = async (action: AutocompleteActionType, companyName: string) => {
  if (!companyName || typeof companyName !== 'string') return [];

  const {
    data: { clientSearchCompanies },
  } = await getClientSearchCompanies(companyName);

  return clientSearchCompanies.map((company: any) => {
    let newCompanyName = company.name;

    newCompanyName += company.countryCode ? ` (${company.countryCode})` : '';
    newCompanyName += company.companyRegistrationNumber
      ? ` (${company.companyRegistrationNumber})`
      : '';

    return {
      label: newCompanyName,
      value: newCompanyName,
      metaData: company,
    };
  });
};

export const addClientFields = (entities: IEntities) => {
  return [
    {
      id: 'company',
      name: 'company',
      placeholder: 'Company name',
      label: "What is  your client's company name",
      required: true,
      callBack,
      fieldType: FieldTypes.AUTOCOMPLETE,
      validationtype: ValidationTypes.AUTOCOMPLETE,
      value: [],
      options: [],
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: false,
          dependencyType: fieldDependencyTypes.DISABLE,
        },
      ],
      hidden: reactAppCountry === 'canada',
    },
    {
      id: 'cantFindCompany',
      name: 'cantFindCompany',
      value: reactAppCountry === 'canada',
      hidden: reactAppCountry === 'canada',
      boolean: true,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        field: styles['cant-find-company-checkbox'],
      },
      options: [
        {
          name: 'cantFindCompany',
          value: false,
          label: "I can't find my client's company",
          parentId: 'cantFindCompany',
        },
      ],
    },
    {
      id: 'companyName',
      name: 'companyName',
      placeholder: 'Company name',
      label: "What is your client's company name?",
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
      classNames: {
        value: styles['company-name-value'],
      },
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'haventDecidedYet',
          value: (field: any) => !field.value,
          dependencyType: fieldDependencyTypes.DISABLE,
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'haventDecidedYet',
      name: 'haventDecidedYet',
      boolean: true,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      value: false,
      classNames: {
        field: styles['cant-find-company-checkbox'],
      },
      options: [
        {
          name: 'haventDecidedYet',
          value: true,
          label: "My client hasn't decided yet",
          parentId: 'haventDecidedYet',
        },
      ],
      dependsOn: {
        name: 'cantFindCompany',
        value: true,
      },
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'companyNumber',
      name: 'companyNumber',
      placeholder: i18next.t('sidebar:addclientmodal.fields.companynumber.placeholder'),
      label: i18next.t('sidebar:addclientmodal.fields.companynumber.label'),
      value: '',
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
      dependsOn: [
        {
          name: 'tradingAs',
          value: ({ value }: any) => {
            return value !== 'Not yet incorporated';
          },
        },
        {
          name: 'cantFindCompany',
          value: true,
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'country',
      name: 'country',
      label: "Where is your client's business located?",
      required: true,
      placeholder: 'Select',
      options: entities.mainCountries,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      dependsOn: {
        name: 'cantFindCompany',
        value: true,
      },
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'stateCode',
      name: 'stateCode',
      label: "Select your client's country",
      placeholder: 'Select',
      metadata: {
        optionsType: 'regions',
        requiredParentField: 'country',
      },
      options: entities.regions,
      required: true,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'country',
          value: 'United Kingdom',
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'province',
      name: 'province',
      label: "Select your client's province",
      placeholder: 'Select',
      metadata: {
        optionsType: 'regions',
        requiredParentField: 'country',
      },
      options: entities.regions,
      required: true,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'country',
          value: (field: any) => ['Ireland', 'Canada', 'South Africa'].includes(field.value),
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'state',
      name: 'state',
      label: "Select your client's state",
      placeholder: 'Select',
      metadata: {
        optionsType: 'regions',
        requiredParentField: 'country',
      },
      options: entities.regions,
      required: true,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'country',
          value: ({ value }: any) => ['United States of America', 'Australia'].includes(value),
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'county',
      name: 'county',
      label: "Select your client's county",
      required: true,
      placeholder: 'Select',
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      metadata: {
        optionsType: 'counties',
        requiredParentField: 'stateCode',
      },
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'stateCode',
          value: '4',
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'districtBasedOnCounty',
      name: 'districtBasedOnCounty',
      label: "Select your client's district",
      required: true,
      placeholder: 'Select',
      options: entities.districts,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      metadata: {
        optionsType: 'districts',
        requiredParentField: 'county',
      },
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'stateCode',
          value: '4',
        },
        {
          name: 'county',
          value: ({ value }: any) => !!value,
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'districtBasedOnStateCode',
      name: 'districtBasedOnStateCode',
      label: "Select your client's district",
      required: true,
      placeholder: 'Select',
      options: entities.districtsWithoutCounty,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
      metadata: {
        optionsType: 'districtsWithoutCounty',
        requiredParentField: 'stateCode',
      },
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'stateCode',
          value: ({ value }: any) => value && !['4', '5', '6'].includes(value),
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'tradingAs',
      name: 'tradingAs',
      label: 'What is your client trading as?',
      placeholder: 'Select',
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      required: true,
      options: entities.companyStructure,
      value: '',
      dependsOn: {
        name: 'cantFindCompany',
        value: true,
      },
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'tradingStartDate',
      name: 'tradingStartDate',
      label: "When did your client's business start trading?",
      fieldType: FieldTypes.DATEPICKER,
      value: '',
      required: true,
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'tradingAs',
          value: ({ value }: any) => {
            return value && value !== 'Not yet incorporated';
          },
        },
        {
          name: 'notYetTrading',
          value: ({ value }: any) => !value,
          dependencyType: fieldDependencyTypes.DISABLE,
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'notYetTrading',
      name: 'notYetTrading',
      value: false,
      boolean: true,
      required: true,
      fieldType: FieldTypes.CHECKBOX,
      classNames: {
        field: styles['cant-find-company-checkbox'],
      },
      options: [
        {
          name: 'notYetTrading',
          value: true,
          label: 'Not yet trading',
          parentId: 'notYetTrading',
        },
      ],
      dependsOn: [
        {
          name: 'cantFindCompany',
          value: true,
        },
        {
          name: 'tradingAs',
          value: ({ value }: any) => {
            return value && value !== 'Not yet incorporated';
          },
        },
      ],
      layout: LayoutTypes.GROUPED,
    },
    {
      id: 'turnoverLastYear',
      name: 'turnoverLastYear',
      label: "What was your client's revenue last year?",
      value: '',
      required: true,
      fieldType: FieldTypes.CURRENCY,
      validationtype: ValidationTypes.CURRENCY,
      dependsOn: [
        {
          name: 'tradingAs',
          value: ({ value }: any) => {
            return value !== 'Not yet incorporated';
          },
        },
        {
          name: 'notYetTrading',
          value: ({ value }: any) => !value,
          dependencyType: fieldDependencyTypes.DISABLE,
        },
      ],
    },
    {
      id: 'sectors',
      name: 'sectors',
      label: 'Which sector(s) does your client operates in?',
      placeholder: 'Sectors',
      fieldType: FieldTypes.MULTISELECT,
      validationtype: ValidationTypes.MULTISELECT,
      required: true,
      options: entities.sectors,
      value: [],
    },
    {
      id: 'fundingAmount',
      name: 'fundingAmount',
      label: 'How much funding does your client require?',
      fieldType: FieldTypes.CURRENCY,
      validationtype: ValidationTypes.CURRENCY,
      required: true,
      value: 0,
    },
    {
      id: 'whenFundingNeeded',
      name: 'whenFundingNeeded',
      label: 'When does your client need the funding?',
      placeholder: 'Select',
      required: true,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      options: entities.fundingRequiredTimescales || [],
      value: '',
    },
    {
      id: 'reasonForFunding',
      name: 'reasonForFunding',
      label: 'What is the specific reason your client needs funding?',
      required: true,
      placeholder: 'Select',
      options: entities?.fundingForms || [],
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      value: '',
    },
    {
      id: 'firstName',
      name: 'firstName',
      placeholder: 'First name',
      label: "What is your client's full name?",
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
      layout: LayoutTypes.INLINE,
    },
    {
      id: 'lastName',
      name: 'lastName',
      placeholder: 'Last name',
      value: '',
      required: true,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      layout: LayoutTypes.INLINE,
    },
    {
      id: 'contactNumber',
      name: 'contactNumber',
      placeholder: 'Contact Number',
      label: "What is your client's phone number?",
      value: '',
      required: false,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
    },
    {
      id: 'email',
      name: 'email',
      placeholder: 'Email',
      label: "What is your client's email address?",
      value: '',
      required: false,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.TEXT,
      type: 'text',
    },
    {
      id: 'confirmEmail',
      name: 'confirmEmail',
      placeholder: 'Email',
      label: "Please confirm your client's email.",
      value: '',
      required: false,
      fieldType: FieldTypes.TEXT,
      validationtype: ValidationTypes.CONFIRM_EMAIL,
      type: 'text',
    },
    {
      id: 'canFundingManagersContactClient',
      name: 'canFundingManagersContactClient',
      label: 'Allow Swoop Funding Managers to contact this client.',
      value: '',
      boolean: true,
      fieldType: FieldTypes.CUSTOM_SELECT,
      validationtype: ValidationTypes.CUSTOM_SELECT,
      placeholder: 'Select',
      options: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
    },
    {
      id: 'roleInCompany',
      name: 'roleInCompany',
      label: "What is your client's role in the company?",
      validationtype: ValidationTypes.MULTISELECT,
      value: [],
      placeholder: 'home:companydetails:overview:accountinfo:contact:role',
      options: [
        {
          label: 'Director',
          value: 'Director',
        },
        {
          label: 'Guarantor',
          value: 'Guarantor',
        },
        {
          label: 'Shareholder',
          value: 'Shareholder',
        },
      ],
      fieldType: FieldTypes.MULTISELECT,
    },
  ];
};
