import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { SizeEnum, SwitchProps } from './type';
import styles from './switch.module.scss';
import { TextformatType } from '_shared/fieldValidation/types';

const Switch: React.FC<SwitchProps> = ({ defaultChecked = false, onChange, ...props }) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  const handleClick = (event: any) => {
    if (props.disabled) return;
    if (onChange) {
      onChange(event);
    }
    if (props.checked === undefined) {
      setChecked(!checked);
    }
  };

  return (
    <button
      id={props.id}
      type="button"
      className={cn(
        styles.switch,
        { [styles['switch--disabled']]: props.disabled },
        props.tooltip && styles['switch-tooltip']
      )}
      onClick={handleClick}
      data-customfield={TextformatType.SWITCH}
      data-checked={checked}
      name={props.name}
      data-label={props.tooltip}
      data-testid={props.id}
    >
      {props.label && <span className={cn(styles.label)}>{props.label}</span>}
      <div
        data-size={props.size || SizeEnum.LARGE}
        className={cn(styles.inner, {
          [styles['inner--checked']]: checked,
          [styles['inner--unchecked']]: !checked,
        })}
      />
    </button>
  );
};

export default Switch;
