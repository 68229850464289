import cn from 'classnames';
import styles from '../../assets/dangerZoneManagement.module.scss';
import Button from 'components/button';
import { ActionType } from 'pages/userDetails/reducer';
import { useTranslation } from 'react-i18next';
import { patchActivationStatus } from '_shared/api/users';
import Modal from 'components/modal';
import { ButtonStyleTypeEnum } from 'components/button/type';
import useToastMessage from '_shared/hooks/useToastMessage';
import { UserActivationStatus } from 'pages/userDetails/type';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';

type DangerZoneManagementProps = {
  userDetailsState: any;
  userDetailsDispatch: any;
  securityId: string;
};

const DangerZoneManagement = ({
  userDetailsState,
  userDetailsDispatch,
  securityId,
}: DangerZoneManagementProps) => {
  const { t } = useTranslation();
  const { successToastMessage, errorToastMessage } = useToastMessage();

  const firstName = userDetailsState.userDetailsInfo.firstName ?? '';
  const lastName = userDetailsState.userDetailsInfo.lastName ?? '';

  const usersFullName = firstName || lastName ? `${firstName} ${lastName}` : 'N/A';
  const usersEmail = userDetailsState.userDetailsInfo.email ?? 'N/A';
  const securtyId = userDetailsState.userDetailsInfo.authId ?? '';

  const showDeleteUserConfirmationModal = () => {
    userDetailsDispatch({
      type: ActionType.SHOW_DELETE_CONFIRMATION_MODAL,
      payload: {
        deleteUserConfirmationModal: true,
      },
    });
  };

  const closeDeleteUserModal = () => {
    userDetailsDispatch({
      type: ActionType.SHOW_DELETE_CONFIRMATION_MODAL,
      payload: {
        deleteUserConfirmationModal: false,
      },
    });
  };

  const deleteUserHandler = () => {
    // BE Delete Status expects: 2
    userActivationStatusHandler(securityId, UserActivationStatus.DEACTIVATED);
  };

  const userActivationStatusHandler = async (
    securityId: string,
    userStatus: UserActivationStatus
  ) => {
    const restoringUser = userStatus === UserActivationStatus.ACTIVE;
    const deletingUser = userStatus === UserActivationStatus.DEACTIVATED;
    try {
      userDetailsDispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: true,
        },
      });

      if (!securityId) return;

      await patchActivationStatus(securityId, userStatus);

      if (restoringUser) {
        successToastMessage(t('home:colleagues:details:toast:reactivatedusersuccess'));
      }

      if (deletingUser) {
        successToastMessage(t('home:colleagues:details:toast:deactivatedusersuccess'));
      }
    } catch (error) {
      userDetailsDispatch({
        type: ActionType.SET_ERRORS,
        payload: {
          error: true,
        },
      });
      if (restoringUser) {
        errorToastMessage('Failed to restore colleague');
      } else if (deletingUser) {
        errorToastMessage('Unable to disable colleague');
      } else {
        errorToastMessage('Something went wrong');
      }
    } finally {
      userDetailsDispatch({
        type: ActionType.SET_LOADING,
        payload: {
          loading: false,
        },
      });
      closeDeleteUserModal();
    }
  };

  return (
    <div data-testid="modalContainer" className={styles['danger-zone-container']}>
      <h3>Disable User</h3>

      <p className={styles['warnng-text']}>
        The user will be disabled and will no longer have access to the application.
      </p>

      <Button
        data-testid="delete-user-btn"
        ariaLabel={'delete-user-btn'}
        id={'delete-user-btn'}
        className={styles['delete-user-btn']}
        clickHandler={showDeleteUserConfirmationModal}
      >
        Disable {usersFullName}
      </Button>

      <h3>Recover User</h3>

      <p>The user will be recovered and gain access to the application again.</p>

      <Button
        ariaLabel={'recover-user-btn'}
        id={'recover-user-btn'}
        dataTestId="recover-user-btn"
        className={styles['recover-user-btn']}
        clickHandler={() =>
          // BE Restore Status expects: 1
          userActivationStatusHandler(securityId, UserActivationStatus.ACTIVE)
        }
      >
        Recover {usersFullName}
      </Button>

      <Modal
        show={userDetailsState.deleteUserConfirmationModal === true}
        handleClose={closeDeleteUserModal}
      >
        <div className={styles['user-deletion-confirm-modal']}>
          <h3>{t('home:colleagues:deletionmodal:title')}</h3>
          <p className={styles['warning']}>
            {t('home:colleagues:deletionmodal:warningtext', { name: usersFullName })}
          </p>
          <div className={styles['table-wrapper']}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={securtyId}>
                  <TableCell>{usersFullName}</TableCell>
                  <TableCell>{usersEmail}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={styles['actions']}>
            <Button
              id="delete-modal-cancel"
              ariaLabel="delete-modal-cancel"
              dataTestId="cancel-modal-action"
              buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
              tabIndex={0}
              clickHandler={closeDeleteUserModal}
            >
              {t('common:cancel')}
            </Button>
            <Button
              id="delete-modal-action"
              ariaLabel="delete-modal-action"
              dataTestId="confirm-delete-modal-action"
              buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
              className={cn(styles['delete'])}
              tabIndex={0}
              clickHandler={deleteUserHandler}
            >
              {t('common:disable')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DangerZoneManagement;
