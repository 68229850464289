import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import ProductApplicationForm from '.';

export const productApplicationFormRoutes: RouteObject[] = [
  {
    path: RoutePath.productapplicationform,
    element: withTransaction('ProductApplicationForm', 'component')(<ProductApplicationForm />),
  },
];
