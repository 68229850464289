import { LSKeys } from '../utils/constants';
import { CustomMenuItem } from 'layouts/type';
import { randomIdGenerator } from '../utils';

const useBookmarks = () => {
  const getBookmarks = () => {
    const rawBookmarksData = window.localStorage.getItem(LSKeys.customMenu);
    let bookmarks: CustomMenuItem[] = [];

    try {
      bookmarks = JSON.parse(rawBookmarksData || '[]');
    } catch (error) {
      // Ignore parsing error and return empty array
    }

    return bookmarks;
  };

  const getBookmarkById = (id: string) => {
    const bookmarks = getBookmarks();
    return bookmarks.find((bookmark) => bookmark.id === id);
  };

  const addBookmark = (bookmark: Omit<CustomMenuItem, 'id'>) => {
    const bookmarks = getBookmarks();
    const newBookmark = { ...bookmark, id: randomIdGenerator() } as CustomMenuItem;
    const newBookmarks = [...bookmarks, newBookmark];
    window.localStorage.setItem(LSKeys.customMenu, JSON.stringify(newBookmarks));
  };

  const updateBookmark = (bookmark: CustomMenuItem) => {
    const bookmarks = getBookmarks();
    const index = bookmarks.findIndex((x) => x.id === bookmark.id);
    bookmarks[index] = bookmark;
    window.localStorage.setItem(LSKeys.customMenu, JSON.stringify(bookmarks));
  };

  const deleteBookmark = (id: string) => {
    const bookmarks = getBookmarks();
    const newBookmarks = bookmarks.filter((x) => x.id !== id);
    window.localStorage.setItem(LSKeys.customMenu, JSON.stringify(newBookmarks));
  };

  return { getBookmarks, getBookmarkById, addBookmark, updateBookmark, deleteBookmark };
};

export default useBookmarks;
