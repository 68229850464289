import { saveState } from 'store';
import { SystemState, SystemActionTypes, UPDATE_SESSION, DISABLE_MODAL } from './types';
import {
  isBroker,
  isSuperAdmin,
  isAdminOrSuperAdmin,
  isBrokerRoleType,
  isAccountantRoleType,
  isMarketPlaceManager,
  hasOneOfRoles,
  isUserManager,
} from '_shared/utils/permission';
import { defaultSelectedGroup } from 'config/grouping';

export const initialState: SystemState = {
  currentUser: undefined,
  accessToken: '',
  groupId: '',
  exposedGroups: [],
  selectedGroups: [defaultSelectedGroup],
  roles: [],
  hasOnboardedGroups: false,
  hasOnboardedDeals: false,
  isSuperAdmin: function () {
    return isSuperAdmin(this?.currentUser);
  },
  isAdminOrSuperAdmin: function () {
    return isAdminOrSuperAdmin(this?.currentUser);
  },
  isMarketPlaceManager: function () {
    return isMarketPlaceManager(this?.currentUser);
  },
  isBroker: function () {
    return isBroker(this?.currentUser);
  },
  isBrokerRoleType: function () {
    return isBrokerRoleType(this?.currentUser);
  },
  isAccountantRoleType: function () {
    return isAccountantRoleType(this?.currentUser);
  },
  hasOneOfRoles: function (roles: string[]) {
    if (!this?.currentUser) {
      return false;
    } else {
      return hasOneOfRoles(this.currentUser, roles);
    }
  },
  isUserManager: function () {
    return isUserManager(this?.currentUser);
  },
};

export function systemReducer(state: SystemState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_SESSION: {
      saveState({ ...state, ...action.payload } as SystemState);

      return {
        ...state,
        ...action.payload,
      };
    }
    case DISABLE_MODAL: {
      if (state.currentUser?.disabledModalsList?.includes(action.payload)) {
        return state;
      }

      const disabledModalsList = [...(state.currentUser?.disabledModalsList ?? []), action.payload];

      saveState({
        ...state,
        currentUser: { ...state.currentUser, disabledModalsList },
      } as SystemState);

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          disabledModalsList,
        },
      };
    }
    default:
      return state;
  }
}
