import React, { useEffect, useMemo, useReducer } from 'react';
import { Link, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '_shared/routes';
import styles from 'pages/productDetails/assets/productDetails.module.scss';
import {
  currentProductReducer,
  ProductDetailsContext,
  ActionType,
} from './store/productDetails.reducer';
import { AmplitudeTrackingEnum } from 'config/amplitude';
import { getProduct, getProviderOptions } from '_shared/api/products';
import { useAppState } from 'store';
import { UPDATE_PRODUCTS } from 'store/products/types';
import { IContext } from 'store/types';
import classNames from 'classnames';
import { SDR_GROUP_ID, SWOOP_GROUP_ID } from '_shared/utils/application';
import TabList from 'components/v2/TabList';
import Tab from 'components/v2/Tab';

const ProductDetailsRoute: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams() as { id: string };
  const { state: globalState, dispatch: globalDispatch }: IContext = useAppState();
  const productId = globalState.deals?.funderId ? globalState.deals?.funderId : id;
  const [state, dispatch] = useReducer(currentProductReducer, {
    redirectToReferrer: false,
    from: location.state || { pathname: RoutePath.companies },
    activeKey: '',
    isLoading: true,
    id: '',
    product: {},
    isError: false,
  });
  const currentProductId = useMemo(() => productId, [productId]);

  const tabItems = useMemo(() => {
    const tabs = ['information'];

    if (globalState.system.isMarketPlaceManager?.()) {
      tabs.push('documents', 'applicationform');
    }

    if (
      globalState.system?.groupId === SWOOP_GROUP_ID ||
      globalState.system?.groupId === SDR_GROUP_ID
    ) {
      tabs.push('insights');
    }

    return tabs.map((tab) => {
      const route = RoutePath[`product${tab}`];
      const routeLink = route.replace(':id', currentProductId);
      return {
        keys: [routeLink],
        title: <Link to={routeLink}>{t(`home:productdetails:${tab}:title`)}</Link>,
        tracking: AmplitudeTrackingEnum.producttab,
      };
    });
  }, [t, currentProductId]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_ACTIVE_KEY, payload: location.pathname });
  }, [location.pathname]);

  const fetchProduct = async () => {
    try {
      const { data } = await getProduct(currentProductId);
      const { data: providerList } = await getProviderOptions();
      dispatch({ type: ActionType.SET_PROVIDER_LIST, payload: providerList });
      dispatch({ type: ActionType.SET_PRODUCT, payload: data });
      globalDispatch({
        type: UPDATE_PRODUCTS,
        payload: {
          ...globalState.product,
          product: data,
        },
      });
    } catch (e) {
      dispatch({ type: ActionType.SET_ERROR, payload: true });
    } finally {
      dispatch({ type: ActionType.DATA_LOADER, payload: false });
    }
  };

  const ProductHeader = () => {
    return (
      <div className={styles['product-header']}>
        {(globalState?.product?.product?.logoUri && (
          <img alt="logo" src={globalState?.product?.product?.logoUri} className={styles.logo} />
        )) ||
          null}
        {(globalState?.product?.product?.productName && (
          <h1 className={classNames('sw-h1', styles.title)}>
            {globalState?.product?.product?.productName}
          </h1>
        )) ||
          null}
      </div>
    );
  };

  useEffect(() => {
    if (!currentProductId) return;

    fetchProduct();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);

  if (state.redirectToReferrer) {
    return <Navigate to={state.from} />;
  }

  return (
    <div className={styles['page-wrapper']}>
      <ProductHeader />
      <TabList
        value={state.activeKey}
        onChange={(value) => dispatch({ type: ActionType.SET_ACTIVE_KEY, payload: value })}
      >
        {tabItems.map((tab) => (
          <Tab value={tab.keys[0]} key={tab.keys[0]}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <div className={styles.content}>
        <ProductDetailsContext.Provider value={{ state, dispatch }}>
          <Outlet />
        </ProductDetailsContext.Provider>
      </div>
    </div>
  );
};

export default ProductDetailsRoute;
