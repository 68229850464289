import { FieldTypes } from '_shared/fieldValidation/types';

const downloadDocumentLink = (url: string, name: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
  setTimeout(() => window.URL.revokeObjectURL(url), 40000);
  a.remove();
};

const convertServerDataTypeToFieldType = (dataType: string) => {
  switch (dataType) {
    case 'ReadOnly':
      return FieldTypes.READONLY;
    case 'String':
      return FieldTypes.TEXT;
    case 'Select':
      return FieldTypes.SELECT;
    case 'Bool':
      return FieldTypes.RADIO;
    case 'TextBox':
      return FieldTypes.TEXTAREA;
    case 'Decimal':
      return FieldTypes.TEXT;
    case 'Date':
      return FieldTypes.DATEPICKER;
    case 'StringArray':
      return FieldTypes.MULTISELECT;
    default:
      return FieldTypes.TEXT;
  }
};

const env = (env: string) => (window as any)?._env_?.[env] || process.env[env];

const isObject = (item: any) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

// Generates a random hexadecimal ID that can be added to and used as a unique identifier for tracking objects without Id's on the FE.
// Should not be used for any security-sensitive purposes such as access tokens or encryption keys.
const randomIdGenerator = () => {
  return Math.floor((1 + Math.random()) * 0x10000000)
    .toString(16)
    .substring(1);
};

const mergeDeep = (
  target: { [key: string]: any },
  ...sources: { [key: string]: any }[]
): { [key: string]: any } => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

export {
  env,
  downloadDocumentLink,
  convertServerDataTypeToFieldType,
  mergeDeep,
  randomIdGenerator,
};
