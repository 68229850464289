import { displayStringValue } from '_shared/utils/string';
import { TablePropType } from 'components/table/type';
import styles from 'pages/fundingMatches/assets/productApplication.module.scss';

export const field = (
  items: Array<{ [key: string]: any }>,
  openDocumentUploadModal: Function
): TablePropType => {
  return {
    header: [
      {
        value: 'Document',
        classname: {
          header: styles['th-header'],
        },
      },
      {
        value: 'Uploaded',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
        },
      },
      {
        value: 'Upload',
        classname: {
          header: styles['th-header'],
          icon: styles['th-icon'],
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    emptyMessage: 'No required documents',
    classname: { table: styles.table },
    items: items.map((item: { [key: string]: any }) => {
      return [
        {
          classname: {
            cell: styles['tr-cell'],
            value: styles['td-value'],
          },
          default: displayStringValue(item?.title),
          tooltip: true,
          id: item.key,
        },
        {
          classname: {
            cell: styles['tr-cell'],
            value: item.isDocumentUploaded ? styles.uploaded : styles['not-uploaded'],
          },
          icon: item.isDocumentUploaded ? 'check' : 'close',
        },
        {
          classname: {
            cell: styles['tr-cell'],
            value: styles['document-upload-icon'],
          },
          icon: 'cloud_upload',
          actionCb: openDocumentUploadModal,
          id: item.title,
          name: item.type,
        },
      ];
    }),
  };
};
