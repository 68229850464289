import styles from './assets/login.module.scss';
import LoginSidebar from './components/loginSidebar';
import Card from 'components/card';
import { Link, useLocation } from 'react-router-dom';
import Footer from 'components/footer';
import { RoutePath } from '_shared/routes';
import Button from 'components/button';

const ForgotPasswordCallbackRoute = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.hash.substring(1));

  const resetPasswordSuccess = params.get('id_token');
  const resetPasswordErrorTitle = params.get('error');
  const resetPasswordDetails = params.get('error_description');

  let errorCode;
  let errorDescription;

  if (resetPasswordDetails) {
    const [code, description] = resetPasswordDetails.split(':');
    errorCode = code;
    errorDescription = description;
  }

  if (resetPasswordSuccess) {
    return (
      <div className={styles.container}>
        <LoginSidebar />
        <div className={styles.content}>
          <Card>
            <div className={styles['login-form-container']}>
              <h1 className="sw-h1">Successfully reset password!</h1>
              <div className={styles['button-container']}>
                <Button id="sign-in" ariaLabel="Login button" className={styles['action-button']}>
                  <Link to={RoutePath.login}>Login</Link>
                </Button>
              </div>
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <LoginSidebar />
        <div className={styles.content}>
          <Card>
            <div className={styles['login-form-container']}>
              <h1 className="sw-h1">Unable to reset password</h1>
              <h4 className="sw-h4">
                Sorry we ran into an issue resetting your password. Please try again or{' '}
                <a
                  href="https://swoopfunding.com/contact-us/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact us.
                </a>
              </h4>
              <div className={styles['error-msg-container']}>
                <p className="sw-p">
                  <strong>Error: </strong>
                  {resetPasswordErrorTitle}
                </p>
                <p className="sw-p">
                  <strong>Error Code: </strong>
                  {errorCode}
                </p>
                <p className="sw-p">
                  <strong>Description: </strong>
                  {errorDescription}
                </p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  id="try-again-reset-password"
                  ariaLabel="Try again reset password button"
                  className={styles['action-button']}
                >
                  <Link to={RoutePath.login}>Try again</Link>
                </Button>
              </div>
            </div>
          </Card>
          <Footer />
        </div>
      </div>
    );
  }
};

export default ForgotPasswordCallbackRoute;
