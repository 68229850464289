import { BrokerAPIURL } from '_shared/url';
// eslint-disable-next-line import/no-cycle
import { BrokerAPI } from '../index';

export const getProducts = (params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getProductList, { params });

export const getProduct = (productId: string) => BrokerAPI.get(BrokerAPIURL.getProduct(productId));

export const putProduct = (data: { [key: string]: any }) =>
  BrokerAPI.put(BrokerAPIURL.putProduct, data);

export const putApplicationFormDefinition = (productId: string, data: { [key: string]: any }) =>
  BrokerAPI.put(BrokerAPIURL.putApplicationFormDefinition(productId), data);

export const getApplicationFormDefinition = (productId: string) =>
  BrokerAPI.get(BrokerAPIURL.getApplicationFormDefinition(productId));

export const putProductInsights = (productId: string, data: { [key: string]: any }) =>
  BrokerAPI.put(BrokerAPIURL.putProductInsights(productId), data);

export const getProductInsights = (productId: string) =>
  BrokerAPI.get(BrokerAPIURL.getProductInsights(productId));

export const getApplicationFormProduct = (productId: string) =>
  BrokerAPI.get(BrokerAPIURL.getApplicationProduct(productId));

export const getProviderOptions = () => BrokerAPI.get(BrokerAPIURL.getProviderOptions);

export const updateApplicationStatus = (
  companyId: string,
  applicationId: string,
  data: { Status: string; OpportunityId: string }
) => {
  return BrokerAPI.patch(BrokerAPIURL.updateApplicationStatus(companyId, applicationId), data);
};

export const putProductApplication = (companyId: string, applicationId: string, data: any) =>
  BrokerAPI.put(BrokerAPIURL.putProductApplication(companyId, applicationId), data);
