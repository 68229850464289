import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import RegisterUserIsv from '.';

export const registerIsvRoutes: RouteObject[] = [
  {
    path: RoutePath.registerIsv,
    element: withTransaction('RegisterIsv', 'component')(<RegisterUserIsv />),
  },
];
