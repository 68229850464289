import styles from '../assets/duplicateDocumentModal.module.scss';
import { TablePropType } from 'components/table/type';
import i18next from 'i18next';
import { convertToLocalDateFormat } from '_shared/utils/date';

const defaultHeaderStyles = {
  header: styles['th-header'],
  label: styles['th-label'],
};

const defaultCellStyles = {
  cell: styles['tr-cell'],
  value: styles['td-value'],
};

export const duplicateDocumentsFields = (data: Array<{ [key: string]: any }>): TablePropType => {
  return {
    header: [
      {
        id: 'category',
        value: i18next.t('Category'),
        classname: {
          header: styles['th-header'],
          label: styles['th-label'],
        },
      },
      {
        id: 'title',
        value: i18next.t('Title'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'fileName',
        value: i18next.t('File Name'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
      {
        id: 'uploadedDate',
        value: i18next.t('Uploaded Date'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t('No duplicate documents found'),
    items: data.map((document: any) => {
      return [
        {
          id: `${document?.documentId}-category`,
          classname: {
            ...defaultCellStyles,
          },
          default: document?.type,
        },
        {
          id: `${document?.documentId}-title`,
          classname: {
            ...defaultCellStyles,
          },
          default: document?.title,
        },
        {
          id: `${document?.documentId}-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: document?.name,
        },
        {
          id: `${document?.documentId}-uploadedDate`,
          classname: {
            ...defaultCellStyles,
          },
          default: convertToLocalDateFormat(document?.uploadedDate),
        },
      ];
    }),
  };
};

export const duplicateFileNameField = (data: Array<{ [key: string]: any }>): TablePropType => {
  return {
    header: [
      {
        id: 'fileName',
        value: i18next.t('File Name'),
        classname: {
          ...defaultHeaderStyles,
        },
      },
    ],
    row: {
      classname: styles['tr-row'],
    },
    classname: { table: styles.table },
    emptyMessage: i18next.t('No duplicate documents found'),
    items: data.map((item: { [key: string]: any }) => {
      return [
        {
          id: `${item?.id}-name`,
          classname: {
            ...defaultCellStyles,
          },
          default: item?.name,
        },
      ];
    }),
  };
};
