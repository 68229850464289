import { PutAccountInfoType } from 'pages/companyOverview/types';
import { BrokerAPIURL } from '_shared/url';
import { resetXGroupHeaders } from '_shared/utils/grouping';
import { BrokerAPI } from '../index';

export enum QueryKeys {
  GetCompanies = 'getCompanies',
  GetDeals = 'getDeals',
  GetDealsStatistics = 'getDealsStatistics',
}

export const getCompanies = (params: { [key: string]: any }) =>
  BrokerAPI.get<{ paging: any; results: any }>(BrokerAPIURL.getCompanies, {
    params,
  });

export const getApplications = (companyId: string, params: { [key: string]: any }) =>
  BrokerAPI.get(BrokerAPIURL.getApplications(companyId), {
    params,
  });

export const getDeals = (params?: { [key: string]: any }) =>
  BrokerAPI.get<{ paging: any; results: any }>(BrokerAPIURL.getDeals, { params });

export const getDealsStatistics = (params?: { [key: string]: any }) =>
  BrokerAPI.get<any>(BrokerAPIURL.getDealsStatistics, { params });

export const postDeal = (
  companyId: string,
  data: { applications: []; FieldValues: { Name: string; Value: string; readonly: boolean }[] }
) => BrokerAPI.post(BrokerAPIURL.postDeal(companyId), data);

export const postDealApplication = (
  companyId: string,
  productId: string,
  data: { [key: string]: any }
) => BrokerAPI.post(BrokerAPIURL.postDealApplication(companyId, productId), data);

export const putDeal = (companyId: string, opportunityId: string, data: { [key: string]: any }) =>
  BrokerAPI.put(BrokerAPIURL.putDeal(companyId, opportunityId), data);

export const getDeal = (companyId: string, opportunityId: string) =>
  BrokerAPI.get(BrokerAPIURL.getDeal(companyId, opportunityId));

export const deleteDeal = (companyId: string, opportunityId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteDeal(companyId, opportunityId));

export const getCompanyChargeDetails = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getCompanyChargeDetails(id));

export const getCompanyInfo = (id: string) => BrokerAPI.get(BrokerAPIURL.getCompanyInfo(id));

export const getBusinessFinancialSnapshot = async (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getBusinessFinancialSnapshot(id));

export const getBusinessPeopleInfo = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getBusinessPeopleInfo(id));

export const getBusinessAccountInfo = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getBusinessAccountInfo(id));

export const getClientSearchCompanies = (companyName: string) =>
  BrokerAPI.get(BrokerAPIURL.getClientSearchCompanies(companyName));

export const getCompanyAssignmentsHistory = (id: string, historyType: string) =>
  BrokerAPI.get(BrokerAPIURL.getAssignmentHistory(id, historyType));

export const postCreateCompany = (data: any) =>
  BrokerAPI.post(BrokerAPIURL.postCreateCompany, data, resetXGroupHeaders());

export const putAccountInfo = (companyId: string, data: PutAccountInfoType) =>
  BrokerAPI.put(BrokerAPIURL.putAccountInfo(companyId), data);

export const getCompanyContacts = (id: string) =>
  BrokerAPI.get(BrokerAPIURL.getCompanyContacts(id));

export const postContact = (companyId: string) =>
  BrokerAPI.post(BrokerAPIURL.postContact(companyId));

export const postContactAddress = (companyId: string, contactId: string) =>
  BrokerAPI.post(BrokerAPIURL.postContactAddress(companyId, contactId));

export const deleteContact = (companyId: string, contactId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteContact(companyId, contactId));

export const getFieldDefinitions = () => BrokerAPI.get(BrokerAPIURL.getFieldDefinitions);

export const getCreditReports = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCreditReports(companyId));

export const putAssignUser = (
  companyId: string,
  data: { userSecurityId: string; userRole: string; companyId: string }
) => BrokerAPI.put(BrokerAPIURL.putAssignUser(companyId), data);

export const unassignUser = (companyId: string, data: { userRole: string; companyId: string }) =>
  BrokerAPI.put(BrokerAPIURL.unassignUser(companyId), data);

export const postCompanyContact = (companyId: string, data: { [key: string]: any }) =>
  BrokerAPI.post(BrokerAPIURL.postCompanyContact(companyId), data);

export const putCompanyContact = (
  companyId: string,
  companyContactId: string,
  data: { [key: string]: any }
) => BrokerAPI.put(BrokerAPIURL.putCompanyContact(companyId, companyContactId), data);

export const deleteCompanyContact = (companyId: string, companyContactId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteCompanyContact(companyId, companyContactId));

export const deleteCompany = (companyId: string) =>
  BrokerAPI.delete(BrokerAPIURL.deleteCompany(companyId));

export const getCompanyStatusHistory = (id: string, opportunityId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCompanyStatusHistory(id, opportunityId));

export const getCreditSafeReport = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCreditSafeReport(companyId));

export const getCreditSafeFinancialReport = (companyId: string) =>
  BrokerAPI.get(BrokerAPIURL.getCreditSafeFinancialReport(companyId));
