export const convertToUTCDate = (givenDateToConvert: Date) => {
  return new Date(
    Date.UTC(
      givenDateToConvert.getFullYear(),
      givenDateToConvert.getMonth(),
      givenDateToConvert.getDate()
    )
  );
};

export const convertEndDateToUTC = (givenEndDateToConvert: Date) => {
  // Adjust the time to the end of the day in the local time zone
  const adjustedDate = new Date(
    givenEndDateToConvert.getFullYear(),
    givenEndDateToConvert.getMonth(),
    givenEndDateToConvert.getDate(),
    23,
    59,
    59,
    999
  );
  // Convert the adjusted end date to UTC format
  const newUTCEndDate = new Date(
    Date.UTC(
      adjustedDate.getFullYear(),
      adjustedDate.getMonth(),
      adjustedDate.getDate(),
      adjustedDate.getHours(),
      adjustedDate.getMinutes(),
      adjustedDate.getSeconds(),
      adjustedDate.getMilliseconds()
    )
  );
  return newUTCEndDate;
};

export const getSearchDateString = (date: Date) => {
  if (!date) return null;
  return date.toISOString().split('T')[0];
};

export const getDateBySearchString = (dateString: string, endOfDay: boolean) => {
  const date = new Date(dateString);

  date.setHours(endOfDay ? 23 : 0, endOfDay ? 59 : 0, endOfDay ? 59 : 0, endOfDay ? 999 : 0);

  return date;
};
