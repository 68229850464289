import cn from 'classnames';
import Spinner from 'components/spinner';
import { APIUsersByRoleType } from 'pages/users/type';
import { useRef, useState } from 'react';
import { useAppState } from 'store';
import { appSpecific } from '_shared/utils/application';
import useBlur from '_shared/hooks/useBlur';
import styles from './assets/userSelect.module.scss';
import { useTranslation } from 'react-i18next';
import { HistoricalData } from 'pages/companyOverview/types';
import HistoryButton from 'components/historybutton';

const UserAssignment = ({
  label,
  selectedUser,
  onSelect,
  users,
  loading = false,
  className,
  isNewDeal = false,
  historicalData = undefined,
  canUnassign = false,
}: {
  label?: string;
  selectedUser?: APIUsersByRoleType | null;
  users: APIUsersByRoleType[];
  onSelect: Function;
  loading?: boolean;
  className?: string;
  isNewDeal?: boolean;
  historicalData?: HistoricalData;
  canUnassign?: boolean;
}) => {
  const { state: globalState } = useAppState();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  useBlur(dropdownRef, () => setIsDropdownOpen(false));

  const handleSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const getUserAssignmentDisplayName = (userId: string, fullName: string) => {
    return (userId === globalState.system.currentUser?.authId && `${fullName} (me)`) || fullName;
  };

  const selectUser = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    const userId = event.currentTarget.id;
    if (!userId) {
      onSelect?.(null);
      setIsDropdownOpen(false);
    }
    const relevantUserDetails =
      users.find((user: any) => {
        return user.userId === userId;
      }) ?? null;

    if (!relevantUserDetails?.userId) return;

    onSelect?.(relevantUserDetails);
    setIsDropdownOpen(false);
  };

  return (
    <div
      data-testid="select-user"
      data-cy="select-user"
      className={cn(styles['field-holder'], styles['assign-user-container'], className)}
    >
      {!isNewDeal && (
        <label htmlFor="user" className={cn(styles['assign-user-label'])}>
          {label}
          <span
            className={cn(styles['info-icon'], styles['tooltip'])}
            data-label={t(
              appSpecific(
                'home:applicationsClient:tooltips:broker:fundingmanager',
                'home:applicationsClient:tooltips:broker:accountowner'
              )
            )}
          >
            <i className={cn('material-icons')}>info</i>
          </span>
          {historicalData && (
            <HistoryButton callback={historicalData.callback} tooltip={historicalData.tooltip} />
          )}
        </label>
      )}
      <div
        className={cn(
          styles['user-dropdown-container'],
          isNewDeal && styles['user-dropdown-container-deal']
        )}
      >
        <button
          data-testid="select-user-dropdown-button"
          className={cn(styles['user-dropdown-button'])}
          onClick={() => setIsDropdownOpen(true)}
        >
          {(selectedUser && (
            <div data-testid="selected-user" className={cn(styles['user-dropdown-button-label'])}>
              <img src={selectedUser?.picture} alt="users" />
              <span>{selectedUser?.fullName}</span>
            </div>
          )) || <span>Unassigned</span>}
          {(loading && <Spinner size="small" />) || (
            <span className={cn('material-icons', styles['edit-icon'])}>edit</span>
          )}
        </button>
        {(isDropdownOpen && (
          <div
            data-testid="user-select-dropdown"
            ref={dropdownRef}
            className={cn(styles['user-dropdown'], isNewDeal && styles['user-dropdown-upward'])}
          >
            <input
              type="text"
              onChange={handleSearchUser}
              placeholder={t('home:colleagues:search:placeholder')}
              className={cn(styles['user-search'])}
            />
            <div className={cn(styles['user-container'])}>
              {selectedUser && canUnassign && (
                <button data-testid={`unassign-selected-user`} id={undefined} onClick={selectUser}>
                  <span>Set as Unassigned</span>
                </button>
              )}
              {(users.length &&
                users
                  .filter(
                    (user) =>
                      user?.fullName?.toLowerCase()?.includes(searchValue) ??
                      user?.email?.toLowerCase()?.includes(searchValue)
                  )
                  .sort((a: any, b: any) =>
                    a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1
                  )
                  .map((user: any) => {
                    return (
                      (Boolean(user) && (
                        <button
                          data-testid={`select-user-id-${user.userId}`}
                          key={user.userId}
                          id={user.userId}
                          onClick={selectUser}
                        >
                          <img src={user.picture} alt="users" />
                          <span>{getUserAssignmentDisplayName(user.userId, user.fullName)}</span>
                        </button>
                      )) ||
                      null
                    );
                  })) || <div className={cn(styles['user-container-empty'])}>No Users Found</div>}
            </div>
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default UserAssignment;
