import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { convertToLocalDateFormat } from '_shared/utils/date';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';
import Modal from 'components/modal';
import Table from 'components/v2/Table';
import TableHead from 'components/v2/TableHead';
import TableRow from 'components/v2/TableRow';
import TableCell from 'components/v2/TableCell';
import TableBody from 'components/v2/TableBody';
import styles from './ApplicationDeleteModal.module.scss';

type ApplicationDeleteModalProps = {
  isModalOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  providerName?: string;
  productName?: string;
  dateCreated?: string;
};

const ApplicationDeleteModal = ({
  isModalOpen,
  isLoading,
  onClose,
  onConfirm,
  providerName = 'N/A',
  productName = 'N/A',
  dateCreated,
}: ApplicationDeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={isModalOpen} handleClose={onClose}>
      <div className={styles.modal}>
        <h3>{t('home:deletemodal:title')}</h3>

        <p className={cn(styles['warning'])}>{t('home:deletemodal:description')}</p>

        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Funder</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{providerName}</TableCell>
              <TableCell>{productName}</TableCell>
              <TableCell>{dateCreated ? convertToLocalDateFormat(dateCreated) : 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className={cn(styles['actions'])}>
          <Button buttonStyleType={ButtonStyleTypeEnum.SECONDARY} clickHandler={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            clickHandler={onConfirm}
            loading={isLoading}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicationDeleteModal;
