import { FieldMetaDataPropType, FormFieldPropType } from '_shared/fieldValidation/types';

export const formatMetadata = (metadata: FormFieldPropType[]) => {
  // Creating an object where the keys are the id of the field and the value is the index of the field in the array.
  // With this approach, we can access the metadata object directly using keys and not array indexes,
  // which is more reliable because we can say the exac field we need without worrying about its position in the array
  // (the position of the field may change). Also, there is no need to create a state object to handle metadata changes,
  // which can cause unnecessary renderings.
  if (metadata.length) {
    const indexByKeyList: { [key: string]: number } = {};

    metadata.forEach((item: FieldMetaDataPropType, index: number) => {
      if (item.id) indexByKeyList[item.id] = index;
    });

    return indexByKeyList;
  }

  return {};
};
