import { withTransaction } from '@elastic/apm-rum-react';
import { RouteObject } from 'react-router-dom';
import { RoutePath } from '_shared/routes';
import UserDetails from '.';

export const userDetailsRoutes: RouteObject[] = [
  {
    path: RoutePath.userdetails,
    element: withTransaction('UserDetails', 'component')(<UserDetails />),
  },
];
