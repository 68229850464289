import { UPDATE_DEALS, DealsState, DealsActionTypes } from './types';

export const initialDealsState: DealsState = {
  dealId: '',
  dealIndexState: '',
  selectedFilters: null,
  companyName: '',
  pageNumber: 1,
  refreshIndexPage: 0,
  filter: {
    orderBy: 'lastProgressed',
    direction: 'desc',
  },
  selectedStatus: [],
  selectedCreatorTypes: [],
  hideCompletedDeals: false,
  userList: [],
  dealsData: [],
  currentDealData: {},
  currentDealFunders: [],
  companyId: '',
  uniqueDealFundersIds: [],
  funderId: '',
  detailsModalOpen: false,
  lastProgressedFilterType: '',
  lastProgressedFrom: null,
  lastProgressedTo: null,
  selectedDealSubTypes: [],
  loanDealSubTypes: [],
  equityDealSubTypes: [],
  allDealSubTypes: [],
};

export function dealsReducer(state: DealsState, action: DealsActionTypes): DealsState {
  switch (action.type) {
    case UPDATE_DEALS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
