import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './StickyFooter.module.scss';
import cn from 'classnames';

interface StickyFooterProps {
  show: boolean;
  children?: ReactNode;
  contentClassName?: string;
}

const StickyFooter: React.FC<StickyFooterProps> = ({ show, children, contentClassName }) => {
  useEffect(() => {
    if (show) document.body.classList.add('sticky-footer-visible');

    return () => {
      document.body.classList.remove('sticky-footer-visible');
    };
  }, [show]);

  return createPortal(
    <div className={cn(styles['sticky-footer'], { [styles.visible]: show })}>
      <div className={contentClassName}>{children}</div>
    </div>,
    document.body
  );
};

export default StickyFooter;
