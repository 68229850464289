import { FieldMetaDataPropType } from '_shared/fieldValidation/types';

export type overviewStateType = {
  charges: Array<FieldMetaDataPropType>;
  loading: boolean;
  error: boolean;
};

export enum ActionType {
  SETCHARGES,
}

export type Action = {
  type: ActionType;
  payload: any;
};

export function chargesReducer(state: overviewStateType, action: Action) {
  if (action.type === ActionType.SETCHARGES) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
}
