import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from 'pages/documentLibrary/assets/deleteDocument.module.scss';
import { APIDocumentType } from 'pages/documentLibrary/types';
import deleteDocumentFields from 'pages/documentLibrary/fields/deleteDocument.fields';
import Modal from 'components/modal';
import Table from 'components/table';
import Button from 'components/button';
import { ButtonStyleTypeEnum } from 'components/button/type';

type DeleteDocumentTypes = {
  documents: Array<APIDocumentType>;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: Function;
  confirmDelete: any;
  isDeleting: boolean;
};

const DeleteDocument = (props: DeleteDocumentTypes) => {
  const { t } = useTranslation();

  const documentFields = deleteDocumentFields(props.documents);

  return (
    <Modal show={props.isDeleteModalOpen} handleClose={props.setIsDeleteModalOpen}>
      <div className={cn(styles['document-deletion-confirm-modal'])}>
        <h3> {t('home:companydetails:documentlibrary:delete:confirmdelete')}</h3>

        <p className={cn(styles['warning'])}>
          {t('home:companydetails:documentlibrary:delete:confirmdeletemessage').replace(
            '{number}',
            `${props.documents.length}`
          )}
        </p>

        <Table {...documentFields} />

        <div className={cn(styles['actions'])}>
          <Button
            id="document-deletion-cancel-btn"
            ariaLabel={t('common:cancel')}
            clickHandler={() => props.setIsDeleteModalOpen()}
            buttonStyleType={ButtonStyleTypeEnum.SECONDARY}
            tabIndex={0}
          >
            {t('common:cancel')}
          </Button>

          <Button
            id="document-deletion-confirm-btn"
            ariaLabel={t('common:delete')}
            buttonStyleType={ButtonStyleTypeEnum.PRIMARY}
            className={cn(styles['delete'])}
            clickHandler={props.confirmDelete}
            loading={props.isDeleting}
            tabIndex={0}
          >
            {t('common:delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocument;
